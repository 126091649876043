import React, { Component } from 'react'
import Chart from 'react-apexcharts'

class BarChart extends Component {
  constructor(props) {
    super(props)
    console.log(props.rotate)
    this.state = {
      series: props.data,
      options: {
        chart: {
          type: 'bar',
          zoom: {
            show: true,
            type: 'xy'
          },
          toolbar: {
            show: true,
            tools: {
              download: true,
              reset: true
            }
          },
          events: {
            dataPointSelection: function(event, chartContext, config) {
              let dataPoint = document.querySelectorAll('.apexcharts-series')
              console.log(dataPoint)
              console.log(config.selectedDataPoints)
              // console.log(dataPoint)

              // console.log(config.w.globals.fill.colors)

              // console.log(config.w.globals.fill.colors[1])
              // console.log(config.w.globals)

              // console.log(config)
              // if(config.selectedDataPoints[0].length === 1) {
              //   console.log(chartContext)
              //   console.log(config.w.config.colors)
              //   console.log(true)
              // } else {
              //   console.log(false)
              // }

              props.handleDataPointSelect(config.dataPointIndex, config.w.globals.categoryLabels[config.dataPointIndex], config.selectedDataPoints[0], props.chartID)

            }
          }
        },
        states: {
          hover: {
            filter: {
              type: 'darken',
              value: 0.7,
            }
          },
          active: {
            allowMultipleDataPointsSelection: true,
            filter: {
              type: 'lighten',
              value: 0.2
            },
          }
        },

        colors: '#008ffb',
        // colors: [function({value, seriesIndex, w}) {
        //   // console.log(w.config.chart.events.dataPointSelection.config)
        //   if(w.globals.events.dataPointSelection.length > 0) {
        //     console.log('hello')
        //   } else {
        //     return '#008ffb'
        //   }
        //   // console.log(w.config.chart.event.dataPointSelection)
        // }],
        plotOptions: {
          bar: {
            barHeight: '75%',
            columnWidth: '50%',
            distributed: props.distributed,
            horizontal: props.horizontal
          }
        },
        legend: {
          show: props.legend,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 1,
        },
        grid: {
          position: 'back',
          row: {
            colors: props.rowColors
          },
          column: {
            colors: props.columnColors
          }
        },
        title: {
          text: props.title ? props.title.toUpperCase() : '',
          align: 'center',
          style: {
            color: props.darkMode ? '#fff' : '',
          },
        },
        xaxis: {
          title: {
            text: props.xLabel,
            style: {
              color: props.darkMode ? '#fff' : ''
            }
          },
          categories: props.labels,
          tickPlacement: 'on',
          labels: {
            hideOverlappingLabels: true,
            rotate: props.rotate,
            // rotateAlways: true,
            style: {
              fontSize: '10px',
              colors: props.darkMode ? '#fff' : ''
            }
          }
        },
        yaxis: {
          title: {
            text: props.yLabel,
            style: {
              color: props.darkMode ? '#fff' : ''
            },
          },
          labels: {
            formatter: value => {
              return value.toLocaleString('en')
            },
            style: {
              colors: props.darkMode ? '#fff' : ''
            }
          },
          axisBorder: {
            show: true,
            color: props.darkMode ? '#fff' : ''
          }
        },
        tooltip: {
          intersect: true,
          theme: props.darkMode ? 'dark' : 'light',
          x: {
            show: true
          },
          y: {
            formatter: function(value) {
              return value.toLocaleString('en')
            }
          },

        },
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return (nextProps.data !== prevState.series) ?
        {
          series: nextProps.data,
          options: {
            xaxis: {
              categories: nextProps.labels
            }
          }
        } : null
  }

  // componentDidMount = () => {
  //   const labels = document.querySelectorAll('.apexcharts-text.apexcharts-xaxis-label')
  //   const dataPoint = document.querySelectorAll('.apexcharts-bar-area')
  //   const tooltips = document.querySelectorAll('.apexcharts-xaxistooltip')
  //
  //   // console.log(tooltips)
  //   // console.log(dataPoint)
  //
  //   labels.forEach(item => {
  //     item.addEventListener('click', e => {
  //       console.log('hello')
  //       console.log(e.target.textContent)
  //
  //     })
  //   })
  //
  //   dataPoint.forEach(item => {
  //     item.addEventListener('click', e => {
  //       console.log('hello')
  //       console.log(e.target.textContent)
  //
  //     })
  //   })
  //
  //   // labels.forEach(item => {
  //   //   item.addEventListener('click', e => {
  //   //     // if(e.target.textContent === 'Boomerang') {
  //   //       window.location=`/${e.target.textContent.toLowerCase()}/lost-opp`
  //   //       // window.location='/lost-opp'
  //   //     // }
  //   //   })
  //   // })
  // }

  render() {

    return(
      <div>
        <Chart options={this.state.options} series={this.state.series} type="bar" height={this.props.height} width={this.props.width}/>
      </div>
    )
  }
}

export default BarChart
