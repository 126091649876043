import React, { Component } from 'react'
import Chart from 'react-apexcharts'
// import moment from 'moment'

class CprChart extends Component {
  constructor(props) {
    super(props)
    // console.log(props.series)
    this.state = {
      series: this.props.series,
      options: {
        chart: {
          type: 'bar',
          stacked: true,
          // offsetX: 27,
        },
        title: {
          text: this.props.title,
          align: 'center',
          style: {
            color: props.darkMode ? '#fff' : '',
          },
        },
        plotOptions: {
          bar: {
            horizontal: false
          },
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          type: 'category',
          categories: this.props.categories,
          labels: {
            rotate: -45,
            hideOverlappingLables: true,
            trim: true,
            style: {
              colors: props.darkMode ? '#fff' : '',
            }
            // formatter: value => {
            //   return
            // }
          },
          // tickAmount: 4,
          // tickPlacement: 'on',
        },
        legend: {
          inverseOrder: true,
          position: 'top',
          // offsetY: 25,
          horizontalAlign: 'left',
          labels: {
            colors: props.darkMode ? '#fff' : '',
          },
        },
        tooltip: {
          y: {
            formatter: (value) => {
              return value.toLocaleString('en')
            }
          }
        },
        fill: {
          opacity: 1,
        },
        colors: [
          '#deebf7',
          '#c6dbef',
          '#9ecae1',
          '#6baed6',
          '#4292c6',
          '#2171b5',
        ],
        yaxis: {
          tickAmount: 10,
          min: 0,
          // max: this.props.maxArr.reduce((a,b) => Math.max(a,b)),
          title: {
            text: this.props.yTitle,
            style: {
              color: props.darkMode ? '#fff' : ''
            },
          },
          labels: {
            formatter: value => {
              if(value <= 999) {
                return value
              } else if (value > 999 && value <= 999999 ) {
                return Math.round(value / 1000) + 'K'
              } else if (value > 999999 && value <= 999999999) {
                return (value / 1000000).toFixed(2) + 'M'
              } else {
                return (value / 1000000000).toFixed(2) + 'B'
              }
            },
            style: {
              colors: props.darkMode ? '#fff' : ''
            }
          },
        },
      }
    }
  }

  render() {
    return(
      <div>
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height={this.props.height}
          // width={'90%'}
        />
      </div>
    )
  }
}

export default CprChart
