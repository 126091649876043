import React, { Component } from 'react'
import ReactExport from 'react-data-export'
import {
  withStyles,
  Typography,
  Button,
} from '@material-ui/core'
import { RiFileExcel2Fill } from 'react-icons/ri'
import { FaFileDownload } from 'react-icons/fa'
import moment from 'moment'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

const styles = theme => ({
  button: {
    color: '#0c7238',
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#17c261'
    }
  },
  icon: {
    fontSize: '17px',
    marginLeft: '5px'
  }
})

class LostOppExcel extends Component {
  constructor(props) {
    console.log(props.maxDate)
    console.log(moment(props.maxDate).format('YYYY-MM'))
    console.log(moment(props.maxDate).subtract(11, 'months').format('YYYY-MM'))
    super(props)
    this.state = {
      maxDate: props.maxDate
    }
  }

  getTotalExportData = data => {
    const totalData = []
    const totalObj = {}
    const decilesArr = data.map(item => item.decID)
    const countsPerDec = data.map(item => item.lostOpp.reduce((a,b) => a + b, 0))
    const roiArr = data.map(item => item.roi)
    const cppArr = data.map(item => item.cpp)

    const totalsData = [
      {
        columns: [
          {title: 'DECILE', width: {wpx: 80}},
          {title: 'AVG LOST OPPORTUNITY (By Month)', width: {wpx: 250}},
          {title: 'AVG INCREMENTAL SALES (By Month)', width: {wpx: 250}},
          {title: 'INCREMENTAL RETURNS ON AD SPEND (%)', width: {wpx: 250}},
        ],
        data: [
          [
            {value: 1},
            {value: Math.round(countsPerDec[0] / this.props.dataCount)},
            {value: Math.round(roiArr[0] * (countsPerDec[0] / this.props.dataCount) * cppArr[0])},
            {value: roiArr[0] * 100},
          ],
          [
            {value: 2},
            {value: Math.round(countsPerDec[1] / this.props.dataCount)},
            {value: Math.round(roiArr[1] * (countsPerDec[1] / this.props.dataCount) * cppArr[1])},
            {value: roiArr[1] * 100},
          ],
          [
            {value: 3},
            {value: Math.round(countsPerDec[2] / this.props.dataCount)},
            {value: Math.round(roiArr[2] * (countsPerDec[2] / this.props.dataCount) * cppArr[2])},
            {value: roiArr[2] * 100},
          ],
          [
            {value: 4},
            {value: Math.round(countsPerDec[3] / this.props.dataCount)},
            {value: Math.round(roiArr[3] * (countsPerDec[3] / this.props.dataCount) * cppArr[3])},
            {value: roiArr[3] * 100},
          ],
          [
            {value: 5},
            {value: Math.round(countsPerDec[4] / this.props.dataCount)},
            {value: Math.round(roiArr[4] * (countsPerDec[4] / this.props.dataCount) * cppArr[4])},
            {value: roiArr[4] * 100},
          ],
          [
            {value: 6},
            {value: Math.round(countsPerDec[5] / this.props.dataCount)},
            {value: Math.round(roiArr[5] * (countsPerDec[5] / this.props.dataCount) * cppArr[5])},
            {value: roiArr[5] * 100},
          ],
          [
            {value: 7},
            {value: Math.round(countsPerDec[6] / this.props.dataCount)},
            {value: Math.round(roiArr[6] * (countsPerDec[6] / this.props.dataCount) * cppArr[6])},
            {value: roiArr[6] * 100},
          ],
          [
            {value: 8},
            {value: Math.round(countsPerDec[7] / this.props.dataCount)},
            {value: Math.round(roiArr[7] * (countsPerDec[7] / this.props.dataCount) * cppArr[7])},
            {value: roiArr[7] * 100},
          ],
          [
            {value: 9},
            {value: Math.round(countsPerDec[8] / this.props.dataCount)},
            {value: Math.round(roiArr[8] * (countsPerDec[8] / this.props.dataCount) * cppArr[8])},
            {value: roiArr[8] * 100},
          ],
          [
            {value: 10},
            {value: Math.round(countsPerDec[9] / this.props.dataCount)},
            {value: Math.round(roiArr[9] * (countsPerDec[9] / this.props.dataCount) * cppArr[9])},
            {value: roiArr[9] * 100},
          ],
        ]
      }
    ]
    return(totalsData)
  }

  getMailedExport = data => {
    const mailedData = [
      {
        columns: [
          {title: 'DECILE', width: {wpx: 80}},
          {title: moment(this.state.maxDate).subtract(11, 'months').format('MMM YY').toUpperCase(), width: {wpx: 150}},
          {title: moment(this.state.maxDate).subtract(10, 'months').format('MMM YY').toUpperCase(), width: {wpx: 150}},
          {title: moment(this.state.maxDate).subtract(9, 'months').format('MMM YY').toUpperCase(), width: {wpx: 150}},
          {title: moment(this.state.maxDate).subtract(8, 'months').format('MMM YY').toUpperCase(), width: {wpx: 150}},
          {title: moment(this.state.maxDate).subtract(7, 'months').format('MMM YY').toUpperCase(), width: {wpx: 150}},
          {title: moment(this.state.maxDate).subtract(6, 'months').format('MMM YY').toUpperCase(), width: {wpx: 150}},
          {title: moment(this.state.maxDate).subtract(5, 'months').format('MMM YY').toUpperCase(), width: {wpx: 150}},
          {title: moment(this.state.maxDate).subtract(4, 'months').format('MMM YY').toUpperCase(), width: {wpx: 150}},
          {title: moment(this.state.maxDate).subtract(3, 'months').format('MMM YY').toUpperCase(), width: {wpx: 150}},
          {title: moment(this.state.maxDate).subtract(2, 'months').format('MMM YY').toUpperCase(), width: {wpx: 150}},
          {title: moment(this.state.maxDate).subtract(1, 'months').format('MMM YY').toUpperCase(), width: {wpx: 150}},
          {title: moment(this.state.maxDate).format('MMM YY').toUpperCase(), width: {wpx: 150}},
        ],
        data: [
          [
            {value: 1},
            {value: data[9].data[0]},
            {value: data[9].data[1]},
            {value: data[9].data[2]},
            {value: data[9].data[3]},
            {value: data[9].data[4]},
            {value: data[9].data[5]},
            {value: data[9].data[6]},
            {value: data[9].data[7]},
            {value: data[9].data[8]},
            {value: data[9].data[9]},
            {value: data[9].data[10]},
            {value: data[9].data[11]},
          ],
          [
            {value: 2},
            {value: data[8].data[0]},
            {value: data[8].data[1]},
            {value: data[8].data[2]},
            {value: data[8].data[3]},
            {value: data[8].data[4]},
            {value: data[8].data[5]},
            {value: data[8].data[6]},
            {value: data[8].data[7]},
            {value: data[8].data[8]},
            {value: data[8].data[9]},
            {value: data[8].data[10]},
            {value: data[8].data[11]},
          ],
          [
            {value: 3},
            {value: data[7].data[0]},
            {value: data[7].data[1]},
            {value: data[7].data[2]},
            {value: data[7].data[3]},
            {value: data[7].data[4]},
            {value: data[7].data[5]},
            {value: data[7].data[6]},
            {value: data[7].data[7]},
            {value: data[7].data[8]},
            {value: data[7].data[9]},
            {value: data[7].data[10]},
            {value: data[7].data[11]},
          ],
          [
            {value: 4},
            {value: data[6].data[0]},
            {value: data[6].data[1]},
            {value: data[6].data[2]},
            {value: data[6].data[3]},
            {value: data[6].data[4]},
            {value: data[6].data[5]},
            {value: data[6].data[6]},
            {value: data[6].data[7]},
            {value: data[6].data[8]},
            {value: data[6].data[9]},
            {value: data[6].data[10]},
            {value: data[6].data[11]},
          ],
          [
            {value: 5},
            {value: data[5].data[0]},
            {value: data[5].data[1]},
            {value: data[5].data[2]},
            {value: data[5].data[3]},
            {value: data[5].data[4]},
            {value: data[5].data[5]},
            {value: data[5].data[6]},
            {value: data[5].data[7]},
            {value: data[5].data[8]},
            {value: data[5].data[9]},
            {value: data[5].data[10]},
            {value: data[5].data[11]},
          ],
          [
            {value: 6},
            {value: data[4].data[0]},
            {value: data[4].data[1]},
            {value: data[4].data[2]},
            {value: data[4].data[3]},
            {value: data[4].data[4]},
            {value: data[4].data[5]},
            {value: data[4].data[6]},
            {value: data[4].data[7]},
            {value: data[4].data[8]},
            {value: data[4].data[9]},
            {value: data[4].data[10]},
            {value: data[4].data[11]},
          ],
          [
            {value: 7},
            {value: data[3].data[0]},
            {value: data[3].data[1]},
            {value: data[3].data[2]},
            {value: data[3].data[3]},
            {value: data[3].data[4]},
            {value: data[3].data[5]},
            {value: data[3].data[6]},
            {value: data[3].data[7]},
            {value: data[3].data[8]},
            {value: data[3].data[9]},
            {value: data[3].data[10]},
            {value: data[3].data[11]},
          ],
          [
            {value: 8},
            {value: data[2].data[0]},
            {value: data[2].data[1]},
            {value: data[2].data[2]},
            {value: data[2].data[3]},
            {value: data[2].data[4]},
            {value: data[2].data[5]},
            {value: data[2].data[6]},
            {value: data[2].data[7]},
            {value: data[2].data[8]},
            {value: data[2].data[9]},
            {value: data[2].data[10]},
            {value: data[2].data[11]},
          ],
          [
            {value: 9},
            {value: data[1].data[0]},
            {value: data[1].data[1]},
            {value: data[1].data[2]},
            {value: data[1].data[3]},
            {value: data[1].data[4]},
            {value: data[1].data[5]},
            {value: data[1].data[6]},
            {value: data[1].data[7]},
            {value: data[1].data[8]},
            {value: data[1].data[9]},
            {value: data[1].data[10]},
            {value: data[1].data[11]},
          ],
          [
            {value: 10},
            {value: data[0].data[0]},
            {value: data[0].data[1]},
            {value: data[0].data[2]},
            {value: data[0].data[3]},
            {value: data[0].data[4]},
            {value: data[0].data[5]},
            {value: data[0].data[6]},
            {value: data[0].data[7]},
            {value: data[0].data[8]},
            {value: data[0].data[9]},
            {value: data[0].data[10]},
            {value: data[0].data[11]},
          ],
        ]
      }
    ]
    return(mailedData)
  }

  getLostOppExport = data => {
    const lostOpp = [
      {
        columns: [
          {title: 'DECILE', width: {wpx: 80}},
          {title: moment(this.state.maxDate).subtract(11, 'months').format('MMM YY').toUpperCase(), width: {wpx: 150}},
          {title: moment(this.state.maxDate).subtract(10, 'months').format('MMM YY').toUpperCase(), width: {wpx: 150}},
          {title: moment(this.state.maxDate).subtract(9, 'months').format('MMM YY').toUpperCase(), width: {wpx: 150}},
          {title: moment(this.state.maxDate).subtract(8, 'months').format('MMM YY').toUpperCase(), width: {wpx: 150}},
          {title: moment(this.state.maxDate).subtract(7, 'months').format('MMM YY').toUpperCase(), width: {wpx: 150}},
          {title: moment(this.state.maxDate).subtract(6, 'months').format('MMM YY').toUpperCase(), width: {wpx: 150}},
          {title: moment(this.state.maxDate).subtract(5, 'months').format('MMM YY').toUpperCase(), width: {wpx: 150}},
          {title: moment(this.state.maxDate).subtract(4, 'months').format('MMM YY').toUpperCase(), width: {wpx: 150}},
          {title: moment(this.state.maxDate).subtract(3, 'months').format('MMM YY').toUpperCase(), width: {wpx: 150}},
          {title: moment(this.state.maxDate).subtract(2, 'months').format('MMM YY').toUpperCase(), width: {wpx: 150}},
          {title: moment(this.state.maxDate).subtract(1, 'months').format('MMM YY').toUpperCase(), width: {wpx: 150}},
          {title: moment(this.state.maxDate).format('MMM YY').toUpperCase(), width: {wpx: 150}},
        ],
        data: [
          [
            {value: 1},
            {value: data[9].data[0]},
            {value: data[9].data[1]},
            {value: data[9].data[2]},
            {value: data[9].data[3]},
            {value: data[9].data[4]},
            {value: data[9].data[5]},
            {value: data[9].data[6]},
            {value: data[9].data[7]},
            {value: data[9].data[8]},
            {value: data[9].data[9]},
            {value: data[9].data[10]},
            {value: data[9].data[11]},
          ],
          [
            {value: 2},
            {value: data[8].data[0]},
            {value: data[8].data[1]},
            {value: data[8].data[2]},
            {value: data[8].data[3]},
            {value: data[8].data[4]},
            {value: data[8].data[5]},
            {value: data[8].data[6]},
            {value: data[8].data[7]},
            {value: data[8].data[8]},
            {value: data[8].data[9]},
            {value: data[8].data[10]},
            {value: data[8].data[11]},
          ],
          [
            {value: 3},
            {value: data[7].data[0]},
            {value: data[7].data[1]},
            {value: data[7].data[2]},
            {value: data[7].data[3]},
            {value: data[7].data[4]},
            {value: data[7].data[5]},
            {value: data[7].data[6]},
            {value: data[7].data[7]},
            {value: data[7].data[8]},
            {value: data[7].data[9]},
            {value: data[7].data[10]},
            {value: data[7].data[11]},
          ],
          [
            {value: 4},
            {value: data[6].data[0]},
            {value: data[6].data[1]},
            {value: data[6].data[2]},
            {value: data[6].data[3]},
            {value: data[6].data[4]},
            {value: data[6].data[5]},
            {value: data[6].data[6]},
            {value: data[6].data[7]},
            {value: data[6].data[8]},
            {value: data[6].data[9]},
            {value: data[6].data[10]},
            {value: data[6].data[11]},
          ],
          [
            {value: 5},
            {value: data[5].data[0]},
            {value: data[5].data[1]},
            {value: data[5].data[2]},
            {value: data[5].data[3]},
            {value: data[5].data[4]},
            {value: data[5].data[5]},
            {value: data[5].data[6]},
            {value: data[5].data[7]},
            {value: data[5].data[8]},
            {value: data[5].data[9]},
            {value: data[5].data[10]},
            {value: data[5].data[11]},
          ],
          [
            {value: 6},
            {value: data[4].data[0]},
            {value: data[4].data[1]},
            {value: data[4].data[2]},
            {value: data[4].data[3]},
            {value: data[4].data[4]},
            {value: data[4].data[5]},
            {value: data[4].data[6]},
            {value: data[4].data[7]},
            {value: data[4].data[8]},
            {value: data[4].data[9]},
            {value: data[4].data[10]},
            {value: data[4].data[11]},
          ],
          [
            {value: 7},
            {value: data[3].data[0]},
            {value: data[3].data[1]},
            {value: data[3].data[2]},
            {value: data[3].data[3]},
            {value: data[3].data[4]},
            {value: data[3].data[5]},
            {value: data[3].data[6]},
            {value: data[3].data[7]},
            {value: data[3].data[8]},
            {value: data[3].data[9]},
            {value: data[3].data[10]},
            {value: data[3].data[11]},
          ],
          [
            {value: 8},
            {value: data[2].data[0]},
            {value: data[2].data[1]},
            {value: data[2].data[2]},
            {value: data[2].data[3]},
            {value: data[2].data[4]},
            {value: data[2].data[5]},
            {value: data[2].data[6]},
            {value: data[2].data[7]},
            {value: data[2].data[8]},
            {value: data[2].data[9]},
            {value: data[2].data[10]},
            {value: data[2].data[11]},
          ],
          [
            {value: 9},
            {value: data[1].data[0]},
            {value: data[1].data[1]},
            {value: data[1].data[2]},
            {value: data[1].data[3]},
            {value: data[1].data[4]},
            {value: data[1].data[5]},
            {value: data[1].data[6]},
            {value: data[1].data[7]},
            {value: data[1].data[8]},
            {value: data[1].data[9]},
            {value: data[1].data[10]},
            {value: data[1].data[11]},
          ],
          [
            {value: 10},
            {value: data[0].data[0]},
            {value: data[0].data[1]},
            {value: data[0].data[2]},
            {value: data[0].data[3]},
            {value: data[0].data[4]},
            {value: data[0].data[5]},
            {value: data[0].data[6]},
            {value: data[0].data[7]},
            {value: data[0].data[8]},
            {value: data[0].data[9]},
            {value: data[0].data[10]},
            {value: data[0].data[11]},
          ],
        ]
      }
    ]
    return(lostOpp)
  }



  render() {
    const { classes } = this.props
    const totalData = this.getTotalExportData(this.props.totals)
    const mailedExport = this.getMailedExport(this.props.mailedSeries)
    const lostOppExport = this.getLostOppExport(this.props.lostOppSeries)

    return (
      <div style={{display: 'flex', justifyContent: 'right'}}>
        <ExcelFile
          filename={`Lost_Opportunity_${this.props.value}(${moment().format('YYYY-MM-DD')})`}
          element={
              <Button
                className={classes.button}
              >
                <div style={{display: 'inline-flex', alignItems: 'center'}}>
                  <Typography variant="caption" style={{marginTop: '3px', fontSize: '11px'}}>EXPORT LOST OPPORTUNITY</Typography>
                  <FaFileDownload className={classes.icon} />
                </div>
              </Button>
          }
        >
          <ExcelSheet dataSet={lostOppExport} name="Lost_Opp_Per_Decile)" />
          <ExcelSheet dataSet={mailedExport} name="Mailed_Counts_Per_Decile)" />
          <ExcelSheet dataSet={totalData} name="ROI" />

        </ExcelFile>
      </div>
    )
  }
}

export default withStyles(styles)(LostOppExcel)
