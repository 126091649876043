import React, { Component } from 'react'
import Chart from 'react-apexcharts'

class ComboChart extends Component {
  constructor(props) {
    super(props)
    console.log('combo chart.....', props.data)
    this.state = {
      series: props.data,
      options: {
        chart: {
          type: 'line',
          stacked: false,
          height: props.height,
          toolbar: {
            tools: {
              download: false,
            }
          }
        },
        // markers: {
        //   size: [0, 0, 3],
        //   strokeColors: props.colors[2],
        //   strokeWidth: 2,
        //   shape: 'square'
        // },
        dataLabels: {
          enabled: false,
        },
        // dataLabels: {
        //   enabled: true,
        //   enabledOnSeries: [2]
        // },
        stroke: {
          width: [0, 0, 2],
          // curve: 'smooth'
        },
        // markers: {
        //   size: [0, 0, 10]
        // },
        plotOptions: {
          bar: {
            columnWidth: '50%',
          }
        },
        fill: {
          opacity: [1, 1, 1],
          gradient: {
            inverseColors: false,
            shade: 'light',
            type: 'vertical',
            opacityFrom: 0.8,
            opacityTo: 0.5,
            stops: [0, 100, 100, 100, 100]
          }
        },
        grid: {
          row: {
            colors: this.props.darkMode ? ['#2a2a2a', '#3a3a3a'] : ['#fff', '#f2f2f2']
          }
        },
        // labels: props.labels,
        xaxis: {
          categories: props.labels,
          type: 'category',
          labels: {
            trim: true,
            hideOverlappingLabels: true,
            rotate: -45,
            fontSize: '6px',
            maxHeight: window.innerWidth <= 1440 ? 80 : 100,
            style: {
              colors: props.darkMode ? '#fff' : '',
              fontSize: props.fontSize ?  props.fontSize : ''
            }
          },
          tooltip: {
            enabled: false,
          },
          crosshairs: {
            show: true,
            // position: 'front',
          }
        },
        yaxis: [
          {
            min: 0,
            tickAmount: 7,
            seriesName: props.name[0],
            axisBorder: {
              show: true,
              color: props.colors[0]
            },
            labels: {
              style: {
                colors: props.colors[0]
              },
              formatter: value => {
                if(value <= 999) {
                  return value
                } else if (value > 999 && value <= 999999 ) {
                  return '$' + Math.round(value / 1000) + 'K'
                } else if (value > 999999 && value <= 999999999) {
                  return '$' + Math.round(value / 1000000) + 'M'
                } else {
                  return '$' + Math.round(value / 1000000000) + 'B'
                }
              }
            },
            title: {
              text: props.name[0],
              style: {
                color: props.colors[0]
              }
            },
            // tooltip: {
            //   enabled: true,
            // }
          },
          {
            min: 0,
            max: Math.max.apply(Math, props.data[0].data.map(function(o) {return o})),
            show: false,
            tickAmount: 50,
          },
          {
            min: 0,
            seriesName: props.name[2],
            opposite: true,
            axisBorder: {
              show: true,
              color: props.colors[2]
            },
            labels: {
              style: {
                colors: props.colors[2]
              },
              formatter: value => {
                if(value <= 999) {
                  return value
                } else if (value > 999 && value <= 999999 ) {
                  return '$' + Math.round(value / 1000) + 'K'
                } else if (value > 999999 && value <= 999999999) {
                  return '$' + Math.round(value / 1000000) + 'M'
                } else {
                  return '$' + Math.round(value / 1000000000) + 'B'
                }
              }
            },
            title: {
              text: props.name[2],
              style: {
                color: props.colors[2]
              }
            },
          }
        ],
        tooltip: {
          shared: true,
          theme: 'dark',
          intersect: false,
          fixed: {
            enabled: props.enableFixed,
            position: props.enableFixed ? 'topLeft' : '',
            offsetY: -85,
            // offsetY: 30,
            // offsetX: 60,
          },
          y: {
            formatter: value => {
              // console.log(value)
              if(value % 1 === 0) {
                return '$' + value.toLocaleString('en')
              } else {
                return value + '%'
              }
            }
          },
          items: {
            display: 'flex',
          },
        },
        legend: {
          show: true,
          // horiztonalAlign: 'left',
          // offsetX: 120,
          labels: {
            colors: props.darkMode ? '#fff' : ''
          }
          // position: 'left'
        },
        colors: props.colors,
      },
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return (nextProps.data !== prevState.series) ?
      {
        series: nextProps.data,
        options: {
          xaxis: {
            categories: nextProps.labels
          }
        }
      } : null
  }

  render() {
    return(
      <div>
      {console.log(this.state.options.yaxis)}
        <Chart series={this.state.series} options={this.state.options} type="line" height={this.props.height} />
      </div>
    )
  }
}

export default ComboChart
