import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    // position: 'fixed'
  },
  paper: {
    backgroundColor: 'rgba(2, 128, 190)',
    height: '200px',
    // position: 'absolute'
  }
  // root: {
  //   color: 'rgba(0,0,0,1)',
  //   width: '100%'
  // }
}))

export default function Footer() {
  const classes = useStyles()

  return(
    <div className={classes.root}>
      <Paper className={classes.paper} square={true}>
        <Typography color="inherit">
          Footer
        </Typography>
      </Paper>
    </div>
  )
}
