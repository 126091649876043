import React, { Component, Fragment } from 'react'
import { withStyles,Typography, Divider, CircularProgress, Card } from '@material-ui/core'
import RadialChart from '../charts/RadialChart'
import DonutChart from '../charts/DonutChart'
import LineChart from '../charts/LineChart'
import Cookies from 'js-cookie'
import custRetStandIn from '../../assets/homeStandIns/custRetStandIn.png'
import AskReport from '../AskReport'

const styles = theme => ({
  root: {
    margin: '1em'
  },
  loader: {
    marginLeft: '45%',
    marginTop: '30%',
  },
  radialDivide: {
    marginTop: '-35px',
    [theme.breakpoints.down('lg')]: {
      marginTop: '-30px'
    }
  },
  dataNumber: {
    fontSize: '15px',
    fontWeight: 'bold',
    color: '#626262'
  },
  dataNumberDark: {
    fontSize: '15px',
    fontWeight: 'bold',
    color: '#fff'
  },
  avgTicketHeader: {
    fontSize: '12px',
    textAlign: 'center'
  },
  unavailable: {
    height: '400px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})

class CustRetentionModule extends Component {
  _isMounted = false

  constructor() {
    super()
    this.state = {
      isLoading: true,
      custRetentionData: {},
      custRetData: [],
      hasData: false,
    }
  }

  componentDidMount() {
    this._isMounted = true

    Promise.all([
      fetch('https://api.dev.lsdirect.com/smartdash/V1/index.cfm/custRetention', {
        method: 'GET',
        headers: {
          'token': Cookies.get('token')
        },
      }),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/custRetention/location/monthly?months=${24}`, {
        method: 'GET',
        headers: {
          'token': Cookies.get('token')
        },
      }),
    ])
    .then(([response, response2]) => Promise.all([response.json(), response2.json()]))
    .then(([data, data2]) => {
      if(this._isMounted && data.length > 0) {
        this.setState({
          isLoading: false,
          custRetentionData: data[0].Data[0],
          custRetData: data2,
          hasData: true,
        })
      } else {
        this.setState({
          isLoading: false,
          custRetentionData: {},
          hasData: false,
        })
      }
    })
    .catch(error => {
      console.log(error)
    })
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  getYoyData = (custData, months) => {
    const yoy = []
    const newCustData = []
    const retCustData = []
    const newCustObj = {}
    const retCustObj = {}
    const metricLength = custData.map(item => item.Data.length)[0] - 1

    for(let i = 0; i < metricLength; i++) {
      const custObj = {}
      let newCust = custData.map(item => item.Data.map(item => item.Customers_New)[i]).reduce((a,b) => a + b, 0)
      let lyNewCust = custData.map(item => item.Data.map(item => item.Customers_New_LY)[i]).reduce((a,b) => a + b, 0)
      custObj['y'] = lyNewCust !== 0 ? ((newCust / lyNewCust - 1) * 100).toFixed(2) : null
      custObj['x'] = months[i]

      newCustData.push(custObj)
    }

    for(let i = 0; i < metricLength; i++) {
      const custObj = {}
      let retCust = custData.map(item => item.Data.map(item => item.Customers_Ret)[i]).reduce((a,b) => a + b, 0)
      let lyRetCust = custData.map(item => item.Data.map(item => item.Customers_Ret_LY)[i]).reduce((a,b) => a + b, 0)
      custObj['y'] = lyRetCust !== 0 ? ((retCust / lyRetCust - 1) * 100).toFixed(2) : null
      custObj['x'] = months[i]
      retCustData.push(custObj)
    }

    for(let i = 0; i < retCustData.length; i ++) {
      if(retCustData[i].y <= 100 && retCustData[i].y !== null) {
        for(let j = 0; j < i; j++) {
          retCustData[j].y = null
          newCustData[j].y = null
        }
        break
      }
    }
    newCustObj['name'] = 'New Customers'
    newCustObj['data'] = newCustData
    retCustObj['name'] = 'Returning Customers'
    retCustObj['data'] = retCustData
    yoy.push(newCustObj, retCustObj)

    return(yoy)
  }

  render() {
    const { classes } = this.props
    const months = this.state.custRetData.map(item => item.Data.map(item => item.YearMo))[0]
    const yoyData = this.getYoyData(this.state.custRetData, months)
    const newCustPct = (this.state.custRetentionData.Pct_Customers_New * 100).toFixed(1)
    const newCustPctLY = (this.state.custRetentionData.Pct_Customers_New_LY * 100).toFixed(1)
    // const retCustSalesPct = (this.state.custRetentionData.Pct_Sales_Ret * 100).toFixed(1)
    // const retCustSalesPctLY = (this.state.custRetentionData.Pct_Sales_Ret_LY * 100).toFixed(1)
    const newSalesPct = (this.state.custRetentionData.Pct_Sales_New * 100).toFixed(1)
    const newSalesPctLY = (this.state.custRetentionData.Pct_Sales_New_LY * 100).toFixed(1)
    const avgTicketNew = Math.round(this.state.custRetentionData.Avg_Ticket_New)
    const avgTicketRet = Math.round(this.state.custRetentionData.Avg_Ticket_Ret)
    const sdLite = JSON.parse(localStorage.getItem("currentUser"))[0].sd_lite ? JSON.parse(localStorage.getItem("currentUser"))[0].sd_lite : 0
    const dbO = JSON.parse(localStorage.getItem("currentUser")) ? JSON.parse(localStorage.getItem("currentUser"))[0].db_owner : ""

    return(
      <div className={classes.root}>
        { this.state.isLoading ? <div className={classes.loader}><CircularProgress /></div> :
          this.state.custRetData.length > 0 && this.state.hasData && sdLite !== 1 && dbO !== "ESCANABA" && dbO !== "AHS-Traffic"?
          // Object.keys(this.state.custRetData).length > 0 ?
          <div style={{marginTop: '20px'}}>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <div
                style={{width: '100%', marginLeft: '-15px', marginRight: '-5px'}}
              >
                <LineChart
                  series={yoyData}
                  title={['YOY CUSTOMER CHANGES', 'center']}
                  toolbar={false}
                  height={250}
                  width={'100%'}
                />
              </div>
              {/* <div style={{height: 300, marginLeft: '-10px'}}>
                <RadialChart
                  data={[newSalesPct, newSalesPctLY]}
                  labels={['% of New Customer Sales', '% of New Customer Sales (Last Year)']}
                  height={250}
                  colors={['#18dbde','#2bc700']}
                  background={'#e7e7e7'}
                  darkMode={this.props.darkMode}
                />
                <Divider className={classes.radialDivide} />
                <div style={{marginTop: '20px'}}>
                  <RadialChart
                    data={[newCustPct, newCustPctLY]}
                    labels={['% of New Customers', '% of New Customers(Last Year)']}
                    height={250}
                    colors={['#18dbde','#2bc700']}
                    background={'#e7e7e7'}
                    darkMode={this.props.darkMode}
                  />
                </div>
              </div> */}
              {/* <Divider origin="vertical" /> */}
              {/* <div style={{borderLeft: '0.5px solid #d4d4d4'}}>
                <div style={{marginTop: 15, marginLeft: '10px'}}>
                  <Typography className={classes.avgTicketHeader} style={this.props.darkMode ? {color: '#72a5ed'} : {color: '#4486e3',}}>
                    Average Ticket (NEW): <span className={this.props.darkMode ? classes.dataNumberDark : classes.dataNumber}>${avgTicketNew.toLocaleString('en')}</span>
                  </Typography><br/>
                  <Typography className={classes.avgTicketHeader} style={this.props.darkMode ? {color: '#c2605b'} : {color: '#c4332b'}}>
                    Average Ticket (RET): <span className={this.props.darkMode ? classes.dataNumberDark : classes.dataNumber}>${avgTicketRet.toLocaleString('en')}</span>
                  </Typography>
                </div>
                <div style={{marginTop: '10px', marginLeft: '10px'}}>
                  <DonutChart
                    data={[avgTicketRet, avgTicketNew]}
                    labels={["Avg. Ticket(Ret)", "Avg. Ticket(New)"]}
                    colors={this.props.darkMode ? ['#72a5ed', '#c2605b'] : ['#c4332b', '#4486e3']}
                    height={ window.innerWidth <= 1440 ? 125 : 180}
                    // width={window.innerWidth <= 1440 ? 100 : 300}
                    radius={'50%'}
                    legend={false}
                    tooltip={false}
                  />
                </div>
              </div> */}
            </div>
            {/* <div style={{display: 'flex', margin: '2em'}}>
              <div style={{marginLeft: 25}}>
                <Typography style={{fontSize: '12px', color: '#4486e3'}}>
                  Average Ticket (NEW): <span style={{fontSize: '15px', fontWeight: 'bold', color: '#626262'}}>${avgTicketNew.toLocaleString('en')}</span>
                </Typography><br/>
                <Typography style={{fontSize: '12px', color: '#c4332b'}}>
                  Average Ticket (RET): <span style={{fontSize: '15px', fontWeight: 'bold', color: '#626262'}}>${avgTicketRet.toLocaleString('en')}</span>
                </Typography>
              </div>
              <DonutChart
                data={[avgTicketRet, avgTicketNew]}
                labels={["Avg. Ticket(Ret)", "Avg. Ticket(New)"]}
                colors={['#c4332b', '#4486e3']}
                height={150}
                radius={'50%'}
                legend={false}
                tooltip={true}
              />
            </div> */}
          </div>
          : 
          <div className={classes.unavailable}>
          {console.log("no-data")}
              <AskReport home={true} />
            </div>
        }
      </div>
    )
  }
}

export default withStyles(styles)(CustRetentionModule)
