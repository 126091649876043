import React, { Component } from 'react'
import { withStyles, CircularProgress, Typography, Button } from '@material-ui/core'
import { FaFileDownload } from 'react-icons/fa'
import { SiTableau } from 'react-icons/si'
import DataUsageIcon from '@material-ui/icons/DataUsage'
import Cookies from 'js-cookie'

const styles = theme => ({
	pageTitle:  {
		fontFamily: 'Roboto, sans-serif',
		fontSize: '17px',
		fontWeight: 'light',
		color: 'rgba(115, 115, 115, 0.9)'
	},
	pageTitleDark:  {
		fontFamily: 'Roboto, sans-serif',
		fontSize: '17px',
		fontWeight: 'light',
		color: 'rgba(255, 255, 255, 0.9)'
	},
	pageIcon: {
		fontSize: '15px',
		marginTop: '21px',
		marginRight: '2px'
	},
	pageIconDark: {
		fontSize: '15px',
		color: 'rgba(255, 255, 255, 0.9)',
		marginTop: '5px',
		marginRight: '2px',
	},
	button: {
    color: '#0c7238',
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#17c261'
    }
  },
  buttonIcon: {
    fontSize: '17px',
    marginLeft: '5px'
  }
})

class TabTest extends Component {
	constructor(props) {
		super(props)
		this.state = {
			url: '',
			isLoading: true,
			dbOwner: '',
		}
	}

	componentDidMount() {
		fetch('https://api.dev.lsdirect.com/smartdash/V1/index.cfm/user', {
			headers: {
				'token': Cookies.get('token')
			}
		})
		.then(response => response.json())
		.then(data => {
			this.setState({
				isLoading: false,
				dbOwner: data[0].db_owner,
				// url: `https://phx.lsdirect.com/v2/tbsrv.cfm?viz=${this.props.viz}&db_owner=${data[0].db_owner}`
				url: `https://api.v2.smartdash.com/v2/tbsrv.cfm?viz=${this.props.viz}&token=${Cookies.get('token')}`
			})
			// window.location.reload()
		})
		.catch(error => {
			console.log(error)
		})


		if(JSON.parse(localStorage.getItem('currentUser'))[0].username.toUpperCase().includes('USER')) {
		} else {
			fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/pageviews/add?display=${this.props.viz}&screen_name=${this.props.pageName}&tableauID=${this.props.tableauID}`, {
				method: 'POST',
				headers: {
					'Access-Control-Allow-Origin': '*',
					'token': Cookies.get('token'),
				},
			})
			.then(response => {
				if(response.ok === true) {
					return response.json()
				}
			})
			.catch(error => {
				console.log(error)
			})			
		}
	}

	handleFileExport = (e, pageName) => {		
		console.log(pageName)

		if(pageName === 'ScanUS YOY Sales By Zip') {
			console.log('hi')
			fetch('https://api.dev.lsdirect.com/smartdash/V1/index.cfm/report/scanus', {
				headers: {
					'token': Cookies.get('token')
				}
			})
			.then(response => response.blob())
			.then(blob => URL.createObjectURL(blob))
			.then(url => {
				window.open(url, '_blank', 'noopener', 'noreferrer')
				URL.revokeObjectURL(url)
			})
			.catch(error => {
				console.log(error)
			})
		} else if(pageName === 'Market Penetration' && this.state.dbOwner === 'CITYFURNITURE') {
			console.log('hey')
			fetch('https://api.dev.lsdirect.com/smartdash/V1/index.cfm/report/mktpen4d', {
				headers: {
					'token': Cookies.get('token')
				}
			})
			.then(response => response.blob())
			.then(blob => URL.createObjectURL(blob))
			.then(url => {
				window.open(url, '_blank', 'noopener', 'noreferrer')
				URL.revokeObjectURL(url)
			})
			.catch(error => {
				console.log(error)
			})
		} else {
			console.log('hello')
			fetch('https://api.dev.lsdirect.com/smartdash/V1/index.cfm/report/salesbyzip', {
				headers: {
					'token': Cookies.get('token')
				}
			})
			.then(response => response.blob())
			.then(blob => URL.createObjectURL(blob))
			.then(url => {
				window.open(url, '_blank', 'noopener', 'noreferrer')
				URL.revokeObjectURL(url)
			})
			.catch(error => {
				console.log(error)
			})
		}
	}

	// handleFileExport = () => {
	// 	console.log('hello')
	// 	fetch('https://api.dev.lsdirect.com/smartdash/V1/index.cfm/report/scanus', {
	// 		headers: {
	// 			'token': Cookies.get('token')
	// 		}
	// 	})
	// 	.then(response => response.blob())
	// 	.then(blob => URL.createObjectURL(blob))
	// 	.then(url => {
	// 		window.open(url, '_blank')
	// 		URL.revokeObjectURL(url)
	// 	})
	// 	.catch(error => {
	// 		console.log(error)
	// 	})
	// }

  render() {
		const { classes } = this.props
    return (
      <div style={{ overflow: 'hidden', width: '100%'}}>
				{this.state.isLoading ? <div/> :
					<div>
						<div style={{display: 'flex', justifyContent: 'space-between'}}>
							<div>
								<Typography className={this.props.darkMode ? classes.pageTitleDark : classes.pageTitle} style={{marginTop: '25px'}}>
									<SiTableau className={this.props.darkMode ? classes.pageIconDark : classes.pageIcon} />
									<span style={{marginLeft: '5px'}}>{this.props.pageName}</span>
								</Typography>
							</div>
							<div
								style={
									((this.props.pageName === 'ScanUS YOY Sales By Zip' || this.props.pageName === 'YOY SALES BY ZIP CODES') && (this.state.dbOwner === 'TAMARAC' || this.state.dbOwner === 'CITYFURNITURE')) || (this.state.dbOwner === 'CITYFURNITURE' && this.props.pageName === 'Market Penetration') || (this.state.dbOwner === 'BERNIEPHYLS' && this.props.pageName === 'YOY SALES BY ZIP CODES') ?
									{marginTop: '40px'}
								:
									{display: 'none'}
								}
							>
								<Button
		              className={classes.button}
									onClick={e => this.handleFileExport(e, this.props.pageName)}
		            >
		              <div style={{display: 'inline-flex', alignItems: 'center'}}>
		                <Typography variant="caption" style={{marginTop: '3px', fontSize: '11px'}}>EXPORT {this.props.pageName}</Typography>
		                <FaFileDownload className={classes.buttonIcon} />
		              </div>
		            </Button>
							</div>
						</div>
							<iframe
								src={this.state.url}
								seamless
								scrolling="yes"
								style={
									{
										minHeight: '1000px',
										width: '101%',
										borderWidth: 0,
										position: 'static',
										overflow: 'hidden',
									}
								}
							>
							</iframe>
					</div>
				}
      </div>
    )
  }
}


export default withStyles(styles)(TabTest)
