import React, { Component } from 'react';
//import { createPortal } from 'react-dom';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemIcon,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
  Button,
  FormControlLabel,
  Switch,
  Grid,
  Tooltip,
} from '@material-ui/core'
import TodayIcon from '@material-ui/icons/Today'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import Cookies from 'js-cookie'

import IframeEmbed from '../iframe/IframeEmbed.js';
import moment from 'moment'

const styles = theme => ({
  pageTitle: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '17px',
    fontWeight: 'light',
    color: 'rgba(115, 115, 115, 0.9)'
  },
  pageTitleDark: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '17px',
    fontWeight: 'light',
    color: 'rgba(255, 255, 255, 0.9)'
  },
  pageIcon: {
    fontSize: '15px',
    marginTop: '21px',
    marginRight: '2px',
  },
  pageIconDark: {
    fontSize: '15px',
    color: 'rgba(255, 255, 255, 0.9)',
    marginTop: '5px',
    marginRight: '2px',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    paddingBottom: 16,
  },
  button: {
    marginRight: '10px',
    height: 56,
    marginTop: '-16px',
    width: 275,
    // color: '#bd4c46',
    // border: '1px solid #bd4c46',
    '&:hover': {
      color: '#ff928c',
      border: '1px solid #ff928c',
      backgroundColor: 'transparent',
    }
  },
  activeButton: {
    marginRight: '10px',
    height: 56,
    marginTop: '-16px',
    width: 275,
    color: '#fff',
    backgroundColor: '#bd4c46',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#ff928c',
      border: '1px solid #ff928c',
    }
  },
  infoIcon: {
    fontSize: '20px',
    marginTop: '-2px',
    marginRight: '15px',
    color: '#0080c0',
    '&:hover': {
      color: '#70c4ee'
    }
  }
})

const CustomSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    margin: theme.spacing(1),
    marginRight: '-2px'
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: '#25aae1',
        borderColor: '#25aae1',
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch)


class CalendarPort extends Component {
  _isMounted = false

  constructor(props) {
    super(props);
    this.state = {
      header: 'Promotion Calendar - By Market',
      src: '/legacy/CalendarPort_Mkt.html',
      id: 'SD2-CalendarPort-Mkt',
      input: "market",
      date: new Date(),
      hasError: false,
      covid: false,
    };

    this.changeCalendar = this.changeCalendar.bind(this);

    this.iframe = React.createRef();

    /*this.setContentRef = node => (
      this.contentRef = ((!node || !node.contentWindow) && null) || node.contentWindow.document.body
    );*/
  }

  componentDidMount = () => {
    this._isMounted = true

    console.log("CalendarPort >> MOUNTED");
    console.log("CalendarPort >> Date >> ", this.state.date);

    if(JSON.parse(localStorage.getItem('currentUser'))[0].username.toUpperCase().includes('USER')) {
    } else {
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/pageviews/add?display=calendar&screen_name=calendar`, {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'token': Cookies.get('token'),
        },
      })
      .then(response => {
        if(response.ok === true) {
          return response.json()
        }
      })
      .catch(error => {
        console.log(error)
      })
    }
  }

  componentWillUnmount = () => {
    this._isMounted = false
  }

  iframeOnLoad = () => {
    console.log("CalendarPort >> iframeOnLoad()");

    /*
    // Create script objects.
    var addScript = document.createElement('script');
    //addScript.setAttribute('type', 'text/javascript');
    //addScript.setAttribute('src', '../../../../../legacy/console_test.js');
    addScript.text = "console.log('CalendarPort >> addScript.text');";
    addScript.async = true;
    //addScript.onload = () => {
      //reactOnLoad();
    //};

    // Append scripts to iframe.
    var iframe = document.getElementById(this.state.id);
    var iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
    iframeDoc.body.appendChild(addScript);
    */
  }

  setDate = (d) => {
    console.log("CalendarPort >> setDate() >> ", d);
    try {
      if (d) {
        this.setState({ date: d });
      } else {
        this.setState({ date: new Date() });
      }
    } catch (err) {
      this.setState({ date: new Date() });
    }
    finally {
      return this.state.date;
    }
  }

  getDate = () => {
    console.log("CalendarPort >> getDate()");
    return this.state.date;
  }

  changeCalendar(event) {
    console.log("CalendarPort >> changeCalendar() >> ", event);
    console.log(event.target.value)
    console.log(this.state.covid)

    // If same option selected as previously selected, do nothing.
    if (event.target.value == this.state.input ) {
      console.log("CalendarPort >> changeCalendar() >> DO NOTHING");
    }

    // Toggle to market calendar, if location was previously selected.
    else if (event.target.value == "market" && this.state.covid === false) {
      console.log("CalendarPort >> changeCalendar() >> TOGGLE MARKET");
      this.setState({
        header: 'Promotion Calendar - By Market',
        src: '/legacy/CalendarPort_Mkt.html',
        id: 'SD2-CalendarPort-Mkt',
        input: event.target.value
      });
      //this.iframe.current.refreshIframe();
    }

    // Toggle to location calendar, if market was previously selected.
    else if (event.target.value == "location" && this.state.covid === false) {
      console.log("CalendarPort >> changeCalendar() >> TOGGLE LOCATION")
      this.setState({
        header: 'Promotion Calendar - By Location',
        src: '/legacy/CalendarPort_Loc.html',
        id: 'SD2-CalendarPort-Loc',
        input: event.target.value
      });
      console.log(event.target.value)
      //this.iframe.current.refreshIframe();
    }
    //this.setDate(); // DEBUG TEST

    // Toggle to market calendar, if location was previously selected.
    else if (event.target.value == "market" && this.state.covid === true) {
      console.log("CalendarPort >> changeCalendar() >> TOGGLE MARKET");
      this.setState({
        header: 'Promotion Calendar - By Market',
        src: '/legacy/CalendarPort_Mkt_Covid.html',
        id: 'SD2-CalendarPort-Mkt',
        input: event.target.value
      });
      //this.iframe.current.refreshIframe();
    }

    // Toggle to location calendar, if market was previously selected.
    else if (event.target.value == "location" && this.state.covid === true) {
      console.log("CalendarPort >> changeCalendar() >> TOGGLE LOCATION")
      this.setState({
        header: 'Promotion Calendar - By Location',
        src: '/legacy/CalendarPort_Loc_Covid.html',
        id: 'SD2-CalendarPort-Loc',
        input: event.target.value
      });
      console.log(event.target.value)
      //this.iframe.current.refreshIframe();
    }
    //this.setDate(); // DEBUG TEST
  }

  handleToggleCovid = (e) => {
    console.log(this.state.covid)

    if(this.state.input === 'location' && this.state.covid === false) {
      this.setState({
        covid: !this.state.covid,
        header: `Promotion Calendar - By ${this.state.input.charAt(0).toUpperCase() + this.state.input.slice(1)}`,
        src: '/legacy/CalendarPort_Loc_Covid.html',
        id: 'SD2-CalendarPort-Loc-Covid',
      })
    } else if(this.state.input === 'market' && this.state.covid === false){
      this.setState({
        covid: !this.state.covid,
        header: `Promotion Calendar - By ${this.state.input.charAt(0).toUpperCase() + this.state.input.slice(1)}`,
        src: '/legacy/CalendarPort_Mkt_Covid.html',
        id: 'SD2-CalendarPort-Mkt-Covid',
      })
    } else if(this.state.input === 'location' && this.state.covid === true) {
      this.setState({
        covid: !this.state.covid,
        header: 'Promotion Calendar - By Location',
        src: '/legacy/CalendarPort_Loc.html',
        id: 'SD2-CalendarPort-Loc',
      })
    } else {
      this.setState({
        covid: !this.state.covid,
        header: 'Promotion Calendar - By Market',
        src: '/legacy/CalendarPort_Mkt.html',
        id: 'SD2-CalendarPort-Mkt',
      })
    }

    // this.setState({
    //   covid: !this.state.covid,
    //   header: `Promotion Calendar - By ${this.state.input.charAt(0).toUpperCase() + this.state.input.slice(1)}`,
    //   src: '/'
    // })
  }

  render() {
    console.log("CalendarPort >> RENDER >>", this.props);

    const { classes } = this.props;

    let calendarMonth = document.getElementsByTagName('h2')[0]
    console.log(calendarMonth)

    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="CalendarPort">
          <h1>Something went wrong.</h1>
        </div>
      );
    }

    console.log('*********************', this.state.src)
    // Set up list of calendars, to toggle between.
    let calendar_list = [];
    switch(this.state.input && this.state.covid) {
      case 'location' && false :
        calendar_list.push(
          <IframeEmbed
            ref={this.iframe}
            key={this.state.id}
            id={this.state.id}
            source={this.state.src}
            iframeOnLoad={this.iframeOnLoad}
            iframeActions={{
              setDate: () => this.setDate(),
              getDate: () => this.getDate()
            }}
          />
        );
        break;
      case 'market' && false:
      default:
        calendar_list.push(
          <IframeEmbed
            ref={this.iframe}
            key={this.state.id}
            id={this.state.id}
            source={this.state.src}
            iframeOnLoad={this.iframeOnLoad}
            iframeActions={{
              setDate: () => this.setDate(),
              getDate: () => this.getDate()
            }}
          />
        );
        break;
      case 'location' && true:
        calendar_list.push(
          <IframeEmbed
            ref={this.iframe}
            key={this.state.id}
            id={this.state.id}
            source={this.state.src}
            iframeOnLoad={this.iframeOnLoad}
            iframeActions={{
              setDate: () => this.setDate(),
              getDate: () => this.getDate()
            }}
          />
        );
        break;
      case 'market' && true:
        calendar_list.push(
          <IframeEmbed
            ref={this.iframe}
            key={this.state.id}
            id={this.state.id}
            source={this.state.src}
            iframeOnLoad={this.iframeOnLoad}
            iframeActions={{
              setDate: () => this.setDate(),
              getDate: () => this.getDate()
            }}
          />
        );
        break;
    }

    /* &emsp;<font color="red"><small>{this.state.date.toString()}</small></font> */ // DEBUG TEST

    // Render page.
    return (
      <div className="CalendarPort">
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItem: 'center', marginTop: '35px'}}>
          <Typography className={this.props.darkMode ? classes.pageTitleDark : classes.pageTitle} style={{marginTop: '10px'}}>
            <TodayIcon className={this.props.darkMode ? classes.pageIconDark : classes.pageIcon} />
            <span style={{marginLeft: '5px'}}>{this.state.header.toUpperCase()}</span>
          </Typography>
          <span style={{display: 'flex', flexDirection: 'row', alignItems: 'center', "float":"right"}}>
            {/* <Button
              variant="outlined"
              // style={{marginRight: '10px', height: 56, marginTop: '-16px'}}
              onClick={this.handleToggleCovid}
              className={this.state.covid ? classes.activeButton : classes.button}
            >
              2 YEAR SALES TRENDS
            </Button> */}
            <FormControlLabel
              control={<CustomSwitch checked={this.state.covid} onChange={this.handleToggleCovid} name="toggleCovid" />}
              label={
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <Tooltip title={<Typography style={{fontSize: '12px'}}>For Example: 2019 - 2021</Typography>}>
                    <InfoOutlinedIcon className={classes.infoIcon} />
                  </Tooltip>
                  <Typography>2 YEARS SALES TRENDS</Typography>
                  {/* <Typography variant="caption">i.e: 2019 - 2021</Typography> */}
                </div>
              }
              labelPlacement="start"
              style={{marginRight: '10px', border: '1px solid #c7c7c7', padding: '1em', borderRadius: 4, height: '56px', marginTop: '-16px'}}
            />
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="calendar-select">Change Calendar</InputLabel>
              <Select
                value={this.state.input}
                onChange={this.changeCalendar}
                labelId="calendar-select"
                label="Change Calendar"
              >
                <MenuItem value={"market"}>MARKET</MenuItem>
                <MenuItem value={"location"}>LOCATION</MenuItem>
              </Select>
            </FormControl>
          </span>
        </div>
        {/* <div style={this.state.covid ? {textAlign: 'center', border: '1px solid #bd4c46', padding: '2em'} : {display: 'none'}}>
          Due to the Covid-19 Pandemic in the year 2020, the Calendar has been adjusted to calculate the Sales changes from 2021 and 2019
        </div> */}
        <br/>
        {calendar_list}
      </div>
    );
  }
}

export default withStyles(styles)(CalendarPort)
//export default (CalendarPort)
