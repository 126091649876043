import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography, CircularProgress } from '@material-ui/core'
import DonutChart from '../charts/DonutChart'
import Cookies from 'js-cookie'
import roiStandIn from '../../assets/homeStandIns/roiStandIn.png'

import moment from 'moment'

const today = moment().format('YYYY-MM-DD')
const twelveMonthsPast = moment().subtract(12, 'months').format('YYYY-MM-DD')

const styles = theme => ({
  root: {
    width: '100%'
  },
  data: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'space-evenly'
  },
  stats: {
    fontWeight: 'bold',
    color: '#626262',
    [theme.breakpoints.up('xl')]: {
      fontSize: 16
    }
  },
  statsDark: {
    fontWeight: 'bold',
    color: '#fff',
    [theme.breakpoints.up('xl')]: {
      fontSize: 16
    }
  },
  pie: {
    paddingTop: 40
  },
  divider: {
    opacity: 0.4,
  },
  customWidth: {
    maxWidth: 500,
  },
  loader: {
    marginLeft: '45%',
    marginTop: '30%',
  },
  standIn: {
    width: '100%',
    [theme.breakpoints.up('xl')]: {
      marginTop: '-50px',
      height: '35%',
      width: '85%'
    },
    [theme.breakpoints.down('lg')]: {
      width: '120%',
      marginTop: '-35px'
    }
  },
  unavailable: {
    position: 'absolute',
    textAlign: 'center',
    marginTop: '100px'
  }
})

class TriggersMod extends Component {
  _isMounted = false

  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      trigRoi: {},
    }
  }

  componentDidMount() {
    this._isMounted = true

    fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI?startDate=${twelveMonthsPast}&endDate=${today}`, {
      method: 'GET',
      headers: {
        'token': Cookies.get('token')
      },
    })
    .then(response => response.json())
    .then(data => {
      if(this._isMounted && data) {
        this.setState({
          isLoading: false,
          trigRoi: data[0].Data[0],
        })
      } else {
        this.setState({
          isLoading: false,
          trigRoi: {}
        })
      }
    })
    .catch(error => {
      console.log(error)
    })
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    const { classes } = this.props

    return(
      <div className={classes.root}>
        {this.state.isLoading ? <div className={classes.loader}><CircularProgress /></div> :
          Object.keys(this.state.trigRoi).length > 0 ?
          <Fragment>
            <Typography style={{fontSize: '12px'}} align="center">
              On completed promotions, a total spend of <span style={this.props.darkMode ? {color: 'rgba(255, 115, 115, 0.7)'} : {color: 'rgba(255, 0, 0, 0.7)'}}>${Math.round(this.state.trigRoi.Cost).toLocaleString('en')}</span> has yielded <span style={{color: 'rgba(113, 177, 40)', fontWeight:'bold'}}>${Math.round(this.state.trigRoi.Sales).toLocaleString('en')}</span> in sales to responders (at a {(this.state.trigRoi.Res_Rate * 100).toFixed(2)}% response rate).
            </Typography>
            <div className={classes.data}>
              <div style={{ display: 'block', textAlign: 'left', marginLeft: '20px'}}>
                <div>
                  <h5 style={{opacity: 0.8, color: '#05b3ad'}}>AVG TICKET</h5>
                  <span className={this.props.darkMode ? classes.statsDark : classes.stats}>${Math.round(this.state.trigRoi.Sales / this.state.trigRoi.Responses).toLocaleString('en')}</span>
                </div>
                <hr className={classes.divider}/>
                <div>
                  <h5 style={{opacity: 1.0, color: '#529c00'}}>INC SALES</h5>
                  <span className={this.props.darkMode ? classes.statsDark : classes.stats}>${Math.round(this.state.trigRoi.ADJ_Sales).toLocaleString('en')}</span>
                </div>
                <hr className={classes.divider}/>
                <div >
                  <h5 style={this.props.darkMode ? {opacity: 0.8, color: '#faa2f4'} : {opacity: 0.8, color: '#942e92'}}>INC RESPONSES</h5>
                  <span className={this.props.darkMode ? classes.statsDark : classes.stats}>{Math.round(this.state.trigRoi.ADJ_Resp).toLocaleString('en')}</span>
                </div>
              </div>
              <div className={classes.pie}>
                <DonutChart
                  // total={'Total Sales'}
                  totalColor={'#529c00'}
                  labels={['Total Spend', 'Incremental Sales']}
                  data={[Math.round(this.state.trigRoi.Cost), Math.round(this.state.trigRoi.ADJ_Sales)]}
                  colors={['rgba(235, 82, 82, 0.9)', 'rgba(110, 209, 0, 0.9)']}
                  radius={'85%'}
                  labelShow={true}
                  legend={true}
                  position={'bottom'}
                  height={250}
                  darkMode={this.props.darkMode}
                />
              </div>
            </div>
          </Fragment>
          : <div style={{textAlign: 'center', position: 'relative'}}>
              <div className={classes.unavailable}>
                <Typography style={{fontSize: '17px', color: '#0c5ab4', fontWeight: 'bold'}}>COMING SOON</Typography>
                <Typography variant="caption">This screen is unavailable at this time. For more information please contact Support at <a href="mailto:support@lsdirect.com">support@lsdirect.com</a></Typography>
              </div>
              <img className={classes.standIn} src={roiStandIn} alt=""/>
            </div>
        }
      </div>
    )
  }
}

export default withStyles(styles)(TriggersMod)
