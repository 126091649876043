import React, { Component } from 'react'
import clsx from 'clsx'
import {
  withStyles,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Typography,
  Paper,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Link,
  Drawer,
  Divider,
  Button,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import Cookies from 'js-cookie'
import BarChart from '../charts/BarChart2'
import ResponseDemoChart from './ResponseDemoChart'
import ComparePanel from './PromoPanel'
import moment from 'moment'

const styles = theme => ({
  root: {
    height: '100%',
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  secondDrawer: {
    width: '50%',
    flexShrink: 0,
  },
  secondDrawerPaper: {
    width: '50%',
  },
  secondDrawerHeader: {
    display: 'flex',
    alignItems: 'right',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  hide: {
    display: 'none'
  },
  chartContent: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    display: 'block',
    justifyContent: 'center',
    marginRight: '-30%'
  },
  link: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  closeButton: {
    '&:hover': {
      color: '#eb838c',
      backgroundColor: 'transparent'
    }
  },
  formControl: {
    margin: theme.spacing(1),
    width: 250,
  },
  header: {
    fontWeight: 'bold'
  },
})

class CompareResponse extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedMetric: 'Age Range',
      dateExtended: 'Extended Date',
      rowsPerPage: 10,
      page: 0,
      respondersPctArray: [],
      customersBasePctArray: [],
    }
  }

  renderMetricSelectOptions = () => {
    return(
      this.props.demoData.filter(item => item.MetricType !== 'Mosaic Household Group').map(item => {
        return(<MenuItem key={item.Key} value={item.MetricType}>{item.MetricType}</MenuItem>)
      })
    )
  }

  handleMetricChange = e => {
    let newMetricType = e.target.value

    this.setState({
      selectedMetric: newMetricType
    })
  }

  // handleChangeRowsPerPage = e => {
  //   let value = e.target.value
  //   const rowsPerPage = value
  //
  //   this.setState({
  //     rowsPerPage: rowPerPage,
  //     page: 0,
  //   })
  // }

  handleChangePage = (e, newPage) => {
    this.setState({
      page: newPage
    })
  }

  handleDrawerClose = () => {
    this.props.handleDrawerClose()
  }

  getRespondersPct = () => {
    const metric = this.state.selectedMetric.split(' ').join('_')
    const respondersPct = []
    const respondersByMetricArray = []
    const selectedRespondersData = this.props.responseData.map(item => item[metric])
    console.log(this.props.demoData)
    const selectedDemoData = this.props.demoData.filter(item => item.MetricType === this.state.selectedMetric)[0].Data

    console.log(selectedDemoData.map(item => item.MetricValue))

    for(let i = 0; i < selectedDemoData.length; i++) {
      const respondersObj = {}
      respondersObj['metric'] = selectedDemoData[i].MetricValue
      respondersObj['data'] = []
      respondersByMetricArray.push(respondersObj)
      for(let j = 0; j < selectedRespondersData.length; j++) {
        if(selectedDemoData[i].MetricValue === selectedRespondersData[j]) {
          respondersByMetricArray[i].data.push(selectedRespondersData[j])
        }
      }
    }

    respondersPct.push(respondersByMetricArray.map(item => item.data.length / respondersByMetricArray.map(item => item.data.length).reduce((a,b) => a + b, 0) * 100))

    return respondersPct

  }

  getCustomersBasePct = () => {
    const customersBasePct = []
    const selectedDemoData = this.props.demoData.filter(item => item.MetricType === this.state.selectedMetric)[0].Data

    for(let i = 0; i < selectedDemoData.length; i++) {
      customersBasePct.push(selectedDemoData.map(item => item.Customers_Baseline)[i] / selectedDemoData.map(item => item.Customers_Baseline).reduce((a,b) =>  a + b, 0) * 100)
    }

    return customersBasePct
  }

  render() {
    const { classes } = this.props
    const series = []
    const respondersPctArray = this.getRespondersPct()
    const customersBasePctArray = this.getCustomersBasePct()
    const metricValues = this.props.demoData.filter(item => item.MetricType === this.state.selectedMetric)[0].Data.map(item => item.MetricValue)

    series.push(
      {
        name: '% of Responders to Event',
        type: 'column',
        data: respondersPctArray.map(item => item.map(item => parseFloat(item.toFixed(2))))[0]
      },
      {
        name: '% of Customers Baseline(1 Year)',
        type: 'column',
        data: customersBasePctArray.map(item => parseFloat(item.toFixed(2)))
      }
    )

    return(
      <div>
        {this.state.isLoading ?  <CircularProgress /> :
          <main
            className={classes.content}
          >
            {console.log(series)}
            <div>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <FormControl className={classes.formControl} variant="outlined">
                  <InputLabel id="metric-select">Metric Type</InputLabel>
                  <Select
                    id="metric"
                    labelId="metric-select"
                    value={this.state.selectedMetric}
                    onChange={this.handleMetricChange}
                    label="Metric Type"
                  >
                    {this.renderMetricSelectOptions()}
                  </Select>
                </FormControl>

                <div style={{textAlign: 'right', marginRight: '10px'}}>
                  <IconButton disableRipple size="small" onClick={this.handleDrawerClose} className={classes.closeButton}>
                    <CloseIcon fontSize="small" />
                    <Typography style={{marginLeft: '10px'}} variant="caption">Close</Typography>
                  </IconButton>
                </div>
              </div>
              <Divider style={{marginTop: '20px'}} />

              <Table
                className={classes.table}
                size="small"
              >
                <TableHead>
                  <TableRow style={{height: '60px'}}>
                    <TableCell style={{fontWeight: 'bold'}}>PROMOTION</TableCell>
                    <TableCell style={{fontWeight: 'bold'}} align="right">REPORT START</TableCell>
                    <TableCell style={{fontWeight: 'bold'}} align="right">REPORT END</TableCell>
                    <TableCell style={{fontWeight: 'bold'}} align="right">FORMAT</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow style={{height: '65px'}}>
                    <TableCell>{this.props.jobData[0].Promotion}</TableCell>
                    <TableCell align="right">
                      {this.props.jobData[0].Data_Extended[0].PromoStartDate}
                      {/* {this.state.dateExtended === 'Extended Date'
                      ? this.props.data[0].Data_Extended[0].PromoStartDate
                      : this.props.data[0].Data_Promo[0].PromoStartDate} */}
                    </TableCell>
                    <TableCell align="right">
                      {this.props.jobData[0].Data_Extended[0].PromoEndDate}
                      {/* {this.state.dateExtended === 'Extended Date'
                      ? this.props.data[0].Data_Extended[0].PromoEndDate
                      : this.props.data[0].Data_Promo[0].PromoEndDate} */}
                    </TableCell>
                    <TableCell align="right">{this.props.jobData[0].Format_Desc}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <br/>
              <div
                className={clsx(classes.chartContent, {[classes.chartShift]: this.state.compareOpen})}
                style={{padding: '2em', width: '100%'}}
              >
                <ResponseDemoChart
                  data={series}
                  height={400}
                  // width={this.state.compareOpen ? '50%' : '100%'}
                  // width={this.state.width}
                  comparePanel={this.state.comparePanel}
                  colors={['#00c9db', '#2f5a7a']}
                  darkMode={this.props.darkMode}
                  categories={metricValues}
                />
              </div>
              <br/>
              <br/>
              <Paper>
                <Table size="small" className={classes.table}>
                  <TableHead>
                    <TableRow style={{backgroundColor: '#3b80b8'}}>
                      <TableCell align="center" colSpan={12}><Typography variant="caption" style={{color: '#fff'}}>RESPONSES</Typography></TableCell>
                    </TableRow>
                    <TableRow style={{height: 60}}>
                      <TableCell><Typography variant="caption" className={classes.header}>Customer ID</Typography></TableCell>
                      <TableCell><Typography variant="caption" className={classes.header}>Trans Date</Typography></TableCell>
                      <TableCell><Typography variant="caption" className={classes.header}>Zip</Typography></TableCell>
                      <TableCell align="right"><Typography variant="caption" className={classes.header}>Age Range</Typography></TableCell>
                      <TableCell align="right"><Typography variant="caption" className={classes.header}>Ethnicity</Typography></TableCell>
                      {/* <TableCell align="right"><Typography variant="caption">Occupation</TableCell> */}
                      {/* <TableCell align="right" className={this.state.compareOpen ? classes.hide : classes.header}><Typography variant="caption">Education</Typography></TableCell> */}
                      <TableCell align="right"><Typography variant="caption" className={classes.header}>Gender</Typography></TableCell>
                      <TableCell align="right"><Typography variant="caption" className={classes.header}>Marital Status</Typography></TableCell>
                      <TableCell align="right"><Typography variant="caption" className={classes.header}>Credit Rating</Typography></TableCell>
                      {/* <TableCell align="right" className={this.state.compareOpen ? classes.hide : classes.header}><Typography variant="caption">Has Children</Typography></TableCell> */}
                      <TableCell align="right"><Typography variant="caption" className={classes.header}>Total Sales</Typography></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.props.responseData.sort((a,b) => moment(b.wrt_dat) - moment(a.wrt_dat))
                      .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                      .map(row => (
                      <TableRow key={row.Key} style={{height: 60}}>
                        <TableCell><Typography variant="caption">{row.db_customerID === 'Unknown' ? 'N/A' : row.db_customerID}</Typography></TableCell>
                        <TableCell><Typography variant="caption">{row.wrt_dat === 'Unknown' ? 'N/A' : moment(row.wrt_dat).format('MM/DD/YYYY')}</Typography></TableCell>
                        <TableCell><Typography variant="caption">{row.CASS_ZIP === 'Unknown' ? 'N/A' : row.CASS_ZIP}</Typography></TableCell>
                        <TableCell align="right"><Typography variant="caption">{row.Age_Range === 'Unknown' ? 'N/A' : row.Age_Range}</Typography></TableCell>
                        <TableCell align="right"><Typography variant="caption">{row.Ethnicity === 'Unknown' ? 'N/A' : row.Ethnicity}</Typography></TableCell>
                        {/* <TableCell align="right">{row.db_customerID}</TableCell> */}
                        {/* <TableCell align="right" className={this.state.compareOpen ? classes.hide : ''}><Typography variant="caption">{row.Education === 'Unknown' ? 'N/A' : row.Education}</Typography></TableCell> */}
                        <TableCell align="right"><Typography variant="caption">{row.Gender === 'Unknown' ? 'N/A' : row.Gender}</Typography></TableCell>
                        <TableCell align="right"><Typography variant="caption">{row.Marital_Status === 'Unknown' ? 'N/A' : row.Marital_Status}</Typography></TableCell>
                        <TableCell align="right"><Typography variant="caption">{row.Credit_Rating === 'Unknown' ? 'N/A' : row.Credit_Rating}</Typography></TableCell>
                        {/* <TableCell align="right" className={this.state.compareOpen ? classes.hide : ''}><Typography variant="caption">{row.Presence_of_Children === 'Unknown' ? 'N/A' : row.Presence_of_Children}</Typography></TableCell> */}
                        <TableCell align="right"><Typography variant="caption">{row.wrt_sale === 'Unknown' ? 'N/A' : '$' + parseFloat(row.wrt_sale.toFixed(2)).toLocaleString('en')}</Typography></TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <TablePagination
                  // rowsPerPage={[10, 20, 30]}
                  component="div"
                  count={this.props.responseData.length}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </Paper>
            </div>
          </main>
        }
      </div>
    )
  }
}

export default withStyles(styles)(CompareResponse)
