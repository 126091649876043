import React, { Component } from 'react'
import Calendar from 'rc-calendar'
import DatePicker from 'rc-calendar/lib/Picker'
// import 'rc-calendar/assets/index.css'
import '../../assets/calendar.css'
import moment from 'moment'


class Demo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showDateInput: true,
      open: false,
      value: props.defaultValue,
    }


    this.calendarContainerRef = React.createRef();
  }

  onChange = (value) => {
    console.log('DatePicker change: ', (value && value.format('YYYY-MM-DD')));
    this.setState({
      value,
    });
  }

  onShowDateInputChange = (e) => {
   this.setState({
     showDateInput: e.target.checked,
   });
 }

 onOpenChange = (open) => {
    this.setState({
      open,
    });
  }

  onFocus = () => {
    if (!this.state.open && this.state.isMouseDown) {
      // focus from a "click" event, let the picker trigger automatically open the calendar
      this.setState({
        isMouseDown: false,
      });
    } else {
      // focus not caused by "click" (such as programmatic or via keyboard)
      this.setState({
        open: true,
      });
    }
  }

  onMouseDown = () => {
    this.setState({
      isMouseDown: true,
    });
  }

  getCalendarContainer = () => this.calendarContainerRef.current;

  toggleDisabled = () => {
    this.setState({
      disabled: !this.state.disabled,
    });
  }

  render() {
    const state = this.state
    const calendar = (<Calendar
      style={{ zIndex: 1001 }}
      dateInputPlaceholder="please input"
      defaultValue={this.props.defaultCalendarValue}
      showDateInput={state.showDateInput}
      // disabledDate={disabledDate}
      focusablePanel={false}
    />)
    return(
      (<div style={{ width: 400, margin: 20 }}>
      <div style={{ marginBottom: 10 }}>
        <label>
          <input
            type="checkbox"
            checked={state.showTime}
            onChange={this.onShowTimeChange}
          />
          showTime
        </label>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <label>
          <input
            type="checkbox"
            checked={state.showDateInput}
            onChange={this.onShowDateInputChange}
          />
          showDateInput
        </label>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <label>
          <input
            checked={state.disabled}
            onChange={this.toggleDisabled}
            type="checkbox"
          />
          disabled
        </label>
      </div>
      <div style={{
        boxSizing: 'border-box',
        position: 'relative',
        display: 'block',
        lineHeight: 1.5,
        marginBottom: 22,
      }}
      >
        <DatePicker
          animation="slide-up"
          calendar={calendar}
          value={state.value}
          onChange={this.onChange}
          getCalendarContainer={this.getCalendarContainer}
          onOpenChange={this.onOpenChange}
          open={state.open}
          style={{ zIndex: 1001 }}
        >
          {
            ({ value }) => {
              return (
                <span tabIndex="0" onMouseDown={this.onMouseDown} onFocus={this.onFocus}>
                  <input
                    placeholder="please select"
                    style={{ width: 250 }}
                    disabled={state.disabled}
                    readOnly
                    tabIndex="-1"
                    className="ant-calendar-picker-input ant-input"
                    value={value && value.format("YYYY-MM-DD") || ''}
                  />
                  <div ref={this.calendarContainerRef} />
                </span>
              );
            }
          }
        </DatePicker>
      </div>
    </div>)
    )
  }

}

export default Demo
