import React, { Component } from 'react'
import styled from 'styled-components'
import {
  withStyles,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  Paper,
  Typography,
  Avatar,
  TextField,
  Select,
  Menu,
  MenuItem,
  Divider,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Grid,
  Snackbar,
  SnackbarContent,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
} from '@material-ui/core'
import {
  AddAPhoto,
  ArrowDropUp,
  ArrowDropDown,
  RadioButtonChecked,
  RadioButtonUnchecked,
  Error,
  Edit,
  CheckCircleOutline,
  History,
} from '@material-ui/icons'
import Cookies from 'js-cookie'

const styles = (theme) => ({
  // root: {
  //   width: '75%'
  // },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  yes: {
    color: '#00a323',
    borderColor: '#00a323',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#00a323',
    }
  },
  no: {
    color: '#b8413f',
    borderColor: '#b8413f',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#b8413f'
    }
  },
  error: {
    color: '#fff'
  }
})

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: #4ab8bb
    }
  }
`

const NextButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    border: '1px solid',
    color: '#4ab8bb',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#4ab8bb',
      borderColor: '#4ab8bb',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none'
    }
  }
})(Button)

const BackButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    border: 'none',
    color: '#4ab8bb',
    '&:hover': {
      color: '#21868a',
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none'
    }
  }
})(Button)

const RemovePicButton = withStyles({
  root: {
    color: '#4ab8bb',
    '&:hover': {
      color: '#21868a',
      backgroundColor: 'transparent',
    }
  }
})(IconButton)

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    width: '100%',
    maxWidth: 765,
    maxHeight: 400,
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left'
    }}
    {...props}
  />
))

const CustomCheckbox = withStyles({
  root: {
    '&$checked': {
      color: '#4ab8bb'
    }
  },
  checked: {},
})(props => <Checkbox color="default" {...props}/>)

class EditSalesPerson extends Component {
  constructor(props) {
    super(props)
    this.state = {
      avatar: '',
      uuid: '',
      salesConsultant: props.location.state.salesConsultant,
      activeStep: 0,
      locationData: [],
      locationValue: '',
      locationID: props.location.state.salesConsultant.LocationID,
      locationMenuOpen: false,
      anchor: null,
      inclusionStatus: props.location.state.salesConsultant.InclusionStatus,
      firstName: '',
      lastName: '',
      errorMessage: '',
      snackbar: false,
      openDialog: false,
      imageSelected: '',
      success: '',
    }
  }

  componentDidMount = () => {
    fetch('https://api.dev.lsdirect.com/smartdash/V1/index.cfm/salesperson/locations', {
      headers: {
        token: Cookies.get('token')
      },
    })
    .then(response => response.json())
    .then(data => {
      let location = data.filter(item => item.LocationID === this.state.salesConsultant.LocationID)
      this.setState({
        locationData: data,
        locationValue: location[0].Address1 + ', ' + location[0].City + ', ' + location[0].State + ', ' + location[0].ZIP
      })
    })
    .catch(error => {
      console.log(error)
    })
  }

  getStepContent = (step) => {
    switch(step) {
      case 0:
        return (
          <div style={{display: 'flex', marginTop: '20px', marginLeft: '35px'}}>
            <div>
              <Avatar
                src={this.state.avatar ? this.state.avatar : this.state.salesConsultant.ImgPath}
                style={{borderRadius: 0, height: 150, width: 130,}}
              />
              <br/>
              <form encType="multipart/form-data">
                <input
                  id="contained-button-file"
                  accept="image/*"
                  type="file"
                  onChange={this.handlePhotoSelect}
                  style={{display: 'none'}}
                />
              </form>
              <label htmlFor="contained-button-file">
                <Button
                  variant="outlined"
                  startIcon={<AddAPhoto style={{fontSize: '12px'}}/>}
                  component="span"
                >
                  <Typography variant="caption" style={{fontSize: '11px'}}>UPLOAD PHOTO</Typography>
                </Button>
              </label>
                <Tooltip title="Use Previous Photo">
                  <RemovePicButton
                    size="small"
                    onClick={this.handleRemovePic}
                    style={this.state.avatar ? {} : {display: 'none'}}
                  >
                    <History />
                  </RemovePicButton>
                </Tooltip>
            </div>
            <div style={{marginLeft: '20'}}>
              <Typography variant="caption" style={{marginLeft: 25, fontWeight: 'bold', fontSize: '13px'}}>UPLOAD A NEW IMAGE:</Typography>
              <ol>
                <li><Typography display="block" variant="caption">Click on the Upload Photo button</Typography></li>
                <li><Typography display="block" variant="caption">Choose your photo and click open</Typography></li>
              </ol>
            </div>
          </div>
        )
      case 1:
        return (
          <div style={{marginTop: '20px', marginLeft: '35px'}}>
            <div>
              <Typography variant="caption" style={{color: 'red'}}>** Enter First and Last Names exactly as they should appear on a card **</Typography>
            </div>
            <div style={{display: 'flex', width: '100%'}}>
              <div style={{width: 375}}>
                <Typography display="block" variant="caption">First Name:</Typography>
                <StyledTextField
                  id="fname"
                  size="small"
                  variant="outlined"
                  style={{width: '100%'}}
                  defaultValue={this.state.firstName ? this.state.firstName : this.state.salesConsultant.FirstName}
                />
              </div>
              <div style={{marginLeft: '15px', width: 375}}>
                <Typography display="block" variant="caption">Last Name:</Typography>
                <StyledTextField
                  id="lname"
                  size="small"
                  variant="outlined"
                  style={{width: '100%'}}
                  defaultValue={this.state.lastName ? this.state.lastName : this.state.salesConsultant.LastName}
                />
              </div>
            </div>
            <div style={{display: 'flex', marginTop: '10px', width: '100%'}}>
              <div style={{width: 765}}>
                <Typography display="block" variant="caption">Address:</Typography>
                <StyledTextField
                  id="location"
                  size="small"
                  name="location"
                  variant="outlined"
                  style={{width: '100%'}}
                  onClick={e => this.handleLocationMenuOpen(e)}
                  value={this.state.locationValue}
                  InputProps={
                    this.state.locationMenuOpen ?
                    {endAdornment: <InputAdornment position="end"><ArrowDropUp fontSize="small"/></InputAdornment>}
                    :
                    {endAdornment: <InputAdornment position="end"><ArrowDropDown fontSize="small"/></InputAdornment>}
                  }
                />
                <StyledMenu
                  anchorEl={this.state.anchor}
                  keepMounted
                  open={this.state.locationMenuOpen}
                  onClose={this.handleLocationMenuClose}
                >
                  <MenuItem onClick={e => this.handleSelectLocation(e)} data-my-value={'--- Please Select One ---'}>--- Please Select One ---</MenuItem>
                  {this.state.locationData.map(row => (
                    <MenuItem
                      key={row.Key}
                      onClick={e => this.handleSelectLocation(e, row.LocationID)}
                    >
                      {row.Address1 + ', ' + row.City + ', ' + row.State + ', ' + row.ZIP}
                    </MenuItem>
                  ))}
                </StyledMenu>
              </div>
            </div>
            <br/>
            <div>
              <Typography display="block" variant="caption">Inclusion Status:</Typography>
              <FormControlLabel
                value={1}
                control={
                  <CustomCheckbox
                    checked={this.state.inclusionStatus === 1}
                    onChange={this.handleSelectInclusion}
                    icon={<RadioButtonUnchecked />}
                    checkedIcon={<RadioButtonChecked />}
                    size="small"
                    disableRipple
                  />
                }
                label={<Typography variant="caption">Included</Typography>}
              />
              <FormControlLabel
                value={2}
                control={
                  <CustomCheckbox
                    checked={this.state.inclusionStatus === 2}
                    onChange={this.handleSelectInclusion}
                    icon={<RadioButtonUnchecked />}
                    checkedIcon={<RadioButtonChecked />}
                    size="small"
                    disableRipple
                  />
                }
                label={<Typography variant="caption">Excluded</Typography>}
              />
            </div>
          </div>
        )
      case 2:
        return (
          <div style={{display: 'flex', marginTop: '20px', marginLeft: '35px'}}>
            <div>
              <Typography
                variant="caption"
                // style={{color: '#b8413f'}}
              >
                Please review the information listed below to make sure everything is correct
              </Typography>
              <br/>
              <div style={{ display: 'flex', marginTop: '15px', justifyContent: 'space-evenly'}}>
                <Avatar
                  src={this.state.avatar ? this.state.avatar : this.state.salesConsultantImg}
                  style={{borderRadius: 0, height: 150, width: 130,}}
                  className="image"
                />
                <Paper style={{ width: 550, marginLeft: '25px', borderRadius: 0}}>
                  <div style={{float: 'right', marginRight: '5px', marginTop: '5px'}}>
                    <IconButton size="small" onClick={this.handleEdit}>
                      <Tooltip title="Edit">
                        <Edit fontSize="small"/>
                      </Tooltip>
                    </IconButton>
                  </div>
                  <div style={{marginLeft: '10px', marginTop: '5px'}}>
                    <Typography variant="caption" style={{color: 'red'}}>** First and Last Names will be displayed exactly as they are shown below **</Typography>
                  </div>
                  <div style={{display: 'flex', marginTop: '20px'}}>
                    <div style={{marginLeft: '25px', width: 210}}>
                      <Typography variant="caption" style={{color: '#b5b5b5'}}>First Name:</Typography>
                      <Typography>{this.state.firstName}</Typography>
                    </div>
                    <div style={{marginLeft: '25px', width: 210}}>
                      <Typography variant="caption" style={{color: '#b5b5b5'}}>Last Name:</Typography>
                      <Typography>{this.state.lastName}</Typography>
                    </div>
                  </div>
                  <div style={{display: 'flex', marginTop: '15px'}}>
                    <div style={{marginLeft: '25px', width: 210}}>
                      <Typography variant="caption" style={{color: '#b5b5b5'}}>Address:</Typography>
                      <Typography>{this.state.locationData.filter(location => location.LocationID === this.state.locationID).map(item => item.Address1)[0]}</Typography>
                    </div>
                    <div style={{marginLeft: '25px', width: 150}}>
                      <Typography variant="caption" style={{color: '#b5b5b5'}}>State:</Typography>
                      <Typography>{this.state.locationData.filter(location => location.LocationID === this.state.locationID).map(item => item.State)[0]}</Typography>
                    </div>
                    <div style={{marginLeft: '25px', width: 100}}>
                      <Typography variant="caption" style={{color: '#b5b5b5'}}>Zip Code:</Typography>
                      <Typography>{this.state.locationData.filter(location => location.LocationID === this.state.locationID).map(item => item.ZIP)[0]}</Typography>
                    </div>
                  </div>
                </Paper>
              </div>
              <div style={{float: 'right'}}>
                <div style={{display: 'flex', verticalAlign: 'text-bottom'}}>
                  <Typography style={{marginTop: '3.4px', marginRight: '15px', color: '#b5b5b5'}} variant="caption">Inclusion Status:</Typography>
                  {this.state.inclusionStatus === 1 ?
                    <Typography style={{color: '#00a323'}}>Included</Typography>
                    :
                    <Typography style={{color: '#b8413f'}}>Excluded</Typography>
                  }
                </div>
              </div>
            </div>
          </div>
        )
      default:
      return 'Unknown Step'
    }
  }

  handlePhotoSelect = (e) => {
    this.setState({
      selectedImage: e.target.files[0],
      avatar: URL.createObjectURL(e.target.files[0])
    })
  }

  handleBack = () => {
    this.setState({
      activeStep: this.state.activeStep - 1
    })
  }

  handleNext = () => {
    if(this.state.activeStep === 0) {
      if(this.state.selectedImage) {
        const fd = new FormData()
        fd.append('imgPath', this.state.selectedImage, this.state.selectedImage.name)

        fetch(`https://api.v2.smartdash.com/smartdash/v1/index.cfm/salesperson/uploadImage`, {
          method: 'POST',
          headers: {

            'Access-Control-Allow-Origin': '*',
            'token': Cookies.get('token'),
          },
          body: fd
        })
        .then(response => {
          if(response.ok === true) {
            return response.json()
          }
        })
        .then(data => {
          if(data) {
            this.setState({
              uuid: data.uuid,
              activeStep: this.state.activeStep + 1,
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
      } else if( this.state.salesConsultant.UUID ) {
        this.setState({
          activeStep: this.state.activeStep + 1
        })
      } else {
        this.setState({
          errorMessage: 'Please upload a photo to continue'
        })
      }
    } else if(this.state.activeStep === 1) {
      let fname = document.querySelector('#fname')
      let lname = document.querySelector('#lname')
      if(fname && lname) {
        this.setState({
          firstName: fname.value,
          lastName: lname.value,
          activeStep: this.state.activeStep + 1
        })
      } else {
        this.setState({
          snackbar: true,
          errorMessage: 'Please make sure everything is filled out correctly'
        })
      }
    } else {
      this.setState({
        openDialog: true,
      })
    }
    // this.setState({
    //   activeStep: this.state.activeStep + 1
    // })
  }

  handleRemovePic = () => {
    this.setState({
      avatar: '',
      selectedImage: '',
    })
  }

  handleNameChange = (e) => {
    if(e.target.id === 'fname') {
      this.setState({
        firstName: e.target.value
      })
    } else {
      this.setState({
        lastName: e.target.value
      })
    }
  }

  handleLocationMenuOpen = (e) => {
    this.setState({
      anchor: e.currentTarget,
      locationMenuOpen: true,
    })
  }

  handleSelectLocation = (e, value) => {
    if(value) {
      let selectedLocation = this.state.locationData.filter(location => location.LocationID === value)[0]
      this.setState({
        locationID: value,
        locationValue: selectedLocation.Address1 + ', ' + selectedLocation.City + ', ' + selectedLocation.State + ', ' + selectedLocation.ZIP
      })
      this.handleLocationMenuClose()
    }
  }

  handleSelectInclusion = (e) => {
    this.setState({
      inclusionStatus: parseInt(e.target.value)
    })
  }

  handleLocationMenuClose = () => {
    this.setState({
      locationMenuOpen: false
    })
  }

  handleCloseDialog = () => {
    this.setState({
      openDialog: false
    })
  }

  handleSubmit = () => {
    const pendingUpdates = {
      'locationID': this.state.locationID,
      'lastName': this.state.lastName,
      'salespersonID': this.state.salesConsultant.SalespersonID,
      'firstName': this.state.firstName,
      'uuid': this.state.uuid ? this.state.uuid : this.state.salesConsultant.UUID,
      'inclusionStatus': this.state.inclusionStatus,
    }
    fetch('https://api.dev.lsdirect.com/smartdash/V1/index.cfm/salesperson/update', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'token': Cookies.get('token'),
      },
      body: JSON.stringify(pendingUpdates)
    })
    .then(response => {
      if(response.ok === true) {
        this.setState({
          snackbar: true,
          success: true,
          message: 'Sales Consultant has been successfully updated!'
        })
        setTimeout(() => this.handleRedirect(), 1000)
      } else {
        this.setState({
          snackbar: true,
          errorMessage: 'Oops, something went wrong. Please check to make sure everything is filled out correctly and try again'
        })
      }
    })
    .then(data => {
      console.log(data)
    })
    .catch(error => {
      console.log(error)
    })

    this.handleCloseDialog()
  }

  handleRedirect = () => {
    this.props.history.push('/sales-consultants')
  }

  render() {
    const { classes } = this.props
    const steps = ['Update Consultant Photo', 'Update Consultant Info', 'Review / Submit Changes']
    return(
      <div>
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{height: '90vh', marginLeft: '-15px'}}
        >
          <Snackbar
            open={this.state.snackbar}
            autoHideDuration={4500}
            onClose={this.handleSnackbarClose}
            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
          >
            <SnackbarContent
              message={this.state.success ?

                <div style={{display: 'flex'}}>
                  <CheckCircleOutline className={classes.error} fontSize="small" fontWeight="bold"/>
                  <span style={{fontWeight: 600, marginLeft: '15px'}}>{this.state.message}</span>
                </div>
              :
                <div style={{display: 'flex'}}>
                  <Error className={classes.error} fontSize="small" fontWeight="bold"/>
                  <span style={{fontWeight: 600, marginLeft: '15px'}}>{this.state.errorMessage}</span>
                </div>
              }
              style={this.state.success ? {backgroundColor: '#00a323'} : {backgroundColor: '#ed263d'}}
            />
          </Snackbar>
          <Dialog
            onClose={this.handleCloseDialog}
            open={this.state.openDialog}
            fullWidth={true}
            maxWidth={'sm'}
          >
            <DialogTitle style={{backgroundColor: '#0076ba', color: '#fff', height: 50}}><Typography variant="overline">Submit Changes</Typography></DialogTitle>
            <div style={{margin: '1em'}}>
              <Typography variant="caption">Are you sure you want to make these changes?</Typography>
            </div>
            <div style={{textAlign: 'right', marginRight: '15px', marginBottom: '15px'}}>

              <Button style={{marginRight: '10px'}} variant="outlined" className={classes.yes} onClick={this.handleSubmit}>Yes</Button>
              <Button variant="outlined" className={classes.no} onClick={this.handleCloseDialog}>No</Button>

            </div>
          </Dialog>
          <Stepper
            activeStep={this.state.activeStep}
            orientation="vertical"
            style={{width: 950}}
          >
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
                <StepContent>
                  {this.getStepContent(index)}
                  <br/>
                  <BackButton
                    disabled={this.state.activeStep === 0}
                    onClick={this.handleBack}
                    className={classes.button}
                  >
                    Back
                  </BackButton>
                  <NextButton
                    // disabled={this.state.activeStep === 0 && this.state.imgUploaded === false}
                    variant="outlined"
                    onClick={this.handleNext}
                    className={classes.button}
                  >
                    {this.state.activeStep < 2 ? 'Next' : 'Submit'}
                  </NextButton>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </Grid>
      </div>
    )
  }
}

export default withStyles(styles)(EditSalesPerson)
