import React, { Component } from 'react'
import styled from 'styled-components'
import {
  withStyles,
  GridList,
  Card,
  CardHeader,
  Avatar,
  Typography,
  Link,
  CardContent,
  FormGroup,
  FormControlLabel,
  Switch,
  Checkbox,
  Button,
  ButtonBase,
  Paper,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Modal,
  Fade,
  Backdrop,
} from '@material-ui/core'
// import Autocomplete from '@material-ui/lab/Autocomplete'
// import createSvgIcon from '@material-ui/icons/utils/createSvgIcon'
import EqualizerIcon from '@material-ui/icons/Equalizer'
import SearchIcon from '@material-ui/icons/Search'
import AppsIcon from '@material-ui/icons/Apps'
import tableauImg from '../assets/tableauSampleImg.png'
import DnsIcon from '@material-ui/icons/Dns'
import { grey } from '@material-ui/core/colors'
import Cookies from 'js-cookie'

const styles = theme => ({
  grid: theme.spacing(2),
  button: {
    color: '#e66a25',
    borderColor: '#e66a25',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#ffa44d',
      borderColor: '#ffa44d'
    }
  },
  pageTitle: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '18px',
    fontWeight: 'light',
    color: 'rgba(115, 115, 115, 0.9)'
  },
  pageIcon: {
    fontSize: '15px',
    color: 'rgba(115, 115, 115, 0.9)',
    marginTop: '5px',
    marginRight: '2px',
  },
  filters: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 200,
    // backgroundColor: '#e0e0e0',
    backgroundColor: 'transparent',
    border: '1px solid #e0e0e0'
  },
  search: {
    marigin: '1em',
  },
  select: {
    color: 'rgba(115, 115, 115, 0.9)',
    marginLeft: '5px',
    marginTop: '14px',
    width: 150,
    '&:active': {
      color: '#000'
    }
  },
  // modal: {
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   // marginLeft: '50%'
  // },
  // modalImg: {
  //   maxWidth: '95%',
  //   maxHeight: '95%',
  // },
})

const CustSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    // border: '1px solid #e66a25',
    color: '#e87d7d',
    '&$checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + $track': {
        opacity: 1,
        backgroundColor: '#8acc7e',
        borderColor: '#fff',
      },
    }
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
    // color: '#e66a25'
  },
  track: {
    border: '1px solid #e87d7d',
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: '#fff',
  },
  checked: {},
}))(Switch)

const StyledTextField = styled(TextField)`
  label.Mui-focused {
    color: #1d3d74
  }
  .MuiOutlinedInput-root {
    fieldset {
      ${'' /* height: 45px */}
    }
    &.Mui-focused fieldset {
      border-color: #1d3d74
    }
  }
`
const StyledSelect = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'transparent',
    border: '1px solid #bfbfbf',
    // height: 33,
    fontSize: 16,
    padding: '17.5px 26px 17px 26px',
    // transition: theme.transition.create(['border-color']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#1d3d74',
      // boxShadow: '0 0 0 0.2rem rgba()'
    }
  }
}))(InputBase)


class TableauCatalog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tableauScreens: JSON.parse(localStorage.getItem('tabScreens')),
      checkedItems: [],
      search: '',
      active: 'All',
      viewActive: JSON.parse(localStorage.getItem('tabScreens')),
      page: 0,
      rowsPerPage: 20,
      currentUser: JSON.parse(localStorage.getItem('currentUser'))[0].admin_level,
      modalOpen: false,
      modalImg: '',
    }
  }

  componentDidMount = () => {
    this.setState({
      checkedItems: this.state.viewActive.filter(item => item.access === 1),
    })
  }

  handleChecked = (e, row) => {
    let data = {
      'tableauID': row.ID,
      'access': row.access === 0 ? 1 : 0
    }
    this.setState({
      checkedItems: this.state.checkedItems.includes(row) ?
      this.state.checkedItems.filter(c => c !== row) :
      [...this.state.checkedItems, row]
    })
  }

  addToNav = () => {
    const accessZero = this.state.viewActive.filter(row => this.state.checkedItems.indexOf(row) === -1)
    const accessOne = this.state.viewActive.filter(row => this.state.checkedItems.indexOf(row) !== -1)
    const changed = this.state.viewActive.filter(row => row.access === 1).filter(row => accessZero.indexOf(row) !== -1)
    const changedOne = this.state.viewActive.filter(row => row.access === 0).filter(row => accessOne.indexOf(row) !== -1)

    if(changed.length > 0) {
      for(let i = 0; i < changed.length; i++) {
        console.log(changed[i])
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/admin/tableau/setAccess?tableauID=${changed[i].ID}&access=0`,{
          method: 'POST',
          headers: {
            'token': Cookies.get('token')
          },
        })
        .then(response => {
            if(response.ok === true) {
              return response.json()
            }
        })
        .then(data => {
          console.log(data)
        })
        .catch(error => {
          console.log(error)
        })
      }

    } else if(changedOne.length > 0) {
      for(let j = 0; j < changedOne.length; j++) {
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/admin/tableau/setAccess?tableauID=${changedOne[j].ID}&access=1`,{
          method: 'POST',
          headers: {
            'token': Cookies.get('token')
          },
        })
        .then(response => {
          if(response.ok === true) {
            console.log(response.json())
          }
        })
        .then(data => {
          console.log(data)
        })
        .catch(error => {
          console.log(error)
        })
      }
    } else if(accessOne.length > 0) {
      for(let k = 0; k < accessOne.length; k++) {
        console.log(accessOne[k])
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/admin/tableau/setAccess?tableauID=${accessOne[k].ID}&access=1`,{
          method: 'POST',
          headers: {
            'token': Cookies.get('token')
          },
        })
        .then(response => {
          if(response.ok === true) {
            console.log(response.json())
          }
        })
        .then(data => {
          console.log(data)
        })
        .catch(error => {
          console.log(error)
        })
      }
    } else if(accessZero.length > 0) {
      for(let l = 0; l < accessZero.length; l++) {
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/admin/tableau/setAccess?tableauID=${accessZero[l].ID}&access=0`,{
          method: 'POST',
          headers: {
            'token': Cookies.get('token')
          },
        })
        .then(response => {
          if(response.ok === true) {
            console.log(response.json())
          }
        })
        .then(data => {
          console.log(data)
        })
        .catch(error => {
          console.log(error)
        })
      }
    } else {
      console.log('')
    }

    for(let x = 0; x < accessOne.length; x++) {
      if(accessOne[x].access !== 1) {
        accessOne[x].access = 1
      }
    }

    for(let x = 0; x < accessZero.length; x++) {
      if(accessZero[x].access !== 0) {
        accessZero[x].access = 0

      }
    }

    const updatedTabList = accessZero.concat(accessOne)
    localStorage.setItem('tabScreens', JSON.stringify(updatedTabList))
    // console.log(updatedTabList)
  }

  updateSearch(e) {
    if(e.target.value) {
      this.setState({
        search: e.target.value.substr(0, 25),
        page: 0
      })
    } else {
      this.setState({
        search: '',
      })
    }
  }

  handleFilterChange = e => {
    if(e.target.value === 'All') {
      this.setState({
        viewActive: this.state.tableauScreens
      })
    } else {
      this.setState({
        viewActive: this.state.tableauScreens.filter(row => row.access === e.target.value),
        checkedItems: this.state.tableauScreens.filter(row => row.access === 1),
      })
    }
    this.setState({
      active: e.target.value,
    })
  }

  handleChangePage = (e, newPage) => {
    this.setState({
      page: newPage
    })
  }

  handleChangeRowsPerPage = e => {
    let value = e.target.value
    const rowsPerPage = value === 'Show All' ? this.state.tableauScreens.length : value

    this.setState({
      rowsPerPage: rowsPerPage,
      page: 0
    })
  }

  // handleOpen = (e) => {
  //   this.setState({
  //     modalOpen: true,
  //     modalImg: e.target.src,
  //   })
  // }
  //
  // handleClose = () => {
  //   this.setState({
  //     modalOpen: false,
  //     modalImg: '',
  //   })
  // }

  render() {
    const { classes } = this.props
    const baseURL = 'https://my.smartdash.com/v2/thumbnails/'

    let filteredItems = this.state.viewActive.filter(
      (item) => {
        return item.screen_name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
      }
    ) || this.state.tableauScreens.filter(
      (item) => {
        return item.screen_name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
      }
    )
    return(

      <div className={classes.grid}>
        <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '20px'}}>
          <Typography className={classes.title}>
            <br />
            <AppsIcon className={classes.pageIcon} />
            DASHBOARD CATALOG
          </Typography>
        </div>

        <Modal
          className={classes.modal}
          open={this.state.modalOpen}
          onClose={this.handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={this.state.modalOpen}>
            <img className={classes.modalImg} src={this.state.modalImg} alt="" />
          </Fade>
        </Modal>

        <div>
          <Paper className={classes.filters}>
            <div>
              <StyledTextField
                style={{margin: '1em'}}
                variant="outlined"
                label="Search"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon style={{color: '#727272'}} fontSize="small" />
                    </InputAdornment>
                  )
                }}
                value={this.state.search}
                onChange={this.updateSearch.bind(this)}
              />
              <Select
                value={this.state.active}
                variant="outlined"
                inputProps={{
                  name: 'date'
                }}
                input={<StyledSelect />}
                onChange={this.handleFilterChange}
                className={classes.select}
              >
                <MenuItem value={'All'}>Show All</MenuItem>
                <MenuItem value={1}>Show Active</MenuItem>
                <MenuItem value={0}>Show Inactive</MenuItem>
              </Select>
            </div>
            <div style={{margin: '1em', textAlign: 'right'}}>
              <Button
                variant="outlined"
                startIcon={<DnsIcon />}
                onClick={this.addToNav}
                className={classes.button}
                >
                  Update Navbar
                </Button>
              </div>

          </Paper>
        </div>

        <Paper style={{marginTop: '30px'}}>
          <Table>
            <TableHead>
              <TableRow style={{background: '#1d3d74'}}>
                <TableCell style={{ width: 150, textAlign: 'center', color: '#fff' }}>THUMBNAIL</TableCell>
                <TableCell style={{ borderLeft: '1px solid #dcdbdc', color: '#fff'}}>SCREEN NAME</TableCell>
                <TableCell style={this.state.currentUser >= 2 ? { borderLeft: '1px solid #dcdbdc', color: '#fff', width: 200} : {display: 'none'}}>DISPLAY</TableCell>
                <TableCell style={this.state.currentUser >= 2 ? { borderLeft: '1px solid #dcdbdc', color: '#fff', width: 150} : {display: 'none'}}>NOTES</TableCell>
                <TableCell style={{ width: 150, textAlign: 'center', borderLeft: '1px solid #dcdbdc', color: '#fff' }} align="right">ACTIVE STATUS</TableCell>
                <TableCell style={{ width: 100, textAlign: 'center', borderLeft: '1px solid #dcdbdc', color: '#fff' }} align="right">ID</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredItems.sort((a, b) => (a.ID < b.ID) ? -1 : 1)
                .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                .map(row => (
                    <TableRow
                      hover
                      key={row.ID}
                    >
                      <TableCell style={{width: 100, textAlign: 'center'}}>
                        {/* <ButtonBase onClick={this.handleOpen}> */}
                          <img
                            style={{width: '100%', border: '1px solid #1d3d74'}}
                            src={
                              row.img
                              ? `https://my.smartdash.com/v2/thumbnails/${row.img}`
                              : 'https://pbs.twimg.com/profile_images/1019707946349969408/ZadESXl4_400x400.jpg'
                            }
                          />
                        {/* </ButtonBase> */}
                      </TableCell>
                      <TableCell style={{borderLeft: '1px solid #dcdbdc'}}>
                        <Link
                          href={'/' + row.display} color="inherit">{row.short_name.toUpperCase()}
                        </Link>
                      </TableCell>
                      <TableCell style={this.state.currentUser >= 2 ? {borderLeft: '1px solid #dcdbdc'} : {display: 'none'}}><Typography variant="caption">{row.display}</Typography></TableCell>
                      <TableCell style={this.state.currentUser >= 2 ? {borderLeft: '1px solid #dcdbdc'} : {display: 'none'}}><Typography variant="caption">{row.notes}</Typography></TableCell>
                      <TableCell style={{width: 150, borderLeft: '1px solid #dcdbdc', textAlign: 'center', backgroundColor: 'rgba(197, 206, 222, 0.3)'}} >
                        <FormControlLabel
                          style={{marginLeft: '15px'}}
                          name={row.screen_name}
                          checked={this.state.checkedItems.includes(row)}
                          onChange={e => this.handleChecked(e, row)}
                          // control={<CustomCheckbox disableRipple color="secondary" />}
                          control={<CustSwitch />}
                        />
                      </TableCell>
                      <TableCell style={{borderLeft: '1px solid #dcdbdc', textAlign: 'center'}} align="right">{row.ID}</TableCell>
                    </TableRow>
                ))
              }
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 20, 40, 'Show All']}
            component="div"
            count={filteredItems.length}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    )
  }
}

export default withStyles(styles)(TableauCatalog)
