import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography, CircularProgress } from '@material-ui/core'
import DonutChart from '../charts/DonutChart'
import Cookies from 'js-cookie'
import roiStandIn from '../../assets/homeStandIns/roiStandIn.png'
import AskReport from '../AskReport'

// import moment from 'moment'

// const today = moment().format('YYYY-MM-DD')
// const twelveMonthsPast = moment().subtract(12, 'months').format('YYYY-MM-DD')

const styles = theme => ({
  root: {
    width: '100%'
  },
  pie: {
    paddingTop: 40,
  },
  data: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'space-evenly'
  },
  divider: {
    opacity: 0.4,
  },
  stats: {
    fontWeight: 'bold',
    color: '#626262',
    [theme.breakpoints.up('xl')]: {
      fontSize: 16
    }
  },
  statsDark: {
    fontWeight: 'bold',
    color: '#fff',
    [theme.breakpoints.up('xl')]: {
      fontSize: 16
    }
  },
  loader: {
    marginLeft: '45%',
    marginTop: '30%',
  },
  standIn: {
    width: '100%',
    [theme.breakpoints.up('xl')]: {
      marginTop: '-50px',
      height: '35%',
      width: '85%'
    },
    [theme.breakpoints.down('lg')]: {
      width: '120%',
      marginTop: '-35px'
    }
  },
 unavailable: {
    height: '400px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})

class TentpoleMod extends Component {
  _isMounted = false

  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      tentpoleRoi: [],
      corporateID: JSON.parse(localStorage.getItem("currentUser")) ? JSON.parse(localStorage.getItem("currentUser"))[0].corporateID : 0
    }
  }

  componentDidMount() {
    this._isMounted = true

    fetch('https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI',{
      method: 'GET',
      headers: {
        'token': Cookies.get('token')
      },
    })
    .then(response => response.json())
    .then(data => {
      console.log(data)
      if(this._isMounted && data) {
        this.setState({
          isLoading: false,
          tentpoleRoi: this.state.corporateID === 278 ? data.map(item => item.Data_Promo[0]) : data.map(item => item.Data_Extended[0]),
        })
      } else {
        this.setState({
          isLoading: false,
          tentpoleRoi: []
        })
      }
    })
    .catch(error => {
      console.log(error)
    })
  }

  render() {
    const { classes } = this.props
    const incSales = []
    const incResponses = []
    const sales = []
    const responses = []
    const spend = []
    const mailed = []
    const currentUserLite = JSON.parse(localStorage.getItem('currentUser')) && JSON.parse(localStorage.getItem('currentUser'))[0].sd_lite === 1 ? true : false

    console.log(this.state.tentpoleRoi)

    if(this.state.tentpoleRoi.length > 0) {
      for(let i = 0; i < this.state.tentpoleRoi.length; i++) {
      console.log(this.state.tentpoleRoi[i])
      incSales.push(this.state.tentpoleRoi[i].ADJ_Sales)
      incResponses.push(this.state.tentpoleRoi[i].ADJ_Resp)
      sales.push(this.state.tentpoleRoi[i].Sales)
      responses.push(this.state.tentpoleRoi[i].Responses)
      spend.push(this.state.tentpoleRoi[i].Cost)
      mailed.push(this.state.tentpoleRoi[i].Mailed)
    }
    }    

    const avgTicket = responses.reduce((a,b) => a + b, 0) > 0 ? sales.reduce((a,b) => a + b, 0) / responses.reduce((a,b) => a + b, 0) : 0
    const responseRate = responses.reduce((a,b) => a + b, 0) / mailed.reduce((a,b) => a + b, 0)

    return(
      <div>
        { this.state.isLoading ? <div className={classes.loader}><CircularProgress /></div> :
          this.state.tentpoleRoi.length > 0 && !currentUserLite ?
          <Fragment>
            <div align="center">
              {console.log(this.state.tentpoleRoi)}
              <Typography style={{fontSize: '12px'}} align="center">
                {/* Promotion: <span>New Years Synchrony Alignment OTB</span> */}
                On completed promotions, a total spend of <span style={this.props.darkMode ? {color: 'rgba(255, 115, 115, 0.7)'} : {color: 'rgba(255, 0, 0, 0.7)'}}>${Math.round(spend.reduce((a,b) => a + b, 0)).toLocaleString('en')}</span> has yielded <span style={{color: 'rgba(113, 177, 40)', fontWeight:'bold'}}>${Math.round(sales.reduce((a,b) => a + b, 0)).toLocaleString('en')}</span> in sales to responders (at a {(responseRate * 100).toFixed(2)}% response rate)
              </Typography>
            </div>
            <div className={classes.data}>
              <div style={{ display: 'block', textAlign: 'left', marginLeft: '20px'}}>
                <div>
                  <h5 style={{opacity: 0.8, color: '#05b3ad'}}>AVG TICKET</h5>                  
                  <span className={this.props.darkMode ? classes.statsDark : classes.stats}>${Math.round(avgTicket).toLocaleString('en')}</span>
                </div>
                <hr className={classes.divider}/>
                <div>
                  <h5 style={{opacity: 1.0, color: '#529c00'}}>INC SALES</h5>
                  <span className={this.props.darkMode ? classes.statsDark : classes.stats}>${Math.round(incSales.reduce((a,b) => a + b, 0)).toLocaleString('en')}</span>
                </div>
                <hr className={classes.divider}/>
                <div >
                  <h5 style={this.props.darkMode ? {opacity: 0.8, color: '#faa2f4'} : {opacity: 0.8, color: '#942e92'}}>INC RESPONSES</h5>
                  <span className={this.props.darkMode ? classes.statsDark : classes.stats}>{Math.round(incResponses.reduce((a,b) => a + b, 0)).toLocaleString('en')}</span>
                </div>
              </div>
              <div className={classes.pie}>
                <DonutChart
                  labels={['Total Spend', 'Incremental Sales']}
                  data={[Math.round(spend.reduce((a,b) => a + b, 0)), Math.round(incSales.reduce((a,b) => a + b, 0))]}
                  colors={['rgba(235, 82, 82, 0.9)', 'rgba(110, 209, 0, 0.9)']}
                  radius={'85%'}
                  labelShow={true}
                  legend={true}
                  position={'bottom'}
                  height={250}
                  darkMode={this.props.darkMode}
                />
              </div>
            </div>
          </Fragment>
          : 
          <div className={classes.unavailable}>
              <AskReport home={true} />
            </div>
        }
      </div>
    )
  }
}

export default withStyles(styles)(TentpoleMod)
