import React, { Component, Fragment } from 'react'
import Heatmap from '../charts/Heatmap'
import Cookies from 'js-cookie'
import {
  withStyles,
  CircularProgress,
  Typography,
  Card,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  Tooltip
} from '@material-ui/core'
import custRetStandIn from '../../assets/mainStandIns/custRetStandIn.png'
import CustLine from './CustLine'
import BarChart from '../charts/BarChart'
import LineChart from '../charts/LineChart'
import MonthPicker from '../calendar/MonthPicker'
import {GiFastBackwardButton, GiFastForwardButton} from 'react-icons/gi'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import AskReport from '../AskReport'

import moment from 'moment'

const styles = theme => ({
  unavailable: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // margin: '2em'
  },
  pageTitle: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '17px',
    fontWeight: 'light',
    color: 'rgba(115, 115, 115, 0.9)'
  },
  pageTitleDark: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '17px',
    fontWeight: 'light',
    color: 'rgba(255, 255, 255, 0.9)'
  },
  pageIcon: {
    fontSize: '15px',
    marginTop: '21px',
    marginRight: '2px',
  },
  pageIconDark: {
    fontSize: '15px',
    color: 'rgba(255, 255, 255, 0.9)',
    marginTop: '5px',
    marginRight: '2px',
  },
  datePicker: {
    height: 60,
    border: '1px solid #c4c4c4',
    borderRadius: 5,
    width: '94%',
    paddingTop: '15px',
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  listLabel: {
    margin: theme.spacing(1),
    color: '#757575',
  },
  listLabelDark: {
    margin: theme.spacing(1),
    color: '#fff',
  },
  list: {
    margin: theme.spacing(1),
    border: '1px solid #c4c4c4',
    borderRadius: 5,
    // width: 250,
    height: 275,
    overflowY: 'auto'
  },
  formControl: {
    margin: theme.spacing(1),
    width: '94%',
  },
  graph: {
    height: 500,
    width: '40%',
    padding: '1em',
    alignItems: 'center'
  },
  definition: {
    display: 'block',
    textAlign: 'center',
    border: '1px solid #eb8e05',
    borderRadius: 5,
    marginTop: '15px',
    paddingTop: '10px',
    paddingBottom: '10px'
    // padding: '1em',
  }
})

class CustRet extends Component {
  _isMounted = false

  constructor() {
    super()
    this.state = {
      custRetData: [],
      custRetMarketData: [],
      custRetMarkets: [],
      pctSalesNew: [],
      labels: [],
      isLoading: true,
      months: moment().diff(moment('2014-01'), 'months'),
      maxYearmo: moment(),
      minYearmo: moment('2014-01'),
      checked: [],
      z11: 0,
      currentUser: JSON.parse(localStorage.getItem('currentUser')),
      hasData: false,
    }
  }

  componentDidMount = () => {
    this._isMounted = true

    Promise.all([
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/custRetention/location/monthly?months=${24}`, {
        method: 'GET',
        headers: {
          'token': Cookies.get('token')
        },
      }),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/custRetention/market/monthly?min_yearmo=${this.state.minYearmo.format('YYYY-MM')}&max_yearmo=${this.state.maxYearmo.format('YYYY-MM')}`, {
        method: 'GET',
        headers: {
          'token': Cookies.get('token')
        },
      }),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/custRetention/location/monthly?min_yearmo=${this.state.minYearmo.format('YYYY-MM')}&max_yearmo=${this.state.maxYearmo.format('YYYY-MM')}`, {
        method: 'GET',
        headers: {
          'token': Cookies.get('token')
        },
      }),
    ])
    .then(([response, response2, response3]) => Promise.all([response.json(), response2.json(), response3.json()]))
    .then(([data, data2, data3]) => {      
      if(this._isMounted && data2.length > 0) {        
        this.setState({
          isLoading: false,
          // custRetData: data,
          // labels: data[0].Data.map(item => item.YearMo),
          // pctSalesNew: data.map(item => item.Data.map(item => (item.Pct_Sales_New * 100).toFixed(2))),
          custRetMarketData: data2.filter(item => item.Market !== ""),
          custRetMarkets: data2.filter(item => item.Market !== ""),
          custRetData: data3,
          checked: data2.filter(item => item.Market !== "").map(item => item.Market),
          hasData: true,
        })
      } else {
        console.log(this.state.currentUser)
        this.setState({
          hasData: false,
          isLoading: false,
        })
      }
    })
    .catch(error => {
      console.log(error)
    })

    if(JSON.parse(localStorage.getItem('currentUser'))[0].username.toUpperCase().includes('USER')) {
    } else {
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/pageviews/add?display=customer_retention&screen_name=customer_retention`, {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'token': Cookies.get('token'),
        },
      })
      .then(response => {
        if(response.ok === true) {
          return response.json()
        }
      })
      .catch(error => {
        console.log(error)
      })
    }
  }

  componentWillUnmount = () => {
    this._isMounted = false
  }

  handleToggleChecked = (e, row) => {
    const currentIndex = this.state.checked.indexOf(row.Market)
    const newChecked = [...this.state.checked]
    if(currentIndex === -1) {
      newChecked.push(row.Market)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    console.log(newChecked)

    this.setState({
      checked: newChecked
    })
  }

  handleDateRange = (value, valName) => {
    this.setState({
      chartLoading: true,
    })

    if(valName === 'minYearmo') {
      Promise.all([
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/custRetention/location/monthly?min_yearmo=${value.format('YYYY-MM')}&max_yearmo=${this.state.maxYearmo.format('YYYY-MM')}`, {
          method: 'GET',
          headers: {
            'token': Cookies.get('token')
          },
        }),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/custRetention/market/monthly?min_yearmo=${value.format('YYYY-MM')}&max_yearmo=${this.state.maxYearmo.format('YYYY-MM')}`, {
          method: 'GET',
          headers: {
            'token': Cookies.get('token')
          },
        }),

      ])
      .then(([response, response2]) => Promise.all([response.json(), response2.json()]))
      .then(([data, data2]) => {
        this.setState({
          chartLoading: false,
          custRetData: data,
          custRetMarketData: data2.filter(item => item.Market !== ""),
          months: Math.floor(this.state.maxYearmo.diff(value, 'months')),
        })
      })
      .catch(error => {
        console.log(error)
      })
    } else {
      Promise.all([
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/custRetention/location/monthly?min_yearmo=${this.state.minYearmo.format('YYYY-MM')}&max_yearmo=${value.format('YYYY-MM')}`, {
          method: 'GET',
          headers: {
            'token': Cookies.get('token')
          },
        }),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/custRetention/market/monthly?min_yearmo=${this.state.minYearmo.format('YYYY-MM')}&max_yearmo=${value.format('YYYY-MM')}`, {
          method: 'GET',
          headers: {
            'token': Cookies.get('token')
          },
        }),
      ])
      .then(([response, response2]) => Promise.all([response.json(), response2.json()]))
      .then(([data, data2]) => {
        this.setState({
          chartLoading: false,
          custRetData: data,
          custRetMarketData: data2.filter(item => item.Market !== ""),
          months: Math.floor(value.diff(this.state.minYearmo, 'months')),
        })
      })
      .catch(error => {
        console.log(error)
      })
    }

    this.setState({
      [valName]: value
    })
  }

  handleSliderDateChange = values => {
    this.setState({
      chartLoading: true,
    })

    const minYearmo = values[0]
    const maxYearmo = values[1]

    console.log(minYearmo)
    console.log(maxYearmo)

    Promise.all([
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/custRetention/location/monthly?min_yearmo=${minYearmo}&max_yearmo=${maxYearmo}`, {
        method: 'GET',
        headers: {
          'token': Cookies.get('token')
        },
      }),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/custRetention/market/monthly?min_yearmo=${minYearmo}&max_yearmo=${maxYearmo}`, {
        method: 'GET',
        headers: {
          'token': Cookies.get('token')
        },
      }),
    ])
    .then(([response, response2]) => Promise.all([response.json(), response2.json()]))
    .then(([data, data2]) => {
      console.log(data)
      console.log(data2)
      this.setState({
        chartLoading: false,
        custRetData: data,
        custRetMarketData: data2.filter(item => item.Market !== ""),
        minYearmo: moment(values[0]),
        maxYearmo: moment(values[1]),
      })
    })
    .catch(error => {
      console.log(error)
    })
  }

  handleToggleCustomerDef = (e) => {
    console.log(this.state.minYearmo)
    let customerId = e.target.value

    fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/custRetention/market/monthly?min_yearmo=${this.state.minYearmo.format('YYYY-MM')}&max_yearmo=${this.state.maxYearmo.format('YYYY-MM')}&z11=${e.target.value}`, {
      method: 'GET',
      headers: {
        'token': Cookies.get('token')
      },
    })
    .then(response => response.json())
    .then(data => {
      this.setState({
        z11: customerId,
        custRetMarketData: data.filter(item => this.state.checked.includes(item.Market)),
        custRetMarkets: data.filter(item => item.Market !== ""),
        checked: data.filter(item => item.Market !== "" && this.state.checked.includes(item.Market)).map(item => item.Market)
      })
    })
    .catch(error => {
      console.log(error)
    })
  }

  getYoyData = (months, marketData) => {
    const yoy = []
    const newdata = []
    const retdata = []
    const newCustSeries = []
    const newCustObj = {}
    const retCustSeries = []
    const retCustObj = {}
    const dataMinusOne = []
    const metricLength = this.state.maxYearmo.format('M') >= moment().format('M') ? marketData.map(item => item.Data.length)[0] - 1 : marketData.map(item => item.Data.length)[0]

    for(let i = 0; i < metricLength; i++) {
      const custObj = {}
      let newCust = marketData.map(item => item.Data.map(item => item.Customers_New)[i]).reduce((a,b) => a + b, 0)
      let lyNewCust = marketData.map(item => item.Data.map(item => item.Customers_New_LY)[i]).reduce((a,b) => a + b, 0)
      custObj['y'] = lyNewCust !== 0 ? ((newCust / lyNewCust - 1) * 100).toFixed(2) : null
      custObj['x'] = months[i]

      newdata.push(custObj)
    }

    for(let i = 0; i < metricLength; i++) {
      const custObj = {}
      let retCust = marketData.map(item => item.Data.map(item => item.Customers_Ret)[i]).reduce((a,b) => a + b, 0)
      let lyRetCust = marketData.map(item => item.Data.map(item => item.Customers_Ret_LY)[i]).reduce((a,b) => a + b, 0)
      custObj['y'] = lyRetCust !== 0 ? ((retCust / lyRetCust - 1) * 100).toFixed(2) : null
      custObj['x'] = months[i]
      retdata.push(custObj)
    }

    for(let i = 0; i < retdata.length; i ++) {
      if(retdata[i].y <= 100 && retdata[i].y !== null) {
        for(let j = 0; j < i; j++) {
          retdata[j].y = null
          newdata[j].y = null
        }
        break
      }
    }

    newCustObj['name'] = 'New Customers'
    newCustObj['data'] = newdata
    retCustObj['name'] = 'Returning Customers'
    retCustObj['data'] = retdata
    yoy.push(newCustObj, retCustObj)    


    return(yoy)
  }

  getAvgTicketData = (months, marketData) => {
    console.log(marketData)
    const dataMinusOne = []
    const avgTicket = []
    const avgTicketRet = []
    const avgTicketRetObj = {}
    const avgTicketNew = []
    const avgTicketNewObj = {}
    const metricLength = this.state.maxYearmo >= moment() ? marketData.map(item => item.Data.length)[0] - 1 : marketData.map(item => item.Data.length)[0]

    for(let i = 0; i < metricLength; i++) {
      let avgTicketObj = {}
      let customerNewCount = marketData.map(item => item.Data.map(item => item.Customers_New)[i]).reduce((a,b) => a + b, 0)
      let salesNewTotal = marketData.map(item => item.Data.map(item => item.Sales_New)[i]).reduce((a,b) => a + b, 0)
      avgTicketObj['y'] = customerNewCount > 0 ? (salesNewTotal / customerNewCount).toFixed(2) : null
      avgTicketObj['x'] = months[i]
      avgTicketNew.push(avgTicketObj)
    }

    for(let i = 0; i < metricLength; i++) {
      let avgTicketObj = {}
      let customerRetCount = marketData.map(item => item.Data.map(item => item.Customers_Ret)[i]).reduce((a,b) => a + b, 0)
      let salesRetTotal = marketData.map(item => item.Data.map(item => item.Sales_Ret)[i]).reduce((a,b) => a + b, 0)
      avgTicketObj['y'] = customerRetCount > 0 ? (salesRetTotal / customerRetCount).toFixed(2) : null
      avgTicketObj['x'] = months[i]
      avgTicketRet.push(avgTicketObj)
    }

    avgTicketNewObj['name'] = 'New Customers'
    avgTicketNewObj['data'] = avgTicketNew
    avgTicketRetObj['name'] = 'Returning Customers'
    avgTicketRetObj['data'] = avgTicketRet

    avgTicket.push(avgTicketNewObj, avgTicketRetObj)

    return(avgTicket)
  }

  getForwardsData = data => {
    const totalCustomers = data.map(item => item.map(item => item.Customers).reduce((a,b) => a + b, 0)).reduce((a,b) => a + b, 0)
    const forwards = []
    // const metricLength = data.map(item => item.Data.map(item => item.Customers_Forwards)[0].length)[0]
    const metricLength = data.map(item => item.map(item => item.Customers_Forwards)[0].length)[0]
    // console.log(metricLength)

    for(let i = 0; i < metricLength; i++) {
      let forwardsObj = {}
      forwardsObj['value'] = data.map(item => item.map(item => item.Customers_Forwards[i]).reduce((a,b) => a + b, 0)).reduce((a,b) => a + b, 0) / totalCustomers * 100
      forwardsObj['key'] = i
      forwards.push(forwardsObj)
    }

    return(forwards)
  }

  getBackwardsData = data => {
    const totalCustomers = data.map(item => item.map(item => item.Customers).reduce((a,b) => a + b, 0)).reduce((a,b) => a + b, 0)
    const backwards = []
    const metricLength = data.map(item => item.map(item => item.Customers_Backwards)[0].length)[0]

    for(let i = 0; i < metricLength; i++) {
      let backwardsObj = {}
      backwardsObj['value'] = data.map(item => item.map(item => item.Customers_Backwards[i]).reduce((a,b) => a + b, 0)).reduce((a,b) => a + b, 0) / totalCustomers * 100
      backwardsObj['key'] = i
      backwards.push(backwardsObj)
    }

    return(backwards)
  }

  getLTV = data => {
    const customersByMarket = data.map(item => item.map(item => item.Customers).reduce((a,b) => a + b, 0)).reduce((a,b) => a + b, 0)
    const salesByMarket = data.map(item => item.map(item => item.Sales).reduce((a,b) => a + b, 0)).reduce((a,b) => a + b, 0)
    const ltvByMarket = salesByMarket / customersByMarket

    return(ltvByMarket)
  }

  render() {
    const { classes } = this.props
    const series = []
    const rangeOfMonths = this.state.custRetData.map(item => item.Data.map(item => item.YearMo))[0]
    const yoyData = this.getYoyData(rangeOfMonths, this.state.custRetMarketData.filter(item => this.state.checked.includes(item.Market)))
    const avgTicketData = this.getAvgTicketData(rangeOfMonths, this.state.custRetMarketData.filter(item => this.state.checked.includes(item.Market)))
    const forwardsData = this.getForwardsData(this.state.custRetMarketData.filter(item => this.state.checked.includes(item.Market)).map(item => item.Data.filter(item => item.YearMo !== moment().subtract(1, 'months').format('YYYY-MM'))))
    const backwardsData = this.getBackwardsData(this.state.custRetMarketData.filter(item => this.state.checked.includes(item.Market)).map(item => item.Data.filter(item => item.YearMo !== moment().subtract(1, 'months').format('YYYY-MM'))))
    const ltvData = this.getLTV(this.state.custRetMarketData.filter(item => this.state.checked.includes(item.Market)).map(item => item.Data.filter(item => item.YearMo !== moment().subtract(1, 'months').format('YYYY-MM'))))

    return(
      <div>

        <div style={{minHeight: '950px'}}>
          {this.state.isLoading ? <CircularProgress /> :

            // this.state.hasData ?
            // this.state.currentUser[0].userID === 66 ?
            // this.state.custRetMarketData.length > 0 ?

            // this.state.hasData ?s
            this.state.currentUser[0].cust_retention_show === 1 && this.state.hasData?
            // this.state.custRetMarketData.length > 0 ?

            <div>
              <div>
                <Typography className={this.props.darkMode ? classes.pageTitleDark : classes.pageTitle} style={{marginTop: '25px'}}>
                  <PersonAddIcon className={this.props.darkMode ? classes.pageIconDark : classes.pageIcon} />
                  <span style={{marginLeft: '5px'}}>CUSTOMER RETENTION</span>
                </Typography>

                {/* {console.log(this.state.checked)} */}
                {/* {console.log('data', this.state.custRetMarketData)}
                {console.log('*****************')}
                {console.log('market', this.state.custRetMarkets)}
                {console.log('*****************')}
                {console.log('z11', this.state.z11)} */}

              </div>
              <div className={classes.definition}>
                {this.state.z11 === 0 ?
                  <div>
                    <Typography variant="caption" style={{fontWeight: 'bold', fontSize: '15px'}}>Client Customer ID</Typography>
                    <Typography display="block" variant="caption">Uses available customerIDs as provided in the data feed</Typography>
                  </div>
                :
                  <div>
                    <Typography variant="caption" style={{fontWeight: 'bold', fontSize: '15px'}}>LS Household ID</Typography>
                    <Typography display="block" variant="caption">Uses available customer name, CASS-certified street address, and phone number to generate a unique ID for each household</Typography>
                  </div>
                }
              </div>
              <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '15px'}}>
                <Card className={classes.graph}>
                  {this.state.chartLoading ? <CircularProgress /> :
                    <div style={{marginTop: '30px'}}>
                      <LineChart
                        series={yoyData}
                        title={['YOY CUSTOMER CHANGES', 'left']}
                        toolbar={true}
                        height={350}
                      />
                      {console.log(yoyData)}
                    </div>
                  }
                </Card>

                <Card className={classes.graph}>
                  {this.state.chartLoading ? <CircularProgress /> :
                    <div style={{marginTop: '30px'}}>
                      <LineChart
                        series={avgTicketData}
                        title={['AVERAGE TICKET (BY MONTH)', 'left']}
                        toolbar={true}
                        height={350}
                      />
                      {console.log(avgTicketData)}
                    </div>
                  }
                </Card>

                <Card style={{height: 500, width: '18%', padding: '1em'}}>
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Typography
                      className={this.props.darkMode ? classes.listLabelDark : classes.listLabel}
                      variant="caption"
                    >
                      MARKETS ({this.state.custRetMarkets.length})
                    </Typography>
                    <Typography className={this.props.darkMode ? classes.listLabelDark : classes.listLabel} variant="caption">{`${this.state.checked.length} selected`}</Typography>
                  </div>
                  <List className={classes.list} dense={true}>
                    <div>
                      {this.state.custRetMarkets.map(row => (
                        <ListItem key={row.Key} dense button onClick={e => this.handleToggleChecked(e, row)}>
                          {console.log(this.state.custRetMarkets)}
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={this.state.checked.indexOf(row.Market) !== -1}
                              disableRipple
                              className={classes.checkbox}
                            />
                          </ListItemIcon>
                          <ListItemText>{row.Market}</ListItemText>
                        </ListItem>
                      ))}
                    </div>
                  </List>

                  <Typography
                    className={this.props.darkMode ? classes.listLabelDark : classes.listLabel}
                    variant="caption"
                    style={
                      this.props.darkMode ?
                      {marginLeft: '17px', padding: '0px 5px 0px 5px', backgroundColor: '#424242', marginTop: '20px'}
                      : {marginLeft: '17px', padding: '0px 5px 0px 5px', backgroundColor: '#fff', marginTop: '20px'}
                    }
                  >
                    Date Range
                  </Typography>
                  <div className={classes.datePicker} style={{marginTop: '-10px'}}>
                    <MonthPicker
                      maxValue={this.state.maxYearmo}
                      minValue={this.state.minYearmo}
                      maxSliderValue={this.state.months}
                      handleDateRange={this.handleDateRange}
                      handleSliderDateChange={this.handleSliderDateChange}
                    />
                  </div>

                  <div>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <InputLabel id="id-select">Customer Definition</InputLabel>
                      <Select
                        id="customer-definition"
                        labelId="id-select"
                        value={this.state.z11}
                        onChange={this.handleToggleCustomerDef}
                        label="Customer Definition"
                      >
                        <MenuItem value={0}>
                          <Typography variant="caption">Client Customer ID</Typography>
                          {/* <Tooltip title="Uses available customerID as provided in data feed"><InfoOutlinedIcon style={{fontSize: '11px'}}/></Tooltip> */}
                        </MenuItem>
                        <MenuItem value={1}>
                          <Typography variant="caption">LS Household ID</Typography>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                </Card>
              </div>

              <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '15px'}}>
                <Card style={{width: '48%'}}>
                  <Table size="small" style={{height: '100%'}}>
                    <TableHead style={{backgroundColor: '#eb8e05'}}>
                      <TableRow>
                        <TableCell colSpan={8} align="center">
                          <div style={{display: 'inline-flex', alignItems: 'center'}}>
                            <GiFastBackwardButton style={{color: '#fff', marginRight: '5px'}}/><Typography variant="caption" style={{color: '#fff'}}>BACKWARDS</Typography>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell><Typography variant="caption" style={{fontWeight: 'bold'}}>MARKET</Typography></TableCell>
                        <TableCell align="right"><Typography variant="caption" style={{fontWeight: 'bold'}}>NEW TO FILE</Typography></TableCell>
                        <TableCell align="right"><Typography variant="caption" style={{fontWeight: 'bold'}}>0-3 Months</Typography></TableCell>
                        <TableCell align="right"><Typography variant="caption" style={{fontWeight: 'bold'}}>3-6 Months</Typography></TableCell>
                        <TableCell align="right"><Typography variant="caption" style={{fontWeight: 'bold'}}>6-12 Months</Typography></TableCell>
                        <TableCell align="right"><Typography variant="caption" style={{fontWeight: 'bold'}}>12-18 Months</Typography></TableCell>
                        <TableCell align="right"><Typography variant="caption" style={{fontWeight: 'bold'}}>18-24 Months</Typography></TableCell>
                        <TableCell align="right"><Typography variant="caption" style={{fontWeight: 'bold'}}>24+ Months</Typography></TableCell>
                      </TableRow>
                      {this.state.custRetMarketData.filter(item => this.state.checked.includes(item.Market)).map(row => (
                        <TableRow key={row.Key} style={{height: 75}}>
                          <TableCell><Typography variant="caption" style={{fontSize: '12px'}}>{row.Market}</Typography></TableCell>
                          <TableCell align="right"><Typography variant="caption" style={{fontSize: '12px'}}>{(row.Data.filter(item => item.YearMo !== moment().subtract(1, 'months').format('YYYY-MM')).map(item => item.Customers_Backwards[0]).reduce((a,b) => a + b, 0) / row.Data.filter(item => item.YearMo !== moment().subtract(1, 'months').format('YYYY-MM')).map(item => item.Customers).reduce((a,b) => a + b, 0) * 100).toFixed(2) + '%'}</Typography></TableCell>
                          <TableCell align="right"><Typography variant="caption" style={{fontSize: '12px'}}>{(row.Data.filter(item => item.YearMo !== moment().subtract(1, 'months').format('YYYY-MM')).map(item => item.Customers_Backwards[1]).reduce((a,b) => a + b, 0) / row.Data.filter(item => item.YearMo !== moment().subtract(1, 'months').format('YYYY-MM')).map(item => item.Customers).reduce((a,b) => a + b, 0) * 100).toFixed(2) + '%'}</Typography></TableCell>
                          <TableCell align="right"><Typography variant="caption" style={{fontSize: '12px'}}>{(row.Data.filter(item => item.YearMo !== moment().subtract(1, 'months').format('YYYY-MM')).map(item => item.Customers_Backwards[2]).reduce((a,b) => a + b, 0) / row.Data.filter(item => item.YearMo !== moment().subtract(1, 'months').format('YYYY-MM')).map(item => item.Customers).reduce((a,b) => a + b, 0) * 100).toFixed(2) + '%'}</Typography></TableCell>
                          <TableCell align="right"><Typography variant="caption" style={{fontSize: '12px'}}>{(row.Data.filter(item => item.YearMo !== moment().subtract(1, 'months').format('YYYY-MM')).map(item => item.Customers_Backwards[3]).reduce((a,b) => a + b, 0) / row.Data.filter(item => item.YearMo !== moment().subtract(1, 'months').format('YYYY-MM')).map(item => item.Customers).reduce((a,b) => a + b, 0) * 100).toFixed(2) + '%'}</Typography></TableCell>
                          <TableCell align="right"><Typography variant="caption" style={{fontSize: '12px'}}>{(row.Data.filter(item => item.YearMo !== moment().subtract(1, 'months').format('YYYY-MM')).map(item => item.Customers_Backwards[4]).reduce((a,b) => a + b, 0) / row.Data.filter(item => item.YearMo !== moment().subtract(1, 'months').format('YYYY-MM')).map(item => item.Customers).reduce((a,b) => a + b, 0) * 100).toFixed(2) + '%'}</Typography></TableCell>
                          <TableCell align="right"><Typography variant="caption" style={{fontSize: '12px'}}>{(row.Data.filter(item => item.YearMo !== moment().subtract(1, 'months').format('YYYY-MM')).map(item => item.Customers_Backwards[5]).reduce((a,b) => a + b, 0) / row.Data.filter(item => item.YearMo !== moment().subtract(1, 'months').format('YYYY-MM')).map(item => item.Customers).reduce((a,b) => a + b, 0) * 100).toFixed(2) + '%'}</Typography></TableCell>
                          <TableCell align="right"><Typography variant="caption" style={{fontSize: '12px'}}>{(row.Data.filter(item => item.YearMo !== moment().subtract(1, 'months').format('YYYY-MM')).map(item => item.Customers_Backwards[6]).reduce((a,b) => a + b, 0) / row.Data.filter(item => item.YearMo !== moment().subtract(1, 'months').format('YYYY-MM')).map(item => item.Customers).reduce((a,b) => a + b, 0) * 100).toFixed(2) + '%'}</Typography></TableCell>
                        </TableRow>
                      ))}
                      <TableRow style={{height: 75}}>
                        <TableCell><Typography variant="caption" style={{fontSize: '12px'}}>ALL MARKETS</Typography></TableCell>
                        {backwardsData.map(row => (
                          <TableCell key={row.key} align="right"><Typography variant="caption" style={{fontSize: '12px'}}>{row.value.toFixed(2) + '%'}</Typography></TableCell>
                        ))}
                      </TableRow>
                    </TableBody>
                  </Table>
                </Card>
                <Card style={{width: '51%'}}>
                  <Table size="small" style={{height: '100%'}}>
                    <TableHead style={{backgroundColor: '#eb8e05'}}>
                      <TableRow>
                        <TableCell colSpan={9} align="center">
                          <div style={{display: 'inline-flex', alignItems: 'center'}}>
                            <Typography variant="caption" style={{color: '#fff'}}>FORWARDS</Typography><GiFastForwardButton style={{color: '#fff', marginLeft: '5px'}}/>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell><Typography variant="caption" style={{fontWeight: 'bold'}}>MARKET</Typography></TableCell>
                        <TableCell align="right"><Typography variant="caption" style={{fontWeight: 'bold'}}>NEVER RETURNED</Typography></TableCell>
                        <TableCell align="right"><Typography variant="caption" style={{fontWeight: 'bold'}}>0-3 Months</Typography></TableCell>
                        <TableCell align="right"><Typography variant="caption" style={{fontWeight: 'bold'}}>3-6 Months</Typography></TableCell>
                        <TableCell align="right"><Typography variant="caption" style={{fontWeight: 'bold'}}>6-12 Months</Typography></TableCell>
                        <TableCell align="right"><Typography variant="caption" style={{fontWeight: 'bold'}}>12-18 Months</Typography></TableCell>
                        <TableCell align="right"><Typography variant="caption" style={{fontWeight: 'bold'}}>18-24 Months</Typography></TableCell>
                        <TableCell align="right"><Typography variant="caption" style={{fontWeight: 'bold'}}>24+ Months</Typography></TableCell>
                        <TableCell align="right"><Typography variant="caption" style={{fontWeight: 'bold'}}>LTV</Typography></TableCell>
                      </TableRow>
                      {this.state.custRetMarketData.filter(item => this.state.checked.includes(item.Market)).map(row => (
                        <TableRow key={row.Key} style={{height: 75}}>
                          <TableCell><Typography variant="caption" style={{fontSize: '12px'}}>{row.Market}</Typography></TableCell>
                          <TableCell align="right"><Typography variant="caption" style={{fontSize: '12px'}}>{(row.Data.filter(item => item.YearMo !== moment().subtract(1, 'months').format('YYYY-MM')).map(item => item.Customers_Forwards[0]).reduce((a,b) => a + b, 0) / row.Data.filter(item => item.YearMo !== moment().subtract(1, 'months').format('YYYY-MM')).map(item => item.Customers).reduce((a,b) => a + b, 0) * 100).toFixed(2) + '%'}</Typography></TableCell>
                          <TableCell align="right"><Typography variant="caption" style={{fontSize: '12px'}}>{(row.Data.filter(item => item.YearMo !== moment().subtract(1, 'months').format('YYYY-MM')).map(item => item.Customers_Forwards[1]).reduce((a,b) => a + b, 0) / row.Data.filter(item => item.YearMo !== moment().subtract(1, 'months').format('YYYY-MM')).map(item => item.Customers).reduce((a,b) => a + b, 0) * 100).toFixed(2) + '%'}</Typography></TableCell>
                          <TableCell align="right"><Typography variant="caption" style={{fontSize: '12px'}}>{(row.Data.filter(item => item.YearMo !== moment().subtract(1, 'months').format('YYYY-MM')).map(item => item.Customers_Forwards[2]).reduce((a,b) => a + b, 0) / row.Data.filter(item => item.YearMo !== moment().subtract(1, 'months').format('YYYY-MM')).map(item => item.Customers).reduce((a,b) => a + b, 0) * 100).toFixed(2) + '%'}</Typography></TableCell>
                          <TableCell align="right"><Typography variant="caption" style={{fontSize: '12px'}}>{(row.Data.filter(item => item.YearMo !== moment().subtract(1, 'months').format('YYYY-MM')).map(item => item.Customers_Forwards[3]).reduce((a,b) => a + b, 0) / row.Data.filter(item => item.YearMo !== moment().subtract(1, 'months').format('YYYY-MM')).map(item => item.Customers).reduce((a,b) => a + b, 0) * 100).toFixed(2) + '%'}</Typography></TableCell>
                          <TableCell align="right"><Typography variant="caption" style={{fontSize: '12px'}}>{(row.Data.filter(item => item.YearMo !== moment().subtract(1, 'months').format('YYYY-MM')).map(item => item.Customers_Forwards[4]).reduce((a,b) => a + b, 0) / row.Data.filter(item => item.YearMo !== moment().subtract(1, 'months').format('YYYY-MM')).map(item => item.Customers).reduce((a,b) => a + b, 0) * 100).toFixed(2) + '%'}</Typography></TableCell>
                          <TableCell align="right"><Typography variant="caption" style={{fontSize: '12px'}}>{(row.Data.filter(item => item.YearMo !== moment().subtract(1, 'months').format('YYYY-MM')).map(item => item.Customers_Forwards[5]).reduce((a,b) => a + b, 0) / row.Data.filter(item => item.YearMo !== moment().subtract(1, 'months').format('YYYY-MM')).map(item => item.Customers).reduce((a,b) => a + b, 0) * 100).toFixed(2) + '%'}</Typography></TableCell>
                          <TableCell align="right"><Typography variant="caption" style={{fontSize: '12px'}}>{(row.Data.filter(item => item.YearMo !== moment().subtract(1, 'months').format('YYYY-MM')).map(item => item.Customers_Forwards[6]).reduce((a,b) => a + b, 0) / row.Data.filter(item => item.YearMo !== moment().subtract(1, 'months').format('YYYY-MM')).map(item => item.Customers).reduce((a,b) => a + b, 0) * 100).toFixed(2) + '%'}</Typography></TableCell>
                          <TableCell align="right"><Typography variant="caption" style={{fontSize: '12px'}}>{Math.round(row.Data.filter(item => item.YearMo !== moment().subtract(1, 'months').format('YYYY-MM')).map(item => item.Sales).reduce((a,b) => a + b, 0) / row.Data.filter(item => item.YearMo !== moment().subtract(1, 'months').format('YYYY-MM')).map(item => item.Customers).reduce((a,b) => a + b, 0)).toLocaleString('en')}</Typography></TableCell>
                        </TableRow>
                      ))}
                      <TableRow style={{height: 75}}>
                        <TableCell><Typography variant="caption" style={{fontSize: '12px'}}>ALL MARKETS</Typography></TableCell>
                        {forwardsData.map(row => (
                          <TableCell key={row.key} align="right"><Typography variant="caption" style={{fontSize: '12px'}}>{row.value.toFixed(2) + '%'}</Typography></TableCell>
                        ))}
                        <TableCell align="right"><Typography variant="caption" style={{fontSize: '12px'}}>{Math.round(ltvData).toLocaleString('en')}</Typography></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Card>
              </div>
            </div>
          :
            <div className={classes.unavailable}>            
              <AskReport />
            }
            </div>
          }
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(CustRet)
