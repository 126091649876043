import React, { Component, Fragment } from 'react'
import Cookies from 'js-cookie'
import CprChart from './CprChart'
import CprStatCards from './CprStatCards'
import {
  withStyles,
  Paper,
  Card,
  GridList,
  Typography,
  Select,
  CardHeader,
  CardContent,
  Avatar,
  Link,
  CircularProgress,
  Divider,
  MenuItem,
} from '@material-ui/core'
import { Favorite, CreditCard, LocalAtm, PermContactCalendar } from '@material-ui/icons'
import CprLine from './CprLine'
import CprTotalsByYear from './CprTotalsByYear'
import moment from 'moment'
import AskReport from '../AskReport'

const styles = theme => ({
  loader: {
    marginTop: '25%',
    marginLeft: '50%'
  },
  chart: {
    height: 645,
    width: '80%',
    marginLeft: 15,
  },
  pageTitle: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '17px',
    fontWeight: 'light',
    color: 'rgba(115, 115, 115, 0.9)'
  },
  pageTitleDark: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '17px',
    fontWeight: 'light',
    color: 'rgba(255, 255, 255, 0.9)'
  },
  pageIcon: {
    fontSize: '15px',
    marginTop: '21px',
    marginRight: '2px',
  },
  pageIconDark: {
    fontSize: '15px',
    color: 'rgba(255, 255, 255, 0.9)',
    marginTop: '5px',
    marginRight: '2px',
  },
  table: {
    marginLeft: 15,
    // height: '100%',
    align: 'right'
  },
  unavailable: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // margin: '2em'
  },
})

class CprStats extends Component {
  _isMounted = false

  constructor() {
    super()
    this.state = {
      metricData: [],
      companyData: [],
      marketData: [],
      locationData: [],
      isLoading: true,
      companySelected: 'ALL BRANDS'
    }
  }

  componentDidMount = () => {
    this._isMounted = true

    Promise.all([
      fetch('https://api.dev.lsdirect.com/smartdash/V1/index.cfm/cpr/stats/market', {headers: {'token': Cookies.get('token')}}),
      fetch('https://api.dev.lsdirect.com/smartdash/V1/index.cfm/cpr/stats/location', {headers: {'token': Cookies.get('token')}}),
      fetch('https://api.dev.lsdirect.com/smartdash/V1/index.cfm/cpr/metricsYOY', {headers: {'token': Cookies.get('token')}}),
      fetch('https://api.dev.lsdirect.com/smartdash/V1/index.cfm/cpr/metricsYOY?company=1', {headers: {'token': Cookies.get('token')}}),
    ])
    .then(([resp1, resp2, resp3, resp4]) => Promise.all([resp1.json(), resp2.json(), resp3.json(), resp4.json()]))
    .then(([data1, data2, data3, data4]) => {
      if(this._isMounted && data1) {

        this.setState({
          isLoading: false,
          marketData: data1,
          locationData: data2,
          metricData: data3,
          companyData: data4,
        })
      } else {
        this.setState({
          isLoading: false,
          marketData: [],
        })
      }
    })
    .catch(error => {
      console.log(error)
    })

    if(JSON.parse(localStorage.getItem('currentUser'))[0].username.toUpperCase().includes('USER')) {
    } else {
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/pageviews/add?display=cpr&screen_name=cpr`, {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'token': Cookies.get('token'),
        },
      })
      .then(response => {
        if(response.ok === true) {
          return response.json()
        }
      })
      .catch(error => {
        console.log(error)
      })
    }
  }

  componentWillUnmount = () => {
    this._isMounted = false
  }

  handleSelectCompany = value => {
    this.setState({
      companySelected: value
    })
  }

  getCompanyList = data => {
    let companyList = []

    for(let i = 0; i < data.length; i++) {
      for(let j = 0; j < data[i].Data.length; j++) {
        if(companyList.indexOf(data[i].Data[j].Company) === -1) {
          companyList.push(data[i].Data[j].Company)
        }
      }
    }

    return companyList
  }

  getCompanySplit = (row, param) => {
    if(!this.state.companySelected.includes('ALL')) {
      let data = []
      let selectedCompanyData = row.Data.filter(item => item.Company === this.state.companySelected)


      for(let i = 0; i < selectedCompanyData.length; i++) {
        data.push(selectedCompanyData[i][`${param}_Average`])
      }

      return data

    } else {
      let data = []
      let years = []

      for(let i = 0; i < row.Data.length; i++) {
        if(years.map(item => item.year).indexOf(row.Data[i].Year) === -1) {
          years.push({year: row.Data[i].Year})
        }
      }

      for(let i = 0; i < years.length; i++) {
        years[i]['avg'] = []
        years[i]['count'] = []
        for(let j = 0; j < row.Data.length; j++) {
          if(years[i].year === row.Data[j]['Year']) {
            years[i]['avg'].push(row.Data[j][`${param}_Average`] * row.Data[j][`${param}_Count`])
            years[i]['count'].push(row.Data[j][`${param}_Count`])
          }
        }
        data.push((years[i]['avg'].reduce((a,b) => a + b, 0) / years[i]['count'].reduce((a,b) => a + b, 0)))
      }
      return data
    }
  }


  render() {
    const { classes } = this.props
    const currentUserLite = JSON.parse(localStorage.getItem('currentUser')) && JSON.parse(localStorage.getItem('currentUser'))[0].sd_lite === 1 ? true : false
    const totalCust = this.state.marketData ? this.state.marketData.map(item => item.Data.map(item => item).map(item => item.Customers).reduce((a,b) => a + b, 0)).reduce((a,b) => a + b, 0) : []
    const companiesList = this.getCompanyList(this.state.companyData)
    const current = moment().diff(moment().startOf('year'), 'months') > 3 ? this.state.locationData.map(item => item.Data.filter(item => item.Year === moment().format('YYYY'))) : this.state.locationData.map(item => item.Data.filter(item => item.Year === moment().subtract(1, 'years').format('YYYY')))
    const oneYearsPrior = moment().diff(moment().startOf('year'), 'months') > 3 ? this.state.locationData.map(item => item.Data.filter(item => item.Year === moment().subtract(1, 'years').format('YYYY'))) : this.state.locationData.map(item => item.Data.filter(item => item.Year === moment().subtract(2, 'years').format('YYYY')))
    const twoYearsPrior = moment().diff(moment().startOf('year'), 'months') > 3 ? this.state.locationData.map(item => item.Data.filter(item => item.Year === moment().subtract(2, 'years').format('YYYY'))) : this.state.locationData.map(item => item.Data.filter(item => item.Year === moment().subtract(3, 'years').format('YYYY')))
    const threeYearsPrior = moment().diff(moment().startOf('year'), 'months') > 3 ? this.state.locationData.map(item => item.Data.filter(item => item.Year === moment().subtract(3, 'years').format('YYYY'))) : this.state.locationData.map(item => item.Data.filter(item => item.Year === moment().subtract(4, 'years').format('YYYY')))
    const fourYearsPrior = moment().diff(moment().startOf('year'), 'months') > 3 ? this.state.locationData.map(item => item.Data.filter(item => item.Year === moment().subtract(4, 'years').format('YYYY'))) : this.state.locationData.map(item => item.Data.filter(item => item.Year === moment().subtract(5, 'years').format('YYYY')))
    const fiveYearsPrior = moment().diff(moment().startOf('year'), 'months') > 3 ? this.state.locationData.map(item => item.Data.filter(item => item.Year === moment().subtract(5, 'years').format('YYYY'))) : this.state.locationData.map(item => item.Data.filter(item => item.Year === moment().subtract(6, 'years').format('YYYY')))
    const color = ['#5d2e80', '#d47800', '#17ab03']
    const icons = [<PermContactCalendar size="small"/>, <CreditCard size="small"/>,  <LocalAtm size="small"/>]

    const series = [
      {
        name: moment().diff(moment().startOf('year'), 'months') > 3 ? moment().subtract(5, 'years').format('YYYY') : moment().subtract(6, 'years').format('YYYY'),
        data: fiveYearsPrior.map(item => item.map(item => item.Customers)[0])
      },
      {
        name: moment().diff(moment().startOf('year'), 'months') > 3 ? moment().subtract(4, 'years').format('YYYY') : moment().subtract(5, 'years').format('YYYY'),
        data: fourYearsPrior.map(item => item.map(item => item.Customers)[0])
      },
      {
        name: moment().diff(moment().startOf('year'), 'months') > 3 ? moment().subtract(3, 'years').format('YYYY') : moment().subtract(4, 'years').format('YYYY'),
        data: threeYearsPrior.map(item => item.map(item => item.Customers)[0])
      },
      {
        name: moment().diff(moment().startOf('year'), 'months') > 3 ? moment().subtract(2, 'years').format('YYYY') : moment().subtract(3, 'years').format('YYYY'),
        data: twoYearsPrior.map(item => item.map(item => item.Customers)[0])
      },
      {
        name: moment().diff(moment().startOf('year'), 'months') > 3 ? moment().subtract(1, 'years').format('YYYY') : moment().subtract(2, 'years').format('YYYY'),
        data: oneYearsPrior.map(item => item.map(item => item.Customers)[0])
      },
      {
        name: moment().diff(moment().startOf('year'), 'months') > 3 ? moment().format('YYYY') : moment().subtract(1, 'years').format('YYYY'),
        data: current.map(item => item.map(item => item.Customers)[0])
      },
    ]

    const seriesTotals = (series.map(item => item.data.reduce((a,b) => a + b, 0)).filter(item => !Number.isNaN(item)))

    return(
      <div>
        {this.state.isLoading ? <div className={classes.loader}><CircularProgress/></div> :
        !currentUserLite ?
        <div>
          <Typography className={this.props.darkMode ? classes.pageTitleDark : classes.pageTitle} style={{marginTop: '25px'}}>
            <Favorite className={this.props.darkMode ? classes.pageIconDark : classes.pageIcon} />
            <span style={{marginLeft: '5px'}}>CPR STATS</span>
          </Typography>
          <br/>
          <div style={{display: 'flex', textAlign: 'center'}}>
            <div>
              <CprStatCards
                darkMode={this.props.darkMode}
                data={this.state.marketData}
                metricData={this.state.metricData}
              />
            </div>
            <Paper className={classes.chart}>
              <div style={{marginTop: 20, margin: '2em'}}>
                <CprChart
                  series={series}
                  categories={this.state.locationData.map(item => item.Label)}
                  height={550}
                  title={'Customers By Year'}
                  yTitle={'Customer Counts'}
                  darkMode={this.props.darkMode}
                 />
              </div>
            </Paper>
            <Paper className={classes.table}>
              <CprTotalsByYear data={series}/>
              <div style={{marginTop: '20px'}}>
                <Typography variant="caption">
                  <span style={{fontWeight: 'bold'}}>Total Customer Count:</span>
                  <span style={{fontWeight: 'bold', fontSize: '15px', color: '#2171b5'}}>
                    {seriesTotals.reduce((a,b) => a + b, 0).toLocaleString('en')}
                  </span>
                </Typography>
              </div>

            </Paper>
          </div>

          <div>
            <GridList
              cellHeight={'auto'}
              cols={3}
              style={{justifyContent: 'space-between', marginTop: 15}}
            >
              <Card
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  border: '1px solid #4292c6',
                  position: 'relative'
                }}
              >
                <div
                  style={ JSON.parse(localStorage.getItem('currentUser'))[0].db_owner === 'FURNITUREMART' ?
                    {position: 'absolute', right: 15, marginTop: 15}
                  :
                    {display: 'none'}
                  }
                >
                  <Select
                    variant="outlined"
                    style={{width: '250px'}}
                    onChange={(e) => this.handleSelectCompany(e.target.value)}
                    value={this.state.companySelected}
                  >
                    <MenuItem value={'ALL BRANDS'}><Typography variant="caption">ALL BRANDS</Typography></MenuItem>
                    {companiesList.map((option, i) => (
                      <MenuItem key={i} value={option}><Typography variant="caption">{option.toUpperCase()}</Typography></MenuItem>
                    ))}
                  </Select>
                </div>
                {this.state.companyData.map((row, i) => (
                  <div
                    key={i}
                    style={{
                      height: 450,
                      borderRadius: 4,
                      margin: '1em',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      width: '100%',
                      marginTop: '20px'
                    }}
                  >
                    <div style={{width: '100%'}}>
                      <CardHeader
                        avatar={
                          <Avatar
                            style={{backgroundColor: 'transparent', color: `${color[i]}`}}
                            >
                              {icons[i]}
                            </Avatar>
                          }
                          title={
                            <Typography variant="body2" style={{fontWeight: 'bold'}}>
                              {row.MetricType.toUpperCase()}
                            </Typography>
                          }
                          style={{
                            color: `${color[i]}`,
                            height: 60,
                          }}
                      />
                      <CardContent>
                        <CprLine
                          height={325}
                          series={[
                            {
                              name: 'Population Average',
                              data: this.state.metricData.length > 0 ? this.getCompanySplit(row, 'Pop') : []
                            },
                            {
                              name: 'Customer Average',
                              data: this.state.metricData.length > 0 ? this.getCompanySplit(row, 'Cust') : []
                            },
                          ]}
                          categories={this.state.metricData.map(item => item.Data.map(item => item.Year))[2]}
                          xTitle={'Year'}
                          yTitle={row.MetricType}
                          darkMode={this.props.darkMode}
                        />

                      </CardContent>
                    </div>
                    <Divider orientation="vertical" style={i < this.state.companyData.length - 1 ? { backgroundColor: '#4292c6'} : {display: 'none'}}/>
                  </div>
                ))}
              </Card>
            </GridList>
          </div>
        </div>
        :
        <div className={classes.unavailable}>
        <AskReport />
        </div>
      }
      </div>
    )
  }
}

export default withStyles(styles)(CprStats)
