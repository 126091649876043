import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Tooltip,
  Fade,
  Grow,
  Slide,
  Collapse,
  SvgIcon,
} from '@material-ui/core'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import DraftsOutlinedIcon from '@material-ui/icons/DraftsOutlined'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import FeedbackOutlinedIcon from '@material-ui/icons/FeedbackOutlined'
import ReplyIcon from '@material-ui/icons/Reply'
import ReplyAllIcon from '@material-ui/icons/ReplyAll'
import LocalAtmOutlinedIcon from '@material-ui/icons/LocalAtmOutlined'
import LocalAtmTwoToneIcon from '@material-ui/icons/LocalAtmTwoTone'
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  card: {
    // margin: '1em',
    margin: '15px 0px 15px 0px',
    display: 'flex',
    height: 125,
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      width: '48%'
    },
    [theme.breakpoints.up('lg')]: {
      width: '24%'
    },
    [theme.breakpoints.up('xl')]: {
      width: '24%'
    }
  },
  cardIcon: {
    color: '#fff',
    marginLeft: '35px',
    height: 125,
    width: 50,
    justifyContent: 'center',
    [theme.breakpoints.down('lg')]: {
      height: 125,
      width: 35,
      marginLeft: '31px'
    },
    [theme.breakpoints.down('md')]: {
      height: 130,
      width: 55,
      marginLeft: '45px'
    }
  },
  cardContent: {
    flex: '1 0 auto',
  },
  statsTitle: {
    fontSize: '15px',
    fontWeight: 'bold',
    textAlign: 'right',
    // marginTop: 2,
    [theme.breakpoints.down('lg')]: {
      fontSize: '11px',
    },
  },
  stats: {
    fontSize: '27px',
    fontWeight: 'bold',
    textAlign: 'right',
    color: '#5c5c5c',
    [theme.breakpoints.down('lg')]: {
      fontSize: '23px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '23px'
    },
  },
  statsDark: {
    fontSize: '27px',
    fontWeight: 'bold',
    textAlign: 'right',
    color: '#fff',
    [theme.breakpoints.down('lg')]: {
      fontSize: '23px'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '23px'
    },
  },
  subStats: {
    fontSize: '13px',
    color: 'rgba(92, 92, 92, 0.8)',
    textAlign: 'right',
  },
  subStatsDark: {
    fontSize: '13px',
    color: 'rgba(255, 255, 255, 0.7)',
    textAlign: 'right',
  },
  help: {
    // marginTop: 2,
    marginLeft: 4,
    fontSize: 13,
    color: 'rgba(75, 75, 75, 0.5)'
  },
  helpDark: {
    // marginTop: 2,
    marginLeft: 4,
    fontSize: 13,
    color: 'rgba(255, 255, 255, 0.5)'
  },
  container: {
    maxWidth: '100%'
  },
  media: {
    fontSize: 95,
    [theme.breakpoints.down('xl')]: {
      width: 125,
    },
    [theme.breakpoints.down('lg')]: {
      width: 100,
    },
    [theme.breakpoints.down('md')]: {
      width: 150,
    }
  },
  tooltip: {
    maxWidth: 250,
    textAlign: 'center',
    fontSize: '12px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '10px',
    }
  }
}))

function CashSolidIcon(props) {
  return(
    <SvgIcon {...props}>
      <path fill="currentColor" d="M20 4H4C2.89 4 2 4.89 2 6V18C2 19.11 2.9 20 4 20H20C21.11 20 22 19.11 22 18V6C22 4.89 21.1 4 20 4M15 10H11V11H14C14.55 11 15 11.45 15 12V15C15 15.55 14.55 16 14 16H13V17H11V16H9V14H13V13H10C9.45 13 9 12.55 9 12V9C9 8.45 9.45 8 10 8H11V7H13V8H15V10Z" />
    </SvgIcon>
  )
}

export default function Stats(props) {
  const classes = useStyles()
  const { Mailed, Responses, Cost, Sales, HO_Resp, HO_Count, HO_Sales } = props.data

  return(
    <div className={classes.root}>
      <Grid
        container
        justify='space-between'
      >
        <Fade in={true} timeout={1000}>
          <Card className={classes.card} style={{border: '1px solid #ba423a'}}>
            <CardMedia>
              <div style={{background: `linear-gradient(to right bottom, #f55247, #ba423a)`}} className={classes.media}>
                <CreditCardIcon
                  className={classes.cardIcon}
                />
              </div>
            </CardMedia>
            <CardContent className={classes.cardContent}>
              <Typography className={classes.statsTitle} style={{color: '#ba423a'}}>
                Spend
                <Tooltip classes={{tooltip: classes.tooltip}} title="Total cost of mailing" placement="top">
                  <InfoOutlinedIcon fontSize="small" className={props.darkMode ? classes.helpDark : classes.help} />
                </Tooltip>
              </Typography>
              <Typography className={props.darkMode ? classes.statsDark : classes.stats}>
                {props.data.Cost ? '$' + Math.round(props.data.Cost).toLocaleString('en') : '$0'}
              </Typography>
            </CardContent>
          </Card>
        </Fade>

        <Fade in={true} timeout={1000} style={{transitionDelay: '300ms'}}>
          <Card className={classes.card} style={{border: '1px solid #006cc4'}}>
            <CardMedia>
              <div style={{background: `linear-gradient(to right bottom, #39d1ff, #006cc4)`}} className={classes.media}>
                <ReplyAllIcon
                  className={classes.cardIcon}
                />
              </div>
            </CardMedia>
            <CardContent className={classes.cardContent}>
              <Typography className={classes.statsTitle} style={props.darkMode ? {color: '#8acade'} : {color: '#006cc4'}}>
                Responses
                <Tooltip classes={{tooltip: classes.tooltip}} title="Total number of orders placed by everyone mailed during the promotional window" placement="top">
                  <InfoOutlinedIcon fontSize="small" className={props.darkMode ? classes.helpDark : classes.help} />
                </Tooltip>
              </Typography>
              <Typography className={props.darkMode ? classes.statsDark : classes.stats}>
                {props.data.Responses ? Math.round(props.data.Responses).toLocaleString('en') : 0}
              </Typography>
              <Typography className={props.darkMode ? classes.subStatsDark : classes.subStats}>
                {props.data.Mailed ? ((props.data.Responses / props.data.Mailed) * 100).toFixed(1) + '%' : '0%'}
              </Typography>
            </CardContent>
          </Card>
        </Fade>

        <Fade in={true} timeout={1000} style={{transitionDelay: '50ms'}}>
          <Card className={classes.card} style={{border: '1px solid #529c00'}}>
            <CardMedia>
              <div style={{background: `linear-gradient(to right bottom, #9cf03c, #529c00)`}} className={classes.media}>
                <CashSolidIcon className={classes.cardIcon} />
              </div>
            </CardMedia>
            <CardContent className={classes.cardContent}>
              <Typography className={classes.statsTitle} style={{color: '#529c00'}}>
                Sales
                <Tooltip classes={{tooltip: classes.tooltip}} title="Total revenue generated by everyone mailed during the promotional window" placement="top">
                  <InfoOutlinedIcon fontSize="small" className={props.darkMode ? classes.helpDark : classes.help} />
                </Tooltip>
              </Typography>
              <Typography className={props.darkMode ? classes.statsDark : classes.stats}>
                {props.data.Sales ? '$' + Math.round(props.data.Sales).toLocaleString('en') : '$0'}
              </Typography>
            </CardContent>
          </Card>
        </Fade>

        <Fade in={true} timeout={1000} style={{transitionDelay: '100ms'}}>
          <Card className={classes.card} style={{border: '1px solid #14a899'}}>
            <CardMedia>
              <div style={{background: `linear-gradient(to right bottom, #32cfbf, #14a899)`}} className={classes.media}>
                <MonetizationOnIcon
                  className={classes.cardIcon}
                />
              </div>
            </CardMedia>
            <CardContent className={classes.cardContent}>
              <Typography className={classes.statsTitle} style={{color: '#14a899'}}>
                ROAS
                <Tooltip classes={{tooltip: classes.tooltip}} title="Revenue generated for each dollar spent on advertising" placement="top">
                  <InfoOutlinedIcon fontSize="small" className={props.darkMode ? classes.helpDark : classes.help} />
                </Tooltip>
              </Typography>
              <Typography className={props.darkMode ? classes.statsDark : classes.stats}>
                {props.data.Cost > 0 ? '$' + (props.data.Sales / props.data.Cost).toFixed(2) :  '$0'}
              </Typography>
            </CardContent>
          </Card>
        </Fade>

        <Fade in={true} timeout={1000} style={{transitionDelay: '150ms'}}>
          <Card className={classes.card} style={props.darkMode ? {border: '1px solid #876a9e'} : {border: '1px solid #5d2e80'}}>
            <CardMedia>
              <div style={{background: `linear-gradient(to right bottom, #b345ff, #5d2e80)`}} className={classes.media}>
                <MailOutlineIcon
                  className={classes.cardIcon}
                />
              </div>
            </CardMedia>
            <CardContent className={classes.cardContent}>
              <Typography className={classes.statsTitle} style={props.darkMode ? {color: '#a482bf'} : {color: '#5d2e80'}}>
                Mailed
                <Tooltip classes={{tooltip: classes.tooltip}} title="Total quantity of pieces mailed" placement="top">
                  <InfoOutlinedIcon fontSize="small" className={props.darkMode ? classes.helpDark : classes.help} />
                </Tooltip>
              </Typography>
              <Typography className={props.darkMode ? classes.statsDark : classes.stats}>
                {props.data.Mailed ? Math.round(props.data.Mailed).toLocaleString('en') : 0}
              </Typography>
            </CardContent>
          </Card>
        </Fade>

        <Fade in={true} timeout={1000} style={{transitionDelay: '350ms'}}>
          <Card className={classes.card} style={{border: '1px solid #942e92'}}>
            <CardMedia>
              <div style={{background: `linear-gradient(to right bottom, #f24bef, #942e92)`}} className={classes.media}>
                <ReplyIcon
                  className={classes.cardIcon}
                />
              </div>
            </CardMedia>
            <CardContent className={classes.cardContent}>
              <Typography display={"block"} className={classes.statsTitle} style={props.darkMode ? {color: '#de8edd'} : {color: '#942e92'}}>
                {/* <div style={{display: 'inline-flex'}}> */}
                  Incremental Responses
                  <Tooltip classes={{tooltip: classes.tooltip}} title="Total number of orders placed scaled down based on the relative performance of the holdout group compared to the mailed group" placement="top">
                    <InfoOutlinedIcon fontSize="small" className={props.darkMode ? classes.helpDark : classes.help} />
                  </Tooltip>
                {/* </div> */}
              </Typography>                            
              {console.log(Object.keys(props.data).length)}
              <Typography className={props.darkMode ? classes.statsDark : classes.stats}>

                {Object.keys(props.data).length > 0 ?
                  props.data.HO_Count > 0 ? 
                  (props.data.Responses / props.data.Mailed) - (props.data.HO_Resp / props.data.HO_Count) > 0 ? Math.round(props.data.Mailed * ((props.data.Responses / props.data.Mailed) - (props.data.HO_Resp / props.data.HO_Count))).toLocaleString('en') : 0
                  : props.data.Responses.toLocaleString("en") : 0
                }              

              </Typography>
              <Typography className={props.darkMode ? classes.subStatsDark : classes.subStats} style={{display: `${props.data.HO_Count <= 0 ? "none" : ""}`}}>
                {props.data.HO_Count > 0 ? (props.data.Responses / props.data.Mailed) - (props.data.HO_Resp / props.data.HO_Count) > 0 ? ((props.data.Responses / props.data.Mailed) / (props.data.HO_Resp / props.data.HO_Count)).toFixed(1) + 'x' : null : '0x'}
              </Typography>
            </CardContent>
          </Card>
        </Fade>

        <Fade in={true} timeout={1000} style={{transitionDelay: '100ms'}}>
          <Card className={classes.card} style={{border: '1px solid #b09e00'}}>
            <CardMedia>
              <div style={{background: `linear-gradient(to right bottom, #e6d31c, #b09e00)`}} className={classes.media}>
                <LocalAtmOutlinedIcon
                  className={classes.cardIcon}
                />
              </div>
            </CardMedia>
            <CardContent className={classes.cardContent}>
              <Typography className={classes.statsTitle} style={{color: '#b09e00'}}>
                Incremental Sales
                <Tooltip classes={{tooltip: classes.tooltip}} title="Total revenue scaled down based on the relative performance of the holdout group compared to the mailed group" placement="top">
                  <InfoOutlinedIcon fontSize="small" className={props.darkMode ? classes.helpDark : classes.help} />
                </Tooltip>
              </Typography>
              <Typography className={props.darkMode ? classes.statsDark : classes.stats}>
                {Object.keys(props.data).length > 0 ? props.data.HO_Count > 0 ? (props.data.Responses / props.data.Mailed) - (props.data.HO_Resp / props.data.HO_Count) > 0 ?
                  '$' + Math.round((props.data.Sales / (props.data.Responses / props.data.Mailed)) * ((props.data.Responses / props.data.Mailed) - (props.data.HO_Resp / props.data.HO_Count))).toLocaleString('en') : "$0"
                  : '$' + Math.round(props.data.Sales).toLocaleString("en") : "$0"
                }
              </Typography>
            </CardContent>
          </Card>
        </Fade>

        <Fade in={true} timeout={1000} style={{transitionDelay: '350ms'}}>
          <Card className={classes.card} style={{border: '1px solid #bf4c17'}}>
            <CardMedia>
              <div style={{background: `linear-gradient(to right bottom, #fc9362, #bf4c17)`}} className={classes.media}>
                <MonetizationOnOutlinedIcon
                  className={classes.cardIcon}
                />
              </div>
            </CardMedia>
            <CardContent className={classes.cardContent}>
              <Typography className={classes.statsTitle} style={{color: '#bf4c17'}}>
                {/* <div style={{display: 'inline-flex'}}> */}
                  Incremental ROAS
                  <Tooltip classes={{tooltip: classes.tooltip}} title="Incremental revenue generated for each dollar spent on advertising" placement="top">
                    <InfoOutlinedIcon fontSize="small" className={props.darkMode ? classes.helpDark : classes.help} />
                  </Tooltip>
                {/* </div> */}
              </Typography>
              <Typography className={props.darkMode ? classes.statsDark : classes.stats}>
                {
                 Object.keys(props.data).length > 0 ? props.data.HO_Count > 0 ? (props.data.Responses / props.data.Mailed) - (props.data.HO_Resp / props.data.HO_Count) > 0 ? '$' + ((props.data.Sales / (props.data.Responses / props.data.Mailed)) * ((props.data.Responses / props.data.Mailed) - (props.data.HO_Resp / props.data.HO_Count)) / props.data.Cost).toFixed(2) : "$0" : '$' + (props.data.Sales / props.data.Cost).toFixed(2) : "$0"
                }
              </Typography>
            </CardContent>
          </Card>
        </Fade>
      </Grid>
    </div>
  )
}
