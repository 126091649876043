import React, { Component, Fragment } from 'react'
import {
  withStyles,
  CircularProgress,
  Typography,
  Fade,
  Menu,
  MenuItem,
  Snackbar,
  SnackbarContent,
  InputBase,
  Select,
  IconButton,
  Chip,
  Checkbox,
} from '@material-ui/core'
import Cookies from 'js-cookie'
import TelegramIcon from '@material-ui/icons/Telegram'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import WarningIcon from '@material-ui/icons/Warning'
import Stats from '../roi/Stats'
import CustProsTable from '../roi/CustProsTable'
import RoiCharts from '../roi/RoiCharts'
import TriggerTypesTable from './TriggerTypesTable'
import TrigLocationsTable from './TrigLocationsTable'
import DatePicker from '../calendar/DatePicker'
import DayPicker from '../calendar/DayPicker'
import roiMainStandIn from '../../assets/mainStandIns/roiMainStandIn.png'
import RoiExcel from '../roi/RoiExcel'
import AskReport from '../AskReport'
import UpdatedThrough from '../UpdatedThrough'

import moment from 'moment'

const styles = theme => ({
  loader: {
    marginTop: '25%',
    marginLeft: '50%'
  },
  pageTitle:  {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '17px',
    fontWeight: 'light',
    color: 'rgba(115, 115, 115, 0.9)'
  },
  pageTitleDark:  {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '17px',
    fontWeight: 'light',
    color: 'rgba(255, 255, 255, 0.9)'
  },
  chart: {
    padding: theme.spacing(5)
  },
  pageIcon: {
    fontSize: '15px',
    marginTop: '21px',
    marginRight: '2px'
  },
  pageIconDark: {
    fontSize: '15px',
    color: 'rgba(255, 255, 255, 0.9)',
    marginTop: '5px',
    marginRight: '2px',
  },
  mailingType: {
    color: '#001d84',
    '&:hover': {
      cursor: 'pointer',
    }
  },
  mailingTypeDark: {
    color: '#4ab8bb',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  unavailable: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // margin: '2em'
  },
  warning: {
    border: '3px solid #de9f10',
    borderRadius: 4,
    textAlign: 'center'
  },
  hidden: {
    display: 'none'
  },
  chip: {
    margin: 1,
    height: '20px',
    padding: '0px 2px 0px 2px',
    border: '1px solid #636768',
    fontSize: '10px',
    backgroundColor: 'rgba(231, 231, 231, 0.6)',
  },
  chips: {
    display: 'flex',
  }
})

const StyledInput = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    overflowX: 'auto',
    position: 'relative',
    padding: '2px 5px 2px 5px',
    border: '1px solid #767676',
    fontSize: 7,
    // width: 200,
    // maxWidth: 350,
    '&:focus': {
      border: '1px solid',
      borderRadius: 4,
      borderColor: '#0054c1',
      backgroundColor: 'transparent',
    }
  }
}))(InputBase)

class Triggers extends Component {
  _isMounted = false

  constructor() {
    super()
    this.state = {
      triggerData: [],
      custPros: [],
      trigType: [],
      weeklyInc: [],
      triggerMarketData: [],
      corpID: JSON.parse(localStorage.getItem('currentUser'))[0].corporateID,
      startDate: moment().diff(moment().startOf('year'), 'months') > 3 ? JSON.parse(localStorage.getItem('currentUser'))[0].db_owner === 'KHOOK' ? moment(JSON.parse(localStorage.getItem('currentUser'))[0].max_date_trig).startOf('year') : moment().startOf('year') : moment().startOf('year').subtract(1, 'years'),
      initialStartDate: moment().diff(moment().startOf('year'), 'months') > 3 ? JSON.parse(localStorage.getItem('currentUser'))[0].db_owner === 'KHOOK' ? moment(JSON.parse(localStorage.getItem('currentUser'))[0].max_date_trig).startOf('year') : moment().startOf('year') : moment().startOf('year').subtract(1, 'years'),
      endDate: JSON.parse(localStorage.getItem('currentUser'))[0].max_date_trig ? moment(JSON.parse(localStorage.getItem('currentUser'))[0].max_date_trig) : moment(),
      initialEndDate: JSON.parse(localStorage.getItem('currentUser'))[0].max_date_trig ? moment(JSON.parse(localStorage.getItem('currentUser'))[0].max_date_trig) : moment(),
      isLoading: true,
      marketLoading: false,
      mailingOpen: false,
      mailingType: JSON.parse(localStorage.getItem('currentUser'))[0].compIDs === '382' || JSON.parse(localStorage.getItem('currentUser'))[0].compIDs === '316' || JSON.parse(localStorage.getItem('currentUser'))[0].roi_all_mailings_default === 1 ? 'all' : 'completed',
      anchor: null,
      complete: 1,
      hasData: false,
      snackbar: false,
      errorMessage: '',
      dataOverall: [],
      dataWeekly: [],
      dataCustPros: [],
      dataTrigType: [],
      selectedMarkets: ['ALL'],
      selectedType: 'market',
      maxYearmo: JSON.parse(localStorage.getItem('currentUser'))[0].max_date_trig ? moment(JSON.parse(localStorage.getItem('currentUser'))[0].max_date_trig) : moment(),
      minYearmo: moment().diff(moment().startOf('year'), 'months') > 3 ? JSON.parse(localStorage.getItem('currentUser'))[0].db_owner === 'KHOOK' ? moment(JSON.parse(localStorage.getItem('currentUser'))[0].max_date_trig).startOf('year') : moment().startOf('year') : moment().startOf('year').subtract(1, 'years'),
      days: 0,
      restoreHidden: true,
      checked: [],
      bUnit: JSON.parse(localStorage.getItem('currentUser'))[0].businessUnitID,
      updatedDate: null,
    }

    this.handleDateClear = this.handleDateClear.bind(this)
  }

  handleDateRange = (value, valName) => {
    const myHeaders = {'token': Cookies.get('token')}
    const trigData = []
    let complete = this.state.mailingType === 'completed' ? 1 : 0

    this.setState({
      marketLoading: true
    })

    if(valName === 'startDate') {
      if(this.state.selectedType === 'market') {
        Promise.all([
          fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI?startDate=${value.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
          fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI/custPros?startDate=${value.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
          fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerRoi/trigType?startDate=${value.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
          fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI/weekly?startDate=${value.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders,}),
          fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI/dashboard/${this.state.selectedType}?startDate=${value.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders,}),
          fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI/dashboard?startDate=${value.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders,})
        ])
        .then(([response1, response2, response3, response4, response5, response6]) => Promise.all([response1.json(), response2.json(), response3.json(), response4.json(), response5.json(), response6.json()]))
        .then(([data1, data2, data3, data4, data5, data6]) => {

          if(data1) {
            let trigObj = {}
            trigObj['Data_CustPros'] = data2
            trigObj['Data_Location'] = data6[0].Data_Location
            trigObj['Data_TrigType'] = data3
            trigObj['Data_Weekly'] = data4
            trigObj['Data_Overall'] = data1[0].Data
            trigObj['Label'] = 'ALL'
            trigObj['Key'] = data5.length + 1
            trigData.push(trigObj)

            this.setState({
              isLoading: false,
              hasData: true,
              triggerData: trigData.concat(data5),
              selectedTriggerData: trigData.concat(data5).filter(item => this.state.selectedMarkets.includes(item.Label)),
              triggerMarketData: data5,
              restoreHidden: false,
              days: this.state.endDate.diff(value, 'days'),
              [valName]: value,
              marketLoading: false,
            })

            this.handleMarketChange(this.state.selectedMarkets)
          } else {
            this.setState({
              isLoading: false,
              snackbar: true,
              errorMessage: 'Trigger ROI is not available for the selected dates. Please broaden the date range',
              days: this.state.endDate.diff(value, 'days'),
              restoreHidden: false,
              startDate: value,
              marketLoading: false,
            })
          }
        })
      } else {
        Promise.all([
          fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI?startDate=${value.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
          fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI/custPros?startDate=${value.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
          fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerRoi/trigType?startDate=${value.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
          fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI/weekly?startDate=${value.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders,}),
          fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI/dashboard/${this.state.selectedType}?startDate=${value.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${1}`, {headers: myHeaders,})
        ])
        .then(([response1, response2, response3, response4, response5]) => Promise.all([response1.json(), response2.json(), response3.json(), response4.json(), response5.json()]))
        .then(([data1, data2, data3, data4, data5]) => {

          if(data1) {
            let trigObj = {}
            trigObj['Data_CustPros'] = data2
            trigObj['Data_TrigType'] = data3
            trigObj['Data_Weekly'] = data4
            trigObj['Data_Overall'] = data1[0].Data
            trigObj['Label'] = 'ALL'
            trigObj['Key'] = data5.length + 1
            trigData.push(trigObj)

            console.log('hey')
            console.log(data5)

            this.setState({
              isLoading: false,
              hasData: true,
              triggerData: trigData.concat(data5),
              selectedTriggerData: trigData.concat(data5).filter(item => this.state.selectedMarkets.includes(item.Label)),
              triggerMarketData: data5,
              restoreHidden: false,
              days: this.state.endDate.diff(value, 'days'),
              [valName]: value,
              marketLoading: false,
            })

            this.handleMarketChange(this.state.selectedMarkets)
          } else {
            this.setState({
              isLoading: false,
              snackbar: true,
              errorMessage: 'Trigger ROI is not available for the selected dates. Please broaden the date range',
              days: this.state.endDate.diff(value, 'days'),
              restoreHidden: false,
              startDate: value,
              triggerData: [],
              selectedTriggerData: [],
              triggerMarketData: [],
              marketLoading: false,
            })
          }
        })
      }
    } else {
      if(this.state.selectedType === 'market') {
        Promise.all([
          fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${value.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
          fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI/custPros?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${value.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
          fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerRoi/trigType?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${value.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
          fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI/weekly?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${value.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders,}),
          fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI/dashboard/${this.state.selectedType}?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${value.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders,}),
          fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI/dashboard?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${value.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders,})
        ])
        .then(([response1, response2, response3, response4, response5, response6]) => Promise.all([response1.json(), response2.json(), response3.json(), response4.json(), response5.json(), response6.json()]))
        .then(([data1, data2, data3, data4, data5, data6]) => {

          if(data1) {
            let trigObj = {}
            trigObj['Data_CustPros'] = data2
            trigObj['Data_Location'] = data6[0].Data_Location
            trigObj['Data_TrigType'] = data3
            trigObj['Data_Weekly'] = data4
            trigObj['Data_Overall'] = data1[0].Data
            trigObj['Label'] = 'ALL'
            trigObj['Key'] = data5.length + 1
            trigData.push(trigObj)

            console.log('hello')
            console.log(data5)

            this.setState({
              isLoading: false,
              hasData: true,
              triggerData: trigData.concat(data5),
              selectedTriggerData: trigData.concat(data5).filter(item => this.state.selectedMarkets.includes(item.Label)),
              triggerMarketData: data5,
              restoreHidden: false,
              days: value.diff(this.state.startDate, 'days'),
              [valName]: value,
              marketLoading: false,

            })

            this.handleMarketChange(this.state.selectedMarkets)
          } else {
            this.setState({
              isLoading: false,
              snackbar: true,
              errorMessage: 'Trigger ROI is not available for the selected dates. Please broaden the date range',
              days: value.diff(this.state.startDate, 'days'),
              restoreHidden: false,
              triggerData: [],
              selectedTriggerData: [],
              triggerMarketData: [],
              endDate: value,
              marketLoading: false,
            })
          }
        })
      } else {
        Promise.all([
          fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${value.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
          fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI/custPros?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${value.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
          fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerRoi/trigType?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${value.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
          fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI/weekly?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${value.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders,}),
          fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI/dashboard/${this.state.selectedType}?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${value.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders,})
        ])
        .then(([response1, response2, response3, response4, response5]) => Promise.all([response1.json(), response2.json(), response3.json(), response4.json(), response5.json()]))
        .then(([data1, data2, data3, data4, data5]) => {

          if(data1) {
            let trigObj = {}
            trigObj['Data_CustPros'] = data2
            trigObj['Data_TrigType'] = data3
            trigObj['Data_Weekly'] = data4
            trigObj['Data_Overall'] = data1[0].Data
            trigObj['Label'] = 'ALL'
            trigObj['Key'] = data5.length + 1
            trigData.push(trigObj)

            this.setState({
              isLoading: false,
              hasData: true,
              triggerData: trigData.concat(data5),
              selectedTriggerData: trigData.concat(data5).filter(item => this.state.selectedMarkets.includes(item.Label)),
              triggerMarketData: data5,
              restoreHidden: false,
              days: value.diff(this.state.startDate, 'days'),
              [valName]: value,
              marketLoading: false,

            })

            this.handleMarketChange(this.state.selectedMarkets)
          } else {
            this.setState({
              isLoading: false,
              snackbar: true,
              errorMessage: 'Trigger ROI is not available for the selected dates. Please broaden the date range',
              days: value.diff(this.state.startDate, 'days'),
              restoreHidden: false,
              triggerData: [],
              selectedTriggerData: [],
              triggerMarketData: [],
              endDate: value,
              marketLoading: false,
            })
          }
        })
      }
    }
  }

  handleSliderDateChange = (values) => {
    const myHeaders = {'token': Cookies.get('token')}
    const trigData = []
    let complete = this.state.mailingType === 'completed' ? 1 : 0

    const startDate = values[0]
    const endDate = values[1]
    if(this.state.selectedType === 'market') {
      Promise.all([
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI?startDate=${startDate}&endDate=${endDate}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI/custPros?startDate=${startDate}&endDate=${endDate}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerRoi/trigType?startDate=${startDate}&endDate=${endDate}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI/weekly?startDate=${startDate}&endDate=${endDate}&complete=${complete}`, {headers: myHeaders,}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI/dashboard/${this.state.selectedType}?startDate=${startDate}&endDate=${endDate}&complete=${complete}`, {headers: myHeaders,}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI/dashboard?startDate=${startDate}&endDate=${endDate}&complete=${complete}`, {headers: myHeaders,})
      ])
      .then(([response1, response2, response3, response4, response5, response6]) => Promise.all([response1.json(), response2.json(), response3.json(), response4.json(), response5.json(), response6.json()]))
      .then(([data1, data2, data3, data4, data5, data6]) => {
        let trigObj = {}

        if(this._isMounted && data1) {
          trigObj['Data_CustPros'] = data2
          trigObj['Data_Location'] = data6[0].Data_Location
          trigObj['Data_TrigType'] = data3
          trigObj['Data_Weekly'] = data4
          trigObj['Data_Overall'] = data1[0].Data
          trigObj['Label'] = 'ALL'
          trigObj['Key'] = data5.length + 1
          trigData.push(trigObj)

          this.setState({
            isLoading: false,
            hasData: true,
            triggerData: trigData.concat(data5),
            selectedTriggerData: trigData.concat(data5).filter(item => this.state.selectedMarkets.includes(item.Label)),
            triggerMarketData: data5,
            startDate: moment(values[0]),
            endDate: moment(values[1]),
            restoreHidden: false,
          })

          this.handleMarketChange(this.state.selectedMarkets)
        } else {
          this.setState({
            isLoading: false,
            snackbar: true,
            errorMessage: 'Trigger ROI is not available for the selected dates. Please broaden the date range',
            restoreHidden: false,
            triggerData: [],
            selectedTriggerData: [],
            triggerMarketData: [],
            startDate: moment(values[0]),
            endDate: moment(values[1]),
          })

        }
      })
    } else {
      Promise.all([
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI?startDate=${startDate}&endDate=${endDate}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI/custPros?startDate=${startDate}&endDate=${endDate}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerRoi/trigType?startDate=${startDate}&endDate=${endDate}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI/weekly?startDate=${startDate}&endDate=${endDate}&complete=${complete}`, {headers: myHeaders,}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI/dashboard/${this.state.selectedType}?startDate=${startDate}&endDate=${endDate}&complete=${complete}`, {headers: myHeaders,}),
      ])
      .then(([response1, response2, response3, response4, response5]) => Promise.all([response1.json(), response2.json(), response3.json(), response4.json(), response5.json()]))
      .then(([data1, data2, data3, data4, data5]) => {

        if(this._isMounted && data1) {
          let trigObj = {}
          trigObj['Data_CustPros'] = data2
          trigObj['Data_TrigType'] = data3
          trigObj['Data_Weekly'] = data4
          trigObj['Data_Overall'] = data1[0].Data
          trigObj['Label'] = 'ALL'
          trigObj['Key'] = data5.length + 1
          trigData.push(trigObj)

          this.setState({
            isLoading: false,
            hasData: true,
            triggerData: trigData.concat(data5),
            selectedTriggerData: trigData.concat(data5).filter(item => this.state.selectedMarkets.includes(item.Label)),
            triggerMarketData: data5,
            startDate: moment(values[0]),
            endDate: moment(values[1]),
            restoreHidden: false,
          })

          this.handleMarketChange(this.state.selectedMarkets)
        } else {
          this.setState({
            isLoading: false,
            snackbar: true,
            errorMessage: 'Trigger ROI is not available for the selected dates. Please broaden the date range',
            restoreHidden: false,
            triggerData: [],
            selectedTriggerData: [],
            triggerMarketData: [],
            startDate: moment(values[0]),
            endDate: moment(values[1]),
          })
        }
      })
    }
  }

  handleDateClear() {
    const myHeaders = {'token': Cookies.get('token')}
    let trigData = []
    let complete = this.state.mailingType === 'completed' ? 1 : 0

    if(this.state.selectedType === 'market') {
      Promise.all([
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI?startDate=${this.state.initialStartDate.format('YYYY-MM-DD')}&endDate=${this.state.initialEndDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI/custPros?startDate=${this.state.initialStartDate.format('YYYY-MM-DD')}&endDate=${this.state.initialEndDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerRoi/trigType?startDate=${this.state.initialStartDate.format('YYYY-MM-DD')}&endDate=${this.state.initialEndDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI/weekly?startDate=${this.state.initialStartDate.format('YYYY-MM-DD')}&endDate=${this.state.initialEndDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI/dashboard/${this.state.selectedType}?startDate=${this.state.initialStartDate.format('YYYY-MM-DD')}&endDate=${this.state.initialEndDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI/dashboard?startDate=${this.state.initialStartDate.format('YYYY-MM-DD')}&endDate=${this.state.initialEndDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
      ])
      .then(([resp1, resp2, resp3, resp4, resp5, resp6]) => Promise.all([resp1.json(), resp2.json(), resp3.json(), resp4.json(), resp5.json(), resp6.json()]))
      .then(([data1, data2, data3, data4, data5, data6]) => {
        let trigObj = {}
        trigObj['Data_CustPros'] = data2
        trigObj['Data_Location'] = data6[0].Data_Location
        trigObj['Data_TrigType'] = data3
        trigObj['Data_Weekly'] = data4
        trigObj['Data_Overall'] = data1[0].Data
        trigObj['Label'] = 'ALL'
        trigObj['Key'] = data5.length + 1
        trigData.push(trigObj)

        if(data1) {
          this.setState({
            isLoading: false,
            hasData: true,
            triggerData: trigData.concat(data5),
            selectedTriggerData: trigData.concat(data5).filter(item => this.state.selectedMarkets.includes(item.Label)),
            triggerMarketData: data5,
            restoreHidden: true,
            startDate: this.state.initialStartDate,
            endDate: this.state.initialEndDate,
            minYearmo: this.state.initialStartDate,
            maxYearmo: this.state.initialEndDate,
            days: this.state.initialEndDate.diff(this.state.initialStartDate, 'days'),
          })

          this.handleMarketChange(this.state.selectedMarkets)
        } else {
          this.setState({
            isLoading: false,
            hasData: false,
          })
        }
      })
      .catch(error => {
        console.log(error)
      })
    } else {
      Promise.all([
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI?startDate=${this.state.initialStartDate.format('YYYY-MM-DD')}&endDate=${this.state.initialEndDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI/custPros?startDate=${this.state.initialStartDate.format('YYYY-MM-DD')}&endDate=${this.state.initialEndDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerRoi/trigType?startDate=${this.state.initialStartDate.format('YYYY-MM-DD')}&endDate=${this.state.initialEndDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI/weekly?startDate=${this.state.initialStartDate.format('YYYY-MM-DD')}&endDate=${this.state.initialEndDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI/dashboard/${this.state.selectedType}?startDate=${this.state.initialStartDate.format('YYYY-MM-DD')}&endDate=${this.state.initialEndDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
      ])
      .then(([resp1, resp2, resp3, resp4, resp5]) => Promise.all([resp1.json(), resp2.json(), resp3.json(), resp4.json(), resp5.json()]))
      .then(([data1, data2, data3, data4, data5]) => {
        let trigObj = {}
        trigObj['Data_CustPros'] = data2
        trigObj['Data_TrigType'] = data3
        trigObj['Data_Weekly'] = data4
        trigObj['Data_Overall'] = data1[0].Data
        trigObj['Label'] = 'ALL'
        trigObj['Key'] = data5.length + 1
        trigData.push(trigObj)

        if(data1) {
          this.setState({
            isLoading: false,
            hasData: true,
            triggerData: trigData.concat(data5),
            selectedTriggerData: trigData.concat(data5).filter(item => this.state.selectedMarkets.includes(item.Label)),
            triggerMarketData: data5,
            restoreHidden: true,
            startDate: this.state.initialStartDate,
            endDate: this.state.initialEndDate,
            minYearmo: this.state.initialStartDate,
            maxYearmo: this.state.initialEndDate,
            days: this.state.initialEndDate.diff(this.state.initialStartDate, 'days'),
          })

          this.handleMarketChange(this.state.selectedMarkets)
        } else {
          this.setState({
            isLoading: false,
            hasData: false,
          })
        }
      })
      .catch(error => {
        console.log(error)
      })
    }
  }

  componentDidMount() {
    this._isMounted = true
    const myHeaders = {'token': Cookies.get('token')}
    let completed = this.state.mailingType === 'completed' ? 1 : 0
    let trigData = []

    console.log(completed)
    console.log(this.state.mailingType)

    Promise.all([
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI?startDate=${this.state.initialStartDate.format('YYYY-MM-DD')}&endDate=${this.state.initialEndDate.format('YYYY-MM-DD')}&complete=${completed}`, {headers: myHeaders}),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI/custPros?startDate=${this.state.initialStartDate.format('YYYY-MM-DD')}&endDate=${this.state.initialEndDate.format('YYYY-MM-DD')}&complete=${completed}`, {headers: myHeaders}),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerRoi/trigType?startDate=${this.state.initialStartDate.format('YYYY-MM-DD')}&endDate=${this.state.initialEndDate.format('YYYY-MM-DD')}&complete=${completed}`, {headers: myHeaders}),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI/weekly?startDate=${this.state.initialStartDate.format('YYYY-MM-DD')}&endDate=${this.state.initialEndDate.format('YYYY-MM-DD')}&complete=${completed}`, {headers: myHeaders,}),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI/dashboard/market?startDate=${this.state.initialStartDate.format('YYYY-MM-DD')}&endDate=${this.state.initialEndDate.format('YYYY-MM-DD')}&complete=${completed}`, {headers: myHeaders,}),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI/dashboard?startDate=${this.state.initialStartDate.format('YYYY-MM-DD')}&endDate=${this.state.initialEndDate.format('YYYY-MM-DD')}&complete=${completed}`, {headers: myHeaders,}),
    ])
    .then(([resp1, resp2, resp3, resp4, resp5, resp6]) => Promise.all([resp1.json(), resp2.json(), resp3.json(), resp4.json(), resp5.json(), resp6.json()]))
    .then(([data1, data2, data3, data4, data5, data6]) => {
      if(this._isMounted && data1.length > 0 && JSON.parse(localStorage.getItem('currentUser'))[0].compIDs !== '260') {

        let trigObj = {}
        trigObj['Data_CustPros'] = data2
        trigObj['Data_Location'] = data6[0].Data_Location
        trigObj['Data_TrigType'] = data3
        trigObj['Data_Weekly'] = data4
        trigObj['Data_Overall'] = data1[0].Data
        trigObj['Label'] = 'ALL'
        trigObj['Key'] = data5.length + 1
        trigData.push(trigObj)

        this.setState({
          isLoading: false,
          hasData: true,
          triggerData: trigData.concat(data5),
          selectedTriggerData: trigData.filter(item => item.Label === 'ALL'),
          triggerMarketData: data5,
          days: this.state.initialEndDate.diff(this.state.initialStartDate, 'days'),
          checked: trigData.concat(data5).map(item => item.Label),
          updatedDate: moment(data6[0].LastTransDate)
        })
      } else {
        this.setState({
          isLoading: false,
          hasData: false,
        })
      }
    })
    .catch(error => {
      console.log(error)
    })

    if(JSON.parse(localStorage.getItem('currentUser'))[0].username.toUpperCase().includes('USER')) {
    } else {
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/pageviews/add?display=triggers&screen_name=triggers`, {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'token': Cookies.get('token'),
        },
      })
      .then(response => {
        if(response.ok === true) {
          return response.json()
        }
      })
      .catch(error => {
        console.log(error)
      })
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  handleSnackbarClose = (e, reason) => {
    if(reason === 'clickaway') {
      this.setState({
        snackbar: false,
      })
    }
    this.setState({
      snackbar: false
    })
  }

  handleSelectedType = value => {
    const myHeaders = {'token': Cookies.get('token')}
    let trigData = []
    let complete = this.state.mailingType === 'completed' ? 1 : 0

    this.setState({
      marketLoading: true,
    })

    console.log(value)

    if(value === 'market') {
      Promise.all([
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI/custPros?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerRoi/trigType?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI/weekly?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders,}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI/dashboard/market?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders,}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI/dashboard?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders,}),
      ])
      .then(([resp1, resp2, resp3, resp4, resp5, resp6]) => Promise.all([resp1.json(), resp2.json(), resp3.json(), resp4.json(), resp5.json(), resp6.json()]))
      .then(([data1, data2, data3, data4, data5, data6]) => {
        if(data1.length > 0) {
          let trigObj = {}
          trigObj['Data_CustPros'] = data2
          trigObj['Data_Location'] = data6[0].Data_Location
          trigObj['Data_TrigType'] = data3
          trigObj['Data_Weekly'] = data4
          trigObj['Data_Overall'] = data1[0].Data
          trigObj['Label'] = 'ALL'
          trigObj['Key'] = data5.length + 1
          trigData.push(trigObj)

          this.setState({
            marketLoading: false,
            hasData: true,
            triggerData: trigData.concat(data5),
            selectedTriggerData: trigData.filter(item => item.Label === 'ALL'),
            triggerMarketData: data5,
            selectedMarkets: ['ALL'],
            checked: trigData.concat(data5).map(item => item.Label)
          })

          console.log(trigData.filter(item => item.Label === 'ALL'))
        } else {
          this.setState({
            isLoading: false,
            snackbar: true,
            errorMessage: 'Trigger ROI is not available for the selection(s) you have made. Please broaden your search and try again.',
            restoreHidden: false,
          })
        }
      })
      .catch(error => {
        console.log(error)
      })
    } else {
      Promise.all([
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI/custPros?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerRoi/trigType?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI/weekly?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders,}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI/dashboard/location?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders,}),
      ])
      .then(([resp1, resp2, resp3, resp4, resp5]) => Promise.all([resp1.json(), resp2.json(), resp3.json(), resp4.json(), resp5.json()]))
      .then(([data1, data2, data3, data4, data5]) => {
        if(data1.length > 0) {
          let trigObj = {}
          trigObj['Data_CustPros'] = data2
          trigObj['Data_TrigType'] = data3
          trigObj['Data_Weekly'] = data4
          trigObj['Data_Overall'] = data1[0].Data
          trigObj['Label'] = 'ALL'
          trigObj['Key'] = data5.length + 1
          trigData.push(trigObj)

          this.setState({
            marketLoading: false,
            hasData: true,
            triggerData: trigData.concat(data5),
            selectedTriggerData: trigData.filter(item => item.Label === 'ALL'),
            triggerMarketData: data5,
            selectedMarkets: ['ALL'],
            checked: trigData.concat(data5).map(item => item.Label)
          })
        } else {
          this.setState({
            isLoading: false,
            snackbar: true,
            errorMessage: 'Trigger ROI is not available for the selection(s) you have made. Please broaden your search and try again.',
          })
        }
      })
      .catch(error => {
        console.log(error)
      })
    }


    this.setState({
      selectedType: value
    })
  }

  handleMarketChange = values => {
    let selectedTrigMarkets = []

    if(values.filter(item => item !== 'ALL').length === this.state.triggerData.filter(item => item.Label !== 'ALL').length) {
      this.setState({
        selectedMarkets: ['ALL'],
        checked: this.state.triggerData.map(item => item.Label),
        selectedTriggerData: this.state.triggerData.filter(item => item.Label === 'ALL')
      })
    } else if(values.length > 1 && this.state.selectedMarkets.includes('ALL')
      || values.length >= 1 && values.includes('ALL') === false
    ) {
      if(values.filter(item => item !== 'ALL').length < 2) {
        let filter = values.filter(item => item !== 'ALL')[0]

        this.setState({
          selectedTriggerData: this.state.triggerData.filter(item => item.Label === filter)
        })
      } else {
        selectedTrigMarkets.push(this.state.triggerData.filter(item => values.includes(item.Label) && item.Label !== 'ALL'))

        let data = []
        let dataObj = {}
        let dataCustPros = []
        let dataLocation = []
        let dataTrigType = []
        let dataOverall = []
        let dataWeekly = []

        for(let i = 0; i < selectedTrigMarkets[0].length; i++) {
          if(this.state.selectedType === 'market') {
            dataCustPros.push(selectedTrigMarkets[0][i]['Data_CustPros'])
            dataLocation.push(selectedTrigMarkets[0][i]['Data_Location'])
            dataTrigType.push(selectedTrigMarkets[0][i]['Data_TrigType'])
            dataOverall.push(selectedTrigMarkets[0][i]['Data_Overall'][0])
            dataWeekly.push(selectedTrigMarkets[0][i]['Data_Weekly'])
          } else {
            dataCustPros.push(selectedTrigMarkets[0][i]['Data_CustPros'])
            dataTrigType.push(selectedTrigMarkets[0][i]['Data_TrigType'])
            dataOverall.push(selectedTrigMarkets[0][i]['Data_Overall'][0])
            dataWeekly.push(selectedTrigMarkets[0][i]['Data_Weekly'])

          }
        }

        // CUSTPROS DATA FOR MULTIPLE MARKET SELECTS
        let custProsNames = dataCustPros[0].map(item => item.Label)
        const custProsArr = []

        for(let i = 0; i < custProsNames.length; i++) {
          const custProsObj = {}
          custProsObj['label'] = custProsNames[i]
          custProsObj['Data'] = []
          for(let j = 0; j < dataCustPros.length; j++) {
            custProsObj['Data'].push(dataCustPros[j].filter(item => item.Label === custProsNames[i])[0])
          }
          custProsArr.push(custProsObj)
        }
        const custProsTotal = [custProsArr.map(item => item.Data.reduce((a,b) => {
          for(const prop in b) {
            if(a.hasOwnProperty(prop) && prop !== 'Label') a[prop] += b[prop]
            else a[prop] = b[prop]
          }
          return a
        }, {}))]

        // LOCATION DATA FOR MULTIPLE MARKET SELECTS
        let locationNames = dataLocation.length > 0 ? dataLocation[0].map(item => item.Label) : []
        const locationsArr = []

        for(let i = 0; i < locationNames.length; i++) {
          const locationObj = {}
          locationObj['label'] = locationNames[i]
          locationObj['Data'] = []
          for(let j = 0; j < dataLocation.length; j++) {
            locationObj['Data'].push(dataLocation[j].filter(item => item.Label === locationNames[i])[0])
          }
          locationsArr.push(locationObj)
        }
        const locationTotal = [locationsArr.map(item => item.Data.reduce((a,b) => {
          for(const prop in b) {
            if(a.hasOwnProperty(prop) && prop !== 'Label' && prop !== 'Market') a[prop] += b[prop]
            else a[prop] = b[prop]
          }
          return a
        }, {}))]

        // TRIGTYPE DATA FOR MULTIPLE MARKET SELECTS
        let trigTypeNames = dataTrigType[0].map(item => item.Label)
        const trigTypeArr = []

        for(let i = 0; i < trigTypeNames.length; i++) {
          const trigTypeObj = {}
          trigTypeObj['Data'] = []
          for(let j = 0; j < dataTrigType.length; j++) {
            trigTypeObj['Data'].push(dataTrigType[j].filter(item => item.Label === trigTypeNames[i])[0])
          }
          trigTypeArr.push(trigTypeObj)
        }
        const trigTypeTotal = [trigTypeArr.map(item => item.Data.reduce((a,b) => {
          for(const prop in b) {
            if(a.hasOwnProperty(prop) && prop !== 'Label') a[prop] += b[prop]
            else a[prop] = b[prop]
          }
          return a
        }, {}))]

        // WEEKLY DATA FOR MULTIPLE MARKET SELECTS
        let weeklyLabels = dataWeekly[0].map(item => item.Label)
        let weeklyArr = []
        for(let i = 0; i < weeklyLabels.length; i ++) {
          const weeklyObj = {}
          weeklyObj['Data'] = []
          for(let j = 0; j < dataWeekly.length; j++) {
            weeklyObj['Data'].push(dataWeekly[j].filter(item => item.Label === weeklyLabels[i])[0])
          }
          weeklyArr.push(weeklyObj)
        }
        const weeklyTotal = [weeklyArr.map(item => item.Data.reduce((a,b) => {
          for(const prop in b) {
            if(a.hasOwnProperty(prop) && prop !== 'Label') a[prop] += b[prop]
            else a[prop] = b[prop]
          }
          return a
        }, {}))]

        // OVERALL DATA FOR MULTIPLE MARKET SELECTS
        const dataOverallTotal = [dataOverall.reduce((a,b) => {
          for(const prop in b) {
            if(a.hasOwnProperty(prop)) a[prop] += b[prop]
            else a[prop] = b[prop]
          }
          return a
        }, {})]

        dataObj['Data_Overall'] = dataOverallTotal
        dataObj['Data_Location'] = locationTotal[0].length > 0 ? locationTotal[0] : null
        dataObj['Data_CustPros'] = custProsTotal[0]
        dataObj['Data_TrigType'] = trigTypeTotal[0]
        dataObj['Data_Weekly'] = weeklyTotal[0]
        data.push(dataObj)

        this.setState({
          selectedTriggerData: data
        })
      }
      this.setState({
        selectedMarkets: values.filter(item => item !== 'ALL'),
        checked: values.filter(item => item !== 'ALL'),
      })
    } else {
      console.log('this one')
      this.setState({
        selectedMarkets: ['ALL'],
        checked: this.state.triggerData.map(item => item.Label),
        selectedTriggerData: this.state.triggerData.filter(item => item.Label === 'ALL')
      })
    }
  }

  handleMailingTypeOpen = (e) => {

    this.setState({
      mailingOpen: true,
      anchor: e.currentTarget,
    })
  }

  changeMailingType = (e) => {
    const {myValue} = e.currentTarget.dataset
    let myHeaders = {'token': Cookies.get('token')}
    let complete = myValue === 'completed' ? 1 : 0

    Promise.all([
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI/custPros?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerRoi/trigType?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI/weekly?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders,}),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI/dashboard/${this.state.selectedType}?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders,}),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/triggerROI/dashboard?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders,}),
    ])
    .then(([response1, response2, response3, response4, response5, response6]) => Promise.all([response1.json(), response2.json(), response3.json(), response4.json(), response5.json(), response6.json()]))
    .then(([data1, data2, data3, data4, data5, data6]) => {
      if(this._isMounted && data1) {
        console.log(data1)
        let trigData = []
        let trigObj = {}
        trigObj['Data_CustPros'] = data2
        trigObj['Data_Location'] = data6[0].Data_Location
        trigObj['Data_TrigType'] = data3
        trigObj['Data_Weekly'] = data4
        trigObj['Data_Overall'] = data1[0].Data

        trigObj['Label'] = 'ALL'
        trigObj['Key'] = data5.length + 1
        trigData.push(trigObj)

        this.setState({
          isLoading: false,
          hasData: true,
          triggerData: trigData.concat(data5),
          selectedTriggerData: trigData.concat(data5).filter(item => this.state.selectedMarkets.includes(item.Label)),
          triggerMarketData: data5,
        })
      } else {
        this.setState({
              isLoading: false,
              snackbar: true,
              errorMessage: 'Trigger ROI is not available for the selection(s) you have made. Please broaden your search and try again.',
              triggerData: []
            })
      }
    })

    this.setState({
      mailingType: myValue,
    })

    console.log(myValue)

    this.handleMailingTypeClose()
  }

  handleMailingTypeClose = () => {
    this.setState({
      mailingOpen: false,
    })
  }

  handleDeselectMarket = (e, label) => {
    const filteredMarkets = this.state.selectedMarkets.filter(item => item !== label)
    this.handleMarketChange(filteredMarkets)
  }

  renderMarketSelectOptions = () => {
    return (
      this.state.triggerData.map(item => (
        <MenuItem
          key={item.Key}
          value={item.Label}
          style={this.state.selectedMarkets.indexOf(item.Label) === -1 ? {} : {fontWeight: 'bold'}}
        >
          <div style={{display: 'inline-flex', alignItems: 'center'}}>
            <Checkbox
              edge="start"
              size="small"
              checked={this.state.checked.indexOf(item.Label) !== -1}
              disableRipple
            />
            <Typography variant="caption" style={{width: 200}}>
              {item.Label === 'ALL' ? `${item.Label} ${this.state.selectedType.toUpperCase()}S` : item.Label.includes('_') ? item.Label.split('_').join(' ').toUpperCase() : item.Label.includes('-') ? item.Label.split('-').join(' ').toUpperCase() : item.Label.toUpperCase()}
            </Typography>
          </div>
        </MenuItem>
      ))
    )
  }

  getSelectedData = (data) => {
    console.log(data)

    const metricArr = []

    if(data[0]) {
      for(let i = 0; i < data.length; i++) {
        for(let j = 0; j < data[i].length; j++) {
          if(metricArr.map(item => item.label).indexOf(data[i][j].Label) === -1) {
            metricArr.push({label: data[i][j].Label})
          }
        }
      }
    }

    for(let i = 0; i < metricArr.length; i++) {
      metricArr[i]['data'] = []
      for(let j = 0; j < data.length; j++) {
        for(let k = 0; k < data[j].length; k++) {
          if(metricArr[i].label === data[j][k].Label) {
            metricArr[i]['data'].push(data[j][k])
          }
        }
      }
    }

    metricArr.forEach(element => {
      element['total'] = element.data.reduce((a,b) => {
        for(const prop in b) {
          if(a.hasOwnProperty(prop)) a[prop] += b[prop]
          else a[prop] = b[prop]
        }
        return a
      }, {})
    })

    return metricArr
  }

  render() {
    const { classes } = this.props

    const overallData = this.getSelectedData(this.state.selectedTriggerData ? this.state.selectedTriggerData.map(item => item.Data_Overall) : [])
    const custProsData = this.getSelectedData(this.state.selectedTriggerData ? this.state.selectedTriggerData.map(item => item.Data_CustPros) : [])
    const trigTypeData = this.getSelectedData(this.state.selectedTriggerData ? this.state.selectedTriggerData.map(item => item.Data_TrigType) : [])
    const locationData = this.state.marketLoading ? [] : this.state.selectedType === "market" ? this.getSelectedData(this.state.selectedTriggerData ? this.state.selectedMarkets.indexOf('ALL') !== -1 ? this.state.selectedTriggerData.map(item => item.Data_Location) : this.state.selectedTriggerData.map(item => item.Data_Location.filter(item => this.state.selectedMarkets.indexOf(item.Market) !== -1)) : []) : []
    const weeklyData = this.getSelectedData(this.state.selectedTriggerData ? this.state.selectedTriggerData.map(item => item.Data_Weekly) : [])
    const currentUserLite = JSON.parse(localStorage.getItem('currentUser')) && JSON.parse(localStorage.getItem('currentUser'))[0].sd_lite === 1 ? true : false

    return(
      <div>
        {this.state.isLoading ? <div className={classes.loader}><CircularProgress /></div> :
        this.state.hasData && !currentUserLite ?
        <Fragment>

          <Snackbar
            open={this.state.snackbar}
            autoHideDuration={4500}
            onClose={this.handleSnackbarClose}
            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
          >
            <SnackbarContent
              message={
                <div style={{display: 'flex'}}>
                  <WarningIcon className={classes.error} fontSize="small" fontWeight="bold"/>
                  <span style={{fontWeight: 600, marginLeft: '15px'}}>{this.state.errorMessage}</span>
                </div>
              }
              style={this.props.darkMode ?
                {
                  color: '#fff',
                  backgroundColor: '#ff9800'
                }
              :
                {
                  backgroundColor: '#ff9800'
                }
              }
            />
          </Snackbar>

          {/* PAGE HEADER */}
          {this.state.marketLoading ?
            <div>
              <CircularProgress className={classes.loader} style={{position: 'absolute', zIndex: 10000}}/>
            </div>
          :
            <div />
          }

          <div style={this.state.marketLoading ? {opacity: 0.5} : {}}>
            <Fade in={true}>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <Typography className={this.props.darkMode ? classes.pageTitleDark : classes.pageTitle} style={{marginTop: '25px'}}>
                  <TelegramIcon className={this.props.darkMode ? classes.pageIconDark : classes.pageIcon} />
                  <span style={{marginLeft: '5px'}}>TRIGGERS</span>
                </Typography>
                <div style={{marginTop: '25px', textAlign: 'right'}}>

                  <Typography variant="caption">
                    Showing <span onClick={this.handleMailingTypeOpen} className={this.props.darkMode ? classes.mailingTypeDark : classes.mailingType}>{`${this.state.mailingType.toUpperCase()} MAILINGS`}</span> from:
                  </Typography>
                   <Menu
                     anchorEl={this.state.anchor}
                     open={this.state.mailingOpen}
                     onClose={this.handleMailingTypeClose}
                   >
                    <MenuItem onClick={e => this.changeMailingType(e)} data-my-value={'completed'}><Typography variant="caption">COMPLETE MAILINGS</Typography></MenuItem>
                    <MenuItem onClick={e => this.changeMailingType(e)} data-my-value={'all'}><Typography variant="caption">ALL MAILINGS</Typography></MenuItem>
                   </Menu>
                  <div style={{display: 'flex'}}>
                    {/* MARKET vs LOCATION SELECT */}
                    <Select
                      variant="outlined"
                      value={this.state.selectedType}
                      onChange={e => this.handleSelectedType(e.target.value)}
                      input={<StyledInput style={{width: '150px'}}/>}
                      style={{marginRight: '15px', textAlign: 'left'}}
                    >
                      <MenuItem value={'market'}>
                        <div style={{padding: '5px 15px 0px 15px'}}>
                          <Typography variant="caption">
                            BY MARKET
                          </Typography>
                        </div>
                      </MenuItem>
                      <MenuItem value={'location'}>
                        <div style={{padding: '5px 15px 0px 15px'}}>
                          <Typography variant="caption">
                            BY LOCATION
                          </Typography>
                        </div>
                      </MenuItem>
                    </Select>
                    {/* MARKET SELECT */}
                    <div style={{marginRight: '15px', textAlign: 'left'}}>
                      <Select
                        multiple
                        variant="outlined"
                        value={this.state.selectedMarkets}
                        onChange={e => this.handleMarketChange(e.target.value)}
                        input={<StyledInput style={{minWidth: 200, maxWidth: 350}}/>}
                        renderValue={selected => (
                          selected.length > 1 ?
                          <div style={{padding: '5px 0px 0px 20px'}}>
                            <Typography variant="caption">{`${selected.length} ${this.state.selectedType.toUpperCase()}S SELECTED`}</Typography>
                          </div>
                          :
                          <div style={{padding: '5px 0px 0px 20px'}}>
                            {selected.map(value => (
                              <Typography key={value} variant="caption">{value === 'ALL' ? value + ' ' + this.state.selectedType.toUpperCase() + 'S' : value}</Typography>
                            ))}
                          </div>
                        )}
                      >
                        {this.renderMarketSelectOptions()}
                      </Select>
                    </div>

                    <div style={{width: 500}}>
                      <DayPicker
                        startDate={this.state.minYearmo}
                        endDate={this.state.maxYearmo}
                        maxSliderValue={this.state.days}
                        handleDateRange={this.handleDateRange}
                        handleSliderDateChange={this.handleSliderDateChange}
                        handleDateClear={this.handleDateClear}
                        restoreHidden={this.state.restoreHidden}
                        allowBeforeJan={false}
                        maxDate={'trig'}
                        marketLoading={this.state.marketLoading}
                      />
                    </div>
                  </div>
                  {this.state.marketLoading ? <div/> :
                    <RoiExcel
                      pageName={'TRIGGER'}
                      markets={this.state.selectedMarkets}
                      selectedType={this.state.selectedType}
                      startDate={this.state.startDate.format('MMM DD YYYY')}
                      endDate={this.state.endDate.format('MMM DD YYYY')}
                      selectedData={this.state.selectedTriggerData[0]}
                    />
                  }
                </div>
              </div>
            </Fade>
            <div
              style={
                this.state.selectedTriggerData.length > 0 ?
                this.state.selectedTriggerData.map(item => item.Data_Overall[0]).reduce((a,b) => {
                  for(const prop in b) {
                    if(a.hasOwnProperty(prop)) a[prop] += b[prop]
                    else a[prop] = b[prop]
                  }
                  return a
                }, {}).HO_Count > 7500 && JSON.parse(localStorage.getItem('currentUser'))[0].roi_use_roas !== 1 || this.state.bUnit === 3 ?
                {display: 'none'} : { marginTop: '15px'} : {}
              }
            >
              <Fade in={true} timeout={2000}>
                <div className={classes.warning}>
                  <div style={{margin: '1em', display: 'inline-flex', alignItems: 'center'}}>
                    <WarningIcon style={{color: '#de9f10', fontSize: '25px'}}/>
                    <Typography align="center" variant="caption" style={{marginLeft: '5px', fontWeight: 'bold', fontSize: '15px'}}>
                      Due to the low holdout quantity, <span style={{color: "#d13e34"}}>the incremental metrics may not be statistically significant.</span>
                    </Typography>
                  </div>
                </div>
              </Fade>
            </div>
            <br />
            <Stats
              data={
                this.state.selectedTriggerData.length > 0 ?
                this.state.selectedTriggerData.map(item => item.Data_Overall[0]).reduce((a,b) => {
                  for(const prop in b) {
                    if(a.hasOwnProperty(prop)) a[prop] += b[prop]
                    else a[prop] = b[prop]
                  }
                  return a
                }, {}) : []
              }
              darkMode={this.props.darkMode}
            />
            <br />
            <Fade in={true} timeout={500}>
              <Typography align="center">
                On completed promotions, a total spend of <span style={{color: 'rgba(255, 0, 0, 0.7)'}}>
                  ${this.state.selectedTriggerData.length > 0 ?
                    this.state.selectedTriggerData.map(item => item.Data_Overall[0]).reduce((a,b) => {
                      for(const prop in b) {
                        if(a.hasOwnProperty(prop)) a[prop] += b[prop]
                        else a[prop] = b[prop]
                      }
                      return a
                    }, {}).Cost.toLocaleString('en', {maximumFractionDigits: 0})
                    : 0
                  }
                </span> has yielded <span style={{color: 'rgba(113, 177, 40)', fontWeight:'bold'}}>
                  ${this.state.selectedTriggerData.length > 0 ?
                    this.state.selectedTriggerData.map(item => item.Data_Overall[0]).reduce((a,b) => {
                      for(const prop in b) {
                        if(a.hasOwnProperty(prop)) a[prop] += b[prop]
                        else a[prop] = b[prop]
                      }
                      return a
                    }, {}).Sales.toLocaleString('en', {maximumFractionDigits: 0})
                    : 0
                  }
                </span> in sales to responders (at a {this.state.selectedTriggerData.length > 0 ?
                  (this.state.selectedTriggerData.map(item => item.Data_Overall[0]).reduce((a,b) => {
                    for(const prop in b) {
                      if(a.hasOwnProperty(prop)) a[prop] += b[prop]
                      else a[prop] = b[prop]
                    }
                    return a
                  }, {}).Responses / this.state.selectedTriggerData.map(item => item.Data_Overall[0]).reduce((a,b) => {
                    for(const prop in b) {
                      if(a.hasOwnProperty(prop)) a[prop] += b[prop]
                      else a[prop] = b[prop]
                    }
                    return a
                  }, {}).Mailed * 100).toFixed(1)
                  : 0
                }% response rate).<br/> Of the sales to responders, <span style={{color: 'rgba(201, 182, 0, 1)', fontWeight:'bold'}}>
                  ${this.state.selectedTriggerData.length > 0  && ((overallData[0].total.Responses / overallData[0].total.Mailed) - (overallData[0].total.HO_Resp / overallData[0].total.HO_Count)) > 0 ?
                    Math.round((overallData[0].total.Sales / (overallData[0].total.Responses / overallData[0].total.Mailed)) * ((overallData[0].total.Responses / overallData[0].total.Mailed) - (overallData[0].total.HO_Resp / overallData[0].total.HO_Count))).toLocaleString('en')
                    : 0
                  }
                </span> are purely incremental.
              </Typography>
            </Fade>
            <br />
            <CustProsTable
              custProsData={this.state.selectedTriggerData.length > 0 ? custProsData.map(item => item.total) : []}
              darkMode={this.props.darkMode}
              dbO={JSON.parse(localStorage.getItem('currentUser'))[0].db_owner}
              roasFlag={JSON.parse(localStorage.getItem('currentUser'))[0].roi_use_roas}
            />
            <br />
            <br />
            {console.log(this.state.selectedTriggerData.length)}
            {console.log(overallData)}
            <RoiCharts
              chartsData={this.state.selectedTriggerData.length > 0 ? overallData : []}
              chartsData2={this.state.selectedTriggerData.length > 0 ? custProsData : []}
              custProsChartData={this.state.selectedTriggerData.length > 0 ? custProsData : []}
              labels={['Total Spend', 'Incremental Sales']}
              labels2={['Customer Responses', 'Prospect Responses']}
              weeklydata={this.state.selectedTriggerData.length > 0 ? weeklyData : []}
              darkMode={this.props.darkMode}
              pageName={"Triggers"}
            />
            <br />
            <br />
            <Fade in={true}>
              <TriggerTypesTable data={this.state.selectedTriggerData.length > 0 ? trigTypeData : []} darkMode={this.props.darkMode} roasFlag={JSON.parse(localStorage.getItem('currentUser'))[0].roi_use_roas}/>
            </Fade>
            <br />
            <br />
            <Fade in={true}>
              <div style={this.state.selectedType === "market" ? {} : {display: 'none'}}>
                <TrigLocationsTable data={this.state.selectedTriggerData.length > 0 ? locationData : []} darkMode={this.props.darkMode}/>
              </div>
            </Fade>
          </div>
          <UpdatedThrough footerDate={this.state.updatedDate} />
        </Fragment>
        : <div className={classes.unavailable}>
          <AskReport />
        </div>
        }
      </div>
    )
  }
}

export default withStyles(styles)(Triggers)
