import React, { Component, Fragment } from 'react'
import clsx from 'clsx'
import styled from 'styled-components'
import {
  withStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  ButtonBase,
  Modal,
  Backdrop,
  Fade,
  Link,
  TextField,
  InputAdornment,
  Drawer,
  Divider,
  Checkbox,
  Button,
  FormControl,
  FormControlLabel,
  Snackbar,
  SnackbarContent,
  TablePagination,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Tooltip,
} from '@material-ui/core'
import { Redirect } from 'react-router-dom'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import ViewAgendaOutlinedIcon from '@material-ui/icons/ViewAgendaOutlined'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import WarningIcon from '@material-ui/icons/Warning'
import SearchIcon from '@material-ui/icons/Search'
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox'
import PlayListAddOutlinedIcon from '@material-ui/icons/PlaylistAddOutlined'
import Cookies from 'js-cookie'
import moment from 'moment'
import DatePicker from '../calendar/DatePicker'
import TentpoleROI from './TentpoleROI'
import noImage from '../../assets/noImage.png'
import tentpoleStandIn from '../../assets/mainStandIns/tentpoleStandIn.png'
import ResponseDemo from './ResponseDemo'
import DayPicker from '../calendar/DayPicker'

const styles = theme => ({
  root: {
    width: '100%',
  },
  column: {
    // flexBasis: '33%',
    marginLeft: '20px',
    marginTop: '10px',
    [theme.breakpoints.up('xl')]: {
      marginLeft: '35px'
    },
    // [theme.breakpoints.up('lg')]: {
    //   marginLeft: '35px'
    // },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '35px',
    }
  },
  metric: {
    [theme.breakpoints.up('xl')]: {
      fontSize: '15px'
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px'
    }
  },
  metricNumber: {
    [theme.breakpoints.up('xl')]: {
      fontSize: '18px'
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '15px'
    }
  },
  headColumns: {
    // [theme.breakpoints.up('lg')]: {
    //   flexBasis: '40%'
    // },
    [theme.breakpoints.up('xl')]: {
      flexBasis: '40%',
    },
    [theme.breakpoints.down('lg')]: {
      flexBasis: '50%'
    }
    // [theme.breakpoints.up('md')]: {
    //   flexBasis: '45%'
    // }
  },
  img: {
    width: 150,
    [theme.breakpoints.up('xl')]: {
      width: 250,
    }
  },
  header: {
    color: 'rgba(255, 255, 255, 1.0)',
    fontWeight: 600
  },
  heading: {},
  secondaryHeading: {
    color: '#999',
    [theme.breakpoints.up('xl')]: {
      marginLeft: '-175px'
    }
  },
  helper: {
    marginLeft: '20px',
    borderLeft: `2px solid #c9c9c9`,
    marginTop: '-5px'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalImg: {
    maxWidth: '95%',
    maxHeight: '95%'
  },
  pageTitle: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '17px',
    fontWeight: 'light',
    color: 'rgba(115, 115, 115, 0.9)'
  },
  pageTitleDark: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '17px',
    fontWeight: 'light',
    color: 'rgba(255, 255, 255, 0.9)'
  },
  pageIcon: {
    fontSize: '15px',
    marginTop: '21px',
    marginRight: '2px',
  },
  pageIconDark: {
    fontSize: '15px',
    color: 'rgba(255, 255, 255, 0.9)',
    marginTop: '5px',
    marginRight: '2px',
  },
  search: {
    marginTop: '15px'
  },
  searchDark: {
    marginTop: '15px',
    borderColor: '#fff',
  },
  drawer: {
    width: '100%',
    flexShrink: 0,
    zIndex: theme.zIndex.seconddrawer - 1
  },
  drawerPaper: {
    width: '100%',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'right',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  hide: {
    display: 'none',
  },
  link: {
    '&:hover': {
      cursor: 'pointer'
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '15px'
    }
  },
  linkDark: {
    color: '#4ab8bb',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'none',
      color: '#6fd7da'
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '15px'
    }
  },
  expansionPanel: {
    // [theme.breakpoints.down('sm')]: {
    //   width: '75%'
    // }
  },
  button: {
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#ff9800'
    }
  },
  buttonDark: {
    color: '#4ab8bb',
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#6fd7da'
    }
  },
  select: {
    color: 'rgba(115, 115, 115, 0.9)',
    marginLeft: '5px',
    marginTop: '15px',
    width: 175,
    '&:active': {
      color: '#000'
    }
  },
  selectDark: {
    color: 'rgba(255, 255, 255, 0.9)',
    marginLeft: '5px',
    marginTop: '15px',
    width: 175,
    '&:active': {
      color: '#000'
    }
  },
  loader: {
    marginTop: '25%',
    marginLeft: '50%'
  },
  unavailable: {
    position: 'absolute',
    textAlign: 'center',
    [theme.breakpoints.up('xl')]: {
      top: '35%',
      left: '12.5%',
    },
    [theme.breakpoints.down('lg')]: {
      top: '35%'
    }
  }
})

const StyledTextField = styled(TextField)`
  label.Mui-focused {
    color: #4ab8bb
  }
  .MuiOutlinedInput-root {
    fieldset {
      ${'' /* height: 45px */}
    }
    &.Mui-focused fieldset {
      border-color: #4ab8bb
    }
  }
`

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: '11px',
    fontWeight: 'bold'
  }
}))(Tooltip)

class Tentpoles extends Component {
  _isMounted = false

  constructor(props) {
    super(props)
    this.state = {
      tentpoleData: [],
      currentUser: JSON.parse(localStorage.getItem('currentUser')),
      search: '',
      disabled: true,
      modalOpen: false,
      modalImg: '',
      openRight: false,
      openBottom: false,
      roiData: [],
      responseData: [],
      demoData: [],
      jobData: [],
      checked: false,
      checkedItems: [],
      startDate: JSON.parse(localStorage.getItem('currentUser'))[0].max_date_event ? moment(JSON.parse(localStorage.getItem('currentUser'))[0].max_date_event).startOf('year').subtract(1, 'years') : moment().startOf('year').subtract(1, 'years'),
      initialStartDate: JSON.parse(localStorage.getItem('currentUser'))[0].max_date_event ? moment(JSON.parse(localStorage.getItem('currentUser'))[0].max_date_event).startOf('year').subtract(1, 'years') : moment().startOf('year').subtract(1, 'years'),
      endDate: JSON.parse(localStorage.getItem('currentUser'))[0].max_date_event ? moment(JSON.parse(localStorage.getItem('currentUser'))[0].max_date_event) : moment(),
      initialEndDate: JSON.parse(localStorage.getItem('currentUser'))[0].max_date_event ? moment(JSON.parse(localStorage.getItem('currentUser'))[0].max_date_event) : moment(),
      snackbar: false,
      errorMessage: '',
      rowsPerPage: 10,
      page: 0,
      expanded: false,
      dateExtended: 'Extended Date',
      isLoading: true,
      scrollTop: 0,
      job: '',
      maxYearmo: JSON.parse(localStorage.getItem('currentUser'))[0].max_date_event ? moment(JSON.parse(localStorage.getItem('currentUser'))[0].max_date_event) : moment(),
      minYearmo: JSON.parse(localStorage.getItem('currentUser'))[0].max_date_event ? moment(JSON.parse(localStorage.getItem('currentUser'))[0].max_date_event).startOf('year').subtract(1, 'years') : moment().startOf('year').subtract(1, 'years'),
      days: 0,
      restoreHidden: true,
      compareMode: false,
    }

    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this)
    this.handleDrawerClose = this.handleDrawerClose.bind(this)
    this.handleExpand = this.handleExpand.bind(this)
    this.handleChecked = this.handleChecked.bind(this)
    this.handleCompare = this.handleCompare.bind(this)
    this.resetCompare = this.resetCompare.bind(this)
    this.handleSwitch = this.handleSwitch.bind(this)
  }

  componentDidMount = () => {
    this._isMounted = true
    // console.log(JSON.parse(localStorage.getItem('currentUser'))[0].max_date_trig ? true : false)

    fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID?startDate=${this.state.initialStartDate.format('YYYY-MM-DD')}&endDate=${this.state.initialEndDate.format('YYYY-MM-DD')}`, {
      headers: {
        token: Cookies.get('token')
      }
    })
    .then(response => response.json())
    .then(data => {
      if(this._isMounted && data) {
        this.setState({
          tentpoleData: data,
          isLoading: false,
          days: this.state.initialEndDate.diff(this.state.initialStartDate, 'days'),
        })
      } else {
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/recentDates?count=${10}`, {
          headers: {
            token: Cookies.get('token')
          }
        })
        .then(response => response.json())
        .then(data => {
          if(data.length > 0) {
            this.setState({
              startDate: data[data.length - 1],
              initialStartDate: data[data.length - 1]
            })
            fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID?startDate=${data[data.length - 1]}&endDate=${moment().format('YYYY-MM-DD')}`, {
              headers: {
                token: Cookies.get('token')
              }
            })
            .then(response => response.json())
            .then(data => {
              this.setState({
                isLoading: false,
                tentpoleData: data,
              })
            })
            .catch(error => {
              console.log(error)
            })
          } else {
            this.setState({
              isLoading: false,
              tentpoleData: [],
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
      }
    })
    .catch(error => {
      console.log(error)
    })

    if(JSON.parse(localStorage.getItem('currentUser'))[0].username.toUpperCase().includes('USER')) {
    } else {
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/pageviews/add?display=event_marketing&screen_name=event_marketing`, {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'token': Cookies.get('token'),
        },
      })
      .then(response => {
        if(response.ok === true) {
          return response.json()
        }
      })
      .catch(error => {
        console.log(error)
      })
    }
  }

  handleDateRange = (value, valName) => {
    const myHeaders = {'token': Cookies.get('token')}

    if(valName === 'startDate') {
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID?startDate=${value.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}`, {
        headers: {
          token: Cookies.get('token')
        }
      })
      .then(response => response.json())
      .then(data => {
        if(data) {
          this.setState({
            tentpoleData: data,
            days: this.state.endDate.diff(value, 'days'),
            restoreHidden: false,
          })
        } else {
          this.setState({
            snackbar: true,
            errorMessage: 'There are no promotions within the selected range',
          })
        }
      })
      .catch(error => {
        console.log(error)
      })
    } else {
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${value.format('YYYY-MM-DD')}`, {
        headers: {
          token: Cookies.get('token')
        }
      })
      .then(response => response.json())
      .then(data => {
        if(data) {
          this.setState({
            tentpoleData: data,
            days: value.diff(this.state.startDate, 'days'),
            restoreHidden: false,
          })
        } else {
          this.setState({
            snackbar: true,
            errorMessage: 'There are no promotions within the selected range',
          })
        }
      })
      .catch(error => {
        console.log(error)
      })
    }

    this.setState({
      [valName]: value,
      restoreHidden: false,
    })
  }

  handleSliderDateChange = (values) => {
    const myHeaders = {'token': Cookies.get('token')}
    const startDate = values[0]
    const endDate = values[1]

    console.log(startDate)
    console.log(endDate)

    fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID?startDate=${startDate}&endDate=${endDate}`, {
      headers: {
        token: Cookies.get('token')
      }
    })
    .then(response => response.json())
    .then(data => {
      if(data) {
        this.setState({
          tentpoleData: data,
          restoreHidden: false,
          days: this.state.endDate.diff(this.state.startDate, 'days'),
          startDate: moment(values[0]),
          endDate: moment(values[1]),
        })
      } else {
        this.setState({
          snackbar: true,
          errorMessage: 'There are no promotions within the selected range',
        })
      }
    })
    .catch(error => {
      console.log(error)
    })
  }

  handleDateClear = () => {
    const myHeaders = {'token': Cookies.get('token')}

    fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID?startDate=${this.state.initialStartDate.format('YYYY-MM-DD')}&endDate=${this.state.initialEndDate.format('YYYY-MM-DD')}`, {
      headers: {
        token: Cookies.get('token')
      }
    })
    .then(response => response.json())
    .then(data => {
      if(this._isMounted && data) {
        this.setState({
          tentpoleData: data,
          isLoading: false,
          restoreHidden: true,
          startDate: this.state.initialStartDate,
          endDate: this.state.initialEndDate,
          minYearmo: this.state.initialStartDate,
          maxYearmo: this.state.initialEndDate,
          days: this.state.initialEndDate.diff(this.state.initialStartDate, 'days'),
        })
      } else {
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/recentDates?count=${10}`, {
          headers: {
            token: Cookies.get('token')
          }
        })
        .then(response => response.json())
        .then(data => {
          if(data.length > 0) {
            this.setState({
              startDate: data[data.length - 1],
              initialStartDate: data[data.length - 1],
              restoreHidden: true,
            })
            fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID?startDate=${data[data.length - 1]}&endDate=${this.state.initialEndDate.format('YYYY-MM-DD')}`, {
              headers: {
                token: Cookies.get('token')
              }
            })
            .then(response => response.json())
            .then(data => {
              this.setState({
                isLoading: false,
                tentpoleData: data,
                restoreHidden: true,
              })
            })
            .catch(error => {
              console.log(error)
            })
          } else {
            this.setState({
              isLoading: false,
              tentpoleData: [],
              restoreHidden: true,
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
      }
    })
    .catch(error => {
      console.log(error)
    })
  }

  handleOpen(e) {
    this.setState({
      modalOpen: true,
      modalImg: e.target.src
    })
  }

  handleClose() {
    this.setState({
      modalOpen: false,
      modalImg: ''
    })
  }

  handleDrawerOpen(jobID) {
    this.setState({
      roiLoading: true,
    })
    const myHeaders = {'token': Cookies.get('token')}
    Promise.all([
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID/${jobID}/market`, {headers: myHeaders}),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID/${jobID}/location`, {headers: myHeaders}),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID/${jobID}/rfm`, {headers: myHeaders}),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID/${jobID}/test`, {headers: myHeaders}),
    ])
    .then(([response, response2, response3, response4]) => Promise.all([response.json(), response2.json(), response3.json(), response4.json()]))
    .then(([data, data2, data3, data4]) => {
      this.setState({
        openRight: true,
        roiLoading: false,
        openBottom: true ? false : true,
        roiData: this.state.tentpoleData.filter(item => item.JobID === jobID),
        marketData: data,
        locationData: data2,
        rfmData: data3,
        testData: data4,
        job: jobID,
      })
    })
    .catch(error => {
      console.log(error)
    })
  }

  handleDrawerClose() {
    this.setState({
      openRight: false,
      openBottom: false,
      job: '',
    })
  }

  handleSwitch(e, job) {
    if(e === 'ROI') {
      this.handleDrawerOpen(job)
    } else {
      console.log(false)
    }
  }

  handleResponseDrawerOpen(jobID) {
    this.setState({
      responseLoading: true,
    })
    const myHeaders = { 'token': Cookies.get('token') }

    Promise.all([
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID/${jobID}/demo`, {headers: myHeaders}),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/demoByLoc`, {headers: myHeaders}),
    ])
    .then(([response, response2]) => Promise.all([response.json(), response2.json()]))
    .then(([data, data2]) => {
      console.log(data2)
      console.log(data)
      this.setState({
        openBottom: true,
        responseLoading: false,
        responseData: data,
        demoData: data2,
        job: jobID,
        jobData: this.state.tentpoleData.filter(item => item.JobID === jobID),
      })
    })
    .catch(error => {
      console.log(error)
    })
  }

  updateSearch(e) {
    if(e.target.value) {
      this.setState({
        search: e.target.value.substr(0,25),
        hidden: true
      })
    } else {
      this.setState({
        search: '',
        hidden: false
      })
    }
  }

  handleExpand = panel => (e, expanded) => {
    console.log(panel, expanded)
    this.setState({
      expanded: expanded ? panel : true
    })
  }

  handleExpandAll() {
    console.log('expanding all')
  }

  handleChecked(e, jobID) {
    this.setState({
      checkedItems: this.state.checkedItems.includes(jobID) ?
        this.state.checkedItems.filter(c => c !== jobID) :
        [...this.state.checkedItems, jobID],
    })
  }

  handleCompare() {
    const compareTentpole = []
    if(this.state.checkedItems.length < 2) {
      this.setState({
        snackbar: true,
        errorMessage: 'Please select at least 2 promotions to compare'
      })
    } else {
      for(let i = 0; i < this.state.checkedItems.length; i++) {
        compareTentpole.push(this.state.tentpoleData.filter(item => item.JobID === this.state.checkedItems[i])[0])
      }
      this.setState({
        tentpoleData: compareTentpole.sort(
          (a,b) => (b.Data_Extended.PromoStartDate && b.Key) - (a.Data_Extended.PromoStartDate && a.Key)
        ),
        compareMode: true,
        expanded: true,
        page: 0,
      })
    }
  }

  handleSum = () => {
    console.log(this.state.checkedItems)
  }

  handleSnackbarClose = (e, reason) => {
    if(reason === 'clickaway') {
      this.setState({
        snackbar: false,
      })
    }
    this.setState({
      snackbar: false
    })
  }

  resetCompare() {
    this.setState({
      checkedItems: [],
      compareMode: false
    })


    console.log(this.state.startDate)
    console.log(this.state.endDate)

    fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}`, {
      headers: {
        token: Cookies.get('token')
      }
    })
    .then(response => response.json())
    .then(data => {
      if(this._isMounted) {
        this.setState({
          tentpoleData: data
        })
      }
    })
    .catch(error => {
      console.log(error)
    })
  }

  handleChangePage = (e, newPage) => {
    this.setState({
      page: newPage
    })
  }

  handleChangeRowsPerPage = e => {
    let value = e.target.value
    const rowsPerPage = value === 'Show All' ? this.state.tentpoleData.length : value

    this.setState({
      rowsPerPage: rowsPerPage,
      page: 0
    })
  }

  handleDateExtendedChange = e => {
    this.setState({
      dateExtended: e.target.value
    })
  }

  render() {
    const { classes } = this.props

    let filteredItems = this.state.tentpoleData.filter(
      (item) => {
        return item.Data_Extended.length > 0 ? item.Promotion.replace(/'/g, '').toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 || item.JobID.indexOf(this.state.search) !== -1 : ''
      }
    )
 
    console.log(filteredItems)

    return(
      <Fragment>
        {this.state.isLoading ? <CircularProgress className={classes.loader} /> :
          this.state.tentpoleData.length > 0 ?
          <div>
            <Fade in={true}>
            <div className={classes.root}>
            {/* {console.log(this.state.tentpoleData)} */}
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <div>
                    <Typography className={this.props.darkMode ? classes.pageTitleDark : classes.pageTitle} style={{marginTop: '25px'}}>
                      <MarkunreadMailboxIcon className={this.props.darkMode ? classes.pageIconDark : classes.pageIcon} />
                      <span style={{marginLeft: '5px'}}>EVENT MARKETING - DIRECT MAIL</span>
                    </Typography>
                    <StyledTextField
                      variant="outlined"
                      label="Search By Event"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon fontSize="small" />
                          </InputAdornment>
                        )
                      }}
                      value={this.state.search}
                      onChange={this.updateSearch.bind(this)}
                      className={this.props.darkMode ? classes.searchDark : classes.search}
                    />
                    <FormControl>
                      <Select
                        value={this.state.dateExtended}
                        variant="outlined"
                        inputProps={{
                          name: 'date'
                        }}
                        onChange={this.handleDateExtendedChange}
                        className={this.props.darkMode ? classes.selectDark : classes.select}
                      >
                        <MenuItem value={'Extended Date'}>Extended Date</MenuItem>
                        <MenuItem value={'Promo Date'}>Promo Date</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div style={{marginTop: '90px'}}>
                    <DayPicker
                      startDate={this.state.minYearmo}
                      endDate={this.state.maxYearmo}
                      maxSliderValue={this.state.days}
                      handleDateRange={this.handleDateRange}
                      handleSliderDateChange={this.handleSliderDateChange}
                      handleDateClear={this.handleDateClear}
                      restoreHidden={this.state.restoreHidden}
                      allowBeforeJan={true}
                      maxDate={'event'}
                    />
                    <div style={{float: 'right'}}>
                      <Button
                        color="primary"
                        className={this.props.darkMode ? classes.buttonDark : classes.button}
                        onClick={this.handleCompare} size="small"
                        endIcon={<ViewAgendaOutlinedIcon size="small" />}
                        disableRipple
                      >
                        <span style={{marginTop:'2px'}}>Compare Promotions</span>
                      </Button>
                      {/* <Button
                        color="primary"
                        className={this.props.darkMode ? classes.buttonDark : classes.button}
                        style={{marginLeft: "10px"}}
                        onClick={this.handleSum}
                        size="small"
                        endIcon={<PlayListAddOutlinedIcon size="small" />}
                        disableRipple
                      >
                        <span style={{marginTop: '2px'}}>Sum of Promotions</span>
                      </Button> */}
                      <Button
                        color="primary"
                        className={this.props.darkMode ? classes.buttonDark : classes.button}
                        style={{marginLeft: '10px'}}
                        onClick={this.resetCompare} size="small"
                        endIcon={<HighlightOffIcon size="small" />}
                        disableRipple
                      >
                        <span style={{marginTop:'2px'}}>Clear Selections</span>
                      </Button>
                    </div>
                    <Snackbar
                      open={this.state.snackbar}
                      autoHideDuration={4500}
                      onClose={this.handleSnackbarClose}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                    >
                      <SnackbarContent
                        message={
                          <div style={{display: 'flex'}}>
                            <WarningIcon className={classes.error} fontSize="small" fontWeight="bold"/>
                            <span style={{fontWeight: 600, marginLeft: '15px'}}>{this.state.errorMessage}</span>
                          </div>
                        }
                        style={this.props.darkMode ?
                          {
                            color: '#fff',
                            backgroundColor: '#ff9800'
                          }
                        :
                          {
                            backgroundColor: '#ff9800'
                          }
                        }
                      />
                    </Snackbar>
                  </div>
                </div>
              <br />

              {/* Modal for Creatives */}
              <Modal
                className={classes.modal}
                open={this.state.modalOpen}
                onClose={this.handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500
                }}
              >
                <Fade in={this.state.modalOpen}>
                  <img className={classes.modalImg} src={this.state.modalImg} alt="" />
                </Fade>
              </Modal>

              {/* ROI Drawer */}
              {/* {this.state.roiLoading ? <CircularProgress style={{position: 'absolute', zIndex: 10000, marginLeft: '50%', marginTop: '10%'}}/> : */}
                <Drawer
                  className={classes.drawer}
                  anchor="right"
                  open={this.state.openRight}
                  classes={{
                    paper: classes.drawerPaper
                  }}
                >
                  <TentpoleROI
                    handleDrawerClose={this.handleDrawerClose}
                    data={this.state.roiData}
                    marketData={this.state.marketData}
                    locationData={this.state.locationData}
                    rfmData={this.state.rfmData}
                    testData={this.state.testData}
                    darkMode={this.props.darkMode}
                    userDemo={this.state.currentUser[0].db_owner}
                    jobID={this.state.job}
                  />
                  <Divider />
                </Drawer>
              {/* } */}

              {/* RESPONSE DEMO Drawer */}
              {/* {this.state.responseLoading ? <CircularProgress style={{position: 'absolute', zIndex: 10000, marginLeft: '50%', marginTop: '10%'}}/> : */}
                <Drawer
                  className={classes.drawer}
                  anchor="bottom"
                  open={this.state.openBottom}
                  classes={{
                    paper: classes.drawerPaper
                  }}
                >
                  {/* {console.log(this.state.demoData)} */}
                  <ResponseDemo
                    style={{height: '100%'}}
                    responseData={this.state.responseData}
                    demoData={this.state.demoData ? this.state.demoData : []}
                    handleDrawerClose={this.handleDrawerClose}
                    handleSwitch={this.handleSwitch}
                    job={this.state.job}
                    jobData={this.state.jobData}
                  />
                </Drawer>
              {/* } */}

              {/* EXPANSION PANELS FOR PROMOTIONS */}
              
              {
                filteredItems.reverse()
                  .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                  .map(row => (
                  <Fragment key={row.Key}>
                    <Accordion
                      key={row.Key}
                      className={classes.expansionPanel}
                      // expanded={this.state.expanded === row.Key}
                      // onChange={this.handleExpand(row.Key)}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <FormControlLabel
                        name={row.JobID}
                        checked={this.state.checkedItems.includes(row.JobID)}
                        onClick={(event) => event.stopPropagation()}
                        onChange={e => this.handleChecked(e, row.JobID)}
                        control={<Checkbox disableRipple color="primary"/>}
                      />
                      <div className={classes.headColumns}>
                        <Typography className={classes.heading}>
                          {row.Promotion}
                          <span style={{marginLeft: '10px'}}>
                            <CustomTooltip
                              title={`NOTE: Results for this promotion are incomplete at this time, Complete results will be available after the promotion has ended. Date last reported on ${moment(row.Data_Extended[0].MaxWrittenDate).format('MM/DD/YYYY')}`}
                              style={{fontSize: '25px'}}
                            >
                              <ErrorOutlineIcon style={row.Complete === 0 ? {fontSize: '15px', color: '#ed5e11'} : {display: 'none'}}/>
                            </CustomTooltip>
                          </span>
                        </Typography>
                        <Typography
                          variant="caption"
                          className={classes.heading}
                          style={{color: '#ababab', fontSize: '11px'}}
                        >
                          Job: {row.JobID}
                        </Typography>
                      </div>
                      <div className={classes.headColumns}>
                        <Typography
                          variant="caption"
                          className={classes.secondaryHeading}
                          name="Date"
                        >
                          PROMO DATES:
                          <span style={{marginLeft: '2px'}}>
                            {this.state.dateExtended === 'Extended Date' ? row.Data_Extended[0].PromoStartDate : row.Data_Promo.length > 0 ? row.Data_Promo[0].PromoStartDate : "N/A"}
                            <span style={{marginLeft: '5px', marginRight: '5px'}}>
                              to
                            </span>
                            {this.state.dateExtended === 'Extended Date' ? row.Data_Extended[0].PromoEndDate : row.Data_Promo.length > 0 ? row.Data_Promo[0].PromoEndDate : "N/A"}
                          </span>
                        </Typography>
                        <Typography
                          variant="caption"
                          className={classes.secondaryHeading}
                          style={{marginLeft: '40px'}}
                          name="Date"
                        >
                          MAILED:
                          <span style={{marginLeft: '2px'}}>
                            {this.state.dateExtended === 'Extended Date' ? row.Data_Extended[0].Mailed.toLocaleString('en') : row.Data_Promo.length > 0 ? row.Data_Promo[0].Mailed.toLocaleString('en') : "N/A"}
                          </span>
                        </Typography>
                        <Typography
                          variant="caption"
                          className={classes.secondaryHeading}
                          style={{marginLeft: '40px'}}
                          name="Date"
                        >
                          RESPONSE RATE:
                          <span style={{marginLeft: '2px'}}>
                            {this.state.dateExtended === 'Extended Date' ? (row.Data_Extended[0].Res_Rate * 100).toFixed(2) + '%' : row.Data_Promo.length > 0 ? (row.Data_Promo[0].Res_Rate * 100).toFixed(2) + '%' : "N/A"}
                          </span>
                        </Typography>
                      </div>
                    </AccordionSummary>
                      <AccordionDetails>
                        <div style={{textAlign: 'center'}}>
                          <div style={{display: 'flex'}}>
                            <ButtonBase style={{border: '1px solid #ababab'}} className={classes.button} onClick={this.handleOpen}>
                              {row.Creative.One ?
                                <img className={classes.img} alt='' src={row.Creative.One} /> :
                                <img className={classes.img} alt='' src={noImage} />
                              }
                            </ButtonBase>
                            <div style={{marginLeft: '10px'}}/>
                            <ButtonBase
                              className={classes.button}
                              onClick={this.handleOpen}
                              style={
                                // this.state.currentUser[0].db_owner === 'DEMO' ?
                                // {display: 'none'} :
                                {border: '1px solid #ababab'}
                              }
                            >
                              {row.Creative.Two ?
                                <img className={classes.img} alt='' src={row.Creative.Two} /> :
                                <img className={classes.img} alt='' src={noImage} />
                              }
                            </ButtonBase>
                          </div>
                          <br />
                          <Typography variant="caption" style={this.props.darkMode ? {color: '#4ab8bb'} : {color: '#3f51b5'}}>CLICK ON THE CREATIVE TO ENLARGE</Typography>
                        </div>
                        <div className={clsx(classes.column, classes.helper)}>
                          <Typography variant="caption" className={classes.column} style={{fontWeight: 'bold'}}>OVERALL:</Typography>
                          <div style={{display: 'flex'}}>
                            
                            <div className={classes.column}>
                              <Typography variant="caption" className={classes.metric} style={{color: '#cc0000'}}>SPEND:</Typography>
                              <Typography variant="caption" className={classes.metricNumber} style={{marginLeft: '5px'}}>
                                {this.state.dateExtended === 'Extended Date' ?'$' + Math.round(row.Data_Extended[0].Cost).toLocaleString('en')
                                  : row.Data_Promo.length > 0 ? '$' + Math.round(row.Data_Promo[0].Cost).toLocaleString('en') : "N/A"
                                }
                              </Typography>
                            </div>
                            <div className={classes.column}>
                              <Typography variant="caption" className={classes.metric} style={{color: '#b045ff'}}>MAILED:</Typography>
                              <Typography variant="caption" className={classes.metricNumber} style={{marginLeft: '5px'}}>
                                {this.state.dateExtended === 'Extended Date' ? row.Data_Extended[0].Mailed.toLocaleString('en')
                                  : row.Data_Promo.length > 0 ? row.Data_Promo[0].Mailed.toLocaleString('en') : "N/A"
                                }
                              </Typography>
                            </div>
                            <div className={classes.column}>
                              <Typography variant="caption" className={classes.metric} style={{color: '#006cc4'}}>RESPONSES:</Typography>
                              <Typography variant="caption" className={classes.metricNumber} style={{marginLeft: '5px'}}>
                                {this.state.dateExtended === 'Extended Date' ? row.Data_Extended[0].Responses.toLocaleString('en')
                                  : row.Data_Promo.length > 0 ? row.Data_Promo[0].Responses.toLocaleString('en') : "N/A"
                                }
                              </Typography>
                            </div>
                            <div className={classes.column}>
                              <Typography variant="caption" className={classes.metric} style={{color: '#529c00'}}>SALES:</Typography>
                              <Typography variant="caption" className={classes.metricNumber} style={{marginLeft: '5px'}}>
                                {this.state.dateExtended === 'Extended Date' ? '$' + Math.round(row.Data_Extended[0].Sales).toLocaleString('en')
                                  : row.Data_Promo.length > 0 ? '$' + Math.round(row.Data_Promo[0].Sales).toLocaleString('en') : "N/A"
                                }
                              </Typography>
                            </div>
                            <div className={classes.column}>
                              <Typography variant="caption" className={classes.metric} style={{color: '#6ec6cc'}}>RESPONSE RATE:</Typography>
                              <Typography variant="caption" className={classes.metricNumber} style={{marginLeft: '5px'}}>
                                {this.state.dateExtended === 'Extended Date' ? (row.Data_Extended[0].Res_Rate * 100).toFixed(2) + '%'
                                  : row.Data_Promo.length > 0 ? (row.Data_Promo[0].Res_Rate * 100).toFixed(2) + '%' : "N/A"
                                }
                              </Typography>
                            </div>
                            <div className={classes.column}>
                              <Typography variant="caption" className={classes.metric} style={{color: '#deb40d'}}>AVG TICKET:</Typography>
                              <Typography variant="caption" className={classes.metricNumber} style={{marginLeft: '5px'}}>
                                {this.state.dateExtended === 'Extended Date' ? '$' + Math.round(row.Data_Extended[0].Sales / row.Data_Extended[0].Responses).toLocaleString('en')
                                  : row.Data_Promo.length > 0 ? '$' + Math.round(row.Data_Promo[0].Sales / row.Data_Promo[0].Responses).toLocaleString('en') : "N/A"
                                }
                              </Typography>
                            </div>
                          </div>

                          <div style={{display: 'flex', float: 'right', marginTop: '20px'}}>
                            <div className={classes.column}>
                              <Typography variant="caption" align="right"><Link onClick={() => this.handleDrawerOpen(row.JobID)} className={this.props.darkMode ? classes.linkDark : classes.link}>VIEW ROI</Link></Typography>
                            </div>
                            <div
                              className={classes.column}
                              style={JSON.parse(localStorage.getItem('currentUser'))[0].compIDs === '323' ? {} : {display: 'none'}}
                            >
                              <Typography variant="caption" align="right" className={classes.link}>
                                <Link onClick={() => this.handleResponseDrawerOpen(row.JobID)} className={this.props.darkMode ? classes.linkDark : classes.link}>VIEW RESPONSE DEMOGRAPHIC</Link>
                              </Typography>
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </Fragment>
                ))             
              }
              {this.state.compareMode && this.state.checkedItems.length >= 2 ? 
                <Accordion expanded>
                  <AccordionSummary>
                    PROMOTIONS TOTAL
                  </AccordionSummary>                  
                  <AccordionDetails style={{display: "flex", justifyContent: "space-evenly"}}>                    
                    <div style={{textAlign: 'right'}}>
                      <Typography variant="caption" className={classes.metric} style={{color: '#cc0000'}}>SPEND</Typography>
                      <Typography>${Math.round(filteredItems.filter(({JobID}) => this.state.checkedItems.indexOf(JobID) !== -1).map(item => item[`Data_${this.state.dateExtended.split(" ")[0]}`][0].Cost).reduce((a,b) => a + b, 0)).toLocaleString('en')}</Typography>
                    </div>
                    <div style={{textAlign: 'right'}}>
                      <Typography variant="caption" className={classes.metric} style={{color: '#b045ff'}}>MAILED</Typography>
                      <Typography>{Math.round(filteredItems.filter(({JobID}) => this.state.checkedItems.indexOf(JobID) !== -1).map(item => item[`Data_${this.state.dateExtended.split(" ")[0]}`][0].Mailed).reduce((a,b) => a + b, 0)).toLocaleString('en')}</Typography>
                    </div>
                    <div style={{textAlign: 'right'}}>
                      <Typography variant="caption" className={classes.metric} style={{color: '#006cc4'}}>RESPONSES</Typography>
                      <Typography>{Math.round(filteredItems.filter(({JobID}) => this.state.checkedItems.indexOf(JobID) !== -1).map(item => item[`Data_${this.state.dateExtended.split(" ")[0]}`][0].Responses).reduce((a,b) => a + b, 0)).toLocaleString('en')}</Typography>
                    </div>
                    <div style={{textAlign: 'right'}}>
                      <Typography variant="caption" className={classes.metric} style={{color: '#529c00'}}>SALES</Typography>
                      <Typography>${Math.round(filteredItems.filter(({JobID}) => this.state.checkedItems.indexOf(JobID) !== -1).map(item => item[`Data_${this.state.dateExtended.split(" ")[0]}`][0].Sales).reduce((a,b) => a + b, 0)).toLocaleString('en')}</Typography>
                    </div>
                    <div style={{textAlign: 'right'}}>
                      <Typography variant="caption" className={classes.metric} style={{color: '#6ec6cc'}}>RESPONSE RATE</Typography>
                      <Typography>{(filteredItems.filter(({JobID}) => this.state.checkedItems.indexOf(JobID) !== -1).map(item => item[`Data_${this.state.dateExtended.split(" ")[0]}`][0].Responses).reduce((a,b) => a + b, 0) / filteredItems.filter(({JobID}) => this.state.checkedItems.indexOf(JobID) !== -1).map(item => item[`Data_${this.state.dateExtended.split(" ")[0]}`][0].Mailed).reduce((a,b) => a + b, 0) * 100).toFixed(2)}%</Typography>
                    </div>
                    <div style={{textAlign: 'right'}}>
                      <Typography variant="caption" className={classes.metric} style={{color: '#deb40d'}}>AVG TICKET</Typography>
                      <Typography>${Math.round(filteredItems.filter(({JobID}) => this.state.checkedItems.indexOf(JobID) !== -1).map(item => item[`Data_${this.state.dateExtended.split(" ")[0]}`][0].Sales).reduce((a,b) => a + b, 0) / filteredItems.filter(({JobID}) => this.state.checkedItems.indexOf(JobID) !== -1).map(item => item[`Data_${this.state.dateExtended.split(" ")[0]}`][0].Responses).reduce((a,b) => a + b, 0)).toLocaleString('en')}</Typography>
                    </div>
                  </AccordionDetails>                  
                </Accordion>
              : null
              }
            </div>
          </Fade>
            <TablePagination
              rowsPerPageOptions={
                filteredItems.length > 50 ?
                [10, Math.round(filteredItems.length / 2), 'Show All'] :
                filteredItems.length < 25 ?
                [10, 15, 'Show All'] :
                [10, Math.round(filteredItems.length / 2), 'Show All']
              }
              component="div"
              count={filteredItems.length}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </div>
          : <div className={classes.root} style={{position: 'relative', textAlign: 'center', marginTop: '25px'}}>
            <div className={classes.unavailable}>
              <Typography style={{fontSize: '50px', color: '#0c5ab4', fontWeight: 'bold'}}>COMING SOON</Typography>
              <Typography variant="caption" style={{fontSize: '25px'}}>This screen is unavailable at this time. For more information please contact Support at <a href="mailto: support@lsdirect.com">support@lsdirect.com</a></Typography>
            </div>
            <img style={{width: '98%'}} src={tentpoleStandIn} alt="" />
          </div>
        }
      </Fragment>
    )
  }
}

export default withStyles(styles)(Tentpoles)
