import React from 'react'
import { Typography, makeStyles} from '@material-ui/core'
import roiStandIn from '../assets/homeStandIns/roiStandIn.png'
import smartDashLogo from '../assets/SmartDash.png'

const useStyles = makeStyles(theme => ({
  homeContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
    height: '100%',
    zIndex: 1000,
  },
  homeHeader: {
    fontSize: '17px',
    color: '#0c5ab4',
    fontWeight: 'bold',
    marginTop: '30px',
  },
  homeLogo: {
    width: '85%',
    opacity: 0.2,
  },
  pageContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
    height: '100%',
    zIndex: 1000,
  },
  pageHeader: {
    fontSize: '50px',
    color: '#0c5ab4',
    fontWeight: 'bold',
    marginTop: 200
  },
  pageSubtext: {
    fontSize: '25px',    
  },
  pageLogo: {
    width: '75%',
  },
  image: {
    position: 'absolute',
    zIndex: -100,
    opactiy: 0,    
    width: '95%',
  }
}))

const RenderAskReport = ({content, logo, header, background, image, subtext}) => (
  <div className={content}>
    <img className={logo} src={smartDashLogo} />
    <Typography className={header}>ASK FOR REPORT</Typography>
    <Typography variant="caption" className={subtext}>Please contact your Client Success Manager for more information</Typography>
    <img src={background} className={image}/>
  </div>
)

export default function AskReport({home, backgroundImg}) {
  const classes = useStyles()
  console.log(backgroundImg)
  return (
    <div>
      {
        home ?
        <RenderAskReport content={classes.homeContent} logo={classes.homeLogo} header={classes.homeHeader}/>
        :
        <RenderAskReport content={classes.pageContent} logo={classes.pageLogo} header={classes.pageHeader} background={backgroundImg} image={classes.image} subtext={classes.pageSubtext}/>
      }
    </div>
  )
}
