import React, { Component } from 'react'
import Cookies from 'js-cookie'
import {
  withStyles,
  Typography,
  SvgIcon,
  Card,
  CardHeader,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  FormControlLabel,
  Paper,
} from '@material-ui/core'
import moment from 'moment'

const styles = theme => ({
  pageTitle: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '17px',
    fontWeight: 'light',
    color: 'rgba(115, 115, 115, 0.9)'
  },
  pageTitleDark: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '17px',
    fontWeight: 'light',
    color: 'rgba(255, 255, 255, 0.9)'
  },
  pageIcon: {
    fontSize: '15px',
    marginTop: '21px',
    marginRight: '2px',
  },
  pageIconDark: {
    fontSize: '15px',
    color: 'rgba(255, 255, 255, 0.9)',
    marginTop: '5px',
    marginRight: '2px',
  },
  header: {
    fontSize: '15px'
  },
  card: {
    width: '20%',
    height: 175,
    textAlign: 'center'
  }
})

function LicenseeIcon(props) {
  return(
    <SvgIcon {...props}>
      <path fill="currentColor" d="M12,5.5A3.5,3.5 0 0,1 15.5,9A3.5,3.5 0 0,1 12,12.5A3.5,3.5 0 0,1 8.5,9A3.5,3.5 0 0,1 12,5.5M5,8C5.56,8 6.08,8.15 6.53,8.42C6.38,9.85 6.8,11.27 7.66,12.38C7.16,13.34 6.16,14 5,14A3,3 0 0,1 2,11A3,3 0 0,1 5,8M19,8A3,3 0 0,1 22,11A3,3 0 0,1 19,14C17.84,14 16.84,13.34 16.34,12.38C17.2,11.27 17.62,9.85 17.47,8.42C17.92,8.15 18.44,8 19,8M5.5,18.25C5.5,16.18 8.41,14.5 12,14.5C15.59,14.5 18.5,16.18 18.5,18.25V20H5.5V18.25M0,20V18.5C0,17.11 1.89,15.94 4.45,15.6C3.86,16.28 3.5,17.22 3.5,18.25V20H0M24,20H20.5V18.25C20.5,17.22 20.14,16.28 19.55,15.6C22.11,15.94 24,17.11 24,18.5V20Z" />
    </SvgIcon>
  )
}

class LicenseeParticipation extends Component {
  _isMounted = false

  constructor() {
    super()
    this.state = {
      isLoading: true,
      licenseeData: []
    }
  }

  componentDidMount() {
    this._isMounted = true

    fetch('https://api.dev.lsdirect.com/smartdash/V1/index.cfm/getLicensedDMSpendData', {
      method: 'GET',
      headers: {
        'token': Cookies.get('token')
      },
    })
    .then(response => response.json())
    .then(data => {
      if(this._isMounted) {
        this.setState({
          isLoading: false,
          licenseeData: data
        })
      }
    })
    .catch(error => {
      console.log(error)
    })
  }
  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    const { classes } = this.props;
    var rows = this.state.licenseeData.map(item => item);
    const StyledTableCell = withStyles((theme) => ({
      head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
      },
      body: {
        fontSize: 14,
      },
    }))(TableCell);
    const StyledTableRow = withStyles((theme) => ({
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
      },
    }))(TableRow);
    // base map
    // console.log(this.state.licenseeData.map(item => item));
    var lastMonthRow = rows.length > 0 ? rows.find(item => item.dbOwner === 'LAST_MONTH') : '';
    var top20Row = rows.length > 0 ? rows.find(item => item.dbOwner === 'TOP 20') : '';

    return(
      <div>
        <Typography className={this.props.darkMode ?  classes.pageTitleDark : classes.pageTitle} style={{marginTop: '25px'}}>
          <LicenseeIcon className={this.props.darkMode ? classes.pageIconDark : classes.pageIcon} />
          <span style={{marginLeft: '5px'}}>LICENSEE PARTICIPATION</span>
        </Typography>
        <br/>
        <div>
          <Typography variant="caption" className={classes.header}>Participation as of <span style={{fontSize: '17px', fontWeight: 'bold'}}>{moment().subtract(1, 'months').startOf('month').format('MMMM YYYY').toUpperCase()}</span></Typography>
          <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '15px'}}>

          <Card className={classes.card}>
            <CardHeader
              title={<Typography variant="caption" style={{fontSize: '17px'}}>TENTPOLE</Typography>}
            />
            <CardContent>
            {(lastMonthRow.tentPole * 100).toFixed() + '%'}
            </CardContent>
          </Card>
          <Card className={classes.card} style={{marginLeft: '10px'}}>
            <CardHeader
              title={<Typography variant="caption" style={{fontSize: '17px'}}>OTB</Typography>}
            />
            <CardContent>
            {(lastMonthRow.OTB * 100).toFixed() + '%'}
            </CardContent>
          </Card>
          <Card className={classes.card} style={{marginLeft: '10px'}}>
            <CardHeader
              title={<Typography variant="caption" style={{fontSize: '17px'}}>CUSTOMER TRIGGERS</Typography>}
            />
            <CardContent>
            {(lastMonthRow.customerTriggers * 100).toFixed() + '%'}
            </CardContent>
          </Card>
          <Card className={classes.card} style={{marginLeft: '10px'}}>
            <CardHeader
              title={<Typography variant="caption" style={{fontSize: '17px'}}>NEW MOVER TRIGGERS</Typography>}
            />
            <CardContent>
            {(lastMonthRow.newMoverTriggers * 100).toFixed() + '%'}
            </CardContent>
          </Card>
          <Card className={classes.card} style={{marginLeft: '10px'}}>
            <CardHeader
              title={<Typography variant="caption" style={{fontSize: '17px'}}>BOOMERANG</Typography>}
            />
            <CardContent>
            {(lastMonthRow.boomerang * 100).toFixed() + '%'}
            </CardContent>
          </Card>

          </div>
        </div>
        <br/>
        <br/>
        <div>
          <Typography variant="caption" style={{fontSize: '17px', fontWeight: 'bold'}}>TOP 20</Typography>
          <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '15px'}}>
            <Card className={classes.card}>
              <CardHeader
                title={<Typography variant="caption" style={{fontSize: '17px'}}>TENTPOLE</Typography>}
              />

              <CardContent>
              {(top20Row.tentPole * 100).toFixed() + '%'}
              </CardContent>
            </Card>
            <Card className={classes.card} style={{marginLeft: '10px'}}>
              <CardHeader
                title={<Typography variant="caption" style={{fontSize: '17px'}}>OTB</Typography>}
              />
              <CardContent>
              {(top20Row.OTB * 100).toFixed() + '%'}
              </CardContent>
            </Card>
            <Card className={classes.card} style={{marginLeft: '10px'}}>
              <CardHeader
                title={<Typography variant="caption" style={{fontSize: '17px'}}>CUSTOMER TRIGGERS</Typography>}
              />
              <CardContent>
              {(top20Row.customerTriggers * 100).toFixed() + '%'}
              </CardContent>
            </Card>
            <Card className={classes.card} style={{marginLeft: '10px'}}>
              <CardHeader
                title={<Typography variant="caption" style={{fontSize: '17px'}}>NEW MOVER TRIGGERS</Typography>}
              />
              <CardContent>
              {(top20Row.newMoverTriggers * 100).toFixed() + '%'}
              </CardContent>
            </Card>
            <Card className={classes.card} style={{marginLeft: '10px'}}>
              <CardHeader
                title={<Typography variant="caption" style={{fontSize: '17px'}}>BOOMERANG</Typography>}
              />
              <CardContent>
              {(top20Row.boomerang * 100).toFixed() + '%'}
              </CardContent>
            </Card>
          </div>
          <br/>
          <br/>
          <div id="header" className="row" style={{marginLeft: '1px'}}>
          <div className="col-md-12 margin-bottom-4">
          <label id="searchLicensee">Licensee:</label>&nbsp;<input id="searchLicensee" size="20" maxLength="45" />&nbsp;&nbsp;&nbsp;
          <label id="searchAccountName">Account Name:</label>&nbsp;<input id="searchAccountName" size="20" maxLength="45" />&nbsp;&nbsp;&nbsp;
          <label id="searchDM">DM:</label>
          <select width="500px" name="searchDM" id="searchDM" >
                <option value="0">  </option>
          </select>&nbsp;
          <button title="Top 20" className="btn btn-sm btn-short btn-danger" id="btnTop20"><i className="fa fa-fw fa-star"></i>Top 20</button>
          <button title="Show All" className="btn btn-sm btn-short bg-boldblue text-white" id="btnShowAllLicensee"><i className="fa fa-fw fa-users"></i>Show All</button>
          <button title="Reset Participation by Licensee" className="btn btn-sm btn-short bg-boldblue text-white" id="btnResetLicensee"><i className="fa fa-fw fa-rotate-left"></i>Reset</button>
        </div>
        </div>
          <br/>
          <br/>
          <div>
          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Licensee</StyledTableCell>
                  <StyledTableCell align="right">Account Name</StyledTableCell>
                  <StyledTableCell align="right">Tentpole</StyledTableCell>
                  <StyledTableCell align="right">OTB</StyledTableCell>
                  <StyledTableCell align="right">Customer Triggers</StyledTableCell>
                  <StyledTableCell align="right">New Mover Triggers</StyledTableCell>
                  <StyledTableCell align="right">Boomerang</StyledTableCell>
                  <StyledTableCell align="right">YOY</StyledTableCell>
                  <StyledTableCell align="right">DM</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.filter(x => x.hasOwnProperty('dbOwner') ? x['dbOwner'] !== 'LAST_MONTH' && x['dbOwner'] !== 'TOP 20': {}).map((row) => (
                  <StyledTableRow key={row.itemID}>
                    <StyledTableCell component="th" scope="row">
                      {row.licensee}
                    </StyledTableCell>
                    <StyledTableCell align="right">{row.accountName}</StyledTableCell>
                    <StyledTableCell align="right">{(row.tentPole * 100).toFixed() + '%'}</StyledTableCell>
                    <StyledTableCell align="right">{(row.OTB * 100).toFixed() + '%'}</StyledTableCell>
                    <StyledTableCell align="right">{(row.customerTriggers * 100).toFixed() + '%'}</StyledTableCell>
                    <StyledTableCell align="right">{(row.newMoverTriggers * 100).toFixed() + '%'}</StyledTableCell>
                    <StyledTableCell align="right">{(row.boomerang * 100).toFixed() + '%'}</StyledTableCell>
                    <StyledTableCell align="right">{(row.YOY * 100).toFixed(1) + '%'}</StyledTableCell>
                    <StyledTableCell align="right">{row.DM}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          </div>

        </div>
      </div>
    )
  }
}

export default withStyles(styles)(LicenseeParticipation)
