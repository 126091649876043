import React, { Component } from 'react'
import MonthCalendar from 'rc-calendar/lib/MonthCalendar'
import DatePicker from 'rc-calendar/lib/Picker'
import {
  withStyles,
  Typography,
  Slider,
} from '@material-ui/core'

import moment from 'moment'
import '../../assets/calendar.css'

const boxShadow = '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)'

const CustomSlider = withStyles({
  root: {
    color: '#01157b',
    height: 2,
    padding: '15px 0'
  },
  thumb: {
    height: 12,
    width: 12,
    backgroundColor: '#fff',
    border: '1px solid rgba(1, 21, 123, 0.5)',
    boxShadow: boxShadow,
    '&:focus, &:hover, &$active': {
      boxShadow: '0 2px 1px rgba(0,0,0,0.1),0 4px 7px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
    }
  },
  track: {
    height: 2,
  },
  rail: {
    height: 2,
    opacity: 0.5,
    backgroundColor: '#bfbfbf',
  },
  mark: {
    backgroundColor: '#bfbfbf',
    height: 5,
    width: 1,
    marginTop: -3,
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor',
  },
})(Slider)

class Picker extends Component {
  render() {
    const props = this.props
    const calendar = (
      <MonthCalendar
        disabledDate={props.disabledDate}
        style={{width: 250, zIndex: 1000}}
      />
    )
    return (
      <DatePicker
        animation="slide-up"
        calendar={calendar}
        value={props.value}
        onChange={props.onChange}
      >
        {
          ({value}) => {
            return (
              <input
                placeholder=""
                style={{
                  width: 50,
                  height: 30,
                  overflow: 'break-word',
                  textAlign: 'center',
                  borderRadius: 4,
                  border: '1px solid #c4c4c4',
                  fontSize: '10px',
                  padding: '0px 3px 0px 3px',
                }}
                readOnly
                value={value && value.format('MMM \'YY')}
              />
            )
          }
        }
      </DatePicker>
    )
  }
}

class MonthDatePicker extends Component {
  constructor(props) {
    super(props)
    this.state = {
      minYearmo: props.minValue,
      displayMinYearmo: props.minValue,
      maxYearmo: props.maxValue,
      displayMaxYearmo: props.maxValue,
      minDate: props.minValue.startOf('months').format('MM/DD/YYYY'),
      maxDate: props.maxValue.startOf('months').format('MM/DD/YYYY'),
      sliderStartVal: 0,
      sliderEndVal: props.maxValue.diff(props.minValue, 'months'),
      sliderValues: [0, props.maxValue.diff(props.minValue, 'months')],
      monthsInRange: props.maxValue.diff(props.minValue, 'months')
    }
  }

  onChange = (field, val) => {

    if(field === 'minYearmo') {
      if(this.state.sliderValues[1] === this.state.maxYearmo.diff(this.state.minYearmo, 'months')) {
        if(this.state.maxYearmo.diff(val, 'months') < this.state.maxYearmo.diff(this.state.minYearmo, 'months')) {
          const min = 0
          const max = this.state.maxYearmo.diff(val, 'months')
          const newMonthRange = this.state.maxYearmo.diff(val, 'months')
          this.setState({
            sliderValues: [min, max],
            monthsInRange: newMonthRange,
            minYearmo: val,
            displayMinYearmo: val,
          })
        } else {
          const min = 0
          const max = this.state.maxYearmo.diff(val, 'months')
          const newMonthRange = this.state.maxYearmo.diff(val, 'months')
          this.setState({
            sliderValues: [min, max],
            monthsInRange: newMonthRange,
            minYearmo: val,
            displayMinYearmo: val,
          })
        }
      } else {
        const min = 0
        const max = this.state.sliderValues[1] + this.state.minYearmo.diff(val, 'months')
        const newMonthRange = this.state.maxYearmo.diff(val, 'months')
        this.setState({
          sliderValues: [min, max],
          monthsInRange: newMonthRange,
          minYearmo: val,
          displayMinYearmo: val,
        })
      }
    } else {
      if(this.state.maxYearmo.diff(this.state.minYearmo, 'months') > val.diff(this.state.minYearmo, 'months')) {
        if(this.state.sliderValues[0] === 0) {
          const min = 0
          const max = val.diff(this.state.minYearmo, 'months')
          const newMonthRange = val.diff(this.state.minYearmo, 'months')
          this.setState({
            sliderValues: [min, max],
            monthsInRange: newMonthRange,
            maxYearmo: val,
            displayMaxYearmo: val,
            maxDate: val.format('MM/DD/YYYY')
          })
        } else {
          const min = this.state.sliderValues[0]
          const max = val.diff(this.state.minYearmo, 'months')
          const newMonthRange = val.diff(this.state.minYearmo, 'months')
          this.setState({
            sliderValues: [min, max],
            monthsInRange: newMonthRange,
            maxYearmo: val,
            displayMaxYearmo: val,
            maxDate: val.format('MM/DD/YYYY')
          })
        }
      } else {
        if(this.state.sliderValues[0] === 0) {
          const min = 0
          const max = val.diff(this.state.minYearmo, 'months')
          const newMonthRange = val.diff(this.state.minYearmo, 'months')
          this.setState({
            sliderValues: [min, max],
            monthsInRange: newMonthRange,
            maxYearmo: val,
            displayMaxYearmo: val,
            maxDate: val.format('MM/DD/YYYY')
          })
        } else {
          const min = this.state.sliderValues[0]
          const max = val.diff(this.state.minYearmo, 'months')
          const newMonthRange = val.diff(this.state.minYearmo, 'months')

          this.setState({
            sliderValues: [min, max],
            monthsInRange: newMonthRange,
            maxYearmo: val,
            displayMaxYearmo: val,
            maxDate: val.format('MM/DD/YYYY')
          })
        }
      }
    }

    this.props.handleDateRange(val, field)
  }

  disabledStartDate = (value) => {
    return (
      value.year() > moment().year() || value.year() === moment().year() && value.month() >= moment().month() || value.year() === moment().year() && value.month() >= this.props.maxValue.month()
    )
  }

  disabledEndDate = (value) => {
    return (
      value.year() > moment().year() || value.year() < this.props.minValue.year() || value.year() === moment().year() && value.month() > moment().month() || value.year() === this.props.minValue.year() && value.month() <= this.props.minValue.month()
    )
  }

  handleSliderValuesChange = (e, value) => {
    e.stopPropagation()
    this.setState({
      sliderValues: value
    })
  }

  handleSliderValuesCommit = (e, value) => {
    const maxDate = moment(this.state.maxDate)
    const monthDiff = this.state.monthsInRange - value[0]
    const newYearmos = []

    for(let i = 0; i < value.length; i++) {
      let maxDate = moment(this.state.maxDate)
      let monthDiff = this.state.monthsInRange - value[i]

      newYearmos.push(maxDate.subtract(monthDiff, 'months').format('YYYY-MM'))
    }

    this.setState({
      displayMinYearmo: moment(newYearmos[0]),
      displayMaxYearmo: moment(newYearmos[1]),
    })

    this.props.handleSliderDateChange(newYearmos, this.props.name)
  }

  getSliderValueText = (value, i) => {
    let maxDate = moment(this.state.maxDate)
    let monthDiff = this.state.monthsInRange - value

    return(
      <div style={{textAlign: 'center', padding: '3px 0px 1px 0px', display: 'block'}}>
        <Typography display="block" variant="caption" style={{fontSize: '10px', fontWeight: 600}}>
          {maxDate.subtract(monthDiff, 'months').format("MMM 'YY")}
        </Typography>
      </div>
    )
  }

  render() {
    return (
      <div>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <div style={{marginRight: '5px', display: 'block'}}>
            <Picker
              disabledDate={this.disabledStartDate}
              value={this.state.displayMinYearmo}
              onChange={this.onChange.bind(this, 'minYearmo')}
            />
          </div>
          <CustomSlider
            value={this.state.sliderValues}
            onMouseEnter={e => e.stopPropagation()}
            onChange={(e, value) => this.handleSliderValuesChange(e, value)}
            onChangeCommitted={this.handleSliderValuesCommit}
            marks={false}
            max={this.state.monthsInRange}
            valueLabelDisplay="auto"
            valueLabelFormat={(value, i) => this.getSliderValueText(value, i)}
            style={{margin: '0px 5px 0px 5px'}}
          />
          <br/>
          <div style={{display: 'block', marginLeft: '5px'}}>
            <Picker
              disabledDate={this.disabledEndDate}
              value={this.state.displayMaxYearmo}
              onChange={this.onChange.bind(this, 'maxYearmo')}
            />
          </div>
        </div>
        <br/>
      </div>
    )
  }
}

export default MonthDatePicker
