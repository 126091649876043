import React, { Component } from 'react'
import ReactExport from 'react-data-export'
import {
  withStyles,
  Typography,
  Button,
} from '@material-ui/core'
import { RiFileExcel2Fill } from 'react-icons/ri'
import { FaFileDownload } from 'react-icons/fa'
import moment from 'moment'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

const styles = theme => ({
  button: {
    color: '#0c7238',
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#17c261'
    }
  },
  icon: {
    fontSize: '17px',
    marginLeft: '5px',
  }
})

class TentpoleRoiExcel extends Component {
  constructor(props) {
    super(props)
    console.log(props)
    this.state = {
    }
  }

  // static getDerivedStateFromProps = (nextProps, prevState) => {
  //
  // }

  getData = (data, dateExtended) => {
      let extendedData = []
      for(let i = 0; i < data.length; i++) {
        let arr = []
        let dataObj = {}
        dataObj['Label'] = data[i].Label
        dataObj['Spend'] = data[i][`Data_${dateExtended.split(' ')[0]}`][0].Cost
        dataObj['Sales'] = data[i][`Data_${dateExtended.split(' ')[0]}`][0].Sales
        dataObj['Mailed'] = data[i][`Data_${dateExtended.split(' ')[0]}`][0].Mailed
        dataObj['Responses'] = data[i][`Data_${dateExtended.split(' ')[0]}`][0].Responses
        dataObj['Response_Rate'] = data[i][`Data_${dateExtended.split(' ')[0]}`][0].Responses / data[i][`Data_${dateExtended.split(' ')[0]}`][0].Mailed * 100
        dataObj['DM_CoA'] = data[i][`Data_${dateExtended.split(' ')[0]}`][0].Cost / data[i][`Data_${dateExtended.split(' ')[0]}`][0].Sales * 100
        dataObj['Cost_Per_Thousand'] = data[i][`Data_${dateExtended.split(' ')[0]}`][0].Cost / data[i][`Data_${dateExtended.split(' ')[0]}`][0].Mailed * 1000
        dataObj['Total_CoA'] = data[i][`Data_${dateExtended.split(' ')[0]}`][0].Cost / data[i][`Data_${dateExtended.split(' ')[0]}`][0].Total_Sales * 100
        dataObj['Average_Ticket'] = data[i][`Data_${dateExtended.split(' ')[0]}`][0].Sales / data[i][`Data_${dateExtended.split(' ')[0]}`][0].Responses
        dataObj['Total_Sales'] = data[i][`Data_${dateExtended.split(' ')[0]}`][0].Total_Sales
        dataObj['%_of_Total_Sales'] = data[i][`Data_${dateExtended.split(' ')[0]}`][0].Sales / data[i][`Data_${dateExtended.split(' ')[0]}`][0].Total_Sales * 100
        dataObj['Contribution_Margin_ROI'] = 'N/A'
        extendedData.push(dataObj)
    }

    return extendedData
  }

  render() {
    const { classes } = this.props
    const locationData = this.getData(this.props.locationData, this.props.dateExtended)
    const rfmData = this.getData(this.props.rfmData, this.props.dateExtended)
    const testData = this.getData(this.props.testData, this.props.dateExtended)

    console.log(rfmData)
    console.log(rfmData.length)

    const multiDataSet = [
      {
        columns: [
          {title: `${this.props.promoName} ROI SUMMARY`, width: {wpx: 500}, style: {font: {sz: '20', bold: true}}},
        ],
        data: [
          [
            {value: `SHOWING: Promotion Dates between ${this.props.roiData[0].PromoStartDate} and ${this.props.roiData[0].PromoEndDate}`},
          ],
          [
            {value: `FORMAT: ${this.props.format}`}
          ],
          [
            {value: `${this.props.selectedType.toUpperCase()}(s) SELECTED: ${this.props.markets} | RFM SEGMENT(s) SELECTED: ${this.props.selectedRfm} | TEST GROUP(s) SELECTED: ${this.props.selectedTestGroups}`}
          ],
        ]
      },
      {
        ySteps: 2,
        columns: [
          {title: 'PROMOTION SUMMARY', width: {wpx: 350}, style: {font: {sz: '15', bold: true}}},
        ],
        data: [[]]
      },
      {
        columns: [
          {title: 'Status', width: {wpx: 150}, style: {font: {sz: '12', bold: true}}},
          {title: 'Spend', width: {wpx: 150}, style: {font: {sz: '12', bold: true}}},
          {title: 'Mailed', width: {wpx: 150}, style: {font: {sz: '12', bold: true}}},
          {title: 'Responses', width: {wpx: 150}, style: {font: {sz: '12', bold: true}}},
          {title: 'Sales', width: {wpx: 150}, style: {font: {sz: '12', bold: true}}},
          {title: 'Response Rate', width: {wpx: 150}, style: {font: {sz: '12', bold: true}}},
          {title: 'DM CoA(%)', width: {wpx: 150}, style: {font: {sz: '12', bold: true}}},
          {title: 'Cost Per Thousand', width: {wpx: 150}, style: {font: {sz: '12', bold: true}}},
          {title: 'Total CoA(%)', width: {wpx: 150}, style: {font: {sz: '12', bold: true}}},
          {title: 'Average Ticket', width: {wpx: 150}, style: {font: {sz: '12', bold: true}}},
          {title: 'Total Sales', width: {wpx: 150}, style: {font: {sz: '12', bold: true}}},
          {title: '% of Total Sales', width: {wpx: 150}, style: {font: {sz: '12', bold: true}}},
          {title: 'Contribution Margin ROI', width: {wpx: 150}, style: {font: {sz: '12', bold: true}}},
        ],
        data: [
          [
            {value: 'Customers'},
            {value: `$${Math.round(this.props.roiData[0].Cost).toLocaleString('en')}`},
            {value: `${Math.round(this.props.roiData[0].Mailed).toLocaleString('en')}`},
            {value: `${(this.props.roiData[0].Responses).toLocaleString('en')}`},
            {value: `$${Math.round(this.props.roiData[0].Sales).toLocaleString('en')}`},
            {value: `${(this.props.roiData[0].Res_Rate * 100).toFixed(2)}%`},
            {value: `${(this.props.roiData[0].Cost / this.props.roiData[0].Sales * 100).toFixed(2)}%`},
            {value: `$${Math.round(this.props.roiData[0].Cost / this.props.roiData[0].Mailed * 1000).toLocaleString('en')}`},
            {value: `${(this.props.roiData[0].Cost / this.props.roiData[0].Total_Sales * 100).toFixed(2)}%`},
            {value: `$${Math.round(this.props.roiData[0].Sales / this.props.roiData[0].Responses).toLocaleString('en')}`},
            {value: `$${Math.round(this.props.roiData[0].Total_Sales).toLocaleString('en')}`},
            {value: `${(this.props.roiData[0].Sales / this.props.roiData[0].Total_Sales * 100).toFixed(2)}%`},
            {value: 'N/A'}
          ],
          [
            {value: 'Holdout'},
            {value: `$0`},
            {value: `${Math.round(this.props.roiData[0].HO_Count).toLocaleString('en')}`},
            {value: `${(this.props.roiData[0].HO_Resp).toLocaleString('en')}`},
            {value: `$${Math.round(this.props.roiData[0].HO_Sales).toLocaleString('en')}`},
            {value: `${(this.props.roiData[0].HO_Res_Rate * 100).toFixed(2)}%`},
            {value: ``},
            {value: ``},
            {value: ``},
            {value: `$${this.props.roiData[0].HO_Resp > 0 ? Math.round(this.props.roiData[0].HO_Sales / this.props.roiData[0].HO_Resp).toLocaleString('en') : 0}`},
            {value: `$${Math.round(this.props.roiData[0].Total_Sales).toLocaleString('en')}`},
            {value: `${(this.props.roiData[0].HO_Sales / this.props.roiData[0].Total_Sales * 100).toFixed(2)}%`},
            {value: 'N/A'}
          ],
          [
            {value: 'Adjusted'},
            {value: `$${Math.round(this.props.roiData[0].Cost).toLocaleString('en')}`},
            {value: `${Math.round(this.props.roiData[0].Mailed).toLocaleString('en')}`},
            {value: `${this.props.roiData[0].Res_Rate - this.props.roiData[0].HO_Res_Rate > 0 ? Math.round(this.props.roiData[0].Mailed * (this.props.roiData[0].Res_Rate - this.props.roiData[0].HO_Res_Rate)).toLocaleString('en') : 0}`},
            {value: `$${this.props.roiData[0].Res_Rate - this.props.roiData[0].HO_Res_Rate > 0 ? Math.round(this.props.roiData[0].Sales / this.props.roiData[0].Res_Rate * (this.props.roiData[0].Res_Rate - this.props.roiData[0].HO_Res_Rate)).toLocaleString('en') : 0}`},
            {value: `${this.props.roiData[0].Res_Rate - this.props.roiData[0].HO_Res_Rate > 0 ? ((this.props.roiData[0].Res_Rate - this.props.roiData[0].HO_Res_Rate) * 100).toFixed(2) : 0.00}%`},
            {value: `${this.props.roiData[0].Res_Rate - this.props.roiData[0].HO_Res_Rate > 0 ? (this.props.roiData[0].Cost / (this.props.roiData[0].Sales / this.props.roiData[0].Res_Rate * (this.props.roiData[0].Res_Rate - this.props.roiData[0].HO_Res_Rate)) * 100).toFixed(2) : 0}%`},
            {value: `$${Math.round(this.props.roiData[0].Cost / this.props.roiData[0].Mailed * 1000).toLocaleString('en')}`},
            {value: `${(this.props.roiData[0].Cost / this.props.roiData[0].Total_Sales * 100).toFixed(2)}%`},
            {value: `$${(this.props.roiData[0].Res_Rate - this.props.roiData[0].HO_Res_Rate) > 0 ? Math.round((this.props.roiData[0].Sales / this.props.roiData[0].Res_Rate * (this.props.roiData[0].Res_Rate - this.props.roiData[0].HO_Res_Rate)) / (this.props.roiData[0].Mailed * (this.props.roiData[0].Res_Rate - this.props.roiData[0].HO_Res_Rate))).toLocaleString('en') : 0}`},
            {value: `$${Math.round(this.props.roiData[0].Total_Sales).toLocaleString('en')}`},
            {value: `${this.props.roiData[0].Res_Rate - this.props.roiData[0].HO_Res_Rate > 0 ? ((this.props.roiData[0].Sales / this.props.roiData[0].Res_Rate * (this.props.roiData[0].Res_Rate - this.props.roiData[0].HO_Res_Rate)) / this.props.roiData[0].Total_Sales * 100).toFixed(2) : 0.00}%`},
            {value: 'N/A'}
          ],
        ],
      },
      {
        ySteps: locationData.length > 0 ? 2 : 0,
        columns: locationData.length > 0 ? [
          {title: 'ROI BY LOCATION', width: {wpx: 350}, style: {font: {sz: '15', bold: true}}},
        ] : [],
        data: [[]]
      },
      {
        columns: locationData.length > 0 ? [
          {title: 'Location', width: {wpx: 150}, style: {font: {sz: '12', bold: true}}},
          {title: 'Spend', width: {wpx: 150}, style: {font: {sz: '12', bold: true}}},
          {title: 'Mailed', width: {wpx: 150}, style: {font: {sz: '12', bold: true}}},
          {title: 'Responses', width: {wpx: 150}, style: {font: {sz: '12', bold: true}}},
          {title: 'Sales', width: {wpx: 150}, style: {font: {sz: '12', bold: true}}},
          {title: 'Response Rate', width: {wpx: 150}, style: {font: {sz: '12', bold: true}}},
          {title: 'DM CoA(%)', width: {wpx: 150}, style: {font: {sz: '12', bold: true}}},
          {title: 'Cost Per Thousand', width: {wpx: 150}, style: {font: {sz: '12', bold: true}}},
          {title: 'Total CoA(%)', width: {wpx: 150}, style: {font: {sz: '12', bold: true}}},
          {title: 'Average Ticket', width: {wpx: 150}, style: {font: {sz: '12', bold: true}}},
          {title: 'Total Sales', width: {wpx: 150}, style: {font: {sz: '12', bold: true}}},
          {title: '% of Total Sales', width: {wpx: 150}, style: {font: {sz: '12', bold: true}}},
          {title: 'Contribution Margin ROI', width: {wpx: 150}, style: {font: {sz: '12', bold: true}}},
        ] : [],
        data: locationData.length > 0 ? locationData.map(item => (
          [
            {value: item.Label},
            {value: `$${Math.round(item.Spend).toLocaleString('en')}`},
            {value: (item.Mailed).toLocaleString('en')},
            {value: (item.Responses).toLocaleString('en')},
            {value: `$${Math.round(item.Sales).toLocaleString('en')}`},
            {value: `${(item.Response_Rate).toFixed(2)}%`},
            {value: `${(item.DM_CoA).toFixed(2)}%`},
            {value: `$${Math.round(item.Cost_Per_Thousand).toLocaleString('en')}`},
            {value: `${(item.Total_CoA).toFixed(2)}%`},
            {value: `$${Math.round(item.Average_Ticket).toLocaleString('en')}`},
            {value: `$${Math.round(item.Total_Sales).toLocaleString('en')}`},
            {value: `${(item['%_of_Total_Sales']).toFixed(2)}%`},
            {value: item.Contribution_Margin_ROI},
          ]
        )) : [[]]
      },
      {
        ySteps: rfmData.length > 0 ? 2 : 0,
        columns: rfmData.length > 0 ? [
          {title: 'ROI BY RFM SEGMENT', width: {wpx: 350}, style: {font: {sz: '15', bold: true}}},
        ]
        : [],
        data: [[]]
      },
      {
        columns: rfmData.length > 0 ? [
          {title: 'RFM Segment', width: {wpx: 150}, style: {font: {sz: '12', bold: true}}},
          {title: 'Spend', width: {wpx: 150}, style: {font: {sz: '12', bold: true}}},
          {title: 'Mailed', width: {wpx: 150}, style: {font: {sz: '12', bold: true}}},
          {title: 'Responses', width: {wpx: 150}, style: {font: {sz: '12', bold: true}}},
          {title: 'Sales', width: {wpx: 150}, style: {font: {sz: '12', bold: true}}},
          {title: 'Response Rate', width: {wpx: 150}, style: {font: {sz: '12', bold: true}}},
          {title: 'DM CoA(%)', width: {wpx: 150}, style: {font: {sz: '12', bold: true}}},
          {title: 'Cost Per Thousand', width: {wpx: 150}, style: {font: {sz: '12', bold: true}}},
          {title: 'Total CoA(%)', width: {wpx: 150}, style: {font: {sz: '12', bold: true}}},
          {title: 'Average Ticket', width: {wpx: 150}, style: {font: {sz: '12', bold: true}}},
          {title: 'Total Sales', width: {wpx: 150}, style: {font: {sz: '12', bold: true}}},
          {title: '% of Total Sales', width: {wpx: 150}, style: {font: {sz: '12', bold: true}}},
          {title: 'Contribution Margin ROI', width: {wpx: 150}, style: {font: {sz: '12', bold: true}}},
        ] : [],
        data: rfmData.length > 0 ? rfmData.map(item => (
          [
            {value: item.Label},
            {value: `$${Math.round(item.Spend).toLocaleString('en')}`},
            {value: (item.Mailed).toLocaleString('en')},
            {value: (item.Responses).toLocaleString('en')},
            {value: `$${Math.round(item.Sales).toLocaleString('en')}`},
            {value: `${(item.Response_Rate).toFixed(2)}%`},
            {value: `${(item.DM_CoA).toFixed(2)}%`},
            {value: `$${Math.round(item.Cost_Per_Thousand).toLocaleString('en')}`},
            {value: `${(item.Total_CoA).toFixed(2)}%`},
            {value: `$${Math.round(item.Average_Ticket).toLocaleString('en')}`},
            {value: `$${Math.round(item.Total_Sales).toLocaleString('en')}`},
            {value: `${(item['%_of_Total_Sales']).toFixed(2)}%`},
            {value: item.Contribution_Margin_ROI},
          ]
        )) : [[]]
      },
      {
        ySteps: testData.length > 0 ? 2 : 0,
        columns: testData.length > 0 ? [
          {title: 'ROI BY TEST GROUPS', width: {wpx: 350}, style: {font: {sz: '15', bold: true}}},
        ] : [],
        data: [[]]
      },
      {
        columns: testData.length > 0 ? [
          {title: 'Test Group', width: {wpx: 150}, style: {font: {sz: '15', bold: true}}},
          {title: 'Spend', width: {wpx: 150}, style: {font: {sz: '15', bold: true}}},
          {title: 'Mailed', width: {wpx: 150}, style: {font: {sz: '15', bold: true}}},
          {title: 'Responses', width: {wpx: 150}, style: {font: {sz: '15', bold: true}}},
          {title: 'Sales', width: {wpx: 150}, style: {font: {sz: '15', bold: true}}},
          {title: 'Response Rate', width: {wpx: 150}, style: {font: {sz: '15', bold: true}}},
          {title: 'DM CoA(%)', width: {wpx: 150}, style: {font: {sz: '15', bold: true}}},
          {title: 'Cost Per Thousand', width: {wpx: 150}, style: {font: {sz: '15', bold: true}}},
          {title: 'Total CoA(%)', width: {wpx: 150}, style: {font: {sz: '15', bold: true}}},
          {title: 'Average Ticket', width: {wpx: 150}, style: {font: {sz: '15', bold: true}}},
          {title: 'Total Sales', width: {wpx: 150}, style: {font: {sz: '15', bold: true}}},
          {title: '% of Total Sales', width: {wpx: 150}, style: {font: {sz: '15', bold: true}}},
          {title: 'Contribution Margin ROI', width: {wpx: 150}, style: {font: {sz: '15', bold: true}}},
        ] : [],
        data: testData.length > 0 ? testData.map(item => (
          [
            {value: item.Label},
            {value: `$${Math.round(item.Spend).toLocaleString('en')}`},
            {value: (item.Mailed).toLocaleString('en')},
            {value: (item.Responses).toLocaleString('en')},
            {value: `$${Math.round(item.Sales).toLocaleString('en')}`},
            {value: `${(item.Response_Rate).toFixed(2)}%`},
            {value: `${(item.DM_CoA).toFixed(2)}%`},
            {value: `$${Math.round(item.Cost_Per_Thousand).toLocaleString('en')}`},
            {value: `${(item.Total_CoA).toFixed(2)}%`},
            {value: `$${Math.round(item.Average_Ticket).toLocaleString('en')}`},
            {value: `$${Math.round(item.Total_Sales).toLocaleString('en')}`},
            {value: `${(item['%_of_Total_Sales']).toFixed(2)}%`},
            {value: item.Contribution_Margin_ROI},
          ]
        )) : [[]]
      },

    ]

    return(
      <div>
        <ExcelFile
          filename={`${this.props.promoName}_ROI(${moment().format('YYYY-MM-DD')})`}
          element={
            <Button
              className={classes.button}
            >
              <div style={{display: 'inline-flex', alignItems: 'center'}}>
                <Typography variant="caption" style={{marginTop: '3px', fontSize: '11px'}}>EXPORT PROMO ROI</Typography>
                <FaFileDownload className={classes.icon} />
              </div>
            </Button>
          }
        >
          <ExcelSheet  dataSet={multiDataSet} name={this.props.promoName} />
        </ExcelFile>
      </div>
    )
  }
}

export default withStyles(styles)(TentpoleRoiExcel)
