import React, { Component, Fragment } from 'react'
import { withStyles,Typography, Divider, CircularProgress } from '@material-ui/core'
import BarChartStacked from '../charts/BarChartStacked'
import BarChart from '../charts/BarChart'
import RfmModule from '../rfm/RfmModule'
import HowToRegIcon from '@material-ui/icons/HowToReg'
import Cookies from 'js-cookie'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  Button,
  Icon,
  Tooltip,
  Chip,
  InputBase
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import rfmMainStandIn from '../../assets/mainStandIns/rfmMainStandIn.png'
import CancelIcon from '@material-ui/icons/Cancel'
import {IoMdRemoveCircleOutline} from 'react-icons/io'
import AskReport from '../AskReport'

const styles = theme => ({
  description: {
    color: '#626262',
    marginTop: '10px',
    paddingBottom: '10px',
  },
  loader: {
    marginTop: '30%',
    marginLeft: '50%'
  },
  tableBorder: {
    marginTop: '20px',
    display: 'flex',
    align: 'right'
  },
  mainDiv: {
    display: 'flex; flex-direction:row',
    width: '100%',
  },
  chart: {
    width: '85%',
    padding: '2em',
    minHeight: 750,
  },
  chart2: {
    marginTop: '20px',
    width: '100%',
    padding: '2em',
  },
  formControl: {
    margin: theme.spacing(1),
    width: 250,
  },
  pageTitle: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '17px',
    fontWeight: 'light',
    color: 'rgba(115, 115, 115, 0.9)'
  },
  pageTitleDark: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '17px',
    fontWeight: 'light',
    color: 'rgba(255, 255, 255, 0.9)'
  },
  pageIcon: {
    fontSize: '15px',
    marginTop: '21px',
    marginRight: '2px',
  },
  pageIconDark: {
    fontSize: '15px',
    color: 'rgba(255, 255, 255, 0.9)',
    marginTop: '5px',
    marginRight: '2px',
  },
  total: {
    fontWeight: 'bold',
    fontSize: '20px',
    color: 'rgba(247, 133, 10, 0.9)',
  },
  grandTotal: {
    marginTop: '20px',
    minHeight: 150,
    padding: theme.spacing(1)
  },
  unavailable: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // margin: '2em'
  },
  resetButton: {
    marginTop: '5px',
    display: 'inline-flex',
    alignItems: 'center',
    color: '#001d84',
    borderColor: '#001d84',
    '&:hover': {
      color: '#4ab8bb',
      borderColor: '#4ab8bb',
      backgroundColor: 'transparent'
    }
  },
  chip: {
    margin: 1,
    height: '20px',
    padding: '0px 2px 0px 2px',
    border: '1px solid #636768',
    fontSize: '9px',
    backgroundColor: 'rgba(231, 231, 231, 0.6)',
  },
  chips: {
    display: 'flex',
  }
})


const StyledInput = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    overflowX: 'auto',
    position: 'relative',
    padding: '2px 5px 2px 5px',
    border: '1px solid #767676',
    fontSize: 7,
    // minWidth: 200,
    // maxWidth: 350,
    '&:focus': {
      border: '1px solid',
      borderRadius: 4,
      borderColor: '#0054c1',
      backgroundColor: 'transparent',
    }
  }
}))(InputBase)

class Rfmcounts extends Component {

  _isMounted = false

  constructor() {
    super()
    this.state = {
      isLoading: true,
      chartLoading: true,
      rfmData: [],
      defaultRfmData: [],
      value: "location",
      tradeValue: 1,
      otbValue: 1,
      rfmDataTotal: [],
      rfmLabels1: [],
      series: [],
      locationClicked: false,
      compID: JSON.parse(localStorage.getItem('currentUser'))[0].compIDs,
      selectedRfmValues: ['All Segments'],
    }
    this.handleMetricChange = this.handleMetricChange.bind(this)
  }

  componentDidMount() {
    this._isMounted = true

    Promise.all([
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/rfm/${this.state.value}`, {
          headers: {
            token: Cookies.get("token")
          }
        }),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/rfm`, {
          headers: {
            token: Cookies.get("token")
          }
        })
    ])
    .then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
    .then(([data, data2]) => {
      if (this._isMounted && data) {
        console.log(data2[0].Data.map(item => item))
        this.setState({
          isLoading: false,
          chartLoading: false,
          rfmData: data,
          defaultRfmData: data,
          rfmDataTotal: this.state.compID === '7' ? data2[0].Data.map(item => item.Count_All) : data2[0].Data.filter(item => item.RFM_Segment !== 'DS').map(item => item.Count_All),
          rfmLabels1: data2[0].Data.map(item => item.RFM_Segment),
        })
      } else {
        this.setState({
          isLoading: false,
          rfmData: [],
        })
      }
    })
    .catch(error => {
      console.log(error)
    })

    if(JSON.parse(localStorage.getItem('currentUser'))[0].username.toUpperCase().includes('USER')) {
    } else {
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/pageviews/add?display=rfm_counts&screen_name=rfm_counts`, {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'token': Cookies.get('token'),
        },
      })
      .then(response => {
        if(response.ok === true) {
          return response.json()
        }
      })
      .catch(error => {
        console.log(error)
      })
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  handleLocationClick = (locationName) => {

    // // console.log(!this.state.locationFilter)
    // // this.setState({
    // //   locationFilter: !this.state.locationFilter
    // // })
    // // this.setState({
    // //   rfmData: this.state.rfmData.filter(item => item.Label === locationName)
    // // })
    // console.log(locationName)
    // console.log(this.state.rfmData.filter(item => item.Label === locationName))
  }

  handleMetricChange(event) {
    this.setState({
      value: event.target.value
    })
    event.preventDefault()
    fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/rfm/${event.target.value}`, {
      method: "GET",
      headers: {
        token: Cookies.get("token")
      }
    })
    .then(response => response.json())
    .then(data => {
        this.setState({
          rfmData: data,
          defaultRfmData: data,
        })
    })
    .catch(error => {
      console.log(error)
    })
  }

  handleOtbChange = e => {
    this.setState({
      chartLoading: true,
    })
    const otbToggle = e.target.value

    this.setState({
      otbValue: otbToggle,
      chartLoading: false,
    })
  }

  handleTAChange = e => {
    this.setState({
      chartLoading: true,
    })

    const taToggle = e.target.value

    this.setState({
      tradeValue: taToggle,
      chartLoading: false,
    })
  }

  handleRfmChange = values => {
    let rfmData = []

    if(values.length > 1 && this.state.selectedRfmValues.includes('All Segments')
      || values.length >= 1 && values.includes('All Segments') === false
    ) {
      if(values.filter(item => item !== 'All Segments').length < 2) {
        let filter = values.filter(item => item !== 'All Segments')
        this.setState({
          selectedRfmValues: filter
        })
      } else {
        this.setState({
          selectedRfmValues: values.filter(item => item !== 'All Segments')
        })
      }
    } else {
      this.setState({
        selectedRfmValues: ['All Segments'],
      })
    }
  }

  handleDeselectSegment = (e, segment) => {
    const filteredMarkets = this.state.selectedRfmValues.filter(item => item !== segment)
    this.setState({
      selectedRfmValues: filteredMarkets
    })
  }

  handleGetRanges = (data) => {
    if(this.state.otbValue === 1 && this.state.tradeValue === 1) {
      let ranges = []
      let length = data.map(item => item.Data.length)[0]

      for(let i = 0; i < length; i++) {
        let dataArr = []
        for(let j = 0; j < data.length; j++) {
          let count = []
          dataArr.push(data[j].Data[i].COUNT_ALL)
        }
        ranges.push(dataArr)
      }
      return ranges
    } else if(this.state.otbValue === 1 && this.state.tradeValue === 0) {
      let ranges = []
      let length = data.map(item => item.Data.length)[0]

      for(let i = 0; i < length; i++) {
        let dataArr = []
        for(let j = 0; j < data.length; j++) {
          let count = []
          dataArr.push(data[j].Data[i].COUNT_TA)
        }
        ranges.push(dataArr)
      }
      return ranges
    } else if(this.state.otbValue === 0 && this.state.tradeValue === 1) {
      let ranges = []
      let length = data.map(item => item.Data.length)[0]

      for(let i = 0; i < length; i++) {
        let dataArr = []
        for(let j = 0; j < data.length; j++) {
          let count = []
          dataArr.push(data[j].Data[i].NOOTB_ALL)
        }
        ranges.push(dataArr)
      }
      return ranges
    } else {
      let ranges = []
      let length = data.map(item => item.Data.length)[0]

      for(let i = 0; i < length; i++) {
        let dataArr = []
        for(let j = 0; j < data.length; j++) {
          let count = []
          dataArr.push(data[j].Data[i].NOOTB_TA)
        }
        ranges.push(dataArr)
      }
      return ranges
    }
  }

  getSeries = (ranges, data) => {
    let series = []
    let colorsHash = {
      0: '#15cf6f',
      1: '#47afc9',
      2: '#007491',
      3: '#c369ff',
      4: '#dec614',
      5: '#bd982b',
      6: '#e38fb6',
      7: '#f7850a',
      8: '#ff2b34',
      9: '#c4c4c4',
      10: '#9c9c9c',
      11: '#737373',
    }

    for(let i = 0; i < ranges.length; i++) {
      let dataObj = {}
      dataObj['name'] = data[0].Data[i].RFM_Segment
      dataObj['data'] = ranges[i]
      dataObj['color'] = colorsHash[i]
      dataObj['rfmName'] = data[0].Data[i].RFM_Name
      series.push(dataObj)
    }

    if(this.state.compID === "7") {
      return series
    } else {
      return series.filter(item => item.name !== 'DS')
    }
  }

  handleLocationClick = (location) => {
    this.setState({
      rfmData: this.state.rfmData.filter(item => item.Label === location),
      locationClicked: true,
    })

  }

  handleRfmMarketReset = () => {
    this.setState({
      rfmData: this.state.defaultRfmData,
      locationClicked: false,
    })
  }

  render() {
    const { classes } = this.props
    const currentUserLite = JSON.parse(localStorage.getItem('currentUser')) && JSON.parse(localStorage.getItem('currentUser'))[0].sd_lite === 1 ? true : false
    const dbO = JSON.parse(localStorage.getItem("currentUser")) ? JSON.parse(localStorage.getItem("currentUser"))[0].db_owner : ""
    const labels = (this.state.rfmData.map(item => item.Label))
    const ranges = this.handleGetRanges(this.state.rfmData)
    const series = this.getSeries(ranges, this.state.rfmData)

    console.log(this.state.compID)

    const colors = [
      '#00ff7b',
      '#66e0ff',
      '#007491',
      '#c369ff',
      '#ffe100',
      '#bd982b',
      '#e38fb6',
      '#f7850a',
      '#ff2b34',
      '#c4c4c4',
      '#9c9c9c',
      '#737373',
    ]

    return (
      <div>
      { this.state.isLoading ? <div className={classes.loader}><CircularProgress /></div> :
        this.state.rfmData.length > 0 && !currentUserLite && dbO !== "ESCANABA" ?
        <Fragment>
          <Typography className={this.props.darkMode ? classes.pageTitleDark : classes.pageTitle} style={{marginTop: '25px'}}>
            <HowToRegIcon className={this.props.darkMode ? classes.pageIconDark : classes.pageIcon} />
            <span style={{marginLeft: '5px'}}>RFM COUNTS</span>
          </Typography>
          <br/>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <Paper className={classes.chart}>
              {this.state.chartLoading ?
                <div>
                  <CircularProgress style={{position: 'absolute', marginLeft: '30%', marginTop: '20%', zIndex: 10000}}/>
                </div>
              :
                <div/>
              }
              <div style={this.state.chartLoading ? {opacity: 0.5} : {}}>
                <div style={this.state.locationClicked ? {} : {display: 'none'}}>
                  <Button
                    className={classes.resetButton}
                    variant="outlined"
                    onClick={this.handleRfmMarketReset}
                    disableRipple
                  >
                    <div style={{display: 'inline-flex', alignItems: 'center'}}>
                      <ArrowBackIcon style={{fontSize: '15px'}}/>
                      <Typography style={{paddingTop: '2px', marginLeft: '2px'}} variant="caption">BACK TO ALL {this.state.value.toUpperCase()}S</Typography>
                    </div>
                  </Button>
                </div>

                <BarChartStacked
                  labels={labels}
                  series={
                    this.state.selectedRfmValues[0] === 'All Segments' ? series : series.filter(item => this.state.selectedRfmValues.includes(item.name))
                  }
                  colors={colors}
                  title={'RFM By Location'}
                  xLabel={'Customer Count'}
                  height={this.state.rfmData.length === 1 ? 500 : this.state.rfmData.length > 80 ? 1500 : this.state.rfmData.length < 60 ? 1000 : 1200}
                  darkMode={this.props.darkMode}
                  handleLocationClick={this.handleLocationClick}
                />                
              </div>
            </Paper>

            <div style={{display: 'block', textAlign: 'center', marginLeft: '20px', width: 300, minHeight: 750}}>
              <Paper style={{padding: '1em', minHeight: 350}}>
                <FormControl variant="outlined" className={classes.formControl} style={{marginTop: '20px'}}>
                  <InputLabel id="metric-select">Metric Type</InputLabel>
                  <Select
                    id="metric"
                    labelId="metric-select"
                    value={this.state.value}
                    onChange={this.handleMetricChange}
                    label="Metric Type"
                  >
                    <MenuItem selected={true} value={"location"}><Typography variant="caption">Location</Typography></MenuItem>
                    <MenuItem value={"market"}><Typography variant="caption">Market</Typography></MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="otb-select">OTB Customers</InputLabel>
                  <Select
                    id="otb"
                    labelId="otb-select"
                    value={this.state.otbValue}
                    onChange={this.handleOtbChange}
                    label="OTB Customers"
                  >
                    <MenuItem value={1}><Typography variant="caption">Include OTB Customers</Typography></MenuItem>
                    <MenuItem value={0}><Typography variant="caption">Exclude OTB Customers</Typography></MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="trade-select">Trade Area</InputLabel>
                  <Select
                    id="trade-area"
                    labelId="trade-select"
                    value={this.state.tradeValue}
                    onChange={this.handleTAChange}
                    label="Trade Area"
                  >
                    <MenuItem value={1}><Typography variant="caption">Include Customers Outside Trade Area</Typography></MenuItem>
                    <MenuItem value={0}><Typography variant="caption">Exclude Customers Outside Trade Area</Typography></MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="rfm-select-label">RFM Segments</InputLabel>
                  <Select
                    multiple
                    id="rfm-select"
                    label-id="rfm-select-label"
                    value={this.state.selectedRfmValues}
                    onChange={e => this.handleRfmChange(e.target.value)}
                    label="RFM Segments"
                    renderValue={selected => (
                      selected.length > 1 ?
                      <div className={classes.chips}>
                        {selected.map(value => (
                          <Chip
                            key={value}
                            label={value !== 'All Segments' ? value.split(' - ')[0].substring(0, 4) + '..' : 'ALL'}
                            className={classes.chip}
                            onMouseDown={e => e.stopPropagation()}
                            onDelete={e => this.handleDeselectSegment(e, value)}
                            deleteIcon={
                              <CancelIcon style={{padding: '3px'}}/>
                            }
                          />
                        ))}
                      </div>
                      :
                      <div>
                        {selected.map(value => (
                          <Typography key={value} variant="caption">{value}</Typography>
                        ))}
                      </div>
                    )}
                  >
                    <MenuItem value={'All Segments'}><Typography variant="caption">All Segments</Typography></MenuItem>
                    {this.state.compID === '7' ?
                      this.state.rfmData[0].Data.map(item =>
                        <MenuItem key={item.RFM_Ord} value={item.RFM_Segment}><Typography variant="caption">{item.RFM_Segment + ' - ' + item.RFM_Name}</Typography></MenuItem>
                      )
                    :
                      this.state.rfmData[0].Data.filter(item => item.RFM_Segment !== 'DS').map(item =>
                        <MenuItem key={item.RFM_Ord} value={item.RFM_Segment}><Typography variant="caption">{item.RFM_Segment + ' - ' + item.RFM_Name}</Typography></MenuItem>
                      )
                    }
                  </Select>
                </FormControl>
                <br/>
                <br/>
              </Paper>

              <Paper className={classes.tableBorder}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" colSpan={2} component="th" scope="row">
                        <Typography variant="caption" style={{fontWeight: 'bold'}}>COUNT BY RFM SEGMENT</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="caption" style={{fontSize: 11, fontWeight: 'bold'}}>RFM Segment</Typography>
                      </TableCell>
                      <TableCell align="right" style={{borderLeft: '1px solid #dcdcdc'}}>
                        <Typography variant="caption" style={{fontSize: 11, fontWeight: 'bold'}}>Count</Typography>
                      </TableCell>
                    </TableRow>
                    {this.state.selectedRfmValues[0] === 'All Segments' ?
                      series.map(row => (
                        <TableRow key={row.name}>
                          <TableCell component="th" scope="row">
                            <Typography variant="caption" style={{color: `${row.color}`}}>{row.name}</Typography>
                            <Tooltip title={<Typography variant="caption" style={{fontSize: '11px', fontWeight: 'bold'}}>{row.rfmName.toUpperCase()}</Typography>}>
                              <InfoOutlinedIcon style={{fontSize: '11px', float: 'right'}}/>
                            </Tooltip>
                          </TableCell>
                          <TableCell align="right" style={{borderLeft: '1px solid #dcdcdc'}}>
                            <Typography variant="caption">{row.data.reduce((a,b) => a + b, 0).toLocaleString('en')}</Typography>
                          </TableCell>
                        </TableRow>
                      ))
                    :
                      series.filter(item => this.state.selectedRfmValues.includes(item.name)).map(row => (
                        <TableRow key={row.name}>
                          <TableCell component="th" scope="row">
                            <Typography variant="caption" style={{color: `${row.color}`}}>{row.name}</Typography>
                            <Tooltip title={<Typography variant="caption" style={{fontSize: '11px', fontWeight: 'bold'}}>{row.rfmName.toUpperCase()}</Typography>}>
                              <InfoOutlinedIcon style={{fontSize: '11px', float: 'right'}}/>
                            </Tooltip>
                          </TableCell>
                          <TableCell align="right" style={{borderLeft: '1px solid #dcdcdc'}}>
                            <Typography variant="caption">{row.data.reduce((a,b) => a + b, 0).toLocaleString('en')}</Typography>
                          </TableCell>
                        </TableRow>
                      ))
                    }

                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="caption" style={{fontWeight: 'bold', fontSize: 13}}>TOTAL</Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="caption" style={{fontWeight: 'bold', fontSize: 13}}>
                          {this.state.selectedRfmValues[0] === 'All Segments' ?
                            series.map(item => item.data).map(item => item.reduce((a,b) => a + b, 0)).reduce((a,b) => a + b, 0).toLocaleString('en')
                          :
                            series.filter(item => this.state.selectedRfmValues.includes(item.name)).map(item => item.data).map(item => item.reduce((a,b) => a + b, 0)).reduce((a,b) => a + b, 0).toLocaleString('en')
                          }
                        </Typography>
                      </TableCell>
                    </TableRow>

                  </TableBody>
                </Table>
              </Paper>

              <Paper className={classes.grandTotal}>
                <Typography style={{marginTop: '45px'}} display="block" variant="caption">
                    <span>The <span style={{fontWeight: 'bold'}}>Grand Total</span> RFM count is </span>
                    <div className={classes.total}>
                      {this.state.rfmDataTotal.reduce((a,b) => a + b, 0).toLocaleString('en')}
                    </div>
                </Typography>
              </Paper>
            </div>
          </div>
          <Paper className={classes.chart2}>
              {/* {console.log(this.state.rfmDataTotal)} */}
              <div>
                <BarChart
                  labels={this.state.compID === '7' ? this.state.rfmLabels1 : this.state.rfmLabels1.filter(item => item !== 'DS')}
                  data={[{
                    name: 'Count',
                    data: this.state.rfmDataTotal
                  }]}
                  colors={colors}
                  width={'99%'}
                  height={250}
                  yLabel={'Customer Count'}
                  xLabel={'RFM Segment'}
                  title={'OVERALL RFM COUNT BY SEGMENT'}
                  distributed={true}
                  toolbar={false}
                  darkMode={this.props.darkMode}
                />
                {console.log(this.state.rfmDataTotal)}
              </div>
          </Paper>
        </Fragment>
          : <div className={classes.unavailable} >
              <AskReport />
            </div>
        }
      </div>
    )
  }
}

export default withStyles(styles)(Rfmcounts)
