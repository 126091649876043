import React, { Component } from 'react'
import Chart from 'react-apexcharts'


class CustLine extends Component {
  constructor(props) {
    super(props)
    console.log(props.series)
    this.state = {
      series: props.series,
      // series: [{
      //           name: "Customers New",
      //           data: [45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10]
      //         },
      //         {
      //           name: "Customer New LY",
      //           data: [35, 41, 62, 42, 13, 18, 29, 37, 36, 51, 32, 35]
      //         },
      //         // {
      //         //   name: 'Total Visits',
      //         //   data: [87, 57, 74, 99, 75, 38, 62, 47, 82, 56, 45, 47]
      //         // }
      //       ],
            options: {
              chart: {
                height: 350,
                // height: 350,
                type: 'line',
                zoom: {
                  enabled: false
                },
                toolbar: {
                  show: false,
                }
              },
              dataLabels: {
                enabled: false
              },
              stroke: {
                width: [3, 2],
                curve: 'straight',
                dashArray: [8, 0]
              },
              // colors: ['#fff', 'rgba(255, 255, 255, 0.6)'],
              // colors: ['#3f7315', '#ba7586', ],
              colors: ['rgba(255, 255, 255, 0.5)', 'rgba(255, 255, 255, 1.0)'],
              // title: {
              //   text: 'Page Statistics',
              //   align: 'left'
              // },
              legend: {
                show: true,
                position: 'bottom',
                markers: {
                  width: 8,
                  height: 8,
                }
              },
              // legend: {
              //   tooltipHoverFormatter: function(val, opts) {
              //     return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
              //   }
              // },
              markers: {
                size: 0,
                hover: {
                  sizeOffset: 6
                }
              },
              xaxis: {
                labels: {
                  show: false,
                }
                // categories: ['01 Jan', '02 Jan', '03 Jan', '04 Jan', '05 Jan', '06 Jan', '07 Jan', '08 Jan', '09 Jan',
                //   '10 Jan', '11 Jan', '12 Jan'
                // ],
              },
              yaxis: {
                show: false,
              },
              tooltip: {
                enabled: false,
                y: [
                  {
                    title: {
                      formatter: function (val) {
                        return val + " (mins)"
                      }
                    }
                  },
                  {
                    title: {
                      formatter: function (val) {
                        return val + " per session"
                      }
                    }
                  },
                  {
                    title: {
                      formatter: function (val) {
                        return val;
                      }
                    }
                  }
                ]
              },
              grid: {
                show: false,
                // borderColor: '#f1f1f1',
              }
            },
    }
  }

  render() {
    return(
      <div>
        <Chart options={this.state.options} series={this.state.series} type="line" height={150} />
      </div>
    )
  }
}

export default CustLine
