import React, { Component } from 'react'
import {
  withStyles,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import LostOppChart from './LostOppChart'
import Cookies from 'js-cookie'
import lostOppStandIn from '../../assets/homeStandIns/lostOppStandIn.png'
import AskReport from '../AskReport'

const styles = theme => ({
  root: {
    margin: '1em'
  },
  loader: {
    marginLeft: '45%',
    marginTop: '30%',
  },
  standIn: {
    width: '100%',
    [theme.breakpoints.up('xl')]: {
      marginTop: '-50px',
      height: '35%',
      width: '85%'
    },
    [theme.breakpoints.down('lg')]: {
      width: '120%',
      marginTop: '-35px'
    }
  },
 unavailable: {
    height: '400px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})

class LostOppMod extends Component {
  _isMounted = false

  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      lostOppData: [],
      hasData: false,
    }
  }

  componentDidMount = () => {
    this._isMounted = true

    fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomLostOpp`, {
      headers: {
        token : Cookies.get('token')
      }
    })
    .then(response => response.json())
    .then(data => {
      if(this._isMounted && data) {
        this.setState({
          isLoading: false,
          hasData: true,
          lostOppData: data,
        })
      } else {
        this.setState({
          isLoading: false,
          hasData: false,
          lostOppData: []
        })
      }
    })
    .catch(error => {
      console.log(error)
    })
  }

  render() {
    const {classes} = this.props
    const dbO = JSON.parse(localStorage.getItem("currentUser")) ? JSON.parse(localStorage.getItem("currentUser"))[0].db_owner : ""

		const decileOne = this.state.lostOppData.map(item => item.Data.filter(item => item.DecileRange === '0 - 10')[0])
		const decileTwo = this.state.lostOppData.map(item => item.Data.filter(item => item.DecileRange === '10 - 20')[0])
		const decileThree = this.state.lostOppData.map(item => item.Data.filter(item => item.DecileRange === '20 - 30')[0])
		const decileFour = this.state.lostOppData.map(item => item.Data.filter(item => item.DecileRange === '30 - 40')[0])
		const decileFive = this.state.lostOppData.map(item => item.Data.filter(item => item.DecileRange === '40 - 50')[0])
		const decileSix = this.state.lostOppData.map(item => item.Data.filter(item => item.DecileRange === '50 - 60')[0])
		const decileSeven = this.state.lostOppData.map(item => item.Data.filter(item => item.DecileRange === '60 - 70')[0])
		const decileEight = this.state.lostOppData.map(item => item.Data.filter(item => item.DecileRange === '70 - 80')[0])
		const decileNine = this.state.lostOppData.map(item => item.Data.filter(item => item.DecileRange === '80 - 90')[0])
		const decileTen = this.state.lostOppData.map(item => item.Data.filter(item => item.DecileRange === '90 - 100')[0])

    const lostOppSeries = [
			{
				name: `Decile: ${decileOne.map(item => item.DecileID)[0]}`,
				data: decileOne.map(item => item.LostOpp)
			},
			{
				name: `Decile: ${decileTwo.map(item => item.DecileID)[0]}`,
				data: decileTwo.map(item => item.LostOpp)
			},
			{
				name: `Decile: ${decileThree.map(item => item.DecileID)[0]}`,
				data: decileThree.map(item => item.LostOpp)
			},
			{
				name: `Decile: ${decileFour.map(item => item.DecileID)[0]}`,
				data: decileFour.map(item => item.LostOpp)
			},
			{
				name: `Decile: ${decileFive.map(item => item.DecileID)[0]}`,
				data: decileFive.map(item => item.LostOpp)
			},
			{
				name: `Decile: ${decileSix.map(item => item.DecileID)[0]}`,
				data: decileSix.map(item => item.LostOpp)
			},
			{
				name: `Decile: ${decileSeven.map(item => item.DecileID)[0]}`,
				data: decileSeven.map(item => item.LostOpp)
			},
			{
				name: `Decile: ${decileEight.map(item => item.DecileID)[0]}`,
				data: decileEight.map(item => item.LostOpp)
			},
			{
				name: `Decile: ${decileNine.map(item => item.DecileID)[0]}`,
				data: decileNine.map(item => item.LostOpp)
			},
			{
				name: `Decile: ${decileTen.map(item => item.DecileID)[0]}`,
				data: decileTen.map(item => item.LostOpp)
			},
		]

    const totals = [
			{
				decID: decileOne.map(item => item.DecileID)[0],
				lostOpp: decileOne.map(item => item.LostOpp),
				decRange: decileOne.map(item => item.DecileRange)[0],
				cpp: decileOne.map(item => item.CPP)[0],
				roi: decileOne.map(item => item.ROI)[0]
			},
			{
				decID: decileTwo.map(item => item.DecileID)[0],
				lostOpp: decileTwo.map(item => item.LostOpp),
				decRange: decileTwo.map(item => item.DecileRange)[0],
				cpp: decileTwo.map(item => item.CPP)[0],
				roi: decileTwo.map(item => item.ROI)[0]
			},
			{
				decID: decileThree.map(item => item.DecileID)[0],
				lostOpp: decileThree.map(item => item.LostOpp),
				decRange: decileThree.map(item => item.DecileRange)[0],
				cpp: decileThree.map(item => item.CPP)[0],
				roi: decileThree.map(item => item.ROI)[0]
			},
			{
				decID: decileFour.map(item => item.DecileID)[0],
				lostOpp: decileFour.map(item => item.LostOpp),
				decRange: decileFour.map(item => item.DecileRange)[0],
				cpp: decileFour.map(item => item.CPP)[0],
				roi: decileFour.map(item => item.ROI)[0]
			},
			{
				decID: decileFive.map(item => item.DecileID)[0],
				lostOpp: decileFive.map(item => item.LostOpp),
				decRange: decileFive.map(item => item.DecileRange)[0],
				cpp: decileFive.map(item => item.CPP)[0],
				roi: decileFive.map(item => item.ROI)[0]
			},
			{
				decID: decileSix.map(item => item.DecileID)[0],
				lostOpp: decileSix.map(item => item.LostOpp),
				decRange: decileSix.map(item => item.DecileRange)[0],
				cpp: decileSix.map(item => item.CPP)[0],
				roi: decileSix.map(item => item.ROI)[0]
			},
			{
				decID: decileSeven.map(item => item.DecileID)[0],
				lostOpp: decileSeven.map(item => item.LostOpp),
				decRange: decileSeven.map(item => item.DecileRange)[0],
				cpp: decileSeven.map(item => item.CPP)[0],
				roi: decileSeven.map(item => item.ROI)[0]
			},
			{
				decID: decileEight.map(item => item.DecileID)[0],
				lostOpp: decileEight.map(item => item.LostOpp),
				decRange: decileEight.map(item => item.DecileRange)[0],
				cpp: decileEight.map(item => item.CPP)[0],
				roi: decileEight.map(item => item.ROI)[0]
			},
			{
				decID: decileNine.map(item => item.DecileID)[0],
				lostOpp: decileNine.map(item => item.LostOpp),
				decRange: decileNine.map(item => item.DecileRange)[0],
				cpp: decileNine.map(item => item.CPP)[0],
				roi: decileNine.map(item => item.ROI)[0]
			},
			{
				decID: decileTen.map(item => item.DecileID)[0],
				lostOpp: decileTen.map(item => item.LostOpp),
				decRange: decileTen.map(item => item.DecileRange)[0],
				cpp: decileTen.map(item => item.CPP)[0],
				roi: decileTen.map(item => item.ROI)[0]
			},
		]

    const colors = [
      '#0084f5',
      '#43abfa',
      '#7cc3fa',
      '#aad6f9',
      '#fccfeb',
      '#fab5df',
      '#f9a3d6',
      '#7cfee0',
      '#5beeca',
      '#00cc9e',
    ]

    const totalIncSales = totals.map(item => item.lostOpp.reduce((a,b) => a + b, 0) * item.cpp * item.roi)

    return(
      <div>
        {this.state.isLoading ? <CircularProgress className={classes.loader}/> :
          this.state.hasData && dbO !== 'ESCANABA' ?
            <div className={classes.root}>
              <div style={{textAlign: 'center'}}>
                <Typography variant="caption">
                  The total lost opportunity so far is
                  <span style={{fontSize: '15px', color: 'rgba(255, 0, 0, 0.7)'}}>{' $' + Math.round(totalIncSales.reduce((a,b) => a + b, 0)).toLocaleString('en')}</span>
                </Typography>
              </div>
              <br/>
              <div>
                <div style={{textAlign: 'center'}}>
                  <Typography variant="caption" style={{fontWeight: 'bold'}}>Lost Opp Per Decile</Typography>
                </div>
                <LostOppChart
                  height={200}
                  series={lostOppSeries}
                  categories={this.state.lostOppData.map(item => item.YearMo)}
                  colors={colors}
                  maxLostArr={this.state.lostOppData.map(item => item.Data.map(item => item.LostOpp).reduce((a,b) => a + b, 0))}
                  maxMailedArr={this.state.lostOppData.map(item => item.Data.map(item => item.Mailed).reduce((a,b) => a + b, 0))}
                  position="bottom"
                  fixed={false}
                  legend={false}
                  style={{border: '1px solid'}}
                />
              </div>
            </div>
          : 
          <div className={classes.unavailable}>
              <AskReport home={true} />
            </div>
        }
      </div>
    )
  }
}

export default withStyles(styles)(LostOppMod)
