import React, { Component } from 'react'
import clsx from 'clsx'
import {
  withStyles,
  Typography,
  Link,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TablePagination,
  MenuItem,
  Drawer,
  IconButton,
  Divider,
  TextField,
  Select,
  Tabs,
  Tab,
  Box,
  ButtonBase,
  Modal,
  Backdrop,
  Fade,
  Button,
} from '@material-ui/core'
import BarChart from '../charts/BarChart'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import LyTentpole from './LyTentpoleROI'
import ComparePanel from './PromoPanel'
import RoiTable from './RoiTable'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import noImage from '../../assets/noImage.png'
import TentpoleRoiExcel from './TentpoleRoiExcel'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return(
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  )
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  }
}

const styles = theme => ({
  content: {
    // flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  // contentShift: {
  //   overflowX: 'scroll',
  //   transition: theme.transitions.create('margin', {
  //     easing: theme.transitions.easing.easeOut,
  //     duration: theme.transitions.duration.enteringScreen,
  //   }),
  //   marginRight: '50%',
  //   marginLeft: 5,
  // },
  secondDrawer: {
    width: '50%',
    flexShrink: 0,
  },
  secondDrawerPaper: {
    width: '50%',
  },
  secondDrawerHeader: {
    display: 'flex',
    alignItems: 'right',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  hide: {
    display: 'none'
  },
  chartContent: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    display: 'block',
    justifyContent: 'center',
    marginRight: '-30%'
  },
  // chartShift: {
  //   transition: theme.transitions.create('margin', {
  //     easing: theme.transitions.easing.easeOut,
  //     duration: theme.transitions.duration.enteringScreen,
  //   }),
  //   marginRight: '-30%',
  //   display: 'block',
  //   justifyContent: 'center'
  // },
  link: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  closeButton: {
    marginTop: '10px',
    marginLeft: '15px',
    '&:hover': {
      color: '#eb838c',
      backgroundColor: 'transparent',
      border: '1px solid #eb838c'
    }
  },
  select: {
    marginLeft: '10px',
    marginTop: '15px',
    fontSize: '13px',
    width: 150,
  },
  indicator: {
    backgroundColor: '#00bcd4'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalImg: {
    maxWidth: '95%',
    maxHeight: '95%'
  }
})

class CompareDrawer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rowsPerPage: 10,
      page: 0,
      selectedMetric: 'Market',
      lyDrawerOpen: false,
      dateExtended: props.dateExtended,
      tab: 0,
      modalOpen: false,
      modalImg: '',
      scrollY: props.yPosition
    }

    this.handleSecondaryOpen = this.handleSecondaryOpen.bind(this)
    this.handleSecondaryClose = this.handleSecondaryClose.bind(this)
    this.handleDrawerClose = this.handleDrawerClose.bind(this)
    this.handleModalOpen = this.handleModalOpen.bind(this)
    this.handleModalClose = this.handleModalClose.bind(this)
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    return (nextProps.yPosition !== prevState.scrollY) ?
    {
      scrollY: nextProps.yPosition
    } : null
    {console.log(this.state.scrollY)}
  }

  handleTabChange = (e, newTab) => {
    // console.log(this.props.tab)
    this.setState({
      tab: newTab
    })
  }

  handleChangePage = (e, newPage) => {
    this.setState({
      page: newPage
    })
  }

  handleChangeRowsPerPage = e => {
    this.setState({
      rowsPerPage: parseInt(e.target.value, 10),
      page: 0
    })
  }

  renderSelectOptions = () => {
    return (
      <div>
        <MenuItem>Market</MenuItem>
        <MenuItem>Location</MenuItem>
      </div>
    )
  }

  handleDrawerClose(e) {
    this.props.handleDrawerClose(e)
  }

  handleSecondaryOpen() {
    this.setState({
      lyDrawerOpen: true,
    })
  }

  handleSecondaryClose() {
    this.setState({
      lyDrawerOpen: false,
    })
  }

  handleSelectChange = (e) => {
    // console.log(e.target.value)
    // if(e.target.value === 'Market') {
    //   console.log(e.target.value)
    // } else {
    //   console.log(e.target.value)
    // }
  }

  handleDateExtendedChange = e => {
    console.log(e.target.value)
    // this.setState({
    //   dateExtended: e.target.value
    // })
  }

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   return nextProps.tab !== prevState.tab ?
  //     console.log(nextProps.tab)
  //     : null
  // }

  handleModalOpen = e => {
    this.setState({
      modalOpen: true,
      modalImg: e.target.src
    })
  }

  handleModalClose = () => {
    this.setState({
      modalOpen: false,
      modalImg: ''
    })
  }

  render() {
    const { classes } = this.props
    return(
      <div id="compareROI">
        <main
          className={clsx(classes.content, {[classes.contentShift]: this.state.lyDrawerOpen,})}
        >
          <div>
            <Modal
              className={classes.modal}
              open={this.state.modalOpen}
              onClose={this.handleModalClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout:500
              }}
            >
              <Fade in={this.state.modalOpen}>
                <img className={classes.modalImg} src={this.state.modalImg} alt="" />
              </Fade>
            </Modal>

            <Button variant="outlined" disableRipple size="small" onClick={this.handleDrawerClose} className={classes.closeButton}>
              <ArrowBackIcon fontSize="small" />
              <Typography style={{marginLeft: '10px'}} variant="caption">GO BACK</Typography>
            </Button>
            <div style={{marginLeft: '7px'}}>
              <TentpoleRoiExcel
                promoName={this.props.data[0].Promotion}
                selectedType={'location'}
                markets={'All'}
                selectedRfm={'All'}
                selectedTestGroups={'All'}
                format={this.props.data[0].Format_Desc}
                roiData={this.props.data[0][`Data_${this.props.dateExtended.split(' ')[0]}`]}
                locationData={this.props.locationData}
                rfmData={this.props.rfmData}
                testData={this.props.testData}
                dateExtended={this.state.dateExtended}
              />
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              {/* <Select
                disabled
                value={this.props.dateExtended}
                className={classes.select}
              >
                <MenuItem value={'Extended Date'}>{this.props.dateExtended}</MenuItem>
                <MenuItem value={'Promo Date'}>{this.props.dateExtended}</MenuItem>
              </Select> */}
              {/* <br/> */}
              <Typography
                style={{fontSize: '13px', textAlign: 'right', marginRight: '15px'}}
                className={clsx(this.state.lyDrawerOpen && classes.hide)}
              >
              </Typography>
            </div>
          </div>
          <Divider style={{marginTop: '20px'}} />
          <Table
            className={classes.table}
            size="small"
          >
            <TableHead>
              <TableRow style={{height: '60px'}}>
                <TableCell style={{fontWeight: 'bold'}}>PROMOTION</TableCell>
                <TableCell style={{fontWeight: 'bold'}} align="right">REPORT START</TableCell>
                <TableCell style={{fontWeight: 'bold'}} align="right">REPORT END</TableCell>
                <TableCell style={{fontWeight: 'bold'}} align="right">FORMAT</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow style={{height: '55px'}}>
                {/* {console.log(this.props)} */}
                <TableCell>{this.props.data[0].Promotion}</TableCell>
                <TableCell align="right">
                  {this.props.dateExtended === 'Extended Date'
                    ? this.props.data[0].Data_Extended[0].PromoStartDate
                    : this.props.data[0].Data_Promo[0].PromoStartDate
                  }
                </TableCell>
                <TableCell align="right">
                  {this.props.dateExtended === 'Extended Date'
                    ? this.props.data[0].Data_Extended[0].PromoEndDate
                    : this.props.data[0].Data_Promo[0].PromoEndDate
                  }
                </TableCell>
                <TableCell align="right">
                  {this.props.data[0].Format_Desc}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <Table
            className={classes.table}
            size="small"
          >
            <TableHead>
              <TableRow>
                <TableCell style={{fontWeight: 'bold', fontSize: '10px'}}>Status</TableCell>
                {/* <TableCell style={{fontWeight: 'bold', fontSize: '10px', color: '#cc0000'}} align="right">Spend</TableCell> */}
                <TableCell style={{fontWeight: 'bold', fontSize: '10px', color: '#b045ff'}} align="right">Mailed</TableCell>
                <TableCell style={{fontWeight: 'bold', fontSize: '10px', color: '#006cc4'}} align="right">Responses</TableCell>
                <TableCell style={{fontWeight: 'bold', fontSize: '10px', color: '#529c00'}} align="right">Sales</TableCell>
                <TableCell style={{fontWeight: 'bold', fontSize: '10px', color: '#6ec6cc'}} align="right">Response Rate</TableCell>
                <TableCell style={{fontWeight: 'bold', fontSize: '10px', color: '#f27272'}} align="right">DM CoA(%)</TableCell>
                {/* <TableCell className={this.state.lyDrawerOpen ? classes.hide : ''} style={{fontWeight: 'bold', fontSize: '10px', color: '#f27272'}} align="right">Cost Per Thousand</TableCell> */}
                {/* <TableCell className={this.state.lyDrawerOpen ? classes.hide : ''} style={{fontWeight: 'bold', fontSize: '10px', color: '#f27272'}} align="right">Total CoA(%)</TableCell> */}
                {/* <TableCell className={this.state.lyDrawerOpen ? classes.hide : ''} style={{fontWeight: 'bold', fontSize: '10px', color: '#deb40d'}} align="right">Average Ticket</TableCell> */}
                {/* <TableCell style={{fontWeight: 'bold', fontSize: '10px', color: '#8ebf56'}} align="right">Total Sales</TableCell> */}
                <TableCell style={{fontWeight: 'bold', fontSize: '10px', color: '#8ebf56'}} align="right">% of Total Sales</TableCell>
                <TableCell style={{fontWeight: 'bold', fontSize: '10px', color: '#de7c0d'}} align="right">Contribution Margin ROI</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow style={this.props.darkMode ? {background: 'rgba(0, 0, 0, 0.1)'} : {background: 'rgba(199, 199, 199, 0.2)'}}>
                <TableCell name="customerLabel" style={{fontSize: '12px'}}>Mailed</TableCell>
                {/* <TableCell name="cost" style={{fontSize: '12px'}} align="right">{'$' + Math.round(this.props.data[0].Data_Extended[0].Cost).toLocaleString('en')}</TableCell> */}
                <TableCell name="mailed" style={{fontSize: '12px'}} align="right">
                  {this.props.dateExtended === 'Extended Date'
                    ? (this.props.data[0].Data_Extended[0].Mailed).toLocaleString('en')
                    : (this.props.data[0].Data_Promo[0].Mailed).toLocaleString('en')
                  }
                </TableCell>
                <TableCell name="responses" style={{fontSize: '12px'}} align="right">
                  {this.props.dateExtended === 'Extended Date'
                    ? (this.props.data[0].Data_Extended[0].Responses).toLocaleString('en')
                    : (this.props.data[0].Data_Promo[0].Responses).toLocaleString('en')
                  }
                </TableCell>
                <TableCell name="sales" style={{fontSize: '12px'}} align="right">
                  {this.props.dateExtended === 'Extended Date'
                    ? '$' + Math.round(this.props.data[0].Data_Extended[0].Sales).toLocaleString('en')
                    : '$' + Math.round(this.props.data[0].Data_Promo[0].Sales).toLocaleString('en')
                  }
                </TableCell>
                <TableCell name="responseRate" style={{fontSize: '12px'}} align="right">
                  {this.props.dateExtended === 'Extended Date'
                    ? (this.props.data[0].Data_Extended[0].Res_Rate * 100).toFixed(2) + '%'
                    : (this.props.data[0].Data_Promo[0].Res_Rate * 100).toFixed(2) + '%'
                  }
                </TableCell>
                <TableCell name="dMCoA" style={{fontSize: '12px'}} align="right">
                  {this.props.dateExtended === 'Extended Date'
                    ? (this.props.data[0].Data_Extended[0].Cost / this.props.data[0].Data_Extended[0].Sales * 100).toFixed(2) + '%'
                    : (this.props.data[0].Data_Promo[0].Cost / this.props.data[0].Data_Promo[0].Sales * 100).toFixed(2) + '%'
                  }
                </TableCell>
                {/* <TableCell className={this.state.lyDrawerOpen ? classes.hide : ''} name="costPerThousand" style={{fontSize: '12px'}} align="right">
                  {
                    '$' + Math.round(this.props.data[0].Data_Extended[0].Cost / this.props.data[0].Data_Extended[0].Mailed * 1000)
                  }
                </TableCell> */}
                {/* <TableCell className={this.state.lyDrawerOpen ? classes.hide : ''} name="totalCoA" style={{fontSize: '12px'}} align="right">
                  {
                    (this.props.data[0].Data_Extended[0].Cost / this.props.data[0].Data_Extended[0].Total_Sales * 100).toFixed(2) + '%'
                  }
                </TableCell> */}
                {/* <TableCell className={this.state.lyDrawerOpen ? classes.hide : ''} name="avgTicket" style={{fontSize: '12px'}} align="right">
                  {
                    '$' + Math.round(this.props.data[0].Data_Extended[0].Sales / this.props.data[0].Data_Extended[0].Responses).toLocaleString('en')
                  }
                </TableCell> */}
                {/* <TableCell name="totalSales" style={{fontSize: '12px'}} align="right">{'$' + Math.round(this.props.data[0].Data_Extended[0].Total_Sales).toLocaleString('en')}</TableCell> */}
                <TableCell name="pctTotalSales" style={{fontSize: '12px'}} align="right">
                  {this.props.dateExtended === 'Extended Date'
                    ? (this.props.data[0].Data_Extended[0].Sales / this.props.data[0].Data_Extended[0].Total_Sales * 100).toFixed(2) + '%'
                    : (this.props.data[0].Data_Promo[0].Sales / this.props.data[0].Data_Promo[0].Total_Sales * 100).toFixed(2) + '%'
                  }
                </TableCell>
                <TableCell name="contributionRoi" style={{fontSize: '12px'}} align="right">N/A</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{fontSize: '12px'}}>Holdout</TableCell>
                {/* <TableCell name="holdoutCOG" style={{fontSize: '12px'}} align="right">{'$'+ 0}</TableCell> */}
                <TableCell name="holdoutCount" style={{fontSize: '12px'}} align="right">
                  {this.props.dateExtended === 'Extended Date'
                    ? (this.props.data[0].Data_Extended[0].HO_Count).toLocaleString('en')
                    : (this.props.data[0].Data_Promo[0].HO_Count).toLocaleString('en')
                  }
                </TableCell>
                <TableCell name="holdoutResponse" style={{fontSize: '12px'}} align="right">
                  {this.props.dateExtended === 'Extended Date'
                    ? (this.props.data[0].Data_Extended[0].HO_Resp).toLocaleString('en')
                    : (this.props.data[0].Data_Promo[0].HO_Resp).toLocaleString('en')
                  }
                </TableCell>
                <TableCell name="holdoutSales" style={{fontSize: '12px'}} align="right">
                  {this.props.dateExtended === 'Extended Date'
                    ? '$' + Math.round(this.props.data[0].Data_Extended[0].HO_Sales).toLocaleString('en')
                    : '$' + Math.round(this.props.data[0].Data_Promo[0].HO_Sales).toLocaleString('en')
                  }
                </TableCell>
                <TableCell name="holdoutRR" style={{fontSize: '12px'}} align="right">
                  {this.props.dateExtended === 'Extended Date'
                    ? (this.props.data[0].Data_Extended[0].HO_Res_Rate * 100).toFixed(2) + '%'
                    : (this.props.data[0].Data_Promo[0].HO_Res_Rate * 100).toFixed(2) + '%'
                  }
                </TableCell>
                <TableCell name="holdoutDMCoA" style={{fontSize: '12px'}} align="right">{null}</TableCell>
                {/* <TableCell className={this.state.lyDrawerOpen ? classes.hide : ''} name="holdoutCostPerThou" style={{fontSize: '12px'}} align="right">{null}</TableCell> */}
                {/* <TableCell className={this.state.lyDrawerOpen ? classes.hide : ''} name="holdoutTotalCoA" style={{fontSize: '12px'}} align="right">{null}</TableCell> */}
                {/* <TableCell className={this.state.lyDrawerOpen ? classes.hide : ''} name="holdoutAvgTicket" style={{fontSize: '12px'}} align="right">{null}</TableCell> */}
                {/* <TableCell name="holdoutTotalSales" style={{fontSize: '12px'}} align="right">{'$' + Math.round(this.props.data[0].Data_Extended[0].Total_Sales).toLocaleString('en')}</TableCell> */}
                <TableCell name="holdoutpctTotalSales" style={{fontSize: '12px'}} align="right">
                  {this.props.dateExtended === 'Extended Date'
                    ? (this.props.data[0].Data_Extended[0].HO_Sales / this.props.data[0].Data_Extended[0].Total_Sales * 100).toFixed(2) + '%'
                    : (this.props.data[0].Data_Promo[0].HO_Sales / this.props.data[0].Data_Promo[0].Total_Sales * 100).toFixed(2) + '%'
                  }
                </TableCell>
                <TableCell name="holdoutContributionRoi" style={{fontSize: '12px'}} align="right">N/A</TableCell>
              </TableRow>
              <TableRow style={this.props.darkMode ? {background: 'rgba(0, 0, 0, 0.1)'} : {background: 'rgba(199, 199, 199, 0.2)'}}>
                <TableCell style={{fontSize: '12px'}}>Adjusted</TableCell>
                {/* <TableCell name="AdjCOG" style={{fontSize: '12px'}} align="right">{null}</TableCell> */}
                <TableCell name="AdjCount" style={{fontSize: '12px'}} align="right">
                  {this.state.dateExtended === 'Extended Date'
                    ? (this.props.data[0].Data_Extended[0].Mailed).toLocaleString('en')
                    : (this.props.data[0].Data_Promo[0].Mailed).toLocaleString('en')
                  }
                </TableCell>
                <TableCell name="AdjResponse" style={{fontSize: '12px'}} align="right">
                  {this.props.dateExtended === 'Extended Date'
                    ? (this.props.data[0].Data_Extended[0].ADJ_Resp).toLocaleString('en')
                    : (this.props.data[0].Data_Promo[0].ADJ_Resp).toLocaleString('en')
                  }
                </TableCell>
                <TableCell name="AdjSales" style={{fontSize: '12px'}} align="right">
                  {this.props.dateExtended === 'Extended Date'
                    ? '$' + Math.round(this.props.data[0].Data_Extended[0].ADJ_Sales).toLocaleString('en')
                    : '$' + Math.round(this.props.data[0].Data_Promo[0].ADJ_Sales).toLocaleString('en')
                  }
                </TableCell>
                <TableCell name="AdjRR" style={{fontSize: '12px'}} align="right">
                  {this.props.dateExtended === 'Extended Date'
                    ? (this.props.data[0].Data_Extended[0].ADJ_Resp / this.props.data[0].Data_Extended[0].Mailed * 100).toFixed(2) + '%'
                    : (this.props.data[0].Data_Promo[0].ADJ_Resp / this.props.data[0].Data_Promo[0].Mailed * 100).toFixed(2) + '%'
                  }
                </TableCell>
                <TableCell name="AdjDMCoA" style={{fontSize: '12px'}} align="right">                
                  {this.state.dateExtended === 'Extended Date'
                    ? (this.props.data[0].Data_Extended[0].Cost / this.props.data[0].Data_Extended[0].ADJ_Sales * 100).toFixed(2) + "%"
                    : (this.props.data[0].Data_Promo[0].Cost / this.props.data[0].Data_Promo[0].ADJ_Sales * 100).toFixed(2) + "%"
                  }
                </TableCell>
                {/* <TableCell className={this.state.lyDrawerOpen ? classes.hide : ''} name="AdjCostPerThou" style={{fontSize: '12px'}} align="right">{null}</TableCell> */}
                {/* <TableCell className={this.state.lyDrawerOpen ? classes.hide : ''} name="AdjTotalCoA" style={{fontSize: '12px'}} align="right">{null}</TableCell> */}
                {/* <TableCell className={this.state.lyDrawerOpen ? classes.hide : ''} name="AdjAvgTicket" style={{fontSize: '12px'}} align="right">{null}</TableCell> */}
                {/* <TableCell name="AdjTotalSales" style={{fontSize: '12px'}} align="right">{null}</TableCell> */}
                <TableCell className={this.state.lyDrawerOpen ? classes.hide : ''} name="AdjpctTotalSales" style={{fontSize: '12px'}} align="right">
                  {this.state.dateExtended
                    ? (this.props.data[0].Data_Extended[0].ADJ_Sales / this.props.data[0].Data_Extended[0].Total_Sales * 100).toFixed(2) + '%'
                    : (this.props.data[0].Data_Promo[0].ADJ_Sales / this.props.data[0].Data_Promo[0].Total_Sales * 100).toFixed(2) + '%'
                  }
                </TableCell>
                <TableCell name="AdjContributionRoi" style={{fontSize: '12px'}} align="right">N/A</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <br />
          <div
            className={classes.chartContent}
            style={{margin: '25px 25px 0px 25px'}}
          >
            <BarChart
              title={"Sales vs Total Sales by Market"}
              data={
                [
                  {
                    name: 'Sales',
                    data: this.props.marketData.map(item => Math.round(item.Data_Extended[0].Sales))
                  },
                  {
                    name: 'Total Sales',
                    data: this.props.marketData.map(item => Math.round(item.Data_Extended[0].Total_Sales))
                  },
                ]
              }
              labels={this.props.marketData.map(item => item.Label)}
              height={500}
              width={650}
              horizontal={true}
              legend={true}
              position={'bottom'}
              rotate={-45}
              distributed={false}
              darkMode={this.props.darkMode}
            />
            <div style={{textAlign: 'center'}}>
              <div style={{display: 'flex', marginTop: 60}}>
                <ButtonBase
                  onClick={this.handleModalOpen}
                  style={{height: 350, width: 350, overflow: 'hidden', border: '1px solid #626262', textAlign: 'center'}}
                >
                  {this.props.marketData[0].Creative.One ?
                    <img src={this.props.marketData[0].Creative.One} alt="" style={{height: 350}}/> :
                    <img src={noImage} alt="" style={{height: 350}}/>
                  }

                </ButtonBase>
                <div style={{marginLeft: '25px'}} />
                <ButtonBase
                  onClick={this.handleModalOpen}
                  style={
                    // this.props.userDemo === 'DEMO' ?
                    // {display: 'none'} :
                    {height: 350, width: 350, overflow: 'hidden', border: '1px solid #626262', textAlign: 'center'}
                  }
                >
                  {this.props.marketData[0].Creative.Two ?
                    <img src={this.props.marketData[0].Creative.Two} alt="" style={{height: 350}}/> :
                    <img src={noImage} alt="" style={{height: 350}}/>
                  }

                </ButtonBase>
              </div>
              <br />
              <Typography variant="caption" style={{display: 'flex', justifyContent: 'center'}}>
                <InfoOutlinedIcon fontSize="small" style={{marginRight: '5px', color: '#2697fb'}} /> Images may appear cropped. Click on the Creatives to view Full Image
              </Typography>
            </div>
          </div>
          <br />
          <br />

          {/* <TabGroup /> */}
          <Tabs
            variant="standard"
            textColor="secondary"
            classes={{indicator: classes.indicator}}
            value={this.props.tab}
            onChange={this.handleTabChange}
            style={{marginTop: '15px'}}
          >
            <Tab label="View ROI" {...a11yProps(0)} disableRipple />
            <Tab label="View ROI by RFM Segment" {...a11yProps(0)} disableRipple />
            <Tab label="View ROI by Test Group" {...a11yProps(0)} disableRipple />
          </Tabs>

          <TabPanel value={this.props.tab} index={0}>
            <RoiTable
              hide={true}
              data={this.props.locationData}
              dateExtended={this.state.dateExtended}
              tab={0}
              darkMode={this.props.darkMode}
            />
          </TabPanel>

          <TabPanel value={this.props.tab} index={1}>
            <RoiTable
              hide={true}
              data={this.props.rfmData}
              dateExtended={this.state.dateExtended}
              tab={1}
              darkMode={this.props.darkMode}
            />
          </TabPanel>

          <TabPanel value={this.props.tab} index={2}>
            <RoiTable
              hide={true}
              data={this.props.testData}
              dateExtended={this.state.dateExtended}
              tab={2}
              darkMode={this.props.darkMode}
            />
          </TabPanel>
        </main>
      </div>
    )
  }
}

export default withStyles(styles)(CompareDrawer)


// import React, { Component } from 'react'
// import {
//   withStyles,
//   Table,
//   TableBody,
//   TableHead,
//   TableRow,
//   TableCell,
//   IconButton,
//   Divider
// } from '@material-ui/core'
// import BarChart from '../charts/BarChart'
// import CloseIcon from '@material-ui/icons/Close'
//
// const styles = theme => ({
//   select: {
//     width: 150
//   },
//   root: {
//     marginRight: '25px'
//   }
// })
//
//
// class LyTentpole extends Component {
//   constructor(props) {
//     super(props)
//     this.state = {
//
//     }
//     // console.log(props)
//     this.handleSecondaryClose = this.handleSecondaryClose.bind(this)
//   }
//
//   handleSecondaryClose(e) {
//     this.props.handleDrawerClose(e)
//   }
//
//   render() {
//     const { classes } = this.props
//
//     return(
//       <div className={classes.root}>
//         <div>
//           <IconButton onClick={this.handleSecondaryClose}>
//             <CloseIcon />
//           </IconButton>
//         </div>
//         <Divider style={{marginTop: '20px'}} />
//         <Table
//           className={classes.table}
//           size="small"
//         >
//           <TableHead>
//             <TableRow>
//               <TableCell style={{fontWeight: 'bold'}}>PROMOTION</TableCell>
//               <TableCell style={{fontWeight: 'bold'}} align="right">REPORT START</TableCell>
//               <TableCell style={{fontWeight: 'bold'}} align="right">REPORT END</TableCell>
//               <TableCell style={{fontWeight: 'bold'}} align="right">FORMAT</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             <TableRow>
//               <TableCell name="promotionName" >Promotion Name</TableCell>
//               <TableCell name="startDate" align="right">xxxx-xx-xx</TableCell>
//               <TableCell name="endDate" align="right">xxxx-xx-xx</TableCell>
//               <TableCell name="format" align="right">Format Type</TableCell>
//             </TableRow>
//           </TableBody>
//         </Table>
//
//         <Table
//           className={classes.table}
//           size="small"
//         >
//           <TableHead>
//             <TableRow>
//               <TableCell style={{fontWeight: 'bold', fontSize: '10px'}}>Status</TableCell>
//               <TableCell style={{fontWeight: 'bold', fontSize: '10px', color: '#cc0000'}} align="right">Spend</TableCell>
//               <TableCell style={{fontWeight: 'bold', fontSize: '10px', color: '#b045ff'}} align="right">Mailed</TableCell>
//               <TableCell style={{fontWeight: 'bold', fontSize: '10px', color: '#006cc4'}} align="right">Responses</TableCell>
//               <TableCell style={{fontWeight: 'bold', fontSize: '10px', color: '#529c00'}} align="right">Sales</TableCell>
//               {/* <TableCell style={{fontWeight: 'bold', fontSize: '10px', color: '#6ec6cc'}} align="right">Response Rate</TableCell> */}
//               <TableCell style={{fontWeight: 'bold', fontSize: '10px', color: '#f27272'}} align="right">DM CoA(%)</TableCell>
//               {/* <TableCell style={{fontWeight: 'bold', fontSize: '10px', color: '#f27272'}} align="right">Cost Per Thousand</TableCell> */}
//               {/* <TableCell style={{fontWeight: 'bold', fontSize: '10px', color: '#f27272'}} align="right">Total CoA(%)</TableCell> */}
//               {/* <TableCell style={{fontWeight: 'bold', fontSize: '10px', color: '#deb40d'}} align="right">Average Ticket</TableCell> */}
//               <TableCell style={{fontWeight: 'bold', fontSize: '10px', color: '#8ebf56'}} align="right">Total Sales</TableCell>
//               {/* <TableCell style={{fontWeight: 'bold', fontSize: '10px', color: '#8ebf56'}} align="right">% of Total Sales</TableCell> */}
//               <TableCell style={{fontWeight: 'bold', fontSize: '10px', color: '#de7c0d'}} align="right">Contribution Margin ROI</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             <TableRow style={{backgroundColor: 'rgba(199, 199, 199, 0.2)'}}>
//               <TableCell name="customerLabel" style={{fontSize: '12px'}}>Customers</TableCell>
//               <TableCell name="cost" style={{fontSize: '12px'}} align="right">{null}</TableCell>
//               <TableCell name="mailed" style={{fontSize: '12px'}} align="right">{null}</TableCell>
//               <TableCell name="responses" style={{fontSize: '12px'}} align="right">{null}</TableCell>
//               <TableCell name="sales" style={{fontSize: '12px'}} align="right">{null}</TableCell>
//               {/* <TableCell name="responseRate" style={{fontSize: '12px'}} align="right">{null}</TableCell> */}
//               <TableCell name="dMCoA" style={{fontSize: '12px'}} align="right">{null}</TableCell>
//               {/* <TableCell name="costPerThousand" style={{fontSize: '12px'}} align="right">{null}</TableCell> */}
//               {/* <TableCell name="totalCoA" style={{fontSize: '12px'}} align="right">{null}</TableCell> */}
//               {/* <TableCell name="avgTicket" style={{fontSize: '12px'}} align="right">{null}</TableCell> */}
//               <TableCell name="totalSales" style={{fontSize: '12px'}} align="right">{null}</TableCell>
//               {/* <TableCell name="pctTotalSales" style={{fontSize: '12px'}} align="right">{null}</TableCell> */}
//               <TableCell name="contributionRoi" style={{fontSize: '12px'}} align="right">N/A</TableCell>
//             </TableRow>
//             <TableRow>
//               <TableCell style={{fontSize: '12px'}}>Holdout</TableCell>
//               <TableCell name="holdoutCOG" style={{fontSize: '12px'}} align="right">{null}</TableCell>
//               <TableCell name="holdoutCount" style={{fontSize: '12px'}} align="right">{null}</TableCell>
//               <TableCell name="holdoutResponse" style={{fontSize: '12px'}} align="right">{null}</TableCell>
//               <TableCell name="holdoutSales" style={{fontSize: '12px'}} align="right">{null}</TableCell>
//               {/* <TableCell name="holdoutRR" style={{fontSize: '12px'}} align="right">{null}</TableCell> */}
//               <TableCell name="holdoutDMCoA" style={{fontSize: '12px'}} align="right">{null}</TableCell>
//               {/* <TableCell name="holdoutCostPerThou" style={{fontSize: '12px'}} align="right">{null}</TableCell> */}
//               {/* <TableCell name="holdoutTotalCoA" style={{fontSize: '12px'}} align="right">{null}</TableCell> */}
//               {/* <TableCell name="holdoutAvgTicket" style={{fontSize: '12px'}} align="right">{null}</TableCell> */}
//               <TableCell name="holdoutTotalSales" style={{fontSize: '12px'}} align="right">{null}</TableCell>
//               {/* <TableCell name="holdoutpctTotalSales" style={{fontSize: '12px'}} align="right">{null}</TableCell> */}
//               <TableCell name="holdoutContributionRoi" style={{fontSize: '12px'}} align="right">N/A</TableCell>
//             </TableRow>
//           </TableBody>
//         </Table>
//         <div className={classes.chartContent} style={{margin: '25px 25px 0px 25px'}}>
//           <BarChart
//             data={
//               [
//                 {
//                   name: 'Sales',
//                   data: [1,2,3,4]
//                 },
//                 {
//                   name: 'Total Sales',
//                   data: [5,7,8,7]
//                 },
//               ]
//             }
//             labels={['a', 'b', 'c', 'd']}
//             legend={true}
//             height={300}
//             width={700}
//             horizontal={true}
//             rotate={-45}
//             distributed={false}
//           />
//           <div style={{display: 'flex'}}>
//             <img src="" alt="" style={{maxHeight: 250, maxWidth: 350}}/>
//             <div style={{marginLeft: '25px'}} />
//             <img src="" alt="" style={{maxHeight: 250, maxWidth: 350}}/>
//           </div>
//         </div>
//         <br />
//         <Table size="small">
//           <TableHead>
//             <TableRow>
//               <TableCell style={{fontWeight: 'bold', fontSize: '10px'}}>Location</TableCell>
//               <TableCell style={{fontWeight: 'bold', fontSize: '10px', color: '#cc0000'}} align="right">Spend</TableCell>
//               <TableCell style={{fontWeight: 'bold', fontSize: '10px', color: '#b045ff'}} align="right">Mailed</TableCell>
//               <TableCell style={{fontWeight: 'bold', fontSize: '10px', color: '#006cc4'}} align="right">Responses</TableCell>
//               <TableCell style={{fontWeight: 'bold', fontSize: '10px', color: '#529c00'}} align="right">Sales</TableCell>
//               {/* <TableCell style={{fontWeight: 'bold', fontSize: '10px', color: '#6ec6cc'}} align="right">Response Rate</TableCell> */}
//               <TableCell style={{fontWeight: 'bold', fontSize: '10px', color: '#f27272'}} align="right">DM CoA(%)</TableCell>
//               {/* <TableCell style={{fontWeight: 'bold', fontSize: '10px', color: '#f27272'}} align="right">Cost Per Thousand</TableCell> */}
//               {/* <TableCell style={{fontWeight: 'bold', fontSize: '10px', color: '#f27272'}} align="right">Total CoA(%)</TableCell> */}
//               {/* <TableCell style={{fontWeight: 'bold', fontSize: '10px', color: '#deb40d'}} align="right">Average Ticket</TableCell> */}
//               <TableCell style={{fontWeight: 'bold', fontSize: '10px', color: '#8ebf56'}} align="right">Total Sales</TableCell>
//               {/* <TableCell style={{fontWeight: 'bold', fontSize: '10px', color: '#8ebf56'}} align="right">% of Total Sales</TableCell> */}
//               <TableCell style={{fontWeight: 'bold', fontSize: '10px', color: '#de7c0d'}} align="right">Contribution Margin ROI</TableCell>
//             </TableRow>
//           </TableHead>
//           {/* <TableBody>
//             {this.props.locationData
//               .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
//               .map(row => (
//               <TableRow key={row.Key}>
//                 <TableCell name="customerLabelByLoc" style={{fontSize: '12px'}}>{row.Location}</TableCell>
//                 <TableCell name="costByLoc" style={{fontSize: '12px'}} align="right">{'$' + Math.round(row.Data_Extended[0].Cost).toLocaleString('en')}</TableCell>
//                 <TableCell name="mailedByLoc" style={{fontSize: '12px'}} align="right">{(row.Data_Extended[0].Mailed).toLocaleString('en')}</TableCell>
//                 <TableCell name="responsesByLoc" style={{fontSize: '12px'}} align="right">{(row.Data_Extended[0].Responses).toLocaleString('en')}</TableCell>
//                 <TableCell name="salesByLoc" style={{fontSize: '12px'}} align="right">{'$' + Math.round(row.Data_Extended[0].Sales).toLocaleString('en')}</TableCell>
//                 <TableCell name="responseRateByLoc" style={{fontSize: '12px'}} align="right">{(row.Data_Extended[0].Res_Rate * 100).toFixed(2) + '%'}</TableCell>
//                 <TableCell name="dMCoAByLoc" style={{fontSize: '12px'}} align="right">{row.Data_Extended[0].Sales > 0 ? (row.Data_Extended[0].Cost / row.Data_Extended[0].Sales * 100).toFixed(2) + '%' : null}</TableCell>
//                 <TableCell name="costPerThousandByLoc" style={{fontSize: '12px'}} align="right">{'$' + Math.round(row.Data_Extended[0].Cost/ row.Data_Extended[0].Mailed * 1000).toLocaleString('en')}</TableCell>
//                 <TableCell name="totalCoAByLoc" style={{fontSize: '12px'}} align="right">{row.Data_Extended[0].Total_Sales ? (row.Data_Extended[0].Cost / row.Data_Extended[0].Total_Sales * 100).toFixed(2) + '%' : null}</TableCell>
//                 <TableCell name="avgTicketByLoc" style={{fontSize: '12px'}} align="right">{row.Data_Extended[0].Responses ? '$' + Math.round(row.Data_Extended[0].Sales / row.Data_Extended[0].Responses).toLocaleString('en') : null}</TableCell>
//                 <TableCell name="totalSalesByLoc" style={{fontSize: '12px'}} align="right">{'$' + Math.round(row.Data_Extended[0].Total_Sales).toLocaleString('en')}</TableCell>
//                 <TableCell name="pctTotalSalesByLoc" style={{fontSize: '12px'}} align="right">{row.Data_Extended[0].Total_Sales ? (row.Data_Extended[0].Cost / row.Data_Extended[0].Total_Sales * 100).toFixed(2) + '%' : null}</TableCell>
//                 <TableCell name="contributionRoiByLoc" style={{fontSize: '12px'}} align="right">N/A</TableCell>
//               </TableRow>
//             ))}
//           </TableBody> */}
//         </Table>
//         {/* <TablePagination
//           rowsPerPageOptions={[5, 10, 20]}
//           component="div"
//           count={32}
//           rowsPerPage={10}
//           page={0}
//           onChangePage={this.handleChangePage}
//           onChangeRowsPerPage={this.handleChangeRowsPerPage}
//         /> */}
//       </div>
//     )
//   }
// }
//
// export default withStyles(styles)(LyTentpole)
