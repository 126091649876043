import React, { Component } from 'react'
import Chart from 'react-apexcharts'

class CprLine extends Component {
  constructor(props) {
    super(props)
    // console.log(this.props.popSeriesName)
    console.log(props.series)
    this.state = {
      series: this.props.series,
      // series: [
      //   {
      //     name: props.popSeriesName,
      //     data: [28,29,33,36,32]
      //   },
      //   {
      //     name: '2',
      //     data: [12,11,14,18,17]
      //   }
      // ],
      options: {
        chart: {
          type: 'line',
          toolbar: {
            show: false,
          }
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth'
        },
        // colors: this.props.colors,
        xaxis: {
          title: {
            text: this.props.xTitle,
            style: {
              color: props.darkMode ? '#fff' : ''
            }
          },
          categories: this.props.categories,
          labels: {
            style: {
              colors: props.darkMode ? '#fff' : ''
            }
          }
        },
        yaxis: {
          title: {
            text: this.props.yTitle,
            style: {
              color: props.darkMode ? '#fff' : ''
            }
          },
          labels: {
            formatter: value => {
              if(value <= 999) {
                return value.toLocaleString('en', {maximumFractionDigits: 1})
              } else if (value > 999 && value <= 999999 ) {
                return Math.round(value / 1000) + 'K'
              } else if (value > 999999 && value <= 999999999) {
                return Math.round(value / 1000000) + 'M'
              } else {
                return Math.round(value / 1000000000) + 'B'
              }
            },
            style: {
              colors: props.darkMode ? '#fff' : ''
            }
          },
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'left',
          labels: {
            colors: this.props.darkMode ? '#fff' : ''
          }
        },
        tooltip: {
          y: {
            formatter: (value) => {
              if(this.props.yTitle.includes('Income')) {
                return '$' + value.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})
              } else if(this.props.yTitle.includes('Age')) {
                return value.toFixed(1)
              } else {
                return Math.round(value)
              }
            }
          }
        }
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return nextProps.series !== prevState.series ?
    {
      series: nextProps.series
    }
    : null
  }

  render() {
    return(
      <div>
        {/* {console.log(this.state.series)} */}
        {/* {console.log(this.props.series)} */}
        <Chart options={this.state.options} series={this.state.series} type="line" height={this.props.height}/>
      </div>
    )
  }
}

export default CprLine
