import React, {Component} from 'react'
import {
  withStyles,
  Typography,
  SvgIcon,
  CircularProgress,
  Select,
  MenuItem,
  Paper,
  InputLabel,
  ListItem,
  ListItemText,
  ListItemIcon,
  List,
  FormControl,
  Card,
  Checkbox,
  Slider,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core'
import Cookies from 'js-cookie'
import BarChart from '../charts/BarChart'
import LtvBarChart from './LtvBarChart'
import MonthPicker from '../calendar/MonthPicker'

import moment from 'moment'

const styles = theme => ({
  pageTitle: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '17px',
    fontWeight: 'light',
    color: 'rgba(115, 115, 115, 0.9)'
  },
  pageTitleDark: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '17px',
    fontWeight: 'light',
    color: 'rgba(255, 255, 255, 0.9)'
  },
  pageIcon: {
    fontSize: '15px',
    marginTop: '21px',
    marginRight: '2px',
  },
  pageIconDark: {
    fontSize: '15px',
    color: 'rgba(255, 255, 255, 0.9)',
    marginTop: '5px',
    marginRight: '2px',
  },
  formControl: {
    margin: theme.spacing(1),
    width: 250,
  },
  card: {
    // margin: theme.spacing(1),
    padding: theme.spacing(2),
    height: 700,
    width: '50%'
  },
  ltvCard: {
    width: '33%'
  },
  list: {
    margin: theme.spacing(1),
    border: '1px solid #c4c4c4',
    borderRadius: 5,
    // width: 250,
    height: 275,
    overflowY: 'auto'
  },
  tableContainer: {
    width: '100%',
  },
  datePicker: {
    height: 60,
    border: '1px solid #c4c4c4',
    borderRadius: 5,
    width: '94%',
    paddingTop: '15px',
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  listLabel: {
    margin: theme.spacing(1),
    color: '#757575',
    marginLeft: '17px',
    padding: '0px 5px 0px 5px',
    // backgroundColor: '#fff',
    marginTop: '20px'
  },
  listLabelDark: {
    margin: theme.spacing(1),
    color: '#fff',
    marginLeft: '17px',
    padding: '0px 5px 0px 5px',
    // backgroundColor: '#3a3a3a',
    marginTop: '20px'
  },
//   slider: {
//     border: '1px solid #c4c4c4',
//     borderRadius: 5,
//     width: '95%',
//     margin: theme.spacing(1),
//     padding: theme.spacing(1),
//   },
//   rfmCard: {
//     width: '100%',
//     height: 250,
//   }
})

function LtvIcon(props) {
  return(
    <SvgIcon {...props}>
      <path fill="currentColor" d="M12 2C13.66 2 15 3.34 15 5S13.66 8 12 8 9 6.66 9 5 10.34 2 12 2M20 18L18 12.56C17.65 11.57 17.34 10.71 16 10C14.63 9.3 13.63 9 12 9C10.39 9 9.39 9.3 8 10C6.68 10.71 6.37 11.57 6 12.56L4 18C3.77 19.13 6.38 20.44 8.13 21.19C9.34 21.72 10.64 22 12 22C13.38 22 14.67 21.72 15.89 21.19C17.64 20.44 20.25 19.13 20 18M15.42 17.5L12 21L8.58 17.5C8.22 17.12 8 16.61 8 16.05C8 14.92 8.9 14 10 14C10.55 14 11.06 14.23 11.42 14.61L12 15.2L12.58 14.6C12.94 14.23 13.45 14 14 14C15.11 14 16 14.92 16 16.05C16 16.61 15.78 17.13 15.42 17.5Z" />
    </SvgIcon>
  )
}

class LtvDemo extends Component {
  _isMounted = false

  constructor() {
    super()
    this.state = {
      isLoading: true,
      firstChartLoading: false,
      secondChartLoading: false,
      firstDataPoint: false,
      secondDataPoint: false,
      firstParamID: null,
      secondParamID: null,
      ltvData: [],
      ltvMarketData: [],
      ltvMarketChecked: [],
      demoData: [],
      firstSelectedMetric: 'Age Range',
      secondSelectedMetric: 'Household Income',
      firstParam: 'age',
      secondParam: 'hhi',
      firstParamIdName: 'age_ID',
      secondParamIdName: 'hhi_ID',
      firstSelectedData: [],
      secondSelectedData: [],
      rfmData: [],
      data: [],
      checked: [],
      // dateRange: [moment().subtract(1, 'years'), moment()]
      months: 0,
      rMinYearmo: '',
      rMaxYearmo: '',
      tMinYearmo: '',
      tMaxYearmo: '',
      dateRange: [0, 11],
      optionsArr: [
        {paramName: 'age', paramIdName: 'age_ID' ,metric: 'Age Range'},
        {paramName: 'cre', paramIdName: 'cre_ID' ,metric: 'Credit Rating'},
        {paramName: 'dwe', paramIdName: 'dwe_ID' ,metric: 'Dwelling Type'},
        {paramName: 'edu', paramIdName: 'edu_ID' ,metric: 'Education'},
        {paramName: 'eth', paramIdName: 'eth_ID' ,metric: 'Ethnicity'},
        {paramName: 'gen', paramIdName: 'gen_ID' ,metric: 'Gender'},
        {paramName: 'hva', paramIdName: 'hva_ID' ,metric: 'Home Value'},
        {paramName: 'hhi', paramIdName: 'hhi_ID' ,metric: 'Household Income'},
        {paramName: 'mar', paramIdName: 'mar_ID' ,metric: 'Marital Status'},
        {paramName: 'moh', paramIdName: 'moh_ID' ,metric: 'Mosaic Household Group'},
        {paramName: 'moz', paramIdName: 'moz_ID' ,metric: 'Mosaic Zip'},
        {paramName: 'poc', paramIdName: 'poc_ID' ,metric: 'Presence Of Children'},
        {paramName: 'roo', paramIdName: 'roo_ID' ,metric: 'Rent Or Owned'},
      ]
    }
  }

  componentDidMount = () => {
    this._isMounted = true
    let ltvTotalData = []

    Promise.all([
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/ltvByDemo?${this.state.firstParam}=1&${this.state.secondParam}=2`, {
        headers: {
          'token': Cookies.get('token')
        },
      }),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/ltvByDemo/market?${this.state.firstParam}=1&${this.state.secondParam}=2`, {
        headers: {
          'token': Cookies.get('token')
        },
      }),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/demoByLoc`, {
        headers: {
          'token': Cookies.get('token')
        }
      })
    ])
    .then(([response, response2, response3]) => Promise.all([response.json(), response2.json(), response3.json()]))
    .then(([data, data2, data3]) => {
      this.setState({
        isLoading: false,
        ltvData: data,
        firstSelectedData: data2.map(item => item.Data_Graph_1),
        secondSelectedData: data2.map(item => item.Data_Graph_2),
        // firstSelectedData: data.Data_Graph_1,
        // secondSelectedData: data.Data_Graph_2,
        // rfmData: data.Data_Tab_RFM,
        rfmData: data2.map(item => item.Data_Tab_RFM),
        ltvMarketData: data2,
        ltvMarketChecked: data2,
        demoData: data3,
        checked: data2,
        rMinYearmo: data2[0].Min_R_YearMo,
        rMaxYearmo: data2[0].Max_R_YearMo,
        tMinYearmo: data2[0].Min_T_YearMo,
        tMaxYearmo: data2[0].Max_T_YearMo,

      })
    })
    .catch(error => {
      console.log(error)
    })
  }

  componentWillUnmount = () => {
    this._isMounted = false
  }

  handleToggleChecked = (e, row) => {
    const currentIndex = this.state.checked.indexOf(row)
    const newChecked = [...this.state.checked]

    if(currentIndex === -1) {
      newChecked.push(row)
      let arr1 = []
      let arr2 = []
      let rfmArr = []
      arr1.push(row.Data_Graph_1)
      arr2.push(row.Data_Graph_2)
      rfmArr.push(row.Data_Tab_RFM)
      this.setState({
        firstSelectedData: this.state.firstSelectedData.concat(arr1),
        secondSelectedData: this.state.secondSelectedData.concat(arr2),
        ltvMarketData: this.state.ltvMarketData.concat(row),
        rfmData: this.state.rfmData.concat(rfmArr),
      })
    } else {
      newChecked.splice(currentIndex, 1)

      this.setState({
        firstSelectedData: this.state.firstSelectedData.filter(item => item !== row.Data_Graph_1),
        secondSelectedData: this.state.secondSelectedData.filter(item => item !== row.Data_Graph_2),
        ltvMarketData: this.state.ltvMarketData.filter(item => item !== row),
        rfmData: this.state.rfmData.filter(item => item !== row.Data_Tab_RFM)
      })
    }

    this.setState({
      checked: newChecked
    })
  }

  handleMetricChange = (e) => {
    let optionsArr = this.state.optionsArr
    let newParamName = optionsArr.filter(item => item.metric === e.target.value).map(item => item.paramName)[0]

    if(e.target.name === 'firstSelectedMetric') {
      Promise.all([
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/ltvByDemo?${newParamName}=1&${this.state.secondParam}=2`, {
          headers: {
            'token': Cookies.get('token')
          },
        }),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/ltvByDemo/market?${newParamName}=1&${this.state.secondParam}=2`, {
          headers: {
            'token': Cookies.get('token')
          },
        })
      ])
      .then(([response, response2]) => Promise.all([response.json(), response2.json()]))
      .then(([data, data2]) => {
        this.setState({
          firstParam: newParamName,
          ltvData: data,
          firstSelectedData: data2.map(item => item.Data_Graph_1),
          secondSelectedData: data2.map(item => item.Data_Graph_2),
          ltvMarketData: data2,
          checked: data2,
          rfmData: data2.map(item => item.Data_Tab_RFM),
        })
      })
    } else {
      Promise.all([
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/ltvByDemo?${this.state.firstParam}=1&${newParamName}=2`, {
          headers: {
            'token': Cookies.get('token')
          },
        }),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/ltvByDemo/market?${this.state.firstParam}=1&${newParamName}=2`, {
          headers: {
            'token': Cookies.get('token')
          },
        })
      ])
      .then(([response, response2]) => Promise.all([response.json(), response2.json()]))
      .then(([data, data2]) => {
        this.setState({
          secondParam: newParamName,
          ltvData: data,
          firstSelectedData: data2.map(item => item.Data_Graph_1),
          secondSelectedData: data2.map(item => item.Data_Graph_2),
          ltvMarketData: data2,
          checked: data2,
          rfmData: data2.map(item => item.Data_Tab_RFM),
        })
      })
    }

    this.setState({
      [e.target.name]: e.target.value
    })
  }

  renderSelectOptions = (prevSelected) => {
    return(
      this.state.demoData.filter(item => item.MetricType !== prevSelected).map(item => (
        <MenuItem key={item.Key} value={item.MetricType}>{item.MetricType}</MenuItem>
      ))
    )
  }

  getCustomersPct = (data) => {
    let metricLength = data.map(item => item.length)[0]
    let customers = []

    for(let i = 0; i < metricLength; i++) {
      customers.push(data.map(item => item[i].Customers).reduce((a,b) => a + b, 0))
    }

    return customers.map(item => (item / customers.reduce((a,b) => a + b, 0)) * 100)
  }

  getRfmCustomerData = data => {
    let customerData = []
    let customersArr = []
    let customersPct = []
    let metricLength = data.map(item => item.length)[0]
    let colorsArr = ['#00b055', '#58c1db', '#007491', '#c369ff', '#d1bc13', '#ab9349', '#e38fb6', '#f7850a', '#ff2b34', '#c4c4c4', '#9c9c9c', '#737373',]

    for(let i = 0; i < metricLength; i++) {
      customersArr.push(data.map(item => item[i].Customers).reduce((a,b) => a + b, 0))
    }

    for(let i = 0; i < customersArr.length; i++) {
      customersPct.push(customersArr[i] / customersArr.reduce((a,b) => a + b, 0) * 100)
    }

    for(let i = 0; i < metricLength; i++) {
      const rfmObj = {}
      rfmObj['key'] = data.map(item => item[i].Key)[0]
      rfmObj['percent'] = customersPct[i]
      rfmObj['label'] = data.map(item => item[i].Label)[0]
      rfmObj['color'] = colorsArr[i]
      customerData.push(rfmObj)
    }

    return(customerData)
  }

  getTotals = data => {
    const totals = []
    let metricLength = data.map(item => item.Data_Tab_Tot.length)[0]
    let totalCust = data.map(item => item.Data_Tab_Tot).map(item => item.filter(item => item.Label === 'Total Customers')).map(item => item[0].Value).reduce((a,b) => a + b, 0)
    let totalOrders = data.map(item => item.Data_Tab_Tot).map(item => item.filter(item => item.Label === 'Total Orders')).map(item => item[0].Value).reduce((a,b) => a + b, 0)
    let totalSales = data.map(item => item.Data_Tab_Tot).map(item => item.filter(item => item.Label === 'Total Sales')).map(item => item[0].Value).reduce((a,b) => a + b, 0)

    for(let i = 0; i < metricLength; i++) {
      const totalsObj = {}
      totalsObj['Value'] = data.map(item => item.Data_Tab_Tot[i].Value).reduce((a,b) => a + b, 0)
      totalsObj['Label'] = data.map(item => item.Data_Tab_Tot[i].Label)[0]
      totalsObj['Key'] = data.map(item => item.Data_Tab_Tot[i].Key)[0]
      totals.push(totalsObj)
    }

    return(totals)
  }

  getLtv = data => {
    const ltv = []
    const ltvValues = []
    const metricLength = data.map(item => item.Data_Tab_LTV.length)[0]
    let totalCust = data.map(item => item.Data_Tab_Tot).map(item => item.filter(item => item.Label === 'Total Customers')).map(item => item[0].Value).reduce((a,b) => a + b, 0)
    let totalOrders = data.map(item => item.Data_Tab_Tot).map(item => item.filter(item => item.Label === 'Total Orders')).map(item => item[0].Value).reduce((a,b) => a + b, 0)
    let totalSales = data.map(item => item.Data_Tab_Tot).map(item => item.filter(item => item.Label === 'Total Sales')).map(item => item[0].Value).reduce((a,b) => a + b, 0)

    ltvValues.push(totalOrders / totalCust, totalSales / totalCust, totalSales / totalOrders)

    for(let i = 0; i < metricLength; i++) {
      const ltvObj = {}
      ltvObj['Label'] = data.map(item => item.Data_Tab_LTV[i].Label)[0]
      ltvObj['Value'] = ltvValues[i]
      ltvObj['Key'] = data.map(item => item.Data_Tab_LTV[i].Key)[0]
      ltv.push(ltvObj)
    }

    return(ltv)
  }

  getRepurchase = data => {
    const repurchase = []
    const metricLength = data.map(item => item.Data_Tab_Rep.length)[0]
    const repurchaseTotalCust = data.map(item => item.Data_Tab_Rep.map(item => item.Customers).reduce((a,b) => a + b, 0)).reduce((a,b) => a + b, 0)

    for(let i = 0; i < metricLength; i++) {
      const repObj = {}
      repObj['Label'] = data.map(item => item.Data_Tab_Rep[i].Label)[0]
      repObj['Value'] = data.map(item => item.Data_Tab_Rep[i].Customers).reduce((a,b) => a + b, 0) / repurchaseTotalCust
      repObj['Key'] = data.map(item => item.Data_Tab_Rep[i].Key)[0]
      repurchase.push(repObj)
    }

    return(repurchase)
  }

  handleDataPointSelect = (dataIndex, dataLabel, selected, chartID) => {
    if(chartID === 1) {
      let paramObj = {}
      let paramIdName = this.state.optionsArr.filter(item => item.paramName === this.state.firstParam).map(item => item.paramIdName)[0]
      let paramMetricId = this.state.firstSelectedData.map(item => item.filter(item => item.Label === dataLabel)[0])[0].MetricID

      if(selected.length > 1) {
        console.log(paramMetricId)
      } else if (selected.length === 1) {
        console.log(paramIdName)
        console.log(paramMetricId)
        Promise.all([
          fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/ltvByDemo?${this.state.firstParam}=1&${this.state.secondParam}=2&${paramIdName}=${paramMetricId}`, {
            headers: {
              'token': Cookies.get('token')
            },
          }),
          fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/ltvByDemo/market?${this.state.firstParam}=1&${this.state.secondParam}=2&${paramIdName}=${paramMetricId}`, {
            headers: {
              'token': Cookies.get('token')
            },
          })
        ])
        .then(([response1, response2]) => Promise.all([response1.json(), response2.json()]))
        .then(([data1, data2]) => {
          console.log(data2)
          this.setState({
            firstDataPoint: true,
            firstParamID: paramMetricId,
            secondChartLoading: false,
            secondSelectedData: data2.map(item => item.Data_Graph_2),
            rfmData: data2.map(item => item.Data_Tab_RFM),
            ltvMarketData: data2,
          })
        })
        .catch(error => {
          console.log(error)
        })
      }
        // for(let i = 0; i < selected.length; i++) {
        //   let paramIdName = this.state.optionsArr.filter(item => item.paramName === this.state.firstParam).map(item => item.paramIdName)[0]
        //   let paramMetricId = this.state.firstSelectedData.map(item => item.filter(item => item.Label === dataLabel)[0])[0].MetricID
        //   console.log(paramIdName)
        //   console.log(paramMetricId)
        // }

        // if(selected.length === 1) {
        //   console.log(paramIdName)
        //   console.log(paramMetricId)
        //   Promise.all([
        //     fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/ltvByDemo?${this.state.firstParam}=1&${this.state.secondParam}=2&${paramIdName}=${paramMetricId}`, {
        //       headers: {
        //         'token': Cookies.get('token')
        //       },
        //     }),
        //     fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/ltvByDemo/market?${this.state.firstParam}=1&${this.state.secondParam}=2&${paramIdName}=${paramMetricId}`, {
        //       headers: {
        //         'token': Cookies.get('token')
        //       },
        //     })
        //   ])
        //   .then(([response1, response2]) => Promise.all([response1.json(), response2.json()]))
        //   .then(([data1, data2]) => {
        //     console.log(data2)
        //     this.setState({
        //       firstDataPoint: true,
        //       firstParamID: paramMetricId,
        //       secondChartLoading: false,
        //       secondSelectedData: data2.map(item => item.Data_Graph_2),
        //       rfmData: data2.map(item => item.Data_Tab_RFM),
        //       ltvMarketData: data2,
        //     })
        //   })
        //   .catch(error => {
        //     console.log(error)
        //   })
        // } else {
        //   Promise.all([
        //     fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/ltvByDemo?${this.state.firstParam}=1&${this.state.secondParam}=2`, {
        //       headers: {
        //         'token': Cookies.get('token')
        //       },
        //     }),
        //     fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/ltvByDemo/market?${this.state.firstParam}=1&${this.state.secondParam}=2`, {
        //       headers: {
        //         'token': Cookies.get('token')
        //       },
        //     })
        //   ])
        //   .then(([response1, response2]) => Promise.all([response1.json(), response2.json()]))
        //   .then(([data1, data2]) => {
        //     this.setState({
        //       firstDataPoint: false,
        //       firstParamID: null,
        //       secondChartLoading: false,
        //       secondSelectedData: data2.map(item => item.Data_Graph_2),
        //       rfmData: data2.map(item => item.Data_Tab_RFM),
        //       ltvMarketData: data2,
        //     })
        //   })
        //   .catch(error => {
        //     console.log(error)
        //   })
        // }

      } else {
        let paramIdName = this.state.optionsArr.filter(item => item.paramName === this.state.secondParam).map(item => item.paramIdName)[0]
        let paramMetricId = this.state.secondSelectedData.map(item => item.filter(item => item.Label === dataLabel)[0])[0].MetricID

        if(this.state.firstDataPoint && selected.length === 1) {
          console.log('firstDataPoint, secondDataPoint')
        //
        //   Promise.all([
        //     fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/ltvByDemo?${this.state.firstParam}=1&${this.state.secondParam}=2&${this.state.firstParam + '_ID'}=${this.state.firstParamID}&${paramIdName}=${paramMetricId}`, {
        //       headers: {
        //         'token': Cookies.get('token')
        //       },
        //     }),
        //     fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/ltvByDemo/market?${this.state.firstParam}=1&${this.state.secondParam}=2&${this.state.firstParam + '_ID'}=${this.state.firstParamID}&${paramIdName}=${paramMetricId}`, {
        //       headers: {
        //         'token': Cookies.get('token')
        //       },
        //     })
        //   ])
        //   .then(([response1, response2]) => Promise.all([response1.json(), response2.json()]))
        //   .then(([data1, data2]) => {
        //     console.log(data2)
        //     this.setState({
        //       secondDataPoint: true,
        //       secondParamID: paramMetricId,
        //       firstSelectedData: data2.map(item => item.Data_Graph_1),
        //     })
        //   })
        } else if(this.state.firstDataPoint && selected.length === 0) {
          console.log('firstDataPoint only')
        } else if(!this.state.firstDataPoint && selected.length === 1) {
          Promise.all([
            fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/ltvByDemo?${this.state.firstParam}=1&${this.state.secondParam}=2&${paramIdName}=${paramMetricId}`, {
              headers: {
                'token': Cookies.get('token')
              },
            }),
            fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/ltvByDemo/market?${this.state.firstParam}=1&${this.state.secondParam}=2&${paramIdName}=${paramMetricId}`, {
              headers: {
                'token': Cookies.get('token')
              },
            })
          ])
          .then(([response1, response2]) => Promise.all([response1.json(), response2.json()]))
          .then(([data1, data2]) => {
            console.log(data2)
            this.setState({
              secondDataPoint: true,
              secondParamID: paramMetricId,
              firstChartLoading: false,
              firstSelectedData: data2.map(item => item.Data_Graph_1),
              rfmData: data2.map(item => item.Data_Tab_RFM),
              ltvMarketData: data2,
            })
          })
          .catch(error => {
            console.log(error)
          })
        } else {
          Promise.all([
            fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/ltvByDemo?${this.state.firstParam}=1&${this.state.secondParam}=2`, {
              headers: {
                'token': Cookies.get('token')
              },
            }),
            fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/ltvByDemo/market?${this.state.firstParam}=1&${this.state.secondParam}=2`, {
              headers: {
                'token': Cookies.get('token')
              },
            })
          ])
          .then(([response1, response2]) => Promise.all([response1.json(), response2.json()]))
          .then(([data1, data2]) => {
            console.log(data2)
            this.setState({
              secondDataPoint: true,
              secondParamID: paramMetricId,
              firstChartLoading: false,
              firstSelectedData: data2.map(item => item.Data_Graph_1),
              rfmData: data2.map(item => item.Data_Tab_RFM),
              ltvMarketData: data2,
            })
          })
          .catch(error => {
            console.log(error)
          })
        }



        // let paramIdName = this.state.optionsArr.filter(item => item.paramName === this.state.secondParam).map(item => item.paramIdName)[0]
        // let paramMetricId = this.state.secondSelectedData.filter(item => item.Label === dataLabel)[0].MetricID
        //
        // console.log(paramIdName)
        // console.log(paramMetricId)

        // console.log(this.state.secondSelectedData.filter(item => item.Label === dataLabel))
        // console.log(dataLabel)
      }
    // } else {
    //   console.log(false)
    // }

  }

  handleRdateRange = (value, valName) => {
    if(valName === 'minYearmo') {
      this.setState({
        months: Math.floor(moment(this.state.rMaxYearmo).diff(value, 'months')),
      })
    } else {
      this.setState({
        months: Math.floor(value.diff(moment(this.state.rMinYearmo), 'months')),
      })
    }

    this.setState({
      [valName]: value
    })
  }

  handleTdateRange = (value, valName) => {
    if(valName === 'minYearmo') {
      this.setState({
        months: Math.floor(moment(this.state.tMaxYearmo).diff(value, 'months')),
      })
    } else {
      this.setState({
        months: Math.floor(value.diff(moment(this.state.tMinYearmo), 'months')),
      })
    }

    this.setState({
      [valName]: value
    })
  }

  handleSliderDateChange = (values, sName) => {
    if(sName === 'first') {
      const rMinYearmo = values[0]
      const rMaxYearmo = values[1]

      let ltvTotalData = []

      // Promise.all([
      //   fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/ltvByDemo?${this.state.firstParam}=1&${this.state.secondParam}=2`, {
      //     headers: {
      //       'token': Cookies.get('token')
      //     },
      //   }),
      //   fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/ltvByDemo/market?${this.state.firstParam}=1&${this.state.secondParam}=2`, {
      //     headers: {
      //       'token': Cookies.get('token')
      //     },
      //   }),
      //   fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/demoByLoc`, {
      //     headers: {
      //       'token': Cookies.get('token')
      //     }
      //   })
      // ])
      // .then(([response, response2, response3]) => Promise.all([response.json(), response2.json(), response3.json()]))
      // .then(([data, data2, data3]) => {
      //   this.setState({
      //     isLoading: false,
      //     ltvData: data,
      //     firstSelectedData: data2.map(item => item.Data_Graph_1),
      //     secondSelectedData: data2.map(item => item.Data_Graph_2),
      //     // firstSelectedData: data.Data_Graph_1,
      //     // secondSelectedData: data.Data_Graph_2,
      //     // rfmData: data.Data_Tab_RFM,
      //     rfmData: data2.map(item => item.Data_Tab_RFM),
      //     ltvMarketData: data2,
      //     ltvMarketChecked: data2,
      //     demoData: data3,
      //     checked: data2,
      //     rMinYearmo: data2[0].Min_R_YearMo,
      //     rMaxYearmo: data2[0].Max_R_YearMo,
      //     tMinYearmo: data2[0].Min_T_YearMo,
      //     tMaxYearmo: data2[0].Max_T_YearMo,
      //
      //   })
      // })
      // .catch(error => {
      //   console.log(error)
      // })

    } else {
      const tMinYearmo = values[0]
      const tMaxYearmo = values[1]
    }
  }

  render() {
    const { classes } = this.props
    const firstCustomersPct = this.getCustomersPct(this.state.firstSelectedData)
    const secondCustomersPct = this.getCustomersPct(this.state.secondSelectedData)
    const rfmCustomersPct = this.getRfmCustomerData(this.state.rfmData)
    const totals = this.getTotals(this.state.ltvMarketData)
    const ltv = this.getLtv(this.state.ltvMarketData)
    const repurchase = this.getRepurchase(this.state.ltvMarketData)

    return(
      <div>
        <div>
          {this.state.isLoading ? <CircularProgress /> :
            <div>
              <Typography className={this.props.darkMode ? classes.pageTitleDark : classes.pageTitle} style={{marginTop: '25px'}}>
                <LtvIcon className={this.props.darkMode ? classes.pageIconDark : classes.pageIcon} />
                <span style={{marginLeft: '5px'}}>LTV BY DEMOGRAPHICS</span>
              </Typography>
              <br/>
              <Card className={classes.tableContainer}>
                <Table>
                  <TableHead>
                    <TableRow style={{backgroundColor: 'rgba(235, 130, 34, 0.8)'}}>
                      <TableCell colSpan={this.state.ltvData.Data_Tab_RFM.length} style={{textAlign: 'center'}}>
                        <Typography variant="caption" style={{fontWeight: 'bold', fontSize: '15px', color: '#fff'}}>RFM SEGMENT</Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      {rfmCustomersPct.map(row => (
                        <TableCell key={row.key} align="center">
                          <Typography variant="caption" style={{fontWeight: 'bold', color: `${row.color}`}}>{row.label}</Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      {rfmCustomersPct.map(row => (
                        <TableCell key={row.key} align="center">
                          <Typography variant="caption">{row.percent.toFixed(2) + '%'}</Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </Card>
              <br/>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <Card className={classes.card}>
                  <div>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <InputLabel id="first-metric-select">Metric Type One</InputLabel>
                      <Select
                        name="firstSelectedMetric"
                        id="first-metric"
                        labelId="first-metric-select"
                        value={this.state.firstSelectedMetric}
                        onChange={e => this.handleMetricChange(e)}
                        label="Metric Type One"
                      >
                        {this.renderSelectOptions(this.state.secondSelectedMetric)}
                      </Select>
                    </FormControl>
                  </div>
                  <br/>
                  <LtvBarChart
                    chartID={1}
                    data={[{
                      name: 'Customer %',
                      data: firstCustomersPct.map(item => parseFloat(item.toFixed(2)))
                    }]}
                    height={500}
                    labels={this.state.firstSelectedData.map(item => item.map(item => item.Label))[0]}
                    rotate={0}
                    handleDataPointSelect={this.handleDataPointSelect}
                  />

                  {/* {console.log(this.state.firstSelectedData)} */}

                </Card>

                <Card className={classes.card} style={{marginLeft: '15px'}}>
                  <div>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <InputLabel id="second-metric-select">Metric Type Two</InputLabel>
                      <Select
                        name="secondSelectedMetric"
                        id="second-metric"
                        labelId="second-metric-select"
                        value={this.state.secondSelectedMetric}
                        onChange={e => this.handleMetricChange(e)}
                        label="Metric Type Two"
                      >
                        {this.renderSelectOptions(this.state.firstSelectedMetric)}
                      </Select>
                    </FormControl>
                  </div>
                  <br/>
                  {/* <BarChart
                    data={[{
                      name: 'Customer %',
                      data: secondCustomersPct.map(item => parseFloat(item.toFixed(2)))
                    }]}
                    height={500}
                    labels={this.state.secondSelectedData.map(item => item.Label)}
                    rotate={0}
                  /> */}
                  {this.state.secondChartLoading ? <CircularProgress /> :
                    <LtvBarChart
                      chartID={2}
                      data={[{
                        name: 'Customer %',
                        data: secondCustomersPct.map(item => parseFloat(item.toFixed(2)))
                      }]}
                      height={500}
                      labels={this.state.secondSelectedData.map(item => item.map(item => item.Label))[0]}
                      rotate={0}
                      handleDataPointSelect={this.handleDataPointSelect}
                    />
                  }
                </Card>

                <Card className={classes.card} style={{width: '20%', padding: '1em', marginLeft: '15px'}}>
                  <div>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                      <Typography
                        className={this.props.darkMode ? classes.listLabelDark : classes.listLabel}
                        variant="caption"
                        style={{marginLeft: '2px'}}
                      >
                        {`MARKETS (${this.state.ltvMarketData.length})`}
                      </Typography>
                      <Typography className={this.props.darkMode ? classes.listLabelDark : classes.listLabel} variant="caption" style={{marginRight: '2px'}}>{`${this.state.checked.length} selected`}</Typography>
                    </div>
                    {/* <Typography variant="caption" style={{marginLeft: '15px'}}>MARKETS({this.state.ltvMarketData.length})</Typography> */}
                    <List className={classes.list} dense={true}>
                      {this.state.ltvMarketChecked.map(row => (
                        <ListItem key={row.Key} dense button onClick={e => this.handleToggleChecked(e, row)}>
                          <ListItemIcon>
                            <Checkbox
                              // edge="start"
                              checked={this.state.checked.indexOf(row) !== -1}
                              disableRipple
                              className={classes.checkbox}
                            />
                          </ListItemIcon>
                          <ListItemText>{row.Label}</ListItemText>
                        </ListItem>
                      ))}
                    </List>
                  </div>
                  <div>
                    <Typography
                      className={this.props.darkMode ? classes.listLabelDark : classes.listLabel}
                      variant="caption"
                      style={this.props.darkMode ? {backgroundColor: '#424242'} : {backgroundColor: '#fff'}}
                    >
                      First Purchase Date
                    </Typography>
                    <div className={classes.datePicker} style={{marginTop: '-10px'}}>
                      <MonthPicker
                        maxValue={moment(this.state.ltvMarketData[0].Max_R_YearMo)}
                        minValue={moment(this.state.ltvMarketData[0].Min_R_YearMo)}
                        maxSliderValue={moment(this.state.ltvMarketData[0].Max_R_YearMo).diff(moment(this.state.ltvMarketData[0].Min_R_YearMo), 'months')}
                        handleDateRange={this.handleRdateRange}
                        name={'first'}
                        handleSliderDateChange={this.handleSliderDateChange}
                      />
                    </div>
                  </div>
                  <div>
                    <Typography
                      className={this.props.darkMode ? classes.listLabelDark : classes.listLabel}
                      variant="caption"
                      style={this.props.darkMode ? {backgroundColor: '#424242'} : {backgroundColor: '#fff'}}
                    >
                      Most Recent Purchase Date
                    </Typography>
                    <div className={classes.datePicker} style={{marginTop: '-10px'}}>
                      <MonthPicker
                        maxValue={moment(this.state.ltvMarketData[0].Max_T_YearMo)}
                        minValue={moment(this.state.ltvMarketData[0].Min_T_YearMo)}
                        maxSliderValue={moment(this.state.ltvMarketData[0].Max_T_YearMo).diff(moment(this.state.ltvMarketData[0].Min_T_YearMo), 'months')}
                        handleDateRange={this.handleTdateRange}
                        name={'recent'}
                        handleSliderDateChange={this.handleSliderDateChange}
                      />
                    </div>
                  </div>
                </Card>
              </div>
              <br/>

              {/* <Card className={classes.tableContainer}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={this.state.ltvData.Data_Tab_RFM.length} style={{textAlign: 'center'}}>
                        <Typography variant="caption" style={{fontWeight: 'bold', fontSize: '15px'}}>RFM SEGMENT</Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      {rfmCustomersPct.map(row => (
                        <TableCell key={row.key} align="center">
                          <Typography variant="caption" style={{fontWeight: 'bold', color: `${row.color}`}}>{row.label}</Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      {rfmCustomersPct.map(row => (
                        <TableCell key={row.key} align="center">
                          <Typography variant="caption">{row.percent.toFixed(2) + '%'}</Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </Card> */}
              {/* <br/> */}
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <Card className={classes.ltvCard}>
                  <Table>
                    <TableHead>
                      <TableRow style={{backgroundColor: '#dba400'}}>
                        <TableCell colSpan={2} align="center"><Typography variant="caption" style={{fontWeight: 'bold', fontSize: '15px', color: '#fff'}}>LONG TERM VALUE</Typography></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {ltv.map(row => (
                        <TableRow key={row.Key}>
                          <TableCell>{row.Label}</TableCell>
                          <TableCell align="right">{row.Value.toString().indexOf('.') == -1 ? row.Value.toLocaleString('en') : row.Value.toFixed(2)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Card>
                <Card className={classes.ltvCard} style={{marginLeft: '15px'}}>
                  <Table>
                    <TableHead>
                      <TableRow style={{backgroundColor: '#51adc4'}}>
                        <TableCell colSpan={2} align="center"><Typography variant="caption" style={{fontWeight: 'bold', fontSize: '15px', color: '#fff'}}>REPURCHASE BEHAVIOR</Typography></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {repurchase.map(row => (
                        <TableRow key={row.Key}>
                          <TableCell>{row.Label}</TableCell>
                          {/* <TableCell align="right">{row.Value.toString().indexOf('.') == -1 ? row.Value.toLocaleString('en') : row.Value.toFixed(2)}</TableCell> */}
                          <TableCell align="right">{(row.Value * 100).toFixed(2) + '%'}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Card>
                <Card className={classes.ltvCard} style={{marginLeft: '15px'}}>
                  <Table>
                    <TableHead>
                      <TableRow style={{backgroundColor: '#00b055'}}>
                        <TableCell colSpan={2} align="center"><Typography variant="caption" style={{fontWeight: 'bold', fontSize: '15px', color: '#fff'}}>TOTALS</Typography></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {totals.map(row => (
                        <TableRow key={row.Key}>
                          <TableCell>{row.Label}</TableCell>
                          <TableCell align="right">{row.Value.toString().indexOf('.') == -1 ? row.Value.toLocaleString('en') : row.Value.toFixed(2)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Card>
              </div>

            </div>
          }

        </div>
        {/* {console.log(this.state.demoData)} */}
        {console.log(this.state.ltvMarketData)}
        {/* {console.log(this.state.ltvData)}
        {console.log(this.state.ltvMarketData)} */}


      </div>
    )
  }
}

export default withStyles(styles)(LtvDemo)
