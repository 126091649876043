import React, { Component, Fragment } from 'react'
import {
  withStyles,
  Typography,
  Divider,
  CircularProgress,
  Card,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  Button
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import BarChartStacked from '../charts/BarChartStacked'
import Cookies from 'js-cookie'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import otbMainStandIn from '../../assets/mainStandIns/otbMainStandIn.png'
import AskReport from '../AskReport'

const styles = theme => ({
  loader: {
    marginTop: '25%',
    marginLeft: '50%'
  },
  description: {
    color: '#626262',
    marginTop: '10px',
    paddingBottom: '10px',
  },
  formControl: {
    width: 250,
    margin: theme.spacing(1),
  },
  tableBorder: {
    marginTop: '20px',
    display: 'flex',
    // height: '100%',
    align: 'right'
  },
  pageTitle: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '17px',
    fontWeight: 'light',
    color: 'rgba(115, 115, 115, 0.9)'
  },
  pageTitleDark: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '17px',
    fontWeight: 'light',
    color: 'rgba(255, 255, 255, 0.9)'
  },
  pageIcon: {
    fontSize: '15px',
    marginTop: '21px',
    marginRight: '2px',
  },
  pageIconDark: {
    fontSize: '15px',
    color: 'rgba(255, 255, 255, 0.9)',
    marginTop: '5px',
    marginRight: '2px',
  },
  unavailable: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // margin: '2em'
  },
  chart: {
    padding: '2em',
    width: '85%',
    minHeight: 750,
    // height: 1000,
  },
  total: {
    fontWeight: 'bold',
    fontSize: '20px',
    color: '#ff4189'
  },
  grandTotal: {
    marginTop: '20px',
    minHeight: 150,
    padding: theme.spacing(1)
  },
  resetButton: {
    marginTop: '5px',
    display: 'inline-flex',
    alignItems: 'center',
    color: '#001d84',
    borderColor: '#001d84',
    '&:hover': {
      color: '#4ab8bb',
      borderColor: '#4ab8bb',
      backgroundColor: 'transparent'
    }
  }
})

class OtbCounts extends Component {
  _isMounted = false

  constructor() {
    super()
    this.state = {
      isLoading: true,
      otbData: [],
      otbDataSource: [],
      defaultOtbDataSource: [],
      otbTotalData: [],
      source: 0,
      sourceName: '',
      metric: 'location',
      chartLoading: true,
      labels: [],
      selectedLocation: '',
    }
  }

  componentDidMount() {
    this._isMounted = true

    Promise.all([
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/otb/location?Source=1`, {
        headers: {
          token: Cookies.get('token')
        }
      }),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/otb/location?Source=0`, {
        headers: {
          token: Cookies.get('token')
        }
      }),
    ])
    .then(([response, response2]) => Promise.all([response.json(), response2.json()]))
    .then(([data, data2]) => {
      if(this._isMounted && data) {
        this.setState({
          isLoading: false,
          chartLoading: false,
          otbData: data,
          otbTotalData: data2,
          defaultOtbDataSource: data2,
          otbDataSource: data2,
          labels: data.map(item => item.Label)
        })
      } else {
        this.setState({
          isLoading: false,
          otbData: []
        })
      }
    })
    .catch(error => {
      console.log(error)
    })

    if(JSON.parse(localStorage.getItem('currentUser'))[0].username.toUpperCase().includes('USER')) {
    } else {
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/pageviews/add?display=otb_counts&screen_name=otb_counts`, {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'token': Cookies.get('token'),
        },
      })
      .then(response => {
        if(response.ok === true) {
          return response.json()
        }
      })
      .catch(error => {
        console.log(error)
      })      
    }
  }

  componentWillUnmount = () => {
    this._isMounted = false
  }

  handleMetricChange = e => {
    this.setState({
      chartLoading: true,
    })

    if(this.state.source === 0) {
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/otb/${e.target.value}?Source=0`, {
        headers: {
          token: Cookies.get('token')
        }
      })
      .then(response => response.json())
      .then(data => {
        if(data) {
          this.setState({
            otbDataSource: data,
            defaultOtbDataSource: data,
            metric: e.target.value,
            chartLoading: false,
            labels: data.map(item => item.Label)
          })
        }
      })
      .catch(error => {
        console.log(error)
      })
    } else {
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/otb/${e.target.value}?Source=1`, {
        headers: {
          token: Cookies.get('token')
        }
      })
      .then(response => response.json())
      .then(data => {
        if(data) {
          this.setState({
            otbData: data,
            otbDataSource: data.map(item => item.Source[this.state.source - 1 ]),
            defaultOtbDataSource: data.map(item => item.Source[this.state.source - 1 ]),
            metric: e.target.value,
            chartLoading: false,
            labels: data.map(item => item.Label)
          })
        }
      })
      .catch(error => {
        console.log(error)
      })
    }
  }

  handleSourceChange = e => {
    this.setState({
      chartLoading: true,
    })

    let selectedSource = e.target.value
    if(selectedSource < 1) {
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/otb/${this.state.metric}?source=0`, {
        headers: {
          token: Cookies.get('token')
        }
      })
      .then(response => response.json())
      .then(data => {
        this.setState({
          otbDataSource: this.state.selectedLocation ? data.filter(item => item.Label === this.state.selectedLocation) : data,
          defaultOtbDataSource: data,
          source: selectedSource,
          chartLoading: false,
          labels: data.map(item => item.Label)
        })
        console.log(data.map(item => item.Label))
      })
      .catch(error => {
        console.log(error)
      })
    } else {
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/otb/${this.state.metric}?source=1`, {
        headers: {
          token: Cookies.get('token')
        }
      })
      .then(response => response.json())
      .then(data => {
        let dataArr = []
        for(let i = 0; i < data.length; i++) {
          let dataObj = {}
          dataObj['Key'] = data[i].Key
          dataObj['Label'] = data[i].Label
          dataObj['ProfitCenter'] = data[i].ProfitCenter
          dataObj['StoreId'] = data[i].StoreID
          dataObj['Data'] = data[i].Source[selectedSource - 1].Data
          dataArr.push(dataObj)
        }

        this.setState({
          source: selectedSource,
          otbData: data,
          otbDataSource: this.state.selectedLocation ? dataArr.filter(item => item.Label === this.state.selectedLocation) : dataArr,
          defaultOtbDataSource: dataArr,
          chartLoading: false,
          labels: data.map(item => item.Label)
        })
      })
      .catch(error => {
        console.log(error)
      })
    }

    this.setState({
      locationClicked: false,
    })
  }

  handleLocationClick = location => {
    this.setState({
      otbDataSource: this.state.otbDataSource.filter(item => item.Label === location),
      locationClicked: true,
      selectedLocation: location,
    })
  }

  handleOtbLocationReset = () => {
    this.setState({
      otbDataSource: this.state.defaultOtbDataSource,
      locationClicked: false,
      selectedLocation: '',
    })
  }

  render() {
    const { classes } = this.props
    const labels = this.state.labels
    const currentUserLite = JSON.parse(localStorage.getItem('currentUser')) && JSON.parse(localStorage.getItem('currentUser'))[0].sd_lite === 1 ? true : false
    const dbO = JSON.parse(localStorage.getItem("currentUser")) ? JSON.parse(localStorage.getItem("currentUser"))[0].db_owner : ""
    const colors = [
      '#ff4545',
      '#ffc849',
      '#56afe4',
      '#6dc96d',
      '#ff4189',
      '#c22ddd',
      '#07e8e0',
      '#ff9c3b',
    ]

    const series = [
      {
        name: this.state.otbDataSource.map(item => item.Data[0].OTB_Range)[0],
        data: this.state.otbDataSource.map(item => item.Data[0].Qty),
        color: '#ff4545',
      },
      {
        name: this.state.otbDataSource.map(item => item.Data[1].OTB_Range)[0],
        data: this.state.otbDataSource.map(item => item.Data[1].Qty),
        color: '#d6a940',
      },
      {
        name: this.state.otbDataSource.map(item => item.Data[2].OTB_Range)[0],
        data: this.state.otbDataSource.map(item => item.Data[2].Qty),
        color: '#4895c2',
      },
      {
        name: this.state.otbDataSource.map(item => item.Data[3].OTB_Range)[0],
        data: this.state.otbDataSource.map(item => item.Data[3].Qty),
        color: '#5ba85b',
      },
      {
        name: this.state.otbDataSource.map(item => item.Data[4].OTB_Range)[0],
        data: this.state.otbDataSource.map(item => item.Data[4].Qty),
        color: '#ff4189',
      },
      {
        name: this.state.otbDataSource.map(item => item.Data[5].OTB_Range)[0],
        data: this.state.otbDataSource.map(item => item.Data[5].Qty),
        color: '#c22ddd',
      },
      {
        name: this.state.otbDataSource.map(item => item.Data[6].OTB_Range)[0],
        data: this.state.otbDataSource.map(item => item.Data[6].Qty),
        color: '#2ab8b3',
      },
      {
        name: this.state.otbDataSource.map(item => item.Data[7].OTB_Range)[0],
        data: this.state.otbDataSource.map(item => item.Data[7].Qty),
        color: '#ff9c3b',
      },
    ]

    return(
      <div>
        {this.state.isLoading ? <div className={classes.loader}><CircularProgress /></div> :
        this.state.otbData.length > 0 && !currentUserLite && dbO !== "ESCANABA" ?
          <div>
            <Typography className={this.props.darkMode ? classes.pageTitleDark : classes.pageTitle} style={{marginTop: '25px'}}>
              <ShoppingCartIcon className={this.props.darkMode ? classes.pageIconDark : classes.pageIcon} />
              <span style={{marginLeft: '5px'}}>OTB COUNTS</span>
            </Typography>
            <br/>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <Paper className={classes.chart}>
                {this.state.chartLoading ?
                  <div>
                    <CircularProgress style={{position: 'absolute', marginLeft: '30%', marginTop: '20%', zIndex: 10000}}/>
                  </div>
                :
                  <div/>
                }
                <div style={this.state.chartLoading ? {opacity: 0.5} : {}}>
                  <div style={this.state.selectedLocation ? {} : {display: 'none'}}>
                    <Button
                      className={classes.resetButton}
                      variant="outlined"
                      onClick={this.handleOtbLocationReset}
                      disableRipple
                    >
                      <ArrowBackIcon style={{fontSize: '15px'}} />
                      <Typography style={{paddingTop: '2px', marginLeft: '2px'}} variant="caption">BACK TO ALL {this.state.metric.toUpperCase()}S</Typography>
                    </Button>
                  </div>
                  <BarChartStacked
                    labels={this.state.selectedLocation ? labels.filter(item => this.state.otbDataSource.map(item => item.Label).includes(item)) : labels}
                    series={series}
                    colors={colors}
                    xLabel={'OTB Count'}
                    height={this.state.otbDataSource.length === 1 ? 500 : this.state.otbDataSource.length > 80 ? 1500 : this.state.otbDataSource.length < 60 ? 800 : 1200}
                    darkMode={this.props.darkMode}
                    title={'OTB by Location'}
                    handleLocationClick={this.handleLocationClick}
                  />
                </div>
              </Paper>
              <div style={{display: 'block', textAlign: 'center', marginLeft: '20px', width: 300, minHeight: 750}}>
                <Paper style={{padding: '1em', minHeight: 225}}>
                  <FormControl variant="outlined" className={classes.formControl} style={{marginTop: '30px'}}>
                    <InputLabel id="metric-select">Metric Type</InputLabel>
                    <Select
                      id="metric"
                      labelId="metric-select"
                      value={this.state.metric}
                      onChange={this.handleMetricChange}
                      label="Metric Type"
                    >
                      <MenuItem value={'location'}>Location</MenuItem>
                      <MenuItem value={'market'}>Market</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel>Source</InputLabel>
                    <Select
                      value={this.state.source}
                      onChange={this.handleSourceChange}
                      label="Data Source"
                    >
                      <MenuItem value={0}>All</MenuItem>
                      {this.state.otbData[0].Source.map(row => (
                        <MenuItem key={row.Source_Key} value={row.Source_Key} name={row.Source_Name}>{row.Source_Name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <br />
                  <br />
                  <br />
                </Paper>

                <Paper className={classes.tableBorder}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" colSpan={2} component="th" scope="row">
                          <Typography variant="caption" style={{fontWeight: 'bold'}}>QUANTITY BY OTB RANGE</Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell><Typography variant="caption" style={{fontSize: 11, fontWeight: 'bold'}}>OTB Range</Typography></TableCell>
                        <TableCell align="right" style={this.props.darkMode ? {borderLeft: '1px solid #474747'} : {borderLeft: '1px solid #dcdcdc'}}><Typography variant="caption" style={{fontSize: 11, fontWeight: 'bold'}}>Quantity</Typography></TableCell>
                      </TableRow>
                      {series.map(row => (
                        <TableRow key={row.name}>
                          <TableCell><Typography variant="caption" style={{color: `${row.color}`}}>{row.name}</Typography></TableCell>
                          <TableCell align="right" style={this.props.darkMode ? {borderLeft: '1px solid #474747'} : {borderLeft: '1px solid #dcdcdc'}}>
                            <Typography variant="caption">{row.data.reduce((a,b) => a + b, 0).toLocaleString('en')}</Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography variant="caption" style={{fontWeight: 'bold', fontSize: 13}}>Total</Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="caption" style={{fontWeight: 'bold', fontSize: 13}}>{series.map(item => item.data).map(item => item.reduce((a,b) => a + b, 0)).reduce((a,b) => a + b, 0).toLocaleString('en')}</Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Paper>

                <Paper className={classes.grandTotal}>
                  <Typography style={{marginTop: '45px'}} display="block" variant="caption">
                      <span>The <span style={{fontWeight: 'bold'}}>Grand Total</span> OTB count is </span>
                      <div className={classes.total}>
                        {/* {series.map(item => item.data).map(item => item.reduce((a,b) => a + b, 0)).reduce((a,b) => a + b, 0).toLocaleString('en')} */}
                        {this.state.otbTotalData.map(item => item.Data.map(item => item.Qty).reduce((a,b) => a + b, 0)).reduce((a,b) => a + b, 0).toLocaleString('en')}
                      </div>
                  </Typography>
                </Paper>

              </div>
            </div>
          </div>
        : 
        <div className={classes.unavailable}>
            <AskReport />
          </div>
        }

      </div>
    )
  }
}

export default withStyles(styles)(OtbCounts)
