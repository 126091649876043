import React from 'react'
import {
  makeStyles,
  Typography,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  Grid,
  Tooltip,
  Paper,
  Fade,
  SvgIcon,
} from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  statsCard: {
    height: 150,
    border: '1px solid rgba(235, 140, 137, 0.7)',
    textAlign: 'center',
  },
  cardHeader: {
    // background: `linear-gradient(to right bottom, #f55247, #ba423a)`,
    background: `linear-gradient(to right bottom, #eb8c7f, #cf7467)`,
    height: 50,
    // display: 'inline-flex',
    // alignItems: 'center',
  },
  avatar: {
    color: '#fff',
    // marginTop: '10px',
    fontSize: '20px',
  }
}))

function LostOppIcon(props) {
  return(
    <SvgIcon {...props}>
      <path fill="currentColor" d="M3,4V12.5L6,9.5L9,13C10,14 10,15 10,15V21H14V14C14,14 14,13 13.47,12C12.94,11 12,10 12,10L9,6.58L11.5,4M18,4L13.54,8.47L14,9C14,9 14.93,10 15.47,11C15.68,11.4 15.8,11.79 15.87,12.13L21,7" />
    </SvgIcon>
  )
}

export default function LostOppStats(props) {
  const classes = useStyles()

  const totalIncSales = props.data.map(item => item.lostOpp.reduce((a,b) => a + b, 0) * item.cpp * item.roi)

  // const incSales = props.data.map(item => item)

  return(
    <div>
      <Fade in={true} timeout={500}>
        <Card className={classes.statsCard} style={{margin:'auto'}}>
          <div className={classes.cardHeader}>
            <div style={{display: 'inline-flex', marginTop: '15px'}}>
              <LostOppIcon className={classes.avatar}/>
              <Typography variant="caption" style={{color: '#fff', marginLeft: '10px', fontWeight: 'bold', fontSize: '14px'}}>LOST OPPORTUNITY</Typography>
            </div>
          </div>
          <CardContent style={{textAlign: 'center', justifyContent: 'center'}}>
            {/* <InfoOutlined fontSize="small" style={{color: '#3bb2b4'}}/> */}
            <div>
            </div>
            <div style={{marginLeft: '5px'}}>
              <Typography variant="caption">Using a conservative discounted Incremental ROI for additional targeted households, the total lost opportunity for the last 12 months is</Typography>
              <Typography style={{fontSize: '30px', color: 'rgba(255, 0, 0, 0.7)'}}>{'$' + Math.round(totalIncSales.reduce((a,b) => a + b, 0)).toLocaleString('en')}</Typography>
            </div>
          </CardContent>
        </Card>
      </Fade>
    </div>
  )
}
