import React from 'react'
import TabTest from '../TabTest'
import { Typography, makeStyles } from '@material-ui/core'
import {GiLifeBar} from 'react-icons/gi'
import AskReport from '../AskReport'
// import { withStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '17px',
    fontWeight: 'light',
    color: 'rgba(115, 115, 115, 0.9)'
  },
  pageIcon: {
    fontSize: '15px',
    marginTop: '21px',
    marginRight: '2px'
  },
  unavailable: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // margin: '2em'
  },
}))

export default function CustomersAFH() {
  const classes = useStyles()
  const currentUserLite = JSON.parse(localStorage.getItem('currentUser')) && JSON.parse(localStorage.getItem('currentUser'))[0].sd_lite === 1 ? true : false

  return (
    <div>
        {!currentUserLite && JSON.parse(localStorage.getItem('currentUser'))[0].db_owner === "AFHSCORP" ?

      <TabTest pageName={'RFM SALES STATS(1MM SAMPLE)'} viz={'/RFMSalesStatsAHSTopTier1MM//AHSCustView'} />
      :
      <div className={classes.unavailable}>
             <AskReport />
            </div>
      }
      </div>
  )
}
