import React, { useState } from 'react'
import { Typography, makeStyles, Box } from '@material-ui/core'
// import { withStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '24px',
    fontWeight: 'light',
    color: 'rgba(115, 115, 115, 0.9)',
    paddingTop: "20px",
    paddingLeft: "20px",
  },
  subTitle: {
    fontSize: '18px',
    fontWeight: 'light',
    color: 'rgba(115, 115, 115, 0.9)',
    paddingLeft: "20px",
  },
  pageIcon: {
    fontSize: '15px',
    marginTop: '21px',
    marginRight: '2px'
  },
  unavailable: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // margin: '2em'
  },
  root: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexWrap: "wrap",
    gap: "50px",
    margin: "20px",
  },
  imageItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  thumbnail: {
    width: "250px",
    height: "140px",
    cursor: "pointer",
    objectFit: "cover",
    transition: "transform 0.3s",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  imageCaption: {
    fontSize: '14px',
    paddingTop: "10px",
  },
  enlargedImage: {
    position: "absolute",
    top: "400px",
    left: "52%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    height: "450px",
    objectFit: "contain",
    zIndex: 999,
    background: "rgba(0,0,0,0.8)",
  },
  backdrop: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 998,
  },
}))

export default function Digital() {
  const classes = useStyles()
  const currentUserLite = JSON.parse(localStorage.getItem('currentUser')) && JSON.parse(localStorage.getItem('currentUser'))[0].sd_lite === 1 ? true : false
  const [selectedImage, setSelectedImage] = useState(null);

  const images = [
    {url: "https://storage.googleapis.com/smartdash-v2-content/digital-marketing-images/exec%20summary%201.png", caption: "Executive Summary - Performance"},
    {url: "https://storage.googleapis.com/smartdash-v2-content/digital-marketing-images/exec%20summary%202.png", caption: "Trends by Source"},
    {url: "https://storage.googleapis.com/smartdash-v2-content/digital-marketing-images/exec%20summary%203.png", caption: "Metric Comparison"},
    {url: "https://storage.googleapis.com/smartdash-v2-content/digital-marketing-images/exec%20summary%204.png", caption: "Perfomance by Device"},
    {url: "https://storage.googleapis.com/smartdash-v2-content/digital-marketing-images/performance%20tools.png", caption: "Performance Table"},
    {url: "https://storage.googleapis.com/smartdash-v2-content/digital-marketing-images/touches%20and%20days.png", caption: "Touches & Days"},
    {url: "https://storage.googleapis.com/smartdash-v2-content/digital-marketing-images/conversion%20log.png", caption: "Conversion Log"},
    {url: "https://storage.googleapis.com/smartdash-v2-content/digital-marketing-images/real%20time%20map.png", caption: "Real Time Map"},
    {url: "https://storage.googleapis.com/smartdash-v2-content/digital-marketing-images/cohorts.png", caption: "Cohorts"},
  ];

  const handleClick = (image) => {
    if (selectedImage === image) {
      setSelectedImage(null); // Close if same image clicked
    } else {
      setSelectedImage(image); // Open new image
    }
  };
  return (
    <div>
        {!currentUserLite && JSON.parse(localStorage.getItem('currentUser'))[0].db_owner === "FREEPEOPLE" ?

        <div>
            <Typography className={classes.title}> Digital Campaigns</Typography>
            <Typography className={classes.subTitle}> Coming soon</Typography>
            <Box className={classes.root}>
                {images.map((image, index) => (
                    <Box className={classes.imageItem}>
                            <img
                            key={index}
                            src={image.url}
                            alt={`Thumbnail ${index + 1}`}
                            className={classes.thumbnail}
                            onClick={() => handleClick(image.url)}
                        />
                        <Typography className={classes.imageCaption}>{image.caption}</Typography>
                    </Box>
                ))}
            </Box>

            {selectedImage && (
            <>
                <div className={classes.backdrop} onClick={() => setSelectedImage(null)} />
                <img
                    src={selectedImage}
                    alt="Enlarged"
                    className={classes.enlargedImage}
                    onClick={() => setSelectedImage(null)}
                />
            </>
            )}
        </div>
      
      :
      <div className={classes.unavailable}>
             DIGITAL COMING SOON
            </div>
      }
      </div>
  )
}
