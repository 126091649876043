import React, { Component } from 'react'
import {
  withStyles,
  Paper,
  Typography,
  Divider,
  Tooltip,
} from '@material-ui/core'
import moment from 'moment'
import { InfoOutlined } from '@material-ui/icons'

const styles = theme => ({
  statCard: {
    width: 200,
    height: 150
  },
  header: {
    marginTop: '25px',
    fontWeight: 'bold',
    // fontSize: '15px'
  },
  subheader: {
    fontWeight: 'bold',
    fontSize: '11px',
  },
  statNumber: {
    fontSize: '25px',
    fontWeight: 'bold',
  },
  ageRange: {
    fontSize: '15px',
    fontWeight: 'bold'
  },
  tooltip: {
    maxWidth: 200,
    textAlign: 'left',
    lineHeight: 1.6,
    fontSize: '11px',
  },
  help: {
    marginTop: 5,
    marginRight: 5,
    float: 'right',
    fontSize: 13,
    color: 'rgba(75, 75, 75, 0.5)'
  },
  helpDark: {
    marginTop: 5,
    marginRight: 5,
    float: 'right',
    fontSize: 13,
    color: 'rgba(255, 255, 255, 0.5)'
  },
})


class Stats extends Component {
  constructor(props) {
    super(props)
    console.log(props.metricData)

    this.state = {

    }
  }

  render() {
    const { classes } = this.props
    const totalCust = this.props.data.map(item => item.Data.map(item => item).map(item => item.Customers).reduce((a,b) => a + b, 0)).reduce((a,b) => a + b, 0)
    const uniqueDel = this.props.data.map(item => item.Data.map(item => item).map(item => item.UniqueDel).reduce((a,b) => a + b, 0)).reduce((a,b) => a + b, 0)
    const uniqueAppends = this.props.data.map(item => item.Data.map(item => item).map(item => item.Appends).reduce((a,b) => a + b, 0)).reduce((a,b) => a + b, 0)
    const currentYear = moment().diff(moment().startOf('year'), 'months') ? this.props.data.map(item => item.Data.filter(item => item.Year === moment().format('YYYY'))[0]) : this.props.data.map(item => item.Data.filter(item => item.Year === moment().subtract(1, 'years').format('YYYY'))[0])
    const lastYear = moment().diff(moment().startOf('year'), 'months') ? this.props.data.map(item => item.Data.filter(item => item.Year === moment().subtract(1, 'years').format('YYYY'))[0]) : this.props.data.map(item => item.Data.filter(item => item.Year === moment().subtract(2, 'years').format('YYYY'))[0])
    const lastTwoYear = moment().diff(moment().startOf('year'), 'months') ? this.props.data.map(item => item.Data.filter(item => item.Year === moment().subtract(2, 'years').format('YYYY'))[0]) : this.props.data.map(item => item.Data.filter(item => item.Year === moment().subtract(3, 'years').format('YYYY'))[0])
    const lastThreeYear = moment().diff(moment().startOf('year'), 'months') ? this.props.data.map(item => item.Data.filter(item => item.Year === moment().subtract(3, 'years').format('YYYY'))[0]) : this.props.data.map(item => item.Data.filter(item => item.Year === moment().subtract(4, 'years').format('YYYY'))[0])
    const avgCurrentHigh = currentYear[0] ? currentYear.map(item => item.Age_High).filter(item => item !== "").reduce((a, b) => a + b, 0) / currentYear.map(item => item.Age_High).filter(item => item !== "").length : 0
    const avgCurrentLow = currentYear[0] ? currentYear.map(item => item.Age_Low).filter(item => item !== "").reduce((a, b) => a + b, 0) / currentYear.map(item => item.Age_Low).filter(item => item !== "").length : 0
    const avgCurrentVar = currentYear[0] ? currentYear.map(item => item.Var_Age).filter(item => item !== "").reduce((a,b) => a + b, 0) / currentYear.map(item => item.Var_Age).filter(item => item !== "").length : 0
    const currentAppends = currentYear[0] ? currentYear.map(item => item.Appends).filter(item => item !== "").reduce((a,b) => a + b, 0) : 0
    const avgLastHigh = lastYear[0] ? lastYear.map(item => item.Age_High).filter(item => item !== "").reduce((a, b) => a + b, 0) / lastYear.map(item => item.Age_High).filter(item => item !== "").length : 0
    const avgLastLow = lastYear[0] ? lastYear.map(item => item.Age_Low).filter(item => item !== "").reduce((a, b) => a + b, 0) / lastYear.map(item => item.Age_Low).filter(item => item !== "").length : 0
    const avgLastVar = lastYear[0] ? lastYear.map(item => item.Var_Age).filter(item => item !== "").reduce((a,b) => a + b, 0) / lastYear.map(item => item.Var_Age).filter(item => item !== "").length : 0
    const lastAppends = lastYear[0] ? lastYear.map(item => item.Appends).filter(item => item !== "").reduce((a,b) => a + b, 0) : 0

    const avgTwoHigh = lastTwoYear[0] ? lastTwoYear.map(item => item.Age_High).filter(item => item !== "").reduce((a, b) => a + b, 0) / lastTwoYear.map(item => item.Age_High).filter(item => item !== "").length : 0
    const avgTwoLow = lastTwoYear[0] ? lastTwoYear.map(item => item.Age_Low).filter(item => item !== "").reduce((a, b) => a + b, 0) / lastTwoYear.map(item => item.Age_Low).filter(item => item !== "").length : 0
    const avgTwoVar = lastTwoYear[0] ? lastTwoYear.map(item => item.Var_Age).filter(item => item !== "").reduce((a,b) => a + b, 0) / lastTwoYear.map(item => item.Var_Age).filter(item => item !== "").length : 0
    const twoAppends = lastTwoYear[0] ? lastTwoYear.map(item => item.Appends).filter(item => item !== "").reduce((a,b) => a + b, 0) : 0
    const avgThreeHigh = lastThreeYear[0] ? lastThreeYear.map(item => item.Age_High).filter(item => item !== "").reduce((a, b) => a + b, 0) / lastThreeYear.map(item => item.Age_High).filter(item => item !== "").length : 0
    const avgThreeLow = lastThreeYear[0] ? lastThreeYear.map(item => item.Age_Low).filter(item => item !== "").reduce((a, b) => a + b, 0) / lastThreeYear.map(item => item.Age_Low).filter(item => item !== "").length : 0
    const avgThreeVar = lastThreeYear[0] ? lastThreeYear.map(item => item.Var_Age).filter(item => item !== "").reduce((a,b) => a + b, 0) / lastThreeYear.map(item => item.Var_Age).filter(item => item !== "").length : 0
    const threeAppends = lastThreeYear[0] ? lastThreeYear.map(item => item.Appends).filter(item => item !== "").reduce((a,b) => a + b, 0) : 0

    return(
      <div>
        <div style={{display: 'flex', textAlign: 'center'}}>
          <Paper className={classes.statCard} style={{border: '1px solid rgba(212, 120, 0, 0.3)'}}>
              <Tooltip classes={{tooltip: classes.tooltip}} title="Number of CASS-verified addresses within the trade area">
                <InfoOutlined fontSize="small" className={this.props.darkMode ? classes.helpDark : classes.help} />
              </Tooltip>
              <Typography
                display="block"
                variant="caption"
                className={classes.header}
                style={{color: '#d47800'}}
              >
                UNIQUE DELIVERABLE ADDRESSES
              </Typography>
            <Typography
              display="block"
              variant="caption"
              className={classes.statNumber}
              style={{marginTop: 15}}
            >
              {uniqueDel.toLocaleString('en')}
            </Typography>
          </Paper>
          <Paper className={classes.statCard} style={this.props.darkMode ? {marginLeft: '15px', border: '1px solid rgba(167, 96, 219, 0.4)'} : {marginLeft: '15px', border: '1px solid rgba(93, 46, 128, 0.2)'}}>
            <Tooltip classes={{tooltip: classes.tooltip}} title="Percent of all addresses that are deliverable">
              <InfoOutlined fontSize="small" className={this.props.darkMode ? classes.helpDark : classes.help} />
            </Tooltip>
            <Typography
              display="block"
              variant="caption"
              className={classes.header}
              style={this.props.darkMode ? {color: '#a760db'} : {color: '#5d2e80'}}
            >
              GOOD ADDRESS RATE
            </Typography>
            <Typography
              display="block"
              variant="caption"
              className={classes.statNumber}
              style={{marginTop: 15}}
            >
              {/* 92.8% */}
              {(uniqueDel / totalCust * 100).toFixed(1) + '%'}
            </Typography>
          </Paper>
        </div>
        <div style={{display: 'flex', marginTop: '15px', textAlign: 'center'}}>
          <Paper className={classes.statCard} style={{border: '1px solid rgba(201, 182, 0, 0.3)'}}>
            <Tooltip classes={{tooltip: classes.tooltip}} title="Number of addresses with available demographic data">
              <InfoOutlined fontSize="small" className={this.props.darkMode ? classes.helpDark : classes.help} />
            </Tooltip>
            <Typography
              display="block"
              variant="caption"
              className={classes.header}
              style={{color: '#c9b600'}}
            >
              UNIQUE APPENDS
            </Typography>
            <Typography
              display="block"
              variant="caption"
              className={classes.statNumber}
              style={{marginTop: 25}}
            >
              {/* 434,738 */}
              {uniqueAppends.toLocaleString('en')}
            </Typography>
          </Paper>
          <Paper className={classes.statCard} style={{marginLeft: '15px', border: '1px solid rgba(82, 156, 0, 0.3)'}}>
            <Tooltip classes={{tooltip: classes.tooltip}} title="Percent of all addresses that have available demographic data">
              <InfoOutlined fontSize="small" className={this.props.darkMode ? classes.helpDark : classes.help} />
            </Tooltip>
            <Typography
              display="block"
              variant="caption"
              className={classes.header}
              style={{color: '#529c00'}}
            >
              UNIQUE MATCH RATE
            </Typography>
            <Typography
              display="block"
              variant="caption"
              className={classes.statNumber}
              style={{marginTop: 25}}
            >
              {/* 45.4% */}
              {(uniqueAppends / totalCust * 100).toFixed(1) + '%'}
            </Typography>
          </Paper>
        </div>
        <div style={{display: 'flex', marginTop: '15px', textAlign: 'center',}}>
          <Paper className={classes.statCard} style={this.props.darkMode ? {border: '1px solid rgba(17, 141, 242, 0.3)'} : {border: '1px solid rgba(0, 108, 196, 0.3)'}}>
            <Tooltip classes={{tooltip: classes.tooltip}} title={`Average age and household income of customers during ${moment().format('YYYY')}`}>
              <InfoOutlined fontSize="small" className={this.props.darkMode ? classes.helpDark : classes.help} />
            </Tooltip>
            <Typography
              display="block"
              variant="caption"
              className={classes.header}
              style={this.props.darkMode ? {color: '#118df2'} : {color: '#006cc4'}}
            >
              {moment().diff(moment().startOf('year'), 'months') >= 3 ? moment().format('YYYY') : moment().subtract(1, 'years').format('YYYY')}
            </Typography>
            <div style={{display: 'flex', justifyContent: 'space-evenly', marginTop: 25}}>
              <div>
                <Typography variant="caption" className={classes.subheader}>
                  AVERAGE AGE
                </Typography>
                <Typography display="block" variant="caption" className={classes.ageRange}>
                
                  {this.props.metricData.filter(item => item.MetricType === 'Age (of householder)') ?
                    moment().diff(moment().startOf('year'), 'months') >= 3 ?                    
                    this.props.metricData.filter(item => item.MetricType === 'Age (of householder)')[0].Data.filter(item => item.Year === moment().format('YYYY')).length > 0 ?
                      this.props.metricData.filter(item => item.MetricType === 'Age (of householder)')[0].Data.filter(item => item.Year === moment().format('YYYY'))[0].Cust_Average
                      : 'NA'
                    :
                    // console.log(Boolean(this.props.metricData.filter(item => item.MetricType === 'Age (of householder)')[0].Data.filter(item => item.Year === moment().format('YYYY'))))
                    this.props.metricData.filter(item => item.MetricType === 'Age (of householder)')[0].Data.filter(item => item.Year === moment().subtract(1, 'years').format('YYYY'))[0].Cust_Average            
                  :
                  'N/A'
                  }
                  {/* {avgCurrentLow > 0 ?
                    (avgCurrentLow - (1.96 * Math.sqrt(avgCurrentVar) / Math.sqrt(currentAppends))).toFixed(1) + ' - ' + (avgCurrentHigh + (1.96 * Math.sqrt(avgCurrentVar) / Math.sqrt(currentAppends))).toFixed(1)
                  :
                    'N/A'
                  } */}
                </Typography>
              </div>
              <div style={{borderLeft: '0.1px solid'}} />
              <div>
                <Typography variant="caption" className={classes.subheader}>
                  AVERAGE HHI
                </Typography>
                <Typography display="block" variant="caption" className={classes.ageRange}>
                  {this.props.metricData.filter(item => item.MetricType === 'HH Income') ?
                    moment().diff(moment().startOf('year'), 'months') >= 3 ?
                    this.props.metricData.filter(item => item.MetricType === 'HH Income')[0].Data.filter(item => item.Year === moment().format('YYYY')).length > 0 ?
                      '$' + this.props.metricData.filter(item => item.MetricType === 'HH Income')[0].Data.filter(item => item.Year === moment().format('YYYY'))[0].Cust_Average.toLocaleString('en', {minimumFractionDigits: 2})
                      :
                      'N/A'
                    :
                      '$' + this.props.metricData.filter(item => item.MetricType === 'HH Income')[0].Data.filter(item => item.Year === moment().subtract(1, 'years').format('YYYY'))[0].Cust_Average.toLocaleString('en', {minimumFractionDigits: 2})
                  :
                    'N/A'
                  }
                  {/* $82,167 */}
                  {/* {currentYear[0] ?
                    '$' +
                    Math.round(currentYear.map(item => item.Med_HHI).filter(item => item !== "").reduce((a,b) => a + b, 0) /
                    currentYear.map(item => item.Med_HHI).filter(item => item !== "").length).toLocaleString('en')
                  :
                    'N/A'
                  } */}
                </Typography>
              </div>
            </div>
          </Paper>
          <Paper className={classes.statCard} style={{marginLeft: '15px', border: '1px solid rgba(242, 79, 239, 0.3)'}}>
            <Tooltip classes={{tooltip: classes.tooltip}} title={`Average age and household income of customers during ${moment().subtract(1, 'years').format('YYYY')}`}>
              <InfoOutlined fontSize="small" className={this.props.darkMode ? classes.helpDark : classes.help} />
            </Tooltip>
            <Typography
              display="block"
              variant="caption"
              className={classes.header}
              style={{color: '#f24bef'}}
            >
              {moment().diff(moment().startOf('year'), 'months') >= 3 ? moment().subtract(1, 'years').format('YYYY') : moment().subtract(2, 'years').format('YYYY')}
            </Typography>
            <div style={{display: 'flex', justifyContent: 'space-evenly', marginTop: 25}}>
              <div>
                <Typography variant="caption" className={classes.subheader}>
                  AVERAGE AGE
                </Typography>
                <Typography display="block" variant="caption" className={classes.ageRange}>
                  {this.props.metricData.filter(item => item.MetricType === 'Age (of householder)') ?
                    moment().diff(moment().startOf('year'), 'months') >= 3 ?
                      this.props.metricData.filter(item => item.MetricType === 'Age (of householder)')[0].Data.filter(item => item.Year === moment().subtract(1, 'years').format('YYYY'))[0].Cust_Average
                    :
                      this.props.metricData.filter(item => item.MetricType === 'Age (of householder)')[0].Data.filter(item => item.Year === moment().subtract(2, 'years').format('YYYY'))[0].Cust_Average
                  :
                    'N/A'
                  }
                  {/* 46.0 - 56.6 */}
                  {/* {avgLastLow > 0 ?
                    (avgLastLow - (1.96 * Math.sqrt(avgLastVar) / Math.sqrt(lastAppends))).toFixed(1) + ' - ' + (avgLastHigh + (1.96 * Math.sqrt(avgLastVar) / Math.sqrt(lastAppends))).toFixed(1)
                  :
                    'N/A'
                  } */}
                </Typography>
              </div>
              <div style={{borderLeft: '0.1px solid'}} />
              <div>
                <Typography variant="caption" className={classes.subheader}>
                  AVERAGE HHI
                </Typography>
                <Typography display="block" variant="caption" className={classes.ageRange}>
                  {this.props.metricData.filter(item => item.MetricType === 'HH Income') ?
                    moment().diff(moment().startOf('year'), 'months') >= 3 ?
                      '$' + this.props.metricData.filter(item => item.MetricType === 'HH Income')[0].Data.filter(item => item.Year === moment().subtract(1, 'years').format('YYYY'))[0].Cust_Average.toLocaleString('en', {minimumFractionDigits: 2})
                    :
                      '$' + this.props.metricData.filter(item => item.MetricType === 'HH Income')[0].Data.filter(item => item.Year === moment().subtract(2, 'years').format('YYYY'))[0].Cust_Average.toLocaleString('en', {minimumFractionDigits: 2})
                  :
                    'N/A'
                  }
                  {/* {lastYear[0] ?
                    '$' +
                    Math.round(lastYear.map(item => item.Med_HHI).filter(item => item !== "").reduce((a,b) => a + b, 0) /
                    lastYear.map(item => item.Med_HHI).filter(item => item !== "").length).toLocaleString('en')
                  :
                    'N/A'
                  } */}
                </Typography>
              </div>
            </div>
          </Paper>
        </div>
        <div style={{display: 'flex', marginTop: '15px', textAlign: 'center'}}>
          <Paper className={classes.statCard} style={{border: '1px solid rgba(72, 189, 163, 0.3)'}}>
            <Tooltip classes={{tooltip: classes.tooltip}} title={`Average age and household income of customers during ${moment().subtract(2, 'years').format('YYYY')}`}>
              <InfoOutlined fontSize="small" className={this.props.darkMode ? classes.helpDark : classes.help} />
            </Tooltip>
            <Typography
              display="block"
              variant="caption"
              className={classes.header}
              style={{color: '#48bda3'}}
            >
              {moment().diff(moment().startOf('year'), 'months') >= 3 ? moment().subtract(2, 'years').format('YYYY') : moment().subtract(3, 'years').format('YYYY')}
            </Typography>
            <div style={{display: 'flex', justifyContent: 'space-evenly', marginTop: 25}}>
              <div>
                <Typography variant="caption" className={classes.subheader}>
                  AVERAGE AGE
                </Typography>
                <Typography display="block" variant="caption" className={classes.ageRange}>
                    {this.props.metricData.filter(item => item.MetricType === 'Age (of householder)') ?
                    moment().diff(moment().startOf('year'), 'months') >= 3 ?
                      this.props.metricData.filter(item => item.MetricType === 'Age (of householder)')[0].Data.filter(item => item.Year === moment().subtract(2, 'years').format('YYYY'))[0].Cust_Average
                    :
                      this.props.metricData.filter(item => item.MetricType === 'Age (of householder)')[0].Data.filter(item => item.Year === moment().subtract(3, 'years').format('YYYY'))[0].Cust_Average
                  :
                    'N/A'
                  }
                  {/* 49.2 - 50.9 */}
                  {/* {avgTwoLow > 0 ?
                    (avgTwoLow - (1.96 * Math.sqrt(avgTwoVar) / Math.sqrt(twoAppends))).toFixed(1) + ' - ' + (avgTwoHigh + (1.96 * Math.sqrt(avgTwoVar) / Math.sqrt(twoAppends))).toFixed(1)
                  :
                    'N/A'
                  } */}
                </Typography>
              </div>
              <div style={{borderLeft: '0.1px solid'}} />
              <div>
                <Typography variant="caption" className={classes.subheader}>
                  AVERAGE HHI
                </Typography>
                <Typography display="block" variant="caption" className={classes.ageRange}>
                  {this.props.metricData.filter(item => item.MetricType === 'HH Income') ?
                    moment().diff(moment().startOf('year'), 'months') >= 3 ?
                      '$' + this.props.metricData.filter(item => item.MetricType === 'HH Income')[0].Data.filter(item => item.Year === moment().subtract(2, 'years').format('YYYY'))[0].Cust_Average.toLocaleString('en', {minimumFractionDigits: 2})
                    :
                      '$' + this.props.metricData.filter(item => item.MetricType === 'HH Income')[0].Data.filter(item => item.Year === moment().subtract(3, 'years').format('YYYY'))[0].Cust_Average.toLocaleString('en', {minimumFractionDigits: 2})
                  :
                    'N/A'
                  }
                  {/* {lastTwoYear[0] ?
                    '$' +
                    Math.round(lastTwoYear.map(item => item.Med_HHI).filter(item => item !== "").reduce((a,b) => a + b, 0) /
                    lastTwoYear.map(item => item.Med_HHI).filter(item => item !== "").length).toLocaleString('en')
                  :
                    'N/A'
                  } */}
                </Typography>
              </div>
            </div>
          </Paper>
          <Paper className={classes.statCard} style={{marginLeft: '15px', border: '1px solid rgba(227, 159, 86, 0.3)'}}>
            <Tooltip classes={{tooltip: classes.tooltip}} title={`Average age and household income of customers during ${moment().subtract(3, 'years').format('YYYY')}`}>
              <InfoOutlined fontSize="small" className={this.props.darkMode ? classes.helpDark : classes.help} />
            </Tooltip>
            <Typography
              display="block"
              variant="caption"
              className={classes.header}
              style={{color: '#e39f56'}}
            >
              {moment().diff(moment().startOf('year'), 'months') >= 3 ? moment().subtract(3, 'years').format('YYYY') : moment().subtract(4, 'years').format('YYYY')}
            </Typography>
            <div style={{display: 'flex', justifyContent: 'space-evenly', marginTop: 25}}>
              <div>
                <Typography variant="caption" className={classes.subheader}>
                  AVERAGE AGE
                </Typography>
                <Typography display="block" variant="caption" className={classes.ageRange}>

                  {this.props.metricData.filter(item => item.MetricType === 'Age (of householder)') ?
                    moment().diff(moment().startOf('year'), 'months') >= 3 ?
                      this.props.metricData.filter(item => item.MetricType === 'Age (of householder)')[0].Data.filter(item => item.Year === moment().subtract(3, 'years').format('YYYY'))[0].Cust_Average
                    :
                      this.props.metricData.filter(item => item.MetricType === 'Age (of householder)')[0].Data.filter(item => item.Year === moment().subtract(4, 'years').format('YYYY'))[0].Cust_Average
                  :
                    'N/A'
                  }
                  {/* 49.4 - 50.9 */}
                  {/* {avgThreeLow > 0 ?
                    (avgThreeLow - (1.96 * Math.sqrt(avgThreeVar) / Math.sqrt(threeAppends))).toFixed(1) + ' - ' + (avgThreeHigh + (1.96 * Math.sqrt(avgThreeVar) / Math.sqrt(threeAppends))).toFixed(1)
                  :
                    'N/A'
                  } */}

                </Typography>
              </div>
              <div style={{borderLeft: '0.1px solid'}} />
              <div>
                <Typography variant="caption" className={classes.subheader}>
                  AVERAGE HHI
                </Typography>
                <Typography display="block" variant="caption" className={classes.ageRange}>

                  {this.props.metricData.filter(item => item.MetricType === 'HH Income') ?
                    moment().diff(moment().startOf('year'), 'months') >= 3 ?
                      '$' + this.props.metricData.filter(item => item.MetricType === 'HH Income')[0].Data.filter(item => item.Year === moment().subtract(3, 'years').format('YYYY'))[0].Cust_Average.toLocaleString('en', {minimumFractionDigits: 2})
                    :
                      '$' + this.props.metricData.filter(item => item.MetricType === 'HH Income')[0].Data.filter(item => item.Year === moment().subtract(4, 'years').format('YYYY'))[0].Cust_Average.toLocaleString('en', {minimumFractionDigits: 2})
                  :
                    'N/A'
                  }
                  {/* $75,221 */}
                  {/* {lastThreeYear[0] ?
                    '$' + Math.round(lastThreeYear.map(item => item.Med_HHI).filter(item => item !== "").reduce((a,b) => a + b, 0) /
                    lastThreeYear.map(item => item.Med_HHI).filter(item => item !== "").length).toLocaleString('en')
                  :
                    'N/A'
                  } */}

                </Typography>
              </div>
            </div>
          </Paper>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(Stats)
