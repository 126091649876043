import React, { Component } from 'react'
import {
  withStyles,
  Typography,
  Card,
  CardContent,
  CardMedia,
  CardHeader,
  CircularProgress,
} from '@material-ui/core'
import { Assignment } from '@material-ui/icons'
import Cookies from 'js-cookie'
import ProdPerfChart from './ProdPerfChart'
import SalesMultiplierTable from './SalesMultiplierTable'
import RecBudget from './RecBudget'
import prodPerf from '../../assets/mainStandIns/prodPerf.png'
import AskReport from '../AskReport'

const styles = theme => ({
  loader: {
    marginTop: '25%',
    marginLeft: '50%'
  },
  pageTitle: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '17px',
    fontWeight: 'light',
    color: 'rgba(115, 115, 115, 0.9)'
  },
  pageTitleDark: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '17px',
    fontWeight: 'light',
    color: 'rgba(255, 255, 255, 0.9)'
  },
  pageIcon: {
    fontSize: '15px',
    marginTop: '21px',
    marginRight: '2px',
  },
  pageIconDark: {
    fontSize: '15px',
    color: 'rgba(255, 255, 255, 0.9)',
    marginTop: '5px',
    marginRight: '2px',
  },
  card: {
    [theme.breakpoints.up('xl')]: {
      height: 200
    },
    [theme.breakpoints.down('lg')]: {
      height: 225
    }
  },
  cardMedia: {
    height: 50,
    backgroundColor: '#0074cb',
  },
  unavailable: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // margin: '2em'
  },
})

class ProductPerformance extends Component {
  _isMounted = false

  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      prodPerformanceData: [],
      user: [],
      hasData: false,
    }
  }

  componentDidMount = () => {
    this._isMounted = true
    Promise.all([
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/prodPerformance`, {
        headers: {
          token: Cookies.get('token')
        }
      }),
      fetch('https://api.dev.lsdirect.com/smartdash/V1/index.cfm/user', {
        headers: {
          token: Cookies.get('token')
        }
      })
    ])
    .then(([response, response2]) => Promise.all([response.json(), response2.json()]))
    .then(([data, data2]) => {
      if(this._isMounted && data) {
        this.setState({
          isLoading: false,
          prodPerformanceData: data,
          user: data2,
          hasData: true,
        })
      } else {
        this.setState({
          isLoading: false,
          prodPerformanceData: [],
          user: data2
        })
      }
    })
    .catch(error => {
      console.log(error)
    })

    if(JSON.parse(localStorage.getItem('currentUser'))[0].username.toUpperCase().includes('USER')) {
    } else {
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/pageviews/add?display=product_performance&screen_name=product_performance`, {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'token': Cookies.get('token'),
        },
      })
      .then(response => {
        if(response.ok === true) {
          return response.json()
        }
      })
      .catch(error => {
        console.log(error)
      })      
    }
  }

  render() {
    const { classes } = this.props
    const holdoutRR = this.state.prodPerformanceData.map(item => (item.Data[0].HO_ResRate * 100).toFixed(2))
    const adjRR = this.state.prodPerformanceData.map(item => (item.Data[0].Adj_ResRate * 100).toFixed(2))
    const pctBudj = this.state.prodPerformanceData.map(item => (item.Data[0].Pct_Budget * 100).toFixed(2))
    const pctRolling = this.state.prodPerformanceData.map(item => (item.Data[0].Pct_Rolling * 100).toFixed(2))
    const currentUserLite = JSON.parse(localStorage.getItem('currentUser')) && JSON.parse(localStorage.getItem('currentUser'))[0].sd_lite === 1 ? true : false
    const series = [
      {
        name: 'Yearly Holdout Response Rate',
        data: holdoutRR.map(Number),
        type: 'column',
      },
      {
        name: 'Yearly Adjusted Response Rate',
        data: adjRR.map(Number),
        type: 'column',
      },
      {
        name: 'Percent Rolling',
        data: pctRolling.map(Number),
        type: 'line',
      },
      // {
      //   name: 'Percent Budget',
      //   data: pctBudj,
      //   type: 'line',
      // },
    ]

    const colors = [
      '#8042c5',
      '#c3a0eb',
      '#00ca98'
    ]

    return(
      <div>
        {this.state.isLoading ? <div className={classes.loader}><CircularProgress /></div> :
          this.state.hasData && !currentUserLite?
            <div>
              <div>
                <Typography className={this.props.darkMode ? classes.pageTitleDark : classes.pageTitle} style={{marginTop: '25px'}}>
                  <br/>
                  <Assignment className={this.props.darkMode ? classes.pageIconDark : classes.pageIcon}/>
                  <span style={{marginLeft: '5px'}}>Product Performance Summary</span>
                </Typography>
              </div>
              <br/>
              <div>
                <Card className={classes.card}>
                  {/* <CardMedia className={classes.cardMedia} /> */}
                  <CardHeader
                    className={classes.cardMedia}
                    title={
                      <div style={{textAlign: 'center'}}>
                        <Typography variant="body2" style={{color: '#fff', fontWeight: 'bold'}}>Percentage Of Recommended Budget</Typography>
                      </div>
                    }
                  />
                  <RecBudget data={this.state.prodPerformanceData}/>
                </Card>
                <br />
                <Card style={{ padding: '2em' }}>
                  <ProdPerfChart
                    labels={this.state.prodPerformanceData.map(item => item.ProductName)}
                    series={series}
                    height={500}
                    colors={colors}
                    darkMode={this.props.darkMode}
                  />
                </Card>
                <br/>
                <Card className={classes.card}>
                  {/* <CardMedia className={classes.cardMedia} /> */}
                  <CardHeader
                    className={classes.cardMedia}
                    title={
                      <div style={{textAlign: 'center'}}>
                        <Typography variant="body2" style={{color: '#fff', fontWeight: 'bold'}}>Sales Multiplier (Incremental ROAS)</Typography>
                      </div>
                    }
                  />
                  <SalesMultiplierTable data={this.state.prodPerformanceData}/>
                </Card>
              </div>
            </div>
            : <div className={classes.unavailable} >
                <AskReport />
              </div>
        }
      </div>
    )
  }
}

export default withStyles(styles)(ProductPerformance)
