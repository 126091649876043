import React, { Component, Fragment } from 'react'
import {
  withStyles,
  Typography,
  Grid,
  Select,
  Divider,
  FormControl,
  MenuItem,
  Paper,
  InputLabel,
  CircularProgress,
  ListItem,
  ListItemText,
  ListItemIcon,
  List,
  Checkbox,
  Slider,
  Snackbar,
  SnackbarContent,
} from '@material-ui/core'
import PublicIcon from '@material-ui/icons/Public'
import WarningIcon from '@material-ui/icons/Warning'
import BarChart from '../charts/BarChart2'
import Cookies from 'js-cookie'
import MonthPicker from '../calendar/MonthPicker'
import DemoExcel from './DemoExcel'
import AskReport from '../AskReport'

import moment from 'moment'

const styles = theme => ({
  mainLoader: {
    marginTop: '25%',
    marginLeft: '50%'
  },
  pageTitle: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '17px',
    fontWeight: 'light',
    color: 'rgba(115, 115, 115, 0.9)'
  },
  pageTitleDark: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '17px',
    fontWeight: 'light',
    color: 'rgba(255, 255, 255, 0.9)'
  },
  pageIcon: {
    fontSize: '15px',
    marginTop: '21px',
    marginRight: '2px',
  },
  pageIconDark: {
    fontSize: '15px',
    color: 'rgba(255, 255, 255, 0.9)',
    marginTop: '5px',
    marginRight: '2px',
  },
  select: {
    width: '200px'
  },
  chart: {
    width: '85%',
    padding: '2em',
    height: 800,
  },
  formControl: {
    margin: theme.spacing(1),
    width: 250,
  },
  list: {
    margin: theme.spacing(1),
    border: '1px solid #c4c4c4',
    borderRadius: 5,
    width: 250,
    // height: 275,
    overflowY: 'auto'
  },
  listLabel: {
    margin: theme.spacing(1),
    color: '#757575',
  },
  listLabelDark: {
    margin: theme.spacing(1),
    color: '#fff',
  },
  checkbox: {
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&:focus': {
      backgroundColor: 'transparent'
    }
  },
  loader: {
    marginLeft: '42.5%',
    marginTop: '42.5%'
  },
  datePicker: {
    height: 60,
    border: '1px solid #c4c4c4',
    borderRadius: 5,
    width: '94%',
    paddingTop: '15px',
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  unavailable: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // margin: '2em'
  },
})

class Demographics extends Component {
  _isMounted = false

  constructor() {
    super()
    this.state = {
      isLoading: true,
      chartLoading: true,
      dataLoad: true,
      demoData: [],
      demoDataByLoc: [],
      selectedData: [],
      selectedDataLength: 0,
      selectedMetric: 'Age Range',
      selectedLocationType: 'market',
      metricValues: [],
      checked: [],
      checkList: [],
      allChecked: true,
      months: moment().diff(moment().startOf('year'), 'months') > 3 ? moment().diff(moment().subtract(1, 'years'), 'months') : moment().diff(moment().subtract(1, 'years'), 'months'),
      maxYearmo: moment(),
      initialMaxYearmo: moment(),
      minYearmo: moment().diff(moment().startOf('year'), 'months') > 3 ? moment().subtract(1, 'years').startOf('year') : moment().subtract(1, 'years').startOf('year'),
      initialMinYearmo: moment().diff(moment().startOf('year'), 'months') > 3 ? moment().subtract(1, 'years').startOf('year') : moment().subtract(1, 'years').startOf('year'),
      snackbar: false,
      errorMessage: ''
    }
  }

  componentDidMount = () => {
    this._isMounted = true

    console.log(this.state.initialMinYearmo)

    Promise.all([
      fetch('https://api.dev.lsdirect.com/smartdash/V1/index.cfm/demoByLoc', {
        method: 'GET',
        headers: {
          'token': Cookies.get('token')
        },
      }),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/demoByLoc/market?min_yearmo=${this.state.initialMinYearmo.format('YYYY-MM')}&max_yearmo=${this.state.initialMaxYearmo.format('YYYY-MM')}`, {
        method: 'GET',
        headers: {
          'token': Cookies.get('token')
        },
      }),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/markets?active=1`, {
        headers: {
          'token': Cookies.get('token')
        },
      }),
    ])
    .then(([response, response2, response3]) => Promise.all([response.json(), response2.json(), response3.json()]))
    .then(([data, data2, data3]) => {
      console.log(data)
      console.log(data3)
      console.log(data2.length)

      if(this._isMounted && data2) {
        this.setState({
          dataLoad: false,
          isLoading: false,
          chartLoading: false,
          demoData: data,
          demoDataByLoc: data2,
          selectedData: data2.map(item => item.Data.filter(item => item.MetricType === this.state.selectedMetric)),
          selectedDataLength: data2.map(item => item.Data.filter(item => item.MetricType === this.state.selectedMetric)).length,
          metricValues: data2.map(item => item.Data.filter(item => item.MetricType === this.state.selectedMetric)).map(item => item[0].Data.map(item => item.MetricValue))[0],
          checked: data2.map(item => item.Label),
          checkList: data3,
        })
      } else {
        this.setState({
          isLoading: false,
          demoDataByLoc: []
        })
      }
    })
    .catch(error => {
      console.log(error)
    })

    if(JSON.parse(localStorage.getItem('currentUser'))[0].username.toUpperCase().includes('USER')) {
    } else {
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/pageviews/add?display=demo_by_loc&screen_name=demo_by_loc`, {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'token': Cookies.get('token'),
        },
      })
      .then(response => {
        if(response.ok === true) {
          return response.json()
        }
      })
      .catch(error => {
        console.log(error)
      })
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  renderSelectOptions1 = () => {
    console.log(this.state.demoData)

    return(
      this.state.demoData.map((item, i) => {
        return (<MenuItem key={item.Key} value={item.MetricType}>{item.MetricType}</MenuItem>)
      })
    )
  }

  handleLocationTypeChange = e => {
    let locationType = e.target.value

    console.log(locationType)

    this.setState({
      dataLoad: true,
    })

    Promise.all([
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/demoByLoc/${locationType}?min_yearmo=${this.state.minYearmo.format('YYYY-MM')}&max_yearmo=${this.state.maxYearmo.format('YYYY-MM')}`, {
        headers: {
          'token': Cookies.get('token')
        },
      }),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/demoByLoc/${locationType}?min_yearmo=${moment().subtract(2, 'months').format('YYYY-MM')}&max_yearmo=${moment().format('YYYY-MM')}`, {
        headers: {
          'token': Cookies.get('token')
        },
      }),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/${locationType}s`, {
        headers: {
          'token': Cookies.get('token')
        }
      })
    ])
    .then(([response, response2, response3]) => Promise.all([response.json(), response2.json(), response3.json()]))
    .then(([data, data2, data3]) => {
      if(data) {
        let list = []

        for(let i = 0; i < data3.length; i++) {
          for(let j = 0; j < data.length; j++) {
            if(data3[i] === data[j].Label) {
              list.push(data3[i])
            }
          }
        }

        this.setState({
          dataLoad: false,
          demoDataByLoc: data,
          selectedData: data.map(item => item.Data.filter(item => item.MetricType === this.state.selectedMetric)),
          selectedDataLength: data.map(item => item.Data.filter(item => item.MetricType === this.state.selectedMetric)).length,
          selectedLocationType: locationType,
          checked: data.map(item => item.Label),
        })
      } else {
        this.setState({
          dataLoad: false,
          selectedData: [],
          demoDataByLoc: [],
          selectedLocationType: locationType,
          checked: data3,
        })
      }
    })
  }

  handleMetricChange = e => {
    let metricType = e.target.value

    this.setState({
      selectedMetric: metricType,
      selectedData: this.state.demoDataByLoc.filter(item => this.state.checked.includes(item.Label)).map(item => item.Data.filter(item => item.MetricType === metricType))
    })
  }

  handleToggleChecked = (e, row) => {
    let newChecked = [...this.state.checked]
    const currentIndex = this.state.checked.indexOf(row.Label)

    if(row === 'All') {
      let allData = []
      for(let i = 0; i < this.state.demoDataByLoc.length; i++) {
        allData.push(this.state.demoDataByLoc[i].Data.filter(item => item.MetricType === this.state.selectedMetric))
      }

      this.setState({
        allChecked: true,
        checked: this.state.demoDataByLoc.map(item => item.Label),
        selectedData: allData,
      })
    } else {
      let filteringRow = row.Data.filter(item => item.MetricType === this.state.selectedMetric)
      if(currentIndex === -1) {
        newChecked.push(row.Label)
        let arr = []
        arr.push(filteringRow)
        let joinedArr = this.state.selectedData.concat(arr)

        this.setState({
          selectedData: joinedArr
        })
      } else if(this.state.selectedData.length > 1 && !this.state.allChecked) {
        let filteringRow = row.Data.filter(item => item.MetricType === this.state.selectedMetric)
        newChecked = newChecked.filter(item => item !== row.Label)
        this.setState({
          selectedData: this.state.selectedData.filter(item => item[0] !== filteringRow[0])
        })
      } else {
        newChecked = newChecked.filter(item => item === row.Label)
        this.setState({
          selectedData: this.state.selectedData.filter(item => item[0] === filteringRow[0])
        })
      }

      this.setState({
        checked: newChecked,
        allChecked: false
      })
    }

    console.log(this.state.demoDataByLoc.length)
    console.log(this.state.demoDataByLoc)
    console.log(this.state.checked.length)
    console.log(this.state.selectedData.length)
  }

  handleDateRange = (value, valName) => {
    const myHeaders = {'token': Cookies.get('token')}
    let boomData = []

    this.setState({
      chartLoading: true
    })

    if(valName === 'minYearmo') {
      Promise.all([
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/demoByLoc/${this.state.selectedLocationType}?min_yearmo=${value.format('YYYY-MM')}&max_yearmo=${this.state.maxYearmo.format('YYYY-MM')}`, {
          headers: {
            'token': Cookies.get('token')
          },
        }),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/${this.state.selectedLocationType}s`, {
          headers: {
            'token': Cookies.get('token')
          },
        }),
      ])
      .then(([response, response2]) => Promise.all([response.json(), response2.json()]))
      .then(([data, data2]) => {
        if(data) {
          let list = []

          for(let i = 0; i < data2.length; i++) {
            for(let j = 0; j < data.length; j++) {
              if(data2[i] === data[j].Label) {
                list.push(data2[i])
              }
            }
          }
          this.setState({
            chartLoading: false,
            selectedData: data.filter(item => this.state.checked.includes(item.Label)).map(item => item.Data.filter(item => item.MetricType === this.state.selectedMetric)),
            months: this.state.maxYearmo.diff(value, 'months', true),
            minYearmo: value,
            demoDataByLoc: data,
            checked: list.filter(item => this.state.checked.indexOf(item) !== -1)
          })
        } else {
          this.setState({
            chartLoading: false,
            selectedData: [],
            months: this.state.maxYearmo.diff(value, 'months', true),
            minYearmo: value,
            demoDataByLoc: [],
            snackbar: true,
            errorMessage: 'Demographics by Location data is not available for the selection(s) you have made. Please broaden your search and try again'
          })
        }
      })
    } else {
      Promise.all([
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/demoByLoc/${this.state.selectedLocationType}?min_yearmo=${this.state.minYearmo.format('YYYY-MM')}&max_yearmo=${value.format('YYYY-MM')}`, {
          headers: {
            'token': Cookies.get('token')
          },
        }),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/${this.state.selectedLocationType}s`, {
          headers: {
            'token': Cookies.get('token')
          },
        }),
      ])
      .then(([response, response2]) => Promise.all([response.json(), response2.json()]))
      .then(([data, data2]) => {
        if(data) {
          let list = []

          for(let i = 0; i < data2.length; i++) {
            for(let j = 0; j < data.length; j++) {
              if(data2[i] === data[j].Label) {
                list.push(data2[i])
              }
            }
          }
          this.setState({
            chartLoading: false,
            selectedData: data.filter(item => this.state.checked.includes(item.Label)).map(item => item.Data.filter(item => item.MetricType === this.state.selectedMetric)),
            months: value.diff(this.state.minYearmo, 'months', true),
            maxYearmo: value,
            demoDataByLoc: data,
            checked: list.filter(item => this.state.checked.indexOf(item) !== -1)
          })
        } else {
          this.setState({
            chartLoading: false,
            selectedData: [],
            months: this.state.maxYearmo.diff(value, 'months', true),
            maxYearmo: value,
            demoDataByLoc: [],
            snackbar: true,
            errorMessage: 'Demographics by Location data is not available for the selection(s) you have made. Please broaden your search and try again'
          })
        }

      })
    }

    this.setState({
      [valName]: value
    })
  }

  handleSliderDateChange = (values) => {
    this.setState({
      chartLoading: true
    })
    const minYearmo = values[0]
    const maxYearmo = values[1]

    fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/demoByLoc/${this.state.selectedLocationType}?min_yearmo=${minYearmo}&max_yearmo=${maxYearmo}`, {
      headers: {
        'token': Cookies.get('token')
      },
    })
    .then(response => response.json())
    .then(data => {
      if(data) {
        this.setState({
          chartLoading: false,
          selectedData: data.filter(item => this.state.checked.includes(item.Label)).map(item => item.Data.filter(item => item.MetricType === this.state.selectedMetric)),
          demoDataByLoc: data,
          minYearmo: moment(values[0]),
          maxYearmo: moment(values[1]),
        })
      } else {
        this.setState({
          chartLoading: false,
          minYearmo: moment(values[0]),
          maxYearmo: moment(values[1]),
          selectedData: [],
          demoDataByLoc: [],
          snackbar: true,
          errorMessage: 'Demographics by Location data is not available for the selection(s) you have made. Please broaden your search and try again'
        })
      }
    })
    .catch(error => {
      console.log(error)
    })
  }

  getCustomersPct = () => {
    if(this.state.selectedData.length > 0) {
      let customersPct = []
      let selectedDataLength = this.state.selectedData.map(item => item[0].Data).length
      let metricValueLength = this.state.selectedData.map(item => item[0].Data.map(item => item.MetricValue).length)[0]

      for(let i = 0; i < metricValueLength; i++) {
          customersPct.push(this.state.selectedData.map(item => item[0].Data[i].Customers).reduce((a,b) => a + b, 0))
      }

      return customersPct.map((item => item / customersPct.reduce((a,b) => a + b, 0) * 100))
    } else {
      return []
    }
  }

  getCustomersBasePct = () => {
    if(this.state.selectedData.length > 0) {
      let customersBasePct = []
      let selectedDataLength = this.state.selectedData.map(item => item[0].Data).length
      let metricValueLength = this.state.selectedData.map(item => item[0].Data.map(item => item.MetricValue).length)[0]

      for(let i = 0; i < metricValueLength; i++) {
        customersBasePct.push(this.state.selectedData.map(item => item[0].Data[i].Customers_Baseline).reduce((a,b) => a + b, 0))
      }
      return customersBasePct.map((item => item / customersBasePct.reduce((a,b) => a + b, 0) * 100))
    } else {
      return []
    }
  }

  getBoomPct = () => {

    if(this.state.selectedData.length > 0) {
      let boomPct = []
      let selectedDataLength = this.state.selectedData.map(item => item[0].Data).length
      let metricValueLength = this.state.selectedData.map(item => item[0].Data.map(item => item.MetricValue).length)[0]

      for(let i = 0; i < metricValueLength; i++) {
        boomPct.push(this.state.selectedData.map(item => item[0].Data[i].Boomerang).reduce((a,b) => a + b, 0))
      }
      return boomPct.map((item => item / boomPct.reduce((a,b) => a + b, 0) * 100))
    } else {
      return []
    }
  }

  handleSnackbarClose = (e, reason) => {
    if(reason === 'clickaway') {
      this.setState({
        snackbar: false,
      })
    }

    this.setState({
      snackbar: false
    })
  }

  render() {
    const { classes } = this.props
    const series = []
    const customersPct = this.getCustomersPct()
    const customersBasePct = this.getCustomersBasePct()
    const boomPct = this.state.selectedData.length > 0 ? this.getBoomPct() : []
    const customersLY = []
    const boomPctLY = []
    const customersTotal = ''
    const metricValue = this.state.selectedData.map(item => item[0].Data.map(item => item.MetricValue))[0]
    let now = moment().format('M')
    let firstOfYear = moment().month('January').format('M')
    const currentUserLite = JSON.parse(localStorage.getItem('currentUser')) && JSON.parse(localStorage.getItem('currentUser'))[0].sd_lite === 1 ? true : false
    const dbO = JSON.parse(localStorage.getItem("currentUser")) ? JSON.parse(localStorage.getItem("currentUser"))[0].db_owner : ""

    if(this.state.selectedData.length > 0) {
      series.push(
        {
          name: '% of Customers',
          type: 'column',
          data: customersPct.map(item => parseFloat(item.toFixed(2)))
        },
        {
          name: '% of Customers Baseline',
          type: 'column',
          data: customersBasePct.map(item => parseFloat(item.toFixed(2)))
        },
        {
          name: '% of Boomerang Browsers',
          type: 'column',
          data: boomPct.map(item => parseFloat(item.toFixed(2)))
        },
      )
    }

    const marks = [
      {value: 0, label: moment().subtract(this.state.months, 'months').format('MM-YYYY')},
      {value: this.state.months, label: moment().format('MM-YYYY')}
    ]

    return(
      <div className={classes.root}>
        {this.state.isLoading ? <div className={classes.mainLoader}><CircularProgress /></div> :
        !currentUserLite && dbO !== "ESCANABA" ?
          <div>
            <Fragment>
              <Snackbar
                open={this.state.snackbar}
                autoHideDuration={4500}
                onClose={this.handleSnackbarClose}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
              >
                <SnackbarContent
                  message={
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                      <WarningIcon  fontSize="small" fontWeight="bold"/>
                      <span style={{fontWeight: 600, marginLeft: '15px'}}>{this.state.errorMessage}</span>
                    </div>
                  }
                  style={this.props.darkMode ?
                    {color: '#fff', backgroundColor: '#ff9800'}
                  :
                    {backgroundColor: '#ff9800'}
                  }
                />
              </Snackbar>
            </Fragment>

            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <div>
                <Typography className={this.props.darkMode ? classes.pageTitleDark : classes.pageTitle} style={{marginTop: '25px'}}>
                  <PublicIcon className={this.props.darkMode ? classes.pageIconDark : classes.pageIcon} />
                  <span style={{marginLeft: '5px'}}>DEMOGRAPHICS BY LOCATION</span>
                </Typography>
              </div>
              <div style={{marginTop: '40px'}}>
                <DemoExcel
                  metricType={this.state.selectedMetric}
                  minYearmo={this.state.minYearmo}
                  maxYearmo={this.state.maxYearmo}
                  selectedData={this.state.selectedData}
                  marketSelected={this.state.checked}
                  dataSeries={series}
                  categories={metricValue}
                />
              </div>
            </div>

            <br/>

            <div style={{display: 'flex', justifyContent: 'space-between'}}>
            {console.log(this.state.demoDataByLoc)}
              <Paper className={classes.chart}>
                {this.state.chartLoading ?
                  <div>
                    <CircularProgress style={{position: 'absolute', marginLeft: '50%', marginTop: '20%', zIndex: 10000}}/>
                  </div>
                :
                  <div/>
                }
                  <div style={this.state.chartLoading ? {opacity: 0.5} : {}}>
                    <BarChart
                      categories={metricValue ? metricValue : ['']}
                      data={series}
                      colors={['#00c9db', '#2f5a7a', '#ff2b34']}
                      height={700}
                      darkMode={this.props.darkMode}
                    />
                  </div>
              </Paper>

              <div style={{display: 'block', marginLeft: '20px', width: 300, minHeight: 500}}>
                <Paper style={{padding: '1em', minHeight: 800}}>
                  <br/>
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Typography className={this.props.darkMode ? classes.listLabelDark : classes.listLabel} variant="caption">{`${this.state.selectedLocationType.toUpperCase()}S (${this.state.demoDataByLoc.length})`}</Typography>
                    <Typography className={this.props.darkMode ? classes.listLabelDark : classes.listLabel} variant="caption">{`${this.state.checked.length} selected`}</Typography>
                  </div>
                  <List className={classes.list} dense={true} style={this.state.selectedLocationType.toUpperCase() === 'MARKET' ? {height: 250} : this.state.demoDataByLoc.length > 5 ? {height: 525} : {height: 250}}>
                    {this.state.dataLoad ?
                      <CircularProgress className={classes.loader} />
                    :
                      <div>
                        <ListItem dense button onClick={e => this.handleToggleChecked(e, 'All')}>
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={this.state.checked.length === this.state.demoDataByLoc.length ? true : false}
                              disableRipple
                              className={classes.checkbox}
                            />
                          </ListItemIcon>
                          <ListItemText><Typography variant="caption">{`ALL ${this.state.selectedLocationType.toUpperCase()}S`}</Typography></ListItemText>
                        </ListItem>
                        {this.state.demoDataByLoc.map(row => (
                          <ListItem key={row.Key} dense button onClick={e => this.handleToggleChecked(e, row)}>
                            <ListItemIcon>
                              <Checkbox
                                edge="start"
                                checked={this.state.checked.indexOf(row.Label) !== -1}
                                disableRipple
                                className={classes.checkbox}
                              />
                            </ListItemIcon>
                            <ListItemText><Typography variant="caption">{row.Label.toUpperCase()}</Typography></ListItemText>
                          </ListItem>
                        ))}
                      </div>
                    }
                  </List>
                  <Typography
                    className={this.props.darkMode ? classes.listLabelDark : classes.listLabel}
                    variant="caption"
                    style={
                       this.props.darkMode ?
                       {marginLeft: '17px', padding: '0px 5px 0px 5px', backgroundColor: '#424242', marginTop: '20px'}
                       : {marginLeft: '17px', padding: '0px 5px 0px 5px', backgroundColor: '#fff', marginTop: '20px'}
                     }
                  >
                    Date Range
                  </Typography>
                  <div className={classes.datePicker} style={{marginTop: '-10px'}}>
                    <MonthPicker
                      maxValue={this.state.maxYearmo}
                      minValue={this.state.minYearmo}
                      maxSliderValue={this.state.months}
                      handleDateRange={this.handleDateRange}
                      handleSliderDateChange={this.handleSliderDateChange}
                    />
                  </div>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="metric-select">Location vs Market</InputLabel>
                    <Select
                      id="metric"
                      labelId="metric-select"
                      value={this.state.selectedLocationType}
                      onChange={this.handleLocationTypeChange}
                      label="Location vs Market"
                    >
                      <MenuItem value={'location'}>Location</MenuItem>
                      <MenuItem value={'market'}>Market</MenuItem>
                    </Select>
                  </FormControl>
                  <br/>
                  <FormControl variant="outlined" className={classes.formControl} style={{marginTop: '10px'}}>
                    <InputLabel id="metric-select">Metric Type</InputLabel>
                    <Select
                      id="metric"
                      labelId="metric-select"
                      value={this.state.selectedMetric}
                      onChange={this.handleMetricChange}
                      label="Metric Type"
                    >
                      {this.renderSelectOptions1()}
                    </Select>
                  </FormControl>
                </Paper>
              </div>
            </div>
        </div>
        :
        <div className={classes.unavailable}>
        <AskReport />
        </div>
        }
      </div>
    )
  }
}

export default withStyles(styles)(Demographics)
