import React, { Component } from 'react'
import Chart from 'react-apexcharts'

class BarChart2 extends Component {
  constructor(props) {
    super(props)

    this.state = {
      series: props.data,
      options: {
        chart: {
          // type: 'bar',
          height: 225,
          toolbar: {
            tools: {
              download: false,
            }
          }
        },
        stroke: {
          show: true,
          color: 'transparent',
          width: 2,
        },
        colors: props.colors,
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
          },
        },
        dataLabels: {
          enabled: false
        },
        grid: {
          position: 'back',
          row: {
            colors: props.darkMode ? ['#2a2a2a', '#3a3a3a'] : ['#fff', '#f2f2f2']
          }
        },
        xaxis: {
          categories: props.categories,
          title: {
          },
          labels: {
            rotate: -60,
            style: {
              colors: props.darkMode ? '#fff' : ''
            }
          }
        },
        yaxis: {
          labels: {
            formatter: function(val, index) {
              return val.toFixed(1) + '%';
            },
            style: {
              colors: props.darkMode ? '#fff' : ''
            }
          }
        },
        fill: {
          opacity: props.darkMode ? [1, 1, .5] : [1, 1, .2],
        },
        legend: {
          position: 'top',
          labels: {
            colors: props.darkMode ? '#fff' : ''
          }
        },
        tooltip: {
          shared: true,
          theme: props.darkMode ? 'dark' : 'light',
          x: {
            formatter: function(value) {
              return value
            }
          },
          y: {
            formatter: function(value) {
              if(value) {
                return value.toFixed(2) + '%'
              }
            }
          },
          fixed: {
            enabled: props.fixed,
            position: 'topLeft',
            offsetY: '-50'
          }
        }
      },
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return (nextProps.data !== prevState.series) ?
      {
        series: nextProps.data,
        options: {
          xaxis: {
            categories: nextProps.categories
          }
        }
      } : null
  }

  render() {    

    return(
      <div>
        <Chart options={this.state.options} series={this.state.series} type="bar" height={this.props.height}/>
      </div>
    )
  }
}

export default BarChart2
