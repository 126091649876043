import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { Paper, Tab, Tabs, Box, Typography, AppBar, Fade, Divider, Select, MenuItem } from '@material-ui/core'
import DonutChart from '../charts/DonutChart'
import BarChart from '../charts/BarChart'

// import moment from 'moment'

// const today = moment().format('YYYY-MM-DD')
// const twelveMonths = moment().subtract(12, 'months').format('YYYY-MM-DD')
// console.log(today, twelveMonths)

function TabPanel(props) {
  const { children, value, index, ...other} = props

  return(
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  )
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  }
}

const useStyles = makeStyles((theme) =>
createStyles({
  root: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
    alignItems: 'stretch'
  },
  appBar: {
    background: '#fff',
    boxShadow: 'none',
    width: '100%'
  },
  indicator: {
    backgroundColor: 'transparent'
  },
  button: {
    backgroundColor: 'rgba(137, 67, 187, 1.0)',
    '&:hover': {
      backgroundColor: 'rgba(137, 67, 187, 0.5)'
    },
  },
  container: {
    height: 450,
  },
  title: {
    padding: '1em',
    textAlign: 'center'
  },
  chart: {
    flexGrow: 6,
    [theme.breakpoints.down('sm')]: {
      marginTop: 45,
    }
  },
}))

function RoiCharts(props) {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)
  const [dataType, setDataType] = React.useState(`${props.pageName === 'dailypros' ? 'categories' : "custPros"}`)

  const handleChangeDataType = ( event ) => {
    const { value } = event.target
    setDataType(value)
  }

  return(
    <div className={classes.root}>      
      <Paper square={true} style={{position: "relative", width: "100%", padding: "1em", border: "2px solid #0e85d3"}}>        
        {props.pageName === "Boom" ?
          <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", padding: "1em"}}>            
            <Typography style={{fontWeight: "bold"}}>
              {/* {props[`${dataType}ChartData`].length > 0 ? props[`${dataType}ChartData`].map(({label}) => label.toUpperCase())[0] + " VS " + props[`${dataType}ChartData`].map(({label}) => label.toUpperCase())[1]: ""} */}
              {dataType === "custPros" ? "CUSTOMERS VS PROSPECTS" : "BROWSE ABANDON VS CART ABANDON"}
            </Typography>
            {console.log(dataType)}
            <Select
              variant="outlined"
              value={dataType}
              onChange={handleChangeDataType}
              style={{width: "250px"}}
            >
              <MenuItem value={"custPros"}><Typography variant="caption">CUSTOMERS VS PROSPECTS</Typography></MenuItem>
              <MenuItem value={"listType"}><Typography variant="caption">LIST TYPE</Typography></MenuItem>
            </Select>
          </div>
        : props.pageName === 'dailypros' 
        ?
          <Typography style={{fontWeight: "bold", textAlign: "center"}}>PRODUCT CATEGORIES</Typography>
        :
          <Typography style={{fontWeight: "bold", textAlign: "center"}}>CUSTOMERS VS PROSPECTS</Typography>
        }
        <br/>
        <div style={{display: "flex", justifyContent: "space-around"}}>

          <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
            <Typography variant="caption" style={{fontWeight: "bold", color: "#529c00"}}>SALES</Typography>            
            <DonutChart 
              data={props[`${dataType}ChartData`].length > 0 ? props[`${dataType}ChartData`].map(({total}) => Math.round(total.Sales)) : []}
              total={false}
              radius={"65%"}
              labels={props[`${dataType}ChartData`].length > 0 ? props[`${dataType}ChartData`].map(({label}) => label.toUpperCase()) : []}
              legend={true}
              position={"bottom"}
              labelShow={true}
              colors={dataType === 'categories' ? undefined : ["#14a1ff", "#f76459"]}
              hoverColor={["rgba(125, 186, 255, 0.05)", "rgba(255, 184, 84, 0.05)"]}
              hoverBorderWidth={5}
              height={375}
              className={classes.chart}
              darkMode={props.darkMode}
            />
          </div>
          <Divider orientation="vertical" style={{height: 350}}/>

          <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
            <Typography variant="caption" style={{fontWeight: "bold", color: "#5d2e80"}}>MAILED</Typography>
            <DonutChart 
              data={props[`${dataType}ChartData`].length > 0 ? props[`${dataType}ChartData`].map(({total}) => Math.round(total.Mailed)) : []}
              total={false}
              radius={"65%"}
              labels={props[`${dataType}ChartData`].length > 0 ? props[`${dataType}ChartData`].map(({label}) => label.toUpperCase()) : []}
              legend={true}
              position={"bottom"}
              labelShow={true}
              colors={dataType === 'categories' ? undefined : ["#14a1ff", "#f76459"]}
              hoverColor={["rgba(125, 186, 255, 0.05)", "rgba(255, 184, 84, 0.05)"]}
              hoverBorderWidth={5}
              height={375}
              className={classes.chart}
              darkMode={props.darkMode}
            />
          </div>
          <Divider orientation="vertical" style={{height: 350}}/>

          <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
            <Typography variant="caption" style={{fontWeight: "bold", color: "#006cc4"}}>RESPONSES</Typography>
            <DonutChart 
              data={props[`${dataType}ChartData`].length > 0 ? props[`${dataType}ChartData`].map(({total}) => Math.round(total.Responses)) : []}
              total={false}
              radius={"65%"}
              labels={props[`${dataType}ChartData`].length > 0 ? props[`${dataType}ChartData`].map(({label}) => label.toUpperCase()) : []}
              legend={true}
              position={"bottom"}
              labelShow={true}
              colors={dataType === 'categories' ? undefined : ["#14a1ff", "#f76459"]}
              hoverColor={["rgba(125, 186, 255, 0.05)", "rgba(255, 184, 84, 0.05)"]}
              hoverBorderWidth={5}
              height={375}
              className={classes.chart}
              darkMode={props.darkMode}
            />
          </div>

        </div>
        <br/>
      </Paper>              
    </div>
  )
}

export default RoiCharts
