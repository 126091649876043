import React, { Component, Fragment } from 'react'
import {
  withStyles,
  CircularProgress,
  Typography,
  Menu,
  MenuItem,
  Tooltip,
  Button,
  Link,
  Fade,
  Grow,
  Collapse,
  Snackbar,
  SnackbarContent,
  Select,
  InputLabel,
  InputBase,
  FormControl,
  Chip,
  TextField,
  IconButton,
  Checkbox,
} from '@material-ui/core'
import Cookies from 'js-cookie'
import Brightness3Icon from '@material-ui/icons/Brightness3'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import CancelIcon from '@material-ui/icons/Cancel'
import WarningIcon from '@material-ui/icons/Warning'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import Stats from '../roi/Stats'
import CustProsTable from '../roi/CustProsTable'
import RoiCharts from '../roi/RoiCharts'
import CategoriesTable from '../roi/CategoriesTable'
import ListTable from '../roi/ListTable'
import LocationsTable from '../triggers/TrigLocationsTable'
import DatePicker from '../calendar/DatePicker'
import DayPicker from '../calendar/DayPicker'
import moment from 'moment'
import roiMainStandIn from '../../assets/mainStandIns/roiMainStandIn.png'
import SvgIcon from '@material-ui/core/SvgIcon'
import RoiExcel from '../roi/RoiExcel'
import AskReport from '../AskReport'
import UpdatedThrough from '../UpdatedThrough'

const styles = theme => ({
  loader: {
    marginTop: '25%',
    marginLeft: '50%'
  },
  title: {
    fontSize: '17px',
    fontWeight: 'light',
    color: 'rgba(115, 115, 115, 0.9)'
  },
  chart: {
    padding: theme.spacing(5)
  },
  pageTitle: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '17px',
    fontWeight: 'light',
    color: 'rgba(115, 115, 115, 0.9)'
  },
  pageTitleDark: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '17px',
    fontWeight: 'light',
    color: 'rgba(255, 255, 255, 0.9)'
  },
  pageIcon: {
    fontSize: '15px',
    marginTop: '21px',
    marginRight: '2px',
  },
  pageIconDark: {
    fontSize: '15px',
    color: 'rgba(255, 255, 255, 0.9)',
    marginTop: '5px',
    marginRight: '2px',
  },
  mailingType: {
    color: '#001d84',
    '&:hover': {
      cursor: 'pointer',
    }
  },
  mailingTypeDark: {
    color: '#4ab8bb',
    '&:hover': {
      cursor: 'pointer',
    }
  },
  unavailable: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // margin: '2em'
  },
  lostOppLink: {
    color: '#001d84',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'none',
      color: '#4ab8bb'
    }
  },
  lostOppLinkDark: {
    color: '#4ab8bb',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'none',
      color: '#6fd7da'
    }
  },
  warning: {
    border: '3px solid #de9f10',
    borderRadius: 4,
    textAlign: 'center',
    fontWeight: 'bold'
  },
  hidden: {
    display: 'none'
  },
  chip: {
    margin: 1,
    height: '20px',
    padding: '0px 2px 0px 2px',
    border: '1px solid #636768',
    fontSize: '10px',
    backgroundColor: 'rgba(231, 231, 231, 0.6)',
  },
  chips: {
    display: 'flex',
  }
})

function BoomIcon(props) {
  return(
    <SvgIcon {...props}>
      <path d="M10 2H4C2.9 2 2 2.9 2 4S2.9 6 4 6H8L10 2M18 2C20.2 2 22 3.8 22 6V12L18 14C18 9.6 14.4 6 10 6L12 2H18M18 20V16L22 14V20C22 21.1 21.1 22 20 22S18 21.1 18 20Z" />
    </SvgIcon>
  )
}

const StyledInput = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    overflowX: 'auto',
    position: 'relative',
    padding: '2px 5px 2px 5px',
    border: '1px solid #767676',
    fontSize: 7,
    // minWidth: 200,
    // maxWidth: 350,
    '&:focus': {
      border: '1px solid',
      borderRadius: 4,
      borderColor: '#0054c1',
      backgroundColor: 'transparent',
    }
  }
}))(InputBase)

const StyledTooltip = withStyles({
  tooltip: {
    // color: "#fff",
    // backgroundColor: "#4d4d4d",
    color: "#6d6d6d",
    backgroundColor: "#fff",
    borderRadius: "8px",
    border: "2px solid #25aae1"
  }
})(Tooltip)

class Boom extends Component {
  _isMounted = false

  constructor(props) {
    super(props)
    this.state = {
      boomData: [],
      selectedBoomData: [],
      dataOverall: [],
      dataWeekly: [],
      dataCustPros: [],
      dataListType: [],
      dataCategory: [],
      checked: [],
      selectedMarkets: ['ALL'],
      selectedType: 'market',
      startDate: JSON.parse(localStorage.getItem("currentUser"))[0].max_date_boom ? moment(JSON.parse(localStorage.getItem("currentUser"))[0].max_date_boom).subtract(3, "months").startOf("year") : JSON.parse(localStorage.getItem('currentUser'))[0].corporateID === 315 || JSON.parse(localStorage.getItem('currentUser'))[0].corporateID === 146 ? moment('01-01-2019') :JSON.parse(localStorage.getItem('currentUser'))[0].db_owner === 'DEMO-APPAREL' || JSON.parse(localStorage.getItem('currentUser'))[0].db_owner === 'BLUFFTON' ? moment().startOf('year').subtract(1, 'years') : moment().diff(moment().startOf('year'), 'months') > 3 ? moment().startOf('year') : moment().startOf('year').subtract(1, 'years'),
      initialStartDate: JSON.parse(localStorage.getItem("currentUser"))[0].max_date_boom ? moment(JSON.parse(localStorage.getItem("currentUser"))[0].max_date_boom).subtract(3, "months").startOf("year") : JSON.parse(localStorage.getItem("currentUser"))[0].corporateID === 316 || JSON.parse(localStorage.getItem("currentUser"))[0].corporateID === 346 || JSON.parse(localStorage.getItem('currentUser'))[0].corporateID === 315 || JSON.parse(localStorage.getItem('currentUser'))[0].corporateID === 146 ? moment('01-01-2019') : JSON.parse(localStorage.getItem('currentUser'))[0].db_owner === 'DEMO-APPAREL' || JSON.parse(localStorage.getItem('currentUser'))[0].db_owner === 'BLUFFTON' ? moment().startOf('year').subtract(1, 'years') : moment().diff(moment().startOf('year'), 'months') > 3 ? moment().startOf('year') : moment().startOf('year').subtract(1, 'years'),
      initialEndDate: JSON.parse(localStorage.getItem('currentUser'))[0].max_date_boom ? moment(JSON.parse(localStorage.getItem('currentUser'))[0].max_date_boom) : moment(),
      endDate: JSON.parse(localStorage.getItem('currentUser'))[0].max_date_boom ? moment(JSON.parse(localStorage.getItem('currentUser'))[0].max_date_boom) : moment(),
      isLoading: true,
      marketLoading: false,
      corpID: JSON.parse(localStorage.getItem('currentUser'))[0].corporateID,
      compID: JSON.parse(localStorage.getItem('currentUser'))[0].compIDs,
      allMailingsToggle: JSON.parse(localStorage.getItem("currentUser"))[0].roi_all_mailings_toggle,      
      mailingType: JSON.parse(localStorage.getItem("currentUser"))[0].roi_all_mailings_default ? 'all' : 'completed',
      mailingOpen: false,
      anchor: null,
      complete: 1,
      hasData: false,
      snackbar: false,
      errorMessage: '',
      maxYearmo: JSON.parse(localStorage.getItem('currentUser'))[0].max_date_boom ? moment(JSON.parse(localStorage.getItem('currentUser'))[0].max_date_boom) : moment(),
      minYearmo: JSON.parse(localStorage.getItem("currentUser"))[0].max_date_boom ? moment(JSON.parse(localStorage.getItem("currentUser"))[0].max_date_boom).subtract(3, "months").startOf("year") : JSON.parse(localStorage.getItem("currentUser"))[0].corporateID === 316 || JSON.parse(localStorage.getItem("currentUser"))[0].corporateID === 346 || JSON.parse(localStorage.getItem('currentUser'))[0].corporateID === 146 || JSON.parse(localStorage.getItem('currentUser'))[0].corporateID === 315 ? moment('01-01-2019') : JSON.parse(localStorage.getItem('currentUser'))[0].db_owner === 'DEMO-APPAREL' || JSON.parse(localStorage.getItem('currentUser'))[0].db_owner === 'BLUFFTON' ? moment().startOf('year').subtract(1, 'years') : moment().diff(moment().startOf('year'), 'months') > 3 ? moment().startOf('year') : moment().startOf('year').subtract(1, 'years'),
      days: 0,
      restoreHidden: true,
      bUnit: JSON.parse(localStorage.getItem("currentUser"))[0].businessUnitID,
      updatedDate: null,
    }

    this.handleDateClear = this.handleDateClear.bind(this)
  }

  componentDidMount() {
    this._isMounted = true
    const myHeaders = {'token': Cookies.get('token')}
    let boomData = []
    let completed = this.state.mailingType === 'completed' ? 1 : 0

    console.log(completed)
    console.log(this.state.mailingType)
    console.log(this.state.initialStartDate.format("YYYY-MM-DD"))
    console.log(this.state.initialEndDate.format("YYYY-MM-DD"))


    console.log(this.state.endDate.format("YYYY-MM-DD"))


    Promise.all([
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI?startDate=${this.state.initialStartDate.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${completed}`, {headers: myHeaders}),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/custPros?startDate=${this.state.initialStartDate.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${completed}`, {headers: myHeaders}),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/category?startDate=${this.state.initialStartDate.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${completed}`, {headers: myHeaders}),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/listType?startDate=${this.state.initialStartDate.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${completed}`, {headers: myHeaders}),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/weekly?startDate=${this.state.initialStartDate.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${completed}`, {headers: myHeaders}),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/dashboard/market?startDate=${this.state.initialStartDate.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${completed}`, {headers: myHeaders}),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/dashboard?startDate=${this.state.initialStartDate.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${completed}`, {headers: myHeaders}),
    ])
    .then(([resp1, resp2, resp3, resp4, resp5, resp6, resp7]) => Promise.all([resp1.json(), resp2.json(), resp3.json(), resp4.json(), resp5.json(), resp6.json(), resp7.json()]))
    .then(([data1, data2, data3, data4, data5, data6, data7]) => {

      if(this._isMounted && data1) {
        let boomObj = {}
        boomObj['Data_Category'] = data3
        boomObj['Data_CustPros'] = data2
        boomObj['Data_Location'] = data7[0].Data_Location
        boomObj['Data_ListType'] = data4
        boomObj['Data_Overall'] = data1[0].Data
        boomObj['Data_Weekly'] = data5
        boomObj['Label'] = 'ALL'
        boomObj['Key'] = data6.length + 1
        boomData.push(boomObj)        


        this.setState({
          isLoading: false,
          hasData: true,
          boomData: boomData.concat(data6),
          selectedBoomData: boomData.filter(item => item.Label === 'ALL'),
          boomMarketData: data6,
          days: this.state.initialEndDate.diff(this.state.initialStartDate, 'days'),
          checked: boomData.concat(data6).map(item => item.Label),
          updatedDate: moment(data7[0].LastTransDate)
        })
      } else {
        this.setState({
          isLoading: false,
          hasData: false,
        })
      }
    })
    .catch(error => {
      console.log(error)
    })

    if(JSON.parse(localStorage.getItem('currentUser'))[0].username.toUpperCase().includes('USER')) {
    } else {
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/pageviews/add?display=boomerang&screen_name=boomerang`, {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'token': Cookies.get('token'),
        },
      })
      .then(response => {
        if(response.ok === true) {
          return response.json()
        }
      })
      .catch(error => {
        console.log(error)
      })
    }
  }

  componentWillUnmount = () => {
    this._isMounted = false
  }

  handleDateClear = () => {
    console.log('clearing')
    let boomData = []
    let complete = this.state.mailingType === 'completed' ? 1 : 0
    const myHeaders = {'token': Cookies.get('token')}

    if(this.state.selectedType === 'market') {
      Promise.all([
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI?startDate=${this.state.initialStartDate.format('YYYY-MM-DD')}&endDate=${this.state.initialEndDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/custPros?startDate=${this.state.initialStartDate.format('YYYY-MM-DD')}&endDate=${this.state.initialEndDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/category?startDate=${this.state.initialStartDate.format('YYYY-MM-DD')}&endDate=${this.state.initialEndDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/listType?startDate=${this.state.initialStartDate.format('YYYY-MM-DD')}&endDate=${this.state.initialEndDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/weekly?startDate=${this.state.initialStartDate.format('YYYY-MM-DD')}&endDate=${this.state.initialEndDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/dashboard/${this.state.selectedType}?startDate=${this.state.initialStartDate.format('YYYY-MM-DD')}&endDate=${this.state.initialEndDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/dashboard?startDate=${this.state.initialStartDate.format('YYYY-MM-DD')}&endDate=${this.state.initialEndDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
      ])
      .then(([resp1, resp2, resp3, resp4, resp5, resp6, resp7]) => Promise.all([resp1.json(), resp2.json(), resp3.json(), resp4.json(), resp5.json(), resp6.json(), resp7.json()]))
      .then(([data1, data2, data3, data4, data5, data6, data7]) => {
        let boomObj = {}
        boomObj['Data_Category'] = data3
        boomObj['Data_CustPros'] = data2
        boomObj['Data_Location'] = data7[0].Data_Location
        boomObj['Data_ListType'] = data4
        boomObj['Data_Overall'] = data1[0].Data
        boomObj['Data_Weekly'] = data5
        boomObj['Label'] = 'ALL'
        boomObj['Key'] = data6.length + 1
        boomData.push(boomObj)

        if(data1) {
          const startDate = JSON.parse(localStorage.getItem('currentUser'))[0].corporateID === 146 ? moment('01-01-2019') :JSON.parse(localStorage.getItem('currentUser'))[0].db_owner === 'DEMO-APPAREL' ? moment().startOf('year').subtract(1, 'years') : moment().diff(moment().startOf('year'), 'months') > 3 ? moment().startOf('year') : moment().startOf('year').subtract(1, 'years')
          const endDate = JSON.parse(localStorage.getItem('currentUser'))[0].max_date_boom ? moment(JSON.parse(localStorage.getItem('currentUser'))[0].max_date_boom) : moment()

          this.setState({
            isLoading: false,
            hasData: true,
            boomData: boomData.concat(data6),
            selectedBoomData: boomData.concat(data6).filter(item => this.state.selectedMarkets.includes(item.Label)),
            boomMarketData: data6,
            restoreHidden: true,
            startDate: startDate,
            endDate: endDate,
            minYearmo: startDate,
            maxYearmo: endDate,
            days: endDate.diff(startDate, 'days')
          })

          this.handleMarketChange(this.state.selectedMarkets)
        } else {
          this.setState({
            isLoading: false,
            hasData: false,
          })
        }
      })
      .catch(error => {
        console.log(error)
      })
    } else {
      Promise.all([
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI?startDate=${this.state.initialStartDate.format('YYYY-MM-DD')}&endDate=${this.state.initialEndDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/custPros?startDate=${this.state.initialStartDate.format('YYYY-MM-DD')}&endDate=${this.state.initialEndDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/category?startDate=${this.state.initialStartDate.format('YYYY-MM-DD')}&endDate=${this.state.initialEndDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/listType?startDate=${this.state.initialStartDate.format('YYYY-MM-DD')}&endDate=${this.state.initialEndDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/weekly?startDate=${this.state.initialStartDate.format('YYYY-MM-DD')}&endDate=${this.state.initialEndDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/dashboard/${this.state.selectedType}?startDate=${this.state.initialStartDate.format('YYYY-MM-DD')}&endDate=${this.state.initialEndDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
      ])
      .then(([resp1, resp2, resp3, resp4, resp5, resp6]) => Promise.all([resp1.json(), resp2.json(), resp3.json(), resp4.json(), resp5.json(), resp6.json()]))
      .then(([data1, data2, data3, data4, data5, data6]) => {
        let boomObj = {}
        boomObj['Data_Category'] = data3
        boomObj['Data_CustPros'] = data2
        boomObj['Data_ListType'] = data4
        boomObj['Data_Overall'] = data1[0].Data
        boomObj['Data_Weekly'] = data5
        boomObj['Label'] = 'ALL'
        boomObj['Key'] = data6.length + 1
        boomData.push(boomObj)

        if(data1) {
          const startDate = JSON.parse(localStorage.getItem('currentUser'))[0].corporateID === 146 ? moment('01-01-2019') :JSON.parse(localStorage.getItem('currentUser'))[0].db_owner === 'DEMO-APPAREL' ? moment().startOf('year').subtract(1, 'years') : moment().diff(moment().startOf('year'), 'months') > 3 ? moment().startOf('year') : moment().startOf('year').subtract(1, 'years')
          const endDate = JSON.parse(localStorage.getItem('currentUser'))[0].max_date_boom ? moment(JSON.parse(localStorage.getItem('currentUser'))[0].max_date_boom) : moment()

          this.setState({
            isLoading: false,
            hasData: true,
            boomData: boomData.concat(data6),
            selectedBoomData: boomData.concat(data6).filter(item => this.state.selectedMarkets.includes(item.Label)),
            boomMarketData: data6,
            restoreHidden: true,
            startDate: startDate,
            endDate: endDate,
            minYearmo: startDate,
            maxYearmo: endDate,
            days: endDate.diff(startDate, 'days')
          })

          this.handleMarketChange(this.state.selectedMarkets)
        } else {
          this.setState({
            isLoading: false,
            hasData: false,
          })
        }
      })
      .catch(error => {
        console.log(error)
      })
    }
  }

  handleDateRange = (value, valName, sliderValues) => {
    const myHeaders = {'token': Cookies.get('token')}
    let boomData = []
    let complete = this.state.mailingType === 'completed' ? 1 : 0

    this.setState({
      marketLoading: true
    })
    console.log('date change')
    console.log(this.state.selectedMarkets)

    if(valName === 'startDate') {
      if(this.state.selectedType === 'market') {
        Promise.all([
          fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI?startDate=${value.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
          fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/custPros?startDate=${value.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
          fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/category?startDate=${value.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
          fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/listType?startDate=${value.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
          fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/weekly?startDate=${value.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
          fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/dashboard/${this.state.selectedType}?startDate=${value.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
          fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/dashboard?startDate=${value.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
        ])
        .then(([response1, response2, response3, response4, response5, response6, response7]) => Promise.all([response1.json(), response2.json(), response3.json(), response4.json(), response5.json(), response6.json(), response7.json()]))
        .then(([data1, data2, data3, data4, data5, data6, data7]) => {
          if(data1) {
            let boomObj = {}
            boomObj['Data_Category'] = data3
            boomObj['Data_CustPros'] = data2
            boomObj['Data_Location'] = data7[0].Data_Location
            boomObj['Data_ListType'] = data4
            boomObj['Data_Overall'] = data1[0].Data
            boomObj['Data_Weekly'] = data5
            boomObj['Label'] = 'ALL'
            boomObj['Key'] = data6.length + 1
            boomData.push(boomObj)

            console.log(boomData.concat(data6))
            console.log(boomData.concat(data6).filter(({Label}) => this.state.selectedMarkets.includes(Label)))

            this.setState({
              isLoading: false,
              hasData: true,
              boomData: boomData.concat(data6),
              selectedBoomData: boomData.concat(data6).filter(item => this.state.selectedMarkets.includes(item.Label)),
              boomMarketData: data6,
              restoreHidden: false,
              days: this.state.endDate.diff(value, 'days'),
              [valName]: value,
              marketLoading: false,
            })
            this.handleMarketChange(this.state.selectedMarkets)

            console.log(this.state.selectedMarkets)
          } else {
            this.setState({
              isLoading: false,
              snackbar: true,
              errorMessage: 'Boomerang ROI is not available for the selected dates. Please broaden the date range',
              days: this.state.endDate.diff(this.state.startDate, 'days'),
              restoreHidden: false,
              startDate: value,
              boomData: [],
              selectedBoomData: [],
              boomMarketData: [],
              marketLoading: false,
            })
          }
        })
        .catch(error => {
          console.log(error)
        })

      } else {
        Promise.all([
          fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI?startDate=${value.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
          fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/custPros?startDate=${value.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
          fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/category?startDate=${value.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
          fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/listType?startDate=${value.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
          fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/weekly?startDate=${value.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
          fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/dashboard/${this.state.selectedType}?startDate=${value.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
        ])
        .then(([response1, response2, response3, response4, response5, response6]) => Promise.all([response1.json(), response2.json(), response3.json(), response4.json(), response5.json(), response6.json()]))
        .then(([data1, data2, data3, data4, data5, data6]) => {
          if(data1) {
            let boomObj = {}
            boomObj['Data_Category'] = data3
            boomObj['Data_CustPros'] = data2
            boomObj['Data_ListType'] = data4
            boomObj['Data_Overall'] = data1[0].Data
            boomObj['Data_Weekly'] = data5
            boomObj['Label'] = 'ALL'
            boomObj['Key'] = data6.length + 1
            boomData.push(boomObj)

            this.setState({
              isLoading: false,
              hasData: true,
              boomData: boomData.concat(data6),
              selectedBoomData: boomData.concat(data6).filter(item => this.state.selectedMarkets.includes(item.Label)),
              boomMarketData: data6,
              restoreHidden: false,
              days: this.state.endDate.diff(value, 'days'),
              [valName]: value,
              marketLoading: false,
            })
            this.handleMarketChange(this.state.selectedMarkets)
          } else {
            this.setState({
              isLoading: false,
              snackbar: true,
              errorMessage: 'Boomerang ROI is not available for the selected dates. Please broaden the date range',
              days: this.state.endDate.diff(value, 'days'),
              restoreHidden: false,
              startDate: value,
              boomData: [],
              selectedBoomData: [],
              boomMarketData: [],
              marketLoading: false,
            })
          }
        })
        .catch(error => {
          console.log(error)
        })

      }
    } else {
      if(this.state.selectedType === 'market') {
        Promise.all([
          fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${value.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
          fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/custPros?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${value.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
          fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/category?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${value.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
          fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/listType?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${value.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
          fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/weekly?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${value.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
          fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/dashboard/${this.state.selectedType}?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${value.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
          fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/dashboard?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${value.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
        ])
        .then(([response1, response2, response3, response4, response5, response6, response7]) => Promise.all([response1.json(), response2.json(), response3.json(), response4.json(), response5.json(), response6.json(), response7.json()]))
        .then(([data1, data2, data3, data4, data5, data6, data7]) => {
          if(data1) {
            let boomObj = {}
            boomObj['Data_Category'] = data3
            boomObj['Data_CustPros'] = data2
            boomObj['Data_Location'] = data7[0].Data_Location
            boomObj['Data_ListType'] = data4
            boomObj['Data_Overall'] = data1[0].Data
            boomObj['Data_Weekly'] = data5
            boomObj['Label'] = 'ALL'
            boomObj['Key'] = data6.length + 1
            boomData.push(boomObj)

            this.setState({
              isLoading: false,
              hasData: true,
              boomData: boomData.concat(data6),
              selectedBoomData: boomData.concat(data6).filter(item => this.state.selectedMarkets.includes(item.Label)),
              boomMarketData: data6,
              restoreHidden: false,
              days: value.diff(this.state.startDate, 'days'),
              endDate: value,
              marketLoading: false,
            })
            this.handleMarketChange(this.state.selectedMarkets)
          } else {
            console.log('hey')
            this.setState({
              isLoading: false,
              snackbar: true,
              errorMessage: 'Boomerang ROI is not available for the selected dates. Please broaden the date range',
              boomData: [],
              selectedBoomData: [],
              boomMarketData: [],
              marketLoading: false,
              endDate: value,
            })

          }
        })
        .catch(error => {
          console.log(error)
        })
      } else {
        Promise.all([
          fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${value.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
          fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/custPros?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${value.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
          fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/category?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${value.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
          fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/listType?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${value.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
          fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/weekly?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${value.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
          fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/dashboard/${this.state.selectedType}?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${value.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
        ])
        .then(([response1, response2, response3, response4, response5, response6]) => Promise.all([response1.json(), response2.json(), response3.json(), response4.json(), response5.json(), response6.json()]))
        .then(([data1, data2, data3, data4, data5, data6]) => {
          if(data1) {
            let boomObj = {}
            boomObj['Data_Category'] = data3
            boomObj['Data_CustPros'] = data2
            boomObj['Data_ListType'] = data4
            boomObj['Data_Overall'] = data1[0].Data
            boomObj['Data_Weekly'] = data5
            boomObj['Label'] = 'ALL'
            boomObj['Key'] = data6.length + 1
            boomData.push(boomObj)

            this.setState({
              isLoading: false,
              hasData: true,
              boomData: boomData.concat(data6),
              selectedBoomData: boomData.concat(data6).filter(item => this.state.selectedMarkets.includes(item.Label)),
              boomMarketData: data6,
              restoreHidden: false,
              days: value.diff(this.state.startDate, 'days'),
              endDate: value,
              marketLoading: false,
            })
            this.handleMarketChange(this.state.selectedMarkets)
          } else {
            console.log('hello')
            this.setState({
              isLoading: false,
              snackbar: true,
              errorMessage: 'Boomerang ROI is not available for the selected dates. Please broaden the date range',
              days: this.state.endDate.diff(this.state.startDate, 'days'),
              restoreHidden: false,
              endDate: value,
              boomData: [],
              selectedBoomData: [],
              boomMarketData: [],
              marketLoading: false,
            })

          }
        })
        .catch(error => {
          console.log(error)
        })

      }
    }
  }

  handleSliderDateChange = (values) => {
    const myHeaders = {'token': Cookies.get('token')}
    const startDate = values[0]
    const endDate = values[1]
    let boomData = []
    let complete = this.state.mailingType === 'completed' ? 1 : 0

    this.setState({
      marketLoading: true
    })

    if(this.state.selectedType === 'market') {
      Promise.all([
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI?startDate=${startDate}&endDate=${endDate}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/custPros?startDate=${startDate}&endDate=${endDate}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/category?startDate=${startDate}&endDate=${endDate}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/listType?startDate=${startDate}&endDate=${endDate}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/weekly?startDate=${startDate}&endDate=${endDate}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/dashboard/${this.state.selectedType}?startDate=${startDate}&endDate=${endDate}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/dashboard?startDate=${startDate}&endDate=${endDate}&complete=${complete}`, {headers: myHeaders}),
      ])
      .then(([response1, response2, response3, response4, response5, response6, response7]) => Promise.all([response1.json(), response2.json(), response3.json(), response4.json(), response5.json(), response6.json(), response7.json()]))
      .then(([data1, data2, data3, data4, data5, data6, data7]) => {
        if(data1) {
          let boomObj = {}
          boomObj['Data_Category'] = data3
          boomObj['Data_CustPros'] = data2
          boomObj['Data_Location'] = data7[0].Data_Location
          boomObj['Data_ListType'] = data4
          boomObj['Data_Overall'] = data1[0].Data
          boomObj['Data_Weekly'] = data5
          boomObj['Label'] = 'ALL'
          boomObj['Key'] = data6.length + 1
          boomData.push(boomObj)

          this.setState({
            marketLoading: false,
            hasData: true,
            boomData: boomData.concat(data6),
            selectedBoomData: boomData.concat(data6).filter(item => this.state.selectedMarkets.includes(item.Label)),
            boomMarketData: data6,
            startDate: moment(values[0]),
            endDate: moment(values[1]),
            minYearmo: moment(values[0]),
            maxYearmo: moment(values[1]),
            restoreHidden: false,
          })
          this.handleMarketChange(this.state.selectedMarkets)
        } else {
          this.setState({
            isLoading: false,
            snackbar: true,
            errorMessage: 'Boomerang ROI is not available for the selected dates. Please broaden the date range',
            boomData: [],
            selectedBoomData: [],
            boomMarketData: [],
            startDate: moment(values[0]),
            endDate: moment(values[1]),
          })
        }
      })
      .catch(error => {
        console.log(error)
      })
    } else {
      Promise.all([
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI?startDate=${startDate}&endDate=${endDate}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/custPros?startDate=${startDate}&endDate=${endDate}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/category?startDate=${startDate}&endDate=${endDate}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/listType?startDate=${startDate}&endDate=${endDate}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/weekly?startDate=${startDate}&endDate=${endDate}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/dashboard/${this.state.selectedType}?startDate=${startDate}&endDate=${endDate}&complete=${complete}`, {headers: myHeaders}),
      ])
      .then(([response1, response2, response3, response4, response5, response6]) => Promise.all([response1.json(), response2.json(), response3.json(), response4.json(), response5.json(), response6.json()]))
      .then(([data1, data2, data3, data4, data5, data6]) => {
        if(data1) {
          let boomObj = {}
          boomObj['Data_Category'] = data3
          boomObj['Data_CustPros'] = data2
          boomObj['Data_ListType'] = data4
          boomObj['Data_Overall'] = data1[0].Data
          boomObj['Data_Weekly'] = data5
          boomObj['Label'] = 'ALL'
          boomObj['Key'] = data6.length + 1
          boomData.push(boomObj)

          this.setState({
            marketLoading: false,
            hasData: true,
            boomData: boomData.concat(data6),
            selectedBoomData: boomData.concat(data6).filter(item => this.state.selectedMarkets.includes(item.Label)),
            boomMarketData: data6,
            startDate: moment(values[0]),
            endDate: moment(values[1]),
            minYearmo: moment(values[0]),
            maxYearmo: moment(values[1]),
            restoreHidden: false,
          })
          this.handleMarketChange(this.state.selectedMarkets)
        } else {
          this.setState({
            isLoading: false,
            snackbar: true,
            errorMessage: 'Boomerang ROI is not available for the selected dates. Please broaden the date range',
            boomData: [],
            selectedBoomData: [],
            boomMarketData: [],
            startDate: moment(values[0]),
            endDate: moment(values[1]),
          })
        }
      })
      .catch(error => {
        console.log(error)
      })
    }
  }

  handleSelectedType = value => {
    const myHeaders = {'token': Cookies.get('token')}
    let boomData = []
    let complete = this.state.mailingType === 'completed' ? 1 : 0

    this.setState({
      marketLoading: true,
    })

    console.log(value)

    if(value === 'market') {
      Promise.all([
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/custPros?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/category?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/listType?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/weekly?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/dashboard/market?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/dashboard?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
      ])
      .then(([resp1, resp2, resp3, resp4, resp5, resp6, resp7]) => Promise.all([resp1.json(), resp2.json(), resp3.json(), resp4.json(), resp5.json(), resp6.json(), resp7.json()]))
      .then(([data1, data2, data3, data4, data5, data6, data7]) => {
        if(this._isMounted && data1) {
          let boomObj = {}
          boomObj['Data_Category'] = data3
          boomObj['Data_CustPros'] = data2
          boomObj['Data_Location'] = data7[0].Data_Location
          boomObj['Data_ListType'] = data4
          boomObj['Data_Overall'] = data1[0].Data
          boomObj['Data_Weekly'] = data5
          boomObj['Label'] = 'ALL'
          boomObj['Key'] = data6.length + 1
          boomData.push(boomObj)

          this.setState({
            marketLoading: false,
            hasData: true,
            boomData: boomData.concat(data6),
            selectedBoomData: boomData.filter(item => item.Label === 'ALL'),
            boomMarketData: data6,
            selectedMarkets: ['ALL'],
            checked: boomData.concat(data6).map(item => item.Label)
          })
        } else {
          this.setState({
            isLoading: false,
            snackbar: true,
            errorMessage: 'Boomerang ROI is not available for the selection(s) you have made. Please broaden your search and try again'
          })
        }
      })
      .catch(error => {
        console.log(error)
      })
    } else {
      Promise.all([
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/custPros?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/category?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/listType?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/weekly?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/dashboard/location?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
      ])
      .then(([resp1, resp2, resp3, resp4, resp5, resp6]) => Promise.all([resp1.json(), resp2.json(), resp3.json(), resp4.json(), resp5.json(), resp6.json()]))
      .then(([data1, data2, data3, data4, data5, data6]) => {
        if(this._isMounted && data1) {
          let boomObj = {}
          boomObj['Data_Category'] = data3
          boomObj['Data_CustPros'] = data2
          boomObj['Data_ListType'] = data4
          boomObj['Data_Overall'] = data1[0].Data
          boomObj['Data_Weekly'] = data5
          boomObj['Label'] = 'ALL'
          boomObj['Key'] = data6.length + 1
          boomData.push(boomObj)

          this.setState({
            marketLoading: false,
            hasData: true,
            boomData: boomData.concat(data6),
            selectedBoomData: boomData.filter(item => item.Label === 'ALL'),
            boomMarketData: data6,
            selectedMarkets: ['ALL'],
            checked: boomData.concat(data6).map(item => item.Label)
          })
        } else {
          this.setState({
            isLoading: false,
            snackbar: true,
            errorMessage: 'Boomerang ROI is not available for the selection(s) you have made. Please broaden your search and try again'
          })
        }
      })
      .catch(error => {
        console.log(error)
      })
    }

    this.setState({
      selectedType: value
    })
  }

  handleMarketChange = values => {
    let selectedBoomMarkets = []

    if(this.state.boomData.filter(({Label}) => Label !== 'ALL').length !== 2) {      
    console.log('hello')
    if(values.length === this.state.boomData.filter(item => item.Label !== 'ALL').length) {
      this.setState({
        selectedMarkets: ['ALL'],
        checked: this.state.boomData.map(item => item.Label),
        selectedBoomData: this.state.boomData.filter(item => item.Label === 'ALL')
      })
      console.log('here')
    } else if (
      values.length > 1 && this.state.selectedMarkets.includes('ALL')
      || values.length >= 1 && values.includes('ALL') === false
    ) {
      if(values.filter(item => item !== 'ALL').length < 2) {
        let filter = values.filter(item => item !== 'ALL')[0]

        this.setState({
          selectedBoomData: this.state.boomData.filter(item => item.Label === filter)
        })
      } else {
        selectedBoomMarkets.push(this.state.boomData.filter(item => values.includes(item.Label) && item.Label !== 'ALL'))

        let data = []
        let dataObj = {}
        let dataCategory = []
        let dataLocation = []
        let dataCustPros = []
        let dataListType = []
        let dataOverall = []
        let dataWeekly = []

        for(let i = 0; i < selectedBoomMarkets[0].length; i++) {
          if(this.state.selectedType === 'market') {
            dataCategory.push(selectedBoomMarkets[0][i]['Data_Category'])
            dataCustPros.push(selectedBoomMarkets[0][i]['Data_CustPros'])
            dataLocation.push(selectedBoomMarkets[0][i]['Data_Location'])
            dataListType.push(selectedBoomMarkets[0][i]['Data_ListType'])
            dataOverall.push(selectedBoomMarkets[0][i]['Data_Overall'][0])
            dataWeekly.push(selectedBoomMarkets[0][i]['Data_Weekly'])
          } else {
            dataCategory.push(selectedBoomMarkets[0][i]['Data_Category'])
            dataCustPros.push(selectedBoomMarkets[0][i]['Data_CustPros'])
            dataListType.push(selectedBoomMarkets[0][i]['Data_ListType'])
            dataOverall.push(selectedBoomMarkets[0][i]['Data_Overall'][0])
            dataWeekly.push(selectedBoomMarkets[0][i]['Data_Weekly'])
          }
        }

        // CUSTPROS DATA FOR MULTIPLE MARKET SELECTS
        let custProsNames = dataCustPros[0].map(item => item.Label)
        const custProsArr = []

        for(let i = 0; i < custProsNames.length; i++) {
          const custProsObj = {}
          custProsObj['label'] = custProsNames[i]
          custProsObj['Data'] = []
          for(let j = 0; j < dataCustPros.length; j++) {
            custProsObj['Data'].push(dataCustPros[j].filter(item => item.Label === custProsNames[i])[0])
          }
          custProsArr.push(custProsObj)
        }
        const custProsTotal = [custProsArr.map(item => item.Data.reduce((a,b) => {
          for(const prop in b) {
            if(a.hasOwnProperty(prop) && prop !== 'Label') a[prop] += b[prop]
            else a[prop] = b[prop]
          }
          return a
        }, {}))]

        // LOCATION DATA FOR MULTIPLE MARKET SELECTS
        let locationNames = dataLocation.length > 0 ? dataLocation[0].map(item => item.Label) : []
        const locationsArr = []

        if(locationNames.length > 0) {
          for(let i = 0; i < locationNames.length; i++) {
            const locationObj = {}
            locationObj['label'] = locationNames[i]
            locationObj['Data'] = []
            for(let j = 0; j < dataLocation.length; j++) {
              locationObj['Data'].push(dataLocation[j].filter(item => item.Label === locationNames[i])[0])
            }
            locationsArr.push(locationObj)
          }
        }
        const locationTotal = [locationsArr.map(item => item.Data.reduce((a,b) => {
          for(const prop in b) {
            if(a.hasOwnProperty(prop) && prop !== 'Label' && prop !== 'Market') a[prop] += b[prop]
            else a[prop] = b[prop]
          }
          return a
        }, {}))]

        // CATEGORY DATA FOR MULTIPLE MARKET SELECTS
        let categoryNames = dataCategory[0].map(item => item.Label)
        const categoryArr = []

        for(let i = 0; i < categoryNames.length; i++) {
          const categoryObj = {}
          categoryObj['Data'] = []
          for(let j = 0; j < dataCategory.length; j++) {
            categoryObj['Data'].push(dataCategory[j].filter(item => item.Label === categoryNames[i])[0])
          }
          categoryArr.push(categoryObj)
        }
        const categoryTotal = [categoryArr.map(item => item.Data.reduce((a,b) => {
          for(const prop in b) {
            if(a.hasOwnProperty(prop) && prop !== 'Label') a[prop] += b[prop]
            else a[prop] = b[prop]
          }
          return a
        }, {}))]

        // LIST TYPE DATA FOR MULTIPLE MARKET SELECTS
        let listNames = dataListType[0].map(item => item.Label)
        let listArr = []
        for(let i = 0; i < listNames.length; i++) {
          const listObj = {}
          listObj['Data'] = []
          for(let j = 0; j < dataListType.length; j++) {
            listObj['Data'].push(dataListType[j].filter(item => item.Label === listNames[i])[0])
          }
          listArr.push(listObj)
        }
        const listTotal = [listArr.map(item => item.Data.reduce((a,b) => {
          for(const prop in b) {
            if(a.hasOwnProperty(prop) && prop !== 'Label') a[prop] += b[prop]
            else a[prop] = b[prop]
          }
          return a
        }, {}))]

        // WEEKLY DATA FOR MULTIPLE MARKET SELECTS
        let weeklyLabels = dataWeekly[0].map(item => item.Label)
        let weeklyArr = []
        for(let i = 0; i < weeklyLabels.length; i++) {
          const weeklyObj = {}
          weeklyObj['Data'] = []
          for(let j = 0; j < dataWeekly.length; j++) {
            weeklyObj['Data'].push(dataWeekly[j].filter(item => item.Label === weeklyLabels[i])[0])
          }
          weeklyArr.push(weeklyObj)
        }
        const weeklyTotal = [weeklyArr.map(item => item.Data.reduce((a,b) => {
          for(const prop in b) {
            if(a.hasOwnProperty(prop) && prop !== 'Label') a[prop] += b[prop]
            else a[prop] = b[prop]
          }
          return a
        }, {}))]

        // OVERALL DATA FOR MULTIPLE MARKET SELECTS
        const dataOverallTotal = [dataOverall.reduce((a,b) => {
          for(const prop in b) {
            if(a.hasOwnProperty(prop)) a[prop] += b[prop]
            else a[prop] = b[prop]
          }
          return a
        }, {})]

        dataObj['Data_Overall'] = dataOverallTotal
        dataObj['Data_CustPros'] = custProsTotal[0]
        dataObj['Data_Location'] = locationTotal[0]
        dataObj['Data_Category'] = categoryTotal[0]
        dataObj['Data_ListType'] = listTotal[0]
        dataObj['Data_Weekly'] = weeklyTotal[0]
        data.push(dataObj)

        this.setState({
          selectedBoomData: data
        })

      }

      this.setState({
        selectedMarkets: values.filter(item => item !== 'ALL'),
        checked: values.filter(item => item !== 'ALL'),
      })
    } else {
      this.setState({
        selectedMarkets: ['ALL'],
        checked: this.state.boomData.map(item => item.Label),
        selectedBoomData: this.state.boomData.filter(item => item.Label === 'ALL')
      })
    }
  } else {
    console.log('hi')
    console.log(values)
    console.log(this.state.selectedMarkets)
    if(values.filter(value => value !== 'ALL').length > 0 && values[1] !== 'ALL' && values.filter(value => value !== 'ALL').length < this.state.boomData.filter(({Label}) => Label !== 'ALL').length) {
      console.log('options')
      let filter = values.filter(item => item !== 'ALL')[0]



      this.setState({
        selectedBoomData: this.state.boomData.filter(({Label}) => Label === filter),
        checked: values.filter(item => item !== 'ALL'),
        selectedMarkets: [filter],
      })

      
      
    } else {
      this.setState({
        selectedMarkets: ['ALL'],
        checked: this.state.boomData.map(item => item.Label),
        selectedBoomData: this.state.boomData.filter(item => item.Label === 'ALL')
      })
    }
  }
  }

  handleDeselectMarket = (e, label) => {
    const filteredMarkets = this.state.selectedMarkets.filter(item => item !== label)
    this.handleMarketChange(filteredMarkets)
  }

  handleMailingTypeOpen = (e) => {
    this.setState({
      mailingOpen: true,
      anchor: e.currentTarget,
    })
  }

  changeMailingType = (e) => {
    const {myValue} = e.currentTarget.dataset
    const myHeaders = {'token': Cookies.get('token')}
    let complete = myValue === 'completed' ? 1 : 0

    this.setState({
      marketLoading: true,
    })

    Promise.all([
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/custPros?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/category?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/listType?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/weekly?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/dashboard/${this.state.selectedType}?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomROI/dashboard?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}&complete=${complete}`, {headers: myHeaders}),
    ])
    .then(([response1, response2, response3, response4, response5, response6, response7]) => Promise.all([response1.json(), response2.json(), response3.json(), response4.json(), response5.json(), response6.json(), response7.json()]))
    .then(([data1, data2, data3, data4, data5, data6, data7]) => {
      if(data1) {
        let boomData = []
        let boomObj = {}
        boomObj['Data_Category'] = data3
        boomObj['Data_CustPros'] = data2
        boomObj['Data_Location'] = data7[0].Data_Location
        boomObj['Data_ListType'] = data4
        boomObj['Data_Overall'] = data1[0].Data
        boomObj['Data_Weekly'] = data5
        boomObj['Label'] = 'ALL'
        boomObj['Key'] = data6.length + 1
        boomData.push(boomObj)

        console.log(boomData)

        this.setState({
          marketLoading: false,
          hasData: true,
          boomData: boomData.concat(data6),
          selectedBoomData: boomData.concat(data6).filter(item => this.state.selectedMarkets.includes(item.Label)),
          boomMarketData: data6,
          // days: this.state.endDate.diff(this.state.startDate, 'days')
        })
      } else {
        this.setState({
          isLoading: false,
          snackbar: true,
          errorMessage: 'Boomerang ROI is not available for the selection(s) you have made. Please broaden your search and try again'
        })
      }
    })

    this.setState({
      mailingType: myValue,
    })

    this.handleMailingTypeClose()
  }

  handleMailingTypeClose = () => {
    this.setState({
      mailingOpen: false,
    })
  }

  handleSnackbarClose = (e, reason) => {
    if(reason === 'clickaway') {
      this.setState({
        snackbar: false,
      })
    }
    this.setState({
      snackbar: false
    })
  }

  renderMarketSelectOptions = () => {
    return (
      this.state.boomData.map(item => (
        <MenuItem
          key={item.Key}
          value={item.Label}
          style={this.state.selectedMarkets.indexOf(item.Label) === -1 ? {} : {fontWeight: 'bold'}}
        >
          <div style={{display: 'inline-flex', alignItems: 'center', justifyContent: 'space-between'}}>
            <Checkbox
              edge="start"
              size="small"
              checked={this.state.checked.indexOf(item.Label) !== -1}
              disableRipple
            />
            <Typography variant="caption" style={{width: 200}}>
              {item.Label === 'ALL' ? `${item.Label} ${this.state.selectedType.toUpperCase()}S` : item.Label}
            </Typography>
          </div>
        </MenuItem>
      ))
    )
  }

  getSelectedData = data => {
    const metricArr = []

    if(data[0]) {
      for(let i = 0; i < data.length; i++) {
        for(let j = 0; j < data[i].length; j++) {
          if(metricArr.map(item => item.label).indexOf(data[i][j].Label) === -1) {
            metricArr.push({label: data[i][j].Label})
          }
        }
      }
    }

    for(let i = 0; i < metricArr.length; i++) {
      metricArr[i]['data'] = []
      for(let j = 0; j < data.length; j++) {
        for(let k = 0; k < data[j].length; k++) {
          if(metricArr[i].label === data[j][k].Label) {
            metricArr[i]['data'].push(data[j][k])
          }
        }
      }
    }

    metricArr.forEach(element => {
      element['total'] = element.data.reduce((a,b) => {
        for(const prop in b) {
          if(a.hasOwnProperty(prop)) a[prop] += b[prop]
          else a[prop] = b[prop]
        }
        return a
      }, {})
    })

    return metricArr
  }

  render() {
    const { classes } = this.props
    const overallData = this.getSelectedData(this.state.selectedBoomData ?  this.state.selectedBoomData.map(item => item.Data_Overall) : [])
    const custProsData = this.getSelectedData(this.state.selectedBoomData ?  this.state.selectedBoomData.map(item => item.Data_CustPros) : [])
    const categoriesData = this.getSelectedData(this.state.selectedBoomData ?  this.state.selectedBoomData.map(item => item.Data_Category) : [])
    const listData = this.getSelectedData(this.state.selectedBoomData ? this.state.selectedBoomData.map(item => item.Data_ListType) : [])
    const locationData = this.state.marketLoading ? [] : this.state.selectedType === 'market' ? this.getSelectedData(this.state.selectedBoomData ? this.state.selectedMarkets.indexOf('ALL') !== -1 ? this.state.selectedBoomData.map(item => item.Data_Location) : this.state.selectedBoomData.map(item => item.Data_Location.filter(item => this.state.selectedMarkets.indexOf(item.Market) !== -1)) : []) : []
    const weeklyData = this.getSelectedData(this.state.selectedBoomData ? this.state.selectedBoomData.map(item => item.Data_Weekly) : [])


    return (
      <div>
        {
          this.state.isLoading ? <div className={classes.loader}><CircularProgress/></div> :
          this.state.hasData ?
            <Fragment>
              <Snackbar
                open={this.state.snackbar}
                autoHideDuration={4500}
                onClose={this.handleSnackbarClose}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
              >
                <SnackbarContent
                  message={
                    <div style={{display: 'flex'}}>
                      <WarningIcon className={classes.error} fontSize="small" fontWeight="bold"/>
                      <span style={{fontWeight: 600, marginLeft: '15px'}}>{this.state.errorMessage}</span>
                    </div>
                  }
                  style={this.props.darkMode ?
                    {color: '#fff', backgroundColor: '#ff9800'}
                  :
                    {backgroundColor: '#ff9800'}
                  }
                />
              </Snackbar>
              {this.state.marketLoading ?
                <div>
                  <CircularProgress className={classes.loader} style={{position: 'absolute', zIndex: 10000}}/>
                </div>
              :
                <div/>
              }

              <div style={this.state.marketLoading ? {opacity: 0.5} : {}}>
                <Fade in={true}>
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div>
                      <Typography className={this.props.darkMode ? classes.pageTitleDark : classes.pageTitle} style={{marginTop: '25px'}}>
                        <BoomIcon className={this.props.darkMode ? classes.pageIconDark : classes.pageIcon} />
                        <span style={{marginLeft: '5px'}}>BOOMERANG</span>
                      </Typography>

                      <Typography variant="caption" style={{fontSize: '12px', marginTop: '10px', width: 100}}>
                        Go to the Boomerang Lost Opportunity Screen <Link className={this.props.darkMode ? classes.lostOppLinkDark : classes.lostOppLink} href={'/boomerang/lost-opp'}>HERE</Link>
                      </Typography>
                    </div>
                    <div style={{marginTop: '30px', textAlign: 'right'}}>
                      {this.state.allMailingsToggle === 1 ?
                        <Typography variant="caption">
                            Showing <span onClick={this.handleMailingTypeOpen} className={this.props.darkMode ? classes.mailingTypeDark : classes.mailingType} style={{display: "inline-flex"}}>{`${this.state.mailingType.toUpperCase()} MAILINGS`}
                            {this.state.mailingType.toUpperCase() === "COMPLETED" ?
                              <StyledTooltip
                                title={<Typography variant="caption">Our reporting is based on the expected in-home date of the mailed piece, which typically <span style={{fontWeight: "bold", color: "#000"}}>expires</span> within 14-30 days <strong style={{color: "#000"}}>(based on your business rules)</strong>. First-class mail takes 4 days to print and mail, while standard mail takes 10 or more days. Allow up to 4 days after the last transaction date <strong style={{color: "#000"}}>we have received</strong> to see results from that day's transactions. Results from a specific day's mailing may take up to 45 days to appear if you transmit data weekly and the expiration period is 30 days.</Typography>}
                                placement="bottom-end"                    
                              >
                                <InfoOutlinedIcon style={{fontSize: "17px"}}/>
                              </StyledTooltip>
                            :
                              null
                            }
                            </span> from:
                        </Typography>
                      :
                        <Typography variant="caption">
                            Showing <span style={{color: '#001d84', display: "inline-flex"}}>{`${this.state.mailingType.toUpperCase()} MAILINGS`}
                            <StyledTooltip
                                title={<Typography variant="caption">Our reporting is based on the expected in-home date of the mailed piece, which typically <span style={{fontWeight: "bold", color: "#000"}}>expires</span> within 14-30 days <strong style={{color: "#000"}}>(based on your business rules)</strong>. First-class mail takes 4 days to print and mail, while standard mail takes 10 or more days. Allow up to 4 days after the last transaction date <strong style={{color: "#000"}}>we have received</strong> to see results from that day's transactions. Results from a specific day's mailing may take up to 45 days to appear if you transmit data weekly and the expiration period is 30 days.</Typography>}
                                placement="bottom-end"                    
                              >
                                <InfoOutlinedIcon style={{fontSize: "17px"}}/>
                              </StyledTooltip>
                            </span> from:
                        </Typography>
                      }
                        <Menu
                          anchorEl={this.state.anchor}
                          open={this.state.mailingOpen}
                          onClose={this.handleMailingTypeClose}
                        >
                          <MenuItem onClick={e => this.changeMailingType(e)} data-my-value={'completed'}><Typography variant="caption">COMPLETE MAILINGS</Typography></MenuItem>
                          <MenuItem onClick={e => this.changeMailingType(e)} data-my-value={'all'}><Typography variant="caption">ALL MAILINGS</Typography></MenuItem>
                        </Menu>
                      <div style={{display: 'flex', marginTop: '2px'}}>
                        {/* MARKET vs LOCATION SELECT */}
                        <Select
                          variant="outlined"
                          value={this.state.selectedType}
                          onChange={e => this.handleSelectedType(e.target.value)}
                          input={<StyledInput style={{width: '150px'}}/>}
                          style={{marginRight: '15px', textAlign: 'left'}}
                        >
                          <MenuItem value={'market'}>
                            <div style={{padding: '5px 15px 0px 15px'}}>
                              <Typography variant="caption">
                                BY MARKET
                              </Typography>
                            </div>
                          </MenuItem>
                          <MenuItem value={'location'}>
                            <div style={{padding: '5px 15px 0px 15px'}}>
                              <Typography variant="caption">
                                BY LOCATION
                              </Typography>
                            </div>
                          </MenuItem>
                        </Select>
                        {/* MARKET SELECT */}
                        <div style={{marginRight: '15px', textAlign: 'left'}}>
                          <Select
                            multiple
                            value={this.state.selectedMarkets}
                            onChange={e => this.handleMarketChange(e.target.value)}
                            input={<StyledInput style={{minWidth: 200, maxWidth: 350}}/>}
                            renderValue={selected => (
                              selected.length > 1 ?
                                <div style={{padding: '5px 0px 0px 20px'}}>
                                  <Typography variant="caption">{`${selected.length} ${this.state.selectedType.toUpperCase()}S`} SELECTED</Typography>
                                </div>
                              :
                                <div style={{padding: '5px 0px 0px 20px'}}>
                                  {selected.map(value => (
                                    <Typography key={value} variant="caption">{value === 'ALL' ? `${value} ${this.state.selectedType.toUpperCase()}S` : value}</Typography>
                                  ))}
                                </div>
                            )}
                          >
                            {this.renderMarketSelectOptions()}
                          </Select>
                        </div>
                        <div style={{width: 500}}>
                          <DayPicker
                            startDate={this.state.minYearmo}
                            endDate={this.state.maxYearmo}
                            maxSliderValue={this.state.days}
                            handleDateRange={this.handleDateRange}
                            handleSliderDateChange={this.handleSliderDateChange}
                            handleDateClear={this.handleDateClear}
                            restoreHidden={this.state.restoreHidden}
                            allowBeforeJan={false}
                            maxDate={'boom'}
                            marketLoading={this.state.marketLoading}
                          />
                        </div>
                      </div>
                      {this.state.marketLoading ? <div/> :
                        <div style={{float: 'right'}}>
                          {console.log(this.state.selectedMarkets)}
                          <RoiExcel
                            pageName={'BOOMERANG'}
                            markets={this.state.selectedMarkets}
                            selectedType={this.state.selectedType}
                            startDate={this.state.startDate.format('MMM DD, YYYY')}
                            endDate={this.state.endDate.format('MMM DD, YYYY')}
                            selectedData={this.state.selectedBoomData[0]}
                            roasFlag={JSON.parse(localStorage.getItem('currentUser'))[0].roi_use_roas}
                          />
                        </div>
                      }
                    </div>


                  </div>
                </Fade>                                
                <div
                  style={this.state.selectedBoomData.length > 0 ?
                    (overallData[0].total.HO_Count > 7500 && JSON.parse(localStorage.getItem('currentUser'))[0].roi_use_roas !== 1) || this.state.bUnit === 3 ? {display: 'none'} : {marginTop: '15px'} : {}}
                >
                  <Fade in={true} timeout={2000} >
                    <div className={classes.warning}>
                      <div style={{margin: '1em', display: 'inline-flex', alignItems: 'center'}}>
                        <WarningIcon style={{color: '#de9f10', fontSize: '25px'}}/>
                        <Typography align="center" variant="caption" style={{marginLeft: '5px', fontWeight: 'bold', fontSize: '15px'}}>
                          Due to the low holdout quantity, <span style={{color: "#d13e34"}}>the incremental metrics may not be statistically significant.</span>
                        </Typography>
                      </div>
                    </div>
                  </Fade>
                </div>
                <br />
                {console.log(this.state.selectedBoomData.map(({Data_Overall}) => Data_Overall[0]))}
                <Stats
                  data={
                    this.state.selectedBoomData.length > 0 ?
                    this.state.selectedBoomData.map(item => item.Data_Overall[0]).reduce((a,b) => {
                      for(const prop in b) {
                        if(a.hasOwnProperty(prop)) a[prop] += b[prop]
                        else a[prop] = b[prop]
                      }
                      return a
                    }, {}) : {}
                  }
                    darkMode={this.props.darkMode}
                />
                <br />
                <Fade in={true} timeout={500}>
                  <Typography align="center">
                    On {this.state.mailingType} promotions, a total spend of <span style={{color: 'rgba(255, 0, 0, 0.7)'}}>
                  ${this.state.selectedBoomData.length > 0 ?
                    this.state.selectedBoomData.map(item => item.Data_Overall[0]).reduce((a,b) => {
                      for(const prop in b) {
                        if(a.hasOwnProperty(prop)) a[prop] += b[prop]
                        else a[prop] = b[prop]
                      }
                      return a
                    }, {}).Cost.toLocaleString('en', {maximumFractionDigits: 0})
                    : 0
                  }
                </span> has yielded <span style={{color: 'rgba(113, 177, 40)', fontWeight:'bold'}}>
                  ${this.state.selectedBoomData.length > 0 ?
                    this.state.selectedBoomData.map(item => item.Data_Overall[0]).reduce((a,b) => {
                      for(const prop in b) {
                        if(a.hasOwnProperty(prop)) a[prop] += b[prop]
                        else a[prop] = b[prop]
                      }
                      return a
                    }, {}).Sales.toLocaleString('en', {maximumFractionDigits: 0})
                    : 0
                  }
                </span> in sales to responders (at a {this.state.selectedBoomData.length > 0 ?
                  (this.state.selectedBoomData.map(item => item.Data_Overall[0]).reduce((a,b) => {
                    for(const prop in b) {
                      if(a.hasOwnProperty(prop)) a[prop] += b[prop]
                      else a[prop] = b[prop]
                    }
                    return a
                  }, {}).Responses / this.state.selectedBoomData.map(item => item.Data_Overall[0]).reduce((a,b) => {
                    for(const prop in b) {
                      if(a.hasOwnProperty(prop)) a[prop] += b[prop]
                      else a[prop] = b[prop]
                    }
                    return a
                  }, {}).Mailed * 100).toFixed(1)
                  : 0
                }% response rate).<br/> Of the sales to responders, <span style={{color: 'rgba(201, 182, 0, 1)', fontWeight:'bold'}}>
                  ${this.state.selectedBoomData.length > 0 && ((overallData[0].total.Responses / overallData[0].total.Mailed) - (overallData[0].total.HO_Resp / overallData[0].total.HO_Count)) > 0 ?
                    Math.round((overallData[0].total.Sales / (overallData[0].total.Responses / overallData[0].total.Mailed)) * ((overallData[0].total.Responses / overallData[0].total.Mailed) - (overallData[0].total.HO_Resp / overallData[0].total.HO_Count))).toLocaleString('en')
                    : 0
                  }
                </span> are purely incremental.
                  </Typography>
                </Fade>
                <br />
                <Fade in={true}>                
                  <CustProsTable 
                    custProsData={this.state.selectedBoomData.length > 0 ? custProsData.map(item => item.total) : []} 
                    darkMode={this.props.darkMode} 
                    dbO={JSON.parse(localStorage.getItem('currentUser'))[0].db_owner}
                    roasFlag={JSON.parse(localStorage.getItem('currentUser'))[0].roi_use_roas}
                    bUnit={this.state.bUnit}
                  />
                </Fade>
                <br />
                <br />
                <Fade in={true}>
                  <RoiCharts
                    chartsData={this.state.selectedBoomData.length > 0 ? overallData : []}
                    chartsData2={this.state.selectedBoomData.length > 0 ? custProsData : []}
                    custProsChartData={this.state.selectedBoomData.length > 0 ? custProsData : []}
                    listTypeChartData={this.state.selectedBoomData.length > 0 ? listData : []}
                    labels={['Total Spend', 'Incremental Sales']}
                    labels2={['Customer Responses', 'Prospect Responses']}
                    weeklydata={this.state.selectedBoomData.length > 0 ? weeklyData : [0]}
                    darkMode={this.props.darkMode}
                    pageName={"Boom"}
                  />
                </Fade>
                <br />
                <br />

                <Fade in={true}>
                  <CategoriesTable 
                    data={this.state.selectedBoomData.length > 0 ? categoriesData : []} 
                    darkMode={this.props.darkMode}
                    roasFlag={JSON.parse(localStorage.getItem('currentUser'))[0].roi_use_roas}
                    bUnit={this.state.bUnit}
                  />
                </Fade>
                {console.log('here')}
                <br />
                <br />
                <Fade in={true}>
                  <ListTable data={this.state.selectedBoomData.length > 0 ? listData : []} darkMode={this.props.darkMode} roasFlag={JSON.parse(localStorage.getItem('currentUser'))[0].roi_use_roas}/>
                </Fade>
                <br />
                <br />
                <Fade in={true}>
                <div style={this.state.selectedType === 'market' ? {} : {display: 'none'}}>
                  <LocationsTable data={this.state.selectedBoomData.length > 0 ? locationData : []} darkMode={this.props.darkMode}/>
                  </div>
                </Fade>
                {console.log(locationData)}
              </div>
              <UpdatedThrough footerDate={this.state.updatedDate} />
            </Fragment>
          : 
          <div className={classes.unavailable}>
             <AskReport />
            </div>
        }
      </div>
    )
  }
}

export default withStyles(styles)(Boom)
