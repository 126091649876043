import React, { Component } from 'react'
import Chart from 'react-apexcharts'

class LineGraph extends Component {
  constructor(props) {
    super(props)
    this.state = {
      series: props.series,
      options: {
        chart: {
          type: 'line',
          // height: 350,
          // width: '80%',
          toolbar: {
            show: props.toolbar,
            tools: {
              download: false,
            }
          },
        },
        colors: props.colors,
        // colors: ['#ffa03b', '#d38de3'],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight',

        },

        title: {
          text: props.title[0],
          align: props.title[1],
          style: {
            fontSize: '14px'
          }
        },
        xaxis: {
          type: 'datetime',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          tickAmount: 4,
          floating: false,
          labels: {
            formatter: value => {
              if(props.title[0].includes('YOY')) {
                return Math.round(value) + '%'
              } else {
                return '$' + value.toLocaleString('en')
              }
            },
            style: {
              colors: '#8e8da4',
            },
            offsetY: -7,
            offsetX: 0,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false
          }
        },
        fill: {
          opacity: 0.5
        },
        tooltip: {
          x: {
            format: "MMM yyyy",
          },
          y: {
            formatter: value => {
              if(props.title[0].includes('YOY')) {
                console.log(value)
                if(value !== null) {
                  return value + '%'
                } else {
                  return 'N/A'
                }
              } else {
                if(value !== null) {
                  return '$' + value.toLocaleString('en')
                } else {
                  return 'N/A'
                }
              }
            },
          },
          fixed: {
            enabled: false,
            position: 'topRight'
          }
        },
        grid: {
          yaxis: {
            lines: {
              offsetX: -30
            }
          },
          padding: {
            // left: 10,
            right: 15,
            // left: 20
          }
        }
      },
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return (nextProps.series !== prevState.series) ?
    {
      series: nextProps.series,
    } : null
  }

  render() {
    return(
      <div style={{padding: '1em'}}>
        <Chart style={{padding: '1em'}} options={this.state.options} series={this.state.series} type="line" height={this.props.height}/>
      </div>
    )
  }
}

export default LineGraph
