import React, { Component } from 'react'
import styled from 'styled-components'
import { withStyles, Button, Card, CardContent, CardMedia, Typography, TextField, Grid, InputAdornment, Link, Snackbar, SnackbarContent, Tooltip } from '@material-ui/core'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import LockIcon from '@material-ui/icons/Lock'
import ErrorIcon from '@material-ui/icons/Error'
import logoImg from '../../assets/SmartDash3ColorVector_RGB_NoTagline.png'
import sdLogo from '../../assets/SmartDash_darkbg.png'
import lsLogo from '../../assets/lsDirectNEW.png'
// import logoImg2 from '../assets/dashLogo2.png'

const styles = theme => ({
  card: {
    borderRadius: '7px',
    // backgroundColor: '#fff',
    [theme.breakpoints.up('sm')]: {
      width: '45%',
      height: 700,
    },
    [theme.breakpoints.down('xs')]: {
      width: 400,
      height: 700,
    }
  },
  content: {
    marginTop: '35px',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      marginTop: '25px'
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '48px'
    }
  },
  input: {
    width: '75%',
    [theme.breakpoints.down('xs')]: {
      width: '85%'
    }
  },
  media: {
    marginTop: '25px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '50px',
    }
  },
  error: {
    color: '#fff'
  },
  login: {
    [theme.breakpoints.down('xs')]: {
      width: '85%'
    }
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      color: '#10a1da',
      cursor: 'pointer',
      textDecoration: 'none',
    }
  },
  copyright: {
    marginTop: '15px',
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      marginTop: '35px',
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '35px',
    },
  }
})

const LoginButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 13,
    width: '75%',
    height: 50,
    border: '1px solid',
    color: '#233083',
    '&:hover': {
      color: '#fff',
      // background: `linear-gradient(45deg, #10a1da, #0364a6)`,
      backgroundColor: '#10a1da',
      borderColor: '#10a1da',
      // borderColor: '#233083',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none'
    },
  }
})(Button)

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: #10a1da
    }
  }
`

class Login extends Component {
  constructor(props) {
    super(props)

    this.state = {
      username: '',
      password: '',
      loginErrors: '',
      open: false,
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  handleKeyPress = (e) => {
    if(e.key === 'Enter') {
      this.handleSubmit()
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleSubmit(e) {
    const {username, password} = this.state

    let data = {
      'password': password,
      'username': username
    }

    if(data.username === '' || data.password === '') {
      this.setState({
        loginErrors: 'Username and/or Password cannot be left blank',
        open: true
      })
    } else {
      fetch('https://api.dev.lsdirect.com/smartdash/V1/index.cfm/authenticate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(data)
      })
      .then(response => {
        if(response.ok === true) {
          return response.json()
        }
      })
      .then(data => {
        this.props.handleSuccess(data)
        console.log(data)
      })
      .catch(error => {
        this.setState({
          loginErrors: "The Username and/or Password you've entered is incorrect",
          open: true,
        })
      })
    }
  }

  handleClose = (e) => {
    this.setState({
      open: false
    })
  }

  render() {
    const { classes } = this.props

    return(
      <div className={classes.root}>
        <Grid
          container
          justify='center'
          alignItems='center'
          direction='column'
          style={{minHeight: '100vh'}}
        >
        <Card className={classes.card}>
        <div style={{marginTop: '90px', textAlign: 'center', justifyContent: 'center'}}>
          <img
            style={{width: '50%'}}
            src={lsLogo}
            alt=""
          />
        </div>
          {/* <CardMedia
            component="img"
            width={'50%'}
            className={classes.media}
            image={logoImg}
          /> */}
          {/* <CardMedia
            component="img"
            width={'50%'}
            className={classes.media}
            image={logoImg2}
          /> */}
          <CardContent
            className={classes.content}
            // style={{marginTop: '75px'}}
          >
            <Grid container
              spacing={2}
              direction='column'
              justify='center'
              // style={{borderRadius: '4px'}}
            >
              <br/>
              <Grid
                item
                style={{
                  backgroundColor: '#233083',
                  borderRadius: '4px',
                  marginLeft: '13%',
                  marginRight: '13%',
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <img style={{height: '20px', marginRight: '10px'}} src={sdLogo} alt=""/>
                <Typography
                  variant="caption"
                  style={{fontWeight: 'bold', fontSize: '20px', color: '#fff'}}
                >
                  CLIENT LOGIN
                </Typography>
              </Grid>
              <br/>
              <Grid item>
                <StyledTextField
                  name="username"
                  type="username"
                  className={classes.input}
                  placeholder="Username"
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <AccountCircleIcon style={{color: '#10a1da'}} />
                      </InputAdornment>
                    )
                  }}
                  value={this.state.username}
                  onChange={this.handleChange}
                  onKeyPress={this.handleKeyPress}
                  required
                />
              </Grid>
              <Grid item>
                <StyledTextField
                  name="password"
                  type="password"
                  className={classes.input}
                  placeholder="Password"
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <LockIcon style={{color: '#10a1da'}} />
                      </InputAdornment>
                    )
                  }}
                  value={this.state.password}
                  onChange={this.handleChange}
                  onKeyPress={this.handleKeyPress}
                  required
                />
              </Grid>
              <Grid item>
                <LoginButton
                  type="submit"
                  onClick={this.handleSubmit}
                  className={classes.login}
                >
                  Login
                </LoginButton>
              </Grid>
              {/* <Grid item style={{marginRight: '12.25%'}}>
                <div style={{textAlign: 'right'}}>
                  <Typography variant="caption">
                    <span>Forgot Password? </span>
                    <Tooltip title={<Typography variant="caption" style={{fontSize: '10px'}}>COMING SOON... Please Contact support@lsdirect.com</Typography>}>
                      <Link className={classes.link}>Click Here</Link>
                    </Tooltip>
                  </Typography>
                </div>
              </Grid> */}
              <br/>
              <Snackbar
                open={this.state.open}
                autoHideDuration={4500}
                onClose={this.handleClose}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
              >
                <SnackbarContent
                  message={
                    <div style={{display: 'flex'}}>
                      <ErrorIcon className={classes.error} fontSize="small" fontWeight="bold"/>
                      <span style={{fontWeight: 600, marginLeft: '15px'}}>{this.state.loginErrors}</span>
                    </div>
                  }
                  style={{
                    backgroundColor: '#ed263d'
                  }}
                />
              </Snackbar>
            </Grid>
          </CardContent>
          <div className={classes.copyright}>
            <Typography variant="caption" style={{color: '#bfbfbf'}}>
              2023 © LS Direct Marketing
            </Typography>
          </div>
        </Card>
      </Grid>
      </div>
    )
  }
}

export default withStyles(styles)(Login)
