import React, { Component } from 'react'

class PromoCal extends Component {
  constructor() {
    super()
  }

  render() {
    return (
      <div>
        hello
      </div>
    )
  }
}

export default PromoCal
