import React, { Component } from 'react'
import {
  withStyles,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Paper
} from '@material-ui/core'

// const styles = theme => ({
//
// })

class LostOppTable extends Component {
  constructor(props) {
    super(props)
  }

  render() {

    return(
      <div>
        <Typography></Typography>
        <Paper>
          <Table size="small">
            <TableHead>
              <TableRow style={{height: 50, backgroundColor: '#007ac9'}}>
                <TableCell style={{width: 50}} align="center"><Typography style={{color: '#fff', fontWeight: 'bold'}} variant="caption">Decile</Typography></TableCell>
                <TableCell style={{width:250, borderLeft:  '1px solid #dcdbdc'}} align="right"><Typography style={{color: '#fff', fontWeight: 'bold'}} variant="caption">Average Lost Opportunity Counts (By Month)</Typography></TableCell>
                <TableCell style={{width:250, borderLeft:  '1px solid #dcdbdc'}} align="right"><Typography style={{color: '#fff', fontWeight: 'bold'}} variant="caption">Average Incremental Sales(By Month)</Typography></TableCell>
                <TableCell style={{width:250, borderLeft:  '1px solid #dcdbdc'}} align="right"><Typography style={{color: '#fff', fontWeight: 'bold'}} variant="caption">Incremental Return On Ad Spend</Typography></TableCell>
              </TableRow>              
            </TableHead>

            <TableBody>
              {this.props.data.map(row => (
                <TableRow key={row.decID}>
                  <TableCell align="center"><Typography variant="caption">{row.decID}</Typography></TableCell>
                  <TableCell style={{borderLeft:  '1px solid #dcdbdc'}} align="right"><Typography variant="caption">{Math.round(row.lostOpp.reduce((a,b) => a + b, 0)/ this.props.dataCount).toLocaleString('en')}</Typography></TableCell>
                  <TableCell style={{borderLeft:  '1px solid #dcdbdc'}} align="right"><Typography variant="caption">{'$' + Math.round(row.cpp * (row.lostOpp.reduce((a,b) => a + b, 0)/ this.props.dataCount) * row.roi).toLocaleString('en')}</Typography></TableCell>
                  <TableCell style={{borderLeft:  '1px solid #dcdbdc'}} align="right"><Typography variant="caption">{Math.round(row.roi * 100) + '%'}</Typography></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    )
  }
}

export default LostOppTable
