import React, { useState, useEffect, Fragment } from 'react'
import {  
  makeStyles,
  Typography,  
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  CircularProgress,  
} from '@material-ui/core'
import {Public} from '@material-ui/icons'
import BarChart from '../charts/BarChart2'
import moment from 'moment'
import MonthPicker from '../calendar/MonthPicker'
import DemoExcel from './DemoExcel'
import Cookies from 'js-cookie'
import '../../assets/slider.css'

const useStyles = makeStyles((theme) => ({
  mainLoader: {
    marginTop: '25%',
    marginLeft: '50%'
  },
  pageTitle: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '17px',
    fontWeight: 'light',
    color: 'rgba(115, 115, 115, 0.9)'
  },
  pageTitleDark: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '17px',
    fontWeight: 'light',
    color: 'rgba(255, 255, 255, 0.9)'
  },
  pageIcon: {
    fontSize: '15px',
    marginTop: '21px',
    marginRight: '2px',
  },
  pageIconDark: {
    fontSize: '15px',
    color: 'rgba(255, 255, 255, 0.9)',
    marginTop: '5px',
    marginRight: '2px',
  },
  select: {
    width: '200px'
  },
  chart: {
    width: '85%',
    padding: '2em',
    height: 800,
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  list: {
    margin: theme.spacing(1),
    border: '1px solid #c4c4c4',
    borderRadius: 5,
    width: "100%",    
    overflowY: 'auto'
  },
  listLabel: {
    margin: theme.spacing(1),
    color: '#757575',
  },
  listLabelDark: {
    margin: theme.spacing(1),
    color: '#fff',
  },
  checkbox: {
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&:focus': {
      backgroundColor: 'transparent'
    }
  },
  loader: {
    marginLeft: '42.5%',
    marginTop: '42.5%'
  },
  datePicker: {
    height: 60,
    border: '1px solid #c4c4c4',
    borderRadius: 5,
    width: '100%',
    paddingTop: '15px',
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  }
}))

const DemoByLoc = () => {
  const token = Cookies.get("token")
  const classes = useStyles()
  const gen_pop = localStorage.getItem("currentUser") ? JSON.parse(localStorage.getItem("currentUser"))[0].dbl_gen_pop : 0  
  const initialMinYearmo = moment().subtract(1, "years").startOf("year")
  const initialMaxYearmo = moment()
  const [minYearmo, setMinYearmo] = useState(moment().subtract(1, "years").startOf("year"))
  const [maxYearmo, setMaxYearmo] = useState(moment())
  const [months, setMonths] = useState(maxYearmo.diff(minYearmo, "months"))
  const [selectedMetricData, setSelectedMetricData] = useState([])
  const [data, setData] = useState({})  
  const [dataByMarket, setDataByMarket] = useState(null)  
  const [dataByLocation, setDataByLocation] = useState(null)  
  const [selectedMetric, setSelectedMetric] = useState("Age Range")
  const [marketsListHash, setMarketsListHash] = useState({})  
  const [selectedType, setSelectedType] = useState("market")
  const [checked, setChecked] = useState([])
  const [dataLoad, setDataLoad] = useState(false)
  
  
  const getDemographicsData = () => {          
    const request = Promise.all([
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/demoByLoc/market?min_yearmo=${initialMinYearmo.format("YYYY-MM")}&max_yearmo=${initialMaxYearmo.format("YYYY-MM")}&dbl_gen_pop=${gen_pop}`, {headers: {token: token}}),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/demoByLoc/location?min_yearmo=${initialMinYearmo.format("YYYY-MM")}&max_yearmo=${initialMaxYearmo.format("YYYY-MM")}&dbl_gen_pop=${gen_pop}`, {headers: {token: token}}),
    ])
    
    request.then(([response, response2]) => Promise.all([response.json(), response2.json()]))
    .then(([data, data2]) => {
      setData(prevState => ({...prevState, "market": data, "location": data2}))
      setDataByMarket(data)      
      setDataByLocation(data2)      
      setChecked(data.map(({Label}) => Label))
      setSelectedMetricData(data.map(({Data}) => Data.filter(({MetricType}) => MetricType === selectedMetric)))      
      
      data.forEach(({Label}) => {
        setMarketsListHash(prevState => ({...prevState, [Label]: 1}))
      })            
    })
  }
  
  useEffect(() => getDemographicsData(), [])  
  
  const calculateCustomerPct = (data, key) => {        
    let customerCounts = []
    if(data.length > 0) {      
      let metricLength = data.map((item) => item[0].Data.map(({MetricValue}) => MetricValue).length)[0]
    
      for(let i = 0; i < metricLength; i++) {
        customerCounts.push(data.map((item) => item[0].Data[i][key]).reduce((a,b) => a + b, 0))
      }
    }
    
    return customerCounts.map((item => item / customerCounts.reduce((a,b) => a + b, 0) * 100))
  }

  const calculateGlobalPct = (data, key) => {    
    let globalCounts = []   
    let metricData = data.map(({Data}) => Data.filter(({MetricType}) => MetricType === selectedMetric))
    let metricLength = metricData.map((item) => item[0].Data.map(({MetricValue}) => MetricValue).length)[0]

    for(let i = 0; i < metricLength; i++) {
      globalCounts.push(metricData.map(item => item[0].Data[i][key]).reduce((a,b) => a + b, 0))
    }

    return globalCounts.map((item) => item / globalCounts.reduce((a,b) => a + b, 0) * 100)    
  }

  const calculateCustomerBasePct = (data, key) => {
    let baseCounts = []
    if(data.length > 0) {      
      let metricLength = data.map((item) => item[0].Data.map(({MetricValue}) => MetricValue).length)[0]
    
      for(let i = 0; i < metricLength; i++) {
        baseCounts.push(data.map((item) => item[0].Data[i][key]).reduce((a,b) => a + b, 0))
      }
    }
    
    return baseCounts.map((item => item / baseCounts.reduce((a,b) => a + b, 0) * 100))
  }  

  const calculateBoomPct = (data, key) => {
    let boomCount = []

    if(data.length > 0) {
      let metricLength = data.map(item => item[0].Data.map(({MetricValue}) => MetricValue).length)[0]

      for(let i = 0; i < metricLength; i++) {
        boomCount.push(data.map(item => item[0].Data[i][key]).reduce((a,b) => a + b, 0))
      }
      return boomCount.map((item => item / boomCount.reduce((a,b) => a + b, 0) * 100))
    } else {
      return []
    }
  }

  const getChartSeries = () => {
    const series = []
    
    if(gen_pop === 0) {
      series.push(
        {name: "% of Customers", type: "column", data: calculateCustomerPct(selectedMetricData, "Customers").map(item => parseFloat(item.toFixed(2)))},
        {name: "% of Customers Baseline", type: "column", data: calculateCustomerBasePct(selectedMetricData, "Customers_Baseline").map(item => parseFloat(item.toFixed(2)))},
        {name: "% of Boomerang Browsers", type: "column", data: calculateBoomPct(selectedMetricData, "Boomerang").map(item => parseFloat(item.toFixed(2)))},
      )
    } else if(gen_pop === 2 && selectedType === 'location'){       
        let globalData = [...dataByLocation]

      series.push(
        {name: "% of Customers", type: "column", data: calculateCustomerPct(selectedMetricData, "Customers").map(item => parseFloat(item.toFixed(2)))},
        {name: "% of General Population", type: "column", data: calculateGlobalPct(globalData.filter(({Label}) => checked.indexOf(Label) !== -1), "Customers_Baseline").map(item => parseFloat(item.toFixed(2)))},
        {name: "% of Boomerang Browsers", type: "column", data: calculateBoomPct(selectedMetricData, "Boomerang").map(item => parseFloat(item.toFixed(2)))},
      )
    } else {
      let globalData = [...dataByMarket]
      let filterMarkets = []
      
      Object.keys(marketsListHash).forEach(market => {
        if(marketsListHash[market] === 0) filterMarkets.push(market)
      })      

      console.log(selectedMetricData)
      console.log(calculateCustomerPct(selectedMetricData, 'Customers'))

      // console.log(marketsListHash)
      // console.log(filterMarkets)
      // console.log(globalData.filter(({Label}) => filterMarkets.indexOf(Label) === -1))
      // console.log(calculateGlobalPct(globalData.filter(({Label}) => filterMarkets.indexOf(Label) === -1), 'Customers_Baseline'))

      series.push(
        {name: "% of Customers", type: "column", data: calculateCustomerPct(selectedMetricData, "Customers").map(item => parseFloat(item.toFixed(2)))},
        {name: "% of General Population", type: "column", data: calculateGlobalPct(globalData.filter(({Label}) => filterMarkets.indexOf(Label) === -1), "Customers_Baseline").map(item => parseFloat(item.toFixed(2)))},
        {name: "% of Boomerang Browsers", type: "column", data: calculateBoomPct(selectedMetricData, "Boomerang").map(item => parseFloat(item.toFixed(2)))},
      )              
    }

    return series
  }

  const handleLocationTypeChange = (e) => {
    const { value } = e.target        

    Object.keys(marketsListHash).forEach((market) => {
      marketsListHash[market] = 0
    })

    setSelectedType(value)
    setSelectedMetricData(data[value].map(({Data}) => Data.filter(({MetricType}) => MetricType === selectedMetric)))
    setChecked(data[value].map(({Label}) => Label))

    if(value === "location") {
      data[value].forEach(({Market}) => {
        setMarketsListHash(prevState => ({...prevState, [Market]: marketsListHash[Market]+=1}))
      })
    } else {
      data[value].forEach(({Label}) => {
        setMarketsListHash(prevState => ({...prevState, [Label]: marketsListHash[Label]+=1}))
      })
    }
  }

  const handleMetricChange = (e) => {
    const { value } = e.target

    setSelectedMetric(value)
    setSelectedMetricData(data[selectedType].map(({Data}) => Data.filter(({MetricType}) => MetricType === value)))
  }

  const handleToggleCheck = (row) => {        
    if(row === "ALL") {      
      if(checked.length < data[selectedType].length) {
        setChecked(data[selectedType].map(({Label}) => Label))
        setSelectedMetricData(data[selectedType].map(({Data}) => Data.filter(({MetricType}) => MetricType === selectedMetric)))

        if(selectedType === "location") {
          data[selectedType].forEach(({Market}) => {
            setMarketsListHash(prevState => ({...prevState, [Market]: marketsListHash[Market]+=1}))
          })
        } else {
          data[selectedType].forEach(({Label}) => {
            setMarketsListHash(prevState => ({...prevState, [Label]: marketsListHash[Label]+=1}))
          })
        }
        
      } else {
        Object.keys(marketsListHash).forEach(market => {
          marketsListHash[market] = 0
        })
        setChecked([])
        setSelectedMetricData([])                        
      }
    } else {
      const {Label, Market} = row
      let newChecked = [...checked]
      let currentIdx = checked.indexOf(Label)
      let pendingRow = row.Data.filter(({MetricType}) => MetricType === selectedMetric)
  
      if(currentIdx === -1) {
        newChecked.push(Label)
        let arr = []
        arr.push(pendingRow)
        
        let joined = selectedMetricData.concat(arr)        
        setSelectedMetricData(joined)
        if(selectedType === "location") setMarketsListHash(prevState => ({...prevState, [Market]: marketsListHash[Market]+=1}))         
        else setMarketsListHash(prevState => ({...prevState, [Label]: marketsListHash[Label]+=1})) 
        
        console.log(marketsListHash)
      } else {
        newChecked = newChecked.filter(item => item !== row.Label)
        let spliced = selectedMetricData.filter(item => item[0] !== pendingRow[0])      
        setSelectedMetricData(spliced)
        if(selectedType==="location") setMarketsListHash(prevState => ({...prevState, [Market]: marketsListHash[Market]-=1}))
        else setMarketsListHash(prevState => ({...prevState, [Label]: marketsListHash[Label]-=1}))        
      }
  
      setChecked(newChecked)      
    }      
  }

  const handleDateRange = (value, valueName) => {
    setDataLoad(true)
    
    if(valueName === "minYearmo") {
      const request = Promise.all([
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/demoByLoc/market?min_yearmo=${value.format("YYYY-MM")}&max_yearmo=${maxYearmo.format("YYYY-MM")}&dbl_gen_pop=${gen_pop}`, {headers: {token: token}}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/demoByLoc/location?min_yearmo=${value.format("YYYY-MM")}&max_yearmo=${maxYearmo.format("YYYY-MM")}&dbl_gen_pop=${gen_pop}`, {headers: {token: token}}),
      ])

      request.then(([response, response2]) => Promise.all([response.json(), response2.json()]))
      .then(([data, data2]) => {
        setDataLoad(false)
        setData(prevState => ({...prevState, "market": data, "location": data2}))
        setDataByMarket(data)        
        setDataByLocation(data2)        
        setSelectedMetricData(data.map(({Data}) => Data.filter(({MetricType}) => MetricType === selectedMetric)))
        setMinYearmo(value)
        
        if(selectedType === "market") {
          setChecked(data.map(({Label}) => Label))
          data.forEach(({Label}) => {
            setMarketsListHash(prevState => ({...prevState, [Label]: 1}))
          })
        } else {
          setChecked(data.map(({Label}) => Label))
          data.forEach(({Market}) => {
            setMarketsListHash(prevState => ({...prevState, [Market]: marketsListHash[Market]+=1}))
          })          
        }
      })
    } else {
      const request = Promise.all([
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/demoByLoc/market?min_yearmo=${minYearmo.format("YYYY-MM")}&max_yearmo=${value.format("YYYY-MM")}&dbl_gen_pop=${gen_pop}`, {headers: {token: token}}),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/demoByLoc/location?min_yearmo=${minYearmo.format("YYYY-MM")}&max_yearmo=${value.format("YYYY-MM")}&dbl_gen_pop=${gen_pop}`, {headers: {token: token}}),
      ])

      request.then(([response, response2]) => Promise.all([response.json(), response2.json()]))
      .then(([data, data2]) => {
        setDataLoad(false)
        setData(prevState => ({...prevState, "market": data, "location": data2}))
        setDataByMarket(data)        
        setDataByLocation(data2)        
        setSelectedMetricData(data.map(({Data}) => Data.filter(({MetricType}) => MetricType === selectedMetric)))
        setMaxYearmo(value)
        
        if(selectedType === "market") {
          setChecked(data.map(({Label}) => Label))
          data.forEach(({Label}) => {
            setMarketsListHash(prevState => ({...prevState, [Label]: 1}))
          })
        } else {
          setChecked(data.map(({Market}) => Market))
          data.forEach(({Market}) => {
            setMarketsListHash(prevState => ({...prevState, [Market]: marketsListHash[Market]+=1}))
          })
        }
      })
    }
  }

  const handleSliderDateChange = (values) => {
    setDataLoad(true)
    
    const min = values[0]
    const max = values[1]

    const request = Promise.all([
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/demoByLoc/market?min_yearmo=${min}&max_yearmo=${max}&dbl_gen_pop=${gen_pop}`, {headers: {token: token}}),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/demoByLoc/location?min_yearmo=${min}&max_yearmo=${max}&dbl_gen_pop=${gen_pop}`, {headers: {token: token}}),
    ])

    request.then(([response, response2]) => Promise.all([response.json(), response2.json()]))
    .then(([data, data2]) => {
      setDataLoad(false)
      setData(prevState => ({...prevState, "market": data, "location": data2}))
      setDataByMarket(data)      
      setDataByLocation(data2)      
      setSelectedMetricData(data.map(({Data}) => Data.filter(({MetricType}) => MetricType === selectedMetric)))      
      
      setMinYearmo(moment(min))
      setMaxYearmo(moment(max))
      
      if(selectedType === "Market") {
        setChecked(data.map(({Label}) => Label))
        data.forEach(({Label}) => {
          setMarketsListHash(prevState => ({...prevState, [Label]: 1}))
        })
      } else {
        setChecked(data.map(({Label}) => Label))
        data.forEach(({Market}) => {
          setMarketsListHash(prevState => ({...prevState, [Market]: 1}))
        })
      }
    })
  }
  
  if(!dataByMarket) return <div className={classes.mainLoader}><CircularProgress/></div>

  const renderSelectOptions = () => {
    return (
      data[selectedType][0].Data.map(({MetricType}, i) => (
        <Typography key={`${MetricType}-${i}`} value={MetricType}>{MetricType}</Typography>
      ))
    )
  }    
  
  return (
    <div className={classes.root}>
      <div style={{display: "flex", justifyContent: "space-between"}}>
        <Typography className={classes.pageTitle} style={{marginTop: '25px'}}>
          <Public className={classes.pageIcon} />
          <span style={{marginLeft: '5px'}}>DEMOGRAPHICS BY LOCATION</span>
        </Typography>
        <div style={{marginTop: '40px'}}>
          <DemoExcel
            metricType={selectedMetric}
            minYearmo={minYearmo}
            maxYearmo={maxYearmo}
            selectedData={selectedMetricData}
            marketSelected={checked}
            dataSeries={getChartSeries()}
            categories={dataByMarket[0].Data.filter(({MetricType}) => MetricType === selectedMetric)[0].Data.map(({MetricValue}) => MetricValue)}
          />
        </div>    
      </div>
      
      <div style={{display: "flex", justifyContent: "space-between"}}>
        {dataLoad ?
          <div><CircularProgress style={{position: 'absolute', marginLeft: '30%', marginTop: '20%', zIndex: 10000}}/></div>
        :
          null
        }
        <Paper style={{padding: "1em", width: "100%", opacity: `${dataLoad ? 0.5 : 1}`, pointerEvent: `${dataLoad ? "none" : ""}`}}>
          <BarChart
            categories={dataByMarket[0].Data.filter(({MetricType}) => MetricType === selectedMetric)[0].Data.map(({MetricValue}) => MetricValue)}
            data={getChartSeries()}
            colors={["#00c9db", "#2f5a7a", "#ff2b34"]}
          />
        </Paper>
        <Paper style={{padding: "1em", display: "flex", flexDirection: "column", alignItems: "center", marginLeft: "10px", width: "25%", pointerEvents:`${dataLoad ? "none" : ""}`, opacity: `${dataLoad ? 0.5 : 1}`}}>
          <div style={{display: 'flex', flexDirection: "row", justifyContent: 'space-between', width: "100%"}}>
            <Typography align="left" className={classes.listLabel} variant="caption">{`${selectedType.toUpperCase()}S (${data[selectedType].length})`}</Typography>
            <Typography align="right" className={classes.listLabel} variant="caption">{checked.length} selected</Typography>
          </div>
          
          <List className={classes.list} dense={true} style={{maxHeight: "500px"}}>
            <ListItem dense button onClick={e => handleToggleCheck("ALL")}>
              <ListItemText><Typography variant="caption">{checked.length < data[selectedType].length ? "SELECT" : "DESELECT"} ALL</Typography></ListItemText>
            </ListItem>
            {data[selectedType].map(row => (
              <ListItem key={row.Key} dense button onClick={() => handleToggleCheck(row)}>
                <ListItemIcon>
                  <Checkbox edge="start" checked={checked.indexOf(row.Label) !== -1} disableRipple />
                </ListItemIcon>
                <ListItemText><Typography variant="caption">{row.Label.toUpperCase()}</Typography></ListItemText>
              </ListItem>
            ))}
          </List>
          <br/>
          {/* <Typography
            className={classes.listLabel}
            variant="caption"
            align="left"
            style={{marginLeft: '17px', padding: '0px 5px 0px 5px', backgroundColor: '#fff', marginTop: '20px'}}
          >
            Date Range
          </Typography> */}
          <div className={classes.datePicker} style={{marginTop: '-10px'}}>
            <MonthPicker
              maxValue={maxYearmo}
              minValue={minYearmo}
              maxSliderValue={months}
              handleDateRange={handleDateRange}
              handleSliderDateChange={handleSliderDateChange}              
            />
          </div>          
          <FormControl variant="outlined" className={classes.formControl} style={{marginTop: "5px"}}>
            <InputLabel id="metric-select">Location vs Market</InputLabel>
            <Select
              id="metric"
              labelId="metric-select"
              value={selectedType}
              onChange={handleLocationTypeChange}
              label="Location vs Market"
            >
              <MenuItem value={"location"}>Location</MenuItem>
              <MenuItem value={"market"}>Market</MenuItem>
            </Select>
          </FormControl>          
          <FormControl variant="outlined" className={classes.formControl} style={{marginTop: "5px"}}>
            <InputLabel id="metric-select">Metric Type</InputLabel>
            <Select
              id="metric"
              labelId="metric-select"
              value={selectedMetric}
              onChange={handleMetricChange}
              label="Metric Type"
            >
              {renderSelectOptions()}
            </Select>
          </FormControl>
        </Paper>
      </div>
    </div>
  )
}

export default DemoByLoc
