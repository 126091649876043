import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { CircularProgress, MenuItem, Typography } from '@material-ui/core'
import ComboChart from '../charts/ComboChart'
import Cookies from 'js-cookie'
import zipStandIn from '../../assets/homeStandIns/zipStandIn.png'
import AskReport from '../AskReport'

const styles = theme => ({
  root: {
    margin: '1em'
  },
  loader: {
    marginLeft: '45%',
    marginTop: '30%',
  },
  select: {
    width: '100px',
    position: 'absolute',
  },
  chart: {
    [theme.breakpoints.down('lg')]: {
      margin: '-2em'
    }
  },
  standIn: {
    width: '100%',
    [theme.breakpoints.up('xl')]: {
      marginTop: '-50px',
      height: '35%',
      width: '85%'
    },
    [theme.breakpoints.down('lg')]: {
      width: '120%',
      marginTop: '-35px'
    }
  },
 unavailable: {
    height: '400px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})

class ZipModule extends Component {
  _isMounted = false

  constructor() {
    super()
    this.state = {
      isLoading: true,
      zipData: [],
      metrics: [{metric: 'Monthly'}, {metric: 'Quarterly'}, {metric: 'Yearly'}],
      selectedMetric: 'Monthly',
      months: '12',
    }
  }

  componentDidMount() {
    this._isMounted = true

    fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/zips`, {
      method: 'GET',
      headers: {
        'token': Cookies.get('token')
      },
    })
    .then(response => response.json())
    .then(data => {
      if(this._isMounted && data) {
        this.setState({
          isLoading: false,
          zipData: data
        })
      } else {
        this.setState({
          isLoading: false,
          zipData: []
        })
      }
    })
    .catch(error => {
      console.log(error)
    })
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  renderSelectOptions = () => {
    return(
      this.state.metrics.map((item, i) => {
        return(<MenuItem key={item.metric} value={item.metric}>{item.metric}</MenuItem>)
      })
    )
  }

  handleChange = (e) => {

  }

  render() {
    const { classes } = this.props
    // const colors = ['#00ffb4', '#00ffb4', '#db7bc5']
    const colors = this.props.darkMode ? ['#ffae00', '#00ab91', '#faa2f4'] : ['#ffae00', '#00ab91', '#8229ab']
    const labels = this.state.zipData.map(item => item.City + ': ' + item.ZIP)
    const sales = this.state.zipData.map(item => item.Data).map(item => Math.round(item[0].Sales))
    const salesLY = this.state.zipData.map(item => item.Data).map(item => Math.round(item[0].Sales_LY))
    // const salesPenetration = this.state.zipData.map(item => item.Data).map(item => item[0].Sales_Penetration.toFixed(1))
    // const salesYOY = this.state.zipData.map(item => item.Data).map(item => item[0].Sales_YOY ? item[0].Sales_YOY.toFixed(1) : '')
    const salesDiff = this.state.zipData.map(item => item.Data).map(item => item[0].Sales_Diff ? Math.round(item[0].Sales_Diff) : '')
    // const furnitureExpenditure = this.state.zipData.map(item => item.Data).map(item => (item[0].Furniture_Expenditure /1000000).toFixed(2))
    const currentUserLite = JSON.parse(localStorage.getItem('currentUser')) && JSON.parse(localStorage.getItem('currentUser'))[0].sd_lite === 1 ? true : false
    const dbO = JSON.parse(localStorage.getItem("currentUser")) ? JSON.parse(localStorage.getItem("currentUser"))[0].db_owner : ""


    const series = [
        {
          name: 'Total Sales',
          type: 'column',
          data: sales
        },
        {
          name: 'Sales Last Year',
          type: 'column',
          data: salesLY
        },
        {
          name: 'Sales YOY($)',
          type: 'line',
          data: salesDiff
        },
      ]

    return(
      <div className={classes.root}>
        { this.state.isLoading ? <div className={classes.loader}><CircularProgress /></div> :
          this.state.zipData.length > 0 && !currentUserLite && dbO !== "ESCANABA" ?
          <Fragment>
            <div className={classes.chart} style={{marginTop: '10px'}}>
              {/* <Select
                value={this.state.selectedMetric}
                onChange={this.handleChange}
                className={classes.select}
                style={{marginTop: '-14px'}}
              >
                {this.renderSelectOptions()}
              </Select> */}
              {/* {window.innerWidth} */}
              <ComboChart
                labels={labels}
                name={['Total Sales', 'Sales Last Year', 'Sales YOY($)']}
                data={series}
                colors={colors}
                height={300}
                darkMode={this.props.darkMode}
                enableFixed={true}
                // style={{marginTop: '-25px'}}
              />
            </div>
          </Fragment>
          : 
          <div className={classes.unavailable}>
              <AskReport home={true} />
            </div>
        }
      </div>
    )
  }
}

export default withStyles(styles)(ZipModule)
