import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import {Tooltip, Avatar, Popover, IconButton, MenuItem, ListItemAvatar, ListItemIcon, ListItemText, Badge, Paper, Divider, Typography } from '@material-ui/core'
import NotificationsIcon from '@material-ui/icons/Notifications'
import TimerIcon from '@material-ui/icons/Timer'
import TrendingUpSharpIcon from '@material-ui/icons/TrendingUp'
import TrendingDownSharpIcon from '@material-ui/icons/TrendingDown'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    maxWidth: 500,
    marginTop: 48,
  },
})(props => (
  <Popover
    elevation={1}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    {...props}
  />
))

const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      '& .MuiListItemIcon-root & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem)

export default function NotificationsMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [overlap] = React.useState('rectangle')

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return(
    <div>
      <Tooltip title="Notifications">
      <IconButton
        variant="contained"
        onClick={handleClick}
      >
        <Badge badgeContent="" variant="dot" overlap={overlap} color="secondary">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      </Tooltip>
      <Paper>
        <StyledMenu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <StyledMenuItem>
            <ListItemAvatar>
              <Avatar>
                <TimerIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={<Typography variant="caption" style={{color: 'green'}}>Notifications</Typography>} secondary="Coming Soon.."/>
            <ListItemIcon style={{padding: '2em'}}><RemoveCircleIcon fontSize="small" color="secondary" /></ListItemIcon>
          </StyledMenuItem>
          {/* <StyledMenuItem>
            <NotificationsIcon fontSize={'small'} /><Typography variant="caption" style={{marginLeft: '10px'}}>View All Notifications</Typography>
          </StyledMenuItem>
          <Divider />
          <StyledMenuItem>
            <ListItemAvatar>
              <Avatar>
                <TrendingUpSharpIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={<Typography variant="caption" style={{color: 'green'}}>Sales</Typography>} secondary="A spend of $326,109 has yielded $11,590,622 in sales"/>
            <ListItemIcon style={{padding: '2em'}}><RemoveCircleIcon fontSize="small" color="secondary" /></ListItemIcon>
          </StyledMenuItem>
          <Divider variant="inset" />
          <StyledMenuItem>
            <ListItemAvatar>
              <Avatar>
                <TrendingDownSharpIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={<Typography variant="caption" style={{color: 'red'}}>Lost Opportunity</Typography>} secondary="The total lost opportunity so far in 2019 is -$963,028."/>
            <ListItemIcon style={{padding: '2em'}}><RemoveCircleIcon fontSize="small" color="secondary" /></ListItemIcon>
          </StyledMenuItem> */}
        </StyledMenu>
      </Paper>
    </div>
  )
}
