import React, {Component} from 'react'
import {
  makeStyles,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  table: {
    marginTop: '20px',
    display: 'flex',
    // height: '100%',
    align: 'right'
  },
}))

export default function TotalChart(props) {
  const classes = useStyles()

  return(
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan={2}>
              <Typography variant="caption" style={{fontWeight: 'bold'}}>TOTAL CUSTOMERS BY YEAR</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell><Typography variant="caption" style={{fontSize: 11, fontWeight: 'bold'}}>Year</Typography></TableCell>
            <TableCell align="right" style={{borderLeft: '1px solid #dcdcdc'}}><Typography variant="caption" style={{fontSize: 11, fontWeight: 'bold'}}>Customer Count</Typography></TableCell>
          </TableRow>
          {props.data.reverse().map(row => (
            <TableRow key={row.name} style={{height: 65}}>
              <TableCell><Typography variant="caption">{row.name}</Typography></TableCell>
              <TableCell align="right" style={{borderLeft: '1px solid #dcdcdc'}}>
                <Typography variant="caption">{Number.isNaN(row.data.reduce((a,b) => a + b, 0)) ? 0 : row.data.reduce((a,b) => a + b, 0).toLocaleString('en')}</Typography>
                {/* <Typography variant="caption">{row.data.reduce((a,b) => a + b, 0).toLocaleString('en')}</Typography> */}

              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}
