import React, { Component } from 'react'
import {
  withStyles,
  Tabs,
  Tab,
  Typography,
  Box,
} from '@material-ui/core'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return(
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  )
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls':  `full-width-tabpanel-${index}`
  }
}

const styles = theme => ({
  indicator: {
    backgroundColor: '#00bcd4'
  }
})

class TabGroup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tab: 0,
    }
  }

  handleTabChange = ( e, newTab ) => {
    this.setState({
      tab: newTab
    })
  }

  render() {
    const { classes } = this.props
    return(
      <div>
        <Tabs
          variant="fullWidth"
          textColor="secondary"
          classes={{indicator: classes.indicator}}
          value={this.state.tab}
          onChange={this.handleTabChange}
          style={{marginTop: '15px'}}
        >
          <Tab label="View ROI" {...a11yProps(0)} disableRipple />
          <Tab label="View ROI by RFM Segment" {...a11yProps(1)} disableRipple />
          <Tab label="View ROI by Test Group" {...a11yProps(2)} disableRipple />

        </Tabs>
        <TabPanel value={this.state.tab} index={0}>
          page one
        </TabPanel>
      </div>
    )
  }
}

export default withStyles(styles)(TabGroup)
