import React, { Component } from 'react'
import {
  withStyles,
  Typography,
  Icons,
  Tooltip,
  IconButton,
  TextField,
  InputAdornment,
  Card,
  Icon,
} from '@material-ui/core'
import MonthCalendar from 'rc-calendar/lib/MonthCalendar'
import DatePicker from 'rc-calendar/lib/Picker'
import { format, addMonths, subMonths, startOfWeek, endOfWeek, startOfMonth, endOfMonth, isSameDay, isSameMonth, addDays} from 'date-fns'
import { BiChevronLeft, BiChevronRight, BiChevronsLeft, BiChevronsRight, BiCalendar } from 'react-icons/bi'
import { MdToday } from 'react-icons/md'
import { FaCalendarAlt, FaTemperatureHigh, FaTemperatureLow, FaCloudRain } from 'react-icons/fa'
import { FiCloudRain } from 'react-icons/fi'
import { GiSnowflake1 } from 'react-icons/gi'
import { RiRainyLine, RiSnowyLine, RiArrowDownFill, RiArrowUpFill } from 'react-icons/ri'
import { IoRainy } from 'react-icons/io5'
import { TiWeatherSnow } from 'react-icons/ti'
import { HiSun } from 'react-icons/hi'
import moment from 'moment'
import Cookies from 'js-cookie'

import '../../assets/full-calendar.css'

const styles = theme => ({
  pageTitle: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '17px',
    fontWeight: 'light',
    color: 'rgba(115, 115, 115, 0.9)'
  },
  pageTitleDark: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '17px',
    fontWeight: 'light',
    color: 'rgba(255, 255, 255, 0.9)'
  },
  pageIcon: {
    fontSize: '15px',
    marginTop: '21px',
    marginRight: '2px',
  },
  pageIconDark: {
    fontSize: '15px',
    color: 'rgba(255, 255, 255, 0.9)',
    marginTop: '5px',
    marginRight: '2px',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    paddingBottom: 16,
  },
  button: {
    marginRight: '10px',
    height: 56,
    marginTop: '-16px',
    width: 275,
    color: '#bd4c46',
    border: '1px solid #bd4c46',
    '&:hover': {
      color: '#ff928c',
      border: '1px solid #ff928c',
      backgroundColor: 'transparent',
    }
  },
  activeButton: {
    marginRight: '10px',
    height: 56,
    marginTop: '-16px',
    width: 275,
    color: '#fff',
    backgroundColor: '#bd4c46',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#ff928c',
      border: '1px solid #ff928c',
    }
  }
})

const weatherIcons = [
  {name: 'PRECIP', icon: <FaCloudRain />, alert: 'Precipitation', color: '#a390f0'},
  {name: 'WINTRY_PRECIP', icon: <GiSnowflake1 style={{fontSize: '20px'}}/>, alert: 'Wintry Precipitation', color: '#58bde6'},
  {name: 'WARM', icon: <HiSun style={{fontSize: '23px'}}/>, alert: 'Unseasonably Hot', color: '#e3824b'},
  {name: 'COLD', icon: <FaTemperatureLow />, alert: 'Unseasonably Cold', color: '#3253ba'},
]

class Picker extends Component {
  render() {
    const props = this.props
    const calendar = (
      <MonthCalendar
        disabledDate={props.disabledDate}
        style={{width: 250, zIndex: 1000, marginTop: '3px'}}
      />
    )
    return (
      <DatePicker
        animation="slide-up"
        calendar={calendar}
        value={props.value}
        onChange={props.onChange}
      >
        {
          ({value}) => {
            return (
              <TextField
                variant="outlined"
                placeholder=""
                InputProps={{
                  style: {fontWeight: 800, fontSize: '22px'},
                  endAdornment: (
                    <InputAdornment position="end">
                      <BiCalendar style={{fontSize: '20px'}} />
                    </InputAdornment>
                  )
                }}
                style={{
                  width: 300,
                  height: 50,
                  background: 'transparent',
                  overflow: 'break-word',
                  textAlign: 'left',
                  padding: '0px 3px 0px 3px',
                }}
                readOnly
                value={value && value.format('MMMM YYYY').toUpperCase()}
              />
            )
          }
        }
      </DatePicker>
    )
  }
}

class Calendar extends Component {
  _isMounted = false

  constructor() {
    super()
    this.state = {
      currentDate: moment(),
      selectedDate: moment(),
      isLoading: true,
      calData: [],
      weatherData: [],
    }
  }

  componentDidMount = () => {
    this._isMounted = true

    this.setState({
      isLoading: false,
      currentDate: moment()
    })

    Promise.all([
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/calendar?yearmo=${this.state.currentDate.format('YYYY-MM-DD')}`, {
        headers: {
          token: Cookies.get('token')
        }
      }),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/calendar/weatherData?calMonth=${this.state.currentDate.format('M')}&calYear=${this.state.currentDate.format('YYYY')}&locMktName=${'La Crosse'}`, {
        headers: {
          token: Cookies.get('token')
        }
      }),

    ])
    .then(([response, response2]) => Promise.all([response.json(), response2.json()]))
    .then(([data, data2]) => {
      this.setState({
        calData: data,
        weatherData: data2,
      })
    })
    .catch(error => {
      console.log(error)
    })
  }

  handleDateChange = (newDate) => {
    console.log(this.state.currentDate)

    Promise.all([
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/calendar?yearmo=${newDate.format('YYYY-MM-DD')}`, {
        headers: {
          token: Cookies.get('token')
        }
      }),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/calendar/weatherData?calMonth=${newDate.format('M')}&calYear=${newDate.format('YYYY')}&locMktName=${'La Crosse'}`, {
        headers: {
          token: Cookies.get('token')
        }
      }),
    ])
    .then(([response, response2]) => Promise.all([response.json(), response2.json()]))
    .then(([data, data2]) => {
      this.setState({
        calData: data,
        weatherData: data2,
      })
    })
  }

  componentWillUnmount = () => {
    this._isMounted = false
  }

  onDateChange = (newDate) => {
    console.log(newDate)

    this.setState({
      currentDate: newDate
    })

    this.handleDateChange(newDate)
  }

  renderHeader = () => {
    const dateFormat = 'MMMM YYYY'

    return (
      <div className="header row flex-middle">
        <div className="col col-start">
          <Picker
            // disabledDate={this.disabledStartDate}
            value={this.state.currentDate}
            onChange={this.onDateChange.bind(this)}
          />
        </div>
        <div className="col col-end">
          <Tooltip title={`Today: ${moment().format('MMM DD,YYYY')}`} placement={'top'}>
            <div className="icon" onClick={e => this.resetToToday()}><IconButton variant="contained"><MdToday style={{color: '#e0ac00'}}/></IconButton></div>
          </Tooltip>
          <Tooltip title="Previous Year" placement={'top'}>
            <div className="icon" onClick={e => this.prevYear(this.state.currentDate)}><IconButton variant="contained"><BiChevronsLeft /></IconButton></div>
          </Tooltip>
          <Tooltip title="Previous Month" placement={"top"}>
            <div className="icon" onClick={e => this.prevMonth(this.state.currentDate)}><IconButton variant="contained"><BiChevronLeft /></IconButton></div>
          </Tooltip>
          <Tooltip title="Next Month" placement={"top"}>
            <div className="icon" onClick={e => this.nextMonth(this.state.currentDate)}><IconButton variant="contained"><BiChevronRight /></IconButton></div>
          </Tooltip>
          <Tooltip title="Next Year" placement={"top"}>
            <div className="icon" onClick={e => this.nextYear(this.state.currentDate)}><IconButton variant="contained"><BiChevronsRight /></IconButton></div>
          </Tooltip>
        </div>
      </div>
    )
  }

  renderDays = () => {
    const dateFormat = 'dddd'
    const days = []
    let startDate = moment().startOf('week')

    for(let i = 0; i < 7; i++) {
      days.push(
        <div className="col col-center" key={i}>
          {moment(startDate).add(i, 'days').format(dateFormat)}
        </div>
      )
    }

    return <div className="days row">{days}</div>
  }

  renderWeatherEvents = (day) => {
    const { currentDate, selectedDate, weatherData } = this.state
    const weather = []

    if(weatherData.map(item => item.wrt_dat).includes(day)) {
      return (
        weatherData.filter(item => item.wrt_dat === day).map((item, index) => (
          <Tooltip
            key={index}
            placement="top-start"
            title={
              <Typography variant="caption" >
                Weather Alert - {weatherIcons.filter(weather => item.alertList_weather.split(',').includes(weather.name)).map(item => item.alert).join(', ')}<br/>
                <span>( Hi Temp: {item.tmax}° | Lo Temp: {item.tmin}° )</span>
              </Typography>
            }
          >
            <div style={{marginTop: '5px'}}>
              {item.alertList_weather.split(',').map((item, i) => (
                <IconButton
                  key={i}
                  size="small"
                  style={moment(day).format('M') !== currentDate.format('M') ?
                    {color: '#c7c7c7'}
                  :
                    {color: `${weatherIcons.filter(weather => weather.name === item)[0].color}`}
                  }
                >
                  {weatherIcons.filter(weather => weather.name === item)[0].icon}
                </IconButton>
              ))}
            </div>
          </Tooltip>
        ))
      )
    }
  }

  renderEvents = (day) => {
  //   let eventRender = ""
  //   const events = [
  //     {
  //       title: 'my event',
  //       start: '2021-07-20',
  //       end: '2021-07-21',
  //       description: 'event description'
  //     },
  //     {
  //       title: 'same day event',
  //       start: '2021-07-13',
  //       end: '2021-07-13',
  //       description: 'event description'
  //     },
  //     {
  //       title: 'same day event',
  //       start: '2021-07-13',
  //       end: '2021-07-13',
  //       description: 'event description'
  //     },
  //     {
  //       title: 'other event',
  //       start: '2021-07-05',
  //       end: '2021-07-09',
  //       description: 'event description'
  //     },
  //   ]
  //
  //   for(let i = 0; i < events.length; i++) {
  //     if(day === events[i].start && day === events[i].end) {
  //       return(
  //         <div style={{color: '#fff', backgroundColor: '#25aae1', borderRadius: 4, margin: '2px 5px 0px 5px', padding: '0px 0px 0px 5px'}}>
  //           {events[i].title}
  //         </div>
  //       )
  //     } else if(day === events[i].start && day !== events[i].end) {
  //       return (
  //         <div key={i} style={{color: '#fff', backgroundColor: '#25aae1', borderRadius: '4px 0px 0px 4px', margin: '2px 0px 0px 5px', padding: '0px 0px 0px 5px'}}>
  //           {events[i].title}
  //         </div>
  //       )
  //     } else if(day === events[i].end) {
  //       return (
  //         <div key={i} style={{color: '#fff', backgroundColor: '#25aae1', borderRadius: '0px 4px 4px 0px', margin: '2px 5px 0px 0px', padding: '0px 5px 0px 0px', height: '20px'}}>
  //         </div>
  //       )
  //     } else if(day < events[i].end && day > events[i].start) {
  //       return (
  //         <div key={i} style={{color: '#fff', backgroundColor: '#25aae1', borderRadius: '0px 0px 0px 0px', margin: '2px 0px 0px 0px', height: '20px'}}>
  //         </div>
  //       )
  //     }
  //   }
  }

  renderCellData = (day) => {
    const { calData, currentDate, selectedDate } = this.state

    if(calData.filter(item => item.Written_Date === day).length > 0 && calData.filter(item => item.Written_Date === day)[0].Data[0].Sales_YOY_Flag !== 0) {
      return (
        <div style={{position: 'absolute', bottom: 0, marginLeft: '10px'}}>
          <Tooltip
            title={
              <Typography variant="caption">
                {console.log(day)}
                Sales This Year: ${Math.round(calData.filter(item => item.Written_Date === day)[0].Data[0].Sales).toLocaleString('en')}<br/>
                Sales Last Year: ${Math.round(calData.filter(item => item.Written_Date === day)[0].Data[0].Sales_LY).toLocaleString('en')}<br/>
                Change: {(calData.filter(item => item.Written_Date === day)[0].Data[0].Sales_YOY * 100).toFixed(2)}%<br/>
              </Typography>
            }
          >
            <Typography
              style={
              moment(day).format('M') !== currentDate.format('M') ?
                {
                  fontSize: '12px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  color: '#c7c7c7',
                }
              :
                calData.filter(item => item.Written_Date === day)[0].Data[0].Sales_YOY_Flag === 1 ?
                {
                  fontSize: '12px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  color: '#15b345'
                }
              :
                {
                  fontSize: '12px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  color: '#e65555'
                }
              }
            >
              ${Math.round(calData.filter(item => item.Written_Date === day)[0].Data[0].Sales).toLocaleString('en')}
              {calData.filter(item => item.Written_Date === day)[0].Data[0].Sales_YOY_Flag === 1 ?
                <RiArrowUpFill  style={{marginLeft: '5px', marginRight: '5px'}}/>
              :
                <RiArrowDownFill  style={{marginLeft: '5px', marginRight: '5px'}}/>
              }
              {Math.round(calData.filter(item => item.Written_Date === day)[0].Data[0].Sales_YOY * 100)}%
            </Typography>
          </Tooltip>
        </div>
      )
    } else {
      return <div/>
    }
  }

  renderCells = (data) => {
    const { currentDate, selectedDate } = this.state
    const monthStart = moment(currentDate).startOf('month')
    const monthEnd = moment(currentDate).endOf('month')
    const startDate = monthStart.startOf('week')
    const endDate = monthEnd.endOf('week')
    const dateFormat = 'D'
    const rows = []
    let days = []
    let day = startDate
    let formattedDay = ""
    let cellData = []

    while (day <= endDate) {
      let cellData = data.filter(item => item.Written_Date === day.format('YYYY-MM-DD'))

      for(let i = 0; i < 7; i++) {
        formattedDay = day.format(dateFormat)
        const cloneDay = day
        days.push(
          <div
            className={`
              col
              cell
              ${day.format('M') !== currentDate.format('M') ?
                'disabled' :
                day === selectedDate ? 'selected' : ''
              }
            `}
            key={day}
            style={{ height: 200, }}
          >
            <span className="number" style={day.format('MM-DD-YY') === moment().format('MM-DD-YY') ? {color: '#fff', backgroundColor: '#e0ac00', padding: '1em', borderRadius: '50px', marginTop: '-5px'} : {padding: '1em', marginTop: '-5px'}}>{formattedDay}</span>
            <span className="bg">{formattedDay}</span>
            <div style={{height: '30px'}}>
              {this.renderWeatherEvents(day.format('YYYY-MM-DD'))}
            </div>

            {this.renderCellData(day.format('YYYY-MM-DD'))}
          </div>
        )
        day = day.add(1, 'days')
      }
      rows.push(
        <div className="row" style={{justifyContent: 'space-evenly', overflowY: 'auto'}} key={day}>
          {days}
        </div>
      )
      days = []
    }

    return <div className="body">{rows}</div>
  }

  onDateClick = day => {
    console.log(day.format('MM DD YY'))
  }

  handleEventClick = (event) => {
    console.log(event)
  }

  resetToToday = () => {
    this.setState({
      currentDate: moment()
    })

    this.handleDateChange(moment())
  }

  nextYear = (year) => {
    this.setState({
      currentDate: this.state.currentDate.add(1, 'years')
    })

    this.handleDateChange(this.state.currentDate)
  }

  nextMonth = (month) => {
    this.setState({
      currentDate: month.add(1, 'months')
    })

    this.handleDateChange(this.state.currentDate)
  }

  prevYear = (year) => {
    this.setState({
      currentDate: this.state.currentDate.subtract(1, 'years')
    })

    this.handleDateChange(this.state.currentDate)
  }

  prevMonth = (month) => {
    this.setState({
      currentDate:month.subtract(1, 'months')
    })

    this.handleDateChange(this.state.currentDate)
  }

  render() {
    const { classes } = this.props

    return (
      <div>
        <Typography className={this.props.darkMode ? classes.pageTitleDark : classes.pageTitle} style={{marginTop: '10px'}}>
          <FaCalendarAlt className={this.props.darkMode ? classes.pageIconDark : classes.pageIcon} />
          <span style={{marginLeft: '5px'}}>PROMOTION CALENDAR - BY MARKET</span>
        </Typography>
        {this.state.isLoading ? <div></div> :
          <div style={{display: 'flex', flexDirection: 'row', height: '100%'}}>
            <div className="calendar">
              {this.renderHeader()}
              {this.renderDays()}
              <br/>
              {this.renderEvents()}
              {this.renderCells(this.state.calData)}
            </div>
            <div style={{width: '35%', marginTop: '25px', marginLeft: '15px'}}>
              <Card style={{height: '65%'}}>
                <div style={{padding: '2em'}}>
                  <Typography variant="caption">MARKETS</Typography>
                </div>
              </Card>

              {/* <Card style={{height: '100%', marginTop: '15px'}}>
                <div style={{padding: '2em'}}>
                  <Typography variant="caption">MARKETS</Typography>
                </div>
              </Card> */}
            </div>
          </div>
        }
      </div>
    )
  }
}

export default withStyles(styles)(Calendar)
