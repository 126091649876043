import React, { Component, Fragment } from 'react'
import clsx from 'clsx'
import styled from 'styled-components'
import {
  withStyles,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  ButtonBase,
  Modal,
  Backdrop,
  Fade,
  Link,
  TextField,
  InputAdornment,
  Drawer,
  Divider,
  Checkbox,
  Button,
  FormControlLabel,
  Snackbar,
  SnackbarContent,
  IconButton,
  TablePagination,
  CircularProgress,
  Tooltip,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import WarningIcon from '@material-ui/icons/Warning'
import SearchIcon from '@material-ui/icons/Search'
import Cookies from 'js-cookie'
import moment from 'moment'
import DatePicker from '../calendar/PromoDatePicker'
import DayPicker from '../calendar/DayPicker'
import TentpoleROI from './TentpoleROI'
import LyTentpole from './LyTentpoleROI'
import CompareResponse from './CompareResponse'
import DemoDate from './DemoDate'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

const styles = theme => ({
  panel: {
    width: '100%',
    // border: '1px solid',
    // justifyContent: 'space-between',
    // justify: 'center',
    // alignItems: 'center'

  },
  // root: {
  //   width: '100%',
  //   border: '1px solid',
  //   maxHeight: '1500px'
  //   // marginLeft: '20px',
  //   // marginRight: '10px'
  // },
  column: {
    marginLeft: '35px',
    marginTop: '10px',
    // [theme.breakpoints.up('xl')]: {
    //   marginLeft: '80%'
    // },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '35px'
    }
  },
  metric: {
    fontSize: '12px',
    [theme.breakpoints.up('xl')]: {
      fontSize: '15px'
    }
  },
  metricNumber: {
    fontSize: '13px',
    [theme.breakpoints.up('xl')]: {
      fontSize: '17px'
    }
  },
  headColumns: {
    [theme.breakpoints.down('lg')]: {
      flexBasis: '40%',
      marginLeft: '40px'
    },
    [theme.breakpoints.up('xl')]: {
      flexBasis: '50%',
      marginLeft: '35px'
    }
  },
  img: {
    width: 75,
    border: '1px solid #ababab',
    [theme.breakpoints.up('xl')]: {
      width: 125
    }
  },
  header: {
    color: 'rgba(255, 255, 255, 1.0)',
    fontWeight: 600
  },
  heading: {},
  secondaryHeading: {
    color: '#999'
  },
  helper: {
    marginLeft: '10px',
    marginTop: '-10px',
    borderLeft: `2px solid #c9c9c9`,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalImg: {
    maxWidth: '95%',
    maxHeight: '95%'
  },
  pageIcon: {
    fontSize: '15px',
    marginTop: '21px'
  },
  title: {
    fontSize: '18px',
    fontWeight: 'light',
    color: 'rgba(115, 115, 115, 0.9)'
  },
  search: {
    marginTop: '15px'
  },
  drawer: {
    width: '50%',
    flexShrink: 0,
  },
  drawerPaper: {
    width: '50%'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'right',
    padding: theme.spacing(0,1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start'
  },
  hide: {
    display: 'none'
  },
  link: {
    '&:hover': {
      cursor: 'pointer'
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '15px'
    }
  },
  linkDark: {
    color: '#4ab8bb',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'none',
      color: '#6fd7da'
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '15px'
    }
  },
  expansionPanel: {
    // height: 115,
    // [theme.breakpoints.down('sm')]: {
    //   width: '75%'
    // },
    // [theme.breakpoints.up('xl')]: {
    //   width: '95%',
    //   // border: '1px solid red'
    // }
  },
  closeButton: {
    marginTop: '10px',
    marginLeft: '15px',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#eb838c',
      border: '1px solid #eb838c'
    }
  }
})

const StyledTextField = styled(TextField)`
  label.Mui-focused {
    color: #4ab8bb
  }
  .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: #4ab8bb
    }
  }
`

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: '11px',
    fontWeight: 'bold'
  }
}))(Tooltip)

class ComparePanel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tentpoleData: [],
      search: '',
      openDrawer: false,
      openBottom: false,
      roiData: [],
      responseData: [],
      demoData: [],
      job: '',
      startDate: moment().startOf('year').subtract(1, 'years'),
      initialStartDate: '',
      initialEndDate: '',
      endDate: moment(),
      rowsPerPage: 10,
      page: 0,
      snackbar: false,
      isLoading: true,
      roiLoading: true,
      responseLoading: true,
      expanded: null,
      maxYearmo: moment(),
      minYearmo: moment().diff(moment().startOf('year'), 'months') > 3 ? moment().startOf('year') : moment().startOf('year').subtract(1, 'years'),
      days: moment().diff(moment().startOf('year'), 'months') > 3 ? moment().diff(moment().startOf('year'), 'days') : moment().diff(moment().startOf('year').subtract(1, 'year'), 'days'),
      restoreHidden: true,
    }

    // this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleDateClear = this.handleDateClear.bind(this)
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this)
    this.handleDrawerClose = this.handleDrawerClose.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this)
    this.handleSnackbarClose = this.handleSnackbarClose.bind(this)
  }

  handleClose(e) {
    this.props.handleSecondaryClose(e)
  }

  // componentDidMount() {
  //   this._isMounted = true
  //
  //   fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID?startDate=${moment().subtract(1, 'years').format('YYYY-MM-DD')}&endDate=${moment().format('YYYY-MM-DD')}`, {
  //     headers: {
  //       token: Cookies.get('token')
  //     }
  //   })
  //   .then(response => response.json())
  //   .then(data => {
  //     if(this._isMounted && data) {
  //       this.setState({
  //         tentpoleData: data,
  //
  //       })
  //     }
  //     // console.log(this.state.tentpoleData)
  //   })
  //   .catch(error => {
  //     console.log(error)
  //   })
  // }

  componentDidMount = () => {
    this._isMounted = true

    fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID?startDate=${moment().subtract(1, 'years').format('YYYY-MM-DD')}&endDate=${moment().format('YYYY-MM-DD')}`, {
      headers: {
        token: Cookies.get('token')
      }
    })
    .then(response => response.json())
    .then(data => {
      if(this._isMounted && data) {
        this.setState({
          tentpoleData: data,
          isLoading: false,
          initialStartDate: this.state.startDate,
          initialEndDate: this.state.endDate,
        })
      } else {
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/recentDates?count=${10}`, {
          headers: {
            token: Cookies.get('token')
          }
        })
        .then(response => response.json())
        .then(data => {
          console.log(data)
          this.setState({
            startDate: data[data - 1],
            initialStartDate: data[data.length - 1]
          })
          fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID?startDate=${data[data.length - 1]}&endDate=${moment().format('YYYY-MM-DD')}`, {
            headers: {
              token: Cookies.get('token')
            }
          })
          .then(response => response.json())
          .then(data => {
            this.setState({
              isLoading: false,
              tentpoleData: data,
            })
          })
          .catch(error => {
            console.log(error)
          })
        })
        .catch(error => {
          console.log(error)
        })
      }
    })
    .catch(error => {
      console.log(error)
    })
  }

  handleDateRange = (value, valName) => {
    const myHeaders = {'token': Cookies.get('token')}

    if(valName === 'startDate') {
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID?startDate=${value.format('YYYY-MM-DD')}&endDate=${this.state.endDate.format('YYYY-MM-DD')}`, {
        headers: {
          token: Cookies.get('token')
        }
      })
      .then(response => response.json())
      .then(data => {
        if(data) {
          this.setState({
            tentpoleData: data,
            days: Math.floor(this.state.endDate.diff(value, 'days')),
            restoreHidden: false,
          })
        } else {
          this.setState({
            snackbar: true,
            errorMessage: 'There are no promotions within the selected range',
          })
        }
      })
      .catch(error => {
        console.log(error)
      })
    } else {
      console.log('endDate')
      console.log(value.format('YYYY-MM-DD'))
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID?startDate=${this.state.startDate.format('YYYY-MM-DD')}&endDate=${value.format('YYYY-MM-DD')}`, {
        headers: {
          token: Cookies.get('token')
        }
      })
      .then(response => response.json())
      .then(data => {
        if(data) {
          console.log(this.state.startDate)
          console.log(Math.floor(value.diff(this.state.startDate, 'days')))
          this.setState({
            tentpoleData: data,
            days: Math.floor(value.diff(this.state.startDate, 'days')),
            restoreHidden: false,
          })
        } else {
          this.setState({
            snackbar: true,
            errorMessage: 'There are no promotions within the selected range',
          })
        }
      })
      .catch(error => {
        console.log(error)
      })
    }

    this.setState({
      [valName]: value,
      restoreHidden: false,
    })
  }

  handleSliderDateChange = (values) => {
    const myHeaders = {'token': Cookies.get('token')}
    const startDate = values[0]
    const endDate = values[1]

    fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID?startDate=${startDate}&endDate=${endDate}`, {
      headers: {
        token: Cookies.get('token')
      }
    })
    .then(response => response.json())
    .then(data => {
      if(data) {
        this.setState({
          tentpoleData: data,
          restoreHidden: false,
          days: this.state.initialEndDate.diff(this.state.initialStartDate, 'days')

        })
      } else {
        this.setState({
          snackbar: true,
          errorMessage: 'There are no promotions within the selected range',
        })
      }
    })
    .catch(error => {
      console.log(error)
    })
  }

  handleDateClear = () => {
    const myHeaders = {'token': Cookies.get('token')}

    fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID?startDate=${this.state.initialStartDate.format('YYYY-MM-DD')}&endDate=${this.state.initialEndDate.format('YYYY-MM-DD')}`, {
      headers: {
        token: Cookies.get('token')
      }
    })
    .then(response => response.json())
    .then(data => {
      if(this._isMounted && data) {
        this.setState({
          tentpoleData: data,
          isLoading: false,
          restoreHidden: true,
          days: moment().diff(moment().startOf('year'), 'months') > 3 ? moment().diff(moment().startOf('year'), 'days') : moment().diff(moment().startOf('year').subtract(1, 'year'), 'days'),
        })
      } else {
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/recentDates?count=${10}`, {
          headers: {
            token: Cookies.get('token')
          }
        })
        .then(response => response.json())
        .then(data => {
          if(data.length > 0) {
            this.setState({
              startDate: data[data.length - 1],
              initialStartDate: data[data.length - 1],
              restoreHidden: true,
            })
            fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID?startDate=${data[data.length - 1]}&endDate=${this.state.initialEndDate.format('YYYY-MM-DD')}`, {
              headers: {
                token: Cookies.get('token')
              }
            })
            .then(response => response.json())
            .then(data => {
              this.setState({
                isLoading: false,
                tentpoleData: data,
                restoreHidden: true,
              })
            })
            .catch(error => {
              console.log(error)
            })
          } else {
            this.setState({
              isLoading: false,
              tentpoleData: [],
              restoreHidden: true,
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
      }
    })
    .catch(error => {
      console.log(error)
    })
  }

  handleDateChange(value) {
    const startDate = value[0]
    const endDate = value[1]

    fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID?startDate=${startDate.format('YYYY-MM-DD')}&endDate=${endDate.format('YYYY-MM-DD')}`, {
      headers: {
        token: Cookies.get('token')
      }
    })
    .then(response => response.json())
    .then(data => {
      if(data) {
        this.setState({
          tentpoleData: data,
          startDate: startDate,
          endDate: endDate
        })
      } else {
        this.setState({
          snackbar: true,
          errorMessage: 'There are no promotions within the selected range',
        })
      }
      // console.log(data)
    })
    .catch(error => {
      console.log(error)
    })
  }

  handleDateClear() {
    fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID?startDate=${moment(this.state.initialStartDate).format('YYYY-MM-DD')}&endDate=${moment().format('YYYY-MM-DD')}`, {
      headers: {
        token: Cookies.get('token')
      }
    })
    .then(response => response.json())
    .then(data => {
      if(this._isMounted) {
        this.setState({
          tentpoleData: data,
          startDate: this.state.initialStartDate,
          endDate: moment()
        })
      }
    })
    .catch(error => {
      console.log(error)
    })
  }

  handleDrawerOpen(e, jobID) {
    e.stopPropagation()
    const myHeaders = {'token': Cookies.get('token')}
    Promise.all([
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID/${jobID}/market`, {headers: myHeaders}),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID/${jobID}/location`, {headers: myHeaders}),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID/${jobID}/rfm`, {headers: myHeaders}),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID/${jobID}/test`, {headers: myHeaders}),
    ])
    .then(([response, response2, response3, response4]) => Promise.all([response.json(), response2.json(), response3.json(), response4.json()]))
    .then(([data, data2, data3, data4]) => {
      this.setState({
        openDrawer: true,
        roiLoading: false,
        roiData: this.state.tentpoleData.filter(item => item.JobID === jobID),
        marketData: data,
        locationData: data2,
        rfmData: data3,
        testData: data4,
      })
      // console.log(this.state.roiData)
    })

    .catch(error => {
      console.log(error)
    })
  }

  handleResponseDrawerOpen = (e, jobID) => {
    console.log('hello')
    e.stopPropagation()
    const myHeaders = {'token': Cookies.get('token')}
    Promise.all([
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID/${jobID}/demo`, {headers: myHeaders}),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/demoByLoc`, {headers: myHeaders}),
    ])
    .then(([response, response2]) =>  Promise.all([response.json(), response2.json()]))
    .then(([data, data2]) => {
      this.setState({
        responseLoading: false,
        openBottom: true,
        responseData: data,
        demoData: data2,
        job: jobID,
        jobData: this.state.tentpoleData.filter(item => item.JobID === jobID),
      })
    })
    .catch(error => {
      console.log(error)
    })
  }

  handleDrawerClose() {
    this.setState({
      openDrawer: false,
      openBottom: false,
    })
  }

  updateSearch(e) {
    if(e.target.value) {
      this.setState({
        search: e.target.value.substr(0,25),
        hidden: true
      })
    } else {
      this.setState({
        search: '',
        hidden: false
      })
    }
  }

  handleSnackbarClose(e, reason) {
    if(reason === 'clickaway') {
      this.setState({
        snackbar: false,
      })
    }
    this.setState({
      snackbar: false
    })
  }

  handleChangePage(e, newPage) {
    this.setState({
      page: newPage
    })
  }

  handleChangeRowsPerPage = e => {
    let value = e.target.value
    const rowsPerPage = value === 'Show All' ? this.state.tentpoleData.length : value

    this.setState({
      rowsPerPage: rowsPerPage,
      page: 0
    })
  }

  handleExpandPanel = panel => (e, expanded) => {
    console.log(panel, e,  expanded)
    this.setState({
      expanded: expanded ? panel : false
    })
  }

  render() {
    const { classes } = this.props

    let filteredItems = this.state.tentpoleData.filter(
      (item) => {
        // if(item.JobID !== this.props.openJob) {
        //   return item.Promotion.replace(/'/g, '').toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 || item.JobID.indexOf(this.state.search) !== -1
        // }
        return item.JobID !== this.props.openJob && item.Data_Extended.length > 0 && item.Data_Promo.length > 0 ? item.Promotion.replace(/'/g, '').toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 || item.JobID.indexOf(this.state.search) !== -1 : ''
      }
    )

    return(
      <Fragment>
        {/* <div className={classes.root}> */}
        {this.state.isLoading ? <CircularProgress /> :
        <div className={classes.panel}>
          <div>
          <Button variant="outlined" disableRipple size="small" onClick={this.handleClose} className={classes.closeButton}>
            <ArrowBackIcon fontSize="small" />
            <Typography style={{marginLeft: '10px'}} variant="caption">GO BACK</Typography>
          </Button>
          </div>
          <div style={{display: 'flex', margin: '15px', justifyContent: 'space-between'}}>
            <StyledTextField
              variant="outlined"
              label="Search For Promotion"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                )
              }}
              value={this.state.search}
              onChange={this.updateSearch.bind(this)}
              className={classes.search}
            />
            <div style={{marginTop: '25px', width: '450px'}}>
              {/* <DatePicker
                handleDateChange={this.handleDateChange}
                handleDateClear={this.handleDateClear}
                darkMode={this.props.darkMode}
                startDate={moment(this.state.initialStartDate)}
              /> */}

              <DayPicker
                startDate={this.state.minYearmo}
                endDate={this.state.maxYearmo}
                maxSliderValue={this.state.days}
                handleDateRange={this.handleDateRange}
                handleSliderDateChange={this.handleSliderDateChange}
                handleDateClear={this.handleDateClear}
                restoreHidden={this.state.restoreHidden}
              />
            </div>
          </div>
          <Snackbar
            open={this.state.snackbar}
            autoHideDuration={4500}
            onClose={this.handleSnackbarClose}
            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
          >
            <SnackbarContent
              message={
                <div style={{display: 'flex'}}>
                  <WarningIcon className={classes.error} fontSize="small" fontWeight="bold"/>
                  <span style={{fontWeight: 600, marginLeft: '15px'}}>{this.state.errorMessage}</span>
                </div>
              }
              style={{
                backgroundColor: '#ff9800'
              }}
            />
          </Snackbar>
          <br />
          <Drawer
            variant="persistent"
            anchor="right"
            open={this.state.openDrawer}
            classes={{
              paper: classes.drawerPaper
            }}
          >
            {this.state.roiLoading ? <CircularProgress /> :
              <LyTentpole
                tab={this.props.tab}
                dateExtended={this.props.dateExtended}
                handleDrawerClose={this.handleDrawerClose}
                data={this.state.roiData}
                marketData={this.state.marketData}
                locationData={this.state.locationData}
                rfmData={this.state.rfmData}
                testData={this.state.testData}
                yPosition={this.props.yPosition}
                darkMode={this.props.darkMode}
                userDemo={this.props.userDemo}
              />
              // <TentpoleROI
              //   handleDrawerClose={this.handleDrawerClose}
              //   data={this.state.roiData}
              //   marketData={this.state.marketData}
              //   locationData={this.state.locationData}
              // />
            }
          </Drawer>

          <Drawer
            variant="persistent"
            anchor="right"
            open={this.state.openBottom}
            classes={{
              paper: classes.drawerPaper
            }}
          >
            {this.state.responseLoading ?  <CircularProgress /> :
              <CompareResponse
                responseData={this.state.responseData}
                demoData={this.state.demoData}
                handleDrawerClose={this.handleDrawerClose}
                job={this.state.job}
                jobData={this.state.jobData}
              />
            }
          </Drawer>

          {filteredItems.reverse()
            .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
            .map(row => (
              <Fragment key={row.Key}>
                <ExpansionPanel
                   key={row.Key}
                   className={classes.expansionPanel}
                   expanded={this.state.expanded === row.Key}
                   onChange={this.handleExpandPanel(row.Key)}
                 >
                  <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                    style={{justifyContent: 'space-evenly'}}
                  >
                    <div className={classes.headColumns}>
                      <Typography className={classes.heading}>
                        {row.Promotion}
                      </Typography>
                      <Typography
                        variant="caption"
                        className={classes.heading}
                        style={{color: '#ababab', fontSize: '11px'}}
                      >
                        Job: {row.JobID}
                      </Typography>
                      <span style={{marginLeft: '10px'}}>
                        <CustomTooltip
                          title={`NOTE: Results for this promotion are incomplete at this time, Complete results will be available after the promotion has ended. Date last reported on ${moment(row.Data_Extended[0].MaxWrittenDate).format('MM/DD/YYYY')}`}
                          style={{fontSize: '25px'}}
                        >
                          <ErrorOutlineIcon style={row.Complete === 0 ? {fontSize: '15px', color: '#ed5e11'} : {display: 'none'}}/>
                        </CustomTooltip>
                      </span>
                    </div>
                    <div className={classes.headColumns}>
                      <Typography
                        variant="caption"
                        className={classes.secondaryHeading}
                        name="Date"
                      >
                        PROMO DATES:
                        <div>
                          <span>
                            {row.Data_Extended[0].PromoStartDate}
                            <span style={{marginLeft: '5px', marginRight: '5px'}}>
                              to
                            </span>
                            {row.Data_Extended[0].PromoEndDate}
                          </span>
                        </div>
                      </Typography>
                    </div>
                    <div className={classes.headColumns} style={{display: 'flex'}}>
                      <Typography variant="caption" align="right" className={this.props.viewName !== 'ROI' ? classes.hide : ''}><Link className={this.props.darkMode ? classes.linkDark : classes.link} onClick={(e) => this.handleDrawerOpen(e, row.JobID)}>VIEW ROI</Link></Typography>
                      <Typography variant="caption" align="right" className={this.props.viewName !== 'RESPONSE' ? classes.hide : ''}><Link className={this.props.darkMode ? classes.linkDark : classes.link} onClick={(e) => this.handleResponseDrawerOpen(e, row.JobID)}>VIEW RESPONSE DEMOGRAPHIC</Link></Typography>
                    </div>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <div style={{textAlign: 'center'}}>
                      <div style={{display: 'flex'}}>
                        <ButtonBase className={classes.button} onClick={e => e.preventDefault()}>
                          <img className={classes.img} alt="" src={row.Creative.One} />
                        </ButtonBase>
                        <div style={{marginLeft: '10px'}} />
                        <ButtonBase
                          className={classes.button}
                          onClick={e => e.preventDefault()}
                          // style={
                          //   this.props.userDemo === 'DEMO' ?
                          //   {display: 'none'} :
                          //   {}
                          // }
                        >
                          <img className={classes.img} alt="" src={row.Creative.Two} />
                        </ButtonBase>
                      </div>
                    </div>
                    <div className={classes.column}/>
                    <div className={clsx(classes.column, classes.helper)}>
                      <Typography variant="caption" className={classes.column} style={{fontWeight: 'bold'}}>OVERALL:</Typography>
                      <div style={{display: 'flex'}}>
                        {/* <div className={classes.column}>
                          <Typography variant="caption" className={classes.metric} style={{color: '#cc0000'}}>SPEND:</Typography>
                          <Typography variant="caption" className={classes.metricNumber} style={{marginLeft: '5px'}}>{'$' + Math.round(row.Data_Extended[0].Cost).toLocaleString('en')}</Typography>
                        </div> */}
                        <div className={classes.column}>
                          <Typography variant="caption" className={classes.metric} style={{color: '#b045ff'}}>MAILED:</Typography>
                          <Typography variant="caption" className={classes.metricNumber} style={{marginLeft: '5px'}}>{row.Data_Extended[0].Mailed.toLocaleString('en')}</Typography>
                        </div>
                        {/* <div className={classes.column}>
                          <Typography variant="caption" className={classes.metric} style={{color: '#006cc4'}}>RESPONSES:</Typography>
                          <Typography variant="caption" className={classes.metricNumber} style={{marginLeft: '5px'}}>{row.Data_Extended[0].Responses.toLocaleString('en')}</Typography>
                        </div> */}
                        <div className={classes.column}>
                          <Typography variant="caption" className={classes.metric} style={{color: '#529c00'}}>SALES:</Typography>
                          <Typography variant="caption" className={classes.metricNumber} style={{marginLeft: '5px'}}>{'$' + Math.round(row.Data_Extended[0].Sales).toLocaleString('en')}</Typography>
                        </div>
                        <div className={classes.column}>
                          <Typography variant="caption" className={classes.metric} style={{color: '#6ec6cc'}}>RESPONSE RATE:</Typography>
                          <Typography variant="caption" className={classes.metricNumber} style={{marginLeft: '5px'}}>{(row.Data_Extended[0].Res_Rate * 100).toFixed(2) + '%'}</Typography>
                        </div>
                      </div>
                      {/* <div style={{display: 'flex'}}>
                        <div className={classes.column}>
                          <Typography variant="caption" className={classes.metric} style={{color: '#deb40d'}}>AVG TICKET:</Typography>
                          <Typography variant="caption" className={classes.metricNumber} style={{marginLeft: '5px'}}>{'$' + Math.round(row.Data_Extended[0].Sales / row.Data_Extended[0].Responses).toLocaleString('en')}</Typography>
                        </div>
                      </div> */}
                      {/* <div style={{display: 'flex', float: 'right', marginTop: '20px'}}>
                        <div className={classes.column}>
                          <Typography variant="caption" align="right" className={classes.link}><Link onClick={() => this.handleDrawerOpen(row.JobID)}>VIEW ROI</Link></Typography>
                        </div>
                        <div className={classes.column}>
                          <Typography variant="caption" align="right" className={classes.link}><Link>RESPONSE DEMOGRAPHIC</Link></Typography>
                        </div>
                      </div> */}
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </Fragment>
            ))
          }
          <TablePagination
            rowsPerPageOptions={[10, 20, 'Show All']}
            component="div"
            count={filteredItems.length}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />

        </div>
        }
      </Fragment>
    )
  }
}

export default withStyles(styles)(ComparePanel)
