import React, { Component } from 'react'
import Calendar from 'rc-calendar'
import { withStyles, Tooltip, Typography } from '@material-ui/core/'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import Cookies from 'js-cookie'

import moment from 'moment'
import 'moment/locale/en-gb'

import '../../assets/calendar.css'

// set sunday as first day of the week
moment.updateLocale('en', {
  week: {
    dow: 0,
  }
})

const format = 'YYYY-MM-DD'
const today = moment()

const styles = theme => ({
  root: {
    margin: '-2em'
  },
  arrow: {
    fontSize: '10px'
  },
  tooltip: {
    fontSize: '12px',
    fontWeight: 'bold',
  }
})

class HomeCalendar extends Component {
  _isMounted = false

  constructor() {
    super()

    this.state = {
      value: today,
      calData: [],
    }
  }

  onChange = (value) => {
    this.setState({
      value,
    })
    Promise.all([
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/calendar?yearmo=${value.format('YYYY-MM')}`, {
        method: 'GET',
        headers: {
          'token': Cookies.get('token')
        },
      }),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/calendar?yearmo=${this.state.value.subtract(1, 'months').format('YYYY-MM')}`, {
        method: 'GET',
        headers: {
          'token': Cookies.get('token')
        },
      })
    ])
    .then(([response, response2]) => Promise.all([response.json(), response2.json()]))
    .then(([data, data2]) => {
      this.setState({
        calData: data,
      })
    })
    .catch(error => {
      console.log(error)
    })
  }

  componentDidMount = () => {
    this._isMounted = true

    // console.log(this.state.value.format('YYYY-MM'))

    fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/calendar?yearmo=${this.state.value.format('YYYY-MM')}`, {
      method: 'GET',
      headers: {
        'token': Cookies.get('token')
      },
    })
    .then(response => response.json())
    .then(data => {
      if(this._isMounted) {
        this.setState({
          calData: data,
        })
      }
    })
    .catch(error => {
      console.log(error)
    })
  }

  componentWillUnmount = () => {
    this._isMounted = false
  }

  render() {
    const state = this.state
    const { classes } = this.props
    return(
      <div className={classes.root}>
        <Calendar
          showDateInput={false}
          dateRender={(current) =>
            {
              for(let i = 0; i < this.state.calData.length; i++) {
                if(current.format('YYYY-MM-DD') === this.state.calData[i].Written_Date && this.state.calData[i].Data[0].Sales_YOY_Flag === 1) {
                  return (
                    // <Tooltip
                    //   title={
                    //     <Typography className={classes.tooltip}>There is a <span style={{color: '#15b345'}}>{Math.round(this.state.calData[i].Data[0].Sales_YOY * 100)}% increase</span> in sales from this year compared to last year.</Typography>
                    //   }
                    // >
                      <div className="rc-calendar-date up">{current.format('D')}<ArrowUpwardIcon className={classes.arrow} /></div>
                    // </Tooltip>
                  )
                }
              }
              for(let j = 0; j < this.state.calData.length; j++) {
                if(current.format('YYYY-MM-DD') === this.state.calData[j].Written_Date && this.state.calData[j].Data[0].Sales_YOY_Flag === -1) {
                  return <div className="rc-calendar-date down">{current.format('D')}<ArrowDownwardIcon className={classes.arrow} /></div>
                }
              }
              return <div className={'rc-calendar-date'}>{current.format('D')}</div>
            }
          }
          format={format}
          value={state.value}
          onChange={this.onChange}
          focusablePanel={false}
          className='calendar'
          style={this.props.darkMode ?
            {backgroundColor: '#3a3a3a', marginTop: 12}
          :
            {marginTop: 12}
          }
        />
      </div>
    )
  }
}

export default withStyles(styles)(HomeCalendar)
