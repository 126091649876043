import React, { Component, Fragment } from 'react'
// import styled from 'styled-components'
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc'
import { withStyles } from '@material-ui/core/styles'
import {
  Avatar,
  Card,
  CardHeader,
  CardContent,
  Typography,
  IconButton,
  GridList,
  Link,
  Tooltip,
  TextField,
  Grid,
  InputAdornment,
  MenuList,
  Paper,
  Menu,
  MenuItem,
  Icon,
  Divider,
  Button,
  SvgIcon,
  Fade,
  Collapse,
  Grow,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core'
import EventAvailableIcon from '@material-ui/icons/EventAvailable'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import TrendingDownIcon from '@material-ui/icons/TrendingDown'
import DashboardIcon from '@material-ui/icons/Dashboard'
import CloseIcon from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'
import AddToQueueIcon from '@material-ui/icons/AddToQueue'
import AppsIcon from '@material-ui/icons/Apps'
import SaveIcon from '@material-ui/icons/Save'
import RestorePageIcon from '@material-ui/icons/RestorePage'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import LocalFloristIcon from '@material-ui/icons/LocalFlorist'
import Cookies from 'js-cookie'
import WelcomeBoard from './WelcomeBoard'
import arrayMove from 'array-move'

import '../../assets/grid.css'

function MoreIcon(props) {
  return(
    <SvgIcon {...props}>
      <path fill="currentColor" d="M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4M12,10.5A1.5,1.5 0 0,1 13.5,12A1.5,1.5 0 0,1 12,13.5A1.5,1.5 0 0,1 10.5,12A1.5,1.5 0 0,1 12,10.5M7.5,10.5A1.5,1.5 0 0,1 9,12A1.5,1.5 0 0,1 7.5,13.5A1.5,1.5 0 0,1 6,12A1.5,1.5 0 0,1 7.5,10.5M16.5,10.5A1.5,1.5 0 0,1 18,12A1.5,1.5 0 0,1 16.5,13.5A1.5,1.5 0 0,1 15,12A1.5,1.5 0 0,1 16.5,10.5Z" />
    </SvgIcon>
  )
}

const styles = theme => ({
  banner: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    }
  },
  welcome: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    width: '100%',
    // height: 375,
    color: 'white',
    background: `linear-gradient(to right bottom, #039be5, #dcedc8)`,
    // backgroundColor: '#00147b',
    [theme.breakpoints.down('sm')]: {
      width: '96.8%'
    },
  },
  welcomeDark: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    width: '100%',
    // height: 375,
    color: 'white',
    background: `linear-gradient(to right bottom, rgba(3,154,229, 0.4), rgba(220, 237, 200, 0.4))`,
    // background: 'transparent',
    // opacity: 0.5,
    border: '1px solid #3bb2b4',
    [theme.breakpoints.down('sm')]: {
      width: '96.8%'
    },
  },
  grid: {
    alignItems: 'center',
    marginTop: '20px'
  },
  pageTitle: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '17px',
    fontWeight: 'light',
    color: 'rgba(115, 115, 115, 0.9)'
  },
  pageTitleDark: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '17px',
    fontWeight: 'light',
    color: 'rgba(255, 255, 255, 0.9)'
  },
  pageIcon: {
    fontSize: '15px',
    color: 'rgba(115, 115, 115, 0.9)',
    marginTop: '5px',
    marginRight: '2px',
  },
  pageIconDark: {
    fontSize: '15px',
    color: 'rgba(255, 255, 255, 0.9)',
    marginTop: '5px',
    marginRight: '2px',
  },
  homeGrid: {
    marginTop: theme.spacing(2),
  },
  search: {
    marginRight: theme.spacing(1.5),
  },
  iconbutton: {
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#4ab8bb'
    }
  },
  // button: {
  //   '&:hover': {
  //     backgroundColor: 'transparent',
  //     color: '#4ab8bb',
  //   }
  // },
  alerts: {
    borderRadius: '4px',
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    width: '100%',
    height: '75px',
    marginTop: '10px',
    overflowY: 'auto',
    // [theme.breakpoints.down('lg')]: {
    //   height: '75px'
    // }
    // [theme.breakpoints.down('lg')]: {
    //   width: '100%'
    // }
  },
  alertAvatar: {
    width: '35px',
    height: '35px'
  },
  alertMessage: {
    margin: '10px 10px 0px 10px',
    width: '80%',
    display: 'inline-flex',
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
      margin: '2px 10px 0px 10px',
    },
  },
  menuItem: {
    width: 400,
    justifyContent: 'space-between'
  },
  socialMedia: {
    display: 'flex',
    height: 275,
    width: 400,
    marginTop: '47px',
    border: '2px solid rgba(38, 76, 154, 0.7)',
    [theme.breakpoints.down('lg')] : {
      width: 300,
    }
  },
  bulletin: {
    height: 275,
    width: 400,
    marginTop: '47px',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    border: '2px solid rgba(235, 192, 84, 0.8)',
    overflowY: 'auto',
    [theme.breakpoints.down('lg')] : {
      width: 300,
    }
  },
  hidden: {
    display: 'none'
  }
})

// const StyledTextField = styled(TextField)`
//   label.Mui-focused {
//     color: #4ab8bb
//   }
//   &.Mui-focused {
//     border-bottom: #4ab8bb;
//   }
//
//   ${'' /* .MuiInput-root {
//     color: '#4ab8bb'
//     ${'' /* &.Mui-focused fieldset {
//       color: #4ab8bb
//     } */}
//   } */}
// `

const CustomButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 12,
    border: '0px',
    // backgroundColor: 'rgba(255, 255, 255, 0.4)',
    color: '#011689',
    height: '100%',
    '&:hover': {
      // backgroundColor: 'rgba(255, 255, 255, 0.1)',
      backgroundColor: 'transparent',
      color: '#0072ff',
      border: '0px',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none'
    },
    '&:focus': {
      boxShadow: 'none',
    }
  }
})(Button)

const DragHandle = sortableHandle(() => <span><DragHandleIcon style={{fontSize: '16px', marginTop: '5px'}} /></span>)

const SortableItem = sortableElement(({value, removePanel, darkMode, itemsLength}) => (
    <Fade in={true} timeout={500}>
      <Card className={itemsLength > 1 ? "card" : "single-card"} style={darkMode ? {border: `2px solid ${value.color}`} : {}}>
        <CardHeader
          avatar={
            <Avatar
              style={{
                backgroundColor: 'transparent',
                color: '#fff'
              }}
            >
              {value.icon}
            </Avatar>
          }
          action={

            itemsLength > 1 ?
              <Fragment>
                <Tooltip title="Hold to drag panel">
                  <IconButton style={{backgroundColor: 'transparent', color: '#fff'}} size='small' disableRipple>
                      <DragHandle />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Remove panel">
                  <IconButton onClick={value => removePanel(value)} style={{backgroundColor: 'transparent', color: '#fff'}} size='small' disableRipple>
                      <CloseIcon style={{fontSize: '14px'}}/>
                  </IconButton>
                </Tooltip>
              </Fragment>
            : <div />

          }
          title={
            <Typography variant="body2" style={{color: '#fff'}}>
              <Link href={itemsLength > 1 ? value.path : null} color="inherit" >{value.title}</Link>
            </Typography>
          }
          subheader={
            <Typography variant="caption" style={{color: 'rgba(255, 255, 255, 0.5)'}}>
              {value.subheader}
            </Typography>
          }
          style={
            darkMode ?
              {
                backgroundColor: value.colorDark,
                height: 60,
                // border: `2px solid ${value.color}`
              }
            :
              {
                backgroundColor: value.color,
                height: 60,
                border: `1px solid ${value.color}`,
              }
          }
        />
        <CardContent>
          {value.panel}
        </CardContent>
      </Card>
    </Fade>
))

const SortableContainer = sortableContainer(({children}) => {
  return (
    <GridList
      cellHeight={'auto'}
      cols={3}
      style={{
        justifyContent: 'space-evenly',
        marginTop: 15,
      }}
    >
      {children}
    </GridList>
  )
})

class HomeGrid extends Component {
  constructor(props) {
    super(props)
    this.state = {
      items: [],
      // sortingArr: [],
      search: '',
      hidden: false,
      menuOpen: false,
      anchorEl: null,
      removedItems: [],
      sorted: false,
      default: false,
      messages: [],
      currentUser: props.currentUser
    }

    this.handleRemovePanel = this.handleRemovePanel.bind(this)
  }

  componentDidMount = () => {
    const homeListArr = this.props.homeList

    Promise.all([
      fetch('https://api.dev.lsdirect.com/smartdash/V1/index.cfm/user', {
        headers: {
          'token': Cookies.get('token')
        }
      }),
      fetch('https://api.dev.lsdirect.com/smartdash/V1/index.cfm/welcome', {
        headers: {
          'token': Cookies.get('token')
        }
      }),
    ])
    .then(([response, response2]) => Promise.all([response.json(), response2.json()]))
    .then(([data, data2]) => {
      const sortingArr = data[0].arr_layout.map(item => parseInt(item))

      if(sortingArr.length > 0) {

        if(sortingArr.length < homeListArr.length) {
          let filteredHomeList = homeListArr.filter(item => sortingArr.includes(item.id))
          filteredHomeList.sort((a,b) => {
            return sortingArr.indexOf(a.id) - sortingArr.indexOf(b.id)
          })
          this.setState({
            items: filteredHomeList,
            default: true,
            messages: data2
          })
        } else {
          homeListArr.sort((a,b) => {
            return sortingArr.indexOf(a.id) - sortingArr.indexOf(b.id)
          })
          this.setState({
            items: homeListArr,
            default: true,
            messages: data2
          })
        }
      } else {
        this.setState({
          items: this.props.homeList,
          messages: data2
        })
      }
    })
    .catch(error => {
      console.log(error)
    })

    if(this.state.currentUser[0].username.toUpperCase().includes('USER')) {
    } else {
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/pageviews/add?display=dashboard_home&screen_name=dashboard_home`, {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'token': Cookies.get('token'),
        },
      })
      .then(response => {
        if(response.ok === true) {
          return response.json()
        }
      })
      .catch(error => {
        console.log(error)
      })
    }
  }

  updateSearch(e, row) {
    if(e.target.value) {
      this.setState({
        search: e.target.value.substr(0, 25),
        hidden: true,
      })
    } else if(row) {
      this.setState({
        search: row.title,
        hidden: true,
        menuOpen: false,
      })
    } else {
      this.setState({
        search: "",
        hidden: false,
      })
    }
  }

  handleRemovePanel(value) {
    const filterTitle = value.title
    this.setState({
      items: this.state.items.filter(item => {
        return item.title !== filterTitle
      }),
      sorted: true
    })
  }

  handleAddPanel(e, row) {
    let joined = this.state.items.concat(row)
    this.setState({
      items: joined,
      sorted: true
    })
  }

  onSortEnd = ({oldIndex, newIndex}) => {
    // console.log(oldIndex, newIndex)
    this.setState(({items}) => ({
      items: arrayMove(items, oldIndex, newIndex),
      sorted: true
    }))
  }

  handleTogglePanelMenu = (e) => {
    this.setState({
      anchorEl: e.currentTarget
    })
  }

  handleTogglePanelMenuClose = () => {
    this.setState({
      anchorEl: null
    })
  }

  handleSaveGrid = (e, filteredItems) => {
    let sortingArr = filteredItems.map(item => item.id).join()
    // console.log(filteredItems)
    // console.log(sortingArr)

    fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/admin/updateLayout?layout=${filteredItems.map(item => item.id).join()}`, {
      method: 'POST',
      headers: {
        'token': Cookies.get('token'),
      },
    })
    .then(response => {
      if(response.ok === true) {
        return response.json()
      }
    })
    .then(data => {
      // console.log(data)
    })
    .catch(error => {
      console.log(error)
    })

    this.setState({
      sorted: false,
      default: true
    })
  }

  handleRestoreGrid = () => {
    fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/admin/updateLayout?layout=${''}`, {
      method: 'POST',
      headers: {
        'token': Cookies.get('token')
      }
    })
    .then(response => {
      if(response.ok === true) {
        return response.json()
      }
    })
    .then(data => {
      // console.log(data)
    })
    .catch(error => {
      console.log(error)
    })

    this.setState({
      sorted: false,
      default: false,
    })

    window.location.reload()
    // localStorage.removeItem('sortHomeList')
    // window.location.reload()
    // this.setState({
    //   items: this.props.homeList
    // })
  }

  handleRemoveNotification = (e, key) => {
    // console.log(key)
    // console.log(this.props.alerts)
  }

  render() {
    const { classes } = this.props

    // console.log(this.state.items)
    // console.log(this.props.currentUser[0].userID)

    let filteredItems = this.props.currentUser[0].userID === 44 || this.props.currentUser[0].userID === 1045 || this.props.currentUser[0].userID === 370 ? this.state.items.filter(
      (item) => {
        return item.id === 1
      }
    ) : this.props.currentUser[0].db_owner === 'KHOOK' ? this.state.items.filter(item => item.title !== 'Demographics').filter(
      (item) => {
        return item.title.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
      }
    )
    :
    this.state.items.filter(
      (item) => {
        return item.title.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
      }
    )

    let addPanelList = this.props.currentUser[0].db_owner === 'KHOOK' ? 
      this.props.homeList.filter(item => item.title !== 'Demographics').filter(item => filteredItems.indexOf(item) === -1)
      :  this.props.homeList.filter(item => filteredItems.indexOf(item) === -1)

    console.log(filteredItems)

    return(
      <div>
        <Fade in={true}>
          <div style={{display: 'flex', marginLeft: '10px', justifyContent: 'space-between'}}>
            <Typography className={this.props.darkMode ? classes.pageTitleDark : classes.pageTitle} style={{marginTop: '25px'}}>
              <DashboardIcon className={this.props.darkMode ? classes.pageIconDark : classes.pageIcon} />
              <span style={{marginLeft: '5px'}}>DASHBOARD</span>
            </Typography>
            <div className={classes.search}>
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item>
                <TextField
                  label="Search"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon fontSize="small"/>
                      </InputAdornment>
                    )
                  }}
                  value={this.state.search}
                  onChange={this.updateSearch.bind(this)}
                />
              </Grid>
              <Tooltip title="View Panels List">
                <IconButton
                  className={this.props.currentUser[0].userID === 44 || this.props.currentUser[0].userID === 1045 || this.props.currentUser[0].userID === 370 ? classes.hidden : classes.iconbutton}
                  size="small"
                  disableRipple
                  onClick={this.handleTogglePanelMenu}
                >
                  <AppsIcon style={{marginBottom: '5px'}}/>
                </IconButton>
              </Tooltip>
              <Tooltip title="Save Changes">
                <IconButton
                  className={this.props.currentUser[0].userID === 44 || this.props.currentUser[0].userID === 1045 || this.props.currentUser[0].userID === 370 ? classes.hidden : classes.iconbutton}
                  size="small"
                  disableRipple
                  onClick={(e) => this.handleSaveGrid(e, filteredItems)}
                  style={this.state.sorted ? {} : {display: 'none'}}
                  >
                    <SaveIcon style={{marginBottom: '5px'}}/>
                  </IconButton>
              </Tooltip>

              <Tooltip title="Restore Default">
                <IconButton
                  className={this.props.currentUser[0].userID === 44 || this.props.currentUser[0].userID === 1045 || this.props.currentUser[0].userID === 370 ? classes.hidden : classes.iconbutton}
                  size="small"
                  disableRipple
                  onClick={this.handleRestoreGrid}
                  style={this.state.sorted || this.state.default ? {} : {display: 'none'}}
                  >
                    <RestorePageIcon style={{marginBottom: '5px'}}/>
                  </IconButton>
              </Tooltip>

              <div style={{textAlign: 'right', marginTop: '15px'}}>
                {/* <Button
                  color="primary"
                  variant="outlined"
                  className={classes.button}
                  onClick={(e) => this.handleSaveGrid(e, filteredItems)}
                  size="small"
                  style={this.state.sorted ? {} : {display: 'none'}}
                  disableRipple
                >
                  <span style={{marginTop: '2px'}}>Save Changes</span>
                </Button> */}

                {/* <Button
                  color="primary"
                  variant="outlined"
                  className={classes.button}
                  onClick={this.handleRestoreGrid}
                  size="small"
                  style={this.state.sorted || this.state.default ? {marginLeft: 15} : {display: 'none'}}
                  disableRipple
                >
                  <span style={{marginTop: '2px'}}>Restore Defaults</span>
                </Button> */}
              </div>

              <Menu
                anchorEl={this.state.anchorEl}
                keepMounted
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleTogglePanelMenuClose}
              >
                {addPanelList.length > 0 ?
                  <div>
                    <Typography variant="caption" style={{textDecoration: 'underline', color: '#ff3c51', marginLeft: '20px'}}>Screens Not Displayed On The Dashboard</Typography>
                    {addPanelList.map(row => (
                      <MenuItem dense key={row.title} className={classes.menuItem}>
                        <div style={{display: 'flex'}}>
                          <Icon style={{marginRight: '10px'}}>{row.icon}</Icon>
                          <Typography>{row.title}</Typography>
                        </div>
                        <div>
                          <Tooltip title="Add Screen to Dashboard">
                            <IconButton size="small" onClick={e => this.handleAddPanel(e, row)}>
                              <AddToQueueIcon style={{fontSize: '14px', color: '#6dd141'}} />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </MenuItem>
                    ))}
                    <Divider style={{marginBottom: '10px', marginTop: '10px'}}/>
                    <Typography variant="caption" style={{textDecoration: 'underline', color: '#52a22f', marginLeft: '20px'}}>Screens Displayed On The Dashboard</Typography>
                    {this.props.homeList.filter(item => addPanelList.indexOf(item) === -1).map(row => (
                      <MenuItem dense key={row.title} className={classes.menuItem}>
                        <div style={{display: 'flex'}}>
                          <Icon style={{marginRight: '10px', color: '#6a6a6a'}}>{row.icon}</Icon>
                          <Typography style={{color: '#6a6a6a'}}>{row.title}</Typography>
                        </div>
                        <div>
                          <Tooltip title={`Search for ${row.title}`}>
                            <IconButton size="small" onClick={e => this.updateSearch(e, row)}>
                              <SearchIcon style={{fontSize: '14px'}} />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </MenuItem>
                    ))}
                  </div>
                  :
                  <div>
                    <Typography variant="caption" style={{textDecoration: 'underline', color: '#52a22f', marginLeft: '20px'}}>Screens Displayed On The Dashboard</Typography>
                    {this.props.homeList.map(row => (
                      <MenuItem dense key={row.title} className={classes.menuItem}>
                        <div style={{display: 'flex'}}>
                          <Icon style={{marginRight: '10px', color: '#6a6a6a'}}>{row.icon}</Icon>
                          <Typography style={{color: '#6a6a6a'}}>{row.title}</Typography>
                        </div>
                        <div>
                          <Tooltip title={`Search for ${row.title}`}>
                            <IconButton size="small" onClick={e => this.updateSearch(e, row)}>
                              <SearchIcon style={{fontSize: '14px'}} />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </MenuItem>
                    ))}
                </div>
                }
              </Menu>
            </Grid>
            </div>
          </div>
        </Fade>

        <div className={classes.homeGrid}>
          <div className={classes.grid}>

            {this.state.hidden ? null :


              <div>
                <Fade in={true} timeout={500}>
                  <div className={classes.banner}>
                    <Card className={this.props.darkMode ? classes.welcomeDark : classes.welcome}>
                      <WelcomeBoard
                        currentUser={this.props.currentUser}
                        alerts={this.props.alerts}
                      />
                    </Card>
                  </div>
                </Fade>
                <br/>

              </div>
            //   <Fade in={true} timeout={500}>
            //   <div className={classes.banner}>
            //     <Card className={this.props.darkMode ? classes.welcomeDark : classes.welcome}>
            //       <CardContent style={{margin: '1em'}}>
            //         <div style={{justifyContent: 'space-between', display: 'flex'}}>
            //           <div style={{width: '100%'}}>
            //             <div style={{marginTop: '-10px'}}>
            //               <Typography variant="h5" style={{fontSize: '25px', fontWeight: 'bold'}}>Hello {this.props.currentUser[0].fname + ' ' + this.props.currentUser[0].lname}!</Typography><br />
            //             </div>
            //             <Typography variant="caption" style={{fontWeight: 'bold', fontSize: '15px'}}>MONTHLY TRENDS:</Typography>
            //             {this.props.alerts ?
            //               this.props.alerts.map(row => (
            //                 <Grow key={row.Key} in={true} timeout={2000}>
            //                   <div className={classes.alerts} style={row.Data[0].YOY_Index > 0 ? {border: '2px solid rgba(58, 115, 33, 0.5)'} : {border: '2px solid rgba(255, 60, 81, 0.5)'}}>
            //                     <div style={{display: 'flex', justifyContent: 'space-between'}}>
            //                       <div className={classes.alertMessage}>
            //                         {row.Data[0].YOY_Index > 0 ? <TrendingUpIcon style={{color: '#448727'}}/> : <TrendingDownIcon style={{color: '#ff3c51'}}/>}
            //                         <Typography style={{color: '#6a6a6a', marginLeft: '10px', marginTop: '5px'}} variant="body2">
            //                           The largest Year Over Year {row.Data[0].YOY_Index > 0 ? <span style={{color: '#448727'}}>increase</span> : <span style={{color: '#ff3c51'}}>decrease</span>} was for customers in the {row.Data[0].MetricValue} demographics by {row.Data[0].YOY_Index > 0 ? <span style={{color: '#448727'}}>{(row.Data[0].YOY_Index * 100).toFixed(2)}%</span> : <span style={{color: '#ff3c51'}}>{(row.Data[0].YOY_Index * 100).toFixed(2)}%</span>}
            //                         </Typography>
            //                       </div>
            //                       <div style={{display: 'inline-flex', alignItems: 'center', marginRight: '10px', marginTop: '7px'}}>
            //                         <CustomButton
            //                           // variant="contained"
            //                           href={'/demographic-trends'}
            //                         >
            //                           <Typography variant="caption">MORE INFO</Typography><MoreVertIcon fontSize="small" style={{marginLeft: '5px'}}/>
            //                         </CustomButton>
            //                       </div>
            //                     </div>
            //                   </div>
            //                 </Grow>
            //               )) : ''
            //             }
            //           </div>
            //
            //           <div style={{marginLeft: '25px', marginRight: '25px'}}>
            //             <Fade in={true} timeout={3000}>
            //               <Card elevation={0} className={classes.bulletin}>
            //                 <CardHeader
            //                   title={<Typography variant="caption" style={{fontSize: '14px', color: '#6a6a6a'}}>BULLETIN BOARD</Typography>}
            //                   style={{backgroundColor: 'rgba(255, 255, 255, 0.3)'}}
            //                 />
            //                 <CardContent>
            //                   <Typography variant="caption" style={{fontWeight: 'bold', color: '#6a6a6a'}}>Welcome to the all-new SmartDash by LS Direct!</Typography><br/>
            //                   <Typography variant="caption" style={{color: '#6a6a6a'}}>Here you can view the campaign metrics most important to you and gain deeper insights into your customers.</Typography>
            //
            //                   <List style={{color: '#6a6a6a', marginTop: '10px'}}>
            //                     <ListItem alignItems="flex-start">
            //                       <ListItemAvatar>
            //                         <Avatar style={{backgroundColor: 'transparent', color: '#2e9900'}}><EventAvailableIcon/></Avatar>
            //                       </ListItemAvatar>
            //                       <ListItemText
            //                         primary={<Typography variant="caption" style={{fontSize: '13px', fontWeight: 'bold', color: '#2e9900'}}>PREPARE FOR SUCCESS</Typography>}
            //                         secondary={
            //                           <Fragment>
            //                             <Typography variant="caption" style={{color: '#6a6a6a'}}>2021 Planning Meetings start in November!</Typography>
            //                           </Fragment>
            //                         }
            //                       />
            //                     </ListItem>
            //                     <Divider style={{backgroundColor: '#6a6a6a'}} variant="inset" component="li" />
            //                   </List>
            //                 </CardContent>
            //               </Card>
            //             </Fade>
            //           </div>
            //
            //           <div>
            //             <Fade in={true} timeout={3000}>
            //               <Card elevation={0} className={classes.socialMedia}>
            //                 <iframe
            //                   src="https://www.linkedin.com/embed/feed/update/urn:li:share:6717127140527153152"
            //                   height="383"
            //                   width="504"
            //                   frameBorder="0"
            //                   title="Embedded post"
            //                 >
            //
            //                 </iframe>
            //               </Card>
            //             </Fade>
            //           </div>
            //         </div>
            //
            //       </CardContent>
            //     </Card><br />
            //   </div>
            // </Fade>
            }
          </div>
          <SortableContainer
            onSortEnd={this.onSortEnd}
            axis={'xy'}
            useDragHandle
            useWindowAsScrollContainer
          >
            {filteredItems.map((value, index) => (
              <SortableItem
                key={value.title}
                index={index}
                value={value}
                removePanel={() => this.handleRemovePanel(value)}
                darkMode={this.props.darkMode}
                itemsLength={filteredItems.length}
              />
            ))}
          </SortableContainer>
        </div>
    </div>
    )
  }
}

export default withStyles(styles)(HomeGrid)
