import React, { Component } from 'react'
import Router from './Router'
import { withStyles } from '@material-ui/core/styles'
// import Footer from './components/Footer'

const styles = theme => ({
  root: {
  },
  login: {
    backgroundColor: '#0280be',
    minHeight: '100vh'
  }
})

class App extends Component {
  constructor() {
    super()
    this.state = {
      currentUser: {},
      token: '',
    }
  }

  render() {
    const { classes } = this.props

    return (
      <div className={classes.root}>
        <Router />
        {/* <Footer /> */}
      </div>
    )
  }
}

export default withStyles(styles)(App)
