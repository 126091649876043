import React, { useState, useEffect } from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import moment from 'moment'

const dateFormat = "MMMM DD, YYYY"

const useStyles = makeStyles(theme => ({
	updatedDate: {
		color: "#a3a3a3",
		position: "absolute"
	}
}))

const UpdatedThrough = props => {
	const { footerDate } = props
	const classes = useStyles()

	return (
		<Typography variant="caption" className={classes.updatedDate}><i>** Data has been updated through: <strong>{footerDate.format(dateFormat)}</strong> **</i></Typography>
	)
}

export default UpdatedThrough