import React, { Component } from 'react'
import Chart from 'react-apexcharts'
import moment from 'moment'

class LostOppChart extends Component {
  constructor(props) {
    super(props)

    this.state = {
      series: props.series,
      options: {
        chart: {
          type: 'bar',
          stacked: true,
          events: {
            legendClick: function(chartContext, seriesIndex, config) {
              console.log(seriesIndex)
            }
          },
          toolbar: {
            tools: {
              download: false,
            }
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          type: 'category',
          categories: props.categories,
          labels: {
            hideOverlappingLabels: false,
            rotateAlways: true,
            rotate: -45,
            formatter: value => {
              return moment(value).format('MMM YY')
            },
            style: {
              colors: props.darkMode ? '#fff' : '',
            }
          },
          tickAmount: props.categories.length,
          tickPlacement: 'on',
        },
        legend: {
          show: props.legend,
          inverseOrder: true,
          position: props.position ?  props.position : 'right',
          offsetY: 25,
          horizontalAlign: 'left',
          labels: {
            colors: props.darkMode ? '#fff' : ''
          },
          // markers: {
          //   onClick: function(chart, seriesIndex, opts) {
          //     console.log("series-" + seriesIndex + "clicked")
          //   }
          // }
        },
        fill: {
          opacity: 1,
        },
        colors: props.colors,
        yaxis: {
          tickAmount: 10,

          min: 0,
          max: this.props.maxLostArr.reduce((a,b) => Math.max(a,b)) > this.props.maxMailedArr.reduce((a,b) => Math.max(a,b)) ?
            this.props.maxLostArr.reduce((a,b) => Math.max(a,b)) : this.props.maxMailedArr.reduce((a,b) => Math.max(a,b)),
          labels: {
            formatter: value => {
              if(value <= 999) {
                return value
              } else if (value > 999 && value <= 999999 ) {
                return Math.round(value / 1000) + 'K'
              } else if (value > 999999 && value <= 999999999) {
                return (value / 1000000).toFixed(2) + 'M'
              } else {
                return (value / 1000000000).toFixed(2) + 'B'
              }
            },
            style: {
              colors: props.darkMode ? '#fff' : ''
            },
          },
          axisBorder: {
            show: true,
            color: props.darkMode ? '#fff' : ''
          }
        },
        tooltip: {
          // custom: function({series, seriesIndex, dataPointIndex, w}) {
            // console.log(w.config.xaxis.categories[dataPointIndex])
            // console.log(w)
          //   // console.log(w.config.series[seriesIndex].name)
          //   // console.log(series)
          //   // console.log(dataPointIndex)
            // return '<div width="100">' +
            //   '<div>' + w.config.xaxis.categories[dataPointIndex] + ': ' + '</div>' +
            //   '<div>' + w.config.series[seriesIndex].name + ': ' + '</div>' +
            //   '<span>' + (series[seriesIndex][dataPointIndex]).toLocaleString('en') + '</span>' +
            //   '</div>'
          //   // console.log(w.config.colors[seriesIndex])
          // },
          fixed: {
            enabled: props.fixed,
            position: 'right',
            offsetX: 35,
            offsetY: 225,
          },
          x: {
            format: 'MMM dd',
          },
          y: {
            formatter: value => {
              return value.toLocaleString('en')
            }
          }
        },
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return (nextProps.series !== prevState.series) ?
      {
        series: nextProps.series,
      } : null
  }

  render() {
    return(
      <div style={{height: 500}}>
      {console.log(this.state.series)}
        <Chart options={this.state.options} series={this.state.series} type="bar" height={this.props.height} />
      </div>
    )
  }
}

export default LostOppChart
