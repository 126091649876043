import React, { Component, Fragment } from 'react'
import HomeGrid from './HomeGrid'
import { withStyles, CircularProgress, SvgIcon } from '@material-ui/core'
import Brightness3Icon from '@material-ui/icons/Brightness3'
import TelegramIcon from '@material-ui/icons/Telegram'
import GpsFixedIcon from '@material-ui/icons/GpsFixed'
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import HowToRegIcon from '@material-ui/icons/HowToReg'
import PublicIcon from '@material-ui/icons/Public'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import TodayIcon from '@material-ui/icons/Today'
import BoomModule from '../boomerang/BoomModule'
import TriggersModule from '../triggers/TriggersModule'
import TentpoleMod from '../tentpole/TentpoleMod'
import RfmModule from '../rfm/RfmModule'
import ZipModule from '../zipcode/ZipModule'
import OtbMod from '../otb/OtbModule'
import LostOppMod from '../lostOpp/LostOppsModule'
// import CustomerRetentionModule from '../customerRetention/CustRetentionModule'
import CustomerRetentionModule from '../customerRetention/CustRetModule'
import DemoModule from '../demographics/DemoModule'
import Calendar from '../calendar/Calendar'
import Cookies from 'js-cookie'


import '../../assets/grid.css'

function BoomIcon(props) {
  return(
    <SvgIcon {...props}>
      <path d="M10 2H4C2.9 2 2 2.9 2 4S2.9 6 4 6H8L10 2M18 2C20.2 2 22 3.8 22 6V12L18 14C18 9.6 14.4 6 10 6L12 2H18M18 20V16L22 14V20C22 21.1 21.1 22 20 22S18 21.1 18 20Z" />
    </SvgIcon>
  )
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      flexGrow: 0,
      marginRight: '35px',
    },
    [theme.breakpoints.up('md')]: {
      marginRight: '15px'
    }
  },
  circularprogress: {
    marginLeft: '45vw',
    marginTop: '40vh'
  }
})

const homeList = JSON.parse(localStorage.getItem("currentUser")) && JSON.parse(localStorage.getItem("currentUser"))[0].db_owner === "AHS-TRAFFIC" ?
[  {
    id: 1,
    icon: <TodayIcon />,
    title: 'Calendar',
    path: '/calendar',
    panel: <Calendar darkMode={JSON.parse(localStorage.getItem('darkMode'))} tooltip={true}/>,
    color: '#bd4c46',
    colorDark: 'rgba(189, 76, 70, 0.5)',
    tooltip: 'Green arrows pointing upward indicate a positive change in sales from the previous year. Red Arrows point downward indicate a negative change in sales from the previous year.'
    // colorDark: '#d67974',
  },
]
:
[
  {
    id: 1,
    icon: <TodayIcon />,
    title: 'Calendar',
    path: '/calendar',
    panel: <Calendar darkMode={JSON.parse(localStorage.getItem('darkMode'))} tooltip={true}/>,
    color: '#bd4c46',
    colorDark: 'rgba(189, 76, 70, 0.5)',
    tooltip: 'Green arrows pointing upward indicate a positive change in sales from the previous year. Red Arrows point downward indicate a negative change in sales from the previous year.'
    // colorDark: '#d67974',
  },
  {
    id: 2,
    icon: <BoomIcon />,
    title: 'Boomerang ROI',
    path: '/boomerang',
    panel: <BoomModule darkMode={JSON.parse(localStorage.getItem('darkMode'))} />,
    color: '#3b80b8',
    colorDark: 'rgba(59, 128, 184, 0.5)',
    subheader: 'Past 12 Months'
  },
  // {
  //   id: 3,
  //   icon: <TelegramIcon />,
  //   title: 'Trigger ROI',
  //   path: '/trigger',
  //   panel: <TriggersModule darkMode={JSON.parse(localStorage.getItem('darkMode'))} />,
  //   color: '#3b80b8',
  //   colorDark: 'rgba(59, 128, 184, 0.5)',
  //   subheader: 'Past 12 Months'
  // },
  {
    id: 3,
    icon: <GpsFixedIcon />,
    title: 'Boomerang Lost Opportunity',

    path: '/boomerang/lost-opp',

    panel: <LostOppMod darkMode={JSON.parse(localStorage.getItem('darkMode'))} />,
    color: '#3b80b8',
    colorDark: 'rgba(59, 128, 184, 0.5)',
    subheader: 'Current Calendar Year'
  },
  {
    id: 4,
    icon: <MarkunreadMailboxIcon />,
    title: 'Event Marketing',
    path: '/event-marketing',
    panel: <TentpoleMod darkMode={JSON.parse(localStorage.getItem('darkMode'))} />,
    color: '#3b80b8',
    colorDark: 'rgba(59, 128, 184, 0.5)',
    subheader: 'Past 12 Months'
  },
  {
    id: 5,
    icon: <HowToRegIcon />,
    title: 'RFM Counts',
    path: '/rfm-counts',
    panel: <RfmModule darkMode={JSON.parse(localStorage.getItem('darkMode'))} />,
    color: '#eb8e05',
    colorDark: 'rgba(235, 143, 5, 0.5)',
  },
  {
    id: 6,
    icon: <ShoppingCartIcon />,
    title: 'OTB Counts',
    path: '/otb-counts',
    panel: <OtbMod darkMode={JSON.parse(localStorage.getItem('darkMode'))} />,
    color: '#eb8e05',
    colorDark: 'rgba(235, 143, 5, 0.5)',
  },
  {
    id: 7,
    icon: <PersonAddIcon />,
    title: 'Customer Retention',
    path: '/customer-retention',
    panel: <CustomerRetentionModule darkMode={JSON.parse(localStorage.getItem('darkMode'))} />,
    color: '#eb8e05',
    colorDark: 'rgba(235, 143, 5, 0.5)',
  },
  {
    id: 8,
    icon: <LocationOnIcon />,
    title: 'Zip Codes',
    path: '/zip-code',
    panel: <ZipModule darkMode={JSON.parse(localStorage.getItem('darkMode'))} />,
    color: '#17ab03',
    colorDark: 'rgba(23, 171, 3, 0.5)',
    subheader: 'Top Zip Codes By YOY Sales'
  },
  {
    id: 9,
    icon: <PublicIcon />,
    title: 'Demographics',
    path: '/demographic',
    panel: <DemoModule darkMode={JSON.parse(localStorage.getItem('darkMode'))} />,
    color: '#17ab03',
    colorDark: 'rgba(23, 171, 3, 0.5)',
  },
]

class Home extends Component {
  _isMounted = false

  constructor(props){
    super(props)
    this.state = {
      isLoading: true,
      homeList: homeList,
      currentUser: {},
      alerts: []
    }
  }

  componentDidMount() {
    this._isMounted = true

    Promise.all([
      fetch('https://api.dev.lsdirect.com/smartdash/V1/index.cfm/user', {
        method: 'GET',
        headers: {
          'token': Cookies.get('token')
        },
      }),
      fetch('https://api.dev.lsdirect.com/smartdash/V1/index.cfm/demoTrends/alerts?months=1', {
        method: 'GET',
        headers: {
          'token': Cookies.get('token')
        },
      }),
      fetch('https://api.dev.lsdirect.com/smartdash/V1/index.cfm/demoTrends/alerts?months=2', {
        method: 'GET',
        headers: {
          'token': Cookies.get('token')
        },
      }),
    ])
    .then(([response, response2, response3]) => Promise.all([response.json(), response2.json(), response3.json()]))
    .then(([data, data2, data3]) => {
      console.log(data)
      if(this._isMounted) {
        this.setState({
          isLoading: false,
          currentUser: data,
          alerts: data2.length > 0 ? data2 : data3,
        })
      }
    })
    .catch(error => {
      console.log(error)
    })
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    const { classes } = this.props

    return(
      <div className={classes.root}>
        {this.state.isLoading ? <CircularProgress className={classes.circularprogress}/> :
          <Fragment>
          {console.log(this.state.currentUser)}
            {/* {console.log(this.state.currentUser)} */}
            {/* logged in? : {this.props.loggedInStatus}
            currentUser: {this.props.currentUser} */}
            <HomeGrid homeList={this.state.homeList} currentUser={this.state.currentUser} alerts={this.state.alerts} darkMode={this.props.darkMode}/>
        </Fragment>
        }
      </div>
    )
  }
}

export default withStyles(styles)(Home)
