import React, { Component } from 'react'
import Cookies from 'js-cookie'
import {
  withStyles,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TablePagination,
  CircularProgress,
  Select,
  MenuItem,
  InputBase,
  Typography,
} from '@material-ui/core'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import GroupIcon from '@material-ui/icons/Group'

const styles = theme => ({

  // newGrowthTable: {
  //   textAlign: 'center',
  // },
  tableBackground: {
    width: '70%'
  },
  container: {
    width: '100%'
  },
  pctGrowth: {
    '&:hover': {
      border: '1px solid #3f6b4b',
      cursor: 'pointer'
    }
  },
  customerCount: {
    width: 200,
    '&:hover': {
      // border: '1px solid #525252'
      backgroundColor: 'rgba(85, 85, 85, 0.1)'
    }
  },
  timeFrame: {
    width: 100,
  },
  tableRow: {
    height: 50,
  },
  select: {
    color: 'rgba(115, 115, 115, 0.9)',
    marginLeft: '5px',
    width: 300
  }
})

const StyledSelect = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'transparent',
    border: '1px solid #bfbfbf',
    fontSize: 16,
    padding: '17.5px 26px 17px 26px',
    '&:focus': {
      borderRadius: 4,
      borderColor: '#1d3d74'
    }
  }
}))(InputBase)

class NewCustGrowth extends Component {
  _isMounted = false

  constructor() {
    super()
    this.state = {
      isLoading: true,
      growthData: [],
      growthMetric: 'new',
      rowsPerPage: 0,
      page: 0,
      timeFrame: 12
    }
  }

  componentDidMount = () => {
    this._isMounted = true

    fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/custRetention/newCustCount`, {
      headers: {
        'token': Cookies.get('token')
      }
    })
    .then(response => response.json())
    .then(data => {
      if(this._isMounted && data) {
        this.setState({
          growthData: data,
          rowsPerPage: data.length > 10 ? Math.round(data.length / 2) : data.length,
          isLoading: false,
        })
      }
    })
    .catch(error => {
      console.log(error)
    })
  }

  componentWillUnmount = () => {
    this._isMounted = false
  }

  handleSelect = (row) => {
    console.log(row)
  }

  handleChangePage = (e, newPage) => {
    this.setState({
      page: newPage
    })
  }

  handleChangeRowsPerPage = e => {
    let value = e.target.value
    const rowsPerPage = value

    this.setState({
      rowsPerPage: rowsPerPage,
      page: 0,
    })
  }

  handleTimeFrameChange = e => {
    this.setState({
      timeFrame: e.target.value
    })

    let months = e.target.value
    fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/custRetention/newCustCount?months=${months}`, {
      headers: {
        'token': Cookies.get('token')
      }
    })
    .then(response => response.json())
    .then(data => {
      if(this._isMounted && data) {
        this.setState({
          growthData: data,
        })
      }
    })
    .catch(error => {
      console.log(error)
    })

  }

  handleMetricChange = e => {
    this.setState({
      growthMetric: e.target.value
    })
  }

  render() {
    const { classes } = this.props
    let totalRows = this.state.growthData.length
    let halfRows = Math.round(this.state.growthData.length / 2)
    return(
      <div>
      {this.state.isLoading ? <CircularProgress /> :
        <div style={{marginTop: '30px'}}>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <Paper className={classes.tableBackground}>
              <TableContainer className={classes.container}>
                <Table size="small" className={classes.newGrowthTable}>
                  <TableHead>
                    <TableRow style={{height: 65}}>
                      <TableCell>Location</TableCell>
                      <TableCell align="right">Time Frame</TableCell>
                      <TableCell align="right">Customer Count</TableCell>
                      <TableCell align="right">New Customer Count</TableCell>
                      <TableCell align="right">Percent Growth</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.growthData.sort((a,b) => (a.Data[0].Pct_Grwth_Customers < b.Data[0].Pct_Grwth_Customers) ? 1 : -1)
                      .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                      .map(row => (
                      <TableRow key={row.Key} style={{height: 35}} hover className={classes.tableRow}>
                        <TableCell>{row.Location}</TableCell>
                        <TableCell
                          className={classes.timeFrame}
                          align="right"
                          >
                          {this.state.timeFrame === 12 ? '1 Year' : this.state.timeFrame === 1 ? '30 Days' : '90 Days'}
                        </TableCell>
                        <TableCell className={classes.customerCount} align="right">{(row.Data[0].Tot_Customers_Loc).toLocaleString('en')}</TableCell>
                        <TableCell className={classes.customerCount} align="right">{(row.Data[0].Customers_New).toLocaleString('en')}</TableCell>
                        <TableCell
                          align="right"
                          style={
                           row.Data[0].Pct_Grwth_Customers > 1
                           ? {backgroundColor: '#39ad73', color: '#fff'}
                           : row.Data[0].Pct_Grwth_Customers === 1
                           ? {backgroundColor: '#61c771', color: '#676767'}
                           : row.Data[0].Pct_Grwth_Customers > 0
                           ? {backgroundColor: '#b1e0b8', color: '#676767'}
                           : {}
                          }
                          className={classes.pctGrowth}
                          onClick={() => this.handleSelect(row)}
                        >
                          {(row.Data[0].Pct_Grwth_Customers * 100).toFixed(2) + '%'}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[halfRows, totalRows]}
                component="div"
                count={this.state.growthData.length}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </Paper>
            <div>
              <Typography style={{textAlign: 'right', color: '#6a6a6a'}}>Time Frame</Typography>
              <Select
                value={this.state.timeFrame}
                variant="outlined"
                inputProps={{
                  name: 'time'
                }}
                input={<StyledSelect />}
                onChange={this.handleTimeFrameChange}
                className={classes.select}
              >
                <MenuItem value={12}>1 Year</MenuItem>
                <MenuItem value={1}>30 Days</MenuItem>
                <MenuItem value={3}>90 Days</MenuItem>
                {/* <MenuItem value={90}>90 Days</MenuItem> */}
              </Select>

              <div style={{marginTop: '15px'}}>
                <Typography style={{textAlign: 'right', color: '#6a6a6a'}}>Growth Metric</Typography>
                <Select
                  value={this.state.growthMetric}
                  variant="outlined"
                  inputProps={{
                    name: 'metric'
                  }}
                  input={<StyledSelect />}
                  onChange={this.handleMetricChange}
                  className={classes.select}
                  >
                    <MenuItem value={'new'}>New Customers</MenuItem>
                  <MenuItem value={'returning'}>Returning Customers</MenuItem>
                  <MenuItem value={'total'}>Total Customers</MenuItem>
                </Select>
              </div>
              <br />
              <div>
                <Typography
                  variant="h6"
                  style={{
                    textAlign: 'right',
                    fontWeight: 'bold',
                    fontSize: '17px',
                    color: '#6a6a6a'
                  }}
                >
                  Total Customer Count: <span style={{color: '#0381b6'}}>{(this.state.growthData[0].Data[0].Tot_Customers).toLocaleString('en')}</span>
                </Typography>
              </div>
            </div>
          {console.log(this.state.growthData.map(item => item))}
          </div>
        </div>
      }
      </div>
    )
  }
}

export default withStyles(styles)(NewCustGrowth)
