import React, { Component } from 'react'
import {
  withStyles,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination
} from '@material-ui/core'

const styles = theme => ({
  hide: {
    display: 'none'
  }
})

class RoiTables extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rowsPerPage: 10,
      page: 0,
    }
  }

  handleChangePage = (e, newPage) => {
    this.setState({
      page: newPage
    })
  }

  handleChangeRowsPerPage = e => {
    this.setState({
      rowsPerPage: parseInt(e.target.value, 10),
      page: 0
    })
  }

  render() {
    const { classes } = this.props
    return(
      <div>
        <Table size="small">
          <TableHead>
            <TableRow style={{height: '25px'}}>
              {
                this.props.tab === 0 ? <TableCell style={{fontWeight: 'bold', fontSize: '9px', }}>Location</TableCell>
                : this.props.tab === 1 ? <TableCell style={{fontWeight: 'bold', fontSize: '9px', }}>RFM Segment</TableCell>
                : <TableCell style={{fontWeight: 'bold', fontSize: '9px', }}>Test Group</TableCell>
              }
              <TableCell className={this.props.hide ? classes.hide : ''} style={{fontWeight: 'bold', fontSize: '9px', color: '#cc0000'}} align="right">Spend</TableCell>
              <TableCell style={{fontWeight: 'bold', fontSize: '9px', color: '#b045ff'}} align="right">Mailed</TableCell>
              <TableCell style={{fontWeight: 'bold', fontSize: '9px', color: '#006cc4'}} align="right">Responses</TableCell>
              <TableCell style={{fontWeight: 'bold', fontSize: '9px', color: '#529c00'}} align="right">Sales</TableCell>
              <TableCell style={{fontWeight: 'bold', fontSize: '9px', color: '#6ec6cc'}} align="right">Response Rate</TableCell>
              <TableCell style={{fontWeight: 'bold', fontSize: '9px', color: '#f27272'}} align="right">DM CoA</TableCell>
              <TableCell className={this.props.hide ? classes.hide : ''} style={{fontWeight: 'bold', fontSize: '9px', color: '#f27272'}} align="right">Cost Per Thousand</TableCell>
              <TableCell className={this.props.hide ? classes.hide : ''} style={{fontWeight: 'bold', fontSize: '9px', color: '#f27272'}} align="right">Total CoA</TableCell>
              <TableCell className={this.props.hide ? classes.hide : ''} style={{fontWeight: 'bold', fontSize: '9px', color: '#deb40d'}} align="right">Average Ticket</TableCell>
              <TableCell className={this.props.hide ? classes.hide : ''} style={{fontWeight: 'bold', fontSize: '9px', color: '#8ebf56'}} align="right">Total Sales</TableCell>
              <TableCell style={{fontWeight: 'bold', fontSize: '9px', color: '#8ebf56'}} align="right">% of Total Sales</TableCell>
              <TableCell style={{fontWeight: 'bold', fontSize: '9px', color: '#de7c0d'}} align="right">Contribution Margin ROI</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.data
              .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
              .map(row => (
                <TableRow key={row.Key} style={this.props.darkMode ? row.Key % 2 ? {backgroundColor: 'rgba(0, 0, 0, 0.1)'} : {backgroundColor: ''} : row.Key % 2 ? {backgroundColor: 'rgba(199, 199, 199, 0.2)'} : {backgroundColor: '#fff'}}>
                  <TableCell name="customerLabel" style={{fontSize: '12px'}}>{row.Label}</TableCell>
                  <TableCell className={this.props.hide ? classes.hide : ''} name="cost" style={{fontSize: '12px'}} align="right">
                    {this.props.dateExtended === 'Extended Date'
                      ? '$' + Math.round(row.Data_Extended[0].Cost).toLocaleString('en')
                      : '$' + Math.round(row.Data_Promo[0].Cost).toLocaleString('en')
                    }
                  </TableCell>
                  <TableCell name="mailed" style={{fontSize: '12px'}} align="right">
                    {this.props.dateExtended === 'Extended Date'
                      ? row.Data_Extended[0].Mailed.toLocaleString('en')
                      : row.Data_Promo[0].Mailed.toLocaleString('en')
                    }
                  </TableCell>
                  <TableCell name="response" style={{fontSize: '12px'}} align="right">
                    {this.props.dateExtended === 'Extended Date'
                      ? row.Data_Extended[0].Responses.toLocaleString('en')
                      : row.Data_Promo[0].Responses.toLocaleString('en')
                    }
                  </TableCell>
                  <TableCell name="sales" style={{fontSize: '12px'}} align="right">
                    {this.props.dateExtended === 'Extended Date'
                      ? '$' + Math.round(row.Data_Extended[0].Sales).toLocaleString('en')
                      : '$' + Math.round(row.Data_Promo[0].Sales).toLocaleString('en')
                    }
                  </TableCell>
                  <TableCell name="responseRate" style={{fontSize: '12px'}} align="right">
                    {this.props.dateExtended === 'Extended Date'
                      ? (row.Data_Extended[0].Res_Rate * 100).toFixed(2) + '%'
                      : (row.Data_Promo[0].Res_Rate * 100).toFixed(2) + '%'
                    }
                  </TableCell>
                  <TableCell name="dmCoA" style={{fontSize: '12px'}} align="right">
                    {this.props.dateExtended === 'Extended Date'
                      ? row.Data_Extended[0].Sales > 0 ? (row.Data_Extended[0].Cost / row.Data_Extended[0].Sales * 100).toFixed(2) + '%' : null
                      : row.Data_Promo[0].Sales > 0 ? (row.Data_Promo[0].Cost / row.Data_Promo[0].Sales * 100).toFixed(2) + '%' : null
                    }
                  </TableCell>
                  <TableCell className={this.props.hide ? classes.hide : ''} name="costPerThousand" style={{fontSize: '12px'}} align="right">
                    {this.props.dateExtended === 'Extended Date'
                      ? row.Data_Extended[0].Mailed > 0 ? '$' + Math.round(row.Data_Extended[0].Cost / row.Data_Extended[0].Mailed * 1000).toLocaleString('en') : '$0'
                      : row.Data_Promo[0].Mailed > 0 ? '$' + Math.round(row.Data_Promo[0].Cost / row.Data_Promo[0].Mailed * 1000).toLocaleString('en') : '$0'
                    }
                  </TableCell>
                  <TableCell className={this.props.hide ? classes.hide : ''} name="totalCoA" style={{fontSize: '12px'}} align="right">
                    {this.props.dateExtended === 'Extended Date'
                      ? row.Data_Extended[0].Total_Sales > 0 ? row.Data_Extended[0].Total_Sales ? (row.Data_Extended[0].Cost / row.Data_Extended[0].Total_Sales * 100).toFixed(2) + '%' : '0.00%' : null
                      : row.Data_Promo[0].Total_Sales > 0 ? row.Data_Promo[0].Total_Sales ? (row.Data_Promo[0].Cost / row.Data_Promo[0].Total_Sales * 100).toFixed(2) + '%' : '0.00%' : null
                    }
                  </TableCell>
                  <TableCell className={this.props.hide ? classes.hide : ''} name="avgTicket" style={{fontSize: '12px'}} align="right">
                    {this.props.dateExtended === 'Extended Date'
                      ? row.Data_Extended[0].Responses ? '$' + Math.round(row.Data_Extended[0].Sales / row.Data_Extended[0].Responses).toLocaleString('en') : null
                      : row.Data_Promo[0].Responses ? '$' + Math.round(row.Data_Promo[0].Sales / row.Data_Promo[0].Responses).toLocaleString('en') : null
                    }
                  </TableCell>
                  <TableCell className={this.props.hide ? classes.hide : ''} name="totalSales" style={{fontSize: '12px'}} align="right">
                    {this.props.dateExtended === 'Extended Date'
                      ? '$' + Math.round(row.Data_Extended[0].Total_Sales).toLocaleString('en')
                      : '$' + Math.round(row.Data_Promo[0].Total_Sales).toLocaleString('en')
                    }
                  </TableCell>
                  <TableCell name="pctTotalSales" style={{fontSize: '12px'}} align="right">
                    {this.props.dateExtended === 'Extended Date'
                      ? row.Data_Extended[0].Total_Sales ? (row.Data_Extended[0].Sales / row.Data_Extended[0].Total_Sales * 100).toFixed(2) + '%' : null
                      : row.Data_Promo[0].Total_Sales ? (row.Data_Promo[0].Sales / row.Data_Promo[0].Total_Sales * 100).toFixed(2) + '%' : null
                    }
                  </TableCell>
                  <TableCell name="contributionRoi" style={{fontSize: '12px'}} align="right">
                    {Boolean(this.props.contributionFactor) 
                    ? this.props.dateExtended === 'Extended Date'                                  
                      ? (((((row.Data_Extended[0].Sales / row.Data_Extended[0].Res_Rate * (row.Data_Extended[0].Res_Rate - row.Data_Extended[0].HO_Res_Rate)) / row.Data_Extended[0].Cost) * this.props.contributionFactor) - 1) * 100).toFixed(2) + '%'
                      : (((((row.Data_Promo[0].Sales / row.Data_Promo[0].Res_Rate * (row.Data_Promo[0].Res_Rate - row.Data_Promo[0].HO_Res_Rate)) / row.Data_Promo[0].Cost) * this.props.contributionFactor) - 1) * 100).toFixed(2) + '%'
                    : 'N/A'
                    }
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>

        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={this.props.data.length}
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
        <br/>
      </div>
    )
  }
}

export default withStyles(styles)(RoiTables)
