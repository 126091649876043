import React from 'react'
import {
  makeStyles,
  useTheme,
  Card,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Divider,
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  table: {
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  tableItems: {
    [theme.breakpoints.down('lg')]: {
      width: 100,
    },
    [theme.breakpoints.up('xl')]: {
      width: 150
    },
    [theme.breakpoints.down('md')]: {
      width: 75,
    }
  }
}))

export default function RecBudget(props) {
  const classes = useStyles()

  return(
    <div>
      {/* <Table>
        <TableBody>
          <TableRow className={classes.table}>
            {props.data.map(row => (
              <TableCell key={row.ProductName} align="center"><Typography variant="caption">{row.ProductName.toUpperCase()}</Typography></TableCell>
            ))}
          </TableRow>
          <TableRow>
            {props.data.map(row => (
              <TableCell key={row.ProductName} align="center">{(row.Data[0].Pct_Recommend * 100).toFixed(1) + '%'}</TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table> */}
      <div style={{justifyContent: 'space-evenly', textAlign: 'center'}}>
        <div style={{display: 'flex', justifyContent: 'space-evenly', marginTop: '25px'}}>
          <div/>
          {props.data.map(row => (
            <div key={row.Key} className={classes.tableItems}>
              <Typography variant="caption">{row.ProductName.toUpperCase()}</Typography>
            </div>
          ))}
          <div/>
        </div>
        <br/>
        <Divider style={{marginTop: '5px', marginBottom: '5px'}}/>
        <br/>
        <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
          <div/>
          {props.data.map(row => (
            <div key={row.Key} className={classes.tableItems}>
              <Typography variant="caption" style={{fontSize: '15px'}}>{(row.Data[0].Pct_Recommend * 100).toFixed(1) + '%'}</Typography>
            </div>
          ))}
          <div/>
        {/* <Typography>{props.data[0].ProductName}</Typography> */}
        </div>
      </div>
    </div>
  )
}
