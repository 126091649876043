import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import Login from './Login'

const styles = theme => ({
  login: {
    // backgroundColor: '#0280be',
    background: 'linear-gradient(45deg, #92d1e9, #10a1da, #0280be, #3d4ba6)',
    minHeight: '100vh'
  }
})

class LandingPage extends Component {
  constructor(props) {
    super(props)

    this.handleSuccess = this.handleSuccess.bind(this)
  }

  handleSuccess(data) {
    this.props.handleLogin(data)
    // this.props.checkLogin(data)
    this.props.history.push('/')
  }

  render() {
    const { classes } = this.props

    return(
      <div className={classes.login}>
        <Login handleSuccess={this.handleSuccess}/>
      </div>
    )
  }
}

export default withStyles(styles)(LandingPage)
