import React, { Component } from 'react'
import ReactExport from 'react-data-export'
import {
  withStyles,
  Typography,
  Button,
} from '@material-ui/core'
import { RiFileExcel2Fill } from 'react-icons/ri'
import { FaFileDownload } from 'react-icons/fa'
import moment from 'moment'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

const styles = theme => ({
  button: {
    color: '#0c7238',
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#17c261'
    }
  },
  icon: {
    fontSize: '17px',
    marginLeft: '5px'
  }
})

class RoiExcel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      markets: props.market,
    }
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    return (nextProps.markets !== prevState.markets) ?
      {
        markets: nextProps.markets.length > 1 ? nextProps.markets.join('&') : nextProps.markets
      } : null
  }

  getTable = data => {    
    const sortingArr = ['Label', 'Cost', 'Sales', 'Mailed', 'Responses', 'Res_Rate', 'HO_Sales', 'HO_Count', 'HO_Resp', 'HO_Res_Rate',]
    const tableData = []
    const tableLine = []
    const newTotals = []

    for(let i = 0; i < data.length; i++) {
      let arr = []
      let adjRes = {}
      let adjResRate = {}
      let avgTicketObj = {}
      let roasObj = {}
      let iroasObj = {}
      for(const property in data[i]) {
        const dataObj = {}
        dataObj['value'] = data[i][property]
        dataObj['name'] = property
        arr.push(dataObj)
      }

      arr.sort((a,b) => {
        return sortingArr.indexOf(a['name']) - sortingArr.indexOf(b['name'])
      })

      let newArr = arr.filter(item => sortingArr.includes(item.name))
      adjRes['value'] = arr.filter(({name}) => name === 'Res_Rate')[0].value > arr.filter(({name}) => name === 'HO_Res_Rate')[0].value ? arr.filter(({name}) => name === 'Mailed')[0].value * (arr.filter(({name}) => name === 'Res_Rate')[0].value - arr.filter(({name}) => name === 'HO_Res_Rate')[0].value) : 0
      adjRes['name'] = 'Inc Response'
      adjResRate['value'] = arr.filter(({name}) => name === 'Res_Rate')[0].value > arr.filter(({name}) => name === 'HO_Res_Rate')[0].value ? arr.filter(({name}) => name === 'Res_Rate')[0].value - arr.filter(({name}) => name === 'HO_Res_Rate')[0].value : 0
      adjResRate['name'] = 'ADJ_Res_Rate'
      avgTicketObj['value'] = arr.filter(item => item.name === 'Responses')[0].value > 0 ? arr.filter(item => item.name === 'Sales')[0].value / arr.filter(item => item.name === 'Responses')[0].value : 0
      avgTicketObj['name'] = 'Avg Ticket'
      roasObj['value'] = arr.filter(({name}) => name === 'Cost')[0].value > 0 ? arr.filter(({name}) => name === 'Sales')[0].value / arr.filter(({name}) => name === 'Cost')[0].value : 0
      roasObj['name'] = 'ROAS'
      iroasObj['value'] = arr.filter(({name}) => name === 'Cost')[0].value > 0 && arr.filter(({name}) => name === 'ADJ_Res_Rate')[0].value > 0 ? (arr.filter(({name}) => name === 'Sales')[0].value / (arr.filter(({name}) => name === 'Res_Rate')[0].value ) * (arr.filter(({name}) => name === 'ADJ_Res_Rate')[0].value)) / arr.filter(({name}) => name === 'Cost')[0].value : 0
      iroasObj['name'] = 'iROAS'      
      if(this.props.roasFlag === 1) newArr.push(adjRes, adjResRate, avgTicketObj, roasObj, iroasObj)
      else newArr.push(adjRes, adjResRate, avgTicketObj)
      
      for(let j = 0; j < newArr.length; j++) {
        if(newArr[j].name === 'Label') {
          newArr[j] = newArr[j]
        } else if(newArr[j].name.includes('_Rate')) {
          newArr[j].value = (newArr[j].value * 100).toFixed(2) + '%'
        } else if (newArr[j].name.includes('ROAS')) {
          newArr[j].value = '$' + (newArr[j].value).toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})
        } else if(newArr[j].name.includes('Cost') || newArr[j].name.includes('Sale')) {
          newArr[j].value = '$' + Math.round(newArr[j].value).toLocaleString('en')
        } else {
          newArr[j].value = Math.round(newArr[j].value).toLocaleString('en')
        }

        delete newArr[j].name
      }
      tableLine.push(newArr)      
    }

    if(data.length > 1) {
      const totalArr = [data.map(item => item).reduce((a,b) => {
        for(const prop in b) {
          if(a.hasOwnProperty(prop) && prop !== 'Label') a[prop] += b[prop]
          else a[prop] = b[prop]
        }
        return a
      }, {})]


      for(const property in totalArr[0]) {
        const dataObj = {}
        dataObj['value'] = totalArr[0][property]
        dataObj['name'] = property
        newTotals.push(dataObj)
      }      

      let totalAdjRes = {}
      let totalAdjResRate = {}
      let totalAvgTicket = {}
      let totalRoas = {}
      let totalIRoas = {}

      let totResRate = newTotals.filter(({name}) => name === 'Responses')[0].value / newTotals.filter(({name}) => name === 'Mailed')[0].value
      let totIncResRate = newTotals.filter(({name}) => name === 'Responses')[0].value / newTotals.filter(({name}) => name === 'Mailed')[0].value - newTotals.filter(({name}) => name === 'HO_Resp')[0].value / newTotals.filter(({name}) => name === 'HO_Count')[0].value
      
      totalAdjRes['value'] = totIncResRate > 0 ? Math.round(newTotals.filter(({name}) => name == 'Mailed')[0].value * totIncResRate) : 0
      totalAdjRes['name'] = 'Inc Response'
      totalAdjResRate['value'] = totIncResRate > 0 ? totIncResRate : 0
      totalAdjResRate['name'] = 'ADJ_Res_Rate'
      totalAvgTicket['value'] = newTotals.filter(item => item.name === 'Responses')[0].value > 0 ? newTotals.filter(item => item.name === 'Sales')[0].value / newTotals.filter(item => item.name === 'Responses')[0].value : 0
      totalAvgTicket['name'] = 'Avg Ticket'
      totalRoas['value'] = newTotals.filter(({name}) => name === 'Cost')[0].value > 0 ? newTotals.filter(({name}) => name === 'Sales')[0].value / newTotals.filter(({name}) => name === 'Cost')[0].value : 0
      totalRoas['name'] = 'ROAS'      
      totalIRoas['value'] = newTotals.filter(({name}) => name === 'Cost')[0].value > 0 && totIncResRate > 0 ? (newTotals.filter(({name}) => name === 'Sales')[0].value / totResRate * totIncResRate) / newTotals.filter(({name}) => name === 'Cost')[0].value : 0
      totalIRoas['name'] = 'iROAS'      

      let sortedTotals = newTotals.sort((a,b) => {
        return sortingArr.indexOf(a['name']) - sortingArr.indexOf(b['name'])
      }).filter(item => sortingArr.includes(item.name))

      if(this.props.roasFlag === 1) sortedTotals.push(totalAdjRes, totalAdjResRate, totalAvgTicket, totalRoas, totalIRoas)
      else sortedTotals.push(totalAdjRes, totalAdjResRate, totalAvgTicket)

      for(let i = 0; i < sortedTotals.length; i++) {        
        if(sortedTotals[i].name === 'Label') {
          sortedTotals[i].value = 'Totals'
        } else if (sortedTotals[i].name === 'Res_Rate') {
          sortedTotals[i].value = (parseInt(sortedTotals.filter(item => item.name === 'Responses')[0].value.split(',').join('')) / parseInt(sortedTotals.filter(item => item.name === 'Mailed')[0].value.split(',').join('')) * 100).toFixed(2) + '%'
        } else if (sortedTotals[i].name === 'HO_Res_Rate') {
          sortedTotals[i].value = (parseInt(sortedTotals.filter(item => item.name === 'HO_Resp')[0].value.split(',').join('')) / parseInt(sortedTotals.filter(item => item.name === 'HO_Count')[0].value.split(',').join('')) * 100).toFixed(2) + '%'
        } else if (sortedTotals[i].name === 'ADJ_Res_Rate') {
          sortedTotals[i].value = (parseFloat(sortedTotals.filter(item => item.name === 'Res_Rate')[0].value) - parseFloat(sortedTotals.filter(item => item.name === 'HO_Res_Rate')[0].value)).toFixed(2) + '%'
        } else if (sortedTotals[i].name.includes('ROAS')) {
          sortedTotals[i].value = '$' + sortedTotals[i].value.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})
        } else if (sortedTotals[i].name.includes('Sales') || sortedTotals[i].name.includes('Cost')) {
          sortedTotals[i].value = '$' + Math.round(sortedTotals[i].value).toLocaleString('en')
        } else {
          sortedTotals[i].value = Math.round(sortedTotals[i].value).toLocaleString('en')
        }
      }

      for(let i = 0; i < sortedTotals.length; i++) {
        delete sortedTotals[i].name
      }

      tableLine.push(sortedTotals)
    }

    for(let i = 0; i < tableLine.length; i++) {
      tableData.push(tableLine[i])
    }

    return tableData
  }

  render() {
    const { classes } = this.props
    const custProsData = this.props.selectedData ? this.getTable(this.props.selectedData.Data_CustPros) : [[]]
    const categoryData = this.props.selectedData ? this.props.pageName === 'BOOMERANG' ? this.getTable(this.props.selectedData.Data_Category) : this.getTable(this.props.selectedData.Data_TrigType) : [[]]
    // const listData = this.props.pageName === 'BOOMERANG' ? this.getTable(this.props.selectedData.Data_ListType) : [[]]
    const locationsData = this.props.selectedData ? this.props.selectedType === 'market' ? this.getTable(this.props.selectedData.Data_Location) : [[]] : [[]]
    const weeklyData = this.props.selectedData ? this.props.corpID === 256 ? this.getTable(this.props.selectedData.Data_Weekly) : [[]] : [[]]

    const multiDataSet = [
      {
        columns: [
          {title: `${this.props.pageName} ROI SUMMARY`, width: {wpx: 500}, style: {font: {sz: '20', bold: true}}},
        ],
        data: [
          [
            {value: `SHOWING: completed mailings between ${this.props.startDate} and ${this.props.endDate}`},
          ],
          [
            {value: `${this.props.selectedType.toUpperCase()} SELECTED: ${this.props.markets}`}
          ]
        ]
      },
      {
        ySteps: 2,
        columns: [
          {title: 'Spend', width: {wpx: 200}, style: {font: {sz: '15', bold: true}}},
          {title: 'Responses', width: {wpx: 200}, style: {font: {sz: '15', bold: true}}},
          {title: 'Sales', width: {wpx: 200}, style: {font: {sz: '15', bold: true}}},
          {title: 'ROAS', width: {wpx: 200}, style: {font: {sz: '15', bold: true}}},
        ],
        data: [
          [
            this.props.selectedData ? {value: '$' + Math.round(this.props.selectedData.Data_Overall[0].Cost).toLocaleString('en'), style: {font: {sz: '20', bold: true}}} : {},
            this.props.selectedData ? {value: '$' + Math.round(this.props.selectedData.Data_Overall[0].Responses).toLocaleString('en'), style: {font: {sz: '20', bold: true}}} : {},
            this.props.selectedData ? {value: '$' + Math.round(this.props.selectedData.Data_Overall[0].Sales).toLocaleString('en'), style: {font: {sz: '20', bold: true}}} : {},
            this.props.selectedData ? {value: '$' + (this.props.selectedData.Data_Overall[0].Sales / this.props.selectedData.Data_Overall[0].Cost).toFixed(2), style: {font: {sz: '20', bold: true}}} : {},
          ]
        ],
      },
      {
        ySteps: 1,
        columns: [
          {title: 'Mailed', width: {wpx: 200}},
          {title: 'Incremental Responses', width: {wpx: 200}},
          {title: 'Incremental Sales', width: {wpx: 200}},
          {title: 'Incremental ROAS', width: {wpx: 200}},
        ],
        data: [
          [
            this.props.selectedData ? {value: Math.round(this.props.selectedData.Data_Overall[0].Mailed).toLocaleString('en'), style: {font: {sz: '20', bold: true}}} : {},
            this.props.selectedData ? {value: Math.round(this.props.selectedData.Data_Overall[0].Mailed * ((this.props.selectedData.Data_Overall[0].Responses / this.props.selectedData.Data_Overall[0].Mailed) - (this.props.selectedData.Data_Overall[0].HO_Resp / this.props.selectedData.Data_Overall[0].HO_Count))).toLocaleString('en'), style: {font: {sz: '20', bold: true}}} : {},
            this.props.selectedData ? {value: '$' + Math.round(this.props.selectedData.Data_Overall[0].ADJ_Sales).toLocaleString('en'), style: {font: {sz: '20', bold: true}}} : {},
            this.props.selectedData ? {value: '$' + (this.props.selectedData.Data_Overall[0].ADJ_Sales / this.props.selectedData.Data_Overall[0].Cost).toFixed(2) + '%', style: {font: {sz: '20', bold: true}}} : {},
          ]
        ]
      },
      {
        ySteps: 2,
        columns: [
          {title: 'CUSTOMERS VS PROSPECTS', width: {wpx: 350}, style: {font: {sz: '15', bold: true}}},
        ],
        data: [[]]
      },
      {
        columns: [
          {title: 'Type', width: {wpx: 150}},
          {title: 'Spend', width: {wpx: 150}},
          {title: 'Sales', width: {wpx: 150}},
          {title: 'Mailed', width: {wpx: 150}},
          {title: 'Responses', width: {wpx: 150}},
          {title: 'Response Rate', width: {wpx: 150}},
          {title: 'Holdout Sales', width: {wpx: 150}},
          {title: 'Holdout Count', width: {wpx: 150}},
          {title: 'Holdout Responses', width: {wpx: 150}},
          {title: 'Holdout Response Rate', width: {wpx: 150}},
          {title: 'Inc Response', width: {wpx: 150}},
          {title: 'Inc Response Rate', width: {wpx: 150}},
          {title: 'Average Ticket', width: {wpx: 150}},
          this.props.roasFlag === 1 ? {title: 'ROAS', width: {wpx: 150}} : {title: '', width: {wpx: 0}},
          this.props.roasFlag === 1 ? {title: 'Inc ROAS', width: {wpx: 150}} : {title: '', width: {wpx: 0}},
        ],
        data: custProsData
      },
      {
        ySteps: 2,
        columns: [
          {title: this.props.pageName === 'BOOMERANG' ? 'PRODUCT CATEGORY' : 'TRIGGER TYPES', width: {wpx: 350}, style: {font: {sz: '15', bold: true}}},
        ],
        data: [[]]
      },
      {
        columns: [
          {title: 'Type', width: {wpx: 150}},
          {title: 'Spend', width: {wpx: 150}},
          {title: 'Sales', width: {wpx: 150}},
          {title: 'Mailed', width: {wpx: 150}},
          {title: 'Responses', width: {wpx: 150}},
          {title: 'Response Rate', width: {wpx: 150}},
          {title: 'Holdout Sales', width: {wpx: 150}},
          {title: 'Holdout Count', width: {wpx: 150}},
          {title: 'Holdout Responses', width: {wpx: 150}},
          {title: 'Holdout Response Rate', width: {wpx: 150}},
          {title: 'Inc Response', width: {wpx: 150}},
          {title: 'Inc Response Rate', width: {wpx: 150}},
          {title: 'Average Ticket', width: {wpx: 150}},
          this.props.roasFlag === 1 ? {title: 'ROAS', width: {wpx: 150}} : {title: '', width: {wpx: 0}},
          this.props.roasFlag === 1 ? {title: 'Inc ROAS', width: {wpx: 150}} : {title: '', width: {wpx: 0}},
        ],
        data: categoryData
      },
      this.props.pageName === 'BOOMERANG' ?
        {
          ySteps: 2,
          columns: [
            {title: 'LIST TYPE', width: {wpx: 350}, style: {font: {sz: '15', bold: true}}},
          ],
          data: [[]]
        }
      :
        {
          columns: [],
          data:[[]]
        },
      this.props.pageName === 'BOOMERANG' ?
        {
          columns: [
            {title: 'Type', width: {wpx: 150}},
            {title: 'Spend', width: {wpx: 150}},
            {title: 'Sales', width: {wpx: 150}},
            {title: 'Mailed', width: {wpx: 150}},
            {title: 'Responses', width: {wpx: 150}},
            {title: 'Response Rate', width: {wpx: 150}},
            {title: 'Holdout Sales', width: {wpx: 150}},
            {title: 'Holdout Count', width: {wpx: 150}},
            {title: 'Holdout Responses', width: {wpx: 150}},
            {title: 'Holdout Response Rate', width: {wpx: 150}},
            {title: 'Inc Response', width: {wpx: 150}},
            {title: 'Inc Response Rate', width: {wpx: 150}},
            {title: 'Average Ticket', width: {wpx: 150}},
            this.props.roasFlag === 1 ? {title: 'ROAS', width: {wpx: 150}} : {title: '', width: {wpx: 0}},
            this.props.roasFlag === 1 ? {title: 'Inc ROAS', width: {wpx: 150}} : {title: '', width: {wpx: 0}},
          ],
          data: this.props.selectedData ? this.getTable(this.props.selectedData.Data_ListType) : [[]]
        }
      :
        {
          columns: [],
          data: [[]]
        },
      this.props.selectedType === 'market' ?
        {
          ySteps: 2,
          columns: [
            {title: 'LOCATIONS', width: {wpx: 350}, style: {font: {sz: '15', bold: true}}},
          ],
          data: [[]]
        }
      :
        {
          columns: [],
          data: [[]]
        },
      this.props.selectedType === 'market' ?
        {
          columns: [
            {title: 'Type', width: {wpx: 150}},
            {title: 'Spend', width: {wpx: 150}},
            {title: 'Sales', width: {wpx: 150}},
            {title: 'Mailed', width: {wpx: 150}},
            {title: 'Responses', width: {wpx: 150}},
            {title: 'Response Rate', width: {wpx: 150}},
            {title: 'Holdout Sales', width: {wpx: 150}},
            {title: 'Holdout Count', width: {wpx: 150}},
            {title: 'Holdout Responses', width: {wpx: 150}},
            {title: 'Holdout Response Rate', width: {wpx: 150}},
            {title: 'Inc Response', width: {wpx: 150}},
            {title: 'Inc Response Rate', width: {wpx: 150}},
            {title: 'Average Ticket', width: {wpx: 150}},
            this.props.roasFlag === 1 ? {title: 'ROAS', width: {wpx: 150}} : {title: '', width: {wpx: 0}},
            this.props.roasFlag === 1 ? {title: 'Inc ROAS', width: {wpx: 150}} : {title: '', width: {wpx: 0}},
          ],
          data: locationsData
        }
      :
        {
          columns: [],
          data:[[]]
        },
        this.props.corpID === 256 ?
          {
            ySteps: 2,
            columns: [
              {title: 'WEEKLY ROI', width: {wpx: 350}, style: {font: {sz: '15', bold: true}}},
            ],
            data: [[]]
          }
        :
          {
            columns: [],
            data: [[]]
          },
        this.props.corpID === 256 ?
          {
            columns: [
              {title: 'Week', width: {wpx: 150}},
              {title: 'Spend', width: {wpx: 150}},
              {title: 'Sales', width: {wpx: 150}},
              {title: 'Mailed', width: {wpx: 150}},
              {title: 'Responses', width: {wpx: 150}},
              {title: 'Response Rate', width: {wpx: 150}},
              {title: 'Holdout Sales', width: {wpx: 150}},
              {title: 'Holdout Count', width: {wpx: 150}},
              {title: 'Holdout Responses', width: {wpx: 150}},
              {title: 'Holdout Response Rate', width: {wpx: 150}},
              {title: 'Inc Response', width: {wpx: 150}},
              {title: 'Inc Response Rate', width: {wpx: 150}},
              {title: 'Average Ticket', width: {wpx: 150}},
            ],
            data: weeklyData
          }
        :
          {
            columns: [],
            data:[[]]
          },
    ]

    return (
      <div>
        {/* {console.log(this.state.markets)}
        {console.log(this.props.selectedData)} */}
        <ExcelFile
          filename={`${this.props.pageName}_ROI(${moment().format('YYYY-MM-DD')})`}
          element={
            <Button
              className={classes.button}
            >
              <div style={{display: 'inline-flex', alignItems: 'center'}}>
                <Typography variant="caption" style={{marginTop: '3px', fontSize: '11px'}}>EXPORT {this.props.pageName} ROI</Typography>
                <FaFileDownload className={classes.icon} />
              </div>
            </Button>
          }
        >
          <ExcelSheet dataSet={multiDataSet} name={this.props.pageName} />
        </ExcelFile>
      </div>
    )
  }
}

export default withStyles(styles)(RoiExcel)
