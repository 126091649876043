import React, { Component } from 'react'
import Chart from 'react-apexcharts'

class RadialChart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      series: props.data,
      options: {
        chart: {
          type: 'radialBar',
          offsetY: -20,
          toolbar: {
            tools: {
              download: false,
            }
          }
        },
        colors: props.colors,
        legend: {
          show: true,
          position: 'bottom',
          labels: {
            colors: props.darkMode ? '#fff' : ''
          },
          offsetY: -35,
          fontSize: window.innerWidth <= 1440 ? '10px' : '11px',
          markers: {
            width: 7,
            height: 7,
          },
          onItemClick: {
            toggleDataSeries: false,
          },
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: props.background,
              strokeWidth: 10
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                offsetY: -2,
                fontSize: '20px',
                fontFamily: '-apple-system, system-ui, BlinkMacSystemFont',
                color: props.darkMode ? '#fff' : ''
              },
              style: {
                fontWeight: 'bold',
              },
            }
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            shadeIntensity: 0.4,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 53, 91]
          },
        },
        labels: props.labels
      }
    }
  }

  render() {
    return(
      <div>
        <Chart options={this.state.options} series={this.state.series} type="radialBar" height={this.props.height}/>
      </div>
    )
  }
}

export default RadialChart
