import React, { Component } from 'react'
import Chart from 'react-apexcharts'

class Heatmap extends Component {
  constructor(props) {
    super(props);
    console.log(props)
    this.state = {

      series: props.data,
      // series: [{
      //   name: 'Metric1',
      //   data: [15, 38, 54, 42, 55, 75, 62, 77, 85, 85, 95, 90 ]
      // },
      // {
      //   name: 'Metric2',
      //   data: [15, 20, 10, 15, 35, 7, 10, 15, 20, 35, 35, 25 ]
      // },
      // {
      //   name: 'Metric3',
      //   data: [3, 3, 1, 4, 4,]
      // },
      // ],
      options: {
        chart: {
          height: 350,
          type: 'heatmap',
        },
        xaxis:{
          categories: props.labels
        },
        // colors: ['#b30000'],
        plotOptions: {
          heatmap: {
            // shadeIntensity: 0.5,
            // distributed: true,
            colorScale: {
              inverse: true,
              ranges: [
                {
                  from: 0,
                  to: 60,
                  name: 'low',
                  color: '#0074bf'
                },
                // {
                //   from : 41,
                //   to: 60,
                //   name: 'mid',
                //   color: '#969696'
                // },
                // {
                //   from : 61,
                //   to: 95,
                //   name: 'high',
                //   color: '#b30000'
                // },
                {
                  from : 61,
                  to: 100,
                  // name: 'high',
                  color: '#940000'
                },
                {
                  from : 76,
                  to: 100,
                  name: 'high',
                  color: '#b30000'
                },
              ]
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        // colors: ["#008FFB"],
        // title: {
        //   text: 'HeatMap Chart (Single color)'
        // },
      },


    };
  }

  render() {
    return(
      <div>
        <Chart series={this.state.series} options={this.state.options} type="heatmap" height={750}/>
      </div>
    )
  }
}

export default Heatmap
