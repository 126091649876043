import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { FormControl, Select, MenuItem, CircularProgress } from '@material-ui/core'
import BarChart2 from '../charts/BarChart2'
import Cookies from 'js-cookie'
import AskReport from '../AskReport'

const styles = theme => ({
  root: {
    margin: '1em',
    display: 'block'
  },
  description: {
    textAlign: 'center',
    fontSize: '18px',
  },
  loader: {
    marginLeft: '45%',
    marginTop: '30%',
  },
  select: {
    width: '150px'
  },
  unavailable: {
    height: '400px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})

class DemoModule extends Component {
  _isMounted = false

  constructor() {
    super()
    this.state = {
      isLoading: true,
      metricTypeOptions: [],
      selectedMetric: 'Age Range',
      selectedData: [],
      defaultData: [],
      demoData: []
    }
  }

  componentDidMount() {
    this._isMounted = true

    fetch('https://api.dev.lsdirect.com/smartdash/V1/index.cfm//demoByLoc', {
      method: 'GET',
      headers: {
        'token': Cookies.get('token')
      },
    })
    .then(response => response.json())
    .then(data => {
      if(this._isMounted && data) {
        this.setState({
          isLoading: false,
          demoData: data,
          selectedData: data[0].Data
        })
      } else {
        this.setState({
          demoData: [],
          isLoading: false,
          selectedData: [],
        })
      }
    })
    .catch(error => {
      console.log(error)
    })
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  renderSelectOptions = () => {
    return (
      this.state.demoData.map((item, i) => {
        if(item.MetricType === 'Age Range' || item.MetricType === 'Ethnicity' || item.MetricType === 'Household Income') {
          return (<MenuItem key={item.Key} value={item.MetricType}>{item.MetricType}</MenuItem>)
        } else {
          return ''
        }
      })
    )
  }

  handleChange = (e) => {
    for( let i = 0; i < this.state.demoData.length; i++ ) {
      if(e.target.value === this.state.demoData[i].MetricType) {
        this.setState({
          selectedMetric: e.target.value,
          selectedData: this.state.demoData[i].Data
        })
      }
    }
  }

  render() {
    const { classes } = this.props
    const customersPct = []
    const customersBasePct = []
    const metricValue = []
    const series = []
    const customersLY = []
    const boomerangPct = []
    const dbO = JSON.parse(localStorage.getItem("currentUser")) ? JSON.parse(localStorage.getItem("currentUser"))[0].db_owner : ""
    const currentUserLite = JSON.parse(localStorage.getItem('currentUser')) && JSON.parse(localStorage.getItem('currentUser'))[0].sd_lite === 1 ? true : false

    for (let i = 0; i < this.state.selectedData.length; i++) {
      customersPct.push(this.state.selectedData.map(item => item.Customers)[i] / this.state.selectedData.map(item => item.Customers).reduce((a, b) => a + b, 0) * 100)
      customersBasePct.push(this.state.selectedData.map(item => item.Customers_Baseline)[i] / this.state.selectedData.map(item => item.Customers_Baseline).reduce((a,b) => a + b, 0) * 100)
      customersLY.push(this.state.selectedData.map(item => item.Customers_LY)[i] / this.state.selectedData.map(item => item.Customers_LY).reduce((a, b) => a + b, 0) * 100)
      boomerangPct.push(this.state.selectedData.map(item => item.Boomerang)[i] / this.state.selectedData.map(item => item.Boomerang).reduce((a, b) => a + b, 0) * 100)
      metricValue.push(this.state.selectedData[i].MetricValue)
    }

    series.push(
      {
        name: '% of Customers(Current)',
        type: 'column',
        data: customersPct
      },
      {
        name: '% of Customers Baseline',
        type: 'column',
        data: customersBasePct
      },
      {
        name: '% of Boomerang Browsers',
        type: 'column',
        data: boomerangPct
      }
    )

    return (
      <div className={classes.root}>
        { this.state.isLoading ? <div className={classes.loader}><CircularProgress /></div> :
        !currentUserLite && dbO !== "ESCANABA" ? 
          <Fragment>
            <BarChart2
              data={series}
              height={250}
              categories={metricValue}
              colors={this.props.darkMode ? ['#00d2e6', '#7483ab', '#d97e82'] : ['#00c9db', '#2f5a7a', '#ff2b34']}
              fixed={true}
              darkMode={this.props.darkMode}
            />
            <FormControl style={{marginTop: '-5px'}}>
              <Select
                value={this.state.selectedMetric}
                onChange={this.handleChange}
                className={classes.select}
              >
                {this.renderSelectOptions()}
              </Select>
            </FormControl>
          </Fragment>
          :
          <div className={classes.unavailable}>
          <AskReport home={true}/>
          </div>
        }
      </div>
    );
  }
}

export default withStyles(styles)(DemoModule)
