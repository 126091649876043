import React, { Component, Fragment } from 'react'
import clsx from 'clsx'
import {
  withStyles,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  ExpansionPanelActions,
  Tab,
  Tabs,
  Box,
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Divider,
  Fade,
  Button,
  Grow,
  IconButton,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import CloseIcon from '@material-ui/icons/Close'
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import TrendingDownIcon from '@material-ui/icons/TrendingDown'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import EventAvailableIcon from '@material-ui/icons/EventAvailable'
import AddIcon from '@material-ui/icons/Add'
import DonutChart from '../charts/DonutChart'
import RadialChart from '../charts/RadialChart'

const styles = theme => ({
  accordion: {
    // background: `linear-gradient(to right, #039be5, #dcedc8)`,
    background: `linear-gradient(to right, #269fc7, #85cacc)`,
  },
  // details: {
  //   height: 250,
  // },
  expandIcon: {
    // opacity: 0.3,
    // color: 'rgba(220, 237, 200, 0.5)'
  },
  tabRoot: {
    flexGrow: 1,
    // backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 250,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    // marginRight: '10px'
    // borderRight: '1px solid'
  },
  tab: {
    width: '100%',
    marginTop: '-30px'
  },
  indicator: {
    backgroundColor: '#fff',
    opacity: 0.7,
  },
  alerts: {
    borderRadius: '4px',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    width: '90%',
    height: '75px',
    marginTop: '10px',
    // marginRight: '15px',
    overflowY: 'auto',
  },
  alertAvatar: {
    width: '35px',
    height: '35px',
  },
  alertMessage: {
    margin: '10px 10px 0px 10px',
    width: '100%',
    // width: '80%',
    display: 'inline-flex',
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
      margin: '2px 10px 0px 10px',
    },
  },
  bulletin: {
    height: 225,
    width: '100%',
    // width: 400,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    border: '2px solid rgba(235, 192, 84, 0.8)',
    overflowY: 'auto',
    [theme.breakpoints.down('lg')] : {
      // width: 300,
    }
  },
  socialMedia: {
    display: 'flex',
    height: 225,
    width: 400,
    overflowY: 'auto',
    border: '2px solid rgba(38, 76, 154, 0.7)',
    [theme.breakpoints.down('lg')] : {
      width: 300,
    }
  },
  moreInfoButton: {
    display: 'inline-flex',
    alignItems: 'center',
    marginTop: '7px',
    borderLeft: `4px solid ${theme.palette.divider}`
  },
  expandIcon: {
    '&:hover': {
      color: '#324d4c',
      backgroundColor: 'rgba(221, 245, 233, 0.5)'
    }
  }
})

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  }
}

const CustomButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 12,
    border: '0px',
    // backgroundColor: 'rgba(255, 255, 255, 0.4)',
    color: '#011689',
    height: '100%',
    '&:hover': {
      // backgroundColor: 'rgba(255, 255, 255, 0.1)',
      backgroundColor: 'transparent',
      color: '#0072ff',
      border: '0px',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none'
    },
    '&:focus': {
      boxShadow: 'none',
    }
  }
})(Button)

class WelcomeBoard extends Component {
  constructor(props) {
    super(props)
    console.log(props.alerts)
    this.state = {
      tab: 0,
      expanded: true,
    }
  }

  handleTabChange = (e, newTab) => {
    this.setState({
      tab: newTab
    })
  }

  handleExpand = (e) => {
    this.setState({
      expanded: !this.state.expanded
    })
  }

  render() {
    const { classes } = this.props
    return (
      <div>
        <ExpansionPanel
          className={classes.accordion}
          // defaultExpanded
          expanded={this.state.expanded}
        >
          <ExpansionPanelSummary>
            <div style={{display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
              <Typography variant="h5" style={{fontSize: '25px', fontWeight: 'bold', color: '#fff'}}>Hello {this.props.currentUser[0].fname + ' ' + this.props.currentUser[0].lname}!</Typography>
              <IconButton className={classes.expandIcon} onClick={this.handleExpand} disableRipple>
                {this.state.expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            // className={classes.details}
          >
            <div className={classes.tabRoot}>
              <Tabs
                id="alerts"
                orientation="vertical"
                variant="fullWidth"
                onChange={this.handleTabChange}
                classes={{indicator: classes.indicator}}
                className={classes.tabs}
                value={this.state.tab}
                // style={{marginTop: '15px'}}
              >
                <Tab label={<Typography variant="caption" style={{color: '#fff', fontWeight: 'bold', marginRight: '10px'}}>Monthly Trends</Typography>} {...a11yProps(0)} disableRipple />
                <Tab label={<Typography variant="caption" style={{color: '#fff', fontWeight: 'bold', marginRight: '10px'}}>Bulletin Board</Typography>} {...a11yProps(1)} disableRipple />
                {/* <Tab label='Monthly Trends' {...a11yProps(2)} disableRipple /> */}
              </Tabs>

              <TabPanel value={this.state.tab} index={0} className={classes.tab}>
                {this.props.alerts ?
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{width: '90%'}}>
                      {this.props.alerts.map(row => (
                        <Fade key={row.Key} in={true} timeout={2000}>
                          <div className={classes.alerts} style={row.Data[0].YOY_Index > 0 ? {border: '2px solid rgba(58, 115, 33, 0.5)'} : {border: '2px solid rgba(255, 60, 81, 0.5)'}}>
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                              <div className={classes.alertMessage}>
                                {row.Data[0].YOY_Index > 0 ? <TrendingUpIcon style={{color: '#448727'}}/> : <TrendingDownIcon style={{color: '#ff3c51'}}/>}
                                <Typography style={{color: '#6a6a6a', marginLeft: '10px', marginTop: '5px'}} variant="body2">
                                  The largest Year Over Year {row.Data[0].YOY_Index > 0 ? <span style={{color: '#448727'}}>increase</span> : <span style={{color: '#ff3c51'}}>decrease</span>} was for customers in the <span style={{color: '#a837bf'}}>{row.Data[0].MetricType} / {row.Data[0].MetricValue}</span> demographics by {row.Data[0].YOY_Index > 0 ? <span style={{color: '#448727'}}>{(row.Data[0].YOY_Index * 100).toFixed(2)}%</span> : <span style={{color: '#ff3c51'}}>{(row.Data[0].YOY_Index * 100).toFixed(2)}%</span>}
                                </Typography>
                              </div>
                            </div>
                          </div>
                        </Fade>
                      ))}
                    </div>
                    <Fade in={true} timeout={3000}>
                      <div style={{float: 'right'}}>
                        <div className={classes.moreInfoButton}>
                          <CustomButton
                            href={'/demographic-trends'}
                          >
                            <Typography variant="caption">MORE INFO</Typography>
                          </CustomButton>
                        </div>
                      </div>
                    </Fade>
                  </div>
                  :
                  'N/A'
                }
              </TabPanel>

              <TabPanel value={this.state.tab} index={1} className={classes.tab}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <Fade in={true} timeout={2000}>
                    <Card elevation={0} className={classes.bulletin}>
                      <CardHeader
                        title={<Typography variant="caption" style={{fontSize: '14px', color: '#6a6a6a'}}>BULLETIN BOARD</Typography>}
                        style={{backgroundColor: 'rgba(255, 255, 255, 0.3)'}}
                      />
                      <CardContent>
                        <Typography variant="caption" style={{fontWeight: 'bold', color: '#6a6a6a'}}>Welcome to the all-new SmartDash by LS Direct!</Typography><br/>
                        <Typography variant="caption" style={{color: '#6a6a6a'}}>Here you can view the campaign metrics most important to you and gain deeper insights into your customers.</Typography>

                        <List style={{color: '#6a6a6a', marginTop: '10px'}}>
                          <ListItem alignItems="flex-start">
                            <ListItemAvatar>
                              <Avatar style={{backgroundColor: 'transparent', color: '#2e9900'}}><EventAvailableIcon/></Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={<Typography variant="caption" style={{fontSize: '13px', fontWeight: 'bold', color: '#2e9900'}}>PREPARE FOR SUCCESS</Typography>}
                              secondary={
                                <Fragment>
                                  <Typography variant="caption" style={{color: '#6a6a6a'}}>2021 Planning Meetings start in November!</Typography>
                                </Fragment>
                              }
                            />
                          </ListItem>
                          <Divider style={{backgroundColor: '#6a6a6a'}} variant="inset" component="li" />
                        </List>
                      </CardContent>
                    </Card>
                  </Fade>
                  <div style={{marginLeft: '10px'}}>
                    <Fade in={true} timeout={3000}>
                      <Card elevation={0} className={classes.socialMedia}>
                        <iframe
                          src="https://www.linkedin.com/embed/feed/update/urn:li:share:6717127140527153152"
                          // height="383"
                          width="400"
                          frameBorder="0"
                          title="Embedded post"
                        >
                        </iframe>
                      </Card>
                    </Fade>
                  </div>
                </div>
              </TabPanel>
            </div>

          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    )
  }
}

export default withStyles(styles)(WelcomeBoard)