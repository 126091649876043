import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import Chart from 'react-apexcharts'

const styles = theme => ({
  label: {
    '&:hover': {
      color: 'blue'
    }
  }
})

class BarChartStacked extends Component {
  constructor(props) {
    super(props)

    this.state = {
      series: props.series,
      options: {
        chart: {
          type: 'bar',
          // height: 350,
          stacked: true,
          toolbar: {
            tools: {
              download: false,
            }
          },
          events: {
            click: this.handleClick,
            mouseMove: this.handleHover
          }
        },
        states: {
          active: {
            filter: {
              type: 'none'
            }
          }
        },
        colors: props.colors,
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        title: {
          text: props.title ? props.title.toUpperCase() : '',
          align: 'center',
          style: {
            fontSize: '17px',
            color: props.darkMode ? '#fff' : '',
          }
        },
        xaxis: {
          categories: props.labels,
          title: {
            text: props.xLabel,
            style: {
              color: props.darkMode ? '#fff' : '',
            },
          },
          labels: {
            formatter: function (val) {
              return val
            },
            style: {
              colors: props.darkMode ? '#fff' : ''
            }
          }
        },
        yaxis: {
          categories: props.labels,
          title: {
            text: props.yLabel
          },
          labels: {
            hideOverlappingLabels: true,
            style: {
              colors: props.darkMode ? '#fff' : '',
            }
          },
          tooltip: {
            enabled: true,
          }
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val.toLocaleString('en')
            }
          }
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: 'top',
          labels: {
            colors: props.darkMode ? '#fff' : ''
          },
          horizontalAlign: 'left',
          offsetX: 40,
          markers: {
              width: 12,
              height: 12,
              fillColors: props.colors,
              radius: 12,
          },
        }
      },
    };
  }

  // componentDidMount = () => {
  //   const labels = document.querySelectorAll('.apexcharts-text.apexcharts-yaxis-label')
  //
  //   labels.forEach(item => {
  //     item.addEventListener('mouseover', e => {
  //       console.log(e.target)
  //       // this.props.handleLocationClick(item, e)
  //
  //     })
  //   })
  // }

  // componentWillReceiveProps(nextProps) {
  //   if (this.props !== nextProps) {
  //     this.setState({
  //       series: nextProps.series,
  //       options: {
  //         yaxis: {
  //           categories: nextProps.labels
  //         },
  //         xaxis: {
  //           categories: nextProps.labels
  //         }
  //       }
  //     })
  //   }
  // }

  static getDerivedStateFromProps(nextProps, prevState) {
    return (nextProps.series !== prevState.series) ?
      {
        series: nextProps.series,
        options: {
          yaxis: {
            categories: nextProps.labels
          },
          xaxis: {
            categories: nextProps.labels
          }
        }
      } : null
  }

  handleClick = (event, chartContext, config) => {
    const labels = this.props.labels
    if(labels.includes(event.target.textContent)) {
      this.props.handleLocationClick(event.target.textContent)
    }
  }

  // handleHover = (event, config) => {
  //   const labels = this.props.labels
  //   const labelDiv = document.querySelectorAll('.apexcharts-text.apexcharts-yaxis-label')
  //   if(labels.includes(event.target.textContent)) {
  //     // console.log(labelDiv)
  //     // console.log(labelDiv.length)
  //     for(let i = 0; i < labelDiv.length; i++) {
  //       if(labelDiv[i].textContent === event.target.textContent) {
  //         // this.setState({
  //         //   options: {
  //         //     yaxis: {
  //         //       labels: {
  //         //         style: {
  //         //           colors: 'blue'
  //         //         }
  //         //       }
  //         //     }
  //         //   }
  //         // })
  //         // console.log(labelDiv[i])
  //         // console.log(labelDiv[i].style)
  //         // return labelDiv[i].style.color = 'blue'
  //       }
  //     }
  //
  //   } else {
  //     // this.setState({
  //     //   options: {
  //     //     yaxis: {
  //     //       labels: {
  //     //         style: {
  //     //           colors: '#373d3f'
  //     //         }
  //     //       }
  //     //     }
  //     //   }
  //     // })
  //   }
  // }

  render() {
    return(
      <div>
        <Chart options={this.state.options} series={this.state.series} type="bar" height={this.props.height}/>        
      </div>
    )
  }
}

export default BarChartStacked
