import React, { Component } from 'react'
import ReactExport from 'react-data-export'
import {
  withStyles,
  Typography,
  Button,
} from '@material-ui/core'
import { RiFileExcel2Fill } from 'react-icons/ri'
import { FaFileDownload } from 'react-icons/fa'
import moment from 'moment'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

const styles = theme => ({
  button: {
    color: '#0c7238',
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#17c261'
    }
  },
  icon: {
    fontSize: '17px',
    marginLeft: '5px'
  }
})

class DemoExcel extends Component {
  constructor(props) {
    super(props)
    console.log(props)
    this.state = {

    }
  }

  getTable = (categories, dataSeries, selectedData) => {
    const dataLength = dataSeries.length
    const demoDataArr = []

    for(let i = 0; i < categories.length; i++) {
      let arr = []
      for(let j = 0; j < dataLength; j++) {
        let arrObj = {}
        arrObj['value'] = dataSeries[j].data[i] + '%'
        arr.push(arrObj)
      }
      demoDataArr.push(arr)
    }

    for(let i = 0; i < demoDataArr.length; i++) {
      let catObj = {}
      catObj['value'] = categories[i]
      demoDataArr[i].unshift(catObj)
    }

    return(demoDataArr)
  }


  render() {
    const { classes } = this.props
    const demoData = this.props.selectedData.length > 0 ? this.getTable(this.props.categories, this.props.dataSeries, this.props.selectedData) : [[]]

    const multiDataSet = [
      {
        columns: [
          {title: 'DEMOGRAPHICS BY LOCATION', width: {wpx: 500}, style: {font: {sz: '20', bold: true}}},
        ],
        data: [
          [
            {value: `SHOWING METRICTYPE: ${this.props.metricType.toUpperCase()}`},
            {value: `DATES BETWEEN: ${this.props.minYearmo.format('MMM YYYY')} & ${this.props.maxYearmo.format('MMM YYYY')}`}
          ],
          [
            {value: `MARKETS SELECTED: ${this.props.marketSelected.join(', ')}`}
          ]
        ]
      },
      {
        ySteps: 1,
        columns: [
          {title: 'Metric Value', width: {wpx: 300}},
          {title: `% of Customers`, width: {wpx: 300}},
          {title: '% of Customers Basline (1yr Rolling)', width: {wpx: 300}},
          {title: '% of Boomerang Browsers', width: {wpx: 300}},
        ],
        data: demoData
      }
    ]

    return (
      <div>
        <ExcelFile
          filename={`DEMOGRAPHIC_BY_LOCATION(${this.props.metricType.toUpperCase()})_${moment().format('YYYY-MM-DD')}`}
          element={
            <Button
              className={classes.button}
            >
              <div style={{display: 'inline-flex', alignItems: 'center'}}>
                <Typography variant="caption" style={{marginTop: '3px', fontSize: '11px'}}>
                  EXPORT DEMOGRAPHICS
                </Typography>
                <FaFileDownload className={classes.icon} />
              </div>
            </Button>
          }
        >
          <ExcelSheet
            dataSet={multiDataSet}
            name={this.props.metricType.toUpperCase()}
          />
        </ExcelFile>
      </div>
    )
  }
}

export default withStyles(styles)(DemoExcel)
