import React, { Component } from 'react'
import Calendar from 'rc-calendar'
import DatePicker from 'rc-calendar/lib/Picker'
import {
  withStyles,
  Typography,
  Slider,
  Tooltip,
  IconButton,
} from '@material-ui/core'
import moment from 'moment'
import RestoreIcon from '@material-ui/icons/Restore'
import '../../assets/calendar.css'

const boxShadow = '0 3px 1px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.13), 0 0 0 1px rgba(0, 0, 0, 0.02)'

const CustomSlider = withStyles({
  root: {
    color: '#01157b',
    height: 2,
    padding: '15px 0'
  },
  thumb: {
    height: 12,
    width: 12,
    backgroundColor: '#fff',
    border: '1px solid rgba(1, 21, 123, 0.5)',
    boxShadow: boxShadow,
    '&:focus, &:hover, &$active': {
      boxShadow: '0 2px 1px rgba(0, 0, 0, 0.1), 0 4px 7px rgba(0, 0, 0, 0.3), 0 0 0 1px rgba(0, 0, 0, 0.02)',
    }
  },
  valueLabel: {
    textAlign: 'center',
    width: '50%',
    wordBreak: 'break-all'
  },
  track: {
    height: 2,
  },
  rail: {
    height: 2,
    opacity: 0.5,
    backgroundColor: '#bfbfbf',
  },
  mark: {
    backgroundColor: '#bfbfbf',
    height: 5,
    width: 1,
    marginTop: -3
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor',
  },
})(Slider)

class Picker extends Component {
  render() {
    const props = this.props
    const calendar = (
      <Calendar
        disabledDate={props.disabledDate}
        style={{width: 250, zIndex: 1000}}
      />
    )
    return (
      <DatePicker
        animation="slide-up"
        calendar={calendar}
        value={props.value}
        onChange={props.onChange}
        disabled={props.marketLoading}
      >
        {
          ({value}) => {
            return (
              <input
                placeholder=""
                style={{
                  width: 100,
                  height: 32,
                  overflow: 'break-word',
                  textAlign: 'center',
                  borderRadius: 4,
                  border: '1px solid #6b6b6b',
                  fontSize: '11px',
                  padding: '0px 3px 0px 3px',
                  backgroundColor: 'transparent'
                }}
                readOnly
                value={value && value.format('MM / DD / YYYY')}
              />
            )
          }
        }
      </DatePicker>
    )
  }
}

class DateRange extends Component {
  constructor(props) {
    super(props)
    this.state = {
      startDate: props.startDate,
      displayStartDate: props.startDate,
      endDate: props.endDate,
      displayEndDate: props.endDate,
      minDate: props.startDate.format('MM/DD/YYYY'),
      maxDate: props.endDate.format('MM/DD/YYYY'),
      sliderValues: [0, props.endDate.diff(props.startDate, 'days')],
      daysInRange: props.endDate.diff(props.startDate, 'days'),
      maxDateType: props.maxDate
    }
  }

  onChange = (fieldName, value) => {
    if(fieldName === 'startDate') {
      if(this.state.sliderValues[1] === this.state.endDate.diff(this.state.startDate, 'days')) {
        if(this.state.endDate.diff(value, 'days') < this.state.endDate.diff(this.state.startDate, 'days')) {
          const min = 0
          const max = this.state.endDate.diff(value, 'days')
          const newDayRange = this.state.endDate.diff(value, 'days')
          this.setState({
            sliderValues: [min, max],
            daysInRange: newDayRange,
            startDate: value,
            displayStartDate: value,
          })
        } else {
          const min = 0
          const max = this.state.endDate.diff(value, 'days')
          const newDayRange = this.state.endDate.diff(value, 'days')
          this.setState({
            sliderValues: [min, max],
            daysInRange: newDayRange,
            startDate: value,
            displayStartDate: value,
          })
        }
      } else {
        const min = 0
        const max = this.state.sliderValues[1] + this.state.startDate.diff(value, 'days')
        const newDayRange = this.state.endDate.diff(value, 'days')
        this.setState({
          sliderValues: [min, max],
          daysInRange: newDayRange,
          startDate: value,
          displayStartDate: value,
        })
      }
    } else {
      if(this.state.endDate.diff(this.state.startDate, 'days') > value.diff(this.state.startDate, 'days')) {
        if(this.state.sliderValues[0] === 0) {
          const min = 0
          const max = value.diff(this.state.startDate, 'days')
          const newDayRange = value.diff(this.state.startDate, 'days')
          this.setState({
            sliderValues: [min, max],
            daysInRange: newDayRange,
            endDate: value,
            displayEndDate: value,
            maxDate: value.format('MM/DD/YYYY'),
          })
        } else {
          const min = this.state.sliderValues[0]
          const max = value.diff(this.state.startDate, 'days')
          const newDayRange = value.diff(this.state.startDate, 'days')
          this.setState({
            sliderValues: [min, max],
            daysInRange: newDayRange,
            endDate: value,
            displayEndDate: value,
            maxDate: value.format('MM/DD/YYYY'),
          })
        }
      } else {
        if(this.state.sliderValues[0] === 0) {
          const min = 0
          const max = value.diff(this.state.startDate, 'days')
          const newDayRange = value.diff(this.state.startDate, 'days')
          this.setState({
            sliderValues: [min, max],
            daysInRange: newDayRange,
            endDate: value,
            displayEndDate: value,
            maxDate: value.format('MM/DD/YYYY')
          })
        } else {
          const min = this.state.sliderValues[0]
          const max = value.diff(this.state.startDate, 'days')
          const newDayRange = value.diff(this.state.startDate, 'days')
          this.setState({
            sliderValues: [min, max],
            daysInRange: newDayRange,
            endDate: value,
            displayEndDate: value,
            maxDate: value.format('MM/DD/YYYY')
          })
        }
      }
    }
    this.props.handleDateRange(value, fieldName, this.state.sliderValues)
  }

  handleSliderValuesChange = (e, value) => {
    this.setState({
      sliderValues: value
    })
  }

  handleSliderValuesCommit = (e, value) => {
    const maxDate = moment(this.state.maxDate)
    const daysDiff = this.state.daysInRange - value[0]
    const newDatesArr = []

    for(let i = 0; i < value.length; i++) {
      let maxDate = moment(this.state.maxDate)
      let daysDiff = this.state.daysInRange - value[i]

      newDatesArr.push(maxDate.subtract(daysDiff, 'days').format('YYYY-MM-DD'))
    }

    this.setState({
      displayStartDate: moment(newDatesArr[0]),
      displayEndDate: moment(newDatesArr[1]),
    })

    this.props.handleSliderDateChange(newDatesArr)
  }

  getSliderValueText = (value, i) => {
    let maxDate = moment(this.state.maxDate)
    let dateDiff = this.state.daysInRange - value

    return(
      <div style={{textAlign: 'center', padding: '10px 0px 2px 0px', display: 'block'}}>
        <Typography display="block" variant="caption" style={{fontSize: '9.5px', fontWeight: 600}}>
          {maxDate.subtract(dateDiff, 'days').format("MM/DD 'YY")}
        </Typography>
      </div>
    )
  }

  onClear = () => {
    const startDate = this.state.maxDateType === 'event' ? moment(JSON.parse(localStorage.getItem('currentUser'))[0][`max_date_${this.state.maxDateType}`]).startOf('year').subtract(1, 'years') : JSON.parse(localStorage.getItem('currentUser'))[0].compIDs === '146' ? moment('01-01-2019') : JSON.parse(localStorage.getItem('currentUser'))[0].db_owner === 'DEMO-APPAREL' ? moment().startOf('year').subtract(1, 'years') : moment().diff(moment().startOf('year'), 'months') > 3 ? moment().startOf('year') : moment().startOf('year').subtract(1, 'years')
    const endDate = moment(JSON.parse(localStorage.getItem('currentUser'))[0][`max_date_${this.state.maxDateType}`])

    this.setState({
      displayStartDate: startDate,
      displayEndDate: endDate,
      minDate: startDate.format('MM/DD/YYYY'),
      maxDate: endDate.format('MM/DD/YYYY'),
      sliderValues: [0, endDate.diff(startDate, 'days')],
      daysInRange: endDate.diff(startDate, 'days')
    })
    this.props.handleDateClear()

    console.log(endDate.format('MM-DD-YYYY'))
  }

  disabledStartDate = (value) => {
    let corpID = JSON.parse(localStorage.getItem('currentUser'))[0].corporateID

    if(corpID === 7 && this.props.allowBeforeJan === false && JSON.parse(localStorage.getItem('currentUser'))[0].db_owner !== 'KHOOK') {
      return (
        value.year() > moment().year() || value.year() < 2020 || value.year() === moment().year() && value > moment() || value.year() === moment().year() && value > this.state.endDate || value > this.state.displayEndDate
      )
    } else {
      return (
        value.year() > moment().year() || value.year() === moment().year() && value > moment() || value > this.state.endDate || value > this.state.displayEndDate
      )
    }

  }

  disabledEndDate = (value) => {
    return (
      value.year() > moment().year() || value > moment() || value < this.state.startDate || value < this.state.displayStartDate
    )
  }

  render() {
    return(
      <div>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <div style={{marginRight: '5px', display: 'block'}}>
            <Picker
              disabledDate={this.disabledStartDate}
              value={this.state.displayStartDate}
              onChange={this.onChange.bind(this, 'startDate')}
              marketLoading={this.props.marketLoading}
            />
          </div>
          <CustomSlider
            value={this.state.sliderValues}
            onChange={(e, value) => this.handleSliderValuesChange(e, value)}
            onChangeCommitted={this.handleSliderValuesCommit}
            max={this.state.daysInRange}
            valueLabelDisplay="auto"
            valueLabelFormat={(value, i) => <div>{this.getSliderValueText(value, i)}</div>}
            style={{margin: '0px 5px 0px 5px'}}
          />
          <div style={{display: 'block', marginLeft: '5px'}}>
            <Picker
              disabledDate={this.disabledEndDate}
              value={this.state.displayEndDate}
              onChange={this.onChange.bind(this, 'endDate')}
              marketLoading={this.props.marketLoading}
            />
          </div>
          <IconButton size="small" style={this.props.restoreHidden ? {display: 'none'} : {marginLeft: '2px'}} onClick={this.onClear}>
            <Tooltip title="Reset to default dates">
              <RestoreIcon fontSize="small" />
            </Tooltip>
          </IconButton>
        </div>
      </div>
    )
  }
}

export default DateRange
