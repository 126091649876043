import React, { Component } from 'react'
import {
  withStyles,
  Typography,
  Divider,
  CircularProgress,
  Paper,
  Link,
  Fade,

} from '@material-ui/core'
import Cookies from 'js-cookie'
import LostOpp from '../lostOpp/LostOpp'
import {GpsFixed} from '@material-ui/icons'

const styles = theme => ({
  pageTitle: {
		fontFamily: 'Roboto, sans-serif',
    fontSize: '17px',
    fontWeight: 'light',
    color: 'rgba(115, 115, 115, 0.9)'
	},
	pageTitleDark: {
		fontFamily: 'Roboto, sans-serif',
    fontSize: '17px',
    fontWeight: 'light',
    color: 'rgba(255, 255, 255, 0.9)'
	},
	pageIcon: {
		fontSize: '15px',
		marginTop: '21px',
		marginRight: '2px',
	},
	pageIconDark: {
		fontSize: '15px',
		color: 'rgba(255, 255, 255, 0.9)',
		marginTop: '21px',
		marginRight: '2px',
	},
})

class BoomLostOpp extends Component {
  _isMounted = false

  constructor() {
    super()
    this.state = {
      isLoading: true,
      lostOppData: [],
    }
  }

  componentDidMount = () => {
    this._isMounted = true

    fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomLostOpp`, {
			headers: {
				token: Cookies.get('token')
			}
		})
		.then(response => response.json())
		.then(data => {
			// console.log(data)
			if(this._isMounted && data) {
				this.setState({
					isLoading: false,
					lostOppData: data
				})
			} else {
				this.setState({
					isLoading: false,
					lostOppData: []
				})
			}
		})
		.catch(error => {
			console.log(error)
		})
  }

  render() {
    const { classes } = this.props
    return(
      <div>
        {this.state.isLoading ? <CircularProgress /> :
          <div>
            <LostOpp lostOppData={this.state.lostOppData}/>
          </div>
        }
      </div>
    )
  }
}

export default withStyles(styles)(BoomLostOpp)
