import React, { Component, Fragment } from 'react'
import BarChart from '../charts/BarChart'
import { withStyles } from '@material-ui/core/styles'
import { Typography, CircularProgress, Link } from '@material-ui/core'
import Cookies from 'js-cookie'
import otbStandIn from '../../assets/homeStandIns/otbStandIn.png'
import AskReport from '../AskReport'

const styles = theme => ({
  root: {
    margin: '1em',
    // [theme.breakpoints.up('xl')]: {
    //   width: '50%'
    // }
  },
  description: {
    textAlign: 'center',
    color: '#626262',
    marginTop: '20px'
  },
  descriptionDark: {
    textAlign: 'center',
    color: '#fff',
    marginTop: '20px'
  },
  total: {
    fontWeight: 'bold',
    fontSize: '15px',
    color: '#ff4189'
  },
  loader: {
    marginLeft: '45%',
    marginTop: '30%',
  },
  standIn: {
    width: '100%',
    [theme.breakpoints.up('xl')]: {
      marginTop: '-50px',
      height: '35%',
      width: '85%',
    },
    [theme.breakpoints.down('lg')]: {
      width: '120%',
      marginTop: '-35px'
    }
  },
  unavailable: {
    height: '400px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})

class OtbMod extends Component {
  _isMounted = false
  constructor() {
    super()
    this.state = {
      isLoading: true,
      otbData: [],
    }
  }

  componentDidMount() {
    this._isMounted = true

    fetch('https://api.dev.lsdirect.com/smartdash/V1/index.cfm/otb', {
      method: 'GET',
      headers: {
        'token': Cookies.get('token')
      },
    })
    .then(response => response.json())
    .then(data => {
      // console.log(data[0].Data)
      if(this._isMounted && data) {
        this.setState({
          isLoading: false,
          otbData: data[0].Data
        })
      } else {
        this.setState({
          isLoading: false,
        })
      }
    })
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    const { classes } = this.props
    const currentUserLite = JSON.parse(localStorage.getItem('currentUser')) && JSON.parse(localStorage.getItem('currentUser'))[0].sd_lite === 1 ? true : false
    const dbO = JSON.parse(localStorage.getItem("currentUser")) ? JSON.parse(localStorage.getItem("currentUser"))[0].db_owner : ""
    const colors = [
      '#ff4545',
      '#ffc849',
      '#56afe4',
      '#6dc96d',
      '#ff4189',
      '#c22ddd',
      '#07e8e0',
      '#ff9c3b',
    ]

    return(
      <div className={classes.root}>
        {this.state.isLoading ? <div className={classes.loader}><CircularProgress /></div> :
          this.state.otbData.length > 0 && !currentUserLite && dbO !== "ESCANABA" ?
          <Fragment>
              <BarChart
                data={[{
                  name: 'Overall Count',
                  data: this.state.otbData.map(item => item.Qty)
                }]}
                colors={colors}
                labels={this.state.otbData.map(item => item.OTB_Range)}
                horizontal={true}
                height={225}
                yLabel={'OTB Range'}
                xLabel={'OTB Count'}
                xLabelColor={this.props.darkMode ? '#fff' : ''}
                columnColors={this.props.darkMode ? ['#2a2a2a', '#3a3a3a'] : ['#fff', '#f2f2f2']}
                distributed={true}
                darkMode={this.props.darkMode}
              />
              <div className={this.props.darkMode ? classes.descriptionDark : classes.description}>
                <Typography variant="caption">
                  <span>The total OTB count is </span>
                  <span className={classes.total}>
                    {this.state.otbData.map(item => item.Qty).reduce((a,b) => a + b, 0).toLocaleString('en')}
                  </span>
                </Typography>
              </div>
          </Fragment>
          : 
          <div className={classes.unavailable}>
          <AskReport home={true} />
            </div>
          
        }
      </div>
    )
  }
}

export default withStyles(styles)(OtbMod)
