/**
 *  https://stackoverflow.com/a/52325099
 */

import React, { Component } from 'react'

class IframeEmbed extends Component {
  _isMounted = false

  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      src: props.source,
      style: {
        width: "100%",
        //height: "80vh",
        height: "1000px",
        border: "0",
        //resize: "both",
        overflow: "auto"
      },
      iframeOnLoad: props.iframeOnLoad,
      iframeActions: props.iframeActions
    };
  }

  componentDidMount = () => {
    this._isMounted = true

    console.log("IframeEmbed >> MOUNTED");

    // Load iframe content
    this.state.iframeOnLoad();
  }

  componentWillUnmount = () => {
    this._isMounted = false
  }

  refreshIframe = () => {
    this.render();
  }

  render() {
    console.log("IframeEmbed >> RENDER");

    /* Removed DEBUG info from returned DIV */
    //state.id = {this.state.id}<br />
    //state.src = {this.state.src}<br />
    //state.style = {JSON.stringify(this.state.style)}<br /><br />

    return (
      <div className="col-md-12">
        <div className="emdeb-responsive">
          <iframe id={this.state.id} src={this.state.src} style={this.state.style}></iframe>
        </div>
      </div>
    );
  }
};

export default IframeEmbed;

/*import React from 'react';
import ReactDOM from 'react-dom';

const IframeEmbed = ({ id, source }) => {

    if (!source) {
        return <div>Loading...</div>;
    }

    const IFRAME_ID = id;
    const IFRAME_SRC = source;
    const IFRAME_STYLE = {
      //position: "absolute",
      width: "100%",
      height: "80vh",
      resize: "both",
      overflow: "auto"
    };

    return (
        // basic bootstrap classes. you can change with yours.
        <div className="col-md-12">
            <div className="emdeb-responsive">
                <iframe id={IFRAME_ID} src={IFRAME_SRC} style={IFRAME_STYLE}></iframe>
            </div>
        </div>
    );
};

export default IframeEmbed;*/
