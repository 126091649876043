import React, { Component } from 'react'
import {
  withStyles,
  MenuItem,
  ListItem,
  ListItemText,
  ListItemIcon,
  Menu,
  Typography,
  Icon,
  Divider,
} from '@material-ui/core'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import SvgIcon from '@material-ui/core/SvgIcon'

import PersonAddIcon from '@material-ui/icons/PersonAdd'

import AppsIcon from '@material-ui/icons/Apps'
import { Link } from 'react-router-dom'
import TableauCatalog from './TableauCatalog'

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    width: '100%',
    maxWidth: 300
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left'
    }}
    {...props}
  />
))

const styles = theme => ({
  link: {
    color: '#00147b',
    textDecoration: 'none',
    border: '0px',
    '&:hover': {
      cursor: 'pointer',
      color: '#4ab8bb',
    }
  },
  linkDark :{
    color: '#4ab8bb',
    textDecoration: 'none',
    border: '0px',
    '&:hover': {
      cursor: 'pointer',
      color: '#97dbde'
    }
  }
})

function SalesConsultantIcon(props) {
  return(
    <SvgIcon {...props}>
      <path fill="currentColor" d="M2 3H22A2.07 2.07 0 0 1 24 5V19A2.07 2.07 0 0 1 22 21H2A2.07 2.07 0 0 1 0 19V5A2.07 2.07 0 0 1 2 3M8 13.91C6 13.91 2 15 2 17V18H14V17C14 15 10 13.91 8 13.91M8 6A3 3 0 1 0 11 9A3 3 0 0 0 8 6M17 10V13H21V10H17" />
    </SvgIcon>
  )
}

function LicenseeIcon(props) {
  return(
    <SvgIcon {...props}>
      <path fill="currentColor" d="M12,5.5A3.5,3.5 0 0,1 15.5,9A3.5,3.5 0 0,1 12,12.5A3.5,3.5 0 0,1 8.5,9A3.5,3.5 0 0,1 12,5.5M5,8C5.56,8 6.08,8.15 6.53,8.42C6.38,9.85 6.8,11.27 7.66,12.38C7.16,13.34 6.16,14 5,14A3,3 0 0,1 2,11A3,3 0 0,1 5,8M19,8A3,3 0 0,1 22,11A3,3 0 0,1 19,14C17.84,14 16.84,13.34 16.34,12.38C17.2,11.27 17.62,9.85 17.47,8.42C17.92,8.15 18.44,8 19,8M5.5,18.25C5.5,16.18 8.41,14.5 12,14.5C15.59,14.5 18.5,16.18 18.5,18.25V20H5.5V18.25M0,20V18.5C0,17.11 1.89,15.94 4.45,15.6C3.86,16.28 3.5,17.22 3.5,18.25V20H0M24,20H20.5V18.25C20.5,17.22 20.14,16.28 19.55,15.6C22.11,15.94 24,17.11 24,18.5V20Z" />
    </SvgIcon>
  )
}

class AdminMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      anchor: null,
    }
  }

  handleOpen = e => {
    this.setState({
      anchor: e.currentTarget
    })
  }

  handleClose = (e) => {
    this.setState({
      anchor: null
    })

    this.props.handleDrawerClose(e)
  }

  // handleAddToSideNav = (data) => {
  // }

  render() {
    const { classes } = this.props
    return(
      <div>

        <ListItem
          button
          onClick={this.handleOpen}
          style={this.props.user < 1 ? {display: 'none'} : {}}
        >
          <ListItemIcon><VerifiedUserIcon /></ListItemIcon>
          <ListItemText style={{lineHeight: '5px'}}>Admin</ListItemText>
        </ListItem>
        {/* {console.log(this.props)} */}
        <StyledMenu
          anchorEl={this.state.anchor}
          keepMounted
          open={Boolean(this.state.anchor)}
          onClose={this.handleClose}
        >
        <ListItem style={{border: 'none'}}>

          <ListItemText>
              <Link
                to={{
                  pathname: '/tableau-catalog',
                  state: {
                    tableauScreens: this.props.tableauScreens,

                  }
                }}
                onClick={this.handleClose}
                className={this.props.darkMode ? classes.linkDark : classes.link}
              >
                <div style={{display: 'inline-flex', alignItems: 'center'}}>
                  <AppsIcon style={{fontSize: '15px'}} />
                  <Typography variant="caption" style={{marginLeft: '5px', marginTop: '2px'}}>
                    Dashboard Catalog
                  </Typography>
                </div>
              </Link>
            {/* <AppsIcon style={{fontSize: '15px', marginRight: '5px'}} />
            <Typography variant="caption">
              Tableau Catalog
            </Typography>
            <Route exact path={"/tableau-catalog"}
              render={props => (
                <TableauCatalog tableauScreens={this.props.tableauScreens} addToSideNav={this.props.addToSideNav} />
              )}
            /> */}
          </ListItemText>
        </ListItem>

        <Divider />


        {/* <ListItem disabled style={this.props.user < 2 ? {display: 'none'} : {}}> */}

          {/* <ListItemText className={this.props.darkMode ? classes.linkDark : classes.link} disabled> */}
          {/*<Link
            // to={{
            //   pathname: '/create-user',
            // }}
            // onClick={this.handleClose}

        <ListItem>
          <ListItemText className={this.props.darkMode ? classes.linkDark : classes.link}>
          <Link
            to={{
              pathname: '/create-user',
            }}
            onClick={this.handleClose}

          >
            <PersonAddIcon style={{fontSize: '15px', marginRight: '5px'}} />
            <Typography variant="caption">
              Add New User
            </Typography>
          </Link>*/}
          {/* </ListItemText> */}

        {/* </ListItem> */}

        <ListItem style={this.props.user < 2 ? {display: 'none'} : {}}>

        {/* <ListItem> */}
          <ListItemText>
          <Link
            to={{
              pathname: '/sales-consultants',
            }}
            onClick={this.handleClose}
            className={this.props.darkMode ? classes.linkDark : classes.link}
          >
            <div style={{display: 'inline-flex', alignItems: 'center'}}>
              <SalesConsultantIcon style={{fontSize: '15px'}} />
              <Typography variant="caption" style={{marginLeft: '5px', marginTop: '2px'}}>
                Featured Sales Consultants
              </Typography>
            </div>
          </Link>
          </ListItemText>

        </ListItem>

        <Divider />

        <ListItem style={this.props.user < 2 ? {display: 'none'} : {}}>
          <ListItemText>
            <Link
              to={{
                pathname: '/licensee-participation'
              }}
              onClick={this.handleClose}
              className={this.props.darkMode ? classes.linkDark : classes.link}
            >
              <div style={{display: 'inline-flex', alignItems: 'center'}}>
                <LicenseeIcon style={{fontSize: '15px'}} />
                <Typography variant="caption" style={{marginLeft: '5px', marginTop: '2px'}}>
                  Licensee Participation
                </Typography>
              </div>
            </Link>
          </ListItemText>
        </ListItem>
        </StyledMenu>
      </div>
    )
  }
}

export default withStyles(styles)(AdminMenu)
