import React, { Component } from 'react'
import Chart from 'react-apexcharts'

class ResponseDemoChart extends Component {
  constructor(props) {
    super(props)
    console.log(props)
    // console.log(props.data)
    // console.log(props.compareOpen)
    console.log(props.categories)
    this.state = {
      series: props.data,
      options: {
        chart: {
          width: '100%'
        },
        // chart: {
        //   height: 225
        // },
        stroke: {
          show: true,
          color: 'transparent',
          width: 2,
        },
        colors: props.colors,
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%'
          },
        },
        dataLabels: {
          enabled: false
        },
        grid: {
          position: 'back',
          row: {
            colors: props.darkMode ? ['#2a2a2a', '#3a3a3a'] : ['#fff', '#f2f2f2']
          }
        },
        xaxis: {
          categories: props.categories,
          title: {

          },
          labels: {
            rotate: -60,
            style: {
              colors: props.darkMode ? '#fff' : ''
            }
          }
        },
        yaxis: {
          labels: {
            formatter: function(val, index) {
              return val.toFixed(1) + '%'
            },
            style: {
              colors: props.darkMode ? '#fff' : ''
            }
          }
        },
        fill: {
          opacity: props.darkMode ?  [1, 1] : [1, 1]
        },
        legend: {
          position: 'top',
          labels: {
            colors: props.darkMode ? '#fff' : ''
          }
        },
        tooltip: {
          shared: true,
          theme: props.darkMode ? 'dark' : 'light',
          x: {
            formatter: function(value) {
              return value
            }
          },
          y: {
            formatter: function(value) {
              if(value) {
                return value.toFixed(1) + '%'
              }
            }
          },
          fixed: {
            enabled: props.fixed,
            position: 'topLeft',
            offsetY: '-50'
          }
        }
      },
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if(nextProps.compareOpen) {
      return(
        {
          series: nextProps.data,
          options: {
            ...prevState.options,
            xaxis: {
              ...prevState.options.xaxis,
              categories: nextProps.categories
            }
          }
        }
      )
    } else {
      return (nextProps.data !== prevState.series) ?
      {
        series: nextProps.data,
        options: {
          ...prevState.options,
          // chart: {
          //   width: nextProps.width
          // },
          xaxis: {
            ...prevState.options.xaxis,
            categories: nextProps.categories
          }
        }
      } : null
    }
  }

  render() {
    return(
      <div>
        {/* {console.log(this.state.options.chart.width)} */}
        <Chart options={this.state.options} series={this.state.series} type="bar" height={this.props.height} width={this.props.width}/>
      </div>
    )
  }
}

export default ResponseDemoChart
