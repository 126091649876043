import React, { Component } from 'react'
import {
	Typography,
	withStyles,
	Divider,
	CircularProgress,
	Paper,
	Link,
	Fade,
	Select,
	MenuItem,
	InputBase,
} from '@material-ui/core'
import Cookies from 'js-cookie'
import { GpsFixed } from '@material-ui/icons'
import TabTest from '../TabTest'
import LostOppChart from './LostOppChart'
import LostOppTable from './LostOppTable'
import LostOppStats from './LostOppStats'
import LostOppExcel from './LostOppExcel'
import lostOppsStandIn from '../../assets/mainStandIns/lostOppsStandIn.png'
import AskReport from '../AskReport'
import moment from 'moment'

const styles = theme =>({
	pageTitle: {
		fontFamily: 'Roboto, sans-serif',
    fontSize: '17px',
    fontWeight: 'light',
    color: 'rgba(115, 115, 115, 0.9)'
	},
	pageTitleDark: {
		fontFamily: 'Roboto, sans-serif',
    fontSize: '17px',
    fontWeight: 'light',
    color: 'rgba(255, 255, 255, 0.9)'
	},
	pageIcon: {
		fontSize: '15px',
		marginTop: '21px',
		marginRight: '2px',
	},
	pageIconDark: {
		fontSize: '15px',
		color: 'rgba(255, 255, 255, 0.9)',
		marginTop: '21px',
		marginRight: '2px',
	},
	loader: {
    marginTop: '25%',
    marginLeft: '50%'
  },
	unavailable: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // margin: '2em'
  },
	link: {
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'none',
      color: '#4ab8bb'
    }
  },
  linkDark: {
    color: '#4ab8bb',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'none',
      color: '#6fd7da'
    }
  },
})

const StyledInput = withStyles((theme) => ({
	input: {
		borderRadius: 4,
		overflowX: 'auto',
		position: 'relative',
		padding: '5px 5px 0px 20px',
		border: '1px solid #767676',
		fontSize: 7,
		minWidth: 200,
		maxWidth: 350,
		// height: 25,
		'&:focus': {
			border: '1px solid',
			borderRadius: 4,
			borderColor: '#0054c1',
			backgroundColor: 'transparent',
		}
	}
}))(InputBase)

class LostOpp extends Component {
	_isMounted = false

	constructor(props) {
		super(props)
		this.state = {
			isLoading: true,
			lostOppData: [],
			lostOppTotalData: [],
			lostOppCustData: [],
			lostOppProsData: [],
			hasData: false,
			selectedValue: 'OVERALL',
			currentUser: JSON.parse(localStorage.getItem('currentUser'))
		}
	}

	componentDidMount = () => {
		this._isMounted = true

		Promise.all([
			fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomLostOpp`, {
				headers: {
					token: Cookies.get('token')
				}
			}),
			fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomLostOpp?cust=1`, {
				headers: {
					token: Cookies.get('token')
				}
			}),
			fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/boomLostOpp?pros=1`, {
				headers: {
					token: Cookies.get('token')
				}
			})
		])
		.then(([response, response2, response3]) => Promise.all([response.json(), response2.json(), response3.json()]))
		.then(([data, data2, data3]) => {
			console.log(data)
			console.log(data2)
			console.log(data3)
			if(this._isMounted && data) {
				this.setState({
					isLoading: false,
					hasData: true,
					lostOppData: data,
					lostOppTotalData: data,
					lostOppCustData:data2,
					lostOppProsData: data3,
				})
			} else {
				this.setState({
					isLoading: false,
					hasData: false,
					lostOppData: []
				})
			}
		})
		.catch(error => {
			console.log(error)
		})

		if(JSON.parse(localStorage.getItem('currentUser'))[0].username.toUpperCase().includes('USER')) {
		} else {
			fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/pageviews/add?display=boom_lost_opp&screen_name=boom_lost_opp`, {
				method: 'POST',
				headers: {
					'Access-Control-Allow-Origin': '*',
					'token': Cookies.get('token'),
				},
			})
			.then(response => {
				if(response.ok === true) {
					return response.json()
				}
			})
			.catch(error => {
				console.log(error)
			})
		}
	}


	handleValueChange = value => {
		if(value === 'OVERALL') {
			console.log('overall')
			this.setState({
				lostOppData: this.state.lostOppTotalData
			})
		} else if (value === 'CUSTOMERS') {
			console.log('customers')
			this.setState({
				lostOppData: this.state.lostOppCustData
			})
		} else {
			console.log('prospects')
			this.setState({
				lostOppData: this.state.lostOppProsData,
			})
		}

		this.setState({
			selectedValue: value
		})
	}


	render() {
		const {classes} = this.props
		const dbO = JSON.parse(localStorage.getItem("currentUser")) ? JSON.parse(localStorage.getItem("currentUser"))[0].db_owner : ""

		const decileOne = this.state.lostOppData.map(item => item.Data.filter(item => item.DecileRange === '0 - 10')[0])
		const decileTwo = this.state.lostOppData.map(item => item.Data.filter(item => item.DecileRange === '10 - 20')[0])
		const decileThree = this.state.lostOppData.map(item => item.Data.filter(item => item.DecileRange === '20 - 30')[0])
		const decileFour = this.state.lostOppData.map(item => item.Data.filter(item => item.DecileRange === '30 - 40')[0])
		const decileFive = this.state.lostOppData.map(item => item.Data.filter(item => item.DecileRange === '40 - 50')[0])
		const decileSix = this.state.lostOppData.map(item => item.Data.filter(item => item.DecileRange === '50 - 60')[0])
		const decileSeven = this.state.lostOppData.map(item => item.Data.filter(item => item.DecileRange === '60 - 70')[0])
		const decileEight = this.state.lostOppData.map(item => item.Data.filter(item => item.DecileRange === '70 - 80')[0])
		const decileNine = this.state.lostOppData.map(item => item.Data.filter(item => item.DecileRange === '80 - 90')[0])
		const decileTen = this.state.lostOppData.map(item => item.Data.filter(item => item.DecileRange === '90 - 100')[0])

		const lostOppSeries = [
			{
				name: `Decile: ${decileOne.map(item => item.DecileID)[0]}`,
				data: decileOne.map(item => item.LostOpp)
			},
			{
				name: `Decile: ${decileTwo.map(item => item.DecileID)[0]}`,
				data: decileTwo.map(item => item.LostOpp)
			},
			{
				name: `Decile: ${decileThree.map(item => item.DecileID)[0]}`,
				data: decileThree.map(item => item.LostOpp)
			},
			{
				name: `Decile: ${decileFour.map(item => item.DecileID)[0]}`,
				data: decileFour.map(item => item.LostOpp)
			},
			{
				name: `Decile: ${decileFive.map(item => item.DecileID)[0]}`,
				data: decileFive.map(item => item.LostOpp)
			},
			{
				name: `Decile: ${decileSix.map(item => item.DecileID)[0]}`,
				data: decileSix.map(item => item.LostOpp)
			},
			{
				name: `Decile: ${decileSeven.map(item => item.DecileID)[0]}`,
				data: decileSeven.map(item => item.LostOpp)
			},
			{
				name: `Decile: ${decileEight.map(item => item.DecileID)[0]}`,
				data: decileEight.map(item => item.LostOpp)
			},
			{
				name: `Decile: ${decileNine.map(item => item.DecileID)[0]}`,
				data: decileNine.map(item => item.LostOpp)
			},
			{
				name: `Decile: ${decileTen.map(item => item.DecileID)[0]}`,
				data: decileTen.map(item => item.LostOpp)
			},
		]

		const mailedSeries = [
			{
				name: `Decile: ${decileOne.map(item => item.DecileID)[0]}`,
				data: decileOne.map(item => item.Mailed)
			},
			{
				name: `Decile: ${decileTwo.map(item => item.DecileID)[0]}`,
				data: decileTwo.map(item => item.Mailed)
			},
			{
				name: `Decile: ${decileThree.map(item => item.DecileID)[0]}`,
				data: decileThree.map(item => item.Mailed)
			},
			{
				name: `Decile: ${decileFour.map(item => item.DecileID)[0]}`,
				data: decileFour.map(item => item.Mailed)
			},
			{
				name: `Decile: ${decileFive.map(item => item.DecileID)[0]}`,
				data: decileFive.map(item => item.Mailed)
			},
			{
				name: `Decile: ${decileSix.map(item => item.DecileID)[0]}`,
				data: decileSix.map(item => item.Mailed)
			},
			{
				name: `Decile: ${decileSeven.map(item => item.DecileID)[0]}`,
				data: decileSeven.map(item => item.Mailed)
			},
			{
				name: `Decile: ${decileEight.map(item => item.DecileID)[0]}`,
				data: decileEight.map(item => item.Mailed)
			},
			{
				name: `Decile: ${decileNine.map(item => item.DecileID)[0]}`,
				data: decileNine.map(item => item.Mailed)
			},
			{
				name: `Decile: ${decileTen.map(item => item.DecileID)[0]}`,
				data: decileTen.map(item => item.Mailed)
			},
		]

		const totals = [
			{
				decID: decileOne.map(item => item.DecileID)[0],
				lostOpp: decileOne.map(item => item.LostOpp),
				decRange: decileOne.map(item => item.DecileRange)[0],
				cpp: decileOne.map(item => item.CPP)[0],
				roi: decileOne.map(item => item.ROI)[0]
			},
			{
				decID: decileTwo.map(item => item.DecileID)[0],
				lostOpp: decileTwo.map(item => item.LostOpp),
				decRange: decileTwo.map(item => item.DecileRange)[0],
				cpp: decileTwo.map(item => item.CPP)[0],
				roi: decileTwo.map(item => item.ROI)[0]
			},
			{
				decID: decileThree.map(item => item.DecileID)[0],
				lostOpp: decileThree.map(item => item.LostOpp),
				decRange: decileThree.map(item => item.DecileRange)[0],
				cpp: decileThree.map(item => item.CPP)[0],
				roi: decileThree.map(item => item.ROI)[0]
			},
			{
				decID: decileFour.map(item => item.DecileID)[0],
				lostOpp: decileFour.map(item => item.LostOpp),
				decRange: decileFour.map(item => item.DecileRange)[0],
				cpp: decileFour.map(item => item.CPP)[0],
				roi: decileFour.map(item => item.ROI)[0]
			},
			{
				decID: decileFive.map(item => item.DecileID)[0],
				lostOpp: decileFive.map(item => item.LostOpp),
				decRange: decileFive.map(item => item.DecileRange)[0],
				cpp: decileFive.map(item => item.CPP)[0],
				roi: decileFive.map(item => item.ROI)[0]
			},
			{
				decID: decileSix.map(item => item.DecileID)[0],
				lostOpp: decileSix.map(item => item.LostOpp),
				decRange: decileSix.map(item => item.DecileRange)[0],
				cpp: decileSix.map(item => item.CPP)[0],
				roi: decileSix.map(item => item.ROI)[0]
			},
			{
				decID: decileSeven.map(item => item.DecileID)[0],
				lostOpp: decileSeven.map(item => item.LostOpp),
				decRange: decileSeven.map(item => item.DecileRange)[0],
				cpp: decileSeven.map(item => item.CPP)[0],
				roi: decileSeven.map(item => item.ROI)[0]
			},
			{
				decID: decileEight.map(item => item.DecileID)[0],
				lostOpp: decileEight.map(item => item.LostOpp),
				decRange: decileEight.map(item => item.DecileRange)[0],
				cpp: decileEight.map(item => item.CPP)[0],
				roi: decileEight.map(item => item.ROI)[0]
			},
			{
				decID: decileNine.map(item => item.DecileID)[0],
				lostOpp: decileNine.map(item => item.LostOpp),
				decRange: decileNine.map(item => item.DecileRange)[0],
				cpp: decileNine.map(item => item.CPP)[0],
				roi: decileNine.map(item => item.ROI)[0]
			},
			{
				decID: decileTen.map(item => item.DecileID)[0],
				lostOpp: decileTen.map(item => item.LostOpp),
				decRange: decileTen.map(item => item.DecileRange)[0],
				cpp: decileTen.map(item => item.CPP)[0],
				roi: decileTen.map(item => item.ROI)[0]
			},
		]

		const colors = [
			'#0084f5',
			'#43abfa',
			'#7cc3fa',
			'#aad6f9',
			'#fccfeb',
			'#fab5df',
			'#f9a3d6',
			'#7cfee0',
			'#5beeca',
			'#00cc9e',
		]

		return(
			<div>

				{this.state.isLoading ? <CircularProgress className={classes.loader}/> :
					this.state.hasData && dbO !== "ESCANABA" ?

					<div>
						<Fade in={true}>
				 			<div style={{display: 'flex', justifyContent: 'space-between'}}>
								<div>
									<Typography className={this.props.darkMode ? classes.pageTitleDark : classes.pageTitle}>
										<br />
										<GpsFixed className={this.props.darkMode ? classes.pageIconDark : classes.pageIcon}/>
										<span style={{marginLeft: '5px'}}>Boomerang Lost Opportunity</span>
									</Typography>
									<Typography variant="caption" style={{fontSize: '12px', marginTop: '10px', width: 100}}>
										Go to the Boomerang ROI Screen <Link className={this.props.darkMode ? classes.linkDark : classes.link} href={'/boomerang'}>HERE</Link>
									</Typography>
								</div>
								<div style={this.state.currentUser[0].compIDs === '236' || this.state.currentUser[0].db_owner === 'HARRYDAVID' || this.state.currentUser[0].db_owner === 'WOLFERMANS' ? {marginTop: '25px', textAlign: 'right', justifyContent: 'right'} : {display: 'none'}}>
									<br/>
									<div style={{marginRight: '10px', textAlign: 'left'}}>
										<Select
											value={this.state.selectedValue}
											onChange={e => this.handleValueChange(e.target.value)}
											input={<StyledInput />}
										>
											<MenuItem value={'OVERALL'}><Typography variant="caption">OVERALL</Typography></MenuItem>
											<MenuItem value={'PROSPECTS'}><Typography variant="caption">PROSPECTS</Typography></MenuItem>
											<MenuItem value={'CUSTOMERS'}><Typography variant="caption">CUSTOMERS</Typography></MenuItem>
										</Select>
									</div>

									<div style={{float: 'right', marginTop: '-5px'}}>
										<LostOppExcel
											totals={totals}
											dataCount={this.state.lostOppData.length}
											lostOppSeries={lostOppSeries}
											mailedSeries={mailedSeries}
											value={this.state.selectedValue}
											maxDate={moment.max(this.state.lostOppData.map(item => moment(item.YearMo))).format('YYYY-MM')}
										/>
					
									</div>
								</div>
				 			</div>
						</Fade>
						<br/>
						<LostOppStats data={totals} darkMode={this.props.darkMode}/>
						{console.log('selected data', this.state.lostOppData)}
						<Fade in={true} timeout={1000} style={{transitionDelay: '200ms'}}>
							<Paper>
								<div style={{display: 'flex', marginTop: '25px', justifyContent: 'space-between'}}>
									<div style={{width: '48%', marginTop: '30px', marginLeft: '10px'}}>
										<div style={{textAlign: 'center'}}>
											<Typography variant="caption" style={{fontWeight: 'bold', color: '#ff4c58'}}>Lost Opportunity Per Decile</Typography>
										</div>
										<LostOppChart
											height={450}
											series={lostOppSeries}
											categories={this.state.lostOppData.map(item => item.YearMo)}
											colors={colors}
											maxLostArr={this.state.lostOppData.map(item => item.Data.map(item => item.LostOpp).reduce((a,b) => a + b, 0))}
											maxMailedArr={this.state.lostOppData.map(item => item.Data.map(item => item.Mailed).reduce((a,b) => a + b, 0))}
											darkMode={this.props.darkMode}
											legend={true}
											fixed={true}
										/>
									</div>
									<Divider orientation="vertical" flexItem />
									<div style={{width: '48%', marginTop: '30px', marginRight: '10px'}}>
										<div style={{textAlign: 'center'}}>
											<Typography variant="caption" style={{fontWeight: 'bold', color: '#732da2'}}>Mailed Counts Per Decile</Typography>
										</div>
										<LostOppChart
											height={450}
											series={mailedSeries}
											categories={this.state.lostOppData.map(item => item.YearMo)}
											colors={colors}

											maxLostArr={this.state.lostOppData.map(item => item.Data.map(item => item.LostOpp).reduce((a,b) => a + b, 0))}
											maxMailedArr={this.state.lostOppData.map(item => item.Data.map(item => item.Mailed).reduce((a,b) => a + b, 0))}

											darkMode={this.props.darkMode}
											legend={true}
											fixed={true}
										/>
									</div>
								</div>
							</Paper>
						</Fade>

						<br/>
						<br/>
						<Fade in={true} timeout={1000} style={{transitionDelay: '200ms'}}>
							<LostOppTable data={totals.reverse()} dataCount={this.state.lostOppData.length}/>
						</Fade>

					</div>
					: 
					<div className={classes.unavailable}>
              <AskReport />
            </div>
				}
			</div>
		)
	}
}

export default withStyles(styles)(LostOpp)
