import React from 'react'
import TabTest from '../TabTest'
import { Typography, makeStyles } from '@material-ui/core'
import TimelineIcon from '@material-ui/icons/Timeline'

const useStyles = makeStyles(theme => ({
	title: {
		fontSize: '17px',
		fontWeight: 'light',
		color: 'rgba(115, 115, 115, 0.9)'
	},
	pageIcon: {
		fontSize: '15px',
		marginTop: '21px',
		marginRight: '2px',
	}
}))

export default function DemoTrends() {
	const classes = useStyles()

	return(
		<div>
			{/*<div style={{display: 'flex', justifyContent: 'space-between'}}>
				<Typography className={classes.title}>
					<br />
					<TimelineIcon className={classes.pageIcon} />
					DEMOGRAPHIC TRENDS
				</Typography>
			</div> */}
			<TabTest pageName={'DEMOGRAPHIC TRENDS'} viz={'DemoTrends/DemoTrends'} />
		</div>
	)
}