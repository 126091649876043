import React, { Component } from 'react'
import RangeCalendar from 'rc-calendar/lib/RangeCalendar'
import DatePicker from 'rc-calendar/lib/Picker'
import {withStyles, Typography, Button, Tooltip} from '@material-ui/core'
import RestoreIcon from '@material-ui/icons/Restore'

import moment from 'moment'
const format = 'MM/DD/YYYY'
const now = moment()

const styles = theme => ({
  button: {
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#ff9800'
    }
  },
  buttonDark: {
    color: '#fff',
    '&:hover': {
      backgroundColor: 'transparent',
    }
  }
})

class Picker extends Component {
  render() {
    const props = this.props
    const { showValue } = props
    const calendar = (
      <RangeCalendar
        type={this.props.type}
        defaultValue={now}
        format={format}
        onChange={props.onChange}
        disabledDate={props.disabledDate}
        style={ this.props.darkMode ?
          {zIndex: 5000, float: 'right', marginRight: '15px', backgroundColor: '#3a3a3a'}
        :
          {zIndex: 5000, float: 'right', marginRight: '15px'}
        }
      />
    )
    return(
      <DatePicker
        animation="slide-up"
        open={this.props.open}
        onOpenChange={this.props.onOpenChange}
        calendar={calendar}
        value={props.value}
      >
        {
          () => {
            return(
              <span>
                <input
                  placeholder={this.props.placeholder ? this.props.placeholder : ''}
                  style={this.props.darkMode ?
                    {
                      width: 150,
                      height: 25,
                      textAlign: 'center',
                      backgroundColor: 'transparent',
                      border: '1px solid #fff',
                      borderRadius: '3px',
                      color: '#fff'
                    }
                  :
                    {
                      width: 150,
                      height: 25,
                      textAlign: 'center',
                      backgroundColor: 'transparent',
                      border: '1px solid #818181',
                      borderRadius: '3px'
                    }
                  }
                  readOnly
                  value={showValue && showValue.format(format) || ''}
                />
              </span>
            )
          }
        }
      </DatePicker>
    )
  }
}

class PromoDate extends Component {
  constructor(props){
    super(props)
    this.state = {
      // value: [],
      // startValue: moment().subtract(1, 'years'),
      startValue: props.startDate,
      endValue: now,
      startOpen: false,
      endOpen: false,
    }
  }

  onStartOpenChange = startOpen => {
    this.setState({
      startOpen,
    })
  }

  onEndOpenChange = endOpen => {
    this.setState({
      endOpen,
    })
  }

  // onChange = value => {
  //   this.setState(prevState => ({
  //     value: [...prevState.value, value]
  //   }))
  // }

  onStartChange = value => {
    this.setState({
      startValue: value[0],
      startOpen: false,
      endOpen: true,
    })
  }

  onEndChange = value => {
    this.setState({
      endValue: value[1],
    })
    this.props.handleDateChange(value)
  }

  onClear = () => {
    this.setState({
      startValue: this.props.startDate,
      endValue: moment(),
    })
    this.props.handleDateClear()
  }

  disabledStartDate = endValue => {
    if(!endValue) {
      return false
    }
    const startValue = this.state.startValue
    if(!startValue) {
      return false
    }
    return endValue.diff(startValue, 'days') < 0
  }

  render() {
    const { classes } = this.props
    return (
      <div style={{float: 'right', width: 225, marginTop: '-11px'}}>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <Typography variant="caption" style={this.props.darkMode ? {color: '#fff', marginTop: '5px', marginRight: '3px'} : {color: '#818181', marginTop: '5px', marginRight: '3px'}}>Start Date:</Typography>
            <Picker
              onOpenChange={this.onStartOpenChange}
              type="start"
              // showValue={this.props.startDate ? this.props.startDate : this.state.startValue}
              showValue={this.state.startValue}
              open={this.state.startOpen}
              value={[this.state.startValue, this.state.endValue]}
              onChange={this.onStartChange}
              darkMode={this.props.darkMode}
            />
          </div>
          <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '5px'}}>
            {/* {console.log(this.props.darkMode)} */}
            <Typography variant="caption" style={this.props.darkMode ? {color: '#fff', marginTop: '5px', marginRight: '3px'} : {color: '#818181', marginTop: '5px', marginRight: '3px'}}>End Date:</Typography>
            <Picker
              onOpenChange={this.onEndOpenChange}
              open={this.state.endOpen}
              type="end"
              showValue={this.state.endValue}
              disabledDate={this.disabledStartDate}
              value={[this.state.startValue, this.state.endValue]}
              onChange={this.onEndChange}
              darkMode={this.props.darkMode}
            />
          </div>
          <div style={{float: 'right'}}>
            <Button
              color="primary"
              className={this.props.darkMode ? classes.buttonDark : classes.button}
              endIcon={<RestoreIcon fontSize="small" />}
              disableRipple
              onClick={this.onClear}
            >
              <span style={{marginTop: '2px', fontSize: '11px'}}>Restore Default</span>
            </Button>
          </div>
      </div>
    )
  }
}

export default withStyles(styles)(PromoDate)
