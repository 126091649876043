import React, { Component } from 'react'
import Chart from 'react-apexcharts'
import { Link } from '@material-ui/core'
import '../../assets/chart.css'

class ProdPerfChart extends Component {
  constructor(props) {
    super(props)
    // console.log(props.labels.length)
    // console.log(props.labels)
    console.log(props.series)
    this.state = {
      series: props.series,
      options: {
        chart: {
          type: 'line',
          stacked: true,
          toolbar: {
            tools: {
              download: false,
            }
          },
          events: {
            // click: function(e, chartContext, config) {
            //   console.log(e.target.textContent)
              // let label = document.getElementById(`${e.target.id}`).textContent
              // console.log(label)
              // console.log(chartContext)
              // console.log(config.globals)

              // console.log(e.target.id)
            // }
            // dataPointSelection: function(e, chartContext, config) {
            //   console.log(e)
            // }
          },
        },
        colors: props.colors,
        dataLabels: {
          enabled: true,
          enabledOnSeries: [2],
          formatter: value => {
            return value.toFixed(2) + '%'
          }
          // enabledOnSeries: props.series[2]
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        stroke: {
          width: [0, 0, 4],
          curve: 'smooth',
        },
        xaxis: {
          // categories: props.labels.map(item => (
          //   item.ProductName
          // )),
          categories: props.labels,
          type: 'category',
          labels: {
            hideOverlappingLabels: false,
            style: {
              colors: props.darkMode ? '#fff' : '#0068c0',
              cssClass: 'xlabels'
            },
            formatter: function(value) {
              return value
            }
            // formatter: function(value) {
            //   console.log(props.labels)
            // }
          },
        },
        yaxis: [
          {
            min: 0,
            max: Math.max.apply(Math, props.series[0].data.map(function(number, index) {
              return number + props.series[1].data[index] + 0.25
            })),
            tickAmount: 7,
            seriesName: props.series[0].name,
            axisBorder: {
              show: true,
              color: props.colors[1]
            },
            labels: {
              formatter: value => {
                // return Math.ceil(value.toFixed(2)/0.05)*0.05
                return (Math.ceil(value / 0.05)*0.05).toFixed(2) + '%'
                // return value.toFixed(2) + '%'
              },
              style: {
                colors: props.darkMode ? '#fff' : ''
              }
            },
            title: {
              text: 'Total Conversion Rate',
              style: {
                color: props.darkMode ? props.colors[1] : props.colors[0]
              }
            }
          },
          {
            min: 0,
            max: Math.max.apply(Math, props.series[0].data.map(function(number, index) {
              return number + props.series[1].data[index] + 0.25
            })),
            show: false,
            tickAmount: 7,
            labels: {
              formatter: value => {
                return value.toFixed(2) + '%'
              },
            }
          },
          {
            opposite: true,
            min: 0,
            // max: Math.max.apply(Math, props.series[2].data.map(function(number) {return number + 0.25})),
            tickAmount: 7,
            seriesName: props.series[2].name,
            axisBorder: {
              show: true,
              color: props.colors[2]
            },
            labels: {
              formatter: value => {
                return (Math.ceil(value / 5) * 5).toFixed(2) + '%'
                // return value.toFixed(2) + '%'
              },
              style: {
                colors: props.darkMode ? '#fff' : ''
              }
            },
            title: {
              text: props.series[2].name,
              style: {
                color: props.colors[2]
              }
            }

          }
        ],
        // yaxis: {
        //   labels: {
        //     formatter: value => {
        //       return value.toFixed(2) + '%'
        //     }
        //   }
        // },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
          labels: {
            colors: props.darkMode ? '#fff' : ''
          }
        },
        tooltip: {
          shared: true,
          theme: props.darkMode ? 'dark' : 'light',
          // x: {
          //   formatter: function(value) {
          //     return value
          //   }
          // },
          y: {
            formatter: function(value) {
              return value.toFixed(2) + '%'
              // console.log(value)
            }
          }
        },
        fill: {
          opacity: 1,
        },
        // y: {
        //   formatter: value => {
        //     return value.toFixed(2) + '%'
        //   }
        // }
      },

    }
  }

  componentDidMount = () => {
    const labels = document.querySelectorAll('.apexcharts-text.apexcharts-xaxis-label')
    const tooltips = document.querySelectorAll('.apexcharts-xaxistooltip')
    console.log(labels)

    labels.forEach(item => {
      item.addEventListener('click', e => {
        // if(e.target.textContent === 'Boomerang') {
          window.location=`/${e.target.textContent.toLowerCase()}/lost-opp`
          // window.location='/lost-opp'
        // }
      })
    })
  }

  render() {
    return(
      <div>
        <Chart series={this.state.series} options={this.state.options} type="line" height={this.props.height}/>

      </div>
    )
  }
}

export default ProdPerfChart
