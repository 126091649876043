import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography, CircularProgress } from '@material-ui/core'
import BarChart from '../charts/BarChart'
import Cookies from 'js-cookie'
import rfmStandIn from '../../assets/homeStandIns/rfmStandIn.png'
import AskReport from '../AskReport'

const styles = theme => ({
  root: {
    margin: '1em'
  },
  description: {
    textAlign: 'center',
    color: '#626262'
  },
  description: {
    textAlign: 'center',
    color: '#626262'
  },
  descriptionDark: {
    textAlign: 'center',
    color: '#fff'
  },
  total: {
    fontWeight: 'bold',
    fontSize: '15px',
    color: 'rgba(247, 133, 10, 0.9)',
  },
  loader: {
    marginLeft: '45%',
    marginTop: '30%',
  },
  standIn: {
    width: '100%',
    [theme.breakpoints.up('xl')]: {
      marginTop: '-50px',
      height: '35%',
      width: '85%'
    },
    [theme.breakpoints.down('lg')]: {
      // width: '120%',
      marginTop: '-35px'
    }
  },
  unavailable: {
    height: '400px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})

class RfmModule extends Component {
  _isMounted = false

  constructor() {
    super()
    this.state = {
      isLoading: true,
      rfmData: [],
      rfmLabels: [],
    }
  }

  componentDidMount() {
    this._isMounted = true

    fetch('https://api.dev.lsdirect.com/smartdash/V1/index.cfm/rfm', {
      method: 'GET',
      headers: {
        'token': Cookies.get('token')
      },
    })
    .then(response => response.json())
    .then(data => {
      if(this._isMounted && data) {
        this.setState({
          isLoading: false,
          rfmData: data[0].Data.map(item => item.Count_All),
          rfmLabels: data[0].Data.map(item => item.RFM_Segment)
        })
      } else {
        this.setState({
          isLoading: false,
          rfmData: []
        })
      }
    })
    .catch(error => {
      console.log(error)
    })
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    const { classes } = this.props
    const dbO = JSON.parse(localStorage.getItem("currentUser")) ? JSON.parse(localStorage.getItem("currentUser"))[0].db_owner : ""
    const currentUserLite = JSON.parse(localStorage.getItem('currentUser')) && JSON.parse(localStorage.getItem('currentUser'))[0].sd_lite === 1 ? true : false
    const colors = [
      '#00ff7b',
      '#66e0ff',
      '#007491',
      '#c369ff',
      '#ffe100',
      '#bd982b',
      '#e38fb6',
      '#f7850a',
      '#ff2b34',
      '#c4c4c4',
      '#9c9c9c',
      '#737373',
    ]

    return(
      <div className={classes.root}>
        {this.state.isLoading ? <div className={classes.loader}><CircularProgress /></div> :
          this.state.rfmData.length > 0 && !currentUserLite && dbO !== "ESCANABA" ?
          <Fragment>
            <BarChart
              labels={this.state.rfmLabels}
              data={[{
                name: 'Count',
                data: this.state.rfmData
              }]}
              colors={colors}
              horizontal={false}
              height={225}
              rotate={-45}
              yLabel={'Customer Count'}
              xLabel={'RFM Segment'}
              rowColors={this.props.darkMode ? ['#2a2a2a', '#3a3a3a'] : ['#fff', '#f2f2f2']}
              distributed={true}
              darkMode={this.props.darkMode}
            />
            <br />

            <div className={this.props.darkMode ? classes.descriptionDark : classes.description}>
              <Typography variant="caption">
                <span>The total customer count is </span>
                <span className={classes.total}>
                  {this.state.rfmData.reduce((a,b) => a + b, 0).toLocaleString('en')}
                </span>
              </Typography>
            </div>
          </Fragment>
          : 
          <div className={classes.unavailable}>
              <AskReport home={true} />
            </div>
        }
      </div>
    )
  }
}

export default withStyles(styles)(RfmModule)
