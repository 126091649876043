import React, { Component } from 'react'
import Chart from 'react-apexcharts'

class BarChart extends Component {
  constructor(props) {
    super(props)    
    this.state = {
      series: props.data,
      options: {
        chart: {
          type: 'bar',
          zoom: {
            show: true,
            type: 'xy'
          },
          toolbar: {
            show: props.toolbar === false ? props.toolbar : true,
            tools: {
              download: false,
              reset: true
            }
          }
        },
        state: {
          active: {
            filter: {
              type: 'none',
            }
          }
        },
        colors: props.colors,
        plotOptions: {
          bar: {
            barHeight: '75%',
            columnWidth: '50%',
            distributed: props.distributed,
            horizontal: props.horizontal
          }
        },
        legend: {
          show: props.legend,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 1,
        },
        grid: {
          position: 'back',
          row: {
            colors: props.rowColors
          },
          column: {
            colors: props.columnColors
          }
        },
        title: {
          text: props.title ? props.title.toUpperCase() : '',
          align: 'center',
          style: {
            color: props.darkMode ? '#fff' : '',
          },
        },
        xaxis: {
          title: {
            text: props.xLabel,
            style: {
              color: props.darkMode ? '#fff' : ''
            }
          },
          categories: props.labels,
          tickPlacement: 'on',
          labels: {
            hideOverlappingLabels: true,
            rotate: props.rotate,
            // rotateAlways: true,
            style: {
              fontSize: '10px',
              colors: props.darkMode ? '#fff' : ''
            }
          }
        },
        yaxis: {
          title: {
            text: props.yLabel,
            style: {
              color: props.darkMode ? '#fff' : ''
            },
          },
          labels: {
            formatter: value => {
              return value.toLocaleString('en')
            },
            style: {
              colors: props.darkMode ? '#fff' : ''
            }
          },
          axisBorder: {
            show: true,
            color: props.darkMode ? '#fff' : ''
          }
        },
        tooltip: {
          intersect: true,
          theme: props.darkMode ? 'dark' : 'light',
          x: {
            show: true
          },
          y: {
            formatter: function(value) {
              return value.toLocaleString('en')
            }
          },

        },
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return (nextProps.data !== prevState.series) ?
        {
          series: nextProps.data,
          options: {
            xaxis: {
              categories: nextProps.labels
            }
          }
        } : null
  }

  render() {

    return(
      <div>
        <Chart options={this.state.options} series={this.state.series} type="bar" height={this.props.height} width={this.props.width}/>
      </div>
    )
  }
}

export default BarChart
