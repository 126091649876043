import React, { Component } from 'react'
import styled from 'styled-components'
import {
  withStyles,
  Paper,
  Card,
  CardMedia,
  CardContent,
  TextField,
  Grid,
  Input,
  InputAdornment,
  Typography,
  InputBase,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Menu,
  ClickAwayListener,
  Divider,
  Button,
  Snackbar,
  SnackbarContent,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  FormLabel,
} from '@material-ui/core'
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import WarningIcon from '@material-ui/icons/Warning'
import ErrorIcon from '@material-ui/icons/Error'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import logo from '../assets/SmartDash3ColorVector_RGB_NoTagline.png'
import logoDark from '../assets/SmartDash3ColorVector_RGB_NoTagline_DarkMode.png'
import graphic from '../assets/graphic.png'
import Cookies from 'js-cookie'

const styles = theme => ({
  // root: {
  //   minHeight: '100vh'
  // },
  paper: {
    // backgroundColor: '#fff',
    borderTop: '1px solid #eaeaea',
    height: 625,
    width: 900,
  },
  secondColumn: {
    width: '30%',
    marginLeft: 20,
    textAlign: 'center'
  },
  media: {
    marginTop: 15
  },
  checkbox: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:focus': {
      backgroundColor: 'transparent'
    },
    '&:active': {
      color: '#4ab8bb'
    }
  },
  checkIcon: {
    fontSize: 12,
  },
  error: {
    backgroundColor: '#ed263d',
  },
  success: {
    backgroundColor: '#4caf50'
  },
  warning: {
    backgroundColor: '#ff8c23'
  },
  statusIcon: {
    fontSize: '17px',
    fontWeight: 'bold',
  }
})

const CustomCheckbox = withStyles({
  root: {
    // color: '#4ab8bb',
    '&$checked': {
      color: '#4ab8bb'
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />)

const StyledTextField = styled(TextField)`
  label.Mui-focused {
    color: #4ab8bb
  }
  .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: #4ab8bb
    }
  }
`

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    width: '100%',
    maxWidth: 248,
    maxHeight: 400,

  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left'
    }}
    {...props}
  />
))

const AdminMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    width: '100%',
    maxWidth: 110,
    maxHeight: 400,

  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left'
    }}
    {...props}
  />
))

const CustomSelect = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'transparent',
    border: '1px solid #bfbfbf',
    fontSize: 16,
    padding: '17.5px 26px 17px 26px',
    '&:focus': {
      borderRadius: 4,
      borderColor: '#4ab8bb'
    },
    width: '27.7%',
  }
}))(InputBase)

const SubmitButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 13,
    border: '1px solid',
    color: '#4ab8bb',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#4ab8bb',
      borderColor: '#4ab8bb',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none'
    }
  }
})(Button)

class CreateNewUser extends Component {
  _isMounted = false

  constructor() {
    super()
    this.state = {
      isLoading: true,
      open: false,
      adminOpen: false,
      anchor: null,
      value: '',
      fname: '',
      lname: '',
      email: '',
      tel: '',
      company: '',
      companyList: [],
      dbO: '',
      corpID: '',
      username: '',
      password: '',
      confirmPassword: '',
      adminLevel: 0,
      dataExport: 0,
      snackbar: false,
      status: '',
      statusMessage: '',
      userDescription: '',
    }

    this.handleMenuOpen = this.handleMenuOpen.bind(this)
  }

  componentDidMount = () => {
    this._isMounted = true

    fetch('https://api.dev.lsdirect.com/smartdash/V1/index.cfm/admin/addUser/getCompanies', {
      headers: {
        token: Cookies.get('token')
      }
    })
    .then(response => response.json())
    .then(data => {
      if(this._isMounted) {
        this.setState({
          isLoading: false,
          companyList: data
        })
      }
    })
    .catch(error => {
      console.log(error)
    })
  }

  handleMenuOpen = (e) => {
    this.setState({
      anchor: e.currentTarget,
      open: true,
    })
  }


  handleMenuClose = () => {
    this.setState({
      open: false
    })
  }

  // handleAdminMenuOpen = (e) => {
  //   this.setState({
  //     anchor: e.currentTarget,
  //     adminOpen: true,
  //   })
  // }
  //
  // handleAdminMenuClose = () => {
  //   this.setState({
  //     adminOpen: false
  //   })
  //
  // }

  handleSelectAdmin = (e) => {
    this.setState({
      adminLevel: parseInt(e.target.value)
    })
  }

  handleDataExport = (e) => {
    if(e.target.checked) {
      this.setState({
        dataExport: 1,
      })
    } else {
      this.setState({
        dataExport: 0,
      })
    }
  }

  handleSelectComp = (e) => {
    const { myValue } = e.currentTarget.dataset
    if(myValue === '--Select A Company--') {
      this.setState({
        value: ''
      })
    } else {
      let selectedCompany = this.state.companyList.filter(company => company.company === myValue)
      let companyID = selectedCompany[0].companyID
      this.setState({
        value: myValue,
        company: companyID,
      })
    }

    this.handleMenuClose()
    this.handleSelectDb(myValue)
  }

  handleSelectDb = (company) => {

    if(company === '--Select A Company--') {
      this.setState({
        dbO: '',
        corpID: '',
      })
    } else {
      let selectedCompany = this.state.companyList.filter(item => item.company === company)

      this.setState({
        dbO: selectedCompany[0].dbO,
        // dbO: company + 'dbO',
        corpID: selectedCompany[0].corpID
      })
    }
  }

  handleNameValidation = (e) => {
    // console.log(e.target.value)
    if(e.target.name === 'fname' && e.target.value.length < 1 || e.target.name === 'lname' && e.target.value.length < 1) {
      console.log(e.target.name + ' is required')
    } else if(e.target.name === 'username' && e.target.value.length < 6) {
      console.log('The username you have entered is not valid or does not follow the correct format')
      // this.setState({
      //   snackbar: true,
      //   statusMessage: 'The username you have entered is not valid or does not follow the correct format'
      // })
    } else {
      console.log('valid')
      this.setState({
        [e.target.name]: e.target.value
      })
    }
  }

  handlePasswordValidation = (e) => {
    if(e.target.name === "password") {
      let reg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/
      let test = reg.test(e.target.value)
      if(test) {
        this.setState({
          password: e.target.value
        })
      } else {
        console.log('The password you have entered does not meet the requirements')
        // this.setState({
        //   snackbar: true,
        //   statusMessage: 'The password you have entered does not meet the requirements'
        // })
      }
    } else {
      if(e.target.value !== this.state.password) {
        console.log('The passwords you have entered do not match.')
        // this.setState({
        //   snackbar: true,
        //   statusMessage: 'The passwords you have entered do not match.'
        // })
      }
    }
  }

  handleEmailValidation = (e) => {
    let reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if(reg.test(e.target.value)) {
      this.setState({
        email: e.target.value
      })
    } else {
      console.log('email address is not valid')
    }
  }

  handlePhoneFormat = (e) => {
    let tel = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
    e.target.value = !tel[2] ? tel[1] : '(' + tel[1] + ') ' + tel[2] + (tel[3] ? '-' + tel[3] : '')

    this.setState({
      tel: tel.input
    })
  }

  handleSnackbarClose = (e, reason) => {
    if(reason === 'clickaway') {
      this.setState({
        snackbar: false,
      })
    }
    this.setState({
      snackbar: false
    })
  }

  handleCheckUserSubmit = () => {
    console.log('submitting user')
    const requiredFields = {
      'fname': this.state.fname,
      'lname': this.state.lname,
      'company': this.state.company,
      'dbO': this.state.dbO,
      'corpID': this.state.corpID,
      'username': this.state.username,
      'password': this.state.password,
      'confirmPassword': this.state.confirmPassword,
    }
    const blank = []

    for ( let [key, value] of Object.entries(requiredFields)) {
      if(`${value}` === '') {
        blank.push(key)
      }
    }

    console.log(blank)


    // console.log(pendingUser)
    // let fname = document.querySelector('#fname').value
    // let lname = document.querySelector('#lname').value
    // let email = document.querySelector('#email').value
    // let tel = document.querySelector('#tel').value
    // let company = document.querySelector('#company').value
    // let dbO = document.querySelector('#dbO').value
    // let corpID = document.querySelector('#corpID').value
    // let username = document.querySelector('#username').value
    // let password = document.querySelector('#password').value
    // let confirmPassword = document.querySelector('#confirmPassword').value
  }

  handleSubmitUser = () => {
    const pendingUser = {
      'fname': this.state.fname,
      'lname': this.state.lname,
      'email': this.state.email,
      'mobilenumber': this.state.tel,
      'companyID': this.state.company,
      'username': this.state.username,
      'password': this.state.password,
      'admin_level': this.state.adminLevel,
      'data_export': this.state.dataExport,
    }

    fetch('https://api.dev.lsdirect.com/smartdash/V1/index.cfm/admin/addUser', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'token': Cookies.get('token')
      },
      body: JSON.stringify(pendingUser)
    })
    .then(response => {
      if(response.ok === true) {
        return response.text()
      } else if (response.ok !== true){
        return response.json()
      }
    })
    .then(data => {
      if(data === '0') {
        this.setState({
          snackbar: true,
          statusMessage: 'The Username you entered is already in use',
          status: 'error'
        })
      } else if (data === '1') {
        this.setState({
          snackbar: true,
          statusMessage: 'User has been successfully added!',
          status: 'success',
        })
      } else {
        console.log(data)
        // this.setState({
        //   snackbar: true,
        //   statusMessage: 'Something went wrong. Please check to make sure all required fields are filled out properly',
        //   status: 'error'
        // })
      }
    })
    .catch(error => {
      console.log(error)
    })
  }

  render() {
    const { classes } = this.props
    return(
      <div className={classes.root}>
        <Snackbar
          open={this.state.snackbar}
          autoHideDuration={4500}
          onClose={this.handleSnackbarClose}
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        >
          <SnackbarContent
            className={
              this.state.status === 'error' ?
              classes.error
              :
              this.state.status === 'success' ?
              classes.success
              :
              classes.warning
            }
            message={
              this.state.status === 'error' ?
              <div style={{display: 'flex', verticalAlign: 'center'}}>
                <ErrorIcon className={classes.statusIcon}/>
                <Typography variant="caption" style={{fontWeight: 'bold', marginLeft: '5px'}}>{this.state.statusMessage}</Typography>
              </div>
              : this.state.status === 'success' ?
              <div style={{display: 'flex', verticalAlign: 'center'}}>
                <CheckCircleIcon className={classes.statusIcon}/>
                <Typography variant="caption" style={{fontWeight: 'bold'}}>{this.state.statusMessage}</Typography>
              </div>
              :
              <div style={{display: 'flex' }}>
                <WarningIcon className={classes.statusIcon}/>
                <Typography variant="caption" style={{fontWeight: 'bold'}}>{this.state.statusMessage}</Typography>
              </div>
            }
          />
        </Snackbar>
      {this.state.isLoading ? <CircularProgress /> :
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="column"
          style={{minHeight: '90vh', marginLeft: '-15px'}}
        >
          <Card className={classes.paper}>
            <div style={{display: 'flex'}}>
            <Grid
              container
              style={{width: '65%', marginLeft: 15, display: 'flex'}}
              justify="space-between"
            >
              <div className={classes.media}>
                <img src={this.props.darkMode ? logoDark : logo} style={{width: '20%', marginTop: '15px', marginLeft: '15px'}}/>
              </div>
              <CardContent>
                <Grid item style={{marginTop: '-15px'}}>
                  <Typography variant="h5" style={{fontSize: '20px'}}>
                    Create A New SmartDash Account
                  </Typography>
                </Grid>
                <Grid item style={{marginTop: '15px'}}>
                  <Typography style={{fontSize: '13px', color: '#727272'}} variant="h6">
                    General Information:
                  </Typography>
                </Grid>
                <Grid item style={{marginTop: '10px'}}>
                  <StyledTextField
                    id="fname"
                    size="small"
                    name="fname"
                    variant="outlined"
                    label="First Name"
                    required
                    style={{width: '49%'}}
                    onBlur={this.handleNameValidation}
                  />
                  <StyledTextField
                    id="lname"
                    size="small"
                    name="lname"
                    variant="outlined"
                    label="Last Name"
                    required
                    style={{marginLeft: '11px', width: '49%'}}
                    onBlur={this.handleNameValidation}
                  />
                </Grid>
                <Grid item style={{marginTop: '20px'}}>
                  <StyledTextField
                    id="email"
                    size="small"
                    name="email"
                    type="email"
                    variant="outlined"
                    label="Email Address"
                    style={{width: '63%'}}
                    onBlur={this.handleEmailValidation}
                  />
                  <StyledTextField
                    id="tel"
                    size="small"
                    name="tel"
                    type="tel"
                    variant="outlined"
                    label="Phone Number"
                    style={{marginLeft: '11px', width: '35%'}}
                    onChange={this.handlePhoneFormat}
                  />
                </Grid>

                <Grid item style={{marginTop: '20px'}}>
                  <StyledTextField
                    id="company"
                    size="small"
                    name="company"
                    variant="outlined"
                    label="Company"
                    required
                    style={{width: '45%'}}
                    onClick={(e) => this.handleMenuOpen(e)}
                    value={this.state.value}
                    InputProps={
                      this.state.open
                      ? { endAdornment: <InputAdornment position="end"><ArrowDropUpIcon fontSize="small" /></InputAdornment> }
                      : { endAdornment: <InputAdornment position="end"><ArrowDropDownIcon fontSize="small" /></InputAdornment> }
                    }
                  />

                    <StyledMenu
                      anchorEl={this.state.anchor}
                      keepMounted
                      open={this.state.open}
                      onClose={this.handleMenuClose}
                    >
                      <MenuItem onClick={e => this.handleSelectComp(e)} data-my-value={'--Select A Company--'}>--Select A Company--</MenuItem>
                      {this.state.companyList.map(row => (
                        <MenuItem key={row.Key} onClick={e => this.handleSelectComp(e)} data-my-value={row.company}>{row.company}</MenuItem>
                      ))}
                    </StyledMenu>
                  <StyledTextField
                    id="dbO"
                    size="small"
                    name="dbO"
                    variant="outlined"
                    label="db Owner"
                    required
                    value={this.state.dbO}
                    style={{marginLeft: '11px', width: '35%'}}
                  />

                  <StyledTextField
                    id="corpID"
                    size="small"
                    name="corpID"
                    variant="outlined"
                    label="CorpID"
                    required
                    value={this.state.corpID}
                    style={{marginLeft: '11px', width: '16%'}}
                  />
                </Grid>
                <br />
                <br />
                <Divider />
                <br />
                <Grid item style={{marginTop: '5px'}}>
                  <Typography style={{fontSize: '13px', color: '#727272'}} variant="h6">
                    Account Information:
                  </Typography>
                </Grid>
                <Grid item style={{marginTop: '10px'}}>
                  <StyledTextField
                    id="username"
                    size="small"
                    name="username"
                    variant="outlined"
                    label="Username"
                    required
                    style={{width: '100%'}}
                    onBlur={this.handleNameValidation}
                  />
                </Grid>
                <Grid item style={{marginTop: '20px'}}>
                  <StyledTextField
                    id="password"
                    size="small"
                    name="password"
                    variant="outlined"
                    label="Password"
                    required
                    type="password"
                    style={{width: '49%'}}
                    onBlur={this.handlePasswordValidation}
                  />
                  <StyledTextField
                    id="confirmPassword"
                    size="small"
                    name="confirm"
                    variant="outlined"
                    label="Confirm Password"
                    required
                    type="password"
                    style={{marginLeft: '11px', width: '49%'}}
                    onBlur={this.handlePasswordValidation}
                  />
                </Grid>

                <Grid item style={{marginTop: '5px'}}>
                  <FormControl component="div" style={{marginLeft: '1px'}}>
                    <div>
                      <Typography style={{marginTop: '6px', marginRight: '15px'}} variant="caption">Admin Level</Typography>
                      <FormControlLabel
                        value={0}
                        control={
                          <CustomCheckbox
                            id="user-level-0"
                            checked={this.state.adminLevel === 0}
                            onChange={this.handleSelectAdmin}
                            className={classes.checkbox}
                            icon={<RadioButtonUncheckedIcon className={classes.checkIcon} />}
                            checkedIcon={<RadioButtonCheckedIcon className={classes.checkIcon} />}
                            size="small"
                            disableRipple
                          />
                        }
                        label={<Typography variant="caption">0</Typography>}
                      />
                      <FormControlLabel
                        value={1}
                        control={
                          <CustomCheckbox
                            id="user-level-1"
                            checked={this.state.adminLevel === 1}
                            onChange={this.handleSelectAdmin}
                            className={classes.checkbox}
                            icon={<RadioButtonUncheckedIcon className={classes.checkIcon} />}
                            checkedIcon={<RadioButtonCheckedIcon className={classes.checkIcon} />}
                            size="small"
                            disableRipple
                          />
                        }
                        label={<Typography variant="caption">1</Typography>}
                      />
                      <FormControlLabel
                        value={2}
                        control={
                          <CustomCheckbox
                            id="user-level-2"
                            checked={this.state.adminLevel === 2}
                            onChange={this.handleSelectAdmin}
                            className={classes.checkbox}
                            icon={<RadioButtonUncheckedIcon className={classes.checkIcon} />}
                            checkedIcon={<RadioButtonCheckedIcon className={classes.checkIcon} />}
                            size="small"
                            disableRipple
                          />
                        }
                        label={<Typography variant="caption">2</Typography>}
                      />
                      <FormControlLabel
                        value={3}
                        control={
                          <CustomCheckbox
                            id="user-level-3"
                            checked={this.state.adminLevel === 3}
                            onChange={this.handleSelectAdmin}
                            className={classes.checkbox}
                            icon={<RadioButtonUncheckedIcon className={classes.checkIcon} />}
                            checkedIcon={<RadioButtonCheckedIcon className={classes.checkIcon} />}
                            size="small"
                            disableRipple
                          />
                        }
                        label={<Typography variant="caption">3</Typography>}
                      />
                    </div>
                  </FormControl>


                  <FormControl component="div" style={{marginLeft: '13px'}}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          className={classes.checkbox}
                          size="small"
                          disableRipple
                          onChange={this.handleDataExport}
                          icon={<RadioButtonUncheckedIcon className={classes.checkIcon}/>}
                          checkedIcon={<RadioButtonCheckedIcon className={classes.checkIcon}/>}
                        />
                      }
                      label={<Typography variant="caption" >Data Export</Typography>}
                      labelPlacement="start"
                    />
                  </FormControl>
                </Grid>
                <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '30px'}}>
                  <div>
                    <Typography display="block" variant="caption" style={{fontSize: '11px', color: '#999999'}}>• A db Owner and CorpID will be filled upon the selection of a Company.</Typography>
                    <Typography display="block" variant="caption" style={{fontSize: '11px', color: '#999999'}}>• Fields marked with a <span style={{fontSize: "15px", verticalAlign: 'sub'}}> * </span> are required.</Typography>
                  </div>
                  <SubmitButton onClick={this.handleCheckUserSubmit} variant="outlined">Add User</SubmitButton>
                </div>
              </CardContent>
            </Grid>
            <div className={classes.secondColumn}>
              <img src={graphic} style={{width: '95%', marginTop: '10vh'}} />
              <div style={{width: '90%', textAlign: 'justify', marginTop: '15px'}}>
                <ul>
                  <li>
                    <Typography variant="caption" display="block">Usernames must be 6 characters or more</Typography>
                  </li>
                  <li>
                    <Typography variant="caption" display="block">Passwords must be 8 characters or more and must include a Capital letter and a number</Typography>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          </Card>
        </Grid>
        }
      </div>
    )
  }
}

export default withStyles(styles)(CreateNewUser)
