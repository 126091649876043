import React, { Component } from 'react'
import clsx from 'clsx'
import {
  withStyles,
  Typography,
  Link,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TablePagination,
  MenuItem,
  Drawer,
  IconButton,
  Button,
  Divider,
  Select,
  AppBar,
  Tabs,
  Tab,
  Box,
  Paper,
  Modal,
  Fade,
  Backdrop,
  ButtonBase,
  CircularProgress,
  Checkbox,
  Chip,
} from '@material-ui/core'
import BarChart from '../charts/BarChart'
import CloseIcon from '@material-ui/icons/Close'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import LyTentpole from './LyTentpoleROI'
import ComparePanel from './PromoPanel'
import TabGroup from './TentpoleRoiTabGroup'
import RoiTable from './RoiTable'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import noImage from '../../assets/noImage.png'
import TentpoleRoiExcel from './TentpoleRoiExcel'
import Cookies from 'js-cookie'



function TabPanel(props) {
  const { children, value, index, ...other } = props

  return(
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  )
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  }
}

const styles = theme => ({
  select: {
    width: 150
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    overflowX: 'scroll',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: '50%',
    marginLeft: 5,
  },
  secondDrawer: {
    width: '50%',
    flexShrink: 0,
  },
  secondDrawerPaper: {
    width: '50%',
  },
  secondDrawerHeader: {
    display: 'flex',
    alignItems: 'right',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  hide: {
    display: 'none'
  },
  chartContent: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  chartShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: '-30%',
    display: 'block',
    justifyContent: 'center'
  },
  link: {
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'none',
    }
  },
  linkDark: {
    color: '#4ab8bb',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'none',
      color: '#6fd7da'
    }
  },
  closeButton: {
    marginTop: '10px',
    marginLeft: '15px',
    '&:hover': {
      color: '#eb838c',
      backgroundColor: 'transparent',
      border: '1px solid #eb838c'
    }
  },
  select: {
    color: 'rgba(115, 115, 115, 0.9)',
    marginLeft: '15px',
    marginTop: '15px',
    fontSize: '13px',
    width: 150,
    '&:active': {
      color: '#000'
    }
  },
  selectDark: {
    color: 'rgba(255, 255, 255, 0.9)',
    marginLeft: '10px',
    marginTop: '15px',
    fontSize: '13px',
    width: 150,
    '&:active': {
      color: '#fff'
    }
  },
  indicator: {
    backgroundColor: '#00bcd4',
    width: '25%',
  },
  openChart: {
    width: 650,
  },
  chart: {
    width: 700,
    [theme.breakpoints.up('xl')]: {
      width: 900,
    }
  },
  modal: {
    display: 'flex',
    // textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalImg: {
    maxWidth: '95%',
    maxHeight: '95%'
  },
  chip: {
    margin: 1,
    height: '20px',
    padding: '0px 2px 0px 2px',
    border: '1px solid #636768',
    fontSize: '10px',
    backgroundColor: 'rgba(231, 231, 231, 0.6)',
  },
  chips: {
    display: 'flex',
  }
})

class TentpoleDrawer extends Component {
  constructor(props) {
    super(props)
    // console.log(props)
    this.state = {
      rowsPerPage: 10,
      page: 0,
      selectedMetric: 'Market',
      lyDrawerOpen: false,
      dateExtended: 'Extended Date',
      metricType: 'Location',
      location: ['All'],
      tab: 0,
      modalOpen: false,
      modalImg: '',
      secondModalImg: '',
      scrollY: 0,
      locationData: [],
      locationsList: [],
      rfmData: [],
      testGroupData: [],
      roiData: [],
      defaultRoiData: [],
      marketData: [],
      rfmSegments: [],
      defaultRfmSegements: [],
      testGroups: [],
      selectedRfm: ['All'],
      selectedTestGroup: ['All'],
      isLoading: true,
      checked: [],
      rfmChecked: [],
      testGroupChecked: [],
    }

    this.handleSecondaryOpen = this.handleSecondaryOpen.bind(this)
    this.handleSecondaryClose = this.handleSecondaryClose.bind(this)
    this.handleDrawerClose = this.handleDrawerClose.bind(this)
    this.handleModalOpen = this.handleModalOpen.bind(this)
    this.handleModalClose = this.handleModalClose.bind(this)
  }

  componentDidMount = () => {
    // window.addEventListener('scroll', this.handleScroll, true)
    const myHeaders = {'token': Cookies.get('token')}
    Promise.all([
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID/${this.props.jobID}/market`, {headers: myHeaders}),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID/${this.props.jobID}/location`, {headers: myHeaders}),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID/${this.props.jobID}/rfm`, {headers: myHeaders}),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID/${this.props.jobID}/test`, {headers: myHeaders}),
    ])
    .then(([response, response2, response3, response4]) => Promise.all([response.json(), response2.json(), response3.json(), response4.json()]))
    .then(([data, data2, data3, data4]) => {
      // console.log(data2)
      this.setState({
        isLoading: false,
        openRight: true,
        roiLoading: false,
        openBottom: true ? false : true,
        roiData: data2,
        defaultRoiData: data2,
        checked: data2.map(item => item.Label),
        marketData: data,
        locationData: data2,
        locationsList: data2,
        allLocations: data2.map(item => item.Label),
        rfmSegments: data3.map(item => item.Label),
        defaultRfmSegements: data.map(item => item.Label),
        rfmData: data3,
        rfmChecked: data3.map(item => item.Label),
        testGroups: data4.map(item => item.Label),
        testGroupData: data4,
        testGroupChecked: data4.map(item => item.Label),
        job: this.props.jobID,
      })
    })
    .catch(error => {
      console.log(error)
    })
  }

  componentWillUnmount = () => {
    // window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = e => {
    let scrollPosition = e.target.scrollTop

    this.setState({
      scrollY: scrollPosition
    })
  }

  handleTabChange = (e, newTab) => {
    this.setState({
      tab: newTab
    })
  }

  handleChangePage = (e, newPage) => {
    this.setState({
      page: newPage
    })
  }

  handleChangeRowsPerPage = e => {
    this.setState({
      rowsPerPage: parseInt(e.target.value, 10),
      page: 0
    })
  }

  handleDrawerClose(e) {
    this.props.handleDrawerClose(e)
  }

  handleSecondaryOpen() {
    const myHeaders = {'token': Cookies.get('token')}
    Promise.all([
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID/${this.props.jobID}/market`, {headers: myHeaders}),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID/${this.props.jobID}/location`, {headers: myHeaders}),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID/${this.props.jobID}/rfm`, {headers: myHeaders}),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID/${this.props.jobID}/test`, {headers: myHeaders}),
    ])
    .then(([response, response2, response3, response4]) => Promise.all([response.json(), response2.json(), response3.json(), response4.json()]))
    .then(([data, data2, data3, data4]) => {
      this.setState({
        isLoading: false,
        openRight: true,
        roiLoading: false,
        openBottom: true ? false : true,
        roiData: data2,
        defaultRoiData: data2,
        marketData: data,
        locationData: data2,
        locationsList: data2,
        rfmSegments: data3.map(item => item.Label),
        rfmData: data3,
        testGroups: data4.map(item => item.Label),
        testGroupData: data4,
        job: this.props.jobID,
        selectedRfm: ['All'],
        selectedTestGroup: ['All'],
        location: ['All'],
        tab: 0,
      })
    })
    .catch(error => {
      console.log(error)
    })

    this.setState({
      lyDrawerOpen: true,
    })
  }

  handleSecondaryClose() {
    this.setState({
      lyDrawerOpen: false,
    })
  }

  handleDateExtendedChange = e => {
    this.setState({
      dateExtended: e.target.value
    })
  }

  handleMetricChange = e => {
    let rfm = !this.state.selectedRfm.includes('All') ? this.state.selectedRfm.join('|') : ''
    let test = !this.state.selectedTestGroup.includes('All') ? this.state.selectedTestGroup.join('|') : ''

    Promise.all([
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID/${this.props.jobID}/${e.target.value.toLowerCase()}?list_rfm=${rfm}&list_test=${test}`, {
        headers: {
          token: Cookies.get('token')
        }
      }),
      fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID/${this.props.jobID}/market?list_rfm=${rfm}&list_test=${test}`, {
        headers: {
          token: Cookies.get('token')
        }
      }),
    ])
    .then(([response, response2]) => Promise.all([response.json(), response2.json()]))
    .then(([data, data2]) => {
      this.setState({
        roiData: data,
        metricType: e.target.value,
        location: ['All'],
        locationsList: data,
        checked: data.map(item => item.Label),
        defaultRoiData: data,
        marketData: data2,

      })
    })
    .catch(error => {
      console.log(error)
    })
  }

  handleLocationChange = values => {
    let selectedLocations = ''

    let rfm = !this.state.selectedRfm.includes('All') ? this.state.selectedRfm.join('|') : ''
    let test = !this.state.selectedTestGroup.includes('All') ? this.state.selectedTestGroup.join('|') : ''
    let paramsLoc = this.state.metricType === 'Location' ? 'list_loc' : 'list_mkt'

    if(values.length > 1 && this.state.location.includes('All')
      || values.length >= 1 && values.includes('All') === false
    ) {
      if(values.filter(item => item !== 'All').length < 2) {
        selectedLocations = values.filter(item => item !== 'All')[0]
      } else {
        selectedLocations = values.filter(item => item !== 'All').join('|')
      }

      Promise.all([
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID/${this.props.jobID}/${this.state.metricType.toLowerCase()}?${paramsLoc}=${selectedLocations}&list_rfm=${rfm}&list_test=${test}`, {
          headers: {
            token: Cookies.get('token')
          }
        }),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID/${this.props.jobID}/rfm?${paramsLoc}=${selectedLocations}&list_test=${test}`, {
          headers: {
            token: Cookies.get('token')
          }
        }),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID/${this.props.jobID}/test?${paramsLoc}=${selectedLocations}&list_rfm=${rfm}`, {
          headers: {
            token: Cookies.get('token')
          }
        }),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID/${this.props.jobID}/market?${paramsLoc}=${selectedLocations}&list_rfm=${rfm}&list_test=${test}`, {
          headers: {
            token: Cookies.get('token')
          }
        }),
      ])
      .then(([response, response2, response3, response4]) => Promise.all([response.json(), response2.json(), response3.json(), response4.json()]))
      .then(([data, data2, data3, data4]) => {
        this.setState({
          roiData: data,
          location: values.filter(item => item !== 'All'),
          checked: values.filter(item => item !== 'All'),
          tab: values.filter(item => item !== 'All').length > 0 && !this.state.selectedRfm.includes('All') ? 2 : values.filter(item => item !== 'All').length > 0 && this.state.selectedRfm.includes('All') ? 1 : 0,
          defaultRoiData: data,
          rfmData: data2,
          testGroupData: data3,
          marketData: data4,
        })

      })
      .catch(error => {
        console.log(error)
      })
    } else {
      Promise.all([
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID/${this.props.jobID}/${this.state.metricType.toLowerCase()}?list_rfm=${rfm}&list_test=${test}`, {
          headers: {
            token: Cookies.get('token')
          }
        }),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID/${this.props.jobID}/rfm?list_test=${test}`, {
          headers: {
            token: Cookies.get('token')
          }
        }),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID/${this.props.jobID}/test?list_rfm=${rfm}`, {
          headers: {
            token: Cookies.get('token')
          }
        }),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID/${this.props.jobID}/market?list_rfm=${rfm}&list_test=${test}`, {
          headers: {
            token: Cookies.get('token')
          }
        }),
      ])
      .then(([response, response2, response3, response4]) => Promise.all([response.json(), response2.json(), response3.json(), response4.json()]))
      .then(([data, data2, data3, data4]) => {
        this.setState({
          roiData: data,
          location: ['All'],
          checked: this.state.allLocations,
          tab: 0,
          defaultRoiData: data,
          rfmData: data2,
          testGroupData: data3,
          locationData: data,
          marketData: data4,
        })
      })
    }
  }

  handleRfmChange = values => {
    let selectedRfms = ''
    let locations = !this.state.location.includes('All') ? this.state.location.join('|') : ''
    let test = !this.state.selectedTestGroup.includes('All') ? this.state.selectedTestGroup.join('|') : ''
    let paramsLoc = this.state.metricType === 'Location' ?  'list_loc' : 'list_mkt'

    if(values.length > 1 && this.state.selectedRfm.includes('All')
      || values.length >= 1 && values.includes('All') === false
    ) {
      if(values.filter(item => item !== 'All').length < 2) {
        selectedRfms = values.filter(item => item !== 'All')[0]
      } else {
        selectedRfms = values.filter(item => item !== 'All').join('|')
      }

      Promise.all([
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID/${this.props.jobID}/${this.state.metricType.toLowerCase()}?${paramsLoc}=${locations}&list_rfm=${selectedRfms}&list_test=${test}`, {
          headers: {
            token: Cookies.get('token')
          }
        }),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID/${this.props.jobID}/test?${paramsLoc}=${locations}&list_rfm=${selectedRfms}&list_test=${test}`, {
          headers: {
            token: Cookies.get('token')
          }
        }),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID/${this.props.jobID}/market?${paramsLoc}=${locations}&list_rfm=${selectedRfms}&list_test=${test}`, {
          headers: {
            token: Cookies.get('token')
          }
        }),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID/${this.props.jobID}/location?list_rfm=${selectedRfms}&list_test=${test}`, {
          headers: {
            token: Cookies.get('token')
          }
        }),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID/${this.props.jobID}/rfm?${paramsLoc}=${locations}&list_test=${test}`, {
          headers: {
            token: Cookies.get('token')
          }
        }),
      ])
      .then(([response, response2, response3, response4, response5]) => Promise.all([response.json(), response2.json(), response3.json(), response4.json(), response5.json()]))
      .then(([data, data2, data3, data4, data5]) => {
        console.log(data4)
        this.setState({
          roiData: data,
          selectedRfm: values.filter(item => item !== 'All'),
          rfmChecked: values.filter(item => item !== 'All'),
          defaultRoiData: data,
          locationData: data4,
          locationsList: data4,
          // locationsList: data4.map(item => item !== 'All'),
          testGroupData: data2,
          tab: values.filter(item => item !== 'All').length > 0 && !this.state.location.includes('All') ? 2 : values.filter(item => item !== 'All').length > 0 && this.state.location.includes('All') ? 0 : 0,
          marketData: data3
        })
      })
      .catch(error => {
        console.log(error)
      })
    } else {
      Promise.all([
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID/${this.props.jobID}/${this.state.metricType.toLowerCase()}?${paramsLoc}=${locations}&list_test=${test}`, {
          headers: {
            token: Cookies.get('token')
          }
        }),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID/${this.props.jobID}/test?${paramsLoc}=${locations}&list_test=${test}`, {
          headers: {
            token: Cookies.get('token')
          }
        }),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID/${this.props.jobID}/market?${paramsLoc}=${locations}&list_test=${test}`, {
          headers: {
            token: Cookies.get('token')
          }
        }),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID/${this.props.jobID}/location?list_test=${test}`, {
          headers: {
            token: Cookies.get('token')
          }
        }),
      ])
      .then(([response, response2, response3, response4]) => Promise.all([response.json(), response2.json(), response3.json(), response4.json()]))
      .then(([data, data2, data3, data4]) => {
        console.log(data4.filter(item => this.state.checked.includes(item.Label)))
        this.setState({
          roiData: data,
          selectedRfm: ['All'],
          rfmChecked: this.state.rfmSegments,
          defaultRoiData: data,
          locationData: data,
          locationsList: data4,

          testGroupData: data2,
          tab: !this.state.location.includes('All') ? 1 : 0,
          // tab: values.filter(item => item !== 'All').length > 0 && !this.state.location.includes('All') ? 2 : values.filter(item => item !== 'All').length > 0 && this.state.location.includes('All') ? 0 : values.filter(item => item !== 'All') < 1 && !this.state.location.includes('All') ? 1 : 0,
          marketData: data3
        })
      })
      .catch(error => {
        console.log(error)
      })
    }

  }

  handleTestChange = values => {
    let selectedTest = ''
    let rfm = !this.state.selectedRfm.includes('All') ? this.state.selectedRfm.join('|') : ''
    let locations = !this.state.location.includes('All') ? this.state.location.join('|') : ''
    let paramsLoc = this.state.metricType === 'Location' ? 'list_loc' : 'list_mkt'

    if(values.length > 1 && this.state.selectedTestGroup.includes('All')
      || values.length >= 1 && values.includes('All') === false
    ) {
      if(values.filter(item => item !== 'All').length < 2) {
        selectedTest = values.filter(item => item !== 'All')[0]
      } else {
        selectedTest = values.filter(item => item !== 'All').join('|')
      }

      Promise.all([
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID/${this.props.jobID}/${this.state.metricType.toLowerCase()}?${paramsLoc}=${locations}&list_rfm=${rfm}&list_test=${selectedTest}`, {
          headers: {
            token: Cookies.get('token')
          }
        }),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID/${this.props.jobID}/rfm?${paramsLoc}=${locations}&list_rfm=${rfm}&list_test=${selectedTest}`, {
          headers: {
            token: Cookies.get('token')
          }
        }),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID/${this.props.jobID}/market?${paramsLoc}=${locations}&list_rfm=${rfm}&list_test=${selectedTest}`, {
          headers: {
            token: Cookies.get('token')
          }
        }),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID/${this.props.jobID}/location?list_rfm=${rfm}&list_test=${selectedTest}`, {
          headers: {
            token: Cookies.get('token')
          }
        }),
      ])
      .then(([response, response2, response3, response4]) => Promise.all([response.json(), response2.json(), response3.json(), response4.json()]))
      .then(([data, data2, data3, data4]) => {
        this.setState({
          roiData: data,
          selectedTestGroup: values.filter(item => item !== 'All'),
          testGroupChecked: values.filter(item => item !== 'All'),
          defaultRoiData: data,
          locationData: data4,
          rfmData: data2,
          tab: values.filter(item => item !== 'All').length > 0 && !this.state.location.includes('All') ? 1 : values.filter(item => item !== 'All').length > 0 && this.state.location.includes('All') ? 0 : 0,
          marketData: data3
        })
      })
      .catch(error => {
        console.log(error)
      })
    } else {
      Promise.all([
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID/${this.props.jobID}/${this.state.metricType.toLowerCase()}?${paramsLoc}=${locations}&list_rfm=${rfm}`, {
          headers: {
            token: Cookies.get('token')
          }
        }),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID/${this.props.jobID}/rfm?${paramsLoc}=${locations}&list_rfm=${rfm}`, {
          headers: {
            token: Cookies.get('token')
          }
        }),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID/${this.props.jobID}/market?${paramsLoc}=${locations}&list_rfm=${rfm}`, {
          headers: {
            token: Cookies.get('token')
          }
        }),
        fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID/${this.props.jobID}/location`, {
          headers: {
            token: Cookies.get('token')
          }
        }),
      ])
      .then(([response, response2, response3, response4]) => Promise.all([response.json(), response2.json(), response3.json(), response4.json()]))
      .then(([data, data2, data3, data4]) => {
        // console.log(this.state.rfmSegments)
        this.setState({
          roiData: data,
          selectedTestGroup: ['All'],
          testGroupChecked: this.state.testGroups,
          defaultRoiData: data,
          locationData: data4,
          rfmData: data2,
          // tab: !this.state.location.includes('All') ? 1 : 0,
          // tab: values.filter(item => item !== 'All').length > 0 && !this.state.location.includes('All') ? 2 : values.filter(item => item !== 'All').length > 0 && this.state.location.includes('All') ? 0 : values.filter(item => item !== 'All') < 1 && !this.state.location.includes('All') ? 1 : 0,
          marketData: data3
        })
      })
      .catch(error => {
        console.log(error)
      })
      // fetch(`https://api.dev.lsdirect.com/smartdash/V1/index.cfm/tentpoleROI/jobID/${this.props.jobID}/${this.state.metricType.toLowerCase()}?${paramsLoc}=${locations}&list_rfm=${rfm}&list_test=${test}`, {
      //   headers: {
      //     token: Cookies.get('token')
      //   }
      // })
      // .then(response => response.json())
      // .then(data => {
      //   this.setState({
      //     roiData: data,
      //     selectedTestGroup: values,
      //     defaultRoiData: data,
      //     tab: 0,
      //   })
      // })
      // .catch(error => {
      //   console.log(error)
      // })
    }
  }

  handleModalOpen(e) {
    this.setState({
      modalOpen: true,
      modalImg: e.target.src,
    })
  }

  handleModalClose() {
    this.setState({
      modalOpen: false,
      modalImg: ''
    })
  }

  renderLocationOptions = () => {
    // console.log(this.state.locationData)
    // console.log(this.state.locationData.length)
    console.log(this.state.locationsList)
    if(this.state.metricType === 'Location') {
      return (
        this.state.locationsList.map(item => {
          return (
            <MenuItem key={item.Key} value={item.Label}>
              <div style={{display: 'inline-flex', alignItems: 'center'}}>
                <Checkbox
                  edge="start"
                  size="small"
                  disableRipple
                  checked={this.state.checked.indexOf(item.Label) !== -1}
                />
                <Typography variant="caption">
                  {item.Label}
                </Typography>
              </div>
            </MenuItem>
          )
        })
      )
    } else {
      return (
        this.state.marketData.map(item => {
          return (
            <MenuItem key={item.Key} value={item.Label}>
              <div style={{display: 'inline-flex', alignItems: 'center'}}>
                <Checkbox
                  edge="start"
                  size="small"
                  disableRipple
                  checked={this.state.checked.indexOf(item.Label) !== -1}
                />
                <Typography variant="caption">
                  {item.Label}
                </Typography>
              </div>
            </MenuItem>
          )
        })
      )
    }
  }

  getData = (data, extended) => {
    if(extended === 'Extended Date') {
      let dataExtended = []
      for(let i = 0; i < data.length; i++) {
        dataExtended.push(data[i].Data_Extended[0])
      }
      const total = [dataExtended.reduce((a,b) => {
        for(const prop in b) {
          if(a.hasOwnProperty(prop) && (prop !== 'MaxWrittenDate') && (prop !== 'PromoEndDate') && (prop !== 'PromoStartDate')) a[prop] += b[prop]
          else a[prop] = b[prop]
        }
        return a
      }, {})]
      total[0].Res_Rate = total[0].Mailed > 0 ? total[0].Responses / total[0].Mailed : 0
      total[0].HO_Res_Rate = total[0].HO_Count > 0 ? total[0].HO_Resp / total[0].HO_Count : 0
      return total
    } else {
      let dataPromo = []
      for(let i = 0; i < data.length; i++) {
        dataPromo.push(data[i].Data_Promo[0])
      }
      const total = [dataPromo.reduce((a,b) => {
        for(const prop in b) {
          if(a.hasOwnProperty(prop) && (prop !== 'MaxWrittenDate') && (prop !== 'PromoEndDate') && (prop !== 'PromoStartDate')) a[prop] += b[prop]
          else a[prop] = b[prop]
        }
        return a
      }, {})]
      total[0].Res_Rate = total[0].Mailed > 0 ? total[0].Responses / total[0].Mailed : 0
      total[0].HO_Res_Rate = total[0].HO_Count > 0 ? total[0].HO_Resp / total[0].HO_Count : 0
      return total
    }
  }

  getChartData = (data, extended) => {
    if(extended === 'Extended Date') {
      let dataExtendedSales = []
      let dataExtendedTotalSales = []

      for(let i = 0; i < data.length; i++) {
        dataExtendedSales.push(data[i].Data_Extended[0].Sales)
        dataExtendedTotalSales.push(data[i].Data_Extended[0].Total_Sales)
      }
      let dataExtended = [
        {
          name: 'Sales',
          data: dataExtendedSales.map(item => Math.round(item))
        },
        {
          name: 'Total Sales',
          data: dataExtendedTotalSales.map(item => Math.round(item))
        }
      ]

      return dataExtended
    } else {
      let dataPromoSales = []
      let dataPromoTotalSales = []
      for(let i = 0; i < data.length; i++) {
        dataPromoSales.push(data[i].Data_Promo[0].Sales)
        dataPromoTotalSales.push(data[i].Data_Promo[0].Total_Sales)
      }
      let dataPromo = [
        {
          name: 'Sales',
          data: dataPromoSales.map(item => Math.round(item))
        },
        {
          name: 'Total Sales',
          data: dataPromoTotalSales.map(item => Math.round(item))
        }
      ]
      return dataPromo
    }

  }

  render() {
    const { classes } = this.props
    const roiData = this.getData(this.state.roiData, this.state.dateExtended)
    const rfmData = this.getData(this.state.rfmData, this.state.dateExtended)
    const testGroupData = this.getData(this.state.testGroupData, this.state.dateExtended)
    const chartData = this.getChartData(this.state.marketData, this.state.dateExtended)


    return(
      <div id="roi">
        {this.state.isLoading ? <CircularProgress /> :
        <main
          className={clsx(classes.content, {[classes.contentShift]: this.state.lyDrawerOpen,})}
        >
          <div>
            <Modal
              className={classes.modal}
              open={this.state.modalOpen}
              onClose={this.handleModalClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500
              }}
            >
              <Fade in={this.state.modalOpen}>
                <img className={classes.modalImg} src={this.state.modalImg} alt="" />
              </Fade>
            </Modal>

            <Button variant="outlined" disableRipple size="small" onClick={this.handleDrawerClose} className={classes.closeButton}>
              <ArrowBackIcon fontSize="small" />
              <Typography style={{marginLeft: '10px'}} variant="caption">GO BACK</Typography>
            </Button>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <div style={this.state.lyDrawerOpen ? {display: 'none'} : {display: 'flex'}}>
                <Select
                  value={this.state.dateExtended}
                  inputProps={{
                    name: 'date'
                  }}
                  onChange={this.handleDateExtendedChange}
                  className={classes.select}
                >
                  <MenuItem value={'Extended Date'}>Extended Date</MenuItem>
                  <MenuItem value={'Promo Date'}>Promo Date</MenuItem>
                </Select>
                <Select
                  value={this.state.metricType}
                  onChange={this.handleMetricChange}
                  className={classes.select}

                >
                  <MenuItem value={'Location'}>Location</MenuItem>
                  <MenuItem value={'Market'}>Market</MenuItem>
                </Select>
                <Select
                  multiple
                  value={this.state.location}
                  onChange={e => this.handleLocationChange(e.target.value)}
                  className={classes.select}
                  renderValue={selected => (
                    selected.length > 1 ?
                    <div className={classes.chips}>
                      <Typography variant="caption">{`${selected.length} ${(this.state.metricType).toLowerCase()}s selected`}</Typography>
                    </div>
                    :
                    <div style={{padding: '5px 0px 0px 20px'}}>
                      {selected.map(value => (
                        <Typography key={value} variant="caption">{value === 'All' ? `${value} ${this.state.metricType}s` : value}</Typography>
                      ))}
                    </div>
                  )}
                >
                  <MenuItem value={'All'}>
                    <Checkbox edge="start" size="small" disableRipple checked={this.state.location.includes('All')}/>
                    <Typography variant="caption">All {this.state.metricType}s</Typography>
                  </MenuItem>
                  {this.renderLocationOptions()}
                </Select>
                <Select
                  multiple
                  value={this.state.selectedRfm}
                  onChange={e => this.handleRfmChange(e.target.value)}
                  className={classes.select}
                  renderValue={selected => (
                    selected.length > 1 ?
                    <div>
                      <Typography variant="caption">{`${selected.length} selected RFMs`}</Typography>
                    </div>
                    :
                    <div style={{padding: '5px 0px 0px 20px'}}>
                      {selected.map(value => (
                        <Typography key={value} variant="caption">{value === 'All' ? `${value} RFMs` : value}</Typography>
                      ))}
                    </div>
                  )}
                >
                  <MenuItem value={'All'}>
                    <Checkbox edge="start" size="small" disableRipple checked={this.state.selectedRfm.includes('All')} />
                    <Typography variant="caption">All RFMs</Typography>
                  </MenuItem>
                    {this.state.rfmData.map(item => (
                      <MenuItem key={item.Key} value={item.Label}>
                        <Checkbox edge="start" size="small" disableRipple checked={this.state.rfmChecked.indexOf(item.Label) !== -1} />
                        <Typography variant="caption">{(item.Label).toUpperCase()}</Typography>
                      </MenuItem>
                    ))}
                </Select>
                <Select
                  multiple
                  value={this.state.selectedTestGroup}
                  onChange={e => this.handleTestChange(e.target.value)}
                  className={classes.select}
                  renderValue={selected => (
                    selected.length > 1 ?
                      <div>
                        <Typography variant="caption">{`${selected.length} test groups`}</Typography>
                      </div>
                    :
                      <div style={{padding: '5px 0pxx 0px 20px'}}>
                        {selected.map(value => (
                          <Typography key={value} variant="caption">{value === 'All' ? `${value} Test Groups` : value}</Typography>
                        ))}
                      </div>
                  )}
                >
                  <MenuItem value={'All'}>
                    <Checkbox edge="start" size="small" disableRipple checked={this.state.selectedTestGroup.includes('All')} />
                    <Typography variant="caption">ALL TEST GROUPS</Typography>
                  </MenuItem>
                  {this.state.testGroupData.map(item => (
                    // this.state.testGroups.map(item => (
                    <MenuItem key={item.Key} value={item.Label}>
                      <Checkbox edge="start" size="small" disableRipple checked={this.state.testGroupChecked.indexOf(item.Label) !== -1} />
                      <Typography variant="caption">{(item.Label).toUpperCase()}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div style={{textAlign: 'right'}}>
                <Typography
                  style={{fontSize: '13px', textAlign: 'right', marginRight: '15px', marginTop: '25px'}}
                  className={clsx(this.state.lyDrawerOpen && classes.hide)}
                >
                  <Link className={this.props.darkMode ? classes.linkDark : classes.link} onClick={this.handleSecondaryOpen}>
                    COMPARE TO ANOTHER PROMOTION
                  </Link>
                </Typography>
                <div style={this.state.lyDrawerOpen ? {marginLeft: '7px'} : {marginRight: '5px'}}>
                  <TentpoleRoiExcel
                    promoName={this.props.data[0].Promotion.replace(/[\u0026]/, 'and')}
                    selectedType={this.state.metricType}
                    markets={this.state.location}
                    selectedRfm={this.state.selectedRfm}
                    selectedTestGroups={this.state.selectedTestGroup}
                    format={this.props.data[0].Format_Desc}
                    roiData={roiData}
                    locationData={this.state.location === 'All' ? this.state.locationData : []}
                    rfmData={this.state.selectedRfm === 'All' ? this.state.rfmData : []}
                    testData={this.state.selectedTestGroup === 'All' ? this.state.testGroupData : []}
                    dateExtended={this.state.dateExtended}
                  />
                </div>
              </div>
            </div>
          </div>
          <Divider style={{marginTop: '20px'}} />
          <Table
            className={classes.table}
            size="small"
          >
            <TableHead>
              <TableRow style={{height: '60px'}}>
                <TableCell style={{fontWeight: 'bold'}}>PROMOTION</TableCell>
                <TableCell style={{fontWeight: 'bold'}} align="right">REPORT START</TableCell>
                <TableCell style={{fontWeight: 'bold'}} align="right">REPORT END</TableCell>
                <TableCell style={{fontWeight: 'bold'}} align="right">FORMAT</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow style={{height: '55px'}}>
                <TableCell>{this.props.data[0].Promotion}</TableCell>
                <TableCell align="right">
                  {roiData[0].PromoStartDate}
                </TableCell>
                <TableCell align="right">
                  {roiData[0].PromoEndDate}
                </TableCell>
                <TableCell align="right">{this.props.data[0].Format_Desc}</TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <Table
            className={classes.table}
            size="small"
          >
            <TableHead>
              <TableRow>
                <TableCell style={{fontWeight: 'bold', fontSize: '10px'}}>Status</TableCell>
                <TableCell className={this.state.lyDrawerOpen ? classes.hide : ''} style={{fontWeight: 'bold', fontSize: '10px', color: '#cc0000'}} align="right">Spend</TableCell>
                <TableCell style={{fontWeight: 'bold', fontSize: '10px', color: '#b045ff'}} align="right">Mailed</TableCell>
                <TableCell style={{fontWeight: 'bold', fontSize: '10px', color: '#006cc4'}} align="right">Responses</TableCell>
                <TableCell style={{fontWeight: 'bold', fontSize: '10px', color: '#529c00'}} align="right">Sales</TableCell>
                <TableCell style={{fontWeight: 'bold', fontSize: '10px', color: '#6ec6cc'}} align="right">Response Rate</TableCell>
                <TableCell style={{fontWeight: 'bold', fontSize: '10px', color: '#f27272'}} align="right">DM CoA(%)</TableCell>
                <TableCell className={this.state.lyDrawerOpen ? classes.hide : ''} style={{fontWeight: 'bold', fontSize: '10px', color: '#f27272'}} align="right">Cost Per Thousand</TableCell>
                <TableCell className={this.state.lyDrawerOpen ? classes.hide : ''} style={{fontWeight: 'bold', fontSize: '10px', color: '#f27272'}} align="right">Total CoA(%)</TableCell>
                <TableCell className={this.state.lyDrawerOpen ? classes.hide : ''} style={{fontWeight: 'bold', fontSize: '10px', color: '#deb40d'}} align="right">Average Ticket</TableCell>
                <TableCell className={this.state.lyDrawerOpen ? classes.hide : ''} style={{fontWeight: 'bold', fontSize: '10px', color: '#8ebf56'}} align="right">Total Sales</TableCell>
                <TableCell style={{fontWeight: 'bold', fontSize: '10px', color: '#8ebf56'}} align="right">% of Total Sales</TableCell>
                <TableCell style={{fontWeight: 'bold', fontSize: '10px', color: '#de7c0d'}} align="right">Contribution Margin ROI</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow style={this.props.darkMode ? {background: 'rgba(0, 0, 0, 0.1)'} : {background: 'rgba(199, 199, 199, 0.2)'}}>
                <TableCell name="customerLabel" style={{fontSize: '12px'}}>Mailed</TableCell>
                <TableCell className={this.state.lyDrawerOpen ? classes.hide : ''} name="cost" style={{fontSize: '12px'}} align="right">
                  {'$' + Math.round(roiData[0].Cost).toLocaleString('en')}
                </TableCell>
                <TableCell name="mailed" style={{fontSize: '12px'}} align="right">
                  {(roiData[0].Mailed).toLocaleString('en')}
                </TableCell>
                <TableCell name="responses" style={{fontSize: '12px'}} align="right">
                  {(roiData[0].Responses).toLocaleString('en')}
                </TableCell>
                <TableCell name="sales" style={{fontSize: '12px'}} align="right">
                  {'$' + Math.round(roiData[0].Sales).toLocaleString('en')}
                </TableCell>
                <TableCell name="responseRate" style={{fontSize: '12px'}} align="right">
                  {(roiData[0].Res_Rate * 100).toFixed(2) + '%'}
                </TableCell>
                <TableCell name="dMCoA" style={{fontSize: '12px'}} align="right">
                  {roiData[0].Sales > 0 ? (roiData[0].Cost / roiData[0].Sales * 100).toFixed(2) + '%' : '0.00%'}
                </TableCell>
                <TableCell className={this.state.lyDrawerOpen ? classes.hide : ''} name="costPerThousand" style={{fontSize: '12px'}} align="right">
                  {roiData[0].Mailed > 0 ? '$' + Math.round(roiData[0].Cost / roiData[0].Mailed * 1000).toLocaleString('en') : '$0'}

                </TableCell>
                <TableCell className={this.state.lyDrawerOpen ? classes.hide : ''} name="totalCoA" style={{fontSize: '12px'}} align="right">
                  {roiData[0].Total_Sales > 0 ? (roiData[0].Cost / roiData[0].Total_Sales * 100).toFixed(2) + '%' : '0%'}
                </TableCell>
                <TableCell className={this.state.lyDrawerOpen ? classes.hide : ''} name="avgTicket" style={{fontSize: '12px'}} align="right">
                  {roiData[0].Responses ? '$' + Math.round(roiData[0].Sales / roiData[0].Responses).toLocaleString('en') : '$0'}
                </TableCell>
                <TableCell className={this.state.lyDrawerOpen ? classes.hide : ''} name="totalSales" style={{fontSize: '12px'}} align="right">
                  {`$${Math.round(roiData[0].Total_Sales).toLocaleString('en')}`}
                </TableCell>
                <TableCell name="pctTotalSales" style={{fontSize: '12px'}} align="right">
                  {roiData[0].Total_Sales > 0 ? `${(roiData[0].Sales / roiData[0].Total_Sales * 100).toFixed(2)}%` : '0.00%'}
                </TableCell>
                <TableCell name="contributionRoi" style={{fontSize: '12px'}} align="right">
                  {Boolean(roiData[0].Contribution_Factor) && roiData[0].Cost > 0 ? (((roiData[0].Sales / roiData[0].Cost * roiData[0].Contribution_Factor) - 1) * 100).toFixed(2) + '%' : 'N/A'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{fontSize: '12px'}}>Holdout</TableCell>
                <TableCell className={this.state.lyDrawerOpen ? classes.hide : ''} name="holdoutCOG" style={{fontSize: '12px'}} align="right">{'$' + 0}</TableCell>
                <TableCell name="holdoutCount" style={{fontSize: '12px'}} align="right">
                  {(roiData[0].HO_Count).toLocaleString('en')}
                </TableCell>
                <TableCell name="holdoutResponse" style={{fontSize: '12px'}} align="right">
                  {(roiData[0].HO_Resp).toLocaleString('en')}
                </TableCell>
                <TableCell name="holdoutSales" style={{fontSize: '12px'}} align="right">
                  {`$${Math.round(roiData[0].HO_Sales).toLocaleString('en')}`}
                </TableCell>
                <TableCell name="holdoutRR" style={{fontSize: '12px'}} align="right">
                  {`${(roiData[0].HO_Res_Rate * 100).toFixed(2)}%`}
                </TableCell>
                <TableCell name="holdoutDMCoA" style={{fontSize: '12px'}} align="right">{null}</TableCell>
                <TableCell className={this.state.lyDrawerOpen ? classes.hide : ''} name="holdoutCostPerThou" style={{fontSize: '12px'}} align="right">{null}</TableCell>
                <TableCell className={this.state.lyDrawerOpen ? classes.hide : ''} name="holdoutTotalCoA" style={{fontSize: '12px'}} align="right">{null}</TableCell>
                <TableCell className={this.state.lyDrawerOpen ? classes.hide : ''} name="holdoutAvgTicket" style={{fontSize: '12px'}} align="right">
                  {roiData[0].HO_Resp > 0 ? `$${Math.round(roiData[0].HO_Sales / roiData[0].HO_Resp).toLocaleString('en')}` : '$0'}
                </TableCell>
                <TableCell className={this.state.lyDrawerOpen ? classes.hide : ''} name="holdoutTotalSales" style={{fontSize: '12px'}} align="right">
                  {`$${Math.round(roiData[0].Total_Sales).toLocaleString('en')}`}
                </TableCell>
                <TableCell name="holdoutpctTotalSales" style={{fontSize: '12px'}} align="right">
                  {roiData[0].Total_Sales > 0 ? `${(roiData[0].HO_Sales / roiData[0].Total_Sales * 100).toFixed(2)}%` : '0.00%'}
                </TableCell>
                <TableCell name="holdoutContributionRoi" style={{fontSize: '12px'}} align="right">N/A</TableCell>
              </TableRow>
              <TableRow style={this.props.darkMode ? {background: 'rgba(0, 0, 0, 0.1)'} : {background: 'rgba(199, 199, 199, 0.2)'}}>
                <TableCell style={{fontSize: '12px'}}>Adjusted</TableCell>
                <TableCell className={this.state.lyDrawerOpen ? classes.hide : ''} name="AdjCOG" style={{fontSize: '12px'}} align="right">
                  {`$${Math.round(roiData[0].Cost).toLocaleString('en')}`}
                </TableCell>
                <TableCell name="AdjCount" style={{fontSize: '12px'}} align="right">
                  {`${(roiData[0].Mailed).toLocaleString('en')}`}
                </TableCell>
                <TableCell name="AdjResponse" style={{fontSize: '12px'}} align="right">
                  {`${(roiData[0].Res_Rate - roiData[0].HO_Res_Rate) > 0 ? Math.round(roiData[0].Mailed * (roiData[0].Res_Rate - roiData[0].HO_Res_Rate)).toLocaleString('en') : 0}`}
                </TableCell>
                <TableCell name="AdjSales" style={{fontSize: '12px'}} align="right">
                  {`$${(roiData[0].Res_Rate - roiData[0].HO_Res_Rate) > 0 ? Math.round(roiData[0].Sales / roiData[0].Res_Rate * (roiData[0].Res_Rate - roiData[0].HO_Res_Rate)).toLocaleString('en') : 0}`}
                </TableCell>
                <TableCell name="AdjRR" style={{fontSize: '12px'}} align="right">
                  {`${(roiData[0].Res_Rate - roiData[0].HO_Res_Rate) > 0 ? ((roiData[0].Res_Rate - roiData[0].HO_Res_Rate) * 100).toFixed(2) : 0.00}%`}
                </TableCell>
                <TableCell name="AdjDMCoA" style={{fontSize: '12px'}} align="right">

                  {(roiData[0].Res_Rate - roiData[0].HO_Res_Rate) > 0 ? (roiData[0].Cost / (roiData[0].Sales / roiData[0].Res_Rate * (roiData[0].Res_Rate - roiData[0].HO_Res_Rate)) * 100).toFixed(2) : 0}%

                </TableCell>
                <TableCell className={this.state.lyDrawerOpen ? classes.hide : ''} name="AdjCostPerThou" style={{fontSize: '12px'}} align="right">
                  {roiData[0].Mailed > 0 ? `$${Math.round(roiData[0].Cost / roiData[0].Mailed * 1000)}` : '$0'}
                </TableCell>
                <TableCell className={this.state.lyDrawerOpen ? classes.hide : ''} name="AdjTotalCoA" style={{fontSize: '12px'}} align="right">
                  {roiData[0].Total_Sales > 0 ? `${(roiData[0].Cost / roiData[0].Total_Sales * 100).toFixed(2)}%` : '0.00%' }
                </TableCell>
                <TableCell className={this.state.lyDrawerOpen ? classes.hide : ''} name="AdjAvgTicket" style={{fontSize: '12px'}} align="right">
                  ${(roiData[0].Res_Rate - roiData[0].HO_Res_Rate) > 0 ? Math.round((roiData[0].Sales / roiData[0].Res_Rate * (roiData[0].Res_Rate - roiData[0].HO_Res_Rate)) / (roiData[0].Mailed * (roiData[0].Res_Rate - roiData[0].HO_Res_Rate))).toLocaleString('en') : 0}
                </TableCell>
                <TableCell className={this.state.lyDrawerOpen ? classes.hide : ''} name="AdjTotalSales" style={{fontSize: '12px'}} align="right">
                  {`$${Math.round(roiData[0].Total_Sales).toLocaleString('en')}`}
                </TableCell>
                <TableCell name="AdjpctTotalSales" style={{fontSize: '12px'}} align="right">
                  {(roiData[0].Res_Rate - roiData[0].HO_Res_Rate) > 0 ? ((roiData[0].Sales / roiData[0].Res_Rate * (roiData[0].Res_Rate - roiData[0].HO_Res_Rate)) / roiData[0].Total_Sales * 100).toFixed(2) : 0.00}%
                </TableCell>
                <TableCell name="AdjContributionRoi" style={{fontSize: '12px'}} align="right">
                  {Boolean(roiData[0].Contribution_Factor) && roiData[0].Res_Rate > roiData[0].HO_Res_Rate ? (((((roiData[0].Sales / roiData[0].Res_Rate * (roiData[0].Res_Rate - roiData[0].HO_Res_Rate)) / roiData[0].Cost) * roiData[0].Contribution_Factor) - 1) * 100).toFixed(2) + '%' : 'N/A'}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <br />
          <div
            className={clsx(classes.chartContent, {[classes.chartShift]: this.state.lyDrawerOpen})}
            style={{margin: '25px 25px 0px 25px'}}
          >
            {/* {console.log(this.state.marketData)} */}
            <BarChart
              title={'Sales vs Total Sales by Market'}
              data={chartData}
              labels={this.state.marketData.map(item => item.Label)}
              height={500}
              width={650}
              horizontal={true}
              legend={true}
              position={'bottom'}
              rotate={-45}
              distributed={false}
              darkMode={this.props.darkMode}
            />
            <div style={{textAlign: 'center'}}>
              <div style={{display: 'flex', marginTop: 60}}>
                <ButtonBase
                  onClick={this.handleModalOpen}
                  style={{height: 350, width: 350, overflow: 'hidden', border: '1px solid #626262', textAlign: 'center'}}
                >
                  {this.props.marketData[0].Creative.One ?
                    <img src={this.props.marketData[0].Creative.One} alt="" style={{height: 350}}/> :
                    <img src={noImage} alt="" style={{height: 350}}/>
                  }
                </ButtonBase>
                <div style={{marginLeft: '25px'}} />
                <ButtonBase
                  onClick={this.handleModalOpen}
                  style={
                    {height: 350, width: 350, overflow: 'hidden', border: '1px solid #626262', textAlign: 'center'}
                  }
                >
                  {this.props.marketData[0].Creative.Two ?
                    <img src={this.props.marketData[0].Creative.Two} alt="" style={{height: 350}}/> :
                    <img src={noImage} alt="" style={{height: 350}}/>
                  }
                </ButtonBase>
              </div>
              <br />
              <Typography variant="caption" style={{display: 'flex', justifyContent: 'center'}}>
                <InfoOutlinedIcon fontSize="small" style={{marginRight: '5px', color: '#2697fb'}} /> Images may appear cropped. Click on the Creatives to view Full Image
              </Typography>
            </div>
          </div>
          <br />
          <br />

          <Tabs
            id="roiTabs"
            variant="scrollable"
            textColor="secondary"
            classes={{indicator: classes.indicator}}
            value={this.state.tab}
            onChange={this.handleTabChange}
            style={{marginTop: '15px'}}
          >
            <Tab label="View ROI by Location" {...a11yProps(0)} disableRipple style={!this.state.location.includes('All') ? {display: 'none'} : {}}/>
            <Tab label="View ROI by RFM Segment" {...a11yProps(1)} disableRipple style={!this.state.selectedRfm.includes('All') ? {display: 'none'} : {}}/>
            <Tab label="View ROI by Test Group" {...a11yProps(2)} disableRipple style={!this.state.selectedTestGroup.includes('All') ? {display: 'none'} : {}}/>
          </Tabs>

          <TabPanel value={this.state.tab} index={0}>
            <RoiTable
              hide={this.state.lyDrawerOpen}
              data={this.state.locationData}
              dateExtended={this.state.dateExtended}
              tab={0}
              darkMode={this.props.darkMode}
            />
          </TabPanel>

          <TabPanel value={this.state.tab} index={1}>
            <RoiTable
              hide={this.state.lyDrawerOpen}
              data={this.state.rfmData}
              dateExtended={this.state.dateExtended}
              tab={1}
              darkMode={this.props.darkMode}
            />
          </TabPanel>

          <TabPanel value={this.state.tab} index={2}>
            <RoiTable
              hide={this.state.lyDrawerOpen}
              data={this.state.testGroupData}
              dateExtended={this.state.dateExtended}
              tab={2}
              darkMode={this.props.darkMode}
            />
          </TabPanel>

        </main>
      }

        {/* DRAWER SHOW LAST YEARS NUMBERS */}
        <Drawer
          className={classes.secondDrawer}
          variant="persistent"
          anchor="right"
          open={this.state.lyDrawerOpen}
          classes={{
            paper: classes.secondDrawerPaper
          }}
        >
          <ComparePanel
            yPosition={this.state.scrollY}
            tab={this.state.tab}
            dateExtended={this.state.dateExtended}
            handleSecondaryClose={this.handleSecondaryClose}
            openJob={this.props.data[0].JobID}
            darkMode={this.props.darkMode}
            userDemo={this.props.userDemo}
            viewName={'ROI'}
          />
          {/* <LyTentpole handleSecondaryClose={this.handleSecondaryClose}/> */}
        </Drawer>
      </div>
    )
  }
}

export default withStyles(styles)(TentpoleDrawer)
