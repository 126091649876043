import React, { Component } from 'react'
import Cookies from 'js-cookie'
import styled from 'styled-components'
import {
  withStyles,
  GridList,
  Card,
  CardHeader,
  Avatar,
  Typography,
  CardContent,
  FormGroup,
  FormControlLabel,
  Switch,
  Checkbox,
  Button,
  Paper,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  CircularProgress,
  IconButton,
  Tooltip
} from '@material-ui/core'
import { Apps, Search, Replay, Edit, PersonAdd } from '@material-ui/icons'
import SvgIcon from '@material-ui/core/SvgIcon'
import { Link } from 'react-router-dom'

const styles = theme => ({
  pageTitle: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '18px',
    fontWeight: 'light',
    color: 'rgba(115, 115, 115, 0.9)'
  },
  pageIcon: {
    fontSize: '15px',
    color: 'rgba(115, 115, 115, 0.9)',
    marginTop: '5px',
    marginRight: '2px',
  },
  filters: {
    // display: 'flex',
    justifyContent: 'space-between',
    height: 175,
    backgroundColor: 'transparent',
    // background: `linear-gradient(to right bottom, #039be5, #dcedc8)`,
    // backgroundColor: '#0083c4',
    border: '1px solid #e0e0e0',
  },
  select: {
    color: 'rgba(115, 115, 115, 0.9)',
    // marginLeft: '5px',
    marginTop: '14px',
    width: 200,
    '&:active': {
      color: '#000'
    }
  },
  locationSelect: {
    color: 'rgba(115, 115, 115, 0.9)',
    marginRight: '15px',
    // marginTop: '14px',
    width: 300,
    '&:active': {
      color: '#000'
    }
  },
  buttonInclude: {
    color: '#00a323',
    borderColor: '#00a323',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#00a323'
    }
  },
  buttonIncludeActive: {
    color: '#fff',
    backgroundColor: '#00a323',
    '&:hover': {
      backgroundColor: '#00c72b'
    }
  },
  buttonExclude: {
    color: '#b8413f',
    borderColor: '#b8413f',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#b8413f'
    }
  },
  buttonExcludeActive: {
    color: '#fff',
    backgroundColor: '#b8413f',
    '&:hover': {
      backgroundColor: '#d96a68'
    }
  },
  buttonReset: {
    color: '#50a5bf',
    borderColor: '#50a5bf',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#50a5bf'
    }
  },
  buttonEdit: {
    color: '#50a5bf',
    borderColor: '#50a5bf',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#50a5bf'
    }
  },
  buttonEditActive: {
    color: '#fff',
    backgroundColor: '#50a5bf',
    '&:hover': {
      backgroundColor: '#79c2d9'
    }
  },
  header: {
    color: '#fff',
    borderLeft:  '1px solid #dcdbdc',

  }
})

function SalesConsultantIcon(props) {
  return(
    <SvgIcon {...props}>
      <path fill="currentColor" d="M2 3H22A2.07 2.07 0 0 1 24 5V19A2.07 2.07 0 0 1 22 21H2A2.07 2.07 0 0 1 0 19V5A2.07 2.07 0 0 1 2 3M8 13.91C6 13.91 2 15 2 17V18H14V17C14 15 10 13.91 8 13.91M8 6A3 3 0 1 0 11 9A3 3 0 0 0 8 6M17 10V13H21V10H17" />
    </SvgIcon>
  )
}

function AccountEditIcon(props) {
  return(
    <SvgIcon {...props}>
      <path fill="currentColor" d="M21.7,13.35L20.7,14.35L18.65,12.3L19.65,11.3C19.86,11.09 20.21,11.09 20.42,11.3L21.7,12.58C21.91,12.79 21.91,13.14 21.7,13.35M12,18.94L18.06,12.88L20.11,14.93L14.06,21H12V18.94M12,14C7.58,14 4,15.79 4,18V20H10V18.11L14,14.11C13.34,14.03 12.67,14 12,14M12,4A4,4 0 0,0 8,8A4,4 0 0,0 12,12A4,4 0 0,0 16,8A4,4 0 0,0 12,4Z" />
    </SvgIcon>
  )
}

const StyledTextField = styled(TextField)`
  label.Mui-focused {
    color: #1d3d74
  }
  .MuiOutlinedInput-root {
    fieldset{}
    &.Mui-focused fieldset {
      border-color: #1d3d74
    }
  }
`

const StyledSelect = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'transparent',
    border: '1px solid #bfbfbf',
    fontSize: 16,
    padding: '17.5px 26px 17px 26px',
    '&:focus': {
      borderRadius: 4,
      borderColor: '#1d3d74'
    }
  }
}))(InputBase)

class SalesPersonsDirectory extends Component {
  constructor() {
    super()
    this.state = {
      salesPersonsData: [],
      salesPersonsFilteredData: [],
      locationData: [],
      isLoading: true,
      selectValue: 'FirstName',
      search: '',
      rowsPerPage: 10,
      page: 0,
      includeActive: false,
      excludeActive: false,
      locationValue: 'Location',
      hidden: true,
    }
  }

  componentDidMount = () => {
    Promise.all([
      fetch('https://api.dev.lsdirect.com/smartdash/V1/index.cfm/salesperson', {
        headers: {
          token: Cookies.get('token')
        },
      }),
      fetch('https://api.dev.lsdirect.com/smartdash/V1/index.cfm/salesperson/locations', {
        headers: {
          token: Cookies.get('token')
        },
      }),
    ])
    .then(([response, response2]) => Promise.all([response.json(), response2.json()]))
    .then(([data, data2]) => {
      if(data) {
        this.setState({
          isLoading: false,
          salesPersonsData: data,
          salesPersonsFilteredData: data,
          locationData: data2,
        })
      } else {
        this.setState({
          isLoading: false,
          salesPersonsData: data
        })
      }
    })
    .catch(error => {
      console.log(error)
    })
  }

  updateSearch(e) {
    if(e.target.value) {
      this.setState({
        search: e.target.value.substr(0, 25),
        page: 0
      })
    } else {
      this.setState({
        search: '',
      })
    }
  }

  handleFilterChange = e => {
    let filteredTerm = e.target.value

    this.setState({
      selectValue: filteredTerm
    })
  }

  handleLocationChange = e => {
    let selectedLocation = e.target.value
    if(selectedLocation !== 'Location') {
      this.setState({
        salesPersonsFilteredData: this.state.salesPersonsData.filter(item => item.LocationID === selectedLocation),
        includeActive: false,
        excludeActive: false,
      })
    } else {
      this.setState({
        salesPersonsFilteredData: this.state.salesPersonsData
      })
    }

    this.setState({
      locationValue: selectedLocation
    })
  }

  handleInclusionStatus = (e, status) => {

    if(status === 'included' && this.state.includeActive === false) {
      this.setState({
        salesPersonsFilteredData: this.state.salesPersonsData.filter(item => item.InclusionStatus === 1),
        includeActive: !this.state.includeActive,
        excludeActive: false,
        page: 0,
      })
    } else if(status === 'excluded' && this.state.excludeActive === false) {
      this.setState({
        salesPersonsFilteredData: this.state.salesPersonsData.filter(item => item.InclusionStatus === 2),
        excludeActive: true,
        includeActive: false,
        page: 0,
      })
    } else {
      this.setState({
        salesPersonsFilteredData: this.state.salesPersonsData,
        search: '',
        page: 0,
        includeActive: false,
        excludeActive: false,
        locationValue: 'Location'
      })
    }
  }

  handleChangePage = (e, newPage) => {
    this.setState({
      page: newPage
    })
  }

  handleChangeRowsPerPage = e => {
    let value = e.target.value
    const rowsPerPage = value === 'Show All' ? this.state.salesPersonsData.length : value

    this.setState({
      rowsPerPage: rowsPerPage,
      page: 0
    })
  }

  handleShowEdit = e => {
    this.setState({
      hidden: !this.state.hidden
    })
  }


  render() {
    const { classes } = this.props

    let filteredItems = this.state.salesPersonsFilteredData.filter(
      item => {
        return item[this.state.selectValue].toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
      }
    )

    return(
      <div>
        {this.state.isLoading ? <CircularProgress /> :
          <div>
            <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '20px'}}>
              <Typography className={classes.title}>
                <br />
                <SalesConsultantIcon className={classes.pageIcon} />
                NEW MOVERS - FEATURED SALES CONSULTANTS
              </Typography>
            </div>
            <br/>
            <div>
              <Paper  className={classes.filters}>
                <div style={{justifyContent: 'space-between', display: 'flex'}}>
                  <div>
                    <StyledTextField
                      style={{margin: '1em', width: 225}}
                      variant="outlined"
                      label="Search"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Search style={{color: '#727272'}} fontSize="small" />
                          </InputAdornment>
                        )
                      }}
                      value={this.state.search}
                      onChange={this.updateSearch.bind(this)}
                    />
                    <Select
                      value={this.state.selectValue}
                      variant="outlined"
                      input={<StyledSelect />}
                      className={classes.select}
                      onChange={e => this.handleFilterChange(e)}
                    >
                      <MenuItem value={"FirstName"} >By First Name</MenuItem>
                      <MenuItem value={"LastName"} >By Last Name</MenuItem>
                      <MenuItem value={"Address1"} >By Address</MenuItem>
                      <MenuItem value={"City"} >By City</MenuItem>
                      <MenuItem value={"State"} >By State</MenuItem>
                      <MenuItem value={"ZIP"} >By Zip Code</MenuItem>
                    </Select>
                  </div>
                  <Select
                    variant="outlined"
                    input={<StyledSelect />}
                    className={classes.locationSelect}
                    value={this.state.locationValue}
                    onChange={e => this.handleLocationChange(e)}
                  >

                    <MenuItem value={"Location"}>Location</MenuItem>
                    {this.state.locationData.map(row => (
                      <MenuItem key={row.Key} value={row.LocationID}>{row.Address1 + ', ' + row.City + ', ' + row.State + ', ' + row.ZIP }</MenuItem>
                    ))}
                  </Select>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <div style={{margin: '1em', marginTop: '-1px'}}>
                    <Tooltip title={this.state.includeActive ? "Turn Off Included Filter" : "Filter By Included Status"}>
                      <Button
                        variant="outlined"
                        onClick={(e, status) => this.handleInclusionStatus(e, 'included')}
                        className={this.state.includeActive ? classes.buttonIncludeActive : classes.buttonInclude}
                      >
                        Included
                      </Button>
                    </Tooltip>
                    <Tooltip title={this.state.excludeActive ? "Turn Off Excluded Filter" : "Filter By Excluded Status"}>
                      <Button
                        style={{marginLeft: '10px'}}
                        variant="outlined"
                        onClick={(e, status) => this.handleInclusionStatus(e, 'excluded')}
                        className={this.state.excludeActive ? classes.buttonExcludeActive : classes.buttonExclude}
                      >
                        Excluded
                      </Button>
                    </Tooltip>

                    <Tooltip title="Reset All Filters">
                      <Button
                        style={{marginLeft: '10px'}}
                        variant="outlined"
                        onClick={(e, status) => this.handleInclusionStatus(e, 'reset')}
                        className={classes.buttonReset}
                      >
                        <Replay />
                      </Button>
                    </Tooltip>
                  </div>
                  <div>
                    <Tooltip title="Add New Consultant">
                      <Button
                        style={{marginRight: '10px'}}
                        variant="outlined"
                        className={classes.buttonReset}
                        href={'/add-new-consultant'}
                      >
                        <PersonAdd />
                      </Button>
                    </Tooltip>
                    <Tooltip title={this.state.hidden ? "Update A Consultant" : "Turn Off Consultant Edits"}>
                      <Button
                        style={{marginRight: '15px'}}
                        variant="outlined"
                        onClick={(e) => this.handleShowEdit(e)}
                        className={this.state.hidden ? classes.buttonEdit : classes.buttonEditActive}
                      >
                        {/* <PersonAdd /> */}
                        <AccountEditIcon />
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              </Paper>
            </div>

            <Paper style={{marginTop: '30px'}}>
              <Table size="small">
                <TableHead>
                  <TableRow style={{backgroundColor: '#00147b', height: 60}}>
                    <TableCell style={this.state.hidden ? {display: 'none'} : {width: 100}} />
                    <TableCell className={classes.header}>Full Name</TableCell>
                    <TableCell className={classes.header}>Address</TableCell>
                    <TableCell className={classes.header} align="center">City</TableCell>
                    <TableCell className={classes.header} align="center">State</TableCell>
                    <TableCell className={classes.header} align="center">Zip Code</TableCell>
                    <TableCell className={classes.header} align="center">Status</TableCell>
                    <TableCell className={classes.header} style={{width: 350}} align="center">Image</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {this.state.salesPersonsData.length > 0 ?
                    filteredItems.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                    .map(row => (
                    <TableRow key={row.Key} style={{height: 175}}>
                      <TableCell align="center" style={this.state.hidden ? {display: 'none'} : {}}>
                        <Tooltip title="Edit Sales Consultant">
                          <IconButton size="small">
                            <Link
                              to={{
                                pathname: 'edit-sales-consultant',
                                state: {
                                  salesConsultant: row
                                }
                              }}
                            >
                              <Edit fontSize="small"/>
                            </Link>
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell style={{borderLeft: '1px solid #dcdbdc'}}>
                        {/* {row.Data[0].FirstName + ' ' + row.Data[0].LastName} */}
                        {row.FirstName + ' ' + row.LastName}
                      </TableCell>
                      <TableCell style={{borderLeft: '1px solid #dcdbdc'}}>
                        {row.Address1}
                      </TableCell>
                      <TableCell style={{borderLeft: '1px solid #dcdbdc'}} align="center">
                        {row.City}
                      </TableCell>
                      <TableCell style={{borderLeft: '1px solid #dcdbdc'}} align="center">
                        {row.State}
                      </TableCell>
                      <TableCell style={{borderLeft: '1px solid #dcdbdc'}} align="center">
                        {row.ZIP}
                      </TableCell>
                      <TableCell style={{borderLeft: '1px solid #dcdbdc'}} align="center">
                        {/* {row.Data[0].InclusionStatus === 1 ? 'Included' : 'Excluded'} */}
                        {row.InclusionStatus === 1 ? <span style={{color: '#00a323'}}>Included</span> : <span style={{color: '#b8413f'}}>Excluded</span>}
                      </TableCell>
                      <TableCell style={{borderLeft: '1px solid #dcdbdc'}} align="center">
                        <img style={{width: '50%' }} src={row.ImgPath} />
                      </TableCell>
                    </TableRow>
                  ))
                  : <TableRow />}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 'Show All']}
                component="div"
                count={filteredItems.length}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </Paper>
          </div>
        }
      </div>
    )
  }
}

export default withStyles(styles)(SalesPersonsDirectory)
