import React, { Component } from 'react'
import clsx from 'clsx'
import { withStyles } from '@material-ui/core/styles'
import {
  Typography,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    width: '100%',
    marginRight: 20,
  },
  title: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  header: {
    color: 'rgba(255, 255, 255, 1.0)',
    fontWeight: 700,
  },
  details: {
    alignItems: 'right'
  },
  column: {
    flexBasis: '50%'
  },
  total: {
    fontWeight: 700,
    color: 'rgba(92, 92, 92)',
    flexBasis: '50%'
  },
  totalDark: {
    fontWeight: 700,
    color: 'rgba(255, 255, 255)',
    flexBasis: '50%'
  }
})

class Locations extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rowsPerPage: 10,
      page: 0,
    }
  }

  handleChangePage = (e, newPage) => {
    this.setState({
      page: newPage
    })
  }

  handleChangeRowsPerPage = e => {
    this.setState({
      rowsPerPage: parseInt(e.target.value),
      page: 0
    })
  }

  render() {
    const { classes } = this.props

    return(
      <div>
        {this.props.data ?
          <div>
            <Typography variant="caption" className={classes.title}>LOCATIONS</Typography>
            <Paper style={{marginTop: '15px'}}>
              <Table>
                <TableHead style={{backgroundColor: '#0e85d3'}}>
                  <TableRow>
                    <TableCell><Typography variant="caption" className={classes.header}>Type</Typography></TableCell>
                    <TableCell align="right"><Typography variant="caption" className={classes.header}>Spend</Typography></TableCell>
                    <TableCell align="right"><Typography variant="caption" className={classes.header}>Sales</Typography></TableCell>
                    <TableCell align="right"><Typography variant="caption" className={classes.header}>Mailed</Typography></TableCell>
                    <TableCell align="right"><Typography variant="caption" className={classes.header}>Responses</Typography></TableCell>
                    <TableCell align="right"><Typography variant="caption" className={classes.header}>Response Rate</Typography></TableCell>
                    <TableCell align="right"><Typography variant="caption" className={classes.header}>Holdout Sales</Typography></TableCell>
                    <TableCell align="right"><Typography variant="caption" className={classes.header}>Holdout Count</Typography></TableCell>
                    <TableCell align="right"><Typography variant="caption" className={classes.header}>Holdout Responses</Typography></TableCell>
                    <TableCell align="right"><Typography variant="caption" className={classes.header}>Holdout Response Rate</Typography></TableCell>
                    <TableCell align="right"><Typography variant="caption" className={classes.header}>Inc Response Rate</Typography></TableCell>
                    <TableCell align="right"><Typography variant="caption" className={classes.header}>Avg Ticket</Typography></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.data.length > 10 ?
                    this.props.data
                    .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                    .map((row, i) => (
                      <TableRow key={i}>                        
                        <TableCell><Typography variant="caption">{row.label.toUpperCase()}</Typography></TableCell>
                        <TableCell align="right"><Typography variant="caption">${Math.round(row.total.Cost).toLocaleString('en')}</Typography></TableCell>
                        <TableCell align="right"><Typography variant="caption">${Math.round(row.total.Sales).toLocaleString('en')}</Typography></TableCell>
                        <TableCell align="right"><Typography variant="caption">{Math.round(row.total.Mailed).toLocaleString('en')}</Typography></TableCell>
                        <TableCell align="right"><Typography variant="caption">{Math.round(row.total.Responses).toLocaleString('en')}</Typography></TableCell>
                        <TableCell align="right"><Typography variant="caption">{(row.total.Responses / row.total.Mailed * 100).toFixed(2) + '%'}</Typography></TableCell>
                        <TableCell align="right"><Typography variant="caption">{'$' + Math.round(row.total.HO_Sales).toLocaleString('en')}</Typography></TableCell>
                        <TableCell align="right"><Typography variant="caption">{Math.round(row.total.HO_Count).toLocaleString('en')}</Typography></TableCell>
                        <TableCell align="right"><Typography variant="caption">{Math.round(row.total.HO_Resp).toLocaleString('en')}</Typography></TableCell>
                        <TableCell align="right"><Typography variant="caption">{row.total.HO_Count > 0 ? (row.total.HO_Resp / row.total.HO_Count * 100).toFixed(2) + '%' : 0 + "%"}</Typography></TableCell>
                        <TableCell align="right"><Typography variant="caption">{row.total.HO_Count > 0 ? (row.total.Responses / row.total.Mailed) > (row.total.HO_Resp / row.total.HO_Count) ? (((row.total.Responses / row.total.Mailed) - (row.total.HO_Resp / row.total.HO_Count)) * 100).toFixed(2) + '%' : '0%' : (row.total.Responses / row.total.Mailed * 100).toFixed(2) + "%"}</Typography></TableCell>
                        <TableCell align="right"><Typography variant="caption">${row.total.Responses > 0 ? Math.round(row.total.Sales / row.total.Responses).toLocaleString('en') : 0}</Typography></TableCell>
                      </TableRow>
                    ))
                  :
                    this.props.data.map((row, i) => (
                      <TableRow key={i}>
                        <TableCell><Typography variant="caption">{row.label.toUpperCase()}</Typography></TableCell>
                        <TableCell align="right"><Typography variant="caption">${Math.round(row.total.Cost).toLocaleString('en')}</Typography></TableCell>
                        <TableCell align="right"><Typography variant="caption">${Math.round(row.total.Sales).toLocaleString('en')}</Typography></TableCell>
                        <TableCell align="right"><Typography variant="caption">{Math.round(row.total.Mailed).toLocaleString('en')}</Typography></TableCell>
                        <TableCell align="right"><Typography variant="caption">{Math.round(row.total.Responses).toLocaleString('en')}</Typography></TableCell>
                        <TableCell align="right"><Typography variant="caption">{(row.total.Res_Rate * 100).toFixed(2) + '%'}</Typography></TableCell>
                        <TableCell align="right"><Typography variant="caption">{'$' + Math.round(row.total.HO_Sales).toLocaleString('en')}</Typography></TableCell>
                        <TableCell align="right"><Typography variant="caption">{Math.round(row.total.HO_Count).toLocaleString('en')}</Typography></TableCell>
                        <TableCell align="right"><Typography variant="caption">{Math.round(row.total.HO_Resp).toLocaleString('en')}</Typography></TableCell>
                        <TableCell align="right"><Typography variant="caption">{row.total.HO_Res_Rate > 0 ? (row.total.HO_Res_Rate * 100).toFixed(2) + '%' : 0 + "%"}</Typography></TableCell>
                        <TableCell align="right"><Typography variant="caption">{row.total.HO_Count > 0 ? (row.total.ADJ_Res_Rate * 100).toFixed(2) + '%' : (row.total.Res_Rate * 100).toFixed(2) + "%"}</Typography></TableCell>
                        <TableCell align="right"><Typography variant="caption">${row.total.Responses > 0 ? Math.round(row.total.Sales / row.total.Responses).toLocaleString('en') : 0}</Typography></TableCell>
                      </TableRow>
                    ))
                  }
                </TableBody>
              </Table>

              <TablePagination
                // rowsPerPageOptions={this.props.data.length > 20 ? [10, 20, this.props.data.length] : [10 , this.props.data.length]}
                rowsPerPageOptions={[10, 20, 50, 100]}
                component="div"
                count={this.props.data.length}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                style={this.props.data.length > 10 ? {} : {display: 'none'}}
              />
            </Paper>
          </div>
          :
          <div/>
        }
      </div>
    )
  }
}

export default withStyles(styles)(Locations)
