import React from 'react'
import TabTest from '../TabTest'
import { Typography, makeStyles } from '@material-ui/core'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import AskReport from '../AskReport'
// import { withStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '17px',
    fontWeight: 'light',
    color: 'rgba(115, 115, 115, 0.9)'
  },
  pageIcon: {
    fontSize: '15px',
    marginTop: '21px',
    marginRight: '2px'
  },
  unavailable: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // margin: '2em'
  },
}))

export default function Zipcode() {
	const classes = useStyles()
	const currentUserLite = JSON.parse(localStorage.getItem('currentUser')) && JSON.parse(localStorage.getItem('currentUser'))[0].sd_lite === 1 ? true : false
  const dbO = JSON.parse(localStorage.getItem("currentUser")) ? JSON.parse(localStorage.getItem("currentUser"))[0].db_owner : ""

	return (
		<div>
			{/*<div style={{display: 'flex', justifyContent: 'space-between'}}>
		      <Typography className={classes.title}>
		        <br />
		        <LocationOnIcon className={classes.pageIcon}/>
		        YOY SALES BY ZIP CODES
		      </Typography>
		    </div>*/}
		    {!currentUserLite && dbO !== "ESCANABA" ?

			<TabTest pageName={'YOY SALES BY ZIP CODES'} viz={'ZipSales/ZipSalesDaily'} />
			:
			<div className={classes.unavailable}>
             <AskReport />
            </div>
			}
	  	</div>
	)
}
