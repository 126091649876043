import React, { Component } from 'react'
import Chart from 'react-apexcharts'

class DonutChart extends Component {
  constructor(props) {
    super(props)    
    this.state = {
      series: props.data,
      options: {
        labels: props.labels,
        chart: {
          type: 'donut',
          toolbar: {
            tools: {
              download: false,
            }
          }
        },
        plotOptions: {
          pie: {
            donut : {
              size: props.radius,
              background: 'transparent',
              labels: {
                show: props.labelShow,
                total: {
                  show: props.total,
                  label: props.totalLabel,
                  color: props.totalColor,
                  formatter: function(w) {
                    return '$' + w.globals.series[1].toLocaleString('en')
                  }
                },
                name: {
                  show: true,
                  fontSize: '12px'
                },
                value: {
                  color: this.props.darkMode ? '#fff' : '',
                  fontSize: '22px',
                  offsetY: -0.5,
                  fontFamily: '-apple-system, system-ui, BlinkMacSystemFont',
                  formatter: function(value) {
                    if(props.labels[0] === 'Total Spend') {
                      return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    } else {
                      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                  }
                },
              }
            }
          }
        },
        tooltip: {
          enabled: props.tooltip,
          y: {
            formatter: function(value) {
              return '$' + value.toLocaleString('en')
            }
          }
        },
        legend: {
          show: props.legend,
          position: props.position,
          labels: {
            colors: this.props.darkMode ? '#fff' : '',
          },
          onItemClick: {
            toggleDataSeries: true
          },
          onItemHover: {
            highlightDataSeries: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: props.colors,
        responsive: [{
          breakpoint: 480,
        }]
      },
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return(nextProps.data !== prevState.series) ?
      {
        series: nextProps.data,
        options: {
          labels: nextProps.labels
        }
      } : null
  }

  // componentWillReceiveProps(nextProps) {
  //   if(nextProps !== this.props) {
  //     this.setState({
  //       series: nextProps.data
  //     })
  //   }
  // }

  render() {
    return(
      <div>
        <Chart options={this.state.options} series={this.state.series} type="donut" height={this.props.height}/>
      </div>
    )
  }
}

export default DonutChart
