import React, { Component } from 'react'
import { Typography, IconButton, Tooltip } from '@material-ui/core'
import RestoreIcon from '@material-ui/icons/Restore'
import Picker from 'rc-calendar/lib/Picker'
import RangeCalendar from 'rc-calendar/lib/RangeCalendar'
import '../../assets/calendar.css'

import moment from 'moment'

const now = moment()
const lastYear = moment().subtract(1, 'year')
const defaultCalendarValue = now.clone()
defaultCalendarValue.add(-1, 'month')

// function newArray(start, end) {
//   const result = []
//   for(let i = start; i < end; i++) {
//     result.push(i)
//   }
//   return result
// }

// function disabledDate(current) {
//   const date = moment()
//   date.hour(0)
//   date.minute(0)
//   date.second(0)
//   return current.isBefore(date)
// }

const formatStr = 'MM/DD/YYYY'
function format(value) {
  return value ? value.format(formatStr) : ''
}

function isValidRange(value) {
  // console.log(value)
  return value && value[0] && value[1]
}

// function onStandaloneChange(value) {
//   console.log('onChange')
//   console.log(value[0] && format(value[0]), value[1] && format(value[1]))
// }
//
// function onStandaloneSelect(value) {
//   console.log('onSelect')
//   console.log(format(value[0]), format(value[1]))
// }

class Date extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: [],
      hoverValue: []
    }
  }

  onChange = value => {
    this.setState({
      value: value
    })
    // console.log(value)
    this.props.handleDateChange(value)
  }

  onHoverChange = hoverValue => {
    this.setState({
      hoverValue: hoverValue
    })
  }

  onClear = () => {
    this.setState({
      value: [],
    })
    this.props.handleDateClear()
  }

  render() {
    const calendar = (
      <RangeCalendar
        hoverValue={this.state.hoverValue}
        onHoverChange={this.onHoverChange}
        showWeekNumber={false}
        showToday={true}
        showClear={true}
        showDateInput={false}
        // dateInputPlaceholder={['Start Date', 'End Date']}
        defaultValue={[now, now.clone().add(1, 'months')]}
        style={this.props.darkMode ?
          {zIndex: '5000', float: 'right', marginRight: '78px', marginTop: '-1px', backgroundColor: '#3a3a3a'}
        :
          {zIndex: '5000', float: 'right', marginRight: '78px', marginTop: '-1px'}
        }
      />
    )
    return(
      <div>
        <Typography variant="caption" style={this.props.darkMode ? {marginRight: '3px', color: '#fff'} : {marginRight: '3px', color: '#818181'}}>Start Date:</Typography>
        <Picker
          style={{justifyContent: 'flex-end'}}
          value={this.state.value}
          onChange={this.onChange}
          animation='slide-up'
          calendar={calendar}
          darkMode={this.props.darkMode}
          disabled={this.props.disable}
        >
          {
            ({value})=> {
              return(
                <span>
                  <input
                    // placeholder={`${lastYear.format('MM/DD/YYYY')}`}
                    placeholder={this.props.placeHolder1 ? `${this.props.placeHolder1}` : `${lastYear.format('MM/DD/YYYY')}`}
                    style={this.props.darkMode ?
                      {
                        width: 150,
                        height: 25,
                        marginRight: '15px',
                        textAlign: 'center',
                        backgroundColor: 'transparent',
                        color: '#fff',
                        border: '1px solid #fff',
                        borderRadius: '3px'
                      }
                    :
                      {
                        width: 150,
                        height: 25,
                        marginRight: '15px',
                        textAlign: 'center',
                        backgroundColor: 'transparent',
                        border: '1px solid #818181',
                        borderRadius: '3px'
                      }
                    }
                    disabled={this.state.disabled}
                    readOnly
                    className="ant-calendar-picker-input ant-input"
                    value={(isValidRange(value) && `${format(value[0])}`) || ''}
                  />
                </span>
              )
            }
          }
        </Picker>
        <Typography variant="caption" style={this.props.darkMode ? {marginRight: '3px', color: '#fff'} : {marginRight: '3px', color: '#818181'}}>End Date:</Typography>
        <Picker
          value={this.state.value}
          onChange={this.onChange}
          animation='slide-up'
          calendar={calendar}
          darkMode={this.props.darkMode}
          disabled={this.props.disable}
        >
          {
            ({value})=> {
              return(
                <span>
                  <input
                    // placeholder={`${now.format('MM/DD/YYYY')}`}
                    placeholder={`${now.format('MM/DD/YYYY')}`}
                    style={this.props.darkMode ?
                      {
                        width: 150,
                        height: 25,
                        textAlign: 'center',
                        backgroundColor: 'transparent',
                        border: '1px solid #fff',
                        borderRadius: '3px'
                      }
                    :
                      {
                        width: 150,
                        height: 25,
                        textAlign: 'center',
                        backgroundColor: 'transparent',
                        border: '1px solid #818181',
                        borderRadius: '3px'
                      }
                    }
                    disabled={this.state.disabled}
                    readOnly
                    className="ant-calendar-picker-input ant-input"
                    value={(isValidRange(value) && `${format(value[1])}`) || ''}
                  />
                </span>
              )
            }
          }
        </Picker>
        <IconButton size="small" style={this.props.restoreHidden ? {display: 'none'} : {marginLeft: '2px'}} onClick={this.onClear}>
          <Tooltip title="Restore default dates">
            <RestoreIcon fontSize="small" />
          </Tooltip>
        </IconButton>
      </div>
    )
  }
}

export default Date
