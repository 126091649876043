import React, {useState, useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  CircularProgress,
  Typography,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Fade,
} from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    width: '100%',
    // marginRight: 20
  },
  title: {
    // marginBottom: '15px',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  header: {
    color: 'rgba(255, 255, 255, 1.0)',
    fontWeight: 700,
  },
  details: {
    alignItems: 'right'
  },
  column: {
    flexBasis: '50%'
  },
  total: {
    fontWeight: 700,
    color: 'rgba(92, 92, 92)',
    flexBasis: '50%'
  },
  totalDark: {
    fontWeight: 700,
    color: 'rgba(255, 255, 255)',
    flexBasis: '50%'
  }
}))

const additionalCalculations = (custProsData) => {


  if(custProsData.length > 0) {
    custProsData.forEach((item, i) => {
      item['Res_Rate'] = item.Mailed > 0 ? item.Responses /  item.Mailed : 0
      item['HO_Res_Rate'] = item.HO_Count > 0 ? item.HO_Resp / item.HO_Count : 0
      item['ADJ_Res_Rate'] = item.Res_Rate - item.HO_Res_Rate > 0 ? (item.Res_Rate - item.HO_Res_Rate) : 0
      item['Avg_Ticket'] = item.Responses > 0 ? item.Sales / item.Responses : 0
      item['ROAS'] = item.Cost > 0 ? item.Sales / item.Cost : 0
      item['ADJ_ROAS'] = item.HO_Count > 0 ? (item.Responses / item.Mailed - (item.HO_Resp / item.HO_Count)) > 0 ? (item.Sales / (item.Responses / item.Mailed)) * (item.Responses / item.Mailed - (item.HO_Resp / item.HO_Count)) / item.Cost : 0 : item.Sales / item.Cost
    })
    return custProsData
  }
}

const getTableHeaders = (dbO, roasFlag) => {
  if(dbO === 'KHOOK' || roasFlag === 1) return [
    {name: 'Type', alignment: 'left', sortBy: 'Label'},
    {name: 'Spend', alignment: 'right', sortBy: 'Cost'},
    {name: 'Sales', alignment: 'right', sortBy: 'Sales'},
    {name: 'Mailed', alignment: 'right', sortBy: 'Mailed'},
    {name: 'Responses', alignment: 'right', sortBy: 'Responses'},
    {name: 'Response Rate', alignment: 'right', sortBy: 'Res_Rate'},
    {name: 'Holdout Count', alignment: 'right', sortBy: 'HO_Count'},
    {name: 'Holdout Sales', alignment: 'right', sortBy: 'HO_Sales'},
    {name: 'Holdout Responses', alignment: 'right', sortBy: 'HO_Resp'},
    {name: 'Holdout Response Rate', alignment: 'right', sortBy: 'HO_Res_Rate'},
    {name: 'Inc Response Rate', alignment: 'right', sortBy: 'ADJ_Res_Rate'},
    {name: 'Avg Ticket', alignment: 'right', sortBy: 'Avg_Ticket'},
    {name: 'ROAS', alignment: 'right', sortBy: 'Label'},
    {name: 'Inc ROAS', alignment: 'right', sortBy: 'Label',},
  ]
  else return [
    {name: 'Type', alignment: 'left', sortBy: 'Label'},
    {name: 'Spend', alignment: 'right', sortBy: 'Cost'},
    {name: 'Sales', alignment: 'right', sortBy: 'Sales'},
    {name: 'Mailed', alignment: 'right', sortBy: 'Mailed'},
    {name: 'Responses', alignment: 'right', sortBy: 'Responses'},
    {name: 'Response Rate', alignment: 'right', sortBy: 'Res_Rate'},
    {name: 'Holdout Sales', alignment: 'right', sortBy: 'HO_Count'},
    {name: 'Holdout Count', alignment: 'right', sortBy: 'HO_Res_Rate'},
    {name: 'Holdout Responses', alignment: 'right', sortBy: 'ADJ_Res_Rate'},
    {name: 'Holdout Response Rate', alignment: 'right', sortBy: 'Avg_Ticket'},
    {name: 'Inc Response Rate', alignment: 'right', sortBy: 'Label'},
    {name: 'Avg Ticket', alignment: 'right', sortBy: 'Label',},
  ]
}

const getTotalSeries = (data) => {
  
  if(data.length > 0) {
    let totalData = data.reduce((a,b) => {
      for(const prop in b) {
        if(a.hasOwnProperty(prop) && prop !== 'Label') a[prop] += b[prop]
        else a[prop] = b[prop]
      }
      return a
    }, {})

    totalData['Res_Rate'] = totalData.Mailed > 0 ? totalData.Responses /  totalData.Mailed : 0
    totalData['HO_Res_Rate'] = totalData.HO_Count > 0 ? totalData.HO_Resp / totalData.HO_Count : 0
    totalData['ADJ_Res_Rate'] = totalData.Res_Rate - totalData.HO_Res_Rate > 0 ? totalData.Res_Rate - totalData.HO_Res_Rate : 0
    totalData['Avg_Ticket'] = totalData.Responses > 0 ? totalData.Sales / totalData.Responses : 0
    totalData['ROAS'] = totalData.Cost > 0 ? totalData.Sales / totalData.Cost : 0
    totalData['ADJ_ROAS'] = totalData.HO_Count > 0 ? (totalData.Responses / totalData.Mailed - (totalData.HO_Resp / totalData.HO_Count)) > 0 ? (totalData.Sales / (totalData.Responses / totalData.Mailed)) * (totalData.Responses / totalData.Mailed - (totalData.HO_Resp / totalData.HO_Count)) / totalData.Cost : 0 : totalData.Sales / totalData.Cost

    return totalData

  }

}

export default function CustProsTable(props) {
  const { custProsData, darkMode, dbO, roasFlag, bUnit } = props
  const classes = useStyles()

  const [dataOrdered, setDataOrdered] = useState(null)
  const [donutSeries, setDonutSeries] = useState(null)
  const [totalSeries, setTotalSeries] = useState(null)
  const flag = roasFlag === 1 ? true : false
  const tableHeaders = getTableHeaders(dbO, roasFlag)

  useEffect(() => {
    
    if(custProsData.length > 0) {
      additionalCalculations(custProsData)
      
      const totals = getTotalSeries(custProsData)
      setDataOrdered(custProsData)
      setDonutSeries(custProsData.map(item => item.Sales))
      setTotalSeries(totals)
    }
  }, [custProsData])

  if(!dataOrdered) return <CircularProgress />

  return (
    <div>
      <Fade in={true} timeout={500}>
        <div>          
          <div style={{display: 'flex', alignItems: 'center'}}>            
            <Typography className={classes.title}>CUSTOMERS VS PROSPECTS</Typography>
            <Fade in={true} timeout={2000}>
              <div style={flag && bUnit !== 3 ? {textAlign: 'center'} : {display: 'none'}}>
                <div style={{margin: '1em', display: 'inline-flex', alignItems: 'center'}}>
                  <WarningIcon style={{color: '#de9f10'}}/>
                  <Typography align="center" variant="caption" style={{marginLeft: '5px', fontSize: '15px'}}>
                    Due to the low holdout quantity, <span style={{color: "#d13e34", fontWeight: 'bold'}}>the incremental metrics may not be statistically significant.</span>
                  </Typography>
                </div>
              </div>
            </Fade>
          </div>                  
          <Paper className={classes.root}>
            <Table className={classes.table}>
              <TableHead style={{backgroundColor: '#0e85d3'}}>
                <TableRow>
                  {tableHeaders.map((header, i) => (
                    <TableCell key={`header-${i}`} className={classes.header} align={header.alignment}>
                      <Typography variant="caption">{header.name}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {dataOrdered.map((row, i) => (
                  <TableRow key={`${row.label} - ${i}`}>
                    <TableCell><Typography variant="caption">{row.Label.toUpperCase()}</Typography></TableCell>
                    <TableCell align="right"><Typography variant="caption">${custProsData.length > 0 ? Math.round(row.Cost).toLocaleString('en') : 0}</Typography></TableCell>
                    <TableCell align="right"><Typography variant="caption">${custProsData.length > 0 ? Math.round(row.Sales).toLocaleString('en') : 0}</Typography></TableCell>
                    <TableCell align="right"><Typography variant="caption">{custProsData.length > 0 ? Math.round(row.Mailed).toLocaleString('en') : 0}</Typography></TableCell>
                    <TableCell align="right"><Typography variant="caption">{custProsData.length > 0 ? Math.round(row.Responses).toLocaleString('en') : 0}</Typography></TableCell>
                    <TableCell align="right"><Typography variant="caption">{custProsData.length > 0 ? row.Mailed > 0 ? (row.Res_Rate * 100).toFixed(2) : 0 : 0}%</Typography></TableCell>
                    <TableCell align="right"><Typography variant="caption">{custProsData.length > 0 ? flag ? Math.round(row.HO_Count).toLocaleString('en') : `$${Math.round(row.HO_Sales).toLocaleString('en')}` : 0}</Typography></TableCell>
                    <TableCell align="right" style={{display: `${flag ? "" : "none"}`}}><Typography variant="caption">{custProsData.length > 0 ? `$${Math.round(row.HO_Sales).toLocaleString('en')}` : 0}</Typography></TableCell>
                    <TableCell align="right" style={{display: `${flag ? "" : "none"}`}}><Typography variant="caption">{custProsData.length > 0 ? `${Math.round(row.HO_Resp).toLocaleString('en')}` : 0}</Typography></TableCell>
                    <TableCell align="right"><Typography variant="caption">{custProsData.length > 0 ? flag ? `${(row.HO_Res_Rate * 100).toFixed(2)}%` : Math.round(row.HO_Count).toLocaleString('en') : 0}</Typography></TableCell>
                    <TableCell align="right"><Typography variant="caption">{custProsData.length > 0 ? flag ? `${(row.ADJ_Res_Rate * 100).toFixed(2)}%` : Math.round(row.HO_Resp).toLocaleString('en') : 0}</Typography></TableCell>
                    <TableCell align="right"><Typography variant="caption">{custProsData.length > 0 ? flag ? `$${Math.round(row.Avg_Ticket).toLocaleString('en')}` : `${(row.HO_Res_Rate * 100).toFixed(2)}%` : 0}</Typography></TableCell>
                    <TableCell align="right"><Typography variant="caption">{custProsData.length > 0 ? flag ? `$${(row.ROAS).toFixed(2)}` : row.ADJ_Res_Rate > 0 ? `${(row.ADJ_Res_Rate * 100).toFixed(2)}%` : '0.00%' : 0}</Typography></TableCell>
                    <TableCell align="right"><Typography variant="caption">{custProsData.length > 0 ? flag ? `$${(row.ADJ_ROAS).toFixed(2)}` : `$${Math.round(row.Avg_Ticket).toLocaleString('en')}` : 0}</Typography></TableCell>
                  </TableRow>
                ))}
                <TableRow>                
                  <TableCell className={darkMode ? classes.totalDark : classes.total}><Typography variant="caption" className={classes.total}>TOTAL</Typography></TableCell>
                  <TableCell className={darkMode ? classes.totalDark : classes.total} align="right"><Typography variant="caption" className={classes.total}>${custProsData.length > 0 ? Math.round(totalSeries.Cost).toLocaleString('en') : 0}</Typography></TableCell>
                  <TableCell className={darkMode ? classes.totalDark : classes.total} align="right"><Typography variant="caption" className={classes.total}>${custProsData.length > 0 ? Math.round(totalSeries.Sales).toLocaleString('en') : 0}</Typography></TableCell>
                  <TableCell className={darkMode ? classes.totalDark : classes.total} align="right"><Typography variant="caption" className={classes.total}>{custProsData.length > 0 ? Math.round(totalSeries.Mailed).toLocaleString('en') : 0}</Typography></TableCell>
                  <TableCell className={darkMode ? classes.totalDark : classes.total} align="right"><Typography variant="caption" className={classes.total}>{custProsData.length > 0 ? Math.round(totalSeries.Responses).toLocaleString('en') : 0}</Typography></TableCell>
                  <TableCell className={darkMode ? classes.totalDark : classes.total} align="right"><Typography variant="caption" className={classes.total}>{custProsData.length > 0 ? (totalSeries.Res_Rate * 100).toFixed(2) : 0}%</Typography></TableCell>
                  <TableCell className={darkMode ? classes.totalDark : classes.total} align="right"><Typography variant="caption" className={classes.total}>{custProsData.length > 0 ? flag ? Math.round(totalSeries.HO_Count).toLocaleString('en') : `$${Math.round(totalSeries.HO_Sales).toLocaleString('en')}` : 0}</Typography></TableCell>
                  <TableCell className={darkMode ? classes.totalDark : classes.total} style={{display: `${flag ? "" : "none"}`}} align="right"><Typography variant="caption" className={classes.total}>{custProsData.length > 0 ? `$${Math.round(totalSeries.HO_Sales).toLocaleString('en')}` : 0}</Typography></TableCell>
                  <TableCell className={darkMode ? classes.totalDark : classes.total} style={{display: `${flag ? "" : "none"}`}} align="right"><Typography variant="caption" className={classes.total}>{custProsData.length > 0 ? `${Math.round(totalSeries.HO_Resp).toLocaleString('en')}` : 0}</Typography></TableCell>
                  <TableCell className={darkMode ? classes.totalDark : classes.total} align="right"><Typography variant="caption" className={classes.total}>{custProsData.length > 0 ? flag ? `${(totalSeries.HO_Res_Rate * 100).toFixed(2)}%` : Math.round(totalSeries.HO_Count).toLocaleString('en') : 0}</Typography></TableCell>
                  <TableCell className={darkMode ? classes.totalDark : classes.total} align="right"><Typography variant="caption" className={classes.total}>{custProsData.length > 0 ? flag ? `${(totalSeries.ADJ_Res_Rate * 100).toFixed(2)}%` : Math.round(totalSeries.HO_Resp).toLocaleString('en') : 0}</Typography></TableCell>
                  <TableCell className={darkMode ? classes.totalDark : classes.total} align="right"><Typography variant="caption" className={classes.total}>{custProsData.length > 0 ? flag ? `$${Math.round(totalSeries.Avg_Ticket).toLocaleString('en')}` : `${(totalSeries.HO_Res_Rate * 100).toFixed(2)}%` : 0}</Typography></TableCell>
                  <TableCell className={darkMode ? classes.totalDark : classes.total} align="right"><Typography variant="caption" className={classes.total}>{custProsData.length > 0 ? flag ? `$${(totalSeries.ROAS).toFixed(2)}` : `${(totalSeries.ADJ_Res_Rate * 100).toFixed(2)}%` : 0}</Typography></TableCell>
                  <TableCell className={darkMode ? classes.totalDark : classes.total} align="right"><Typography variant="caption" className={classes.total}>{custProsData.length > 0 ? flag ? `$${(totalSeries.ADJ_ROAS).toFixed(2)}` : `$${Math.round(totalSeries.Avg_Ticket).toLocaleString('en')}` : 0}</Typography></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </div>
      </Fade>
    </div>
  )
}
